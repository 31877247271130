import AcquisitionsViewer from "../../Modals/AcquisitionsViewer";
import AibeesEmployeeViewer from "../../Modals/AibeesEmployeeViewer";
import ApnicIPViewer from "../../Modals/ApnicIPViewer";
import ApolloEmployeeViewer from "../../Modals/ApolloEmployeeViewer";
import CrunchBaseFundraisingViewer from "../../Modals/CrunchBaseFundraisingViewer";
import CrustDataCompanyViewer from "../../Modals/CrustdataCompanyViewer";
import HackerTargetASN from "../../Modals/HackerTargetASN";
import HgInsightsTechStackViewer from "../../Modals/HgInsightsTechStackViewer";
import JsonViewer from "../../Modals/JsonViewer";
import LinkedinCompanyProfileViewer from "../../Modals/LinkedinCompanyProfileViewer";
import LinkedinPostViewer from "../../Modals/LinkedinPostViewer";
import LinkedinProfileViewer from "../../Modals/LinkedinProfileViewer";
import SalesNavEmployeeViewer from "../../Modals/SalesNavEmployeeViewer";
import TableViewer from "../../Modals/TableViewer";
import TenKDocViewer from "../../Modals/TenKDocViewer";
import WaterfallViewer from "../../Modals/WaterfallViewer";
import WellsViewer from "../../Modals/WellsViewer";

export const viewerComponent = (customViewer: any) => {
  switch (customViewer.type) {
    case "textFileURL":
      return <TenKDocViewer open={customViewer.open} data={customViewer.data} credits={customViewer.credits} />;
    case "table":
      return <TableViewer data={customViewer.data} credits={customViewer.credits} />;
    case "email":
      return (
        <WaterfallViewer
          data={customViewer.data}
          open={customViewer.open}
          credits={customViewer.credits}
          error={customViewer.errorMessage}
        />
      );
    case "linkedinProfile":
      return <LinkedinProfileViewer open={customViewer.open} data={customViewer.data} credits={customViewer.credits} />;
    case "linkedinCompany":
      return (
        <LinkedinCompanyProfileViewer
          open={customViewer.open}
          data={customViewer.data}
          credits={customViewer.credits}
        />
      );
    case "linkedinPosts":
      return <LinkedinPostViewer open={customViewer.open} data={customViewer.data} credits={customViewer.credits} />;
    case "aibeesEmployees":
      return <AibeesEmployeeViewer open={customViewer.open} data={customViewer.data} credits={customViewer.credits} />;
    case "apolloEmployees":
      return <ApolloEmployeeViewer open={customViewer.open} data={customViewer.data} credits={customViewer.credits} />;
    case "salesNavEmployees":
      return (
        <SalesNavEmployeeViewer open={customViewer.open} data={customViewer.data} credits={customViewer.credits} />
      );
    case "wells":
      return <WellsViewer open={customViewer.open} data={customViewer.data} />;
    case "acquisitions":
      return <AcquisitionsViewer open={customViewer.open} data={customViewer.data} />;
    case "crunchBaseFundraising":
      return (
        <CrunchBaseFundraisingViewer open={customViewer.open} data={customViewer.data} credits={customViewer.credits} />
      );
    case "crustdataCompany":
      return <CrustDataCompanyViewer data={customViewer.data} credits={customViewer.credits} />;
    case "hgInsightsTechStack":
      return (
        <HgInsightsTechStackViewer open={customViewer.open} data={customViewer.data} credits={customViewer.credits} />
      );
    case "apnicIpData":
      return <ApnicIPViewer open={customViewer.open} data={customViewer.data} credits={customViewer.credits} />;
    case "hackerTargetAsn":
      return <HackerTargetASN open={customViewer.open} data={customViewer.data} credits={customViewer.credits} />;
    default:
      return <JsonViewer open={customViewer.open} data={customViewer.data} credits={customViewer.credits} />;
  }
};

import React, { ReactNode, useEffect, useState } from "react";
import { conditionAttributes } from "@/Components/UserWorkflows/create/Components/filter.schema";
import Filters from "../Filters";
import ExampleResponse from "../ExampleResponse";
import InfiniteSelect from "../InfiniteSelect";
import { fetchTSLists } from "@/utils/apis";
import { notPrimaryFilters, PayloadStructure } from "../../models";
import { createSignal } from "../../apis";
import { SIGNAL_ID_TO_URL } from "@/utils/urls";
import { isArray } from "lodash";

interface SalesNavigatorProps {
  name: string;
  signalId: string;
  preview?: boolean;
  generatePreview: (payload: any) => Promise<void>;
  tableNode: ReactNode;
  data: any;
  signal: any;
  payloads: any;
  setPayloads: React.Dispatch<any>;
  leadsToImport: number;
  setLeadsToImport: React.Dispatch<React.SetStateAction<number>>;
  onCreateSignal?: (handler: () => Promise<void>) => void;
}

const order_by = [
  {
    desc: true,
    field: "confidence",
  },
  {
    desc: true,
    field: "jobs",
  },
  {
    desc: true,
    field: "num_jobs",
  },
];

const CompaniesListComponent = ({
  name,
  signalId,
  preview,
  generatePreview,
  tableNode,
  data,
  signal,
  payloads,
  setPayloads,
  leadsToImport,
  setLeadsToImport,
  onCreateSignal,
}: SalesNavigatorProps): React.JSX.Element => {
  const [maxCount, setMaxCount] = useState<number>(0);
  const [creditCost, setCreditCost] = useState<number>(0);
  const [fetchedLists, setFetchedLists] = useState<any>({});

  useEffect(() => {
    if (data?.metadata?.total_results !== undefined) {
      setMaxCount(data?.metadata?.total_results);
      setLeadsToImport(data?.metadata?.total_results);
    }
  }, [data?.metadata?.total_results]);

  useEffect(() => {
    const totalLeads = maxCount;
    const totalCost = totalLeads * signal.costPerLead;
    setCreditCost(totalCost);
  }, [data, signal, maxCount]);

  useEffect(() => {
    if (!onCreateSignal) {
      return;
    }

    onCreateSignal(createSignalHandler);
  }, [payloads, maxCount, data]);

  const handleGeneratePreview = async () => {
    try {
      const filtes = constructFilters(payloads);

      const payload = {
        ...filtes,
        order_by,
      };
      await generatePreview(payload);
    } catch (e) {
      console.error(e);
    }
  };

  const getListType = (payloadId: string) => {
    switch (payloadId) {
      case "company_technology_slug":
        return "technologies";
      case "industry_id":
        return "industries";
      case "tech_filters.technology_category_slug":
        return "categories";
      case "job_filters.job_technology_slug":
        return "technologies";
      default:
        return "technologies";
    }
  };

  const constructPayload = (payloads: PayloadStructure[]) => {
    const newPayload = payloads?.map((payload) => {
      if (payload?.type === "customRender") {
        const listType = getListType(payload.payloadStructureId);
        payload.renderer = () => {
          return (
            <InfiniteSelect
              value={[]}
              className="w-full"
              showSearch
              optionFilterProp="name"
              mode="multiple"
              onChange={(_, option) => {
                let optionObj = isArray(option) ? option[0] : option;
                if (!optionObj) {
                  return;
                }

                const { name } = optionObj;
                // @ts-ignore
                const selectedValues = payloads[payload.payloadStructureId] || [];
                if (!selectedValues.includes(name)) {
                  setPayloads((prev: any) => {
                    const key = payload.payloadStructureId;
                    const selectedNames = [...(prev[key] || []), typeof name === "string" ? name?.toLowerCase() : name];
                    const newPayload = {
                      ...prev,
                      [key]: selectedNames,
                    };
                    return newPayload;
                  });
                }
              }}
              fetchData={async (page) => {
                const response = await fetchTSLists(listType, { page, limit: 100 }, true);
                const lists = response?.map((item) => {
                  if (listType === "industries") {
                    if (!item.industry_id) {
                      return;
                    }
                    return {
                      id: item.industry_id,
                      name: item.industry,
                    };
                  }
                  return {
                    id: item.id,
                    name: item.name,
                    logo: item.logo,
                  };
                });

                setFetchedLists((prev: any) => ({
                  ...prev,
                  [payload.payloadStructureId]: lists,
                }));

                return lists;
              }}
              searchFetch={
                listType === "technologies"
                  ? async (searchTerm) => {
                      const response = await fetchTSLists(listType, { name_pattern: searchTerm }, true);
                      return response?.map((item) => {
                        return {
                          id: item.id,
                          name: item.name,
                          logo: item.logo,
                        };
                      });
                    }
                  : undefined
              }
              transformOption={(value: any) => ({
                label: (
                  <div className="flex justify-between items-center gap-2 p-1 w-full">
                    <div className="flex items-center gap-2 justify-end text-ellipsis">
                      {value?.logo && <img src={value?.logo} alt={value?.name} className="w-6 h-6" />}
                      <p>{value?.name}</p>
                    </div>
                  </div>
                ),
                value: value?.id || value.name,
                name: value?.name,
              })}
            />
          );
        };
      }
      return payload;
    });

    return newPayload;
  };

  const constructFieldKey = (oporator: string, key: string) => {
    const field =
      // @ts-ignore
      conditionAttributes[oporator].prefix + key + conditionAttributes[oporator].suffix;

    return field;
  };

  const constructFilters = (payloads: any[]) => {
    const filters: any = {};
    for (const key in payloads) {
      if (notPrimaryFilters.some((filter) => key.includes(filter))) {
        continue;
      }

      const filterDef = signal?.payloadStructure.find((structure: any) => structure.payloadStructureId === key);
      const hasOporator = filterDef?.availableOperators?.length > 0;

      let payload;

      if (key === "industry_id") {
        const items = fetchedLists[key]?.filter((item: any) => {
          return payloads[key]?.includes(item?.name.toLowerCase());
        });

        payload = items?.map((item: any) => item?.id);
      } else {
        payload = payloads[key];
      }

      if (Array.isArray(payload)) {
        payload = payload.map((item) => (!isNaN(Number(item)) ? parseInt(item) : item));
      } else if (!isNaN(Number(payload))) {
        payload = parseInt(payload);
      }

      const oporator = hasOporator
        ? // @ts-ignore
          payloads?.[`${key}_operator`]
          ? // @ts-ignore
            payloads[`${key}_operator`]
          : filterDef?.availableOperators[0]
        : "";

      if (key.includes(".")) {
        const [parent, child] = key.split(".");
        if (!filters?.[parent]) {
          filters[parent] = {};
        }

        hasOporator
          ? (filters[parent][constructFieldKey(oporator, child)] = payload)
          : (filters[parent][child] = payload);
      } else {
        hasOporator ? (filters[constructFieldKey(oporator, key)] = payload) : (filters[key] = payload);
      }
    }

    if (filters?.job_filters && !filters?.job_filters?.posted_at_max_age_days) {
      filters.job_filters.posted_at_max_age_days = "15";
      setPayloads((prev: any) => ({
        ...prev,
        "job_filters.posted_at": "15",
      }));
    }

    return filters;
  };

  const createSignalHandler = async () => {
    const filtes = constructFilters(payloads);

    const payload = {
      ...filtes,
      order_by,
    };

    const res = await createSignal(
      SIGNAL_ID_TO_URL[signalId as keyof typeof SIGNAL_ID_TO_URL],
      { params: payload, maxCount },
      name
    );

    return res;
  };

  return (
    <div>
      <div className="grid grid-cols-5">
        {/* controls section */}
        <div className="col-span-2 max-h-[calc(100vh-50px)] flex flex-col overflow-scroll">
          {/* filters */}
          <div className="h-[calc(100vh-50px)]">
            <div className="px-8 py-4 border-r border-[#D3D3D3]">
              <div className="text-xs text-[#5D5D5D] bg-[#EFEFEF] py-1 px-2 mb-2 rounded-sm w-fit">GET USING</div>
              <div className="text-sm text-[#5D5D5D] mt-2">
                Lets you import companies using a range of filters from Sales Navigator.
              </div>
            </div>

            <Filters
              payloadStructure={constructPayload(signal?.payloadStructure)}
              signalId={signal.id}
              name={name}
              preview={signal.isPreviewAvailable}
              generatePreview={handleGeneratePreview}
              payloads={payloads}
              setPayloads={setPayloads}
              groupFilters={signal?.filterGroups}
              createSignalParentHandler={createSignalHandler}
              pageCount={maxCount}
              handlePageCount={(value) => {
                setMaxCount(value);
              }}
              creditCost={isNaN(creditCost) ? 0 : creditCost}
              showFilterSearch={true}
              isList={true}
              itemsCount={leadsToImport}
              hideCreateSignalButton={true}
              pageCountToolTipText="Enter the number of max items you want to import"
            />
          </div>
        </div>
        {/* table section */}
        <div className="col-span-3 min-h-[calc(100vh-50px)]">
          {leadsToImport && preview ? (
            <div className="h-full px-8 flex flex-col justify-center">{tableNode}</div>
          ) : (
            <ExampleResponse
              exampleResponse={signal.exampleResponse}
              responseStructure={signal.responseStructure}
              signal={signal}
              payloads={payloads}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CompaniesListComponent;

import { renderToString } from "react-dom/server";
import {
  ACTION_TYPES,
  CELL_STATUS_DISPLAY,
  CELL_STATUS_SYMBOL,
  CELL_STATUS_TYPE,
  DISPLAY_DATE_TIME_FORMAT,
  TABLE_COLUMN_STATUS_FILTERS,
  USER_ACTION_STATUS,
  WATERFALL_ACTIONS,
  WATERFALL_ACTIONS_WITH_LARGE_RESPONSE,
} from "@/utils/constants";
import { fetchDocContent, isApplicableURL } from "@/utils/functions";
import { ActionResponses, ResponseStructure } from "@/utils/interfaces";
import { Tooltip } from "antd";
import moment from "moment";
import { IoPlay } from "react-icons/io5";
import * as Checkbox from "@radix-ui/react-checkbox";
import { FaRegCopy } from "react-icons/fa";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import { useState } from "react";
import RegenMenu from "../../Modals/RegenMenu";
import { FaArrowDownLong, FaArrowDownUpAcrossLine, FaArrowUpLong } from "react-icons/fa6";
import { cloneDeep } from "lodash";
import { BsThreeDotsVertical } from "react-icons/bs";
import { GetContextMenuItemsParams } from "ag-grid-enterprise";
import MultiReviewModal from "../../Modals/MultiReviewModal";
import { isFeatureEnabled } from "@/utils/featureFlags";

export interface ViewerProps {
  open: boolean;
  data: any;
  title: () => React.ReactNode;
  type: string;
  // style?:any;
  credits: number | Record<string, number>;
  errorMessage?: string;
}

export type Sorter = { [field: string]: string };

interface MapColumnDeps {
  runAction: (recordKey: any, inputs: any) => void;
  runCellAction: (actionId: string, dataIdL: string) => void;
  copied: boolean;
  updateRow: (row: any) => void;
  updateRows: (rows: any[] | "all") => void;
  setCustomViewer: (data: any) => void;
  setErrorModal: (data: any) => void;
  setErrorData: (data: any) => void;
  setModalStyle: (data: any) => void;
  workflowId: string;
  currentSection: number;
  modalStyle: any;
  filters?: any;
  triggerReload: () => void;
  triggerLoadingOverlay: () => void;
  handleDedupeInput: (columnId: string) => Promise<void>;
  selectedRows: any[];
  sorters: Sorter[] | null;
  sortCreatedAt: () => void;
  columnWidths: any;
  hiddenColumns: string[];
  setCrmSyncAction: (data: any) => void;
  availableSubfloqs: any[];
}

interface MapRowsDeps {
  id: string;
  currentSection: number;
  updateRow: (row: any) => void;
}

const getSortOption = (sort: string) => {
  switch (sort) {
    case "descend":
      return <FaArrowDownLong />;
    case "ascend":
      return <FaArrowUpLong />;
    default:
      return null;
  }
};

export const getSelectedRows = (selectedRows: any[], colunmsData: any[]) => {
  const selectedTempRows = cloneDeep(selectedRows);
  const transformedData = selectedTempRows.map((row: any) => {
    Object.keys(row).forEach((key) => {
      const colData = colunmsData.find((col: any) => col.field === key);
      if (!colData) return;
      const val = row[key];
      delete row[key];
      // @ts-ignore
      row[colData.id] = val;
    });
    return row;
  });

  return transformedData;
};

function formatResponse(sectionStructure: any, response: any) {
  try {
    // Validate inputs
    if (!Array.isArray(sectionStructure) || typeof response !== "object" || response === null) {
      console.warn("Invalid sectionStructure or response provided.");
      return "Invalid data format.";
    }

    // Create a mapping of responseStructureId to names from sectionStructure
    const structureMap = sectionStructure.reduce((map, item) => {
      if (item && item.responseStructureId && item.name) map[item.responseStructureId] = item.name;
      else console.warn("Invalid sectionStructure item", item);
      return map;
    }, {});

    // Format the response based on the structure map
    const formattedResponse = Object.values(response).map((entry) => {
      if (!Array.isArray(entry)) {
        console.warn("Invalid response entry", entry);
        return null;
      }

      return entry.reduce((obj, item) => {
        if (item && item.responseStructureId && item.value !== undefined) {
          const key = structureMap[item.responseStructureId];
          if (key) obj[key] = item.value;
        } else console.warn("Invalid response item", item);
        return obj;
      }, {});
    });

    return JSON.stringify(formattedResponse.filter(Boolean), null, 2); // Filter out any null entries
  } catch (error) {
    console.error("Error formatting response:", error);
    return "Error formatting response.";
  }
}

export const mapColumns = (actions: ActionResponses[], deps: MapColumnDeps) => {
  const {
    runAction,
    runCellAction,
    updateRow,
    updateRows,
    setCustomViewer,
    setErrorModal,
    setErrorData,
    setModalStyle,
    workflowId,
    currentSection,
    modalStyle,
    filters,
    triggerReload,
    handleDedupeInput,
    selectedRows,
    sorters,
    sortCreatedAt,
    columnWidths,
    hiddenColumns,
    setCrmSyncAction,
    availableSubfloqs,
  } = deps;

  let columnIndex = 0;
  const columnOrder = new Map<string, number>();

  // Group columns by subfloq_id while preserving order
  const subfloqGroups = new Map<
    string,
    {
      order: number;
      columns: any[];
    }
  >();
  // Add helper function to determine if action should be grouped
  const shouldBeGrouped = (action: ActionResponses) => {
    return action.subfloq_id || (action.id && (action.id.includes("id2") || action.id.includes("idout")));
  };

  const columns: any[] = actions
    ?.map((action) => {
      if (action.id?.includes("id2")) return null;

      // Track original position
      columnOrder.set(action.actionId, columnIndex++);

      if (action && (action.type === "input" || action.type === "add_section") && action.id) {
        setCrmSyncAction(action.actionName);
        const columns = action.response
          .map((res) => ({
            field: res.responseId,
            id: res.responseId,
            actionId: action.actionId,
            hide: hiddenColumns.includes(res.name),
            editable: !currentSection ? action.type !== "output" : false,
            sortable: false,
            // suppressSpanHeaderHeight: true,
            mainMenuItems: (params: GetContextMenuItemsParams) => [
              ...(params.defaultItems || []),
              {
                icon: `${renderToString(<FaArrowDownUpAcrossLine size={"12px"} className="text-gray-600" />)}`,
                name: "DeDupe Column",
                action: () => handleDedupeInput(res.responseId),
              },
            ],
            headerComponent: (props: any) => (
              <div className="bg-[#fffff0] h-full w-full flex justify-start items-center pr-5">
                <div
                  className="pl-2 hover:cursor-pointer"
                  onClick={() => console.log(props.api.showColumnMenu(res.responseId), "collll")}
                >
                  <BsThreeDotsVertical />
                </div>
                <div className="pl-3">{res.name?.replace(/\./g, " ")}</div>
              </div>
            ),
            width: columnWidths[res.name] || 200,
            minWidth: 80,
          }))
          .filter(Boolean);

        // If action should be grouped, add to subfloqGroups
        if (shouldBeGrouped(action)) {
          const groupId = action?.subfloq_id || action.id?.replace(/(id2|idout).*$/, "");
          if (!subfloqGroups.has(groupId)) {
            subfloqGroups.set(groupId, {
              order: columnOrder.get(action.actionId) || 0,
              columns: [],
            });
          }
          subfloqGroups.get(groupId)?.columns.push(...columns);
          return null;
        }

        return columns;
      }

      const mainColumn = {
        field: action.actionId,
        hide: hiddenColumns.includes(action.displayName || action.name),
        id: action.actionId,
        editable: false,
        sortable: false,
        width: columnWidths[action.displayName || action.name] || 250,
        minWidth: 150, // Prevent shrinking
        colData: action,
        // suppressSpanHeaderHeight: true,
        filter: () => {
          return (
            <div>
              {TABLE_COLUMN_STATUS_FILTERS?.map((status, index) => {
                return (
                  <label key={index} htmlFor={`${index}`}>
                    <div className="flex gap-2 py-1 px-2 cursor-pointer hover:bg-gray-300 hover:text-white transition duration-300">
                      <Checkbox.Root
                        className="CheckboxRoot"
                        id={`${index}`}
                        onCheckedChange={(_val) => {}}
                      ></Checkbox.Root>
                      <div className="text-sm">{status?.text}</div>
                    </div>
                  </label>
                );
              })}
            </div>
          );
        },
        headerComponent: (props: any) => {
          const showModalNearCell = (event: any) => {
            const cellRect = event.target.getBoundingClientRect();
            const modalWidth = 300;
            const modalHeight = 100;

            let modalPosition = {
              top: cellRect.top + window.scrollY,
              left: cellRect.left + window.scrollX,
            };

            // Adjust if modal goes beyond the right edge of the window
            if (modalPosition.left + modalWidth > window.innerWidth)
              modalPosition.left = window.innerWidth - modalWidth;
            // Adjust if modal goes beyond the bottom edge of the window
            if (modalPosition.top + modalHeight > window.innerHeight)
              modalPosition.top = window.innerHeight - modalHeight;

            setModalStyle({
              position: "absolute",
              top: `${modalPosition.top}px`,
              left: `${modalPosition.left}px`,
            });
          };
          return (
            <div className="flex items-center gap-2 pr-2" style={{ width: "100%" }}>
              <div
                className="pl-2 hover:cursor-pointer"
                onClick={() => {
                  console.log(props.api.showColumnMenu(action.actionId));
                }}
              >
                <BsThreeDotsVertical />
              </div>
              <div>
                <img
                  src={props.column.userProvidedColDef.colData.logo}
                  height={25}
                  width={25}
                  className="min-w-[25px] min-h-[25px]"
                />
              </div>
              <div
                className="flex-grow truncate"
                style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
              >
                {action.displayName || action.name}
              </div>
              <div>
                <div className="flex flex-row gap-2">
                  {action.type === ACTION_TYPES.AI && (
                    <div className="opacity-1 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                      <MultiReviewModal
                        actionId={action.actionId}
                        userWorkflowId={workflowId}
                        section={currentSection}
                        // showModalNearCell={showModalNearCell}
                        style={modalStyle}
                        selectedRows={selectedRows?.map((row) => row.key)}
                        setSelectedRows={() => {}}
                      />
                    </div>
                  )}
                  <div className="opacity-1 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                    <RegenMenu
                      actionId={action.actionId}
                      userWorkflowId={workflowId}
                      section={currentSection}
                      showModalNearCell={showModalNearCell}
                      style={modalStyle}
                      selectedRows={selectedRows?.map((row) => row.key)}
                      setSelectedRows={() => {}}
                      filters={filters}
                      updateRows={updateRows}
                      // refresh={triggerReload}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        },

        cellRenderer: (params: any) => {
          const { data } = params;
          const actionData = data?.[action.actionId];

          const subfloqStatuses: string[] = [];
          if (action.actionId.includes("idout")) {
            const subfloqId = action.actionId.replace("idout_", "");
            const acts = actions.filter((act) => act.actionId.includes(subfloqId));
            acts.forEach((act) => {
              const actData = data?.[act.actionId];
              if (actData && actData.status) subfloqStatuses.push(actData.status);
            });
          }

          const status = action.actionId.includes("idout")
            ? actionData?.status && actionData.status !== USER_ACTION_STATUS.PENDING
              ? actionData.status
              : subfloqStatuses.includes(USER_ACTION_STATUS.PAYLOAD_FILLED) ||
                  subfloqStatuses.includes(USER_ACTION_STATUS.CHECKING_NEXT_SOURCE)
                ? USER_ACTION_STATUS.PAYLOAD_FILLED
                : subfloqStatuses.includes(USER_ACTION_STATUS.ERROR)
                  ? USER_ACTION_STATUS.ERROR
                  : subfloqStatuses.includes(USER_ACTION_STATUS.FAILED)
                    ? USER_ACTION_STATUS.FAILED
                    : USER_ACTION_STATUS.PENDING
            : actionData?.status;

          if (!actionData) {
            return (
              <div className="flex flex-row gap-2 h-10 items-center justify-between px-2 transition group">
                <div className="flex gap-2 overflow-hidden whitespace-nowrap text-ellipsis w-auto">
                  <span className="flex pr-2 gap-1 sm:gap-2 items-center">
                    {CELL_STATUS_SYMBOL[status || "pending"]}
                    <span>{CELL_STATUS_DISPLAY[status || "pending"]}</span>
                  </span>
                </div>
                {(status || "pending") === "pending" && (
                  <Tooltip
                    placement="bottomLeft"
                    title="Run this cell"
                    arrow={false}
                    overlayClassName="border border-gray-200 rounded-md"
                    overlayInnerStyle={{
                      fontWeight: 600,
                      backgroundColor: "#f3f4f6",
                      color: "#6c727f",
                      fontSize: "0.75rem",
                    }}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        const actionId = params.column.colDef.id;
                        const dataId = params.data.key;
                        runCellAction(actionId, dataId);
                        setTimeout(triggerReload, 1500);
                      }}
                      title="Run"
                      className="group-hover:flex justify-center items-center rounded-full bg-gray-200 hover:bg-gray-300 p-2 hidden"
                    >
                      <IoPlay size={"12px"} className="text-gray-600" />
                    </button>
                  </Tooltip>
                )}
              </div>
            );
          }

          const ColTitle = ({ action, children }: { action: ActionResponses; children?: React.ReactNode }) => (
            <div className="relative flex items-center justify-between gap-2">
              <section className="flex items-center justify-between gap-2 overflow-hidden text-ellipsis">
                <img src={action.logo} alt={action.name} className="w-6 h-6" />
                <span>{action.displayName || action.name}</span>
              </section>
              {children}
            </div>
          );

          const { extra, stepDownResponse, ...colResponse } = actionData;

          const actionResponseSize: string = action.responseSize;
          const isActionResponseSizeSmall: boolean = actionResponseSize === "small";
          const isActionResponseSizeLarge: boolean = actionResponseSize === "large";
          const isWaterfallAction = WATERFALL_ACTIONS.includes(action.actionName);
          const isWaterfallActionWithLargeResponse = WATERFALL_ACTIONS_WITH_LARGE_RESPONSE.includes(action.actionName);
          const actionResponse: any = isActionResponseSizeSmall && Object.values(colResponse)[0];

          // Remove undefined keys and values from colResponse
          const largeActionResponse: any =
            isActionResponseSizeLarge &&
            Object.entries(colResponse)
              .map(([_, res]: [string, any]) => {
                // Only return the key-value pair if the value is defined
                return res.value !== undefined ? { [res.name]: res.value } : null;
              })
              .filter((item) => item !== null) // Remove null entries
              .reduce((acc, curr) => ({ ...acc, ...curr }), {}); // Merge objects

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [copied, setCopied] = useState(false);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [retried, _setRetried] = useState(false);

          const handleCopyEmail = () => {
            if (isActionResponseSizeSmall) {
              navigator.clipboard
                .writeText(actionResponse.value)
                .then(() => {
                  setCopied(true);
                  setTimeout(() => setCopied(false), 2000); // Hide after 2 seconds
                })
                .catch((_err) => {
                  alert("Failed to copy. Please try again.");
                });
            }
          };

          /**
           * @todo: This function is giving few issues, fix it ASAP.
           */
          const showModalNearCell = (event: any) => {
            const cellRect = event.target.getBoundingClientRect();
            // Assuming a fixed size for the modal for demonstration purposes
            const modalWidth = 500; // Adjust as needed
            const modalHeight = 200; // Adjust as needed

            // Initial modal position calculation
            let modalPosition = {
              top: cellRect.top + window.scrollY,
              left: cellRect.left + window.scrollX,
            };

            // Adjust if modal goes beyond the right edge of the window
            if (modalPosition.left + modalWidth > window.innerWidth)
              modalPosition.left = window.innerWidth - modalWidth;

            // Adjust if modal goes beyond the bottom edge of the window
            if (modalPosition.top + modalHeight > window.innerHeight)
              modalPosition.top = window.innerHeight - modalHeight;

            // Apply the adjusted positions, converting back to strings with 'px'
            setModalStyle({
              position: "absolute",
              top: `${modalPosition.top}px`,
              left: `${modalPosition.left}px`,
            });
          };

          const customDataFormat = (value: any) => {
            if (["string", "number"].includes(typeof value)) return value;
            const valuesLen = Object.keys(value).length;
            return valuesLen ? JSON.stringify(value) : "";
          };

          const jsonParser = (json: string) => {
            if (typeof json !== "string") return json;
            try {
              return JSON.parse(json);
            } catch (e) {
              return json;
            }
          };

          // Get workflowDataActionPartId from actionData
          const workflowDataActionPartId = actionData?.workflowDataActionPartId;

          return (
            //<div className="relative flex space-x-4 transition group">
            <div className="flex flex-row gap-2 px-2 justify-start transition group ">
              {action["ui-style"] === "Formatting" && action.actionName.includes("FORMATTER") ? (
                // display the formatted value as is in the cell
                <div className="flex gap-2 overflow-hidden whitespace-nowrap text-ellipsis w-auto">
                  <span className="flex items-center justify-center pr-2 gap-1 sm:gap-2">
                    {[CELL_STATUS_TYPE.PAYLOAD_FILLED].includes(status) && (
                      <>
                        {CELL_STATUS_SYMBOL[status || "pending"]} {CELL_STATUS_DISPLAY?.[status]}
                      </>
                    )}
                    {customDataFormat((Object.values(colResponse)[0] as any)?.value || {})}
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className={`flex gap-2 overflow-hidden whitespace-nowrap text-ellipsis w-auto`}
                  disabled={
                    !(
                      actionData &&
                      [
                        CELL_STATUS_TYPE.COMPLETED,
                        CELL_STATUS_TYPE.READY_TO_BE_REVIEWED,
                        CELL_STATUS_TYPE.FAILED,
                        CELL_STATUS_TYPE.ERROR,
                      ].includes(status || "")
                    )
                  }
                  onClick={() => {
                    if (!actionData) return alert("Error processing request. Please try again later.");
                    if (
                      [USER_ACTION_STATUS.FAILED, USER_ACTION_STATUS.ERROR].includes(status || "") &&
                      !isWaterfallActionWithLargeResponse
                    ) {
                      if (isWaterfallAction) {
                        setCustomViewer({
                          open: true,
                          data: {
                            stepDownResponse,
                            action,
                            email: actionResponse.value,
                            workflowDataActionPartId: params?.value?.workflowDataActionPartId,
                          },
                          title: () => <ColTitle action={action} />,
                          type: "email",
                          credits: action.credits,
                          errorMessage: actionData.error,
                        });
                      } else {
                        setErrorData({
                          status:
                            status !== USER_ACTION_STATUS.ERROR
                              ? status
                              : actionData.error.includes("Waiting")
                                ? "Waiting"
                                : USER_ACTION_STATUS.ERROR,
                          message: actionData.error,
                        });
                        setErrorModal(true);
                      }
                      return;
                    }

                    if (
                      action.actionName === "PUBLIC_LINKEDIN_POSTS_ENRICH" ||
                      action.actionName === "PUBLIC_LINKEDIN_POSTS_ENRICH_COMPANY"
                    ) {
                      setCustomViewer({
                        open: true,
                        data: {
                          stepDownResponse,
                          response: largeActionResponse,
                        },
                        title: () => <ColTitle action={action} />,
                        type: "linkedinPosts",
                        credits: action.credits,
                      });
                    } else if (action.actionName === "SCRAPE_LINKEDIN_PUBLIC_DATA_2") {
                      setCustomViewer({
                        open: true,
                        data: {
                          responseStructure: action.responseStructure,
                          stepDownResponse,
                          response: largeActionResponse,
                        },
                        title: () => <ColTitle action={action} />,
                        type: "linkedinProfile",
                        credits: action.credits,
                      });
                    } else if (action.actionName === "SCRAPE_COMPANY_LINKEDIN_DATA_2") {
                      setCustomViewer({
                        open: true,
                        data: {
                          stepDownResponse,
                          largeActionResponse,
                        },
                        title: () => <ColTitle action={action} />,
                        type: "linkedinCompany",
                        credits: action.credits,
                      });
                    } else if (action.actionName === "FIND_EMPLOYEES_FOR_COMPANY_USING_APOLLO") {
                      setCustomViewer({
                        open: true,
                        data: largeActionResponse,
                        title: () => <ColTitle action={action} />,
                        type: "apolloEmployees",
                        credits: action.credits,
                      });
                    } else if (action.actionName === "FIND_EMPLOYEES_USING_AI_BEES") {
                      setCustomViewer({
                        open: true,
                        data: {
                          data: largeActionResponse,
                          action: action,
                          workflowDataActionPartId: params?.value?.workflowDataActionPartId,
                        },
                        title: () => <ColTitle action={action} />,
                        type: "aibeesEmployees",
                        credits: action.credits,
                      });
                    } else if (action.actionName === "FIND_EMPLOYEES_USING_SALES_NAV_URL") {
                      setCustomViewer({
                        open: true,
                        data: largeActionResponse,
                        title: () => <ColTitle action={action} />,
                        type: "salesNavEmployees",
                        credits: action.credits,
                      });
                    } else if (action.actionName === "WELL_DB_OPERATOR_LOOKUP_AND_WELLS_SEARCH") {
                      setCustomViewer({
                        open: true,
                        data: largeActionResponse,
                        title: () => <ColTitle action={action} />,
                        type: "wells",
                        credits: 0,
                      });
                    } else if (action.actionName === "CRUNCHBASE_RECENT_FUNDRAISING_DATA_FROM_COMPANY") {
                      setCustomViewer({
                        open: true,
                        data: largeActionResponse,
                        title: () => <ColTitle action={action} />,
                        type: "crunchBaseFundraising",
                        credits: action.credits,
                      });
                    } else if (action.actionName === "ZENROWS_CRUNCHBASE_ACQUISITIONS_LIST") {
                      setCustomViewer({
                        open: true,
                        data: largeActionResponse,
                        title: () => <ColTitle action={action} />,
                        type: "acquisitions",
                        credits: action.credits,
                      });
                    } else if (action.type === "sending" && action.outputStructureType === "noOutput") {
                      setCustomViewer({
                        open: true,
                        data: {
                          status: actionResponse.value || status,
                        },
                        title: () => <ColTitle action={action} />,
                        type: "default",
                        credits: action.credits,
                      });
                    } else if (
                      typeof colResponse === "object" &&
                      Object.keys(colResponse).length > 0 &&
                      Object.values(colResponse).some((res: any) => res.type && res.type === "textFileURL")
                    ) {
                      setCustomViewer({
                        open: true,
                        data: actionData,
                        title: () => <ColTitle action={action} />,
                        type: "textFileURL",
                        credits: action.credits,
                      });
                    } else if (
                      typeof colResponse === "object" &&
                      Object.keys(colResponse).length > 0 &&
                      Object.values(colResponse).some((res: any) => res.type && res.type === "table")
                    ) {
                      setCustomViewer({
                        open: true,
                        data: largeActionResponse,
                        title: () => <ColTitle action={action} />,
                        type: "table",
                        credits: action.credits,
                      });
                    } else if (typeof actionData === "object" && actionData.extra.reviewAllowed) {
                      showModalNearCell(event);
                      setCustomViewer({
                        open: true,
                        data: actionData,
                        title: () => <ColTitle action={action} />,
                        type: "review",
                        credits: action.credits,
                      });
                    } else if (isWaterfallAction) {
                      setCustomViewer({
                        open: true,
                        data: {
                          stepDownResponse,
                          action,
                          email: actionResponse.value,
                          workflowDataActionPartId: params?.value?.workflowDataActionPartId,
                        },
                        title: () => <ColTitle action={action} />,
                        type: "email",
                        credits: action.credits,
                      });
                    } else if (action.actionName === "CRUSTDATA_COMPANY_ENRICH") {
                      setCustomViewer({
                        open: true,
                        data: {
                          responseStructure: action.responseStructure,
                          stepDownResponse,
                          largeActionResponse,
                        },
                        title: () => <ColTitle action={action} />,
                        type: "crustdataCompany",
                        credits: action.credits,
                      });
                    } else if (action.actionName === "HG_INSIGHTS_TECH_STACK_ENRICH") {
                      setCustomViewer({
                        open: true,
                        data: largeActionResponse,
                        title: () => <ColTitle action={action} />,
                        type: "hgInsightsTechStack",
                        credits: action.credits,
                      });
                    } else if (action.actionName === "ENRICH_IP_DATA_WATERFALL") {
                      setCustomViewer({
                        open: true,
                        data: {
                          response: Object.values(colResponse)
                            .map((res: any) => ({
                              [res.name]: res.stringified ? jsonParser(res.value) : res.value,
                            }))
                            .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
                          stepDownResponse,
                          action,
                          workflowDataActionPartId: params?.value?.workflowDataActionPartId,
                          status: status,
                          successText: "Enriched IP Data",
                          navigationText: "View enriched IP data",
                        },
                        title: () => <ColTitle action={action} />,
                        type: "apnicIpData",
                        credits: action.credits,
                        errorMessage: actionData.error,
                      });
                    } else if (action.actionName === "HACKER_TARGET_ASN_LOOKUP") {
                      setCustomViewer({
                        open: true,
                        data: {
                          response: Object.values(colResponse)
                            .map((res: any) => ({
                              [res.name]: res.stringified ? jsonParser(res.value) : res.value,
                            }))
                            .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
                          action,
                          workflowDataActionPartId: params?.value?.workflowDataActionPartId,
                        },
                        title: () => <ColTitle action={action} />,
                        type: "hackerTargetAsn",
                        credits: action.credits,
                      });
                    } else {
                      setCustomViewer({
                        open: true,
                        data: {
                          response: Object.values(colResponse)
                            .map((res: any) => ({
                              [res.name]: res.stringified ? jsonParser(res.value) : res.value,
                            }))
                            .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
                          workflowDataActionPartId: params?.value?.workflowDataActionPartId,
                          action,
                        },
                        title: () => <ColTitle action={action} />,
                        type: "default",
                        credits: action.credits,
                        errorMessage: actionData.error,
                      });
                    }
                  }}
                  data-workflow-action-part-id={workflowDataActionPartId}
                >
                  <span className="flex items-center justify-center pr-2 gap-1 sm:gap-2">
                    {CELL_STATUS_SYMBOL[status || "pending"]}
                    <span>
                      {(status === "completed" || status === "readyToBeReviewed") &&
                      actionResponse &&
                      (actionResponse.value === 0 ? "0" : actionResponse.value)
                        ? actionResponse.value
                        : status === USER_ACTION_STATUS.ERROR && actionData.error.includes("Waiting")
                          ? "𝘞𝘢𝘪𝘵𝘪𝘯𝘨"
                          : CELL_STATUS_DISPLAY[status || "pending"]}
                    </span>
                  </span>
                </button>
              )}
              <div className="flex items-center justify-center gap-2">
                {actionResponse && (
                  <div className="opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                    <Tooltip
                      placement="bottomLeft"
                      title={copied ? "Copied!" : "Copy to clipboard"}
                      arrow={false}
                      overlayClassName="border border-gray-200 rounded-md"
                      overlayInnerStyle={{
                        fontWeight: 600,
                        backgroundColor: `${copied ? "#6c727f" : "#f3f4f6"}`,
                        color: `${copied ? "#f3f4f6" : "#6c727f"}`,
                        fontSize: "0.75rem",
                      }}
                    >
                      <button
                        type="button"
                        onClick={handleCopyEmail}
                        // title="Copy"
                        className="flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200 p-2"
                      >
                        <FaRegCopy size={"12px"} className="text-gray-600" />
                      </button>
                    </Tooltip>
                  </div>
                )}
                <div className="opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                  <Tooltip
                    placement="bottomLeft"
                    title={retried ? "Run!" : "Run this cell"}
                    arrow={false}
                    overlayClassName="border border-gray-200 rounded-md"
                    overlayInnerStyle={{
                      fontWeight: 600,
                      backgroundColor: `${retried ? "#6c727f" : "#f3f4f6"}`,
                      color: `${retried ? "#f3f4f6" : "#6c727f"}`,
                      fontSize: "0.75rem",
                    }}
                  >
                    <button
                      type="button"
                      // onClick={handleRetryCell}
                      onClick={() => {
                        const actionId = params.column.colDef.id;
                        const dataId = params.data.key;
                        runCellAction(actionId, dataId);
                        setTimeout(triggerReload, 1500);
                      }}
                      // title="Retry"
                      className="flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200 p-2"
                    >
                      <IoPlay size={"12px"} className="text-gray-600" />
                    </button>
                  </Tooltip>
                </div>
              </div>
            </div>
          );
        },
        valueGetter: (params: any) => {
          const actionData = params.data?.[action.actionId];
          return {
            value: actionData?.value,
            workflowDataActionPartId: actionData?.workflowDataActionPartId,
          };
        },
      };
      // Subcolumns
      const subColumns = action.response
        .filter((res: any) => res.display_column)
        .map((res: any) => ({
          field: res.responseId,
          hide: hiddenColumns.includes(res.name),
          id: res.responseId,
          actionId: action.actionId,
          editable: false,
          sortable: false,
          // suppressSpanHeaderHeight: true,
          mainMenuItems: (params: GetContextMenuItemsParams) => [
            ...(params.defaultItems || []),
            {
              icon: `${renderToString(<FaArrowDownUpAcrossLine size={"12px"} className="text-gray-600" />)}`,
              name: "DeDupe Column",
              action: () => {
                handleDedupeInput(res.responseId);
              },
            },
          ],
          headerComponent: (props: any) => (
            <div className="bg-[#fffff0] h-full w-full flex justify-start items-center pr-5">
              <div
                className="pl-2 hover:cursor-pointer"
                onClick={() => {
                  console.log(props.api.showColumnMenu(res.responseId));
                }}
              >
                <BsThreeDotsVertical />
              </div>
              <div className="pl-3">{res.name}</div>
            </div>
          ),
          cellRenderer: (params: any) => {
            const { data } = params;
            const actionData = data?.[action.actionId];
            const text = actionData?.[res.responseId];
            const status = actionData?.status;
            /**
             * if text.sectionVariablesStructure exists, then it is a sectionList -> format response accordingly and display
             */
            return (
              <Tooltip
                placement="bottom"
                title={(() => {
                  try {
                    if (["string", "number", "boolean"].includes(typeof text?.value)) return text?.value;
                    else if (Array.isArray(text?.sectionVariablesStructure) && typeof text?.value === "object")
                      return formatResponse(text.sectionVariablesStructure, text.value);
                    else return JSON.stringify(text?.value, null, 2);
                  } catch (error) {
                    console.error("Error generating tooltip content:", error);
                    return "Error generating content.";
                  }
                })()}
                arrow={false}
                overlayClassName="border border-gray-200 rounded-md text-sm whitespace-pre-wrap overflow-auto max-h-96 max-w-md scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
                overlayInnerStyle={{
                  fontWeight: 600,
                  backgroundColor: "#f3f4f6",
                  color: "#6c727f",
                  fontSize: "0.75rem",
                }}
              >
                <div className="overflow-hidden whitespace-nowrap text-ellipsis w-auto cursor-pointer">
                  {(() => {
                    try {
                      if (status !== "completed" && status !== "readyToBeReviewed")
                        return (
                          <div className="flex gap-2 items-center">
                            {CELL_STATUS_SYMBOL[actionData?.status || "pending"]}
                            <span>{CELL_STATUS_DISPLAY[actionData?.status || "pending"]}</span>
                          </div>
                        );
                      else if (["string", "number", "boolean"].includes(typeof text?.value)) return text?.value;
                      else if (Array.isArray(text?.sectionVariablesStructure) && typeof text?.value === "object")
                        return formatResponse(text.sectionVariablesStructure, text.value);
                      else return JSON.stringify(text?.value);
                    } catch (error) {
                      console.error("Error rendering tooltip content:", error);
                      return "Error rendering content.";
                    }
                  })()}
                </div>
              </Tooltip>
            );
          },
          width: columnWidths[res.name] || 180,
          minWidth: 180,
          valueFormatter: (params: any) => {
            const { data } = params;
            const actionData = data?.[action.actionId];
            const text = actionData?.[res.responseId];
            if (["string", "number", "boolean"].includes(typeof text?.value)) return text?.value;
            else if (Array.isArray(text?.sectionVariablesStructure) && typeof text?.value === "object")
              return formatResponse(text.sectionVariablesStructure, text.value);
            else return JSON.stringify(text?.value);
          },
        }));

      // Combine main column and subcolumns
      if (action.subfloq_id) {
        // Add to group while preserving order
        if (!subfloqGroups.has(action.subfloq_id)) {
          subfloqGroups.set(action.subfloq_id, {
            order: columnOrder.get(action.actionId) || 0,
            columns: [],
          });
        }
        subfloqGroups.get(action.subfloq_id)?.columns.push(mainColumn, ...subColumns);
        return null;
      }
      return [mainColumn, ...subColumns];
    })
    .filter(Boolean);

  // When creating grouped columns, modify the column definitions like this:
  const groupedColumns = Array.from(subfloqGroups.entries()).map(([subfloqId, group]) => {
    const columns = group.columns;

    // Modify columns to specify their visibility state
    const enhancedColumns = columns.map((col) => ({
      ...col,
      // Show only main column when collapsed, show all when expanded
      columnGroupShow: col?.actionId?.includes("idout") || col?.id?.includes("idout") ? undefined : "open",
    }));
    // get the subfloq Name to display in the column.
    const subfloqName = availableSubfloqs.find((subfloq) => subfloq.id === subfloqId)?.name || "Subfloq Action";

    return {
      groupId: `SUBFLOQ_${subfloqId}`,
      headerName: subfloqName,
      children: enhancedColumns,
      marryChildren: true,
      openByDefault: false,
      order: group.order,
      suppressColumnsToolPanel: false,
      enableRowGroup: true,
    };
  });
  const createdAtSortOption = sorters?.find((sorter) => sorter.feild === "createdAt");

  const flatArr = columns.reduce((acc, val) => acc.concat(...(Array.isArray(val) ? val : [val])), []);

  // Insert grouped columns at their original positions
  groupedColumns.forEach((group) => {
    const firstActionRes: number = actions.find((action) => action.id === "id1")?.response.length || 1;
    flatArr.splice(group.order + firstActionRes - 1, 0, {
      headerName: group.headerName,
      headerGroupComponentParams: {
        innerHeaderGroupComponent: <div>{group.headerName + "addad"}</div>,
      },
      groupId: group.groupId,
      children: group.children,
      marryChildren: group.marryChildren,
      openByDefault: group.openByDefault,
      headerClass: "text-sm font-semibold text-gray-500",
    });
  });

  // Add the Index column first if enabled
  if (isFeatureEnabled("show_index_column_on_table_view"))
    flatArr.unshift({
      field: "_index",
      headerName: "Index",
      width: 80,
      pinned: "left",
      sortable: true,
      // suppressSpanHeaderHeight: true,
      headerComponent: () => {
        return (
          <div className="flex justify-center items-center pr-5">
            <div className="pl-2 hover:cursor-pointer">
              <BsThreeDotsVertical />
            </div>
            <div className="pl-3">Index</div>
          </div>
        );
      },
      cellRenderer: (params: any) => {
        return <div className="pl-2">{(params.data?._index ?? -1) + 1}</div>;
      },
    });

  // Add other standard columns
  flatArr.unshift({
    field: "Created At",
    hide: hiddenColumns.includes("Created At"),
    id: "createdAt",
    // suppressSpanHeaderHeight: true,
    headerComponent: (params: any) => {
      return (
        <div className="flex justify-center items-center pr-5">
          <div
            className="pl-2 hover:cursor-pointer"
            onClick={() => {
              console.log(params.api.showColumnMenu("Created At"));
            }}
          >
            <BsThreeDotsVertical />
          </div>
          <div className="flex gap-1 pl-3 cursor-pointer" onClick={sortCreatedAt}>
            <div>Created At</div>
            <div>{getSortOption(createdAtSortOption?.order || "")}</div>
          </div>
        </div>
      );
    },
    cellRenderer: (params: any) => {
      const { data } = params;
      return (
        // <div className="flex justify-center h-full items-center
        //  text-red-300 font-semibold text-sm px-5 transition group hover:text-red-500 cursor-pointer
        // ">
        //   {data.createdAtt}
        // </div>
        <div>{data.createdAtt}</div>
      );
    },
  });

  flatArr.unshift({
    field: "Run",
    hide: hiddenColumns.includes("Run"),
    editable: false,
    sortable: false,
    width: 75,
    pinned: "left",
    // suppressSpanHeaderHeight: true,
    headerComponent: (_: any) => {
      return <div className="flex justify-center items-center px-5">Run</div>;
    },
    // type needs to be replaced
    cellRenderer: (params: any) => {
      const { data } = params;
      const { run } = data;

      const updateLoadingState = () => {
        data.run = "loading";
        updateRow(data);
      };
      const runRow = () => {
        const colIndex = params.api.getAllGridColumns();
        const inputColIds = colIndex
          .filter((col: any) => col?.colDef?.editable)
          .map((col: any) => ({ id: col?.colDef?.id, name: col?.colDef?.field }));

        const inputs = Object.entries(data).reduce((acc, [key, value]) => {
          if (key === "key" || key === "run" || key === "data") return acc;
          const colData = inputColIds.find((col: any) => col.name === key);
          if (colData) {
            return { ...acc, [colData?.id]: value };
          }
          return acc;
        }, {});
        setTimeout(() => runAction([data?.key], [inputs]), 2000);
        updateLoadingState();
      };
      return (
        <div className="flex justify-center h-full items-center">
          {run === "run" ? (
            <div className="opacity-1 group-hover:opacity-100 transition-all duration-300 ease-in-out">
              <Tooltip
                placement="right"
                title="Run"
                arrow={false}
                overlayClassName="border border-gray-200 rounded-md"
                overlayInnerStyle={{
                  fontWeight: 600,
                  backgroundColor: "#f3f4f6",
                  color: "#6c727f",
                  fontSize: "0.75rem",
                }}
              >
                <button
                  type="button"
                  onClick={runRow}
                  className="flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-300 p-2"
                >
                  <IoPlay size={"12px"} />
                </button>
              </Tooltip>
            </div>
          ) : run === "retry" ? (
            <div className="opacity-1 group-hover:opacity-100 transition-all duration-300 ease-in-out">
              <Tooltip
                placement="right"
                title="Run"
                arrow={false}
                overlayClassName="border border-gray-200 rounded-md"
                overlayInnerStyle={{
                  fontWeight: 600,
                  backgroundColor: "#f3f4f6",
                  color: "#6c727f",
                  fontSize: "0.75rem",
                }}
              >
                <button
                  onClick={runRow}
                  className="flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-300 p-2"
                >
                  <IoPlay size={"12px"} />
                </button>
              </Tooltip>
            </div>
          ) : run === "loading" ? (
            <div className="flex justify-center items-center p-2">
              <SpinnerStatus />
            </div>
          ) : run === "review" ? (
            <div className="flex items-center justify-center p-2">{CELL_STATUS_SYMBOL.readyToBeReviewed}</div>
          ) : run === "complete" ? (
            <div className="flex items-center justify-center p-2">{CELL_STATUS_SYMBOL.completed}</div>
          ) : (
            <></>
          )}
        </div>
      );
    },
  });
  return flatArr;
};

export const mapRows = async (actions: ActionResponses[], data: any, deps: MapRowsDeps) => {
  const { id, currentSection } = deps;
  const rows: any[] = [];

  await Promise.all(
    Object.entries(data).map(async ([dataId, item]: [string, any], idx: number) => {
      if (item.sectionInfo && currentSection === 0) return;

      // Ensure _index is properly set
      const row: any = {
        key: dataId,
        _index: typeof item._index === "number" ? item._index : idx,
      };

      // Log warning if _index is missing
      if (typeof item._index !== "number") {
        console.warn(`Missing _index for item with dataId ${dataId}, using fallback index ${idx}`);
      }

      const status: string[] = [];
      await Promise.all(
        Object.entries(item).map(async ([actionId, data]: [string, any]) => {
          const action = actions.find((a) => a.actionId === actionId || a.fsWfActionId === actionId);
          if (!action || ["createdAt", "sectionInfo"].includes(actionId)) return;
          else if (
            action &&
            (action.type === "input" || action.type === "add_section") &&
            !action.id?.includes("id2")
          ) {
            const actionResponseMap: Record<string, string> = {};
            action.response.forEach((res: ResponseStructure) => {
              actionResponseMap[res.responseId] = res?.name?.replace(/\./g, " ");
            });

            (data.response ?? []).map((res: any) => {
              row[res.responseId] = res.value;
            });
            if (data?.createdAt)
              if (!row?.["createdAtt"])
                row["createdAtt"] = moment.utc(data?.createdAt).local().format(DISPLAY_DATE_TIME_FORMAT);
            row.responseMap = Object.fromEntries(Object.entries(actionResponseMap).map(([key, value]) => [value, key]));
          } else {
            status.push(data.status);
            let temp: any = {};
            let respArray = [];
            if (typeof data.response === "string" && isApplicableURL(data.response))
              respArray = await fetchDocContent(data.response);
            else respArray = data.response || [];

            if (Array.isArray(respArray)) {
              respArray.map((r: any) => {
                temp[r.responseId] = {
                  ...(action?.response.find((res) => res.responseId === r.responseId) || {}),
                  value: r.value,
                  stringified: r.stringified,
                };
              });
            }

            if (data.stepDownResponse) temp.stepDownResponse = data.stepDownResponse;
            if (data.error) temp.error = data.error;
            temp = {
              ...temp,
              status: data.status,
              workflowDataActionPartId: data.id,
              extra: {
                reviewNeeded: action?.reviewNeeded || false,
                reviewed: data.reviewed || false,
                index: idx,
                workflowId: id,
                actionId,
                dataId,
                reviewAllowed: action?.reviewAllowed || false,
              },
            };
            row[actionId] = temp;
          }
        })
      );
      if (status.includes(CELL_STATUS_TYPE.READY_TO_BE_REVIEWED)) row.run = "review";
      else if (
        status.includes(CELL_STATUS_TYPE.MISSING) ||
        status.includes(CELL_STATUS_TYPE.CHECKING_NEXT_SOURCE) ||
        status.includes(CELL_STATUS_TYPE.PAYLOAD_FILLED)
      )
        row.run = "loading";
      else row.run = "retry";
      // row.data = item;
      rows.push(row);
    })
  );

  // Sort rows by _index before returning
  return rows.sort((a, b) => (a._index ?? Infinity) - (b._index ?? Infinity));
};

export const normalizeRowData = (data: any, actions: ActionResponses[]) => {
  const response: any = {};
  actions?.forEach((action) => {
    if (!((action.type === "input" && !action.id?.includes("id2")) || action.type === "add_section")) return;

    action?.response?.forEach((res) => {
      response[res.responseId] = res.name;
    });
  });

  data.responseMap = response;
  return data;
};

import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";

type SearchInputProps = {
  placeholder?: string;
  onSearch?: (searchTerm: string) => void;
  className?: string; // Optional custom class for the container
};

const SearchInput = ({ placeholder = "Search", onSearch, className }: SearchInputProps) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isSearchExpanded, setIsSearchExpanded] = useState<boolean>(false);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (onSearch) {
      onSearch(value);
    }
  };

  const handleBlur = () => {
    if (!searchTerm.trim()) {
      setIsSearchExpanded(false);
    }
  };

  return (
    <div className={`flex items-center gap-2 ${className}`}>
      {isSearchExpanded ? (
        <div className="flex items-center justify-between w-[350px] bg-[#F3F4F6] border-[0.5px] border-[#333] rounded-md p-[0.5px] px-1">
          <input
            type="text"
            placeholder={placeholder}
            value={searchTerm}
            onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
              e.target.style.border = "none";
              e.target.style.outline = "none";
              e.target.style.boxShadow = "none";
            }}
            autoFocus={isSearchExpanded}
            onBlur={handleBlur}
            onChange={handleSearchChange}
            className="text-[10px] placeholder:text-[10px] bg-transparent outline-none rounded-md px-2 py-1 w-full border-none"
          />
          <button onClick={() => setIsSearchExpanded(false)} className="p-1 rounded-md hover:bg-gray-200">
            <FiSearch />
          </button>
        </div>
      ) : (
        <button onClick={() => setIsSearchExpanded(true)} className="p-2 rounded-md bg-[#F3F4F6] hover:bg-gray-200">
          <FiSearch />
        </button>
      )}
    </div>
  );
};

export default SearchInput;

import { useState, useEffect } from "react";
import { Select, Button, InputNumber } from "antd";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const { Option } = Select;

const daysOfWeek = [
  { label: "Sun", value: 0 },
  { label: "Mon", value: 1 },
  { label: "Tue", value: 2 },
  { label: "Wed", value: 3 },
  { label: "Thu", value: 4 },
  { label: "Fri", value: 5 },
  { label: "Sat", value: 6 },
];

interface CronSchedulerProps {
  onCronChange: (cron: string) => void;
}

const CustomCronGenerator = ({ onCronChange }: CronSchedulerProps) => {
  const [scheduleType, setScheduleType] = useState("daily");
  const [customInterval, setCustomInterval] = useState(1);
  const [customUnit, setCustomUnit] = useState("days");
  const [selectedWeekday, setSelectedWeekday] = useState(1);
  const [monthOption, setMonthOption] = useState("dayX");
  const [monthDay, setMonthDay] = useState(1);
  const [monthWeekday, setMonthWeekday] = useState(1);

  // const [cronExpression, setCronExpression] = useState("0 12 * * *");

  useEffect(() => {
    let cron = "";

    switch (scheduleType) {
      case "twiceDaily":
        cron = "0 9,21 * * *";
        break;
      case "daily":
        cron = "0 12 * * *";
        break;
      case "every2Days":
        cron = "0 12 */2 * *";
        break;
      case "weekly":
        cron = `0 12 * * ${selectedWeekday}`;
        break;
      case "monthly":
        cron = "0 12 1 * *";
        break;
      case "custom":
        if (customUnit === "days") {
          cron = `0 12 */${customInterval} * *`;
        } else if (customUnit === "weeks") {
          cron = `0 12 * */${customInterval} ${selectedWeekday}`;
        } else if (customUnit === "months") {
          cron =
            monthOption === "dayX"
              ? `0 12 ${monthDay} */${customInterval} *`
              : `0 12 * */${customInterval} ${monthWeekday}`;
        }
        break;
      default:
        cron = "0 12 * * *";
    }

    // setCronExpression(cron);
    onCronChange(cron);
  }, [scheduleType, customInterval, customUnit, selectedWeekday, monthOption, monthDay, monthWeekday]);

  return (
    <div className="flex flex-col gap-4 p-4 mx-auto w-full">
      <div className="flex gap-2 flex-wrap">
        <Button
          className={`${scheduleType === "twiceDaily" ? "border font-bold" : "border-none"} bg-[#F3F4F6]`}
          onClick={() => setScheduleType("twiceDaily")}
        >
          Twice daily
        </Button>
        <Button
          className={`${scheduleType === "daily" ? "border font-bold" : "border-none"} bg-[#F3F4F6]`}
          onClick={() => setScheduleType("daily")}
        >
          Daily at 12
        </Button>
        <Button
          className={`${scheduleType === "every2Days" ? "border font-bold" : "border-none"} bg-[#F3F4F6]`}
          onClick={() => setScheduleType("every2Days")}
        >
          Every 2 days
        </Button>
        <Button
          className={`${scheduleType === "weekly" ? "border font-bold" : "border-none"} bg-[#F3F4F6]`}
          onClick={() => setScheduleType("weekly")}
        >
          Every week on{" "}
          {scheduleType === "weekly" && (
            <Select value={selectedWeekday} onChange={setSelectedWeekday}>
              {daysOfWeek.map(({ label, value }) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          )}
        </Button>

        <Button
          className={`${scheduleType === "monthly" ? "border font-bold" : "border-none"} bg-[#F3F4F6]`}
          onClick={() => setScheduleType("monthly")}
        >
          1st of Every Month
        </Button>
      </div>

      {/* Custom Scheduling */}
      <div>
        <Button className="border-none underline text-left" onClick={() => setScheduleType("custom")}>
          Specify custom range {scheduleType === "custom" ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </Button>
        {scheduleType === "custom" && (
          <div className="flex gap-2 mt-2">
            <span>Repeat every</span>
            <InputNumber min={1} value={customInterval} onChange={(value) => setCustomInterval(value ?? 1)} />
            <Select value={customUnit} onChange={setCustomUnit} className="w-[6rem]">
              <Option value="days">Days</Option>
              <Option value="weeks">Weeks</Option>
              <Option value="months">Months</Option>
            </Select>

            {customUnit === "weeks" && (
              // <Select value={selectedWeekday} onChange={setSelectedWeekday}>
              //   {daysOfWeek.map(({ label, value }) => (
              //     <Option key={value} value={value}>{label}</Option>
              //   ))}
              // </Select>
              <div className="flex gap-2">
                {["S", "M", "T", "W", "T", "F", "S"].map((day, index) => (
                  <Button
                    key={index}
                    color="default"
                    variant={selectedWeekday === index ? "solid" : "outlined"}
                    onClick={() => setSelectedWeekday(index)}
                  >
                    {day}
                  </Button>
                ))}
              </div>
            )}

            {customUnit === "months" && (
              <div className="flex gap-2">
                <Select value={monthOption} onChange={setMonthOption}>
                  <Option value="dayX">Day X of the month</Option>
                  <Option value="firstWeekday">First [weekday] of the month</Option>
                </Select>

                {monthOption === "dayX" ? (
                  <InputNumber min={1} max={31} value={monthDay} onChange={(value) => setMonthDay(value ?? 1)} />
                ) : (
                  <Select value={monthWeekday} onChange={setMonthWeekday}>
                    {daysOfWeek.map(({ label, value }) => (
                      <Option key={value} value={value}>
                        First {label}
                      </Option>
                    ))}
                  </Select>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      {/* <p className="text-sm text-gray-500">Selected frequency code: {cronExpression}</p> */}
    </div>
  );
};

export default CustomCronGenerator;

import { BaseEdge, EdgeLabelRenderer, EdgeProps, getSmoothStepPath } from "@xyflow/react";
import { useWorkflow } from "@/contexts/WorkflowContext";
import { useParams } from "react-router-dom";
import { FiPlus } from "react-icons/fi";

export default function AddPathEdge({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  source,
  target,
  data,
}: EdgeProps) {
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const { actions, setActions, saveWorkflowActions } = useWorkflow();
  const { id } = useParams();

  const addNewPath = async () => {
    const updatedActions = [...actions];

    const sourceIndex = updatedActions.findIndex((act) => act.id === source);
    const sourceAction = updatedActions[sourceIndex];
    const payload = sourceAction.payloadConfiguration || [];
    const pathPayload = payload.find((config: any) => config.payloadStructureId === "path_conditions");

    pathPayload.inputString.push({
      conditions: [],
      name: "Path " + ((pathPayload?.inputString.length || 0) + 1),
    });
    sourceAction.payloadConfiguration = payload;
    sourceAction.nextAction.push({
      actionId: null,
      conditions: [],
      name: "Conditional Path",
    });

    setActions(updatedActions);
    await saveWorkflowActions(id || "");
  };

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={markerEnd}
        style={data?.error ? { stroke: "red", strokeWidth: 2, ...style } : style}
        className={`connection-svg source-${source} target-${target}`}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, 0%) translate(${sourceX}px,${sourceY + (targetY - sourceY) / 6}px)`,
            fontSize: 12,
            pointerEvents: "all",
          }}
          className={`nodrag nopan add-action-button source-${source} target-${target}`}
        >
          <div className="group flex relative">
            {data?.isPathSplitter ? (
              <button
                title="Add a new path"
                onClick={addNewPath}
                className={`focus:outline-none flex z-10 select-none px-3 py-2 font-semibold focus:ring-purple-500 text-purple-800 bg-white hover:bg-purple-300 hover:border-purple-600 rounded-full !h-5 !w-5 items-center justify-center !p-0  ${data?.error ? "border-red-500" : "border-purple-500"} ring-offset-2 focus:ring-2 text-2xl`}
              >
                <FiPlus />
              </button>
            ) : null}
          </div>
        </div>
      </EdgeLabelRenderer>
    </>
  );
}

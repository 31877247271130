import React from "react";
import Spinner from "@/assets/SVGs/Spinner";

type Props = {
  text: string | React.ReactNode;
  onClickCallback: () => Promise<void>;
  error: string;
  disabled: boolean;
  loaderText: string | React.ReactNode;
  secondary?: boolean;
  btnClasses?: string;
  txtClasses?: string;
};

const LoaderButton = ({
  text,
  onClickCallback,
  error,
  disabled,
  loaderText,
  secondary,
  btnClasses,
  txtClasses,
}: Props) => {
  const [loading, setLoading] = React.useState(false);

  const handleClick = async () => {
    setLoading(true);
    await onClickCallback();
    setLoading(false);
  };
  if (secondary) {
    return (
      <button
        className={
          "border-2 border-primary bg-primary/10 rounded-md flex items-center justify-center px-2 py-1 font-medium text-primary" +
          (btnClasses || "")
        }
        type="submit"
        onClick={handleClick}
        disabled={disabled || loading || error?.length !== 0}
        title={error?.length ? error : ""}
      >
        {loading ? (
          <>
            <Spinner color="#5750ff" size="20px" />
            <div className={"font-medium text-md leading-6 " + (txtClasses || "")}>
              {loaderText ? loaderText : `Loading`}
            </div>{" "}
          </>
        ) : (
          <>
            <div className={"font-medium text-md leading-6 " + (txtClasses || "")}>{text}</div>{" "}
          </>
        )}
      </button>
    );
  }
  return (
    <button
      className={
        (error?.length ? "!bg-red-600 !bg-none " : "") +
        (disabled || loading ? "!bg-gray-300 !bg-none " : "") +
        "btn p-2 " +
        (btnClasses || "")
      }
      type="submit"
      onClick={handleClick}
      disabled={disabled || loading || error?.length !== 0}
      title={error?.length ? error : ""}
    >
      {loading ? (
        <>
          <div className={"font-medium leading-6 flex gap-3" + (txtClasses || "")}>
            <Spinner color="#5750ff" size="20px" />
            {loaderText ? loaderText : `Loading`}
          </div>{" "}
        </>
      ) : (
        <>
          <div className={"font-medium leading-6 " + (txtClasses || "")}>{text}</div>{" "}
        </>
      )}
    </button>
  );
};

export default LoaderButton;

import { setStateType } from "../../../utils/constants";
import { useEffect, useState, useCallback } from "react";
import { useWorkflow } from "../../../contexts/WorkflowContext";
import TiptapField from "../Tiptap/TiptapField";
import { Action, PayloadConfiguration } from "../../../utils/interfaces";
import { Alert, ConfigProvider, Drawer, message, Select, Skeleton, Switch } from "antd";
import { FaPlus, FaRegEdit } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { MdChevronLeft, MdChevronRight, MdDeleteOutline } from "react-icons/md";
import RunCondition from "./ModalComponents/RunCondition";
import { useConnections } from "@/contexts/ConnectionContext";
import { useRunOnFloq } from "@/contexts/RunOnFloqContext";
import { IoReload } from "react-icons/io5";
import { getSnowflakeColumns } from "@/utils/apis";

const DATABASE_PAYLOAD_STRUCTURE_ID = "snowflakeDatabase";
const SCHEMA_PAYLOAD_STRUCTURE_ID = "snowflakeSchema";
const WAREHOUSE_PAYLOAD_STRUCTURE_ID = "snowflakeWarehouse";
const ROLE_PAYLOAD_STRUCTURE_ID = "snowflakeRole";
const TABLE_PAYLOAD_STRUCTURE_ID = "snowflakeTable";

type Props = {
  action: Action;
  modal: boolean;
  setModal: setStateType<boolean>;
  payloadConfiguration: PayloadConfiguration[];
  responseConfiguration: any[];
  variables: any[];
};

const SnowflakeInsertModal = ({
  action,
  modal,
  setModal,
  payloadConfiguration,
  responseConfiguration,
  variables,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [columnsLoading, setColumnsLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [columns, setColumns] = useState<any[]>([]);
  const [updateField, setUpdateField] = useState(0);
  const [payloads, setPayloads] = useState<PayloadConfiguration[]>(payloadConfiguration);
  const [responses, setResponses] = useState(responseConfiguration);
  const { collapseConfig, setCollapseConfig } = useRunOnFloq();
  const [drawerWidth, setDrawerWidth] = useState(collapseConfig ? "3rem" : "30%");
  const [runCondition, setRunCondition] = useState(action.runCondition);
  const { updatePayloadConfig, updateResponseConfig, saveWorkflowActions, updateActionName, setPublishWarning } =
    useWorkflow();
  const { getConnectionFromType } = useConnections();
  const [isConnectionExists, setIsConnectionExists] = useState(true);
  const [changeName, setChangeName] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    fetchColumns();
  }, []);

  useEffect(() => {
    setWidthPercent();
    window.addEventListener("resize", setWidthPercent);
    return () => window.removeEventListener("resize", setWidthPercent);
  }, [collapseConfig]);

  const setWidthPercent = useCallback(() => {
    try {
      if (collapseConfig) setDrawerWidth("3rem");
      // Adjust width for different screen sizes
      else if (window.matchMedia("(min-width: 1024px) and (min-height: 1366px)").matches)
        // iPad Pro and similar devices
        setDrawerWidth("50%");
      else if (window.matchMedia("(min-width: 1024px)").matches) setDrawerWidth("30%");
      else if (window.matchMedia("(min-width: 640px)").matches) setDrawerWidth("50%");
      else setDrawerWidth("100%");
    } catch (err) {
      console.error("Error calculating width percent based on media size");
    }
  }, [collapseConfig]);

  useEffect(() => {
    if (!action?.connectionId) return;
    getConnectionFromType(action?.connectionId).then((connection: any) => {
      setIsConnectionExists(connection?.userConnection ? true : false);
    });
  }, [action?.connectionId]);

  useEffect(() => {
    setPayloads(payloadConfiguration);
    setUpdateField((prev) => prev + 1);
  }, [payloadConfiguration]);

  useEffect(() => {
    setRunCondition(action.runCondition);
  }, [action]);

  useEffect(() => {
    setResponses(responseConfiguration);
  }, [responseConfiguration]);

  useEffect(() => {
    if (payloads !== payloadConfiguration) setChanged(true);
    else setChanged(false);
  }, [payloads]);

  const fetchColumns = async () => {
    setColumnsLoading(true);
    const database = payloads?.find((p) => p.payloadStructureId === DATABASE_PAYLOAD_STRUCTURE_ID)?.inputString;
    const schema = payloads?.find((p) => p.payloadStructureId === SCHEMA_PAYLOAD_STRUCTURE_ID)?.inputString;
    const warehouse = payloads?.find((p) => p.payloadStructureId === WAREHOUSE_PAYLOAD_STRUCTURE_ID)?.inputString;
    const role = payloads?.find((p) => p.payloadStructureId === ROLE_PAYLOAD_STRUCTURE_ID)?.inputString;
    const table = payloads?.find((p) => p.payloadStructureId === TABLE_PAYLOAD_STRUCTURE_ID)?.inputString;

    if (!database || !schema || !warehouse || !role || !table) return message.error("Please fill in all fields");
    const columns = await getSnowflakeColumns(table, database, schema, role, warehouse);
    setColumns(columns);
    setColumnsLoading(false);
  };

  const saveOutputStructure = async () => {
    setLoading(true);
    const updated = updatePayloadConfig(payloads, action.id, false, action.continueOnFail, runCondition);
    setPayloads(updated);
    const updatedResponses = updateResponseConfig(responses, action.id);
    setResponses(updatedResponses);
    await saveWorkflowActions(id || "");
    setChanged(false);
    setLoading(false);
    setPublishWarning(true);
  };

  const fieldSwitchCase = (field: any) => {
    switch (field.type) {
      case "dynamicJsonArray":
        return (
          <Skeleton loading={columnsLoading ?? true}>
            {payloads
              .find((inp) => inp.payloadStructureId === field.payloadStructureId)
              ?.inputString.map((input: any, idx: number) => {
                return (
                  <div className="flex flex-col gap-1" key={idx}>
                    <div className="flex flex-row gap-2 items-end justify-between">
                      <Select
                        showSearch
                        optionFilterProp="label"
                        style={{ width: "100%", marginBottom: "0.25rem" }}
                        value={
                          payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString[
                            idx
                          ].label || ""
                        }
                        onChange={(value, options: any) => {
                          const temp = [...payloads];
                          const index = payloads.findIndex(
                            (input) => input.payloadStructureId === field.payloadStructureId
                          );
                          temp[index].inputString[idx].name = value;
                          temp[index].inputString[idx].label = options.label;
                          temp[index].inputString[idx].type = options.ctype;
                          setPayloads(temp);
                        }}
                        options={(columns ?? []).map((value: any) => ({
                          label: value?.label || value.name,
                          value: value?.id || value.name,
                          ctype: value.type,
                        }))}
                      />
                      <button
                        className="hover:bg-primary/20 h-fit w-fit p-2 rounded-md text-primary border-2 bg-primary/10 transition-colors duration-300 ease-in-out"
                        onClick={() => {
                          const temp = [...payloads];
                          const index = payloads.findIndex(
                            (input) => input.payloadStructureId === field.payloadStructureId
                          );
                          temp[index].inputString.splice(idx, 1);
                          setUpdateField((prev) => prev + 1);
                          setPayloads(temp);
                        }}
                      >
                        <MdDeleteOutline size={25} />
                      </button>
                    </div>
                    <TiptapField
                      content={input.tiptapJson || ""}
                      setContent={(tiptapJson: any, value: any) => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        temp[index].inputString[idx].value = value;
                        if (tiptapJson) temp[index].inputString[idx].tiptapJson = tiptapJson;

                        setPayloads(temp);
                      }}
                      refresh={updateField}
                      placeholder={input.name}
                      variables={variables}
                    />
                  </div>
                );
              })}
            <div className="flex flex-row gap-2">
              <button
                className="text-gray-900 font-semibold flex flex-row gap-2 items-center border-2 shadow-sm rounded-sm border-gray-400 w-fit px-3 py-2 text-md"
                // disabled={!boardId}
                title="Add a new field"
                onClick={() => {
                  const temp = [...payloads];
                  const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                  if (index === -1)
                    temp.push({
                      payloadStructureId: field.payloadStructureId,
                      inputString: [
                        {
                          name: "",
                          value: "",
                          type: "json_array",
                        },
                      ],
                    });
                  else
                    temp[index].inputString.push({
                      name: "",
                      value: "",
                      type: "json_array",
                    });
                  setPayloads(temp);
                }}
              >
                <FaPlus />
                Add key-value pair
              </button>
            </div>
          </Skeleton>
        );
      case "dynamicDropdown":
        return (
          <Skeleton loading={columnsLoading ?? true}>
            <Select
              showSearch
              defaultValue={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""
              }
              onChange={(value) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                temp[index].inputString = value;
                setPayloads(temp);
              }}
              optionFilterProp="label"
              options={(columns ?? []).map((value: any) => ({
                label: value.label,
                value: value.name,
              }))}
            />
          </Skeleton>
        );
      default:
        return (
          <textarea
            rows={2}
            className="w-full rounded-lg"
            placeholder={`Enter the ${field.name}`}
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""}
            onChange={(e) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: e.target.value,
                  type: field.type,
                });
              } else temp[index].inputString = e.target.value;
              setPayloads(temp);
            }}
          />
        );
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={modal}
        mask={false}
        onClose={() => setModal(false)}
        width={drawerWidth}
        styles={{
          content: {
            transition: "width 0.3s",
          },
          body: {
            padding: collapseConfig ? "0" : "24px",
          },
        }}
        classNames={{ header: !collapseConfig ? "border-b !border-gray-300" : "!border-none" }}
        className="ml-auto !font-favorit !p-0 border-l border-gray-500"
        getContainer={false}
        footer={
          !collapseConfig && (
            <div className="w-full flex flex-col gap-4 bg-white">
              {!isConnectionExists && (
                <Alert
                  message={
                    <>
                      Please add the required connection to proceed.{" "}
                      <Link to="/connections" target="_blank" rel="noopener noreferrer">
                        <i>Click here</i>
                      </Link>
                    </>
                  }
                  type="warning"
                  showIcon
                />
              )}
              <button
                className={` text-white w-full font-semibold rounded-lg p-2 mt-auto ${
                  loading || !changed || !isConnectionExists
                    ? "cursor-not-allowed bg-gray-500"
                    : "bg-primary cursor-pointer"
                }`}
                onClick={saveOutputStructure}
                disabled={loading || !changed || !isConnectionExists}
              >
                {loading ? "Saving..." : !changed ? "Saved" : "Save"}
              </button>
            </div>
          )
        }
        title={
          !collapseConfig && (
            <div className="flex items-center gap-2">
              <button className="p-0 rounded-sm" onClick={() => setCollapseConfig(true)}>
                <MdChevronRight size={25} />
              </button>
              <img src={action.logo} alt={action.name} className="w-6 h-6" />
              <span
                contentEditable={changeName}
                suppressContentEditableWarning
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.target.blur();
                  }
                }}
                onBlur={async (e) => {
                  if (!e.target.textContent) return;
                  setLoading(true);
                  updateActionName(action.id, e.target.textContent || action.name);
                  setChangeName(false);
                  await saveWorkflowActions(id || "");
                  setChanged(false);
                  setLoading(false);
                  setPublishWarning(true);
                }}
              >
                {action.name}
              </span>
              <button
                onClick={() => {
                  setChangeName(!changeName);
                  const p = document.getElementById("actionName");
                  if (!p) return;
                  setTimeout(function () {
                    const range = document.createRange();
                    const selection = window.getSelection();
                    range.selectNodeContents(p);
                    range.collapse(false); // <-- Set the cursor at the end of the selection
                    selection?.removeAllRanges();
                    selection?.addRange(range);
                    p.focus();
                  }, 0);
                }}
                className={`p-2 rounded-md transition-opacity duration-600 ease-in-out hover:bg-gray-200 ${
                  changeName ? "opacity-50" : "opacity-100"
                }`}
              >
                <FaRegEdit />
              </button>
            </div>
          )
        }
      >
        {collapseConfig ? (
          <button
            className="w-full h-full flex flex-col items-center justify-center p-3 gap-3"
            onClick={() => setCollapseConfig(false)}
          >
            <span className="p-0 border border-gray-300 rounded-sm">
              <MdChevronLeft size={25} />
            </span>
            <p className="[writing-mode:sideways-lr] text-center">CONFIG</p>
          </button>
        ) : (
          <div className="bg-white gap-10 flex flex-col">
            {action.payloadStructure.map((field, idx) => {
              return (
                <div key={idx} className="flex flex-col w-full gap-4 p-4 border border-gray-300 rounded-lg">
                  <div className="flex justify-between">
                    <div>
                      <p className="tracking-primary font-semibold">
                        {field.name.toUpperCase()}
                        {!field.required && (
                          <span className="text-sm font-normal tracking-normal text-gray-400"> (Optional)</span>
                        )}
                      </p>
                      <p className="text-sm font-light">{field.description}</p>
                    </div>
                    {field.type === "dynamicJsonArray" && !loading && (
                      <button className="flex gap-1 items-center" onClick={fetchColumns}>
                        <IoReload size={15} />
                        Fetch Columns
                      </button>
                    )}
                  </div>
                  {field.needVars ? (
                    <TiptapField
                      content={
                        payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.tiptapJson ||
                        ""
                      }
                      setContent={(content: any, text: string) => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        if (index === -1) {
                          temp.push({
                            payloadStructureId: field.payloadStructureId,
                            tiptapJson: content,
                            inputString: text,
                            type: field.type,
                          });
                        } else {
                          temp[index].tiptapJson = content;
                          temp[index].inputString = text;
                        }
                        setPayloads(temp);
                      }}
                      refresh={updateField}
                      placeholder={field.name}
                      variables={variables}
                    />
                  ) : (
                    fieldSwitchCase(field)
                  )}
                </div>
              );
            })}
            <div>
              Continue workflow even if this fails:{" "}
              <Switch
                defaultChecked={action.continueOnFail}
                onChange={(value) => {
                  setChanged(true);
                  action.continueOnFail = value;
                }}
              />
            </div>
            <>
              <RunCondition
                variables={variables}
                responses={action.responseStructure}
                conditionsArr={
                  runCondition || {
                    conditions: [],
                    otherwise: [],
                  }
                }
                setConditionsArr={setRunCondition}
              />
            </>
          </div>
        )}
      </Drawer>
    </ConfigProvider>
  );
};

export default SnowflakeInsertModal;

import { useCallback, useEffect, useRef, useState } from "react";
import {
  ReactFlow,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  BackgroundVariant,
  Edge,
  ReactFlowProvider,
  ConnectionLineType,
  ConnectionMode,
  useReactFlow,
} from "@xyflow/react";

import SendIcon from "/assets/send 1.svg";
import CoinIcon from "/assets/coin.svg";
import { FiRefreshCw } from "react-icons/fi";
import { FaCheck } from "react-icons/fa";

import "@xyflow/react/dist/style.css";
import { ActionNode } from "./ActionNode";
import AddEdge from "./AddEdge";
import InputModal from "@/Components/Workflow/ActionModals/InputModal";
import AddSection from "@/Components/Workflow/ActionModals/AddSection";
import SendingModal from "@/Components/Workflow/ActionModals/SendingModal";
import ActionModal from "@/Components/Workflow/ActionModals/ActionModal";
import HTTPModal from "@/Components/Workflow/ActionModals/HTTPApiModal";
import {
  Condition,
  NextActionConfiguration,
  PayloadConfiguration,
  ResponseConfiguration,
  ResponseStructure,
} from "@/utils/interfaces";
import { useParams } from "react-router-dom";
import { useWorkflow } from "@/contexts/WorkflowContext";
import { sameTimeStamp } from "@/utils/functions";
import { IoWarning } from "react-icons/io5";
import WarningSVG from "@/assets/SVGs/buildTab/WarningSVG";
import dagre from "dagre";
import CrmModal from "../../ActionModals/CrmModal";
import CrmPullModal from "../../ActionModals/CrmPullModal";
import HubspotModal from "../../ActionModals/HubspotModal";
import LoaderButton from "@/Components/LoaderButtonBlack";
import EngagebayModal from "../../ActionModals/EngagebayModal";
import FloqPushModal from "../../ActionModals/FloqPushModal";
import { publishDraft, publishDraftSubfloqToWorkflows, revertDraft, updateWorkflowSettings } from "@/utils/apis";
import CloseCrmModal from "../../ActionModals/closeCrmModal";
import AiActionModal from "../../ActionModals/AiActionModal";
import WaterfallModal from "../../ActionModals/WaterfallModal";
import GoogleSheetModal from "../../ActionModals/GoogleSheetModal";
import AirtableAddDataModal from "../../ActionModals/AirtableAddDataModal";
import { ACTION_TYPES, BRANCH_ACTIONS_TYPES } from "@/utils/constants";
import MondayModal from "../../ActionModals/MondayModal";
import { v4 as uuidv4 } from "uuid";
import AirtableImportModal from "../../ActionModals/AirtableImportModal";
import DynamicCRMpushModal from "../../ActionModals/DynamicCRMpushModal";
import AddNewNodes, { AddNewNodesRef } from "./AddNewNodes";
import { useUser } from "@/contexts/UserContext";
import { FlowBasicsRootNode } from "./FlowBasicsRootNode";
import { PathConditionNode } from "./PathConditionNode";
import AddPathEdge from "./AddPathEdge";
import { FilterNode } from "./FilterNode";
import NewConditionalModal from "../../ActionModals/NewConditionalModal";
import useExpandCollapse from "./useExpandCollapse";
import { ExpandCollapseNode } from "./types";
import { message, Popconfirm } from "antd";
import { InputNode } from "./InputNode";
import { motion } from "framer-motion";
import { MdChevronLeft } from "react-icons/md";
import RunModal from "./RunModal";
import { useRunOnFloq } from "@/contexts/RunOnFloqContext";
import HubspotSearchFilters from "../../ActionModals/HubspotSearchFilters";
import SnowflakeInsertModal from "../../ActionModals/SnowflakeInsertModal";
import SubfloqConfigModal from "../../ActionModals/SubfloqConfigModal";

type Props = {
  firstActionId: string;
  workflow: any;
  subfloq?: boolean;
  currentShift: number;
  shiftHeader: (shift: number) => void;
};

declare global {
  interface Window {
    __splitPathWarning?: boolean;
  }
}

const nodeHeight = window.innerHeight * 0.27;
const nodeWidth = window.innerWidth * 0.45;

function ReactFlowBuild({ firstActionId, workflow, subfloq = false, currentShift, shiftHeader }: Props) {
  const [nodes, setNodes, onNodesChange] = useNodesState<ExpandCollapseNode>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);
  const [modal, setModal] = useState(false);
  const [responseConfiguration, setResponseConfiguration] = useState<ResponseConfiguration[]>([]);
  const [payloadConfiguration, setPayloadConfiguration] = useState<PayloadConfiguration[]>([]);
  const [errorActions, setErrorActions] = useState<string[]>([]);
  const [errorEdges, setErrorEdges] = useState<string[]>([]);
  const [variables, setVariables] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [minMaxCostData, setMinMaxCostData] = useState<any>({});
  const [autoPublish, setAutoPublish] = useState<boolean>(false);

  const [selectionMode, setSelectionMode] = useState<"Shift" | null>("Shift");
  const [selectedNodes, setSelectedNodes] = useState<any[]>([]);
  const [tempSelectedNodes, setTempSelectedNodes] = useState<any[]>([]);
  const [allEdges, setAllEdges] = useState<Edge[]>([]);

  const {
    actions,
    setActions,
    saveWorkflowActions,
    getVariblesForAction,
    getAllWorkflowActionsDetails,
    publishWarning,
    revertWarning,
    setPublishWarning,
    setRevertWarning,
    workflowBuildDetails,
    setWorkflowBuildDetails,
    getWorkflowConfig,
    isSubfloq,
    getCountsData,
    removeCountsDataFetch,
  } = useWorkflow();
  const { id } = useParams();
  const nodeTypes = {
    inputNode: InputNode,
    actionNode: ActionNode,
    flowBasicsRootNode: FlowBasicsRootNode,
    pathConditionNode: PathConditionNode,
    filterNode: FilterNode,
  };
  const edgeTypes = { addEdge: AddEdge, addPathEdge: AddPathEdge };
  const initialPos = { x: 0, y: 0 };
  const [dagreGraph, setDagreGraph] = useState<dagre.graphlib.Graph>(new dagre.graphlib.Graph());
  const addNewNodesRef = useRef<AddNewNodesRef>(null);
  const { collapsed, setCollapsed } = useUser();

  const { nodes: visibleNodes, edges: visibleEdges } = useExpandCollapse(nodes, edges, {
    treeWidth: nodeWidth,
    treeHeight: nodeHeight,
  });

  const NON_CLICKABLE_NODE_TYPES = ["flowBasicsRootNode", "pathConditionNode"];
  const [openRowInRun, setOpenRowInRun] = useState<boolean>(false);
  const { setViewport, getViewport } = useReactFlow();
  const { setContinueRun, setData, openRun, setOpenRun, action, setAction } = useRunOnFloq();
  const [_viewWidth, _setViewWidth] = useState("20vw");
  const [sections, setSections] = useState<any>({});
  const [childSates, setChildSates] = useState<any>({});

  const loadStates = (actionId: string, settersMap: any) => {
    if (!actionId) return;
    const states = childSates?.[actionId];
    if (!states || !settersMap) return;
    const stateKeys = Object.keys(states);
    stateKeys.forEach((key) => {
      const setter = settersMap[key];
      if (setter) setter(states[key]);
      else console.warn(`No setter found for state key: ${key}`);
    });
  };

  const saveSates = (states: any, actionId: string) => {
    if (!actionId) return;
    setChildSates((prev: any) => ({ ...prev, [actionId]: states }));
  };

  useEffect(() => {
    const currentViewport = getViewport();
    if (openRun) {
      // Shift the viewport left when right panel expands
      setViewport({
        x: currentViewport.x - 200, // Adjust as needed
        y: currentViewport.y,
        zoom: currentViewport.zoom,
      });
      shiftHeader(currentShift + 50);
    } else {
      // Reset when collapsing
      setData([]);
      setViewport({
        x: currentViewport.x + 200, // Adjust as needed
        y: currentViewport.y,
        zoom: currentViewport.zoom,
      });
      shiftHeader(0);
    }
  }, [openRun]);

  useEffect(() => {
    const currentViewport = getViewport();
    if (openRowInRun) {
      // Shift the viewport left when right panel expands
      setViewport({
        x: currentViewport.x - 200, // Adjust as needed
        y: currentViewport.y,
        zoom: currentViewport.zoom,
      });
      shiftHeader(currentShift + 50);
    } else {
      // Reset when collapsing
      setViewport({
        x: currentViewport.x + 200, // Adjust as needed
        y: currentViewport.y,
        zoom: currentViewport.zoom,
      });
      currentShift && shiftHeader(currentShift - 50);
    }
  }, [openRowInRun]);

  useEffect(() => {
    if (modal) return;
    setAction(undefined);
    if (openRowInRun) setOpenRowInRun(false);
  }, [modal]);

  useEffect(() => {
    if (!id) return;
    getCountsData(id);
    return () => {
      removeCountsDataFetch();
    };
  }, [id]);

  const NODE_TYPE_FROM_ACTION_TYPE = (type: string) => {
    switch (type) {
      case "path_splitter":
      case "if_else":
        return "flowBasicsRootNode";
      case "path_filter":
        return "filterNode";
      case "input":
        return "inputNode";
      default:
        return "actionNode";
    }
  };

  const duplicateNode = (actionId: string, sourceId: string, targetId: string) =>
    addNewNodesRef.current?.handleAdd(actionId, sourceId, targetId, true);

  const enderNode = (id: string): ExpandCollapseNode => ({
    id: `${id}-end`,
    type: "actionNode",
    position: initialPos,
    data: {
      name: "",
      logo: "",
      type: "",
      expanded: true,
    },
    style: { visibility: "hidden" },
  });
  const newNode = (id: string): ExpandCollapseNode => {
    const act = actions.find((act) => act.id === id);
    return {
      id,
      type: NODE_TYPE_FROM_ACTION_TYPE(act?.actionDetails?.type),
      position: initialPos,
      data: {
        name: act?.displayName || act?.actionDetails?.name || act?.actionName,
        actionName: act?.actionName,
        logo: act?.actionDetails?.logo || "",
        type: act?.actionDetails?.type || "",
        expanded: true,
      },
    };
  };
  const newEdge = (source: any, target: any): Edge => ({
    id: `${source.id}-${target.actionId || `${source.id}-end`}`,
    source: source.id,
    target: target.actionId || `${source.id}-end`,
    data: {
      conditions: target,
      v3: (target?.conditions?.length || 0) === 0,
    },
    type: "addEdge",
    style: { strokeWidth: 2, strokeDasharray: "5, 5", animation: "flowAnimation 1s linear infinite" },
  });

  const pathSplitterNodes = (source: any) => {
    const nodes: any[] = [];
    const edges: any[] = [];
    const childIds: string[] = [];
    const payloads: any[] =
      source?.payloadConfiguration?.find((config: any) => config.payloadStructureId === "path_conditions")
        ?.inputString || [];
    const nextActions = source?.nextAction || [];
    payloads.forEach((payload: any, idx: number) => {
      if (!payload) return;
      const id = `${source.id}-cond-${uuidv4()}`;

      nodes.push({
        id,
        type: "pathConditionNode",
        position: initialPos,
        data: {
          actId: source.id,
          name: payload.name,
          payload: payload,
          idx: idx,
          expanded: true,
        },
      });
      edges.push({
        id: `${source.id}-${id}`,
        source: source.id,
        target: id,
        data: { isPathSplitter: source?.actionDetails?.type === "path_splitter" },
        type: "addPathEdge",
        style: { strokeWidth: 2, strokeDasharray: "5, 5", animation: "flowAnimation 1s linear infinite" },
      });
      const endId = nextActions[idx]?.actionId || `${id}-end`;
      edges.push({
        id: `${id}-${endId}`,
        source: id,
        target: endId,
        data: { conditions: payload, idx: idx, addNotAllowed: false, v3: true },
        type: "addEdge",
        style: { strokeWidth: 2, strokeDasharray: "5, 5", animation: "flowAnimation 1s linear infinite" },
      });
      if (!nextActions[idx]?.actionId)
        nodes.push({
          id: endId,
          type: "actionNode",
          position: initialPos,
          data: {
            name: "",
            logo: "",
            type: "",
            expanded: true,
          },
          style: { visibility: "hidden" },
        });
      else {
        childIds.push(endId);
        nodes.push(newNode(endId));
      }
    });

    return { nodes, edges, childIds };
  };

  useEffect(() => {
    if (!collapsed) setCollapsed(true);
    subfloq = isSubfloq ? isSubfloq : subfloq;
  }, []);

  useEffect(() => {
    if (!actions) return;
    setEdges([]);
    const queue = [firstActionId];
    const initialNodes: ExpandCollapseNode[] = [newNode(firstActionId)];
    const initialEdges: Edge[] = [];
    const hiddenSubfloqIds = new Set<string>(); // Track subfloqs that should be hidden

    setErrorActions([]);
    setErrorEdges([]);
    if (workflowBuildDetails) {
      setRevertWarning(
        !sameTimeStamp(
          workflowBuildDetails?.publishedWorkflowConfig?.lastPublishedAt as string,
          workflowBuildDetails?.draftWorkflowConfig?.lastSavedAt as string
        )
      );
      setPublishWarning(
        !sameTimeStamp(
          workflowBuildDetails?.publishedWorkflowConfig?.lastPublishedAt as string,
          workflowBuildDetails?.draftWorkflowConfig?.lastSavedAt as string
        )
      );
      setWorkflowBuildDetails(null);
    } else {
      setPublishWarning(true);
      setRevertWarning(true);
    }
    let currSection = 0;
    const sectActions: any = {};
    // Create while loop
    while (queue.length > 0) {
      const current = queue.shift();
      if (!current) continue;
      const act = actions.find((act) => act.id === current);
      if (!act) continue;
      if (act.actionDetails.type === "add_section") currSection += 1;
      sectActions[act.id] = currSection;

      if (act && BRANCH_ACTIONS_TYPES.includes(act.actionDetails.type)) {
        const { nodes, edges, childIds } = pathSplitterNodes(act);
        initialNodes.push(...nodes);
        initialEdges.push(...edges);
        queue.push(...childIds);
        continue;
      }
      if (act?.subfloq_id && !act.id.includes("id2") && !isSubfloq) {
        hiddenSubfloqIds.add(act.id);
      }

      act.nextAction.map((child: any) => {
        const childObj = child && typeof child === "object" ? child : { actionId: child };
        if (initialEdges.find((edge) => edge.source === current && edge.target.includes(current))) return;
        initialEdges.push(newEdge(act, childObj));
        if (childObj.actionId) initialNodes.push(newNode(childObj.actionId));
        else if (act.actionDetails?.name !== "Input" || act.responseConfiguration.length > 0)
          initialNodes.push(enderNode(current));
        if (child && !queue.includes(childObj.actionId)) queue.push(childObj.actionId);
      });
    }
    // Filter nodes: Keep only the node with id2 visible for each subfloq

    let { nodes: finalNodes, edges: finalEdges } = getLayoutedElements(initialNodes, initialEdges);

    if (!isSubfloq) {
      finalNodes = initialNodes.filter((node) => {
        return !hiddenSubfloqIds.has(node.id); // Hide all other nodes in subfloq groups
      });
      finalEdges = modifyEdges(initialEdges);
    } else {
      finalNodes = initialNodes;
      finalEdges = initialEdges;
    }

    setSections(sectActions);
    setNodes(finalNodes);
    setEdges(finalEdges);
    setAllEdges(initialEdges);
  }, [actions]);

  const modifyEdges = (edges: any) => {
    const updatedEdges = [...edges];
    const nodesToRemove = new Set();

    // Find all `id2` sources
    const id2Edges = updatedEdges.filter((edge) => edge.source.includes("id2"));

    id2Edges.forEach((id2Edge) => {
      let currentEdge = id2Edge;
      let firstEdge = currentEdge;

      // Traverse until `idout` is found
      while (currentEdge && !currentEdge.source.includes("idout")) {
        if (!currentEdge.source.includes("id2")) nodesToRemove.add(currentEdge.source);
        currentEdge = updatedEdges.find((edge) => edge.source === currentEdge.target);
      }

      if (currentEdge) {
        nodesToRemove.add(currentEdge.source); // Remove `idout`
        const newTarget = currentEdge.target; // Get the target after `idout`

        // Update the `id2` source to point directly to `newTarget`
        updatedEdges.forEach((edge, index) => {
          if (edge.id === firstEdge.id) {
            updatedEdges[index] = {
              ...edge,
              target: newTarget, // Ensure correct target
              // id: `${firstEdge.source}-${newTarget}`, // Ensure correct ID format
            };
          }
        });
      }
    });

    // Remove edges with sources in `nodesToRemove`
    return updatedEdges.filter((edge) => !nodesToRemove.has(edge.source));
  };

  useEffect(() => {
    if (!workflow || !workflow?.publishedWorkflowConfig?.lastPublishedAt || !workflow?.draftWorkflowConfig?.lastSavedAt)
      return;
    setPublishWarning(
      !sameTimeStamp(
        workflow?.publishedWorkflowConfig?.lastPublishedAt as string,
        workflow?.draftWorkflowConfig?.lastSavedAt as string
      )
    );
    setRevertWarning(
      !sameTimeStamp(
        workflow?.publishedWorkflowConfig?.lastPublishedAt as string,
        workflow?.draftWorkflowConfig?.lastSavedAt as string
      )
    );
  }, [workflow]);

  useEffect(() => {
    if (!action) return;
    const responses = getVariblesForAction(action.id, dagreGraph);
    setVariables(responses);
  }, [action]);

  useEffect(() => {
    if (subfloq) return;

    if (id) {
      getWorkflowConfig(id)
        .then((config) => {
          if (config?.workflowDetails?.auto_publish !== undefined)
            !subfloq && setAutoPublish(config.workflowDetails.auto_publish);
          if (config?.workflowDetails?.continue_run !== undefined) setContinueRun(config.workflowDetails.continue_run);
        })
        .catch((error) => {
          console.error("Error fetching workflow config:", error);
        });
    }
  }, [id]);

  class NaryTreeNode {
    value: any;
    children: NaryTreeNode[];
    minCost: number;
    maxCost: number;
    constructor(value: any, minCost: number, maxCost: number) {
      this.value = value;
      this.minCost = minCost;
      this.maxCost = maxCost;
      this.children = [];
    }
  }

  /**
   * @todo: adjust this according to the current "modify waterfall" story
   */
  const getMinMaxCost = (value: any) => {
    const action = actions.find((act) => act.id === value);
    if (!action) return { minCost: 0, maxCost: 0 };
    let minCost = 100;
    let maxCost = -100;
    const credits: any = action.actionDetails.credits;
    if (typeof credits === "number") {
      return { minCost: credits, maxCost: credits };
    }
    if (typeof credits === "object") {
      Object.values(credits).forEach((credit: any) => {
        if (credit < minCost) minCost = credit;
        if (credit > maxCost) maxCost = credit;
      });
    }
    return { minCost, maxCost };
  };

  // Reconstruct the n-ary tree from preorder and postorder
  const constructNaryTree = (preorder: any, postorder: any) => {
    const stack = [];
    const nodeMap = new Map();

    for (let i = 0; i < preorder.length; i++) {
      const value = preorder[i];
      const cost = getMinMaxCost(value);
      const node = new NaryTreeNode(value, cost.minCost, cost.maxCost);
      nodeMap.set(value, node);

      if (stack.length > 0) stack[stack.length - 1].children.push(node);
      stack.push(node);
      // Use postorder to determine when to pop the stack
      while (stack.length > 0 && stack[stack.length - 1].value === postorder[0]) {
        stack.pop();
        postorder.shift(); // Remove the first element
      }
    }
    return nodeMap.get(preorder[0]); // Return the root node
  };

  const findMinAndMaxCostPaths = (root: NaryTreeNode) => {
    // why minCost and maxCost paths are also in the results object?
    // It's so that we can so the user the path that has the min and max cost
    // We can do that via a popup modal in the future.
    const results: any = {
      minCostPath: null,
      maxCostPath: null,
      minCost: 1000, // for now, setting it to thousand instead of [Infinity]
      maxCost: -1000, // same for this one as well
    };
    function dfs(node: NaryTreeNode, currentMinCost: number, currentMaxCost: number, path: any) {
      if (!node) return;
      // Update the path costs with the current node's costs
      currentMinCost += node.minCost;
      currentMaxCost += node.maxCost;
      path.push(node.value);
      // If it's a leaf node, check and update the results
      if (node.children.length === 0) {
        if (currentMinCost < results.minCost) {
          results.minCost = currentMinCost;
          results.minCostPath = [...path];
        }
        if (currentMaxCost > results.maxCost) {
          results.maxCost = currentMaxCost;
          results.maxCostPath = [...path];
        }
      }
      // Recur for all children
      for (let child of node.children) {
        dfs(child, currentMinCost, currentMaxCost, path);
      }
      // Backtrack
      path.pop();
    }
    // calling the recursion here
    dfs(root, 0, 0, []);
    return results;
  };

  const getLayoutedElements = (nodes: ExpandCollapseNode[], edges: Edge[]) => {
    const newDagreGraph = new dagre.graphlib.Graph();
    newDagreGraph.setDefaultEdgeLabel(() => ({}));
    newDagreGraph.setGraph({ rankdir: "TB" });

    nodes.forEach((node) => newDagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight }));
    edges.forEach((edge) => newDagreGraph.setEdge(edge.source, edge.target));

    dagre.layout(newDagreGraph);
    setDagreGraph(newDagreGraph);

    nodes.forEach((node) => {
      const nodeWithPosition = newDagreGraph.node(node.id);
      const valid = checkValidAction(node.id, newDagreGraph);

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
      node.data = {
        ...node.data,
        error: !valid,
        duplicateNode,
        variables: getVariblesForAction(node.id, newDagreGraph),
        hidePath: () => hidePath(node),
        openAction: (id: string) => {
          openAction(id);
          setOpenRun(true);
        },
        closeRunModal: () => {
          setOpenRun(false);
          setOpenRowInRun(false);
        },
      };
      return node;
    });

    edges.forEach((edge) => {
      const vars = getVariblesForAction(edge.target, newDagreGraph);
      const valid = checkValidEdge(edge.id, (edge?.data?.conditions || []) as NextActionConfiguration, vars);
      edge.data = {
        ...edge.data,
        variables: getVariblesForAction(edge.target, newDagreGraph),
        error: !valid,
        style: { stroke: valid ? "#9CA3AF" : "#EF4444", strokeWidth: 2 },
        togglePopover: () => addNewNodesRef.current?.togglePopover(edge),
      };
      return edge;
    });
    const nodeNames: string[] = [];
    nodes.forEach((node) => {
      nodeNames.push(node.id);
    });

    const root = constructNaryTree(
      dagre.graphlib.alg.preorder(newDagreGraph, nodeNames),
      dagre.graphlib.alg.postorder(newDagreGraph, nodeNames)
    );
    const minMaxCost = findMinAndMaxCostPaths(root);
    setMinMaxCostData(minMaxCost);

    return { nodes, edges };
  };

  const checkValidVariable = (variableId: any, variables: any[]) => {
    for (const action of variables) {
      for (const variable of action.variables) {
        if (variable.responseId === variableId) return true;
      }
    }
    return false;
  };

  const checkValidAction = (actionId: string, graph: dagre.graphlib.Graph) => {
    const actionVariables = getVariblesForAction(actionId, graph);
    const action = actions.find((act) => act.id === actionId);
    if (!action) return true;
    if (!action.payloadConfiguration) return true;
    let valid = true;
    action.payloadConfiguration.forEach((config: PayloadConfiguration) => {
      if (typeof config.inputString !== "string") return;
      const vars = config.inputString.match(/{{(.*?)}}/g);
      if (vars) {
        vars.forEach((v: string) => {
          const responseId = v.slice(2, -2);
          if (!checkValidVariable(responseId, actionVariables)) valid = false;
        });
      }
    });
    if (action.subfloq_id) return true;
    if (!valid) setErrorActions((prev) => [...prev, actionId]);
    return valid;
  };

  const checkValidEdge = (id: string, nextActionConfig: NextActionConfiguration, variables: any[]) => {
    let valid = true;
    if (!nextActionConfig.conditions || nextActionConfig.conditions.length === 0) return true;
    nextActionConfig.conditions.forEach((condition: Condition) => {
      if (condition.variable && condition.variable !== "")
        if (!checkValidVariable(condition.variable, variables)) valid = false;
    });
    if (!valid) setErrorEdges((prev) => [...prev, id]);
    return valid;
  };

  const deleteActionFromWorkflow = async (aid: string, type: string) => {
    const tempActions = [...actions];
    const action = tempActions.find((act) => act.id === aid);
    let prevId: any = dagreGraph.predecessors(aid)?.[0];
    if (prevId.includes("id2") && !isSubfloq) prevId = prevId.replace("id2", "idout");
    const nextIds: any = dagreGraph.successors(aid);
    if (!prevId) return;
    if (prevId.includes("-cond")) prevId = prevId.split("-cond")[0];
    const prevIndex = tempActions.findIndex((act) => act.id === prevId || act.fsWfActionId === prevId);
    const idx = tempActions[prevIndex].nextAction.findIndex(
      (next: any) => (next && typeof next === "object" ? next.actionId : next) === aid
    );

    if (idx !== -1) tempActions[prevIndex].nextAction.splice(idx, 1, ...action.nextAction);
    // Remove Duplicates
    tempActions[prevIndex].nextAction = tempActions[prevIndex].nextAction.filter(
      (obj1: any, i: number, arr: any[]) =>
        !obj1.actionId || arr.findIndex((obj2) => obj2.actionId === obj1.actionId) === i
    );
    if (type === "flowBasicsRootNode" && idx !== -1)
      tempActions[prevIndex].nextAction = [
        {
          actionId: null,
          conditions: [],
          name: "Conditional Path",
        },
      ];

    if (nextIds && nextIds.length > 0 && type !== "flowBasicsRootNode") {
      nextIds.forEach((nextId: any) => {
        if (!nextId || nextId.includes("-end")) return;
        const nextIndex = tempActions.findIndex((act) => act.id === nextId);
        if (nextIndex === -1 || !tempActions[nextIndex] || !tempActions[nextIndex].prevAction) return;
        const idx = tempActions[nextIndex].prevAction.findIndex(
          (prev: any) => (prev && typeof prev === "object" ? prev.actionId : prev) === aid
        );
        if (idx !== -1)
          tempActions[nextIndex].prevAction.splice(idx, 1, {
            ...tempActions[nextIndex].prevAction[idx],
            actionId: prevId,
          });

        // Remove Duplicates
        tempActions[nextIndex].prevAction = tempActions[nextIndex].prevAction.filter(
          (obj1: any, i: number, arr: any[]) =>
            !obj1.actionId || arr.findIndex((obj2) => obj2.actionId === obj1.actionId) === i
        );
      });
    }
    setActions(tempActions);
    await saveWorkflowActionsAsync(subfloq);
  };

  const publish = async () => {
    try {
      if (!id) return;
      setLoading(true); // Set loading at start
      setPublishWarning(false);
      setRevertWarning(false);
      await publishDraft(id);
    } catch (err) {
      setPublishWarning(true);
    } finally {
      setLoading(false); // Clear loading in finally block
    }
  };
  const handlePublishSubfloq = async () => {
    const containsOutputNode = actions.find((act) => act.actionName === "OUTPUT_FLOQ_DATA");
    if (!containsOutputNode) {
      message.error("Please add an Output Floq Data action to the Subfloq before publishing.");
      return;
    }
    if (!id) return;
    await publishDraftSubfloqToWorkflows(id);
  };

  const hidePath = useCallback(
    (node: ExpandCollapseNode) => {
      setNodes((nds) =>
        nds.map((n) => {
          if (n.id === node.id) {
            return {
              ...n,
              data: { ...n.data, expanded: !n.data.expanded },
            };
          }

          return n;
        })
      );
    },
    [setNodes]
  );

  const actionModalSwitch = (action: any) => {
    if (action.stepDownAPIs)
      return (
        <WaterfallModal
          action={action}
          setModal={setModal}
          modal={modal}
          responseConfiguration={responseConfiguration}
          payloadConfiguration={payloadConfiguration}
          variables={variables}
        />
      );
    if (action.actionName === "AIRTABLE_IMPORT") {
      return (
        <AirtableImportModal
          action={action}
          modal={modal}
          setModal={setModal}
          variables={variables}
          payloadConfiguration={payloadConfiguration}
          responseConfiguration={responseConfiguration}
        />
      );
    }
    if (action.actionName.includes("HTTP_API"))
      return (
        <HTTPModal
          responseConfiguration={responseConfiguration}
          payloadConfiguration={payloadConfiguration}
          action={action}
          setModal={setModal}
          modal={modal}
          variables={variables}
        />
      );
    if (action.actionName.includes("SNOWFLAKE"))
      return (
        <SnowflakeInsertModal
          responseConfiguration={responseConfiguration}
          payloadConfiguration={payloadConfiguration}
          action={action}
          setModal={setModal}
          modal={modal}
          variables={variables}
        />
      );
    if (action.actionName.includes("ADD_ITEM_TO_MONDAY"))
      return (
        <MondayModal
          responseConfiguration={responseConfiguration}
          payloadConfiguration={payloadConfiguration}
          action={action}
          setModal={setModal}
          modal={modal}
          variables={variables}
        />
      );

    switch (action.type) {
      case "output":
        return (
          <SubfloqConfigModal
            action={action}
            setModal={setModal}
            modal={modal}
            responseConfiguration={responseConfiguration}
            payloadConfiguration={payloadConfiguration}
            variables={variables}
          />
        );
      case "input":
        if (action.actionName.includes("SALESFORCE_PULL_DATA"))
          return (
            <CrmPullModal
              action={action}
              modal={modal}
              setModal={setModal}
              variables={variables}
              payloadConfiguration={payloadConfiguration}
              responseConfiguration={responseConfiguration}
            />
          );
        if (action.actionName.includes("MANUAL_INPUT_SUBFLOQ"))
          return (
            <SubfloqConfigModal
              action={action}
              setModal={setModal}
              modal={modal}
              responseConfiguration={responseConfiguration}
              payloadConfiguration={payloadConfiguration}
              variables={variables}
            />
          );
        return (
          <InputModal
            action={action}
            setModal={setModal}
            modal={modal}
            responseConfiguration={responseConfiguration}
            payloadConfiguration={payloadConfiguration}
          />
        );

      case "add_section":
        return (
          <AddSection
            action={action}
            setModal={setModal}
            modal={modal}
            responseConfiguration={responseConfiguration}
            variables={variables}
            payloadConfiguration={payloadConfiguration}
          />
        );
      case "floq_push":
        return (
          <FloqPushModal
            action={action}
            setModal={setModal}
            modal={modal}
            variables={variables}
            payloadConfiguration={payloadConfiguration}
          />
        );
      case "sending":
        return (
          <SendingModal
            responseConfiguration={responseConfiguration}
            payloadConfiguration={payloadConfiguration}
            action={action}
            setModal={setModal}
            modal={modal}
            variables={variables}
          />
        );
      case "crm":
        return (
          <CrmModal
            payloadConfiguration={payloadConfiguration}
            responseConfiguration={responseConfiguration}
            action={action}
            setModal={setModal}
            modal={modal}
            variables={variables}
            type={action.name.includes("Salesforce") ? "salesforce" : ""}
          />
        );
      case "closeCrm":
        return (
          <CloseCrmModal
            payloadConfiguration={payloadConfiguration}
            responseConfiguration={responseConfiguration}
            action={action}
            setModal={setModal}
            modal={modal}
            variables={variables}
          />
        );
      case "hubspot":
        return (
          <HubspotModal
            payloadConfiguration={payloadConfiguration}
            action={action}
            setModal={setModal}
            modal={modal}
            variables={variables}
          />
        );
      case "googleSheet":
        return (
          <GoogleSheetModal
            payloadConfiguration={payloadConfiguration}
            action={action}
            setModal={setModal}
            modal={modal}
            variables={variables}
          />
        );
      case "airtable":
        return (
          <AirtableAddDataModal
            payloadConfiguration={payloadConfiguration}
            action={action}
            setModal={setModal}
            modal={modal}
            variables={variables}
          />
        );
      case "engagebay":
        return (
          <EngagebayModal
            payloadConfiguration={payloadConfiguration}
            action={action}
            setModal={setModal}
            modal={modal}
            variables={variables}
          />
        );
      case "dynamicCRMpush":
        return (
          <DynamicCRMpushModal
            payloadConfiguration={payloadConfiguration}
            action={action}
            setModal={setModal}
            modal={modal}
            variables={variables}
          />
        );
      case "hubspot_search_filters":
        return (
          <HubspotSearchFilters
            responseConfiguration={responseConfiguration}
            payloadConfiguration={payloadConfiguration}
            action={action}
            setModal={setModal}
            modal={modal}
            variables={variables}
          />
        );
      case "path_filter":
        return (
          <NewConditionalModal
            source={action.id}
            idx={0}
            nextActionConfiguration={
              payloadConfiguration.find((config) => config.payloadStructureId === "path_conditions")
                ?.inputString?.[0] || {
                name: "Filter",
                conditions: [],
              }
            }
            modal={modal}
            setModal={setModal}
            variables={variables}
            nameEditable={false}
          />
        );
      case ACTION_TYPES.AI:
        return (
          <AiActionModal
            responseConfiguration={responseConfiguration}
            payloadConfiguration={payloadConfiguration}
            action={action}
            setModal={setModal}
            modal={modal}
            variables={variables}
          />
        );
      default:
        return (
          <ActionModal
            responseConfiguration={responseConfiguration}
            payloadConfiguration={payloadConfiguration}
            action={action}
            setModal={setModal}
            modal={modal}
            variables={variables}
            loadStates={loadStates}
            saveSates={saveSates}
          />
        );
    }
  };

  const handleUpdateResponseConfig = (
    responseStructure: ResponseStructure[],
    responseConfig: ResponseConfiguration[]
  ) => {
    const newConfig = responseStructure.map((res) => {
      const config = responseConfig.find((rc) => rc.responseStructureId === res.responseStructureId);
      if (config) return config;
      else
        return {
          responseId: uuidv4().toString(),
          responseStructureId: res.responseStructureId || "",
        };
    });
    setResponseConfiguration(newConfig as ResponseConfiguration[]);
  };

  const onConnect = useCallback(
    (params: any) => {
      const sourceIndex = actions.findIndex((act) => act.id === params.source);
      const targetIndex = actions.findIndex((act) => act.id === params.target);

      // Update nextAction for the source action
      actions[sourceIndex].nextAction = actions[sourceIndex].nextAction
        .map((next: any) =>
          (next && typeof next === "object" ? next.actionId : next) === null
            ? {
                actionId: params.target,
                conditions: next?.conditions || [],
                name: next?.name || "",
              }
            : next
        )
        // Remove duplicates
        .filter(
          (obj1: any, i: number, arr: any[]) =>
            !obj1.actionId || arr.findIndex((obj2) => obj2.actionId === obj1.actionId) === i
        );

      // Update prevAction for the target action
      if (targetIndex !== -1) {
        actions[targetIndex].prevAction = actions[targetIndex].prevAction || [];
        actions[targetIndex].prevAction = [
          ...actions[targetIndex].prevAction,
          {
            actionId: params.source,
            conditions: [],
            name: "Conditional Path",
          },
        ]
          // Remove duplicates
          .filter(
            (obj1: any, i: number, arr: any[]) =>
              !obj1.actionId || arr.findIndex((obj2) => obj2.actionId === obj1.actionId) === i
          );
      }

      setActions([...actions]);
      saveWorkflowActionsAsync(subfloq);
      setEdges((eds) => addEdge(params, eds));
    },
    [actions]
  );

  const saveWorkflowActionsAsync = useCallback(
    async (subfloq: boolean) => {
      if (!id) return;
      try {
        // setLoading(true); // Set loading before saving
        await saveWorkflowActions(id, subfloq);
      } catch (error) {
        console.error("Error saving workflow actions:", error);
      } finally {
        setLoading(false); // Clear loading when done
      }
    },
    [id]
  );

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const buildAction = (act: any) => {
    return {
      ...act.actionDetails,
      actionName: act.actionName,
      name: act.displayName || act.actionDetails.name,
      id: act.id,
      reviewNeeded: act.reviewNeeded || false,
      continueOnFail: act.continueOnFail || false,
      ping: act.ping || undefined,
      subfloq_id: act.subfloq_id || undefined,
      runCondition: act.runCondition || {
        conditions: [],
        otherwise: [],
      },
    };
  };

  const openAction = (actId: string) => {
    const act = actions.find((a) => a.id === actId);
    setModal(false);
    setOpenRowInRun(false);
    setAction(buildAction(act));
    // set new response configuration for sending actions with no output
    const responseStructure = act.actionDetails.responseStructure || [];
    const responseConfig = act.responseConfiguration || [];
    if (
      !act.actionDetails.responseStructure?.length ||
      act.actionDetails.outputStructureType === "userDefined" ||
      act.actionDetails.responseStructure?.length === act.responseConfiguration?.length
    )
      setResponseConfiguration(act.responseConfiguration || []);
    else handleUpdateResponseConfig(responseStructure, responseConfig);
    setPayloadConfiguration(act.payloadConfiguration || []);
    setModal(true);
  };

  return (
    <div className="w-full h-full bg-white flex">
      <div className="w-full h-full bg-white relative flex-grow build-wrapper" onDragOver={handleDragOver}>
        <ReactFlow
          nodes={visibleNodes}
          edges={visibleEdges}
          panOnScroll
          zoomOnScroll={false}
          zoomOnDoubleClick={false}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onNodeClick={(_evt, node) => {
            if (!selectionMode) {
              message.error({
                content: "You're in selection mode, please exit to open/configure the actions",
                duration: 6,
              });
              return;
            }

            if (node && NON_CLICKABLE_NODE_TYPES.includes(node?.type || "")) return;
            openAction(node.id);
          }}
          onNodesDelete={(deletedNodes) => {
            deletedNodes.forEach((node) => deleteActionFromWorkflow(node.id, node.type as string));
          }}
          fitView
          connectionLineType={ConnectionLineType.Straight}
          onConnect={onConnect}
          nodesDraggable={true}
          connectionMode={ConnectionMode.Strict}
          onSelectionChange={(params) => {
            if (selectionMode === "Shift") return;
            setTempSelectedNodes((prev) => {
              // Prevent unnecessary re-renders by checking if the selection is unchanged
              if (JSON.stringify(prev) === JSON.stringify(params.nodes)) return prev;
              return params.nodes;
            });
          }}
          selectionOnDrag={selectionMode === "Shift" ? false : true}
          onSelectionEnd={() => setSelectedNodes(tempSelectedNodes)}
          panOnDrag={selectionMode === "Shift" ? true : false}
        >
          <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
        </ReactFlow>

        {modal && action && actionModalSwitch(action)}

        <AddNewNodes
          // @ts-ignore
          edges={edges}
          ref={addNewNodesRef}
          className={`absolute top-24 ${collapsed ? "left-[1.75rem]" : "left-[1.75rem]"}`}
          setMode={(mode: "Shift" | null) => setSelectionMode(mode)}
          mode={selectionMode}
          subfloq={subfloq}
          allEdges={allEdges}
          selectedNodes={selectedNodes}
        />

        <div className="">
          <div className="w-[39.5rem] translate-x-[-0.5rem] absolute bottom-[6.5rem] left-12">
            {errorActions.length > 0 ? (
              <section className="w-full flex px-2 py-1 rounded-md bg-red-200 justify-start items-center text-red-600 gap-2">
                <IoWarning size={25} />
                <span className="text-sm">Some actions have invalid data, please fix them before publishing</span>
              </section>
            ) : errorEdges?.length > 0 ? (
              <section className="w-full flex px-2 py-1 rounded-md bg-red-200 justify-start items-center text-red-600 gap-2">
                <IoWarning size={25} />
                <span className="text-sm">Some conditions have invalid data, please fix them before publishing</span>
              </section>
            ) : (
              publishWarning &&
              !autoPublish &&
              !isSubfloq && (
                <section className="w-full flex px-2 py-1 rounded-md bg-[#fdf6d6] justify-start items-start text-[#777777] gap-2">
                  <WarningSVG size="20px" />
                  <span className="text-sm">
                    You have made changes to your workflow actions, please make sure to publish new changes before
                    running to reflect changes.
                  </span>
                </section>
              )
            )}
          </div>
          {subfloq ? (
            <div
              className="flex absolute bottom-8 right-2 w-fit border rounded-lg shadow-[0px_0px_0px_3px_rgba(224,224,224,0.46)] px-4 py-2 bg-gray-50"
              style={{ scale: "0.9" }}
            >
              <Popconfirm
                title="Are you sure you want to publish this subfloq?"
                description="This will publish the subfloq to all workflows that use it. This can remove your existing data in the workflow as well."
                onConfirm={handlePublishSubfloq}
                // onCancel={cancel}
                placement="left"
                okText="Yes"
                cancelText="No"
              >
                <button
                  className={`flex gap-2 disabled:cursor-not-allowed disabled:hover:bg-none py-2 px-4 rounded-[5px] transition font-semibold bg-[#333333] text-white ${
                    loading ? "cursor-not-allowed" : "cursor-pointer"
                  }`}
                  disabled={loading || errorActions.length > 0}
                >
                  {loading ? "Publishing..." : "Publish Subfloq"}
                  <span>
                    <img src={SendIcon} />
                  </span>
                </button>
              </Popconfirm>
            </div>
          ) : (
            <div
              className="flex absolute bottom-8 left-2 w-fit border rounded-lg shadow-[0px_0px_0px_3px_rgba(224,224,224,0.46)] px-4 py-2 bg-gray-50"
              style={{ scale: "0.9" }}
            >
              <div className="flex items-center min-w-fit gap-3">
                <img src={CoinIcon} />
                <div>
                  <span className="font-extralight">Minimum credits per lead: </span>
                  <span className="font-semibold">{minMaxCostData.minCost || 0 + minMaxCostData.maxCost || 0 / 2}</span>
                </div>
              </div>
              <div className="w-[2px] h-[45px] bg-[#DDDDDD] mx-4" style={{ scale: "1.1" }} />
              <section className="flex w-full items-center gap-2">
                <button
                  className={`flex gap-2 py-2 px-4 border rounded-[5px] font-extralight text-[#333333] 
                ${autoPublish ? "bg-[#C2FFC7] border-[#62825D]" : "bg-white border-[#333333]"}
              `}
                  onClick={async () => {
                    try {
                      setAutoPublish(!autoPublish);
                      await updateWorkflowSettings(id || "", {
                        auto_publish: !autoPublish,
                      });
                    } catch (error) {
                      console.error("Error updating auto-publish setting:", error);
                    }
                  }}
                >
                  <span className="translate-y-[3px]">
                    {autoPublish ? <FaCheck className="text-[#62825D]" /> : <FiRefreshCw />}
                  </span>
                  Auto-publish
                </button>
                <LoaderButton
                  disabled={!revertWarning}
                  error=""
                  loaderText="Reverting..."
                  text="Revert"
                  onClickCallback={async () => {
                    try {
                      if (!id) return;
                      setPublishWarning(false);
                      await revertDraft(id);
                      await getAllWorkflowActionsDetails(id);
                    } catch (err) {
                      setPublishWarning(true);
                    }
                  }}
                  btnClasses="w-fit text-black bg-white px-4 py-2 border border-black rounded-[5px]"
                  txtClasses={` ${revertWarning ? "text-gray-900" : "text-[#cccccc]"}`}
                  IconComponent={RevertIcon}
                  iconColor={revertWarning ? "black" : "#cccccc"}
                />
                <button
                  className={`flex gap-2 disabled:cursor-not-allowed disabled:hover:bg-none py-2 px-4 rounded-[5px] transition font-semibold bg-[#333333] text-white ${
                    loading ? "cursor-not-allowed" : "cursor-pointer"
                  }`}
                  onClick={publish}
                  disabled={loading || errorActions.length > 0}
                >
                  {loading ? "Publishing..." : "Publish"}
                  <span>
                    <img src={SendIcon} />
                  </span>
                </button>
              </section>
            </div>
          )}
        </div>
      </div>
      {!isSubfloq && action && modal && action.actionName !== "ADD_SECTION_TO_FLOQ" && !action.id.includes("id2") && (
        <motion.div
          animate={{
            width: openRun && openRowInRun ? "36vw" : openRun ? "20vw" : "3rem",
          }}
          transition={{
            duration: 0.15,
            ease: "easeInOut",
          }}
          className="h-full bg-white flex-shrink-0 z-[1000]"
        >
          {openRun ? (
            <RunModal
              action={action}
              responseConfiguration={responseConfiguration}
              payloadConfiguration={payloadConfiguration}
              close={() => {
                setOpenRun(false);
                setOpenRowInRun(false);
              }}
              openRow={openRowInRun}
              setOpenRow={setOpenRowInRun}
              sections={sections}
            />
          ) : (
            <button
              className="w-full h-full flex flex-col items-center justify-center p-3 gap-3 border-l border-gray-500"
              onClick={() => setOpenRun(true)}
            >
              <span className="p-0 border border-gray-300 rounded-sm">
                <MdChevronLeft size={25} />
              </span>
              <p className="[writing-mode:sideways-lr] text-center">RUN</p>
            </button>
          )}
        </motion.div>
      )}
    </div>
  );
}

export const RevertIcon = ({ color = "black" }: { color?: string }) => (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.208984 11.6243L6.45898 17.8744L7.93814 16.3952L4.19852 12.666H15.834V0.166016H13.7506V10.5827H4.19852L7.93814 6.85351L6.45898 5.37434L0.208984 11.6243Z"
      fill={color}
    />
  </svg>
);

export default (props: Props) => (
  <ReactFlowProvider initialHeight={100}>
    <ReactFlowBuild {...props} />
  </ReactFlowProvider>
);

import { Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { LOADER_TYPES } from "./utils/constants";
import {
  GoogleRedirect,
  HubSpotRedirect,
  Login,
  Payments,
  ProfileSetup,
  SignUpOrg,
  Pricings,
  SalesForceRedirect,
  GoHiLevelRedirect,
  ZohoCRMRedirect,
} from "./pages/index.ts";
import PrivateRoutes from "./utils/PrivateRoutes";
import { useUser } from "./contexts/UserContext";
import Loader from "./Components/Loader";
import SlackRedirect from "./pages/Auth/SlackRedirect.tsx";
import CreditsNew from "./pages/CreditsNew.tsx";
import "@radix-ui/themes/styles.css";
import AirtableRedirect from "./pages/Auth/AirtableRedirect";
import { ConfigProvider } from "antd";
import { MainSidebar } from "./Components/NavbarNew/index.tsx";
import Workflows from "./Components/UserWorkflows/index.tsx";
import Connections from "./Components/Connections/index.tsx";
import Settings from "./Components/Settings/index.tsx";
import Workflow from "./Components/Workflow/index.tsx";
import Lists from "./Components/Lists/index.tsx";
import ListPage from "./Components/Lists/Pages/List.tsx";
import Subfloq from "./Components/UserSubfloqs/Subfloq/index.tsx";
import UserSubfloqs from "./Components/UserSubfloqs/index.tsx";

function App() {
  const { currentUser, userLoad, checkProfileAndPayment } = useUser();
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(false);
  const paymentPending = false;
  const [refresh, setRefresh] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {
        const res = await checkProfileAndPayment();
        setProfile(res.profile);
        if (!res.profile) setLoading(false);
      } else if (!userLoad) {
        setLoading(false);
      }
    };

    setLoading(true);
    fetchData();
  }, [currentUser, refresh, userLoad]);

  useEffect(() => {
    if (profile) setLoading(false);
  }, [profile]);

  if (userLoad || loading) {
    return (
      <div className="flex h-screen w-screen justify-center items-center">
        <Loader payload={"floqer"} loaderType={LOADER_TYPES.loading} />
      </div>
    );
  }
  return (
    <Routes>
      <Route element={<PrivateRoutes profile={profile} paymentPending={paymentPending} />}>
        <Route path="/settings" element={<Settings />} />
        <Route path="/workflows" element={<Workflows />} />
        <Route path="/subfloqs" element={<UserSubfloqs />} />
        <Route path="/workflow/:id" element={<Workflow />} />
        <Route path="/subfloqs/:id" element={<Subfloq />} />
        <Route path="/connections" element={<Connections />} />
        <Route path="/credits" element={<CreditsNew />} />
        <Route path="/intent-signals" element={<Lists />} />
        <Route path="/crm" element={<Lists />} />
        <Route path="/list-building" element={<Lists />} />
        <Route path="/list/:id" element={<ListPage />} />
      </Route>
      <Route path="/gauth" element={<GoogleRedirect />} />
      <Route path="/hubspotAuth" element={<HubSpotRedirect />} />
      <Route path="/goHiLevelAuth" element={<GoHiLevelRedirect />} />
      <Route path="/oauth2/callback" element={<SalesForceRedirect />} />
      <Route path="/slackAuth" element={<SlackRedirect />} />
      <Route path="/airtableAuth" element={<AirtableRedirect />} />
      <Route path="/zohoCRMauth" element={<ZohoCRMRedirect />} />
      <Route
        path="/setup"
        element={
          !currentUser ? (
            <Navigate to="/" />
          ) : !profile ? (
            <ProfileSetup setRefresh={setRefresh} />
          ) : paymentPending ? (
            <Navigate to="/pricing" />
          ) : (
            <Navigate to="/workflows" />
          )
        }
      />
      <Route path="/org" element={<SignUpOrg />} />
      <Route path="/sidebar" element={<MainSidebar />} />
      <Route path="/pricings" element={<Pricings />} />
      <Route
        path="/pricing"
        element={
          !currentUser ? (
            <Navigate to="/" />
          ) : !profile ? (
            <Navigate to="/setup" />
          ) : paymentPending ? (
            <Payments />
          ) : (
            <Navigate to="/workflows" />
          )
        }
      />
      <Route
        path="/"
        element={
          !currentUser ? (
            <ConfigProvider
              theme={{
                token: {
                  fontFamily: "TWK-Lausanne",
                },
              }}
            >
              <Login />
            </ConfigProvider>
          ) : !profile ? (
            <Navigate to="/setup" />
          ) : paymentPending ? (
            <Navigate to="/pricing" />
          ) : (
            <Navigate to="/workflows" />
          )
        }
      />
    </Routes>
  );
}

export default App;

export const HB_SUBSCRIPTION_PROPERTIES = [
  {
    name: "hs_content_membership_notes",
    label: "Membership Notes",
  },
  {
    name: "hs_content_membership_status",
    label: "Contact Activity Status",
  },
  {
    name: "closedate",
    label: "Deal Close Date",
  },
  {
    name: "hs_lead_status",
    label: "Lead Status",
  },
  {
    name: "hubspot_owner_id",
    label: "Contact Owner",
  },
  {
    name: "owneremail",
    label: "Hubspot Owner Email(Legacy)",
  },
  {
    name: "ownername",
    label: "Hubspot Owner Name(Legacy)",
  },
  {
    name: "email",
    label: "Contact Email",
  },
  {
    name: "hs_content_membership_email",
    label: "Membership Email",
  },
  {
    name: "hs_journey_stage",
    label: "Contact Journey Stage",
  },
  {
    name: "jobtitle",
    label: "Contact Job Title",
  },
  {
    name: "lifecyclestage",
    label: "Contact Lifecycle Stage",
  },
  {
    name: "company",
    label: "Company Name",
  },
  {
    name: "contact.creation",
    label: "A new contact is added to HubSpot",
  },
];

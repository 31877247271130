import { setStateType } from "../../../utils/constants";
import { useEffect, useState, useMemo, useCallback } from "react";
import { useWorkflow } from "../../../contexts/WorkflowContext";
import TiptapField from "../Tiptap/TiptapField";
import { Action, PayloadConfiguration } from "../../../utils/interfaces";
import { Alert, ConfigProvider, Drawer, Select, Skeleton, Switch } from "antd";
import { FaPlus, FaRegEdit } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { RiExpandUpDownLine } from "react-icons/ri";
import { MdChevronLeft, MdChevronRight, MdDeleteOutline } from "react-icons/md";
import { useConnections } from "@/contexts/ConnectionContext";
import RunCondition from "./ModalComponents/RunCondition";
import { getCampaigns, getCampaignsFields } from "@/utils/apis";
import { useRunOnFloq } from "@/contexts/RunOnFloqContext";

type Props = {
  action: Action;
  modal: boolean;
  setModal: setStateType<boolean>;
  payloadConfiguration: PayloadConfiguration[];
  variables: any[];
};

const formatZohoCriteria = (criteria: any[]) => {
  if (!criteria?.length) return "";

  const formattedCriteria = criteria
    .map((c) => {
      if (!c.field || !c.operator || !c.value) return "";

      // Escape special characters in value
      let value = c.value.replace(/[,()]/g, "\\$&");

      // Handle 'in' operator differently
      if (c.operator === "in") {
        value = value
          .split(",")
          .map((v: string) => v.trim())
          .join(",");
      }

      return `(${c.field}:${c.operator}:${value})`;
    })
    .filter(Boolean);

  if (!formattedCriteria.length) return "";

  // Join with AND/OR based on conjunction
  const joinedCriteria = formattedCriteria.reduce((acc: string, curr: string, idx: number) => {
    if (idx === 0) return curr;
    const conjunction = criteria[idx - 1].conjunction || "and";
    return `${acc}${conjunction}${curr}`;
  }, "");

  return `(${joinedCriteria})`;
};

// this modal is usefull when there is 1 main dynamic dropdown, and we need to fetch the fields based ont he valuse selected from that dropdown. i.e. both values are dynamic, the secod reiles on the selection of the first one
const DynamicCRMpushModal = ({ action, modal, setModal, payloadConfiguration, variables }: Props) => {
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [campaignsLoading, setCampaignsLoading] = useState<Record<string, boolean>>({});
  const [campaigns, setCampaigns] = useState<Record<string, any[]>>({});
  const [selected, setSelected] = useState<boolean>(false);
  const [updateField, setUpdateField] = useState(0);
  const [payloads, setPayloads] = useState<PayloadConfiguration[]>(payloadConfiguration);
  const [runCondition, setRunCondition] = useState<any>(action.runCondition);
  const { updatePayloadConfig, saveWorkflowActions, updateActionName, setPublishWarning } = useWorkflow();
  const { getConnectionFromType } = useConnections();
  const [isConnectionExists, setIsConnectionExists] = useState(false);
  const [changeName, setChangeName] = useState(false);
  const { collapseConfig, setCollapseConfig } = useRunOnFloq();
  const [drawerWidth, setDrawerWidth] = useState(collapseConfig ? "3rem" : "30%");
  const { id } = useParams();
  const types = useMemo<string[]>(
    () =>
      action.payloadStructure.filter((field) => field.type === "dynamicDropdown").map((field) => field.valuesId ?? ""),
    [action.payloadStructure]
  );
  // Add new state to track search type
  const [searchType, setSearchType] = useState<string>(
    payloadConfiguration.find((p) => p.payloadStructureId === "search_type_id")?.inputString || ""
  );

  useEffect(() => {
    setWidthPercent();
    window.addEventListener("resize", setWidthPercent);
    return () => window.removeEventListener("resize", setWidthPercent);
  }, [collapseConfig]);

  const setWidthPercent = useCallback(() => {
    try {
      if (collapseConfig) setDrawerWidth("3rem");
      // Adjust width for different screen sizes
      else if (window.matchMedia("(min-width: 1024px) and (min-height: 1366px)").matches)
        // iPad Pro and similar devices
        setDrawerWidth("50%");
      else if (window.matchMedia("(min-width: 1024px)").matches) setDrawerWidth("30%");
      else if (window.matchMedia("(min-width: 640px)").matches) setDrawerWidth("50%");
      else setDrawerWidth("100%");
    } catch (err) {
      console.error("Error calculating width percent based on media size");
    }
  }, [collapseConfig]);

  // First useEffect to load modules
  useEffect(() => {
    if (types.length === 0) return;
    types.forEach((type) => {
      setCampaignsLoading((prev) => ({ ...prev, [type]: true }));
      getCampaigns(type)
        .then((data: any[]) => {
          setCampaigns((prev) => ({ ...prev, [type]: data }));
        })
        .catch((error) => {
          console.log("Error loading data for type:", type, error);
          setCampaigns((prev) => ({ ...prev, [type]: [] }));
        })
        .finally(() => {
          setCampaignsLoading((prev) => ({ ...prev, [type]: false }));
        });
    });
  }, [types]);

  // Second useEffect to load fields when module is selected
  useEffect(() => {
    const selectedObjectValue = payloads.find(
      (input) =>
        input.payloadStructureId ===
        action.payloadStructure.find((field) => field.type === "dynamicDropdown")?.payloadStructureId
    )?.inputString;

    // Get the appropriate fieldId based on the search type
    let fieldId;
    if (searchType === "criteria")
      fieldId = action.payloadStructure.find((field) => field.type === "criteriaArray")?.valuesId;
    else fieldId = action.payloadStructure.find((field) => field.type === "jsonArrayWithDropDown")?.valuesId;

    if (selectedObjectValue && fieldId) {
      setCampaignsLoading((prev) => ({ ...prev, [fieldId]: true }));
      getCampaignsFields(fieldId, selectedObjectValue)
        .then((fields) => {
          setCampaigns((prev) => ({ ...prev, [fieldId]: fields }));
        })
        .catch((error) => {
          console.log(error);
          setCampaigns((prev) => ({ ...prev, [fieldId]: [] }));
        })
        .finally(() => {
          setCampaignsLoading((prev) => ({ ...prev, [fieldId]: false }));
        });
    }
  }, [selected, searchType]);

  useEffect(() => {
    setPayloads(payloadConfiguration);
    setUpdateField((prev) => prev + 1);
  }, [payloadConfiguration]);

  useEffect(() => {
    if (payloads !== payloadConfiguration || runCondition !== action.runCondition) setChanged(true);
    else setChanged(false);
  }, [payloads, runCondition]);

  useEffect(() => {
    if (!action?.connectionId) return;
    getConnectionFromType(action?.connectionId).then((connection: any) => {
      setIsConnectionExists(connection?.userConnection ? true : false);
    });
  }, [action?.connectionId]);

  const saveOutputStructure = async () => {
    setLoading(true);
    const updated = updatePayloadConfig(payloads, action.id, false, action.continueOnFail, runCondition);
    setPayloads(updated);
    await saveWorkflowActions(id || "");
    setLoading(false);
    setPublishWarning(true);
  };

  const fieldSwitchCase = (field: any) => {
    switch (field.type) {
      case "dynamicDropdown":
        return (
          <Skeleton
            loading={campaignsLoading[field.valuesId] ?? true}
            paragraph={false}
            active={campaignsLoading[field.valuesId] ?? true}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""}
              onChange={(value) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                if (index === -1) {
                  temp.push({
                    payloadStructureId: field.payloadStructureId,
                    inputString: value,
                    type: field.type,
                  });
                } else {
                  temp[index].inputString = value;
                }
                setPayloads(temp);
                setSelected((prev) => !prev);
              }}
              options={(campaigns[field.valuesId] ?? []).map((option: any) => ({
                label: option.label,
                value: option.id,
              }))}
            />
          </Skeleton>
        );
      case "dropdown":
        return (
          <Select
            suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
            value={
              payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString ||
              field.values.find((value: any) => value?.default)?.id ||
              field.values.find((value: any) => value?.default)?.name ||
              ""
            }
            showSearch
            optionFilterProp="name"
            onChange={(value) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field?.payloadStructureId);
              if (index === -1)
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: value,
                });
              else temp[index].inputString = value;

              // Update search type when changed
              if (field.payloadStructureId === "search_type_id") setSearchType(value);

              setPayloads(temp);
            }}
            options={(field.values ?? []).map((value: any) => ({
              label: (
                <div className="flex justify-between items-center gap-2 p-1 w-full">
                  <div className="flex items-center gap-2 justify-end text-ellipsis">
                    {value?.logo && <img src={value?.logo} alt={value?.name} className="w-6 h-6" />}
                    <p>{value?.name}</p>
                  </div>
                </div>
              ),
              value: value?.id || value.name,
              name: value?.name,
            }))}
          />
        );
      case "jsonArrayWithDropDown":
        return (
          <div className="w-full flex flex-col gap-4">
            {(payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || []).map(
              (_: any, idx: number) => {
                return (
                  <div className="flex gap-2 justify-between">
                    <div className="w-full" key={idx}>
                      <Skeleton
                        loading={campaignsLoading[field.valuesId] ?? true}
                        paragraph={false}
                        active={campaignsLoading[field.valuesId] ?? true}
                        style={{ marginBottom: "0.25rem" }}
                      >
                        <Select
                          showSearch
                          style={{ width: "100%", marginBottom: "0.25rem" }}
                          value={
                            payloads.find((input) => input.payloadStructureId === field.payloadStructureId)
                              ?.inputString[idx]?.label || ""
                          }
                          onChange={(value, options: any) => {
                            const temp = [...payloads];
                            const index = payloads.findIndex(
                              (input) => input.payloadStructureId === field.payloadStructureId
                            );
                            temp[index].inputString[idx].id = value;
                            temp[index].inputString[idx].label = options.label;
                            temp[index].inputString[idx].multi = options.active;
                            temp[index].inputString[idx].inputType = options.inputType;

                            setCampaignsLoading((prev) => ({ ...prev, [field?.valuesId]: false }));
                            setPayloads(temp);
                          }}
                          options={(campaigns[field.valuesId] ?? []).map((field: any) => ({
                            label: field?.name,
                            value: field?.id,
                            active: field?.accepts_multiple_values,
                            inputType: field?.type || field?.dataType,
                          }))}
                        />
                      </Skeleton>
                      <TiptapField
                        content={
                          payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString[
                            idx
                          ]?.tiptapJson || ""
                        }
                        setContent={(content: any, text: string) => {
                          const temp = [...payloads];
                          const index = payloads.findIndex(
                            (input) => input.payloadStructureId === field.payloadStructureId
                          );
                          temp[index].inputString[idx].value = text;
                          temp[index].inputString[idx].tiptapJson = content;
                          setPayloads(temp);
                        }}
                        refresh={updateField}
                        placeholder="Enter the value"
                        variables={variables}
                      />
                    </div>
                    <button
                      className="hover:bg-primary/10 h-fit w-fit p-2 rounded-full text-primary"
                      onClick={() => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        temp[index].inputString.splice(idx, 1);
                        setUpdateField((prev) => prev + 1);
                        setPayloads(temp);
                      }}
                    >
                      <MdDeleteOutline size={25} />
                    </button>
                  </div>
                );
              }
            )}
            <button
              className="text-primary flex gap-2 items-center"
              onClick={() => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                temp[index].inputString.push({
                  name: "",
                  value: "",
                });
                setPayloads(temp);
              }}
            >
              <FaPlus />
              Add a new Variable Name and Variable Value pair
            </button>
          </div>
        );
      case "criteriaArray":
        return (
          <div className="w-full flex flex-col gap-4">
            {(payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || []).map(
              (criteria: any, idx: number) => (
                <div key={idx}>
                  <div className="flex gap-2 justify-between">
                    <div className="w-full">
                      <div className="flex gap-2 mb-2">
                        <Select
                          showSearch
                          style={{ width: "40%" }}
                          value={criteria.field || ""}
                          loading={campaignsLoading[field.valuesId]}
                          onChange={(value, option: any) => {
                            const temp = [...payloads];
                            const index = payloads.findIndex(
                              (input) => input.payloadStructureId === field.payloadStructureId
                            );
                            temp[index].inputString[idx].field = value;
                            temp[index].inputString[idx].fieldType = option.fieldType;
                            setPayloads(temp);
                          }}
                          options={(campaigns[field.valuesId] ?? []).map((f: any) => ({
                            label: f.name,
                            value: f.api_name || f.id,
                            fieldType: f.type || f.dataType,
                          }))}
                        />

                        <Select
                          style={{ width: "30%" }}
                          value={criteria.operator || ""}
                          onChange={(value) => {
                            const temp = [...payloads];
                            const index = payloads.findIndex(
                              (input) => input.payloadStructureId === field.payloadStructureId
                            );
                            temp[index].inputString[idx].operator = value;
                            setPayloads(temp);
                          }}
                          options={
                            (field.operators[criteria.fieldType?.toLowerCase()] || field.operators.text)?.map(
                              (op: string) => ({
                                label: op.replace(/_/g, " "),
                                value: op,
                              })
                            ) || []
                          }
                        />
                      </div>

                      <TiptapField
                        content={criteria.tiptapJson || ""}
                        setContent={(content: any, text: string) => {
                          const temp = [...payloads];
                          const index = payloads.findIndex(
                            (input) => input.payloadStructureId === field.payloadStructureId
                          );
                          temp[index].inputString[idx].value = text;
                          temp[index].inputString[idx].tiptapJson = content;
                          setPayloads(temp);
                        }}
                        refresh={updateField}
                        placeholder="Enter value"
                        variables={variables}
                      />
                    </div>

                    <button
                      className="hover:bg-primary/10 h-fit w-fit p-2 rounded-full text-primary"
                      onClick={() => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        temp[index].inputString.splice(idx, 1);
                        setPayloads(temp);
                      }}
                    >
                      <MdDeleteOutline size={25} />
                    </button>
                  </div>

                  {idx <
                    (payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString
                      ?.length || 0) -
                      1 && (
                    <div className="flex justify-center my-2">
                      <Select
                        style={{ width: "100px" }}
                        value={criteria.conjunction || "and"}
                        onChange={(value) => {
                          const temp = [...payloads];
                          const index = payloads.findIndex(
                            (input) => input.payloadStructureId === field.payloadStructureId
                          );
                          temp[index].inputString[idx].conjunction = value;
                          setPayloads(temp);
                        }}
                        options={[
                          { label: "AND", value: "and" },
                          { label: "OR", value: "or" },
                        ]}
                      />
                    </div>
                  )}
                </div>
              )
            )}

            {(payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString?.length ||
              0) < 10 && (
              <button
                className="text-primary flex gap-2 items-center"
                onClick={() => {
                  const temp = [...payloads];
                  const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);

                  if (index === -1) {
                    temp.push({
                      payloadStructureId: field.payloadStructureId,
                      type: field.type,
                      inputString: [],
                    });
                  }

                  const updatedIndex = index === -1 ? temp.length - 1 : index;

                  if (!temp[updatedIndex].inputString) {
                    temp[updatedIndex].inputString = [];
                  }

                  temp[updatedIndex].inputString.push({
                    field: "",
                    operator: "",
                    value: "",
                    fieldType: "",
                    tiptapJson: "",
                    conjunction: "and",
                  });

                  setPayloads(temp);
                }}
              >
                <FaPlus />
                Add Criteria (max 10)
              </button>
            )}

            {payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString?.length >
              0 && (
              <div className="mt-2 p-2 bg-gray-50 rounded text-sm font-mono break-all whitespace-pre-wrap">
                {formatZohoCriteria(
                  payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString
                )}
              </div>
            )}
          </div>
        );
      default:
        return (
          <textarea
            className="w-full h-32 rounded-lg"
            placeholder="Enter the value"
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""}
            onChange={(e) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: e.target.value,
                  type: field.type,
                });
              } else {
                temp[index].inputString = e.target.value;
              }
              setPayloads(temp);
            }}
          />
        );
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={modal}
        mask={false}
        onClose={() => setModal(false)}
        width={drawerWidth}
        styles={{
          content: {
            transition: "width 0.3s",
          },
          body: {
            padding: collapseConfig ? "0" : "24px",
          },
        }}
        classNames={{ header: !collapseConfig ? "border-b !border-gray-300" : "!border-none" }}
        footer={
          !collapseConfig && (
            <div className="w-full flex flex-col gap-4 bg-white">
              {!isConnectionExists && (
                <Alert
                  message={
                    <>
                      Please add the required connection to proceed.{" "}
                      <Link to="/connections" target="_blank" rel="noopener noreferrer">
                        <i>Click here</i>
                      </Link>
                    </>
                  }
                  type="warning"
                  showIcon
                />
              )}
              <button
                className={` text-white w-full font-semibold rounded-lg p-2 mt-auto ${
                  loading || !changed || !isConnectionExists
                    ? "cursor-not-allowed bg-gray-500"
                    : "bg-primary cursor-pointer"
                }`}
                onClick={saveOutputStructure}
                disabled={loading || !changed || !isConnectionExists}
              >
                {loading ? "Saving..." : !changed ? "Saved" : "Save"}
              </button>
            </div>
          )
        }
        title={
          !collapseConfig && (
            <div className="flex items-center gap-2">
              <button className="p-0 rounded-sm" onClick={() => setCollapseConfig(true)}>
                <MdChevronRight size={25} />
              </button>
              <img src={action.logo} alt={action.name} className="w-6 h-6" />
              <span
                contentEditable={changeName}
                suppressContentEditableWarning
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.target.blur();
                  }
                }}
                onBlur={async (e) => {
                  if (!e.target.textContent) return;
                  setLoading(true);
                  updateActionName(action.id, e.target.textContent || action.name);
                  setChangeName(false);
                  await saveWorkflowActions(id || "");
                  setChanged(false);
                  setLoading(false);
                  setPublishWarning(true);
                }}
              >
                {action.name}
              </span>
              <button
                onClick={() => {
                  setChangeName(!changeName);
                  const p = document.getElementById("actionName");
                  if (!p) return;
                  setTimeout(function () {
                    const range = document.createRange();
                    const selection = window.getSelection();
                    range.selectNodeContents(p);
                    range.collapse(false); // <-- Set the cursor at the end of the selection
                    selection?.removeAllRanges();
                    selection?.addRange(range);
                    p.focus();
                  }, 0);
                }}
                className={`p-2 rounded-md transition-opacity duration-600 ease-in-out hover:bg-gray-200 ${
                  changeName ? "opacity-50" : "opacity-100"
                }`}
              >
                <FaRegEdit />
              </button>
            </div>
          )
        }
      >
        {collapseConfig ? (
          <button
            className="w-full h-full flex flex-col items-center justify-center p-3 gap-3"
            onClick={() => setCollapseConfig(false)}
          >
            <span className="p-0 border border-gray-300 rounded-sm">
              <MdChevronLeft size={25} />
            </span>
            <p className="[writing-mode:sideways-lr] text-center">CONFIG</p>
          </button>
        ) : (
          <div className="bg-white gap-10 flex flex-col">
            {action.payloadStructure.map((field, idx) => {
              // Hide search criteria if search type is not 'criteria'
              if (field.payloadStructureId === "criteria_id" && searchType !== "criteria") return null;
              // Hide search value field if search type is 'criteria'
              if (field.payloadStructureId === "search_value_id" && searchType === "criteria") return null;
              return (
                <div key={idx} className="flex flex-col w-full gap-4 p-4 border border-gray-300 rounded-lg">
                  <div>
                    <p className="tracking-primary font-semibold">
                      {field.name.toUpperCase()}
                      {!field.required && (
                        <span className="text-sm font-normal tracking-normal text-gray-400"> (Optional)</span>
                      )}
                    </p>
                    <p className="text-sm font-light">{field.description}</p>
                  </div>
                  {field.needVars ? (
                    <TiptapField
                      content={
                        payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.tiptapJson ||
                        ""
                      }
                      setContent={(content: any, text: string) => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        if (index === -1) {
                          temp.push({
                            payloadStructureId: field.payloadStructureId,
                            tiptapJson: content,
                            inputString: text,
                            type: field.type,
                          });
                        } else {
                          temp[index].tiptapJson = content;
                          temp[index].inputString = text;
                        }
                        setPayloads(temp);
                      }}
                      refresh={updateField}
                      placeholder={field.name}
                      variables={variables}
                    />
                  ) : (
                    fieldSwitchCase(field)
                  )}
                </div>
              );
            })}
            <div>
              Continue workflow even if this fails:{" "}
              <Switch
                defaultChecked={action.continueOnFail}
                onChange={(value) => {
                  setChanged(true);
                  action.continueOnFail = value;
                }}
              />
            </div>
            <RunCondition
              variables={variables}
              responses={action.responseStructure}
              conditionsArr={
                runCondition || {
                  conditions: [],
                  otherwise: [],
                }
              }
              setConditionsArr={setRunCondition}
            />
          </div>
        )}
      </Drawer>
    </ConfigProvider>
  );
};

export default DynamicCRMpushModal;

import { getIconFromType } from "@/utils/constants";
import { Divider, Select } from "antd";
import { useEffect, useState } from "react";
import { PiPlusBold } from "react-icons/pi";
import { v4 as uuidv4 } from "uuid";

type Option = {
  value: string;
  label: string;
  idx: number;
  type: string;
};

type Props = {
  options: Option[];
  mapper: (idx: string, responseId: string) => void;
  idx: number;
  err: string[];
  allResponses: any;
  map: any;
  columnLabel: string;
  columnType: string;
  addNewResponse: (res: any) => void;
};

const InputDropdown = ({
  options,
  mapper,
  idx,
  err,
  allResponses,
  map,
  columnLabel,
  columnType,
  addNewResponse,
}: Props) => {
  const [bestMatch, setBestMatch] = useState<any>();
  const [newInp, setNewInp] = useState<string>("");
  const [created, setCreated] = useState<boolean>(false);

  const stringSimilarity = (str1: string, str2: string, gramSize: number = 2) => {
    const getNGrams = (s: string, len: number) => {
      s = " ".repeat(len - 1) + s.toLowerCase() + " ".repeat(len - 1);
      let v = new Array(s.length - len + 1);
      for (let i = 0; i < v.length; i++) v[i] = s.slice(i, i + len);
      return v;
    };
    if (!str1?.length || !str2?.length) return 0.0;
    let s1 = str1.length < str2.length ? str1 : str2;
    let s2 = str1.length < str2.length ? str2 : str1;
    let pairs1 = getNGrams(s1, gramSize);
    let pairs2 = getNGrams(s2, gramSize);
    let set = new Set<string>(pairs1);
    let total = pairs2.length;
    let hits = 0;
    for (let item of pairs2) if (set.delete(item)) hits++;
    return hits / total;
  };

  useEffect(() => {
    // Skip comparison if the dropdown is already set
    if (bestMatch) return; // Do not run if a value is already selected

    // If all responses is empty(No inputs available), create a new response
    if (allResponses.length === 0) return addNewOption(columnLabel);
    // Find the best match based on string similarity
    const findBestMatch = () => {
      let bestScore = 0;
      let bestLabel = { value: "", label: "", idx: -1, type: "" };
      options.forEach((option) => {
        const score = stringSimilarity(option.label, columnLabel);
        if (score > bestScore) {
          bestScore = score;
          bestLabel = option;
        }
      });
      if (bestScore >= 0.4 && !Object.values(map).includes(bestLabel.value)) {
        // Use a threshold to assign the best match
        setBestMatch(bestLabel);
        mapper(idx?.toString(), bestLabel.value || ""); // Automatically map the best match
      } else setNewInp(columnLabel);
    };

    findBestMatch(); // Invoke best match search when component is mounted
  }, [columnLabel]);

  const onChange = (value: string) => {
    setCreated(false);
    mapper(idx?.toString(), value || "");
    setBestMatch(options[options.findIndex((option) => option.value === value)]);
  };

  const addNewOption = (name: string) => {
    const val = uuidv4();
    const newInp = {
      label: name,
      value: val,
      idx: idx,
      type: columnType,
    };
    setBestMatch(newInp);
    mapper(idx?.toString(), val);
    addNewResponse({
      name: columnLabel,
      responseId: val,
      type: columnType,
    });
    setNewInp("");
    setCreated(true);
  };

  return (
    <div className="flex items-center gap-4 w-full">
      <Select
        labelRender={(label: any) => (
          <div className="flex items-center gap-2 justify-between">
            <span className="flex items-center gap-2">
              {getIconFromType(bestMatch?.type || "")}
              {label.label}
            </span>
            {created ? (
              <p className="text-[#343434] p-1 font-normal text-xs bg-[#E4E4E4] rounded-sm">Created</p>
            ) : (
              allResponses.filter((item: any) => item.value === map[idx]).length > 0 && (
                <p className="text-[#343434] p-1 font-normal text-xs bg-[#E4E4E4] rounded-sm">Mapped</p>
              )
            )}
          </div>
        )}
        optionRender={(option) => (
          <div className="flex gap-1 items-center">
            <span role="img" aria-label={option.data.label}>
              {getIconFromType(option.data.type || "")}
            </span>
            {option.data.label}
          </div>
        )}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: "8px 0" }} />
            <div className="flex items-center gap-2 p-2">
              <input
                type="text"
                placeholder="Create new input"
                className="w-full py-0.5 px-2 rounded"
                value={newInp}
                onChange={(e) => setNewInp(e.target.value)}
              />
              <button
                className="flex items-center gap-1 bg-[#E4E4E4] text-[#343434] p-1 rounded"
                onClick={() => addNewOption(newInp)}
              >
                <PiPlusBold />
                Create
              </button>
            </div>
          </>
        )}
        className="w-3/4"
        aria-errormessage="Already Mapped"
        status={err.includes(idx?.toString()) ? "error" : ""}
        placeholder="Select a column"
        optionFilterProp="label"
        onChange={onChange}
        value={bestMatch} // Pre-select the best match
        options={options}
        defaultValue={allResponses.filter((item: any) => item.value === map[idx])}
        showSearch
        allowClear
      />
      {
        // If error, show error message
        err.includes(idx?.toString()) && <div className="text-red-500">Already Mapped</div>
      }
    </div>
  );
};

export default InputDropdown;

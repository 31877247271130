import { useState, useCallback } from "react";
import { Select } from "antd";

import { PayloadStructure } from "@/utils/interfaces";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import { getCampaigns } from "@/utils/apis";

type Props = {
  payloadStructure: PayloadStructure[];
  payloads: any;
  setPayloads: any;
  prev: () => void;
  next: () => void;
};

export const personFields = [
  {
    name: "id",
    label: "ID",
    fieldType: "fixed",
    type: "string",
  },
  {
    name: "name",
    label: "Name",
    fieldType: "fixed",
    type: "string",
  },
  {
    name: "first_name",
    label: "First Name",
    fieldType: "fixed",
    type: "string",
  },
  {
    name: "last_name",
    label: "Last Name",
    fieldType: "fixed",
    type: "string",
  },
  {
    name: "owner_id",
    label: "Owner ID",
    fieldType: "fixed",
    type: "string",
  },
  {
    name: "org_id",
    label: "Organization ID",
    fieldType: "fixed",
    type: "string",
  },
  {
    name: "add_time",
    label: "Add Time",
    fieldType: "fixed",
    type: "datetime",
  },
  {
    name: "update_time",
    label: "Update Time",
    fieldType: "fixed",
    type: "datetime",
  },
  {
    name: "emails",
    label: "Emails",
    fieldType: "fixed",
    type: "json",
  },
  {
    name: "phones",
    label: "Phones",
    fieldType: "fixed",
    type: "json",
  },
  {
    name: "is_deleted",
    label: "Is Deleted",
    fieldType: "fixed",
    type: "boolean",
  },
  {
    name: "visible_to",
    label: "Visible To",
    fieldType: "fixed",
    type: "string",
  },
  {
    name: "label_ids",
    label: "Label IDs",
    fieldType: "fixed",
    type: "set",
  },
  {
    name: "picture_id",
    label: "Picture ID",
    fieldType: "fixed",
    type: "string",
  },
  {
    name: "next_activity_id",
    label: "Next Activity ID",
    fieldType: "optional",
    type: "string",
  },
  {
    name: "last_activity_id",
    label: "Last Activity ID",
    fieldType: "optional",
    type: "string",
  },
  {
    name: "open_deals_count",
    label: "Open Deals Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "related_open_deals_count",
    label: "Related Open Deals Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "closed_deals_count",
    label: "Closed Deals Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "related_closed_deals_count",
    label: "Related Closed Deals Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "participant_open_deals_count",
    label: "Participant Open Deals Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "participant_closed_deals_count",
    label: "Participant Closed Deals Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "email_messages_count",
    label: "Email Messages Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "activities_count",
    label: "Activities Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "done_activities_count",
    label: "Done Activities Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "undone_activities_count",
    label: "Undone Activities Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "files_count",
    label: "Files Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "notes_count",
    label: "Notes Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "followers_count",
    label: "Followers Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "won_deals_count",
    label: "Won Deals Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "related_won_deals_count",
    label: "Related Won Deals Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "lost_deals_count",
    label: "Lost Deals Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "related_lost_deals_count",
    label: "Related Lost Deals Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "last_incoming_mail_time",
    label: "Last Incoming Mail Time",
    fieldType: "optional",
    type: "datetime",
  },
  {
    name: "last_outgoing_mail_time",
    label: "Last Outgoing Mail Time",
    fieldType: "optional",
    type: "datetime",
  },
  {
    name: "marketing_status",
    label: "Marketing Status",
    fieldType: "optional",
    type: "string",
  },
  {
    name: "doi_status",
    label: "DOI Status",
    fieldType: "optional",
    type: "string",
  },
];

export const organizationFields = [
  {
    name: "id",
    label: "ID",
    fieldType: "fixed",
    type: "string",
  },
  {
    name: "name",
    label: "Name",
    fieldType: "fixed",
    type: "string",
  },
  {
    name: "owner_id",
    label: "Owner ID",
    fieldType: "fixed",
    type: "string",
  },
  {
    name: "add_time",
    label: "Add Time",
    fieldType: "fixed",
    type: "datetime",
  },
  {
    name: "update_time",
    label: "Update Time",
    fieldType: "fixed",
    type: "datetime",
  },
  {
    name: "visible_to",
    label: "Visible To",
    fieldType: "fixed",
    type: "string",
  },
  {
    name: "address",
    label: "Address",
    fieldType: "fixed",
    type: "string",
  },
  {
    name: "label_ids",
    label: "Label IDs",
    fieldType: "fixed",
    type: "string",
  },
  {
    name: "is_deleted",
    label: "Is Deleted",
    fieldType: "fixed",
    type: "boolean",
  },
  {
    name: "next_activity_id",
    label: "Next Activity ID",
    fieldType: "optional",
    type: "string",
  },
  {
    name: "last_activity_id",
    label: "Last Activity ID",
    fieldType: "optional",
    type: "string",
  },
  {
    name: "open_deals_count",
    label: "Open Deals Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "related_open_deals_count",
    label: "Related Open Deals Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "closed_deals_count",
    label: "Closed Deals Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "related_closed_deals_count",
    label: "Related Closed Deals Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "email_messages_count",
    label: "Email Messages Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "people_count",
    label: "People Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "activities_count",
    label: "Activities Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "done_activities_count",
    label: "Done Activities Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "undone_activities_count",
    label: "Undone Activities Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "files_count",
    label: "Files Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "notes_count",
    label: "Notes Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "followers_count",
    label: "Followers Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "won_deals_count",
    label: "Won Deals Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "related_won_deals_count",
    label: "Related Won Deals Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "lost_deals_count",
    label: "Lost Deals Count",
    fieldType: "optional",
    type: "number",
  },
  {
    name: "related_lost_deals_count",
    label: "Related Lost Deals Count",
    fieldType: "optional",
    type: "number",
  },
];

const PipedriveInputModal = ({ payloadStructure, payloads, setPayloads, prev, next }: Props) => {
  const [pdObjectFields, setPdObjectFields] = useState<{ loading: boolean; pdFields: any[] }>({
    loading: false,
    pdFields: [],
  });

  //   fetches the object's list views, on click
  const handleObjectSelect = useCallback(
    async (selectedObject: any) => {
      setPdObjectFields({
        loading: true,
        pdFields: [],
      });
      const response = await getCampaigns(
        selectedObject === "organizations" ? "pipedriveOrgFields" : "pipedrivePersonFields",
        true
      );
      setPdObjectFields({
        loading: false,
        pdFields: response,
      });
      setPayloads((prev: any) => ({
        ...prev,
        crmProperties: response,
      }));
    },
    [pdObjectFields, payloads]
  );

  const payloadSwitch = (payload: any) => {
    switch (payload.type) {
      case "dropdown":
        return (
          <div className="w-full flex flex-col">
            <p>{payload.name}</p>
            <p className="text-xs">{payload.description}</p>
            <Select
              showSearch
              style={{ width: "100%" }}
              value={payloads[payload.payloadStructureId] || ""}
              onChange={(value) => {
                handleObjectSelect(value);
                setPayloads((prev: any) => ({
                  ...prev,
                  [payload.payloadStructureId]: value,
                }));
              }}
              options={
                payload?.values &&
                payload.values?.map((option: any) => ({
                  label: option.label,
                  value: option.id,
                }))
              }
            />
          </div>
        );

      default:
        return;
    }
  };

  return (
    <div className="p-4 border w-[60%] mx-auto mt-10 flex flex-col items-center justify-center gap-8 rounded-lg">
      <div className="w-full flex items-center justify-between gap-1 rounded-md">
        <p className="text-2xl font-medium">Configure your list</p>
        <div className="flex gap-2 items-center">
          <button
            className="text-gray-800 px-1 py-1 rounded disabled:opacity-50 border border-gray-800 hover:bg-gray-100"
            onClick={prev}
          >
            <AiOutlineArrowLeft />
          </button>
          {payloads?.crmProperties ? (
            <button
              className="text-gray-800 px-1 py-1 rounded disabled:opacity-50 border border-gray-800 hover:bg-gray-100"
              onClick={next}
            >
              <AiOutlineArrowRight />
            </button>
          ) : (
            pdObjectFields.loading && <SpinnerStatus />
          )}
        </div>
      </div>
      <div className="w-full flex flex-col gap-4">
        {payloadStructure.map((payload: any, index: number) => (
          <div key={index} className="flex flex-col gap-2 w-full">
            {payloadSwitch(payload)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PipedriveInputModal;

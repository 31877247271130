import { useWorkflow } from "@/contexts/WorkflowContext";
import { Edge } from "@xyflow/react";
import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { GoPlus } from "react-icons/go";
import * as Popover from "@radix-ui/react-popover";
import AddActionView from "../../ActionModals/AddActionView";
import { useParams } from "react-router-dom";
import { AUTOFILL_KEYWORDS, BRANCH_ACTIONS_TYPES, TEMPLATE_TIPTAP_VARIABLE_VALUE } from "@/utils/constants";
import { v4 as uuidv4 } from "uuid";
import { ResponseStructure } from "@/utils/interfaces";
import { getInputStringFromType } from "../../ActionModals/AddActionModal";
import { RxCross2 } from "react-icons/rx";
import AddSubfloqModal from "../../ActionModals/AddSubfloqModal";
import { message } from "antd";
import SubfloqCreateModal from "../../ActionModals/ModalComponents/SubfloqCreateModal";
import { addWorkflowToSubfloq } from "@/utils/apis";

interface Props {
  edges: Edge[];
  className?: string;
  setMode?: (mode: "Shift" | null) => void;
  mode?: "Shift" | null;
  subfloq?: boolean;
  allEdges?: Edge[];
  selectedNodes: any[];
}
export interface AddNewNodesRef {
  togglePopover: (edge: Edge) => void;
  handleAdd: (
    selectedAction: string,
    hoveredActionSource?: string,
    targetX?: string | null,
    copyAction?: boolean
  ) => void;
}

const AddNewNodes = forwardRef<AddNewNodesRef, Props>(
  ({ edges, className, setMode = () => {}, mode = "Shift", subfloq = false, allEdges, selectedNodes }, ref) => {
    const [addActionModalData, setAddActionModalData] = useState<any>({
      open: false,
      edgeProps: {},
    });
    const [selectedTab, setSelectedTab] = useState<"actions" | "subfloqs">("actions");
    const [subfloqModalOpen, setSubfloqModalOpen] = useState(false);

    const {
      actions,
      setActions,
      saveWorkflowActions,
      availableActions,
      categorizedActions,
      actionCategoryGroups,
      isSubfloq,
      workflowConfig,
    } = useWorkflow();
    const { id } = useParams();

    useImperativeHandle(ref, () => ({
      togglePopover,
      handleAdd,
    }));

    const [lastEdge, setLastEdge] = useState<Edge | null>(null);

    useEffect(() => {
      if (!edges?.length) return;
      setLastEdge(edges[edges.length - 1]);
    }, [edges]);
    useEffect(() => {
      if (selectedNodes.length === 0) return setSubfloqModalOpen(false);
      const invalidNodes = selectedNodes.filter(
        (node) =>
          ["WORKFLOW_PATH_SPLITTER", "ADD_SECTION_TO_FLOQ"].includes(node.data?.actionName || node.data?.name) ||
          ["input"].includes(node.data?.type)
      );

      if (invalidNodes.length > 0) {
        // Use a flag to prevent multiple messages
        if (!window.__splitPathWarning) {
          window.__splitPathWarning = true;
          const invalidNodeNames = invalidNodes.map((node) => node.data.name);
          message.error({
            content: `Can't include actions "${invalidNodeNames.join(", ")}" in SubFloq`,
            duration: 4,
            onClose: () => {
              window.__splitPathWarning = false; // Reset flag when message closes
            },
          });
        }
        setSubfloqModalOpen(false);
        return;
      }
      setSubfloqModalOpen(true);
    }, [selectedNodes]);

    const getPayloadConfig = (action: any, vars: any, copyAction?: boolean, currentActionDuplicate?: any) => {
      let payloadConfig = [];
      if (copyAction && currentActionDuplicate)
        payloadConfig = JSON.parse(JSON.stringify(currentActionDuplicate?.payloadConfiguration || []));
      else {
        payloadConfig = action.payloadStructure.map((field: any) => {
          if (field.needVars && field.required) {
            const matchingVars = vars.filter((v: any) => v.type === field.type);
            let exactMatch = matchingVars.find((v: any) => v.name?.toLowerCase() === field.name?.toLowerCase());
            const keywords = AUTOFILL_KEYWORDS[field.name as keyof typeof AUTOFILL_KEYWORDS] || [];
            // compare each matching variable with the keywords
            for (let i = 0; i < matchingVars.length && !exactMatch; i++) {
              const variable = matchingVars[i];
              if (keywords.some((keyword) => variable.name.toLowerCase() === keyword.toLowerCase())) {
                exactMatch = variable;
                break;
              }
            }
            return {
              payloadStructureId: field.payloadStructureId,
              inputString: exactMatch ? `{{${exactMatch?.id}}}` : "",
              tiptapJson: exactMatch ? TEMPLATE_TIPTAP_VARIABLE_VALUE(exactMatch) : "",
            };
          } else {
            const config: any = {
              payloadStructureId: field.payloadStructureId,
              inputString: getInputStringFromType(field.type),
            };
            if (field.type === "stepDownSearch")
              config["stepDownSearchConfig"] = (action.stepDownAPIs || field.stepDownAPIs)
                .filter((api: any) => !api?.floqerApiKeyNotAllowed && !api?.disabled)
                .map((api: any, idx: number) => ({
                  apiId: api.apiId,
                  order: api.order || idx + 1,
                }));
            return config;
          }
        });
      }

      return payloadConfig;
    };

    const getResponseConfig = (action: any, copyAction?: boolean, currentActionDuplicate?: any) => {
      let responseConfig = [];

      if (copyAction && currentActionDuplicate) {
        responseConfig = currentActionDuplicate.responseConfiguration.map((field: any) => {
          const x: any = {
            responseStructureId: field.responseStructureId,
            responseId: uuidv4().toString(),
          };
          if (field.sectionVariablesConfiguration)
            x["sectionVariablesConfiguration"] = field.sectionVariablesConfiguration.map((section: any) => ({
              responseId: uuidv4().toString(),
              responseStructureId: section.responseStructureId,
            }));

          if (field?.display_column) x.display_column = field.display_column;
          return x;
        });
      } else if (action.outputStructureType === "predefined") {
        responseConfig = action.responseStructure.map((field: any) => {
          const x: any = {
            responseStructureId: field.responseStructureId,
            responseId: uuidv4().toString(),
          };
          if (field.type === "sectionList")
            x["sectionVariablesConfiguration"] = field.sectionVariablesStructure.map((section: any) => ({
              responseId: uuidv4().toString(),
              responseStructureId: section.responseStructureId,
            }));
          return x;
        });
      }

      return responseConfig;
    };

    const getRunCondition = (copyAction?: boolean, currentActionDuplicate?: any) => {
      let runCondition = {};
      if (copyAction && currentActionDuplicate)
        runCondition = structuredClone(currentActionDuplicate?.runCondition || {});
      return runCondition;
    };

    const handleAdd = async (
      selectedAction: string,
      hoveredActionSource?: string,
      targetX?: string | null,
      copyAction?: boolean
    ) => {
      let edge: Edge | null = null;
      if (hoveredActionSource)
        edges?.forEach((e) => {
          if (e.source === hoveredActionSource) edge = e;
        });
      else edge = lastEdge;
      if (edge?.source.includes("id2") && !isSubfloq) edge = allEdges?.find((e) => e.source.includes("idout")) || null;
      if (edge?.source === "idout" && isSubfloq) return;

      if (!edge) return;
      const { source, data } = edge;
      const pathIdx: number = (data?.idx as number) || 0;
      let sourceId = source;
      if (sourceId && sourceId.includes("-cond")) sourceId = sourceId.split("-cond")[0];

      let target = (edge as Edge).source === lastEdge?.source ? null : (edge as Edge)?.target;
      if (targetX?.includes("-end")) target = null;
      else if (targetX || targetX === null) target = targetX;

      const action = availableActions.find((act) => act.id === selectedAction);
      const sourceIdx = actions.findIndex((act) => act.id === sourceId);
      const targetIdx = actions.findIndex((act) => act.id === target);
      const subfloq_id = actions[0]?.subfloq_id || null;

      if (!action) return;
      const actId = uuidv4().toString();
      const vars: any[] = [];
      // @ts-ignore
      data?.variables.forEach((action: any) => {
        action?.variables?.forEach((variable: ResponseStructure) => {
          const option = {
            id: variable.responseId,
            logo: action.logo,
            type: variable.type || "string",
            name: variable.name,
            actionName: action.name,
          };
          vars.push(option);
        });
      });

      let currentActionDuplicate = null;
      if (copyAction) currentActionDuplicate = actions.find((act) => act.id === sourceId);

      const payloadConfig = getPayloadConfig(action, vars, copyAction, currentActionDuplicate);
      const responseConfig = getResponseConfig(action, copyAction, currentActionDuplicate);
      const runCondition = getRunCondition(copyAction, currentActionDuplicate);

      const newAction: any = {
        id: actId,
        pk_id: actId,
        subfloq_id,
        actionDetails: action,
        delayAfterActionPerProspectPerSecond: 0,
        actionName: action.id,
        nextAction: [
          {
            actionId: target,
            conditions: [],
            name: "Conditional Path",
          },
        ],
        prevAction: [
          {
            actionId: sourceId,
            conditions:
              actions?.[sourceIdx]?.nextAction?.find((next: any) => next?.actionId === target)?.conditions || [],
            name: "Conditional Path",
          },
        ],
        payloadConfiguration: payloadConfig,
      };
      if (BRANCH_ACTIONS_TYPES.includes(action.type))
        newAction["nextAction"].push({
          actionId: null,
          conditions: [],
          name: "Conditional Path",
        });
      if (action.outputStructureType === "predefined") newAction["responseConfiguration"] = responseConfig;
      if (copyAction && currentActionDuplicate) newAction["runCondition"] = runCondition;

      if (sourceIdx !== -1 && hoveredActionSource?.includes("-cond"))
        actions[sourceIdx].nextAction[pathIdx].actionId = actId;
      else if (sourceIdx !== -1)
        actions[sourceIdx].nextAction = actions[sourceIdx].nextAction
          .map((next: any) =>
            (next && typeof next === "object" ? next.actionId : next) === target
              ? {
                  actionId: actId,
                  conditions: next?.conditions || [],
                  name: next?.name || "",
                }
              : next
          )
          // Remove duplicates
          .filter(
            (obj1: any, i: number, arr: any[]) =>
              !obj1.actionId || arr.findIndex((obj2) => obj2.actionId === obj1.actionId) === i
          );

      if (targetIdx !== -1)
        actions[targetIdx].prevAction = actions[targetIdx].prevAction
          .map((prev: any) =>
            (prev && typeof prev === "object" ? prev.actionId : prev) === sourceId
              ? {
                  actionId: actId,
                  conditions: [],
                  name: "",
                }
              : prev
          )
          // Remove duplicates
          .filter(
            (obj1: any, i: number, arr: any[]) =>
              !obj1.actionId || arr.findIndex((obj2) => obj2.actionId === obj1.actionId) === i
          );

      setActions([...actions, newAction]);
      await saveWorkflowActions(id || "", subfloq);
    };

    const handleAddSub = async (
      selectedAction: any,
      subfloqActions: any[],
      hoveredActionSource?: string,
      targetX?: string | null
    ) => {
      if (!selectedAction) return console.warn("Skipping because selectedAction is null.");

      let edge: Edge | null = null;
      if (hoveredActionSource)
        edges?.forEach((e) => {
          if (e.source === hoveredActionSource) edge = e;
        });
      else edge = lastEdge;
      if (edge?.source.includes("id2")) edge = allEdges?.find((e) => e.source.includes("idout")) || null;
      if (!edge) return console.warn("Skipping because edge is null.");
      const { source, data } = edge;
      const pathIdx: number = (data?.idx as number) || 0;
      let sourceId = source;
      if (sourceId.includes("-cond")) sourceId = sourceId.split("-cond")[0];

      let target = edge.source === lastEdge?.source ? null : edge?.target;
      if (targetX?.includes("-end")) target = null;
      else if (targetX || targetX === null) target = targetX;

      const sourceIdx = actions.findIndex((act) => act.id === sourceId);
      const targetIdx = actions.findIndex((act) => act.id === target);

      if (sourceIdx !== -1 && hoveredActionSource?.includes("-cond")) {
        actions[sourceIdx].nextAction[pathIdx].actionId = selectedAction?.id || null;
      } else if (sourceIdx !== -1) {
        actions[sourceIdx].nextAction = actions[sourceIdx].nextAction
          .map((next: any) => ({
            ...next,
            actionId: selectedAction?.id || null,
          }))
          .filter(
            (obj1: any, i: number, arr: any[]) =>
              !obj1.actionId || arr.findIndex((obj2) => obj2.actionId === obj1.actionId) === i
          );
      }

      if (targetIdx !== -1) {
        actions[targetIdx].prevAction = actions[targetIdx].prevAction
          .map((prev: any) =>
            (prev && typeof prev === "object" ? prev.actionId : prev) === sourceId
              ? {
                  actionId: selectedAction?.id.replace("id2", "idout") || null,
                  conditions: [],
                  name: "",
                }
              : prev
          )
          .filter(
            (obj1: any, i: number, arr: any[]) =>
              !obj1.actionId || arr.findIndex((obj2) => obj2.actionId === obj1.actionId) === i
          );
      }
      // Get the next action for recursion
      setActions([...actions, ...subfloqActions]);
      await saveWorkflowActions(id || "", subfloq);
    };

    const togglePopover = (edge?: Edge) => {
      if (!edges?.length) return;
      const lastEdge = edge || edges[edges.length - 1];
      if (addActionModalData.open) setAddActionModalData({ ...addActionModalData, open: false });
      if (!addActionModalData?.open) setAddActionModalData({ open: true, edgeProps: lastEdge });
    };

    return (
      <>
        <Popover.Root open={addActionModalData.open}>
          <Popover.Trigger asChild>
            <div
              className={`${className} border border-[#D0D0D0] rounded-[7px] cursor-pointer`}
              onClick={() => togglePopover()}
            >
              {!addActionModalData.open && (
                <div
                  className={`flex items-center justify-center h-[44px] w-[44px] rounded-[7px] bg-[#e8e4f8] text-3xl transition-all duration-1000 opacity-100}`}
                >
                  <GoPlus />
                </div>
              )}
            </div>
          </Popover.Trigger>

          <Popover.Content
            className="top-2 right-2  p-0 w-[440px]  bg-white  !font-favorit z-10 scale-90"
            align="start"
            side="top"
            style={{
              transform: "scale(0.90)",
              transformOrigin: "top right",
              margin: 0,
              position: "relative",
              right: "10%", // Adjust this value to fine-tune horizontal position
            }}
          >
            <Popover.Close className="absolute top-2 right-2 w-8 h-8 cursor-pointer">
              <div onClick={() => togglePopover()}>
                <RxCross2 className="w-6 h-6" />
              </div>
            </Popover.Close>
            {lastEdge && (
              <div className=" max-h-[85vh] min-h-[85vh] pl-0 overflow-hidden border border-[#333333] rounded-[8px] shadow-[0px_0px_0px_5px_rgba(224,224,224,0.46)]">
                <div className="flex items-center px-4 py-2 mt-1 gap-3 border-b border-[#565656]">
                  {[{ name: "Actions", id: "actions" }, !isSubfloq && { name: "SubFloqs", id: "subfloqs" }].map(
                    (tab: any) => (
                      <span
                        onClick={() => setSelectedTab(tab.id)}
                        className={`text-xl ${selectedTab === tab.id && "bg-[#ECE7FF] font-semibold"} p-1 rounded cursor-pointer`}
                      >
                        {tab.name}
                      </span>
                    )
                  )}
                </div>
                {selectedTab === "actions" ? (
                  <AddActionView
                    availableActions={availableActions.filter((act) => act.type !== "input")}
                    source={lastEdge?.source}
                    target={lastEdge?.target}
                    doubleClickAction={(actionId) => {
                      if (addActionModalData?.edgeProps?.source?.includes("-cond-")) {
                        const sourceId = addActionModalData?.edgeProps?.source.split("-cond-")[0];
                        const addActionButtons = document.querySelectorAll(".add-action-button");
                        let targetButton = null;
                        let source;
                        let target;
                        addActionButtons.forEach((button) => {
                          button.classList.forEach((cls) => {
                            if (cls.includes(`source-${sourceId}-cond-`)) targetButton = button;
                          });
                        });

                        if (targetButton) {
                          (targetButton as Element)?.classList.forEach((cls) => {
                            if (cls.includes("target-")) target = cls.replace("target-", "");
                            if (cls.includes("source-")) source = cls.replace("source-", "");
                          });
                        }

                        handleAdd(actionId, source, target);
                        return;
                      }
                      let src = addActionModalData?.edgeProps?.source;
                      if (src.includes("id2") && !isSubfloq) src = src.replace("id2", "idout");
                      const sourceAction = actions.find((act) => act.id === src);
                      let target = sourceAction?.nextAction[0]?.actionId ?? null;

                      handleAdd(actionId, addActionModalData?.edgeProps?.source, target);
                    }}
                    dragableActions={true}
                    onActionDropAction={handleAdd}
                    actionsCategory={categorizedActions}
                    actionCategoryGroups={actionCategoryGroups}
                    isOpen={addActionModalData.open}
                  />
                ) : (
                  <AddSubfloqModal
                    setMode={setMode}
                    mode={mode}
                    onActionDropAction={handleAddSub} // Assuming 'handleAdd' is your drop handler
                    doubleClickAction={async (subfloq: any) => {
                      if (addActionModalData?.edgeProps?.source?.includes("-cond-")) {
                        const sourceId = addActionModalData?.edgeProps?.source.split("-cond-")[0];
                        const addActionButtons = document.querySelectorAll(".add-action-button");
                        let targetButton = null;
                        let source = "";
                        let target = "";
                        addActionButtons.forEach((button) => {
                          button.classList.forEach((cls) => {
                            if (cls.includes(`source-${sourceId}-cond-`)) targetButton = button;
                          });
                        });

                        if (targetButton) {
                          (targetButton as Element)?.classList.forEach((cls) => {
                            if (cls.includes("target-")) target = cls.replace("target-", "");
                            if (cls.includes("source-")) source = cls.replace("source-", "");
                          });
                        }
                        const actIdSub = uuidv4().toString();

                        const updatedActions = subfloq.actions.map((action: any) => {
                          const actPKId = uuidv4().toString();
                          const actDetails = availableActions.find((act) => act.id === action.actionName) || {};
                          return {
                            ...action,
                            actionDetails: actDetails,
                            id: `${action.id}_${actIdSub}`,
                            pk_id: actPKId,
                            prevAction: action.prevAction.map((prev: any) => ({
                              ...prev,
                              actionId:
                                prev.actionId !== null
                                  ? `${prev.actionId}_${actIdSub}`
                                  : action.id.includes("id2")
                                    ? source
                                    : null,
                            })),
                            nextAction: action.nextAction.map((next: any) => ({
                              ...next,
                              actionId:
                                next.actionId !== null
                                  ? `${next.actionId}_${actIdSub}`
                                  : action.id.includes("idout")
                                    ? target
                                    : null,
                            })),
                          };
                        });
                        const firstAction = updatedActions.find((act: any) => act.id.includes("id2"));
                        handleAddSub(firstAction, updatedActions, source, target);
                        await addWorkflowToSubfloq(subfloq.id, workflowConfig.id, workflowConfig.name, "add");
                        return;
                      }

                      let src = addActionModalData?.edgeProps?.source;
                      if (src.includes("id2") && !isSubfloq) src = src.replace("id2", "idout");
                      const sourceAction = actions.find((act) => act.id === src);
                      let target = sourceAction?.nextAction[0]?.actionId ?? null;

                      const actIdSub = uuidv4().toString();

                      const updatedActions = subfloq.actions.map((action: any) => {
                        const actPKId = uuidv4().toString();
                        const actDetails = availableActions.find((act) => act.id === action.actionName) || {};
                        return {
                          ...action,
                          actionDetails: actDetails,
                          id: `${action.id}_${actIdSub}`,
                          pk_id: actPKId,
                          prevAction: action.prevAction.map((prev: any) => ({
                            ...prev,
                            actionId:
                              prev.actionId !== null
                                ? `${prev.actionId}_${actIdSub}`
                                : action.id.includes("id2")
                                  ? addActionModalData?.edgeProps?.source
                                  : null,
                          })),
                          nextAction: action.nextAction.map((next: any) => ({
                            ...next,
                            actionId:
                              next.actionId !== null
                                ? `${next.actionId}_${actIdSub}`
                                : action.id.includes("idout")
                                  ? target
                                  : null,
                          })),
                        };
                      });
                      const firstAction = updatedActions.find((act: any) => act.id.includes("id2"));
                      handleAddSub(firstAction, updatedActions, addActionModalData?.edgeProps?.source, target);
                      await addWorkflowToSubfloq(subfloq.id, workflowConfig.id, workflowConfig.name, "add");
                    }}
                  />
                )}
                <SubfloqCreateModal
                  isOpen={subfloqModalOpen}
                  onClose={() => {
                    setMode("Shift");
                    setSubfloqModalOpen(false);
                    setAddActionModalData({ ...addActionModalData, open: false });
                  }}
                  selectedNodes={selectedNodes}
                  onAddSubfloq={handleAddSub}
                />
              </div>
            )}
          </Popover.Content>
        </Popover.Root>
      </>
    );
  }
);

export default AddNewNodes;

import { Breadcrumb, message, Select, Table } from "antd";
import { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { deleteConnection, fetchListConnections, updateListConnectionStatus } from "../apis";
import { getUserWorkflows } from "@/utils/apis";
import moment from "moment";
import { capitalize } from "lodash";
import Connection from "../Modals/Connection";
import { CollapsibleIcon } from "@/Components/NavbarNew/CollapsibleIcon";
import { FiPlus, FiWifi } from "react-icons/fi";
import SearchInput from "../Components/SearchInput";
import DeleteIconNew from "@Assets/SVGs/DeleteIconNew.svg";
import EditIconBlue from "@Assets/SVGs/EditIconBlue.svg";
import PlusIconBlack from "@Assets/SVGs/PlusIconBlack.svg";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import { CreateWorkflowModal } from "@/Components/Workflow/ActionModals/ModalComponents/CreateWorkflowModal";
import { PayloadConfiguration, ResponseConfiguration } from "@/utils/interfaces";
import PauseIcon from "@Assets/SVGs/PauseIcon.svg";
import PlayIcon from "@Assets/SVGs/PlayIcon.svg";

type Props = {
  responseStructure: any[];
  watcherName: string;
};

const STATUS_COLORS = {
  active: "bg-green-500",
  terminated: "bg-red-500",
  paused: "bg-yellow-500",
};

const TABLE_COLUMNS: any[] = [
  { title: <div className="pl-4">Workflows</div>, dataIndex: "name", key: "name", width: "40%", ellipsis: true },
  { title: "Leads sent to workflow", dataIndex: "leads_pushed", key: "leads_pushed", width: "15%" },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    widht: "15%",
    render: (params: any) => (
      <div className="flex items-center gap-2">
        <div className={`w-2 h-2 rounded-full ${STATUS_COLORS[params as keyof typeof STATUS_COLORS]}`}></div>
        <p className="text-sm font-normal">{capitalize(params)}</p>
      </div>
    ),
  },
  {
    title: "Sync started on",
    dataIndex: "created_at",
    key: "created_at",
    width: "20%",
    render: (params: any) => <div>{moment(params).utc().local().format("DD MMM, YYYY")}</div>,
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    width: "180px",
    fixed: "right" as const,
  },
];

const ListConnections = ({ responseStructure, watcherName }: Props) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [pauseLoader, setPauseLoader] = useState<any>({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openCreateWorkflowModal, setOpenCreateWorkflowModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState<Record<string, boolean>>({});
  const [removeMapping, setRemoveMapping] = useState(false);
  const [update, setUpdate] = useState<string | undefined>(undefined);
  const [selectValue, setSelectValue] = useState<string | undefined>(undefined);
  const [connections, setConnections] = useState<any[]>([]);
  const [workflows, setWorkflows] = useState<any[]>([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState<any>(
    update ? workflows.find((workflow) => workflow.id === update) : undefined
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredConnections, setFilteredConnections] = useState<any[]>([]);

  /* states for create new workflow modal */
  const [createWorkflowParams, setCreateWorkflowParams] = useState<{
    name: string;
    type: string;
  }>({
    name: "",
    type: "v2",
  });
  const [, setNewWorkflows] = useState<{
    loading: boolean;
    workflows: any[];
  }>({
    loading: false,
    workflows: [],
  });
  const [newWorkflowId, setNewWorkflowId] = useState<string>("");
  const [responses] = useState<ResponseConfiguration[]>([]);
  const [payloads, setPayloads] = useState<PayloadConfiguration[]>([]);

  useEffect(() => {
    if (!newWorkflowId) return;
    const fetchAndSetWorkflow = async () => {
      setLoading(true);
      try {
        const flows = await getUserWorkflows(true);
        const firebase = flows.workflows;
        const supabase = flows.workflowsV2;
        const allWorkflows: any[] = (firebase?.workflows || [])
          .concat(supabase?.workflows || [])
          .map((workflow: any) => {
            if ((firebase?.workflows || []).find((w: any) => w.id === workflow.id)) workflow.supabase = false;
            else workflow.supabase = true;
            return workflow;
          });
        allWorkflows.sort((a: any, b: any) => {
          const aDate = moment.utc(a.createdAt);
          const bDate = moment.utc(b.createdAt);

          if (aDate.isAfter(bDate)) return -1;
          if (aDate.isBefore(bDate)) return 1;
          return 0;
        });

        setWorkflows(allWorkflows);

        const selectedWorkflow = allWorkflows.find((workflow) => workflow.id === newWorkflowId);
        if (selectedWorkflow) {
          setSelectedWorkflow(selectedWorkflow);
          setSelectValue(selectedWorkflow.id);
          setOpenDrawer(true);
          setNewWorkflowId("");
        }
      } catch (error) {
        message.error("Failed to fetch workflows after creating a new one");
      } finally {
        setLoading(false);
      }
    };

    fetchAndSetWorkflow();
  }, [newWorkflowId]);

  useEffect(() => {
    if (!id) return;
    const getConnectionsAndWorkflows = async () => {
      setLoading(true);
      try {
        const [conns, flows] = await Promise.all([fetchListConnections(id), getUserWorkflows(true)]);
        setConnections(conns);

        const firebase = flows.workflows;
        const supabase = flows.workflowsV2;
        const allWorkflows: any[] = (firebase?.workflows || [])
          .concat(supabase?.workflows || [])
          .map((workflow: any) => {
            if ((firebase?.workflows || []).find((w: any) => w.id === workflow.id)) workflow.supabase = false;
            else workflow.supabase = true;
            return workflow;
          });
        allWorkflows.sort((a: any, b: any) => {
          const aDate = moment.utc(a.createdAt);
          const bDate = moment.utc(b.createdAt);

          if (aDate.isAfter(bDate)) return -1;
          if (aDate.isBefore(bDate)) return 1;
          return 0;
        });
        setWorkflows(allWorkflows);
      } catch (error) {
        message.error("Failed to fetch connections");
      } finally {
        setLoading(false);
      }
    };
    getConnectionsAndWorkflows();
  }, [id]);

  useEffect(() => {
    const filtered = connections.filter((connection) => {
      const workflow = workflows.find((workflow) => workflow.id === connection.workflow_id);
      const name = workflow?.name || workflow?.publishedWorkflowConfig?.name || "";
      return name.toLowerCase().includes(searchTerm.toLowerCase()) && connection.status !== "archived";
    });
    setFilteredConnections(filtered);
  }, [searchTerm, connections, workflows]);

  const handleDeleteConnection = async (id: string, workflowId: string): Promise<void> => {
    setIsDeleting((prev) => ({ ...prev, [workflowId]: true }));
    try {
      const response = await deleteConnection(id, workflowId);

      if (!response.error) {
        setConnections((prev) => prev.filter((connection) => connection.workflow_id !== workflowId));
        setRemoveMapping(true);
        message.success("Sync removed successfully!");
      } else {
        message.error("Failed to remove sync. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting sync:", error);
      message.error("An error occurred while deleting the sync.");
    } finally {
      setIsDeleting((prev) => ({ ...prev, [workflowId]: false }));
    }
  };

  const handleUpdateSync = async (listId: string, workflowId: string, status: string) => {
    setPauseLoader((prev: any) => ({ ...prev, [workflowId]: true }));
    try {
      const updatedConnection = await updateListConnectionStatus(listId, workflowId, status);
      setConnections((prev) =>
        prev.map((conn) => (conn.workflow_id === workflowId ? { ...conn, status: updatedConnection.status } : conn))
      );
      message.success(`Sync ${status === "active" ? "resumed" : "paused"} successfully!`);
    } catch (error: any) {
      console.error(error);
      message.error(error.message || "Failed to update sync");
    } finally {
      setPauseLoader((prev: any) => ({ ...prev, [workflowId]: false }));
    }
  };

  const navigate = useNavigate();
  const location = useLocation();

  const breadcrumbItems = [
    {
      title: (
        <span
          onClick={() => {
            navigate(location.pathname);
            window.location.reload();
          }}
          style={{ cursor: "pointer" }}
        >
          {watcherName}
        </span>
      ),
    },
    {
      title: (
        <span className="flex items-center gap-2 font-medium">
          <FiWifi className="text-[#BBA8FF]" />
          Sync with Floq
        </span>
      ),
    },
  ];

  const tableHeaderStyle = {
    borderBlock: "0.5px solid #333333",
    paddingBlock: "8px",
    background: "#FAFAFA",
  };

  const dropdownFooter = (
    <div className="bg-white border-t-[0.5px] border-[#333333] mt-2">
      <button className="flex items-center font-medium gap-2 p-2 py-3" onClick={() => setOpenCreateWorkflowModal(true)}>
        <img src={PlusIconBlack} alt="add-icon" className="h-5 w-5" />
        Create a new workflow
      </button>
    </div>
  );

  // Filter workflows that are not in connections
  const filteredWorkflows = useMemo(() => {
    return workflows.filter((workflow) => !connections.some((connection) => connection.workflow_id === workflow.id));
  }, [workflows, connections]);

  const tableFooter = (
    <Select
      showSearch
      placeholder="Add Floq to sync with"
      optionFilterProp="children"
      value={selectValue}
      onChange={(value) => {
        const selectedWorkflow = workflows.find((workflow) => workflow.id === value);
        setSelectedWorkflow(selectedWorkflow);
        setSelectValue(value);
        setOpenDrawer(true);
      }}
      onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
        e.target.style.border = "none";
        e.target.style.outline = "none";
        e.target.style.boxShadow = "none";
      }}
      filterOption={(input, option) => {
        const workflow = workflows.find((workflow) => workflow.id === option?.key);
        const name = workflow?.name || workflow?.publishedWorkflowConfig?.name || workflow?.draftWorkflowConfig?.name;
        return name?.toLowerCase().includes(input.toLowerCase());
      }}
      className="relative rounded-[5px] flex items-center p-1 h-[50px] w-[300px] bg-[#F3F4F6] text-[#8D8D8D] ml-2"
      suffixIcon={<FiPlus className="text-[#333333] text-lg mr-1" />}
      dropdownStyle={{
        borderRadius: "10px",
        border: "0.5px solid #333333",
        boxShadow: "0 0 0 3.5px #dadada",
      }}
      dropdownRender={(menu) => (
        <>
          {menu}
          {dropdownFooter}
        </>
      )}
    >
      {filteredWorkflows?.map((workflow) => (
        <Select.Option key={workflow.id} value={workflow.id}>
          {workflow.name || workflow.publishedWorkflowConfig?.name}
        </Select.Option>
      ))}
    </Select>
  );

  return (
    <div className="h-full w-full flex flex-col !cursor-default gap-5 font-favorit">
      <div className="w-full flex items-center justify-between px-8 min-h-[50px] z-30 sticky top-0 bg-white border-b-[0.5px] border-[#D3D3D3]">
        {/* Breadcrumb */}
        <span className="flex gap-4">
          <CollapsibleIcon isHeader />
          <span className=" font-medium text-center text-[#3F3F3F] flex gap-[5px] max-h-[24px]">
            <Breadcrumb className="text-base font-favorit">
              {breadcrumbItems.map((item, index) => (
                <Breadcrumb.Item key={index}>{item.title}</Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </span>
        </span>
      </div>
      <div className="flex items-center justify-between px-8 min-h-[40px]">
        <h2 className="flex items-center gap-2 font-medium text-2xl text-[#333333]">
          <FiWifi />
          Syncs
        </h2>
        <span className="flex items-center gap-1">
          <SearchInput placeholder="Search for workflows" onSearch={setSearchTerm} />
        </span>
      </div>
      <Table
        loading={loading}
        dataSource={filteredConnections.map((connection) => {
          const { workflow_id, created_at, leads_pushed, status } = connection;
          const { name, publishedWorkflowConfig, supabase } =
            workflows.find((workflow) => workflow.id === workflow_id) || {};
          return {
            key: connection.id,
            name: (
              <Link
                to={`/workflow/${workflow_id}?v2=${supabase}`}
                target="_blank"
                className="text-base tracking-[0.02em]"
              >
                <h3 className="font-normal truncate pl-4">{name || publishedWorkflowConfig.name}</h3>
              </Link>
            ),
            status,
            created_at,
            leads_pushed,
            actions: (
              <div className="flex items-center justify-center rounded gap-2 py-1 border-[0.5px] border-[#D5D5D5]">
                <button
                  title="Edit Sync"
                  onClick={() => {
                    setUpdate(workflow_id);
                    setOpenDrawer(true);
                  }}
                >
                  <img src={EditIconBlue} alt="delete" className="h-6 w-6" />
                </button>
                <div className="h-6 border-r border-gray-300 mx-1" />
                {pauseLoader[workflow_id] ? (
                  <span className="h-6 w-6 flex justify-center items-center">
                    <SpinnerStatus />
                  </span>
                ) : status === "active" ? (
                  <button title="Pause Sync" onClick={() => id && handleUpdateSync(id, workflow_id, "paused")}>
                    <img src={PauseIcon} alt="pause" className="h-6 w-6" />
                  </button>
                ) : (
                  <button title="Resume Sync" onClick={() => id && handleUpdateSync(id, workflow_id, "active")}>
                    <img src={PlayIcon} alt="play" className="h-6 w-6" />
                  </button>
                )}
                <div className="h-6 border-r border-gray-300 mx-1" />
                {isDeleting[workflow_id] ? (
                  <span className="h-6 w-6 flex justify-center items-center">
                    <SpinnerStatus />
                  </span>
                ) : (
                  <button title="Remove Sync" onClick={() => id && handleDeleteConnection(id, workflow_id)}>
                    <img src={DeleteIconNew} alt="delete" className="h-6 w-6" />
                  </button>
                )}
              </div>
            ),
          };
        })}
        columns={TABLE_COLUMNS.map((column) => ({
          ...column,
          title: <div className="font-favorit text-[#828282] font-normal text-sm">{column.title}</div>,
          className: "font-favorit",
        }))}
        className="font-favorit"
        components={{
          header: {
            cell: (props: any) => (
              <th
                {...props}
                style={{
                  ...tableHeaderStyle,
                  ...props.style,
                }}
              />
            ),
          },
        }}
        footer={() => tableFooter}
      />
      <Connection
        open={openDrawer}
        update={update}
        removeMapping={removeMapping}
        close={() => {
          setRemoveMapping(true);
          if (!update) {
            setSelectedWorkflow(undefined);
          }
          setUpdate(undefined);
          setSelectValue(undefined);
          setOpenDrawer(false);
        }}
        existingConnections={connections}
        workflows={workflows}
        setWorkflows={setWorkflows}
        loading={loading}
        selectedWorkflow={selectedWorkflow}
        setSelectedWorkflow={(workflow: any) => setSelectedWorkflow(workflow)}
        responseStructure={responseStructure}
        addConnection={(connection: any) => setConnections([connection, ...connections])}
        updateExistingConnection={(connection: any) => {
          const updatedConnections = connections.map((conn) =>
            conn.workflow_id === connection.workflow_id ? connection : conn
          );
          setConnections(updatedConnections);
        }}
      />
      <CreateWorkflowModal
        modal={openCreateWorkflowModal}
        createWorkflowParams={createWorkflowParams}
        responses={responses}
        payloads={payloads}
        setPayloads={setPayloads}
        setSelectedWorkflow={setNewWorkflowId}
        setModal={setOpenCreateWorkflowModal}
        setCreateWorkflowParams={setCreateWorkflowParams}
        setWorkflows={setNewWorkflows}
        isCreateOnly={true}
      />
    </div>
  );
};

export default ListConnections;

import DeleteAction from "@/assets/SVGs/DeleteAction";
import { finalConditions, getIconFromType, inputTypeFromVariableType, setStateType } from "@/utils/constants";
import { ResponseStructure } from "@/utils/interfaces";
import { Select } from "antd";
import { useEffect, useState, useRef } from "react";
import OtherwiseModal from "./OtherwiseModal";
import ConditionalInput from './ConditionalInput';
import { TagInputRef } from "@/Components/Common/TagInput";

type Props = {
  variables: any[];
  responses: ResponseStructure[];
  conditionsArr: {
    conditions: any[];
    otherwise: any[];
    operator?: string;
  };
  setConditionsArr: setStateType<any>;
};

const RunCondition = ({ variables, responses, conditionsArr, setConditionsArr }: Props) => {
  const [options, setOptions] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const tagInputRefs = useRef<Record<string, TagInputRef | null>>({});

  useEffect(() => {
    if (!variables) return;
    setOptions([]);
    variables.forEach((action) => {
      action?.variables?.forEach((variable: ResponseStructure) => {
        const option = {
          id: variable.responseId,
          logo: action.logo,
          type: variable.type || "string",
          name: variable.name,
          actionName: action.name,
        };
        setOptions((prev) => [...prev, option]);
      });
    });
  }, [variables]);

  // Function to process all tag inputs before saving
  const processAllTagInputs = () => {
    Object.values(tagInputRefs.current).forEach(ref => {
      if (ref) {
        ref.processRemainingInput();
      }
    });
  };

  // Function to handle input changes in tag inputs
  const handleTagInputChange = (parentIdx: number, idx: number, hasInput: boolean, inputValue: string) => {
    if (hasInput) {
      // Create a temporary value for the current input
      const temp = { ...conditionsArr };
      
      // Store the current input value in a temporary field
      // This won't be visible in the UI but will be part of the state
      if (!temp.conditions[parentIdx].conditions[idx]._tempInputValue) {
        temp.conditions[parentIdx].conditions[idx]._tempInputValue = inputValue;
      } else if (temp.conditions[parentIdx].conditions[idx]._tempInputValue !== inputValue) {
        temp.conditions[parentIdx].conditions[idx]._tempInputValue = inputValue;
      }
      
      setConditionsArr(temp);
    }
  };

  return (
    <div className="flex flex-col border-2 p-3 rounded-lg">
      <div className="flex flex-col gap-2 mb-4">
        <h2 className="text-lg font-semibold">Run this only if</h2>
        <p className="text-sm text-gray-500">Run this only if</p>
      </div>
      <div className="bg-white gap-10 flex flex-col">
        {conditionsArr?.conditions?.map((conditionGroup, parentIdx) => (
          <div key={parentIdx} className="flex flex-col gap-2 bg-gray-100/20 p-2 border border-1 rounded-md">
            <div className="flex gap-2 w-full items-center">
              {parentIdx !== 0 && (
                <div className="flex gap-2 w-full items-center">
                  <p className="tracking-primary font-semibold">Operator</p>
                  <Select
                    showSearch
                    value={conditionGroup.operator || "AND"}
                    onChange={(value) => {
                      const temp = { ...conditionsArr };
                      temp.conditions[parentIdx].operator = value;
                      setConditionsArr(temp);
                    }}
                    options={[
                      {
                        label: "AND",
                        value: "AND",
                      },
                      {
                        label: "OR",
                        value: "OR",
                      },
                    ]}
                  />
                </div>
              )}
              <button
                className="text-red-500 ml-auto"
                onClick={() => {
                  const temp = { ...conditionsArr };
                  temp.conditions.splice(parentIdx, 1);
                  setConditionsArr(temp);
                }}
              >
                <DeleteAction />
              </button>
            </div>
            {conditionGroup.conditions?.map((field: any, idx: number) => (
              <div key={idx} className="flex gap-4 p-4 border border-gray-300 rounded-lg">
                <div className="w-[90%] flex flex-col">
                  {idx !== 0 && (
                    <div className="flex gap-2 w-full items-center">
                      <p className="tracking-primary font-semibold">Operator</p>
                      <Select
                        showSearch
                        value={field.operator || "AND"}
                        onChange={(value) => {
                          const temp = { ...conditionsArr };
                          temp.conditions[parentIdx].conditions[idx].operator = value;
                          setConditionsArr(temp);
                        }}
                        options={[
                          {
                            label: "AND",
                            value: "AND",
                          },
                          {
                            label: "OR",
                            value: "OR",
                          },
                        ]}
                      />
                    </div>
                  )}
                  <div className="flex gap-2 w-full">
                    <p className="tracking-primary font-semibold">If</p>
                    <div className="w-full flex flex-col gap-2">
                      <Select
                        showSearch
                        allowClear
                        className="w-[95%]"
                        value={field.variable}
                        onClear={() => {
                          const temp = { ...conditionsArr };
                          temp.conditions[parentIdx].conditions[idx] = {
                            variable: "",
                            condition: "",
                            variableType: "",
                            values: [],
                            caseSensitive: false,
                            operator: temp.conditions[parentIdx].conditions[idx].operator,
                          };
                          setConditionsArr(temp);
                        }}
                        onChange={(value) => {
                          const temp = { ...conditionsArr };
                          const option = options.find((option) => option.id === value);
                          if (field.variableType === option.type)
                            temp.conditions[parentIdx].conditions[idx].variable = value;
                          else
                            temp.conditions[parentIdx].conditions[idx] = {
                              variable: value,
                              condition: "",
                              variableType: option.type,
                              values: [],
                              operator: temp.conditions[parentIdx].conditions[idx].operator,
                              caseSensitive: temp.conditions[parentIdx].conditions[idx].caseSensitive,
                            };
                          setConditionsArr(temp);
                        }}
                        options={options.map((option, index: number) => ({
                          label: (
                            <div className="flex min-w-full flex-row items-center text-sm gap-2" key={index}>
                              <img src={option.logo} alt="logo" className="w-6 h-6" />
                              <span className="text-gray-400 text-xs max-w-[50%] whitespace-nowrap overflow-hidden text-ellipsis">
                                {option.actionName}
                              </span>
                              <p className="">{">"}</p>
                              <p className="flex items-center gap-2 justify-center">
                                <div className="bg-gray-200 p-1 rounded">{getIconFromType(option.type)}</div>
                                {option.name}
                              </p>
                            </div>
                          ),
                          value: option.id,
                          name: `${option.actionName} - ${option.name}`,
                        }))}
                        optionFilterProp="name"
                      />
                      {field.variableType !== "" && (
                        <Select
                          showSearch
                          allowClear
                          className="w-[95%]"
                          value={field.condition}
                          onChange={(value) => {
                            const temp = { ...conditionsArr };
                            temp.conditions[parentIdx].conditions[idx].condition = value;
                            setConditionsArr(temp);
                          }}
                          options={finalConditions(field.variableType) || []}
                        />
                      )}
                      {!(
                        field.condition === "" ||
                        field.condition === "is empty" ||
                        field.condition === "is not empty"
                      ) && (
                        <div className="w-[95%]">
                          <ConditionalInput
                            field={field}
                            onChange={({ values, caseSensitive }) => {
                              const temp = { ...conditionsArr };
                              if (values !== undefined) {
                                temp.conditions[parentIdx].conditions[idx].values = values;
                                // Clear temporary input value when tags are committed
                                if (temp.conditions[parentIdx].conditions[idx]._tempInputValue) {
                                  delete temp.conditions[parentIdx].conditions[idx]._tempInputValue;
                                }
                              }
                              if (temp.conditions[parentIdx].conditions[idx].caseSensitive === undefined) {
                                temp.conditions[parentIdx].conditions[idx].caseSensitive = false;
                              }
                              if (caseSensitive !== undefined) {
                                temp.conditions[parentIdx].conditions[idx].caseSensitive = caseSensitive;
                              }
                              setConditionsArr(temp);
                            }}
                            ref={(el) => {
                              // Store a reference to each TagInput inside ConditionalInput
                              tagInputRefs.current[`${parentIdx}-${idx}`] = el;
                            }}
                            onInputChange={(hasInput, inputValue) => {
                              handleTagInputChange(parentIdx, idx, hasInput, inputValue || '');
                            }}
                          />
                        </div>
                      )}
                      {field.condition === "is between" && (
                        <>
                          <p>To:</p>
                          <input
                            type={inputTypeFromVariableType(field.variableType)}
                            placeholder="value 2..."
                            className="w-[95%] rounded-md p-1 px-2 border-gray-200"
                            value={field.values?.[1] || ""}
                            onChange={(e) => {
                              const temp = { ...conditionsArr };
                              temp.conditions[parentIdx].conditions[idx].values[1] = e.target.value;
                              setConditionsArr(temp);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {idx !== 0 && (
                  <button
                    className="text-red-500 ml-auto"
                    onClick={() => {
                      const temp = { ...conditionsArr };
                      temp.conditions[parentIdx].conditions.splice(idx, 1);
                      setConditionsArr(temp);
                    }}
                  >
                    <DeleteAction />
                  </button>
                )}
              </div>
            ))}
            <button
              className="border w-fit border-black rounded-lg p-2"
              onClick={() => {
                const temp = { ...conditionsArr };
                temp.conditions[parentIdx].conditions.push({
                  variable: "",
                  condition: "",
                  variableType: "",
                  values: [],
                  operator: "AND",
                  caseSensitive: false
                });
                setConditionsArr(temp);
              }}
            >
              + Add Sub-Condition
            </button>
          </div>
        ))}
        <button
          className="border w-fit border-black rounded-lg p-2"
          onClick={() => {
            const temp = { ...conditionsArr };
            temp.conditions.push({
              conditions: [
                {
                  variable: "",
                  condition: "",
                  variableType: "",
                  values: [],
                  operator: "AND",
                  caseSensitive: false
                },
              ],
            });
            setConditionsArr(temp);
          }}
        >
          + Add condition
        </button>
        {conditionsArr.conditions && conditionsArr.conditions.length > 0 && responses && Array.isArray(responses) && (
          <div className="text-sm text-gray-500">
            Otherwise: &nbsp;
            <button className="text-primary hover:underline" onClick={() => setOpenModal(true)}>
              Add Response
            </button>
          </div>
        )}
      </div>
      <OtherwiseModal
        open={openModal}
        setOpen={setOpenModal}
        responses={responses}
        variables={variables}
        values={conditionsArr.otherwise}
        saveValues={(values) => {
          // Process all tag inputs before saving
          processAllTagInputs();
          
          // Small delay to ensure processing is complete
          setTimeout(() => {
            setConditionsArr({
              ...conditionsArr,
              otherwise: values,
            });
          }, 50);
        }}
      />
    </div>
  );
};

export default RunCondition;

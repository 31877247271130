import { ConfigProvider, Drawer, Select } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Action, PayloadConfiguration, PayloadStructure, ResponseConfiguration } from "../../../utils/interfaces";
import { getExpectedTypeFromName, INPUT_TYPES, setStateType } from "../../../utils/constants";
import { useWorkflow } from "../../../contexts/WorkflowContext";
import { Link, useParams } from "react-router-dom";
import { FaCheck, FaExternalLinkAlt } from "react-icons/fa";
import { MdChevronLeft, MdChevronRight, MdContentPaste, MdDeleteOutline } from "react-icons/md";
import styles from "./Components.module.css";
import { PiCopySimpleThin } from "react-icons/pi";
import { useRunOnFloq } from "@/contexts/RunOnFloqContext";
import TiptapField from "../Tiptap/TiptapField";
import { v4 as uuidv4 } from "uuid";
import ResponseStructureViewer from "./ModalComponents/ResponseStructure";

type Props = {
  action: Action;
  modal: boolean;
  setModal: setStateType<boolean>;
  responseConfiguration: ResponseConfiguration[];
  payloadConfiguration: PayloadConfiguration[];
  variables?: any[];
};

const SubfloqConfigModal = ({
  action,
  modal,
  setModal,
  responseConfiguration,
  payloadConfiguration,
  variables = [],
}: Props) => {
  const uid = action.id.includes("id2") ? action.id.split("id2_")[1] : action.id.split("idout_")[1];
  const [loading, setLoading] = useState(false);
  const [responses, setResponses] = useState<any[]>(responseConfiguration);
  const [payloads, setPayloads] = useState<PayloadConfiguration[]>(payloadConfiguration);
  const [isCopied, setIsCopied] = useState(false);
  const [isPasted, setIsPasted] = useState(false);
  const [subfloqOutput, setSubfloqOutput] = useState<ResponseConfiguration[]>([]);
  const { collapseConfig, setCollapseConfig } = useRunOnFloq();
  const [drawerWidth, setDrawerWidth] = useState(collapseConfig ? "3rem" : "30%");
  const [updateField, setUpdateField] = useState(0);
  const { actions, updateResponseConfig, updatePayloadConfig, saveWorkflowActions, setPublishWarning, isSubfloq } =
    useWorkflow();
  const { id } = useParams();

  useEffect(() => {
    setResponses(responseConfiguration);
  }, [responseConfiguration]);

  useEffect(() => {
    setPayloads(payloadConfiguration);
    setUpdateField((prev) => prev + 1);
  }, [payloadConfiguration]);

  useEffect(() => {
    if (!action?.id.includes("id2") || isSubfloq) return;
    const act = actions.find((act: any) => act.id === `idout_${uid}`);
    if (!act) return;
    setSubfloqOutput(act.responseConfiguration);
  }, []);

  useEffect(() => {
    setWidthPercent();
    window.addEventListener("resize", setWidthPercent);
    return () => window.removeEventListener("resize", setWidthPercent);
  }, [collapseConfig]);

  const setWidthPercent = useCallback(() => {
    try {
      if (collapseConfig) setDrawerWidth("3rem");
      // Adjust width for different screen sizes
      else if (window.matchMedia("(min-width: 1024px) and (min-height: 1366px)").matches)
        // iPad Pro and similar devices
        setDrawerWidth("50%");
      else if (window.matchMedia("(min-width: 1024px)").matches) setDrawerWidth("30%");
      else if (window.matchMedia("(min-width: 640px)").matches) setDrawerWidth("50%");
      else setDrawerWidth("100%");
    } catch (err) {
      console.error("Error calculating width percent based on media size");
    }
  }, [collapseConfig]);

  const saveOutputStructure = async () => {
    const filteredResponses = responses?.filter((res) => {
      return !!res?.name;
    });
    setLoading(true);
    const updated = updateResponseConfig(filteredResponses, action.id);
    if (action?.id.includes("id2")) updateResponseConfig(subfloqOutput, `idout_${uid}`);
    updatePayloadConfig(payloads, action.id, false);
    setResponses(updated);
    await saveWorkflowActions(id || "");
    setPublishWarning(true);
    setLoading(false);
  };

  const handleCopy = async (responses: ResponseConfiguration[]) => {
    setIsCopied(true);
    try {
      const names = responses.map((item) => item.name);
      const clipboardText = names.join("\t");
      await navigator.clipboard.writeText(clipboardText);
    } catch (err) {
      console.error("Failed to copy to clipboard:", err);
    }
    setTimeout(() => setIsCopied(false), 1500);
  };

  const handlePaste = async (e: any, index: number, field: string) => {
    setIsPasted(true);
    e.preventDefault();

    let pastedData;
    if (e.clipboardData) pastedData = e.clipboardData.getData("text");
    else if (navigator.clipboard) pastedData = await navigator.clipboard.readText();
    else console.error("unable to paste");

    const lines = pastedData.split("\t").filter((line: any) => line.trim() !== "");
    const updatedRows: any[] = [...responses];

    // Update the rows starting from the pasted input's index
    lines.forEach((line: any, lineIndex: number) => {
      // Update existing rows
      if (index + lineIndex < responses.length) {
        updatedRows[index + lineIndex][field] = line;
        updatedRows[index + lineIndex].type = getExpectedTypeFromName(line);
      } else {
        // Add new rows if needed
        const newRow: any = { name: "", description: "" };
        newRow[field] = line;
        newRow.type = getExpectedTypeFromName(line);
        updatedRows.push(newRow);
      }
    });

    setResponses(updatedRows);
    setTimeout(() => setIsPasted(false), 1500);
  };

  const fieldSwitch = (field: PayloadStructure) => {
    switch (field.type) {
      case "subfloqInputArray":
        return (
          <div
            className={`flex flex-col w-full justify-start items-start ${isSubfloq ? "border border-gray-300" : ""} rounded-xl`}
          >
            {isSubfloq && (
              <div className="flex justify-between w-full items-center px-3 py-2 font-bold">
                <div className="w-full flex gap-x-2 items-center">
                  <p>Inputs</p>
                  <div
                    className="flex gap-x-1 items-center bg-[#ECEBFF] border border-white hover:border-[#C6C4FF] rounded-md text-primary p-1 group cursor-pointer h-[26px]"
                    onClick={(e) => handlePaste(e, 0, "name")}
                  >
                    {isPasted ? <FaCheck className="text-primary" /> : <MdContentPaste color="primary" size={16} />}
                    <p className="text-xs opacity-0 max-w-0 -ml-1 group-hover:ml-0 overflow-hidden group-hover:opacity-100 group-hover:max-w-xs transition-all duration-500 ease-in-out">
                      Paste
                    </p>
                  </div>
                  <div
                    className="flex gap-x-1 items-center bg-[#ECEBFF] border border-white hover:border-[#C6C4FF] rounded-md text-primary p-1 group cursor-pointer h-[26px]"
                    onClick={() => handleCopy(responses)}
                  >
                    {isCopied ? <FaCheck className="text-primary" /> : <PiCopySimpleThin color="prirmary" size={16} />}
                    <p className="text-xs opacity-0 max-w-0 -ml-1 group-hover:ml-0 overflow-hidden group-hover:opacity-100 group-hover:max-w-xs transition-all duration-500 ease-in-out">
                      Copy
                    </p>
                  </div>
                </div>
                <div className="w-full text-left">Type of field</div>
              </div>
            )}
            {payloads.map((payload, index) => {
              return (
                <div
                  className={`w-full ${isSubfloq ? "flex" : "flex-col"} justify-between gap-1 items-end ${isSubfloq ? "border border-gray-300" : ""} p-1`}
                  key={index}
                >
                  <div className="w-full pr-8 pb-1">
                    <input
                      className={`header-input w-full h-4 px-2 py-4 rounded border-none focus:outline-none focus:ring-0 focus:border-transparent ${!isSubfloq ? "capitalize font-bold" : ""}`}
                      type="text"
                      name="name"
                      disabled={!isSubfloq}
                      value={payload.name}
                      onChange={(e) => {
                        const temp = [...payloads];
                        temp[index].name = e.target.value;
                        if (!temp[index].type || temp[index].type === "string")
                          temp[index].type = getExpectedTypeFromName(e.target.value);
                        setPayloads(temp);

                        const temp2 = [...responses];
                        temp2[index].name = e.target.value;
                        if (!temp2[index].type || temp2[index].type === "string")
                          temp2[index].type = getExpectedTypeFromName(e.target.value);
                        setResponses(temp2);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          const temp = [...payloads];
                          const id = payload?.payloadStructureId || uuidv4();
                          temp.push({
                            name: "",
                            description: "",
                            payloadStructureId: id,
                            inputString: "",
                          });
                          setPayloads(temp);

                          const temp2 = [...responses];
                          temp2.push({
                            name: "",
                            responseId: id,
                          });
                          setResponses(temp2);
                          setTimeout(() => {
                            const inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll(".header-input");
                            const lastInput = inputs[inputs.length - 1];
                            lastInput.focus();
                          }, 100);
                        }
                      }}
                      onPaste={(e) => handlePaste(e, index, "name")}
                      placeholder="Name of the Field"
                    />
                  </div>
                  <div className="w-full flex items-center">
                    {isSubfloq ? (
                      <Select
                        showSearch
                        className={`w-full rounded ${styles.selectInputDropdown}`}
                        value={payload.type || "string"}
                        onChange={(value) => {
                          const temp = [...payloads];
                          temp[index].type = value;
                          setPayloads(temp);

                          const temp2 = [...responses];
                          temp2[index].type = value;
                          setResponses(temp2);
                        }}
                        options={INPUT_TYPES}
                        style={{ border: "none" }}
                      />
                    ) : (
                      <div className="w-full flex items-center gap-2">
                        <TiptapField
                          content={payload.tiptapJson || ""}
                          setContent={(content: any, text: string) => {
                            const temp = [...payloads];
                            temp[index] = {
                              ...temp[index], // Preserve other fields
                              tiptapJson: content,
                              inputString: text,
                            };
                            setPayloads(temp);
                          }}
                          refresh={0}
                          placeholder={"Output variable mapping"}
                          variables={variables || []}
                        />
                      </div>
                    )}
                    {isSubfloq && (
                      <button
                        className="hover:bg-red-600/10 h-fit w-fit p-1 rounded-sm text-primary"
                        onClick={() => {
                          const temp = [...payloads];
                          temp.splice(index, 1);
                          setPayloads(temp);

                          const temp2 = [...responses];
                          temp2.splice(index, 1);
                          setResponses(temp2);
                        }}
                      >
                        <MdDeleteOutline size={16} color="red" />
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
            {isSubfloq && (
              <button
                className="p-2 font-bold"
                onClick={() => {
                  const temp = [...payloads];
                  const id = uuidv4();
                  temp.push({
                    name: "",
                    description: "",
                    payloadStructureId: id,
                    inputString: "",
                  });
                  setPayloads(temp);

                  const temp2 = [...responses];
                  temp2.push({
                    name: "",
                    description: "",
                    responseId: id,
                  });
                  setResponses(temp2);
                }}
              >
                + Add input
              </button>
            )}
          </div>
        );
      case "outputArray":
        return (
          <div className="flex flex-col w-full justify-center items-start border border-gray-300 rounded-xl">
            <div className="flex justify-between w-full items-center px-3 py-2 font-bold">
              <div className="w-[45%] flex gap-x-2 items-center">
                <p>Output Column</p>
              </div>
            </div>
            {payloads.map((payload, index) => {
              return (
                <div
                  className="w-full flex-col justify-center gap-1 items-end border border-gray-300 p-1 mt-2"
                  key={index}
                >
                  <div className="w-full pr-8 pb-1">
                    <input
                      className="header-input w-full h-4 px-2 py-4 rounded border border-gray-300 focus:outline-none focus:ring-0 focus:border-gray-500"
                      type="text"
                      name="name"
                      value={payload.name}
                      onChange={(e) => {
                        const temp = [...payloads];
                        temp[index].name = e.target.value;
                        if (!temp[index].type || temp[index].type === "string")
                          temp[index].type = getExpectedTypeFromName(e.target.value);
                        // temp[index].payloadStructureId = payload.payloadStructureId || uuidv4();
                        setPayloads(temp);

                        const temp2 = [...responses];
                        temp2[index].name = e.target.value;
                        if (!temp2[index].type || temp2[index].type === "string")
                          temp2[index].type = getExpectedTypeFromName(e.target.value);
                        // temp2[index].responseStructureId = "";
                        setResponses(temp2);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          const temp = [...payloads];
                          const id = uuidv4();
                          temp.push({
                            name: "",
                            description: "",
                            inputString: "", // Ensure this exists
                            payloadStructureId: id,
                          });
                          setPayloads(temp);

                          const temp2 = [...responses];
                          temp2.push({
                            name: "",
                            responseId: id,
                          });
                          setResponses(temp2);
                          setTimeout(() => {
                            const inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll(".header-input");
                            const lastInput = inputs[inputs.length - 1];
                            lastInput.focus();
                          }, 100);
                        }
                      }}
                      placeholder="Name of the Field"
                    />
                  </div>
                  <div className="w-full flex items-center gap-2">
                    <TiptapField
                      content={payload.tiptapJson || ""}
                      setContent={(content: any, text: string) => {
                        const temp = [...payloads];
                        temp[index] = {
                          ...temp[index], // Preserve other fields
                          tiptapJson: content,
                          inputString: text,
                        };
                        setPayloads(temp);
                      }}
                      refresh={0}
                      placeholder={"Output variable mapping"}
                      variables={variables || []}
                    />
                    {isSubfloq && (
                      <button
                        className="hover:bg-red-600/10 h-fit w-fit p-1 text-primary rounded-full"
                        onClick={() => {
                          const temp = [...payloads];
                          temp.splice(index, 1);
                          setPayloads(temp);

                          const temp2 = [...responses];
                          temp2.splice(index, 1);
                          setResponses(temp2);
                        }}
                      >
                        <MdDeleteOutline size={16} color="red" />
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
            {isSubfloq && (
              <button
                className="p-2 font-bold"
                onClick={() => {
                  const temp = [...payloads];
                  const id = uuidv4();
                  temp.push({
                    name: "",
                    description: "",
                    payloadStructureId: id,
                    inputString: "",
                  });
                  setPayloads(temp);

                  const temp2 = [...responses];
                  temp2.push({
                    name: "",
                    description: "",
                    responseId: id,
                  });
                  setResponses(temp2);
                }}
              >
                + Add output
              </button>
            )}
          </div>
        );
      default:
        return (
          <textarea
            className="w-full h-32 rounded-lg"
            placeholder="Enter the value"
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""}
            onChange={(e) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: e.target.value,
                  type: field.type,
                });
              } else {
                temp[index].inputString = e.target.value;
              }
              setPayloads(temp);
            }}
          />
        );
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={modal}
        mask={false}
        onClose={() => {
          saveOutputStructure();
          setModal(false);
        }}
        width={drawerWidth}
        styles={{
          content: {
            transition: "width 0.3s",
          },
          body: {
            padding: "0",
          },
        }}
        classNames={{ header: !collapseConfig ? "border-b !border-gray-300" : "!border-none" }}
        className="ml-auto !font-favorit !p-0 border-l border-gray-500"
        getContainer={false}
        title={
          !collapseConfig && (
            <div className="flex items-center gap-2">
              <button className="p-0 border border-gray-300 rounded-sm" onClick={() => setCollapseConfig(true)}>
                <MdChevronRight size={25} />
              </button>
              <img src={action.logo} alt={action.name} className="w-6 h-6" />
              <Link
                to={`/subfloqs/${action.subfloq_id}?action=build&v2=true`}
                className="hover:underline hover:text-black flex items-center gap-1"
                target="_blank"
              >
                {action.name}
                <FaExternalLinkAlt size={12} className="text-black" />
              </Link>
            </div>
          )
        }
        footer={
          !collapseConfig && (
            <button
              className={`w-full text-white rounded-lg p-2 mt-auto ${
                loading ? "cursor-not-allowed bg-gray-500" : "bg-primary cursor-pointer"
              }`}
              onClick={saveOutputStructure}
              disabled={loading}
            >
              {loading ? "Saving..." : "Save"}
            </button>
          )
        }
      >
        {collapseConfig ? (
          <button
            className="w-full h-full flex flex-col items-center justify-center p-3 gap-3"
            onClick={() => setCollapseConfig(false)}
          >
            <span className="p-0 border border-gray-300 rounded-sm">
              <MdChevronLeft size={25} />
            </span>
            <p className="[writing-mode:sideways-lr] text-center">CONFIG</p>
          </button>
        ) : (
          <div className="h-full bg-white flex flex-col">
            {action.payloadStructure.map((field, idx) => {
              return (
                <>
                  {action.id.includes("id2") && !isSubfloq ? (
                    payloads.map((payload, index) => {
                      return (
                        <div key={index} className="flex flex-col gap-4 p-4 m-5 border border-gray-300 rounded-lg">
                          <p className="tracking-primary font-semibold">{payload.name?.toUpperCase()}</p>
                          <div className="w-full flex items-center">
                            <div className="w-full flex items-center gap-2">
                              <TiptapField
                                content={payload.tiptapJson || ""}
                                setContent={(content: any, text: string) => {
                                  const temp = [...payloads];
                                  temp[index] = {
                                    ...temp[index], // Preserve other fields
                                    tiptapJson: content,
                                    inputString: text,
                                  };
                                  setPayloads(temp);
                                }}
                                refresh={updateField}
                                placeholder={"Output variable mapping"}
                                variables={variables || []}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : action.id.includes("idout") && isSubfloq ? (
                    <div className="flex flex-col w-fit m-5 gap-5 justify-center items-start">
                      {payloads.map((payload, index) => {
                        return (
                          <div key={index} className="flex flex-col w-full gap-4 p-4 border border-gray-300 rounded-lg">
                            <input
                              className="header-input w-full h-4 px-2 py-4 rounded border border-gray-300 focus:outline-none focus:ring-0 focus:border-gray-500"
                              type="text"
                              name="name"
                              value={payload.name}
                              onChange={(e) => {
                                const temp = [...payloads];
                                temp[index].name = e.target.value;
                                if (!temp[index].type || temp[index].type === "string")
                                  temp[index].type = getExpectedTypeFromName(e.target.value);
                                setPayloads(temp);

                                const temp2 = [...responses];
                                temp2[index].name = e.target.value;
                                if (!temp2[index].type || temp2[index].type === "string")
                                  temp2[index].type = getExpectedTypeFromName(e.target.value);
                                setResponses(temp2);
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter" && e.target instanceof HTMLInputElement) e.target.blur();
                              }}
                              placeholder="Name of the Output"
                            />
                            <div className="w-full flex items-center gap-2">
                              <TiptapField
                                content={payload.tiptapJson || ""}
                                setContent={(content: any, text: string) => {
                                  const temp = [...payloads];
                                  temp[index] = {
                                    ...temp[index], // Preserve other fields
                                    tiptapJson: content,
                                    inputString: text,
                                  };
                                  setPayloads(temp);
                                }}
                                refresh={updateField}
                                placeholder={"Output variable mapping"}
                                variables={variables || []}
                              />
                              {isSubfloq && (
                                <button
                                  className="hover:bg-red-600/10 h-fit w-fit p-1 text-primary rounded-full"
                                  onClick={() => {
                                    const temp = [...payloads];
                                    temp.splice(index, 1);
                                    setPayloads(temp);

                                    const temp2 = [...responses];
                                    temp2.splice(index, 1);
                                    setResponses(temp2);
                                  }}
                                >
                                  <MdDeleteOutline size={16} color="red" />
                                </button>
                              )}
                            </div>
                          </div>
                        );
                      })}
                      <button
                        className="p-2 font-bold hover:bg-gray-100 rounded"
                        onClick={() => {
                          const temp = [...payloads];
                          const id = uuidv4();
                          temp.push({
                            name: "",
                            description: "",
                            payloadStructureId: id,
                            inputString: "",
                          });
                          setPayloads(temp);

                          const temp2 = [...responses];
                          temp2.push({
                            name: "",
                            description: "",
                            responseId: id,
                          });
                          setResponses(temp2);
                        }}
                      >
                        + Add output
                      </button>
                    </div>
                  ) : (
                    <div key={idx} className="flex flex-col gap-4 p-4 m-5 border border-gray-300 rounded-lg">
                      {action.id.includes("id2") && isSubfloq && (
                        <div>
                          <p className="tracking-primary font-semibold">
                            {field.name.toUpperCase()}
                            {!field.required && (
                              <span className="text-sm font-normal tracking-normal text-gray-400"> (Optional)</span>
                            )}
                          </p>
                          <p className="text-sm font-light">{field.description}</p>
                        </div>
                      )}
                      {fieldSwitch(field)}
                    </div>
                  )}
                </>
              );
            })}
            {action.id.includes("id2") && !isSubfloq && (
              <div className="m-5">
                <ResponseStructureViewer
                  action={action}
                  responses={subfloqOutput}
                  setResponses={setSubfloqOutput}
                  setChanged={() => {}}
                  userDefined={true}
                />
              </div>
            )}
          </div>
        )}
      </Drawer>
    </ConfigProvider>
  );
};

export default SubfloqConfigModal;

import moment from "moment";
import {
  CELL_STATUS_TYPE,
  DISPLAY_DATE_TIME_FORMAT,
  FOLDERS_APPLICABLE_FOR_PROMPTS,
  LOADER_TYPES,
  firebaseAccessToken,
  firebaseRefreshToken,
} from "./constants.tsx";
import {
  Action,
  ActionCategory,
  ActionCategoryGroups,
  ActionResponses,
  ApiPrice,
  CategorizedAction,
  ResponseStructure,
  Workflow,
} from "./interfaces.ts";
import { jwtDecode } from "jwt-decode";
import { getFileFromBucketURLV2 } from "./urls.ts";

export const validateEmail = (str: string): boolean => {
  const arr: string[] = str.split("@");
  return arr.length === 2;
};

const defaultLoaderMsg = (payload: string) => `Loading ${payload}...`;

const generatingLoaderMsg = (payload: string) => `Generating ${payload}...`;

const fetchingLoaderMsg = (payload: string) => `Fetching ${payload}...`;

const pastingLoaderMsg = (payload: string) => `Saving ${payload}...`;

export const getLoaderMsg = (payload: string, type: LOADER_TYPES) => {
  switch (type) {
    case LOADER_TYPES.generating:
      return generatingLoaderMsg(payload);
    case LOADER_TYPES.fetching:
      return fetchingLoaderMsg(payload);
    case LOADER_TYPES.loading:
      return defaultLoaderMsg(payload);
    case LOADER_TYPES.pasting:
      return pastingLoaderMsg(payload);
    default:
      return payload;
  }
};

export const updateAccessToken = (newAccessToken: string, oldAccessToken: string) => {
  try {
    if (newAccessToken === oldAccessToken) return;
    document.cookie = `${firebaseAccessToken}=${newAccessToken};max-age=31536000;`;
  } catch (err) {
    console.log("Error in updating access token --> ", err);
    throw err;
  }
};

export const updateRefreshToken = (newRefreshToken: string, oldRefreshToken: string) => {
  try {
    if (newRefreshToken === oldRefreshToken) return;
    document.cookie = `${firebaseRefreshToken}=${newRefreshToken};max-age=31536000;`;
  } catch (err) {
    console.log("Error in updating refresh token --> ", err);
    throw err;
  }
};

/**
 * @description Returns the auth tokens as stored in browser cookies
 * @returns [accessToken, refreshToken]
 */

export const getBrowserTokens = () => {
  const cookies = document.cookie.split(";");
  const tokens: { [key: string]: string } = {};
  cookies.forEach((cookie) => {
    const [key, value] = cookie.split("=");
    tokens[key.trim()] = value;
  });
  return [tokens[firebaseAccessToken], tokens[firebaseRefreshToken]];
};

export const handleUpdateTokens = (data: any, accessToken: string, refreshToken: string) => {
  try {
    const newAccessToken = data.accessToken;
    const newRefreshToken = data.refreshToken;
    newAccessToken && updateAccessToken(newAccessToken, accessToken);
    newRefreshToken && updateRefreshToken(newRefreshToken, refreshToken);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getIndex = (idx: number): string => {
  try {
    if (idx < 10) return `0${idx}`;
    return `${idx}`;
  } catch (err) {
    alert(err);
    return "";
  }
};

export const getDataIdsNotCompleted = (data: any[]): string[] => {
  try {
    const res: string[] = data
      .filter((row) => {
        delete row.createdAt;
        return (
          Object.values(row).some(
            (rowData: any) =>
              rowData &&
              rowData.status &&
              (rowData.status === CELL_STATUS_TYPE.PAYLOAD_FILLED ||
                rowData.status === CELL_STATUS_TYPE.CHECKING_NEXT_SOURCE)
          ) || row.run === "loading"
        );
      })
      .map((row) => row.key);
    return res;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getDataIdsComleted = (data: any[]): string[] => {
  try {
    const res: string[] = data
      .filter((row) => {
        delete row.createdAt;
        return Object.values(row).every((rowData: any) => {
          typeof rowData === "object" ? rowData.status === CELL_STATUS_TYPE.COMPLETED : true;
        });
      })
      .map((row) => row.key);
    return res;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getDataIds = (data: any[]): string[] => {
  try {
    const res: string[] = data.map((row) => row.key);
    return res;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const getCurrIdxForDataEntry = (data: any[], dataId: string) => {
  try {
    const idx = data.findIndex((row) => row.key == dataId);
    return idx;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getUpdatedRows = async (
  currTableRows: any[],
  workflowData: any,
  workflowId: string,
  userActions: ActionResponses[]
) => {
  try {
    const rows: any[] = [];
    await Promise.all(
      Object.entries(workflowData).map(async ([dataId, item]: [string, any]) => {
        const row: any = {};
        item.id = null;
        const status: string[] = [];
        const updateIdx = getCurrIdxForDataEntry(currTableRows, dataId);
        await Promise.all(
          Object.entries(item).map(async ([actionId, data]: [string, any]) => {
            if (actionId === "id") return;
            const action = userActions.find((action) => action.actionId === actionId);
            if (action && (action.type === "input" || action.type === "add_section") && !action.id?.includes("id2")) {
              const actionResponseMap: Record<string, string> = {};
              action.response.forEach((res: ResponseStructure) => {
                actionResponseMap[res.responseId] = res?.name;
              });

              data.response.map((res: any) => {
                row[res.responseId] = res.value;
              });
              if (data?.createdAt) {
                row["createdAtt"] = moment.utc(data?.createdAt).local().format(DISPLAY_DATE_TIME_FORMAT);
              }
              row.responseMap = Object.fromEntries(
                Object.entries(actionResponseMap).map(([key, value]) => [value, key])
              );
            } else if (["createdAt"].includes(actionId)) {
              row["createdAtt"] = moment.utc(data).local().format(DISPLAY_DATE_TIME_FORMAT);
            } else if (["sectionInfo"].includes(actionId)) return;
            else {
              status.push(data.status);
              let temp: any = {};
              let respArray = [];
              if (typeof data.response === "string" && isApplicableURL(data.response))
                respArray = await fetchDocContent(data.response);
              else respArray = data.response || [];
              respArray.map((r: any) => {
                temp[r.responseId] = {
                  ...(action?.response.find((res) => res.responseId === r.responseId) || {}),
                  value: r.value,
                };
              });
              if (data.stepDownResponse) temp.stepDownResponse = data.stepDownResponse;

              if (data.error) temp.error = data.error;
              temp = {
                ...temp,
                status: data.status,
                workflowDataActionPartId: data.id,
                extra: {
                  reviewNeeded: action?.reviewNeeded || false,
                  reviewed: data.reviewed || false,
                  index: updateIdx,
                  workflowId: workflowId,
                  actionId,
                  dataId,
                  reviewAllowed: action?.reviewAllowed || false,
                },
              };
              row[actionId] = temp;
            }
          })
        );
        if (status.includes(CELL_STATUS_TYPE.READY_TO_BE_REVIEWED)) row.run = "review";
        else if (
          // status.includes(CELL_STATUS_TYPE.PENDING) ||
          status.includes(CELL_STATUS_TYPE.MISSING) ||
          status.includes(CELL_STATUS_TYPE.CHECKING_NEXT_SOURCE) ||
          status.includes(CELL_STATUS_TYPE.PAYLOAD_FILLED)
        )
          row.run = "loading";
        else row.run = "retry";

        row.key = dataId;
        rows.push(row);
      })
    );
    return rows;
  } catch (err) {
    console.error(err);
  }
};

export const sameTimeStamp = (str1: string, str2: string): boolean => {
  try {
    return moment(str1).isAfter(str2) || moment(str1).isSame(str2);
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const isApplicableURL = (url: string) => {
  return FOLDERS_APPLICABLE_FOR_PROMPTS.some((folder) => url.includes(folder));
};

export const fetchDocContent = async (url: any) => {
  try {
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
    };
    const response = await fetch(getFileFromBucketURLV2 + "?url=" + url, options);
    if (!response.ok) throw new Error("Failed to fetch file");
    const data = await response.json();
    return data.content;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const ancestors = (graph: dagre.graphlib.Graph, node: any): any[] => {
  const up = graph.predecessors(node);
  if (!up) return [];
  return up.concat(
    up.reduce((sum, u): any => {
      return sum.concat(ancestors(graph, u) as any);
    }, [])
  );
};

export const getStartAndEndDate = (monthString: string) => {
  // Split the string to get year and month
  const [year, month] = monthString.split("-").map(Number);

  // Create a Date object for the first day of the month
  const startDate = new Date(Date.UTC(year, month - 1, 1));

  // Create a Date object for the last day of the month
  const endDate = new Date(Date.UTC(year, month, 0));

  // Format the dates
  const formatDate = (date: Date) => {
    const isoString = date.toISOString();
    return isoString.replace("T", " ").replace("Z", "+00");
  };

  return {
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
  };
};

export const getWorkflokDropdownListFromWorkflows = (workflows: Workflow[]) => {
  const list = workflows?.map((Workflow) => {
    return {
      value: Workflow.id,
      label: Workflow.publishedWorkflowConfig.name,
    };
  });

  return list;
};

export const getActionDropdownListFromApiPricing = (apiPricing: ApiPrice[]) => {
  const list = apiPricing.map((pricing) => {
    return {
      value: `${pricing?.id}`,
      label: `${pricing?.apiName}`,
    };
  });

  return list;
};

export const getEmailFromToken = () => {
  const [accessToken] = getBrowserTokens();
  const decoded: any = jwtDecode(accessToken);

  return decoded?.email || "";
};

export const getEmailFromAccessToken = (accessToken: string) => {
  const decoded: any = jwtDecode(accessToken);
  return decoded?.email || "";
};

export const getCategoryGroups = (categories: ActionCategory[]) => {
  // Build groups by parent name.
  const groups: Record<string, { ids: string[]; logo: string; order: number }> = {
    All: {
      ids: [],
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/home.svg",
      order: 0,
    },
  };

  categories.forEach((category) => {
    if (!groups[category.parent_name]) {
      groups[category.parent_name] = {
        ids: [category.id],
        logo: category.parent_logo,
        order: category.order,
      };
    } else {
      groups[category.parent_name].ids.push(category.id);
    }
  });

  // Convert groups object to an array and sort based on the "order" key.
  const sortedGroupsArray = Object.entries(groups)
    .sort(([, groupA], [, groupB]) => groupA.order - groupB.order)
    .map(([name, group]) => ({ name, ...group }));

  const res = sortedGroupsArray.reduce((acc: any, group) => {
    acc[group.name] = group;
    return acc;
  }, {});

  return res as ActionCategoryGroups;
};

export const categorizeActions = (actions: Action[], categories: ActionCategory[]) => {
  const categorizedActions = categories?.map((category) => {
    const actionsInCategory = actions.filter((action) => category.actions.includes(action.id));

    return {
      ...category,
      actions: actionsInCategory,
    };
  });
  return categorizedActions as CategorizedAction[];
};

export const filterByCategories = (categorizeActions: CategorizedAction[], categoryIds: string[]) => {
  return categorizeActions.filter((category) => categoryIds.includes(category.id));
};

export const actionsSearchFilter = (
  categorizedActions: CategorizedAction[],
  searchString: string
): CategorizedAction[] => {
  if (!searchString.trim()) return categorizedActions;

  const normalizedSearch = searchString.toLowerCase().trim();

  return categorizedActions
    .map((category) => {
      // Filter actions within each category
      const matchedActions = category.actions.filter((action) => {
        // Check action response structure names
        const responseNameMatches = action.responseStructure?.some((response: ResponseStructure) =>
          response.name.toLowerCase().includes(normalizedSearch)
        );

        return responseNameMatches;
      });

      // Check if category name or description matches
      const categoryMatches =
        category.name.toLowerCase().includes(normalizedSearch) ||
        category.description.toLowerCase().includes(normalizedSearch);

      // If category matches, return all actions. If not, return only matched actions
      return {
        ...category,
        actions: categoryMatches ? category.actions : matchedActions,
      };
    })
    .filter(
      (category) =>
        // Keep categories that either match themselves or have matching actions
        category.name.toLowerCase().includes(normalizedSearch) ||
        category.description.toLowerCase().includes(normalizedSearch) ||
        category.actions.length > 0
    );
};

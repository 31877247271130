import { Response, ResponseConfiguration } from "@/utils/interfaces";
import { Table } from "antd";
import { useEffect, useRef, useState } from "react";

type Props = {
  responseConfig: ResponseConfiguration[];
  responses: Response[];
};

const InputViewer = ({ responseConfig, responses }: Props) => {
  const [columns, setColumns] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const tableRef = useRef<any>(null);

  useEffect(() => {
    const config = responseConfig || [];
    const cols: any[] = config.map((response: any) => ({
      title: response.name,
      dataIndex: response.responseId,
      key: response.responseId,
      ellipsis: true,
      width: "15rem",
      render: (text: string) => {
        if (!text) {
          return {
            props: {
              className: "!p-0",
            },
            children: <div className="p-5 w-full">{text}</div>,
          };
        } else {
          return {
            props: {
              className: "!p-0",
            },
            children: (
              <div className="overflow-hidden p-4 whitespace-nowrap text-ellipsis w-full">
                {typeof text === "object" ? JSON.stringify(text) : text}
              </div>
            ),
          };
        }
      },
    }));
    cols.unshift({
      title: "Index",
      dataIndex: "idx",
      key: "idx",
      width: "5rem",
    });
    setColumns(cols);

    const res = responses || [];
    const rows = res.map((row: any, idx: number) => ({
      key: row.dataId,
      idx: idx + 1,
      ...row.response.reduce(
        (acc: any, curr: any) => ({
          ...acc,
          [curr.responseId]: curr.value,
        }),
        {}
      ),
    }));
    setData(rows);

    setTimeout(() => {
      // Remove previous style element if it exists
      const existingStyle = document.head.querySelector("style[data-input-viewer-style]");
      if (existingStyle) {
        existingStyle.remove();
      }

      if (!tableRef.current) return;
      const height = tableRef.current.offsetHeight;

      const heightAdjustment = `#id1InputViewer {
      .ant-table-content {
       height: ${height}px
      }
    }`;
      const style = document.createElement("style");
      style.setAttribute("data-input-viewer-style", "");
      style.innerHTML = heightAdjustment;
      document.head.appendChild(style);
    }, 500);
  }, [responseConfig, responses]);

  return (
    <Table
      bordered
      columns={columns}
      dataSource={data}
      pagination={false}
      id="id1InputViewer"
      className="overflow-y-auto"
      ref={tableRef}
    />
  );
};

export default InputViewer;

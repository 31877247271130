import { ENGAGEBAY_SYSTEM_FIELDS, setStateType } from "../../../utils/constants";
import { useCallback, useEffect, useRef, useState } from "react";
import { useWorkflow } from "../../../contexts/WorkflowContext";
import TiptapField from "../Tiptap/TiptapField";
import { Action, PayloadConfiguration } from "../../../utils/interfaces";
import { Alert, Button, ConfigProvider, Divider, Drawer, Input, InputRef, Select, Skeleton, Space, Switch } from "antd";
import { FaPlus, FaRegEdit } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { MdChevronLeft, MdChevronRight, MdDeleteOutline } from "react-icons/md";
import RunCondition from "./ModalComponents/RunCondition";
import { useConnections } from "@/contexts/ConnectionContext";
import { getCampaigns } from "@/utils/apis";
import { useRunOnFloq } from "@/contexts/RunOnFloqContext";

type Props = {
  action: Action;
  modal: boolean;
  setModal: setStateType<boolean>;
  payloadConfiguration: PayloadConfiguration[];
  variables: any[];
};

const EngagebayModal = ({ action, modal, setModal, payloadConfiguration, variables }: Props) => {
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [name, setName] = useState("");
  const inputRef = useRef<InputRef>(null);
  const [campaigns, setCampaigns] = useState<{
    loading: boolean;
    campaigns: any[];
  }>({
    loading: false,
    campaigns: [],
  });
  const [updateField, setUpdateField] = useState(0);
  const [payloads, setPayloads] = useState<PayloadConfiguration[]>(payloadConfiguration);
  const [runCondition, setRunCondition] = useState(action.runCondition);
  const { updatePayloadConfig, saveWorkflowActions, updateActionName, setPublishWarning } = useWorkflow();
  const { getConnectionFromType } = useConnections();
  const [isConnectionExists, setIsConnectionExists] = useState(true);
  const [changeName, setChangeName] = useState(false);
  const { collapseConfig, setCollapseConfig } = useRunOnFloq();
  const [drawerWidth, setDrawerWidth] = useState(collapseConfig ? "3rem" : "30%");
  const { id } = useParams();

  useEffect(() => {
    setWidthPercent();
    window.addEventListener("resize", setWidthPercent);
    return () => window.removeEventListener("resize", setWidthPercent);
  }, [collapseConfig]);

  const setWidthPercent = useCallback(() => {
    try {
      if (collapseConfig) setDrawerWidth("3rem");
      // Adjust width for different screen sizes
      else if (window.matchMedia("(min-width: 1024px) and (min-height: 1366px)").matches)
        // iPad Pro and similar devices
        setDrawerWidth("50%");
      else if (window.matchMedia("(min-width: 1024px)").matches) setDrawerWidth("30%");
      else if (window.matchMedia("(min-width: 640px)").matches) setDrawerWidth("50%");
      else setDrawerWidth("100%");
    } catch (err) {
      console.error("Error calculating width percent based on media size");
    }
  }, [collapseConfig]);

  useEffect(() => {
    setCampaigns({ loading: true, campaigns: [] });
    getCampaigns("engagebay_properties").then((campaigns) => {
      setCampaigns({ loading: false, campaigns });
    });
  }, []);

  useEffect(() => {
    setPayloads(payloadConfiguration);
    setUpdateField((prev) => prev + 1);
  }, [payloadConfiguration]);

  useEffect(() => {
    if (payloads !== payloadConfiguration || runCondition !== action.runCondition) setChanged(true);
    else setChanged(false);
  }, [payloads, runCondition]);

  useEffect(() => {
    if (!action?.connectionId) return;
    getConnectionFromType(action?.connectionId).then((connection: any) => {
      setIsConnectionExists(connection?.userConnection ? true : false);
    });
  }, [action?.connectionId]);

  const saveOutputStructure = async () => {
    setLoading(true);
    const updated = updatePayloadConfig(payloads, action.id, false, action.continueOnFail, runCondition);
    setPayloads(updated);
    await saveWorkflowActions(id || "");
    setChanged(false);
    setLoading(false);
    setPublishWarning(true);
  };

  const fieldSwitchCase = (field: any) => {
    switch (field.type) {
      case "jsonArray":
        return (
          <div className="w-full flex flex-col gap-4">
            {payloads
              .find((input) => input.payloadStructureId === field.payloadStructureId)
              ?.inputString?.map((_: any, idx: number) => {
                return (
                  <div className="flex gap-2 justify-between">
                    <div className="w-full" key={idx}>
                      <Skeleton
                        loading={campaigns.loading}
                        paragraph={false}
                        active={campaigns.loading}
                        style={{ marginBottom: "0.25rem" }}
                      >
                        <Select
                          showSearch
                          style={{ width: "100%", marginBottom: "0.25rem" }}
                          value={
                            payloads.find((input) => input.payloadStructureId === field.payloadStructureId)
                              ?.inputString[idx]?.label || ""
                          }
                          onChange={(value, options: any) => {
                            const temp = [...payloads];
                            const index = payloads.findIndex(
                              (input) => input.payloadStructureId === field.payloadStructureId
                            );
                            temp[index].inputString[idx].name = value;
                            temp[index].inputString[idx].label = options.label;
                            temp[index].inputString[idx].type = options.type;
                            temp[index].inputString[idx].fieldType = options.fieldType;
                            temp[index].inputString[idx].isSearchable = options.isSearchable;

                            setCampaigns({
                              loading: false,
                              campaigns: campaigns.campaigns,
                            });
                            setPayloads(temp);
                          }}
                          dropdownRender={(menu) => (
                            <>
                              {menu}
                              <Divider style={{ margin: "8px 0" }} />
                              <Space style={{ padding: "0 8px 4px" }}>
                                <Input
                                  placeholder="Please enter field name"
                                  ref={inputRef}
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                  onKeyDown={(e) => e.stopPropagation()}
                                  style={{ borderRadius: "4px" }}
                                />
                                <Button
                                  type="link"
                                  icon={<FaPlus />}
                                  onClick={() => {
                                    const newField = {
                                      field_label: `${name}`,
                                      field_name: `${name}`.replace(/ /g, "_"),
                                      field_type: "TEXT",
                                      type: "CUSTOM",
                                      is_searchable: false,
                                    };
                                    setCampaigns({
                                      loading: false,
                                      campaigns: [...campaigns.campaigns, newField],
                                    });
                                    setName("");
                                    setTimeout(() => {
                                      inputRef.current?.focus();
                                    }, 0);
                                  }}
                                >
                                  Add New Field
                                </Button>
                              </Space>
                            </>
                          )}
                          options={campaigns.campaigns.concat(ENGAGEBAY_SYSTEM_FIELDS).map((field: any) => ({
                            label: field?.field_label,
                            value: field?.field_name,
                            fieldType: field?.field_type ? field.field_type : "TEXT",
                            type: field?.type ? field.type : "CUSTOM",
                            isSearchable: field?.is_searchable ? field.is_searchable : false,
                          }))}
                        />
                      </Skeleton>
                      <TiptapField
                        content={
                          payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString[
                            idx
                          ]?.tiptapJson || ""
                        }
                        setContent={(content: any, text: string) => {
                          const temp = [...payloads];
                          const index = payloads.findIndex(
                            (input) => input.payloadStructureId === field.payloadStructureId
                          );
                          temp[index].inputString[idx].value = text;
                          temp[index].inputString[idx].tiptapJson = content;
                          setPayloads(temp);
                        }}
                        refresh={updateField}
                        placeholder="Enter the value"
                        variables={variables}
                      />
                    </div>
                    <button
                      className="hover:bg-primary/10 h-fit w-fit p-2 rounded-full text-primary"
                      onClick={() => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        temp[index].inputString.splice(idx, 1);
                        setUpdateField((prev) => prev + 1);
                        setPayloads(temp);
                      }}
                    >
                      <MdDeleteOutline size={25} />
                    </button>
                  </div>
                );
              })}
            <button
              className="text-primary flex gap-2 items-center"
              onClick={() => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                temp[index].inputString.push({
                  name: "",
                  value: "",
                });
                setPayloads(temp);
              }}
            >
              <FaPlus />
              Add a new Variable Name and Variable Value pair
            </button>
          </div>
        );
      default:
        return (
          <textarea
            className="w-full h-32 rounded-lg"
            placeholder="Enter the value"
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""}
            onChange={(e) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: e.target.value,
                  type: field.type,
                });
              } else {
                temp[index].inputString = e.target.value;
              }
              setPayloads(temp);
            }}
          />
        );
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={modal}
        mask={false}
        onClose={() => setModal(false)}
        width={drawerWidth}
        styles={{
          content: {
            transition: "width 0.3s",
          },
          body: {
            padding: collapseConfig ? "0" : "24px",
          },
        }}
        classNames={{ header: !collapseConfig ? "border-b !border-gray-300" : "!border-none" }}
        className="ml-auto !font-favorit !p-0 border-l border-gray-500"
        getContainer={false}
        footer={
          !collapseConfig && (
            <div className="w-full flex flex-col gap-4 bg-white">
              {!isConnectionExists && (
                <Alert
                  message={
                    <>
                      Please add the required connection to proceed.{" "}
                      <Link to="/connections" target="_blank" rel="noopener noreferrer">
                        <i>Click here</i>
                      </Link>
                    </>
                  }
                  type="warning"
                  showIcon
                />
              )}
              <button
                className={` text-white w-full font-semibold rounded-lg p-2 mt-auto ${
                  loading || !changed || !isConnectionExists
                    ? "cursor-not-allowed bg-gray-500"
                    : "bg-primary cursor-pointer"
                }`}
                onClick={saveOutputStructure}
                disabled={loading || !changed || !isConnectionExists}
              >
                {loading ? "Saving..." : !changed ? "Saved" : "Save"}
              </button>
            </div>
          )
        }
        title={
          !collapseConfig && (
            <div className="flex items-center gap-2">
              <button className="p-0 rounded-sm" onClick={() => setCollapseConfig(true)}>
                <MdChevronRight size={25} />
              </button>
              <img src={action.logo} alt={action.name} className="w-6 h-6" />
              <span
                contentEditable={changeName}
                suppressContentEditableWarning
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.target.blur();
                  }
                }}
                onBlur={async (e) => {
                  if (!e.target.textContent) return;
                  setLoading(true);
                  updateActionName(action.id, e.target.textContent || action.name);
                  setChangeName(false);
                  await saveWorkflowActions(id || "");
                  setChanged(false);
                  setLoading(false);
                  setPublishWarning(true);
                }}
              >
                {action.name}
              </span>
              <button
                onClick={() => {
                  setChangeName(!changeName);
                  const p = document.getElementById("actionName");
                  if (!p) return;
                  setTimeout(function () {
                    const range = document.createRange();
                    const selection = window.getSelection();
                    range.selectNodeContents(p);
                    range.collapse(false); // <-- Set the cursor at the end of the selection
                    selection?.removeAllRanges();
                    selection?.addRange(range);
                    p.focus();
                  }, 0);
                }}
                className={`p-2 rounded-md transition-opacity duration-600 ease-in-out hover:bg-gray-200 ${
                  changeName ? "opacity-50" : "opacity-100"
                }`}
              >
                <FaRegEdit />
              </button>
            </div>
          )
        }
      >
        {collapseConfig ? (
          <button
            className="w-full h-full flex flex-col items-center justify-center p-3 gap-3"
            onClick={() => setCollapseConfig(false)}
          >
            <span className="p-0 border border-gray-300 rounded-sm">
              <MdChevronLeft size={25} />
            </span>
            <p className="[writing-mode:sideways-lr] text-center">CONFIG</p>
          </button>
        ) : (
          <div className="bg-white gap-10 flex flex-col">
            {action.payloadStructure.map((field, idx) => {
              return (
                <div key={idx} className="flex flex-col w-full gap-4 p-4 border border-gray-300 rounded-lg">
                  <div>
                    <p className="tracking-primary font-semibold">
                      {field.name.toUpperCase()}
                      {!field.required && (
                        <span className="text-sm font-normal tracking-normal text-gray-400"> (Optional)</span>
                      )}
                    </p>
                    <p className="text-sm font-light">{field.description}</p>
                  </div>
                  {field.needVars ? (
                    <TiptapField
                      content={
                        payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.tiptapJson ||
                        ""
                      }
                      setContent={(content: any, text: string) => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        if (index === -1) {
                          temp.push({
                            payloadStructureId: field.payloadStructureId,
                            tiptapJson: content,
                            inputString: text,
                            type: field.type,
                          });
                        } else {
                          temp[index].tiptapJson = content;
                          temp[index].inputString = text;
                        }
                        setPayloads(temp);
                      }}
                      refresh={updateField}
                      placeholder={field.name}
                      variables={variables}
                    />
                  ) : (
                    fieldSwitchCase(field)
                  )}
                </div>
              );
            })}
            <div>
              Continue workflow even if this fails:{" "}
              <Switch
                defaultChecked={action.continueOnFail}
                onChange={(value) => {
                  setChanged(true);
                  action.continueOnFail = value;
                }}
              />
            </div>
            <RunCondition
              variables={variables}
              responses={action.responseStructure}
              conditionsArr={
                runCondition || {
                  conditions: [],
                  otherwise: [],
                }
              }
              setConditionsArr={setRunCondition}
            />
          </div>
        )}
      </Drawer>
    </ConfigProvider>
  );
};

export default EngagebayModal;

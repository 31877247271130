import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { deleteListData, fetchListConnections } from "../apis";

import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { renderToString } from "react-dom/server";
import { FiDownload, FiSave, FiUpload, FiWifi } from "react-icons/fi";
import { IoClose } from "react-icons/io5";

import {
  exportListData,
  fetchPaginatedListData,
  fetchWatcherData,
  updateWatcherName,
  updateSignalStaticData,
  getSignals,
} from "../apis";
import { ResponseStructure } from "../models";
import ListConnections from "./ListConnections";
import { LOADER_TYPES } from "@/utils/constants";
import Loader from "@/Components/Loader";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import { SIGNAL_ID_TO_URL } from "@/utils/urls";
import DatabaseIcon from "@Assets/SVGs/DatabaseIcon.svg";
import { Breadcrumb, Divider, message, Pagination } from "antd";
import { CollapsibleIcon } from "@/Components/NavbarNew/CollapsibleIcon";
import PushFloq from "../Modals/PushFloq";
import EditableName from "../Components/EditableName";
import RadioIconGray from "@Assets/SVGs/RadioIconGray.svg";
import DeleteIconRed from "@Assets/SVGs/DeleteIconRed.svg";
import ListIcon from "@Assets/SVGs/ListIcon.svg";
import { CustomTooltip } from "../Components/CustomTooltip";
import SearchInput from "../Components/SearchInput";
import Analytics from "../Components/Analytics";
import ColumnSelector from "../Components/CrmComponents/ColumnSelectPopup";
import { BiRefresh } from "react-icons/bi";
import CopyHeadersButtons from "../Components/CopyHeadersButtons";
import Connection from "../Modals/Connection";
import { getUserWorkflows } from "@/utils/apis";

const ListPage = () => {
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [signal, setSignal] = useState<any>({});
  const [availableSignals, setAvailableSignals] = useState<any[]>([]);
  const [watcher, setWatcher] = useState<any>({});
  const [listData, setListData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>(listData);
  const [connections, setConnections] = useState<any[]>([]);
  const [exporting, setExporting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [downloadLink, setDownloadLink] = useState<string>("");
  const [connectionsPage, setConnectionsPage] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 20, total: 0 });
  const [pushToFloqModal, setPushToFloqModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  // const [refreshing, setRefreshing] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  // const [totalLeadsPushed, setTotalLeadsPushed] = useState(0);

  const [payloads, setPayloads] = useState<any>({});

  /* Sync Modal states */
  const [removeMapping, setRemoveMapping] = useState(false);
  const [workflows, setWorkflows] = useState<any[]>([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState<any>(undefined);

  const getDefaultSelectedValues = (signalId: string) => {
    switch (signalId) {
      case "HUBSPOT_CONTACT_ADDED":
        return ["createdate", "firstname", "lastname", "email", "lastmodifieddate"];
      case "SALESFORCE_PULL_DATA":
        return ["Id"];
      case "PIPEDRIVE_IMPORT_DATA":
        return ["id", "add_time", "update_time"];
      default:
        return [];
    }
  };
  const { id } = useParams();
  const location = useLocation();

  // Save column state to localStorage on column resize
  const onColumnResized = (params: any) => {
    const columnState = params.api.getColumnState();
    localStorage.setItem("savedColumnState", JSON.stringify(columnState));
  };

  // Restore column state from localStorage when the grid is ready
  const onGridReady = (params: any) => {
    const savedColumnState = localStorage.getItem("savedColumnState");
    if (savedColumnState) {
      const parsedState = JSON.parse(savedColumnState);
      params.api.applyColumnState({ state: parsedState });
    }
  };

  useEffect(() => {
    if (!id) return;

    const getConnectionsAndWorkflows = async () => {
      setLoading(true);
      try {
        const [conns, flows] = await Promise.all([
          fetchListConnections(id).catch((error) => {
            message.error("Failed to fetch connections: " + error.message);
            throw error;
          }),
          getUserWorkflows(true).catch((error) => {
            message.error("Failed to fetch workflows: " + error.message);
            throw error;
          }),
        ]);

        const filteredConnections = conns ? conns.filter((connection: any) => connection.status !== "archived") : [];
        setConnections(filteredConnections);
        // setTotalLeadsPushed(conns.reduce((total: number, item: any) => total + item.leads_pushed, 0));
        const firebase = flows.workflows;
        const supabase = flows.workflowsV2;
        const allWorkflows: any[] = (firebase?.workflows || [])
          .concat(supabase?.workflows || [])
          .map((workflow: any) => {
            if ((firebase?.workflows || []).find((w: any) => w.id === workflow.id)) workflow.supabase = false;
            else workflow.supabase = true;
            return workflow;
          });
        allWorkflows.sort((a: any, b: any) => {
          const aDate = moment.utc(a.createdAt);
          const bDate = moment.utc(b.createdAt);

          if (aDate.isAfter(bDate)) return -1;
          if (aDate.isBefore(bDate)) return 1;
          return 0;
        });
        setWorkflows(allWorkflows);
      } catch (error) {
        console.error("Failed: getConnectionsAndWorkflows", error);
        message.error("Something went wrong while fetching Connections and Workflows");
      } finally {
        setLoading(false);
      }
    };
    getConnectionsAndWorkflows();
  }, [id]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const action = searchParams.get("connections");
    setConnectionsPage(action === "true");
  }, [location.search]);

  useEffect(() => {
    const url = connectionsPage ? `${window.location.pathname}?connections=true` : window.location.pathname;
    window.history.replaceState({}, "", url);
  }, [connectionsPage]);

  // useEffect(() => {
  //   if (!id) return;
  //   fetchListConnections(id)
  //     .then((data) => {
  //       setConnections(data);
  //       // setTotalLeadsPushed(data.reduce((total: number, item: any) => total + item.leads_pushed, 0));
  //     })
  //     .catch((err) => message.error(err));
  // }, [id]);

  /**
   * List data may contain values with null UID, remove them.
   * Also process the data, so that if there is any null value, it should be replaced with empty string.
   */
  const filterListData = (data: any[]) => {
    let filteredData = data;
    if (data.some((row) => "uid" in row)) {
      filteredData = filteredData.filter((row) => row.uid);
    }

    return filteredData.map((row) => {
      return Object.fromEntries(
        Object.entries(row).map(([key, value]) => {
          if (typeof value === "object" && value !== null) {
            // Recursively handle nested objects
            value = Object.fromEntries(
              Object.entries(value).map(([k, v]) => {
                return [k, v === null ? "" : v];
              })
            );
          } else {
            // Replace null with empty string for non-object values
            value = value === null ? "" : value;
          }
          return [key, value];
        })
      );
    });
  };

  useEffect(() => {
    if (!id) return;
    setTableLoading(true);
    fetchPaginatedListData(id, pagination.current, pagination.pageSize)
      .then((data) => {
        setListData(filterListData(data.listData));
        setPagination((prev) => ({ ...prev, total: data.count }));
      })
      .catch((err) => message.error(err))
      .finally(() => setTableLoading(false));
  }, [pagination.current, pagination.pageSize, id]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        // Fetch all available signals from firebase
        const signalsData = await getSignals();
        setAvailableSignals(signalsData.availableWatchers);
        if (id) {
          const watcherData = await fetchWatcherData(id);
          setWatcher(watcherData.watcher);
          setSignal(watcherData.signal);
          setPayloads(watcherData.watcher.payload);
        } else message.error("No ID provided. Unable to fetch watcher data.");
      } catch (err) {
        message.error(err instanceof Error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (!searchTerm) {
      setFilteredData(listData);
      return;
    }
    const lowerSearchTerm = searchTerm.toLowerCase();
    const newFilteredData = listData.filter((row) =>
      Object.values(row).some((value) => value?.toString().toLowerCase().includes(lowerSearchTerm))
    );

    setFilteredData(newFilteredData);
  }, [searchTerm, listData]);

  useEffect(() => {
    if (loading) {
      return;
    }
    // Check URL parameters for triggerExport
    const searchParams = new URLSearchParams(location.search);
    const shouldTriggerExport = searchParams.get("triggerExport");

    if (shouldTriggerExport === "true") {
      handleExport();
      // Remove the parameter from URL without page reload
      searchParams.delete("triggerExport");
      window.history.replaceState({}, "", `${window.location.pathname}?${searchParams.toString()}`);
    }
  }, [loading]);

  const matchedSignal = useMemo(() => {
    return availableSignals.find((signal) => watcher && signal.id === watcher.signal_id);
  }, [availableSignals, watcher]);

  const changeWatcherNameHandler = async (newName: string): Promise<void> => {
    const previousName = watcher.name;
    setWatcher((prevWatcher: any) => ({
      ...prevWatcher,
      name: newName,
    }));

    try {
      const responseData = await updateWatcherName(watcher.id, newName);
      message.success(responseData.message || "Watcher name updated successfully.");
    } catch (error) {
      console.error("Error updating watcher name:", error);

      // Revert to the previous name if an error occurs
      setWatcher((prevWatcher: any) => ({
        ...prevWatcher,
        name: previousName,
      }));

      message.error(
        error instanceof Error ? error.message : "An unexpected error occurred while updating the watcher name."
      );
    }
  };

  // const handleRefreshData = async () => {
  //   setRefreshing(true);
  //   const newId = await updateSignalStaticData(
  //     watcher.id,
  //     SIGNAL_ID_TO_URL[watcher.signal_id as keyof typeof SIGNAL_ID_TO_URL],
  //     watcher.payload
  //   );
  //   if (!newId) {
  //     message.error("Failed to refresh the data, reloading...");
  //   }
  //   window.location.reload();
  //   setRefreshing(false);
  // };
  const refreshCRMlist = async () => {
    setTableLoading(true);
    const newId = await updateSignalStaticData(
      watcher.id,
      SIGNAL_ID_TO_URL[watcher.signal_id as keyof typeof SIGNAL_ID_TO_URL],
      payloads
    );
    if (!newId) {
      message.error("Failed to refresh the data, reloading...");
    }
    window.location.reload();
    setTableLoading(false);
  };

  const getConnectionLayout = (watcher: any, signal: any) => {
    const signal_id = watcher.signal_id;
    switch (signal_id) {
      case "HUBSPOT_CONTACT_ADDED":
      case "SALESFORCE_PULL_DATA":
      case "PIPEDRIVE_IMPORT_DATA":
        return (
          <ListConnections
            responseStructure={
              watcher.payload["properties"].map((field: string) => {
                // Find the corresponding field in crmProperties
                const fieldMetadata = watcher.payload.crmProperties.find((prop: any) => prop.name === field);

                // Return the enriched response structure
                return {
                  name: fieldMetadata?.label || field,
                  description: fieldMetadata?.label || "", // Use the label from crmProperties if available
                  type: fieldMetadata?.type || "string", // Use the type from crmProperties if available
                  responseStructureId: field,
                };
              }) as ResponseStructure[]
            }
            watcherName={watcher?.name || ""}
          />
        );
      default:
        return (
          <ListConnections
            responseStructure={
              signal.outputStructureType === "dataDefined"
                ? listData.length > 0
                  ? Object.keys(listData[0])
                      .map((field: string) => {
                        const skip = ["_id", "created_at", "list_id", "type"];
                        if (skip.includes(field)) return null;
                        return {
                          name: field,
                        };
                      })
                      .filter((field) => field)
                  : []
                : signal.responseStructure
            }
            watcherName={watcher?.name || ""}
          />
        );
    }
  };

  const { tooltipContent, formattedContent } = useMemo(() => {
    if (!watcher?.payload || typeof watcher.payload !== "object")
      return { tooltipContent: null, formattedContent: null };

    const entries = Object.entries(watcher.payload);

    return {
      tooltipContent: (
        <div className="max-h-40 text-xs font-favoritMono overflow-y-auto">
          {entries.map(([key, value], index) => (
            <div key={key} className="last:mb-0 last:pb-0">
              <div className="px-2">
                <span className="text-black font-medium tracking-wider rounded-sm text-[10px] p-1 bg-[#F2F2F2]">
                  {key}:
                </span>
                <span className="text-gray-600 ml-2">
                  {Array.isArray(value)
                    ? value.map((v, i) => (
                        <span key={i} className="block">
                          {typeof v === "object" && v !== null ? JSON.stringify(v, null, 2) : String(v)}
                        </span>
                      ))
                    : typeof value === "object" && value !== null
                      ? JSON.stringify(value, null, 2)
                      : String(value)}
                </span>
              </div>
              {index < entries.length - 1 && <hr className="my-3 border-gray-300" />}
            </div>
          ))}
        </div>
      ),
      formattedContent: (
        <span className="font-favoritMono">
          {entries.map(([key, value], index) => (
            <span key={key} className="last:mr-0 mr-2">
              <span className="text-[#333333] font-medium tracking-wider text-[12px]">{key}:</span>
              <span className="text-gray-600 ml-2">
                {Array.isArray(value) ? value.join(", ") : JSON.stringify(value)}
              </span>
              {index < entries.length - 1 && ", "}
            </span>
          ))}
        </span>
      ),
    };
  }, [watcher]);

  const breadcrumbItems = useMemo(() => {
    if (!matchedSignal) return [];

    const { type, logo } = matchedSignal;

    let path = "";
    let label = "";
    let icon = "";

    switch (type) {
      case "watcher":
        path = "/intent-signals";
        label = "Intent Signals";
        icon = RadioIconGray;
        break;
      case "list":
        path = "/list-building";
        label = "List Building";
        icon = ListIcon;
        break;
      case "crmWatcher":
      case "crmList":
        path = "/crm";
        label = "CRM";
        icon = DatabaseIcon;
        break;
      default:
        path = "/";
        label = "Home";
    }

    return [
      {
        title: (
          <Link to={path} style={{ display: "flex", alignItems: "center", gap: 4 }}>
            <img src={icon} alt="logo" className="h-5 w-5" />
            {label}
          </Link>
        ),
      },
      {
        title: (
          <span className="flex">
            <img src={logo} alt="icon" className="h-6 w-6" />
            <EditableName name={watcher.name} updateName={changeWatcherNameHandler} />
          </span>
        ),
      },
    ];
  }, [matchedSignal, watcher]);

  const handleExport = async () => {
    const signalId = watcher.signal_id;

    if (!signalId || !id) return;
    setExporting(true);
    setDownloadLink("");
    try {
      const selectedRowIds = selectedRows.map((row) => row._id);
      let fileUrl: string;
      if (selectedRowIds.length === 0) fileUrl = (await exportListData(id, signalId)) as string;
      else fileUrl = (await exportListData(id, signalId, selectedRowIds)) as string;

      setDownloadLink(fileUrl);
    } catch (err: any) {
      message.error(err.message);
      console.error("Error in exporting table data --> ", err);
      setExporting(false);
    }
    // Deselect all rows after successful export
    if (grid.current) grid.current.api.deselectAll();
    setSelectedRows([]);
  };

  const handleDelete = async () => {
    if (!id) {
      message.error("Invalid operation: ListID is undefined.");
      return;
    }
    if (selectedRows.length === 0) {
      message.warning("No rows selected for deletion.");
      return;
    }
    setIsDeleting(true);
    try {
      const selectedIds = selectedRows.map((row) => row._id);
      await deleteListData(id, selectedIds);
      const updatedData = listData.filter((row) => !selectedIds.includes(row._id));
      setListData([...updatedData]);
      grid.current.api.deselectAll();
      setSelectedRows([]);
      message.success(`${selectedRows.length} rows deleted successfully`);
    } catch (err: any) {
      message.error(err.message || "Failed to delete rows");
      console.error("Error in deleting rows:", err);
    } finally {
      setIsDeleting(false);
    }
  };

  const grid = useRef<any>(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const getEmptyColDef = (watcher: any, signal_id: string) => {
    switch (signal_id) {
      case "HUBSPOT_CONTACT_ADDED":
      case "SALESFORCE_PULL_DATA":
      case "PIPEDRIVE_IMPORT_DATA":
        return watcher.payload["properties"]
          .map((field: string) => {
            const skip = ["_id", "created_at", "list_id", "type"];
            if (skip.includes(field)) return null;
            return {
              headerName: field,
              field: field,
              mainMenuItems: [],
              headerComponent: (props: any) => {
                return <div className="px-3">{props.displayName}</div>;
              },
            };
          })
          .filter(Boolean);

      default:
        return [];
    }
  };

  const colDefs =
    signal.outputStructureType === "dataDefined"
      ? listData.length > 0
        ? Object.keys(listData[0])
            .map((field: string) => {
              const skip = ["_id", "created_at", "list_id", "type"];
              if (skip.includes(field)) return null;
              return {
                headerName: listData?.[0][field]?.label || field,
                field: field,
                mainMenuItems: [],
                cellStyle: { borderRight: "none", paddingLeft: "24px" },
                headerComponent: (props: any) => {
                  return <div className="pr-3 pl-6">{props.displayName}</div>;
                },
                cellRenderer: (params: any) => {
                  if (!params.value) return null;
                  const value = params.value?.label ? params.value?.value : params.value;

                  if (typeof value === "object") {
                    return <pre className="whitespace-pre-wrap">{JSON.stringify(value)}</pre>;
                  }

                  return <p className="font-normal line-clamp-3">{String(value)}</p>;
                },
              };
            })
            .filter((field) => field)
        : getEmptyColDef(watcher, watcher.signal_id) // this will help us render the columns even if there is no data in the dataDefined signals
      : signal.responseStructure?.map((field: ResponseStructure) => ({
          headerName: field.name,
          field: field.responseStructureId,
          tooltipValueGetter: (params: any) => JSON.stringify(params.data?.[field.responseStructureId]),
          cellRenderer: (params: any) => {
            const fieldKey = field.responseStructureId; // Example: "company_object.name"
            const value = params.data?.[fieldKey];

            if (field.type === "json" || typeof value === "object")
              return <p className="font-normal">{value && JSON.stringify(value)}</p>;
            else if (field.type === "url")
              return (
                <a
                  href={value}
                  className="text-blue-500 font-favorit underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  {value}
                </a>
              );
            else if (field.type === "image") return <img src={value} alt={field.name} className="w-8 h-8" />;
            else if (field.type === "date")
              return value ? moment(value).utc().local().format("MMM DD, YYYY HH:mm:ss") : "";
            else
              return <p className="font-normal line-clamp-3 font-favorit">{value && value.toString()}</p>;
          },
          cellStyle: { borderRight: "none", paddingLeft: "24px" },
          mainMenuItems: [],
          headerComponent: (props: any) => {
            return <div className="pr-3 pl-6 font-favorit font-normal text-[#828282]">{props.displayName}</div>;
          },
        }));

  if (loading)
    return (
      <div className="w-full h-full">
        <Loader loaderType={LOADER_TYPES.fetching} payload="Watcher" />
      </div>
    );

  if (connectionsPage) return getConnectionLayout(watcher, signal);

  const getRefreshType = (watcher: any) => {
    switch (watcher.signal_id) {
      case "HUBSPOT_CONTACT_ADDED":
      case "SALESFORCE_PULL_DATA":
      case "PIPEDRIVE_IMPORT_DATA":
        return (
          <div className="flex gap-2 items-center px-2">
            <ColumnSelector
              payloads={payloads}
              setPayloads={setPayloads}
              propertiesFilter={watcher.payload.crmProperties || []}
              defaultSelectedValues={getDefaultSelectedValues(watcher.signal_id)}
            />
            {payloads.properties.length !== watcher.payload.properties.length && (
              <button className="flex gap-2 items-center" onClick={refreshCRMlist}>
                Refresh list <BiRefresh />
              </button>
            )}
          </div>
        );

      default:
        return;
    }
  };

  return (
    <div className="h-full w-full flex flex-col !cursor-default font-favorit gap-5">
      {exporting && (
        <dialog className="fixed inset-0 z-[200] bg-black bg-opacity-50 flex items-center justify-center backdrop-filter w-full h-full">
          <div className="flex flex-col min-w-[440px] min-h-[100px] bg-white px-4 py-2 rounded-md">
            <div className="flex items-center justify-between w-full mb-2 pb-2 border-b gap-2">
              <div className="flex items-center gap-2">
                <FiSave className="w-6 h-6" />
                <h3 className="text-lg font-semibold">Exporting Leads</h3>
              </div>
              <button
                type="button"
                className="p-1 rounded-full hover:bg-gray-100"
                onClick={() => {
                  setDownloadLink("");
                  setExporting(false);
                }}
              >
                <IoClose size="1.5rem" />
              </button>
            </div>
            {!downloadLink ? (
              <div className="flex flex-col items-center justify-center w-full my-2 gap-2">
                <div className="h-1.5 w-full bg-blue-100 overflow-hidden rounded-lg">
                  <div className="animate-progress w-full h-full bg-blue-500 origin-left-right"></div>
                </div>
                <span className="text-sm text-[#69717d]">Please wait while we export the the Leads</span>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center w-full my-2 gap-2">
                <span className="text-sm text-[#69717d]">Your file is ready for download</span>
                <button
                  type="button"
                  className="flex items-center justify-center px-4 py-2 font-semibold text-white bg-primary rounded-md w-full gap-2"
                  onClick={() => {
                    window.open(downloadLink, "_blank");
                    setExporting(false);
                    setDownloadLink("");
                  }}
                >
                  <FiDownload className="w-4 h-4" />
                  Download
                </button>
              </div>
            )}
          </div>
        </dialog>
      )}

      {/* Header */}
      <div className="w-full flex items-center justify-between px-8 min-h-[50px] z-40 sticky top-0 bg-white border-b-[0.5px] border-[#D3D3D3]">
        {/* Breadcrumb */}
        <span className="flex gap-4">
          <CollapsibleIcon isHeader />
          <span className=" font-medium text-center text-[#3F3F3F] flex gap-[5px] max-h-[24px]">
            <Breadcrumb className="text-base">
              {breadcrumbItems.map((item, index) => (
                <Breadcrumb.Item key={index}>{item.title}</Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </span>
        </span>

        {/* Buttons */}
        <span className="flex gap-2">
          <button
            type="button"
            className="flex items-center gap-2 bg-[#F3F4F6] text-[#333333] py-1 px-2 rounded-md hover:bg-[#d4d5d7]"
            onClick={handleExport}
            disabled={exporting}
          >
            {exporting ? (
              <SpinnerStatus />
            ) : (
              <>
                {`Export ${selectedRows.length === 1 ? "1 Lead" : selectedRows.length ? `${selectedRows.length} Leads` : "All Leads"}`}
                <FiUpload />
              </>
            )}
          </button>
          <button
            className={`flex items-center gap-2 font-medium ${
              connections?.length
                ? "bg-[#E1D9FF] text-[#333333] hover:bg-[#CBB8FF]"
                : "bg-[#333333] text-[#F3F4F6] hover:bg-[#151515]"
            } py-1 px-2 rounded-md transition-colors duration-200`}
            onClick={() => (!connections?.length ? setOpenDrawer(true) : setConnectionsPage(true))}
          >
            {connections?.length
              ? `Synced with ${connections.length} Floq${connections.length !== 1 ? "s" : ""}`
              : "Sync with Floq"}
            <FiWifi className={connections?.length ? "text-[#333333]" : "text-[#BBA8FF]"} />
          </button>
        </span>
      </div>

      {/* Analytics */}
      {!["crm-static", "static"].includes(watcher.status) && (
        <>
          <Analytics totalLeads={watcher?.lead_count || 0} listId={id || ""} />
          <hr className="my-4" />
        </>
      )}

      {/* Leads */}
      <div className="flex flex-col px-8 gap-2 mt-2 bg-white sticky top-[49px] z-30">
        <div className="flex gap-2 divide-x-2">
          <h2 className="font-medium text-2xl">Leads</h2>
          {watcher.status?.includes("crm") && getRefreshType(watcher)}
        </div>
        <div className="flex items-center justify-between">
          <span>
            {/* Seacrh Filters */}
            <CustomTooltip content={tooltipContent}>
              <div className="text-sm text-[#828282] flex items-center gap-2 min-h-[40px] max-w-[700px] truncate">
                Search filters :<span className="bg-[#F2F2F2] p-1 truncate">{formattedContent}</span>
              </div>
            </CustomTooltip>
          </span>
          <span className="flex items-center gap-1">
            {selectedRows.length > 0 && (
              <button
                className="flex items-center gap-2 py-1 px-2 rounded-[5px] border-[0.5px] text-[#A60003] border-[#A60003] bg-[#FFEDED] hover:bg-[#fedddd]"
                disabled={selectedRows.length < 1}
                onClick={handleDelete}
              >
                {isDeleting ? <SpinnerStatus /> : `Delete ${selectedRows.length}`}
                {!isDeleting && <img src={DeleteIconRed} alt="del-icon" className="h-4 w-4" />}
              </button>
            )}
            <Divider type="vertical" />
            <CopyHeadersButtons data={colDefs} />
            <SearchInput placeholder="Search for leads" onSearch={setSearchTerm} />
          </span>
        </div>
      </div>

      {/* Table */}
      <div className="flex flex-col min-h-[780px]">
        <div className="ag-theme-alpine ag-theme-custom h-full w-full block">
          <AgGridReact
            ref={grid}
            onGridReady={onGridReady}
            className="ag-theme-alpine font-favorit"
            rowData={filteredData}
            loading={tableLoading}
            tooltipShowDelay={1000}
            onColumnResized={onColumnResized}
            onSelectionChanged={(e) => {
              setSelectedRows(e.api.getSelectedRows());
            }}
            gridOptions={{
              rowSelection: {
                mode: "multiRow",
              },
              selectionColumnDef: {
                pinned: "left",
                headerClass: "border-r-0 pl-4",
                cellClass: "border-r-0 pl-4",
                cellStyle: { marginLeft: "16px" },
              },
              defaultColDef: {
                cellClass: "font-favorit",
              },
              getRowId: (row) => row.data._id,
            }}
            getRowId={(params) => {
              return params.data._id;
            }}
            overlayLoadingTemplate={`${renderToString(<Loader loaderType={LOADER_TYPES.fetching} payload="Table" />)}`}
            columnDefs={colDefs}
          />
        </div>
        <Pagination
          defaultCurrent={20}
          total={pagination.total}
          pageSize={pagination.pageSize || 20}
          current={pagination.current}
          onChange={(pageNumber: number, pageSize: number) =>
            setPagination((prev) => ({ ...prev, pageSize, current: pageNumber }))
          }
          showQuickJumper={true}
          pageSizeOptions={[20, 50, 100, 250]}
          showSizeChanger
          className="bg-white rounded-lg shadow-sm w-full px-5 py-4 ml-auto flex justify-end"
        />
      </div>
      <PushFloq
        open={pushToFloqModal}
        close={() => setPushToFloqModal(false)}
        fields={
          signal.outputStructureType === "dataDefined"
            ? listData.length > 0
              ? Object.keys(listData[0])
                  .map((field: string) => {
                    const skip = ["_id", "created_at", "list_id", "type"];
                    if (skip.includes(field)) return null;
                    return {
                      name: field,
                    };
                  })
                  .filter((field) => field)
              : []
            : signal.responseStructure
        }
      />
      <Connection
        open={openDrawer}
        update={undefined}
        removeMapping={removeMapping}
        close={() => {
          setRemoveMapping(true);
          // setSelectedWorkflow(undefined);
          setOpenDrawer(false);
        }}
        existingConnections={connections}
        workflows={workflows}
        setWorkflows={setWorkflows}
        loading={loading}
        selectedWorkflow={selectedWorkflow}
        setSelectedWorkflow={(workflow: any) => setSelectedWorkflow(workflow)}
        responseStructure={
          signal.outputStructureType === "dataDefined"
            ? listData.length > 0
              ? Object.keys(listData[0])
                  .map((field: string) => {
                    const skip = ["_id", "created_at", "list_id", "type"];
                    if (skip.includes(field)) return null;
                    return {
                      name: field,
                    };
                  })
                  .filter((field) => field)
              : []
            : signal.responseStructure
        }
        addConnection={(connection: any) => setConnections([connection, ...connections])}
        updateExistingConnection={(connection: any) => {
          const updatedConnections = connections.map((conn) =>
            conn.workflow_id === connection.workflow_id ? connection : conn
          );
          setConnections(updatedConnections);
        }}
        columnsDefination={colDefs}
      />
    </div>
  );
};

export default ListPage;

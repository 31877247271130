import { CELL_STATUS_SYMBOL } from "@/utils/constants";
import { Modal } from "antd";
import { useEffect, useState } from "react";

type Props = {
  open: boolean;
  close: () => void;
  errorData: any;
};

const ErrorModal = ({ open, close, errorData }: Props) => {
  const [newData, setNewData] = useState<any>(errorData);

  useEffect(() => {
    if (!open) setNewData({});
    else setNewData(errorData);
  }, [open, errorData]);

  return (
    <Modal
      title="Error"
      open={open}
      onOk={close}
      onCancel={close}
      width="30%"
      className="text-center"
      footer={[
        <button
          key="ok"
          className="px-4 py-0.5 rounded-md text-md font-semibold border border-black hover:bg-black hover:text-white"
          onClick={close}
        >
          Ok
        </button>,
      ]}
    >
      <div className="text-md my-4 w-full text-left">
        <p>
          <span className="font-bold">Status:</span> {CELL_STATUS_SYMBOL[newData.status]} {newData.status}
        </p>
        <p className="flex flex-col">
          <span className="font-bold">Message:</span> {newData.message}
        </p>
      </div>
    </Modal>
  );
};

export default ErrorModal;

import { Handle, NodeProps, Position } from "@xyflow/react";
import DefaultAction from "@/assets/SVGs/DefaultAction";
import XLSXInput from "../Import/XLSXInput";
import { useWorkflow } from "@/contexts/WorkflowContext";
import Upload from "@/assets/SVGs/buildTab/Upload.svg";
import { useRunOnFloq } from "@/contexts/RunOnFloqContext";
import { CELL_STATUS_DISPLAY_2, CELL_STATUS_SYMBOL_2, USER_ACTION_STATUS } from "@/utils/constants";
import { ReactNode } from "react";
import { ActionCounts } from "@/utils/interfaces";
import DeleteAction from "@/assets/SVGs/DeleteAction";
import { addWorkflowToSubfloq } from "@/utils/apis";

export function InputNode({ data, id }: NodeProps) {
  const { actions, actionCounts, isSubfloq, saveWorkflowActions, workflowConfig, setActions } = useWorkflow();
  const { action } = useRunOnFloq();
  const responses = actions?.find((action) => action.id === id)?.responseConfiguration || [];
  const upload = responses.length === 0 && actions.length === 1 && data?.name === "Input";

  const getStats = (actionCounts: ActionCounts) => {
    const badges: ReactNode[] = [];

    Object.values(USER_ACTION_STATUS).forEach((status) => {
      if (!actionCounts?.[id]?.[status]) {
        return null;
      }
      const StatsWrapper = (children: ReactNode) => {
        return (
          <div
            key={status}
            className="bg-gray-100 items-center justify-center rounded-[2px] !text-[8px] min-w-fit text-nowrap flex gap-[0.15rem] px-[0.35rem] min-h-[1rem]"
          >
            {children}
          </div>
        );
      };
      if (status === "checkingNextSource") return null;
      if (status === "payloadFilled") {
        badges.push(
          StatsWrapper(
            <>
              <span>{CELL_STATUS_SYMBOL_2[status]}</span>
              <span>{(actionCounts?.[id]?.[status] || 0) + (actionCounts[id]?.checkingNextSource || 0)}</span>
              <span>{CELL_STATUS_DISPLAY_2[status]}</span>
            </>
          )
        );
        return;
      }

      badges.push(
        StatsWrapper(
          <>
            <span>{CELL_STATUS_SYMBOL_2[status]}</span>
            <span>{actionCounts?.[id]?.[status] || 0}</span>
            <span>{CELL_STATUS_DISPLAY_2[status]}</span>
          </>
        )
      );
    });

    if (badges.length === 0) return null;
    return <div className="py-2 px-2 border-t border-gray-400 flex gap-1 justify-start flex-wrap">{badges}</div>;
  };

    const deleteSubfloqNode = async (e: any, id: string) => {
      e.stopPropagation();
      const firstAction = actions?.find((action: any) => action.id === id);
      if (!firstAction) return;
      let tempArray: any[] = [...actions];
  
      let temp = firstAction;
      const ind = actions?.findIndex((action: any) => action.id === firstAction.prevAction[0]?.actionId);
  
      // Collect all nodes that should be deleted
      while (temp && !temp.id.includes("idout")) {
        tempArray = tempArray.filter((action) => action.id !== temp.id);
        temp = actions?.find((action: any) => action.id === temp.nextAction[0]?.actionId);
      }
  
      const idx = tempArray[ind].nextAction.findIndex((next: any) =>
        (next && typeof next === "object" ? next.actionId : next).includes("id2")
      );
      if (idx !== -1) tempArray[ind].nextAction.splice(idx, 1, ...temp.nextAction);
  
      // Ensure "idout" is also deleted
      tempArray = tempArray.filter((action) => !action.id.includes("idout"));
      setActions(tempArray);
      await saveWorkflowActions(workflowConfig.id, isSubfloq);
  
      await addWorkflowToSubfloq(firstAction.subfloq_id, workflowConfig.id, workflowConfig.name, "remove");
    };

  return (
    <>
      <div className="nodrag w-[36vw] cursor-pointer bg-white">
        {(data.type !== "input" || (!isSubfloq && id.includes("id2"))) && (
          <Handle type="target" position={Position.Top} isConnectable={true} />
        )}
        <div
          className={`transition-opacity duration-300 w-full rounded relative z-10 ${
            upload
              ? "outline-2 outline-dashed outline-gray-400 hover:outline-primary bg-primary/5"
              : action && action?.id === id
                ? "border border-primary bg-gray-50"
                : "border border-gray-400 bg-white  hover:border-primary hover:bg-gray-50"
          }`}
        >
          <div className="absolute group top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full py-2 px-16 box-content">
            {id.includes("id2") && (
              <div className="flex items-col space-y-2 absolute top-1/2 transform -translate-y-1/2 duration-200 right-1 opacity-0 group-hover:-right-0  translate-x-[-1.5rem] group-hover:opacity-100">
                <button
                  onClick={(e) => deleteSubfloqNode(e, id)}
                  className="group focus:outline-none flex select-none px-3 py-2 font-semibold focus:ring-purple-500 border bg-white text-purple-800 hover:bg-gray-50 hover:border-purple-600 rounded-full !h-8 !w-8 items-center justify-center !p-0 border-grey-500 ring-offset-2 focus:ring-2"
                >
                  <DeleteAction />
                </button>
              </div>
            )}
          </div>
          <div className="flex justify-between items-start sm:items-center w-full">
            {!upload && (
              <div className="action-item-card flex items-center justify-center gap-4 py-3 px-4 md:p-6">
                <div className="h-8 w-8 relative flex justify-center items-center rounded-full flex-shrink-0 ">
                  {(data.logo as string).includes("https") ? (
                    <img src={data.logo as string} alt="icon" />
                  ) : (
                    <DefaultAction />
                  )}
                </div>

                <div className="flex w-fit items-center justify-between overflow-hidden">
                  <label className="text-grey-900 text-base font-semibold select-none pointer-events-none whitespace-no-wrap w-full break-words line-clamp-2">
                    {data.name as string}
                  </label>
                </div>
              </div>
            )}
            <div className={upload ? "w-full" : "w-fit"}>
              <XLSXInput template={responses} refresh={() => {}}>
                {!isSubfloq && !id.includes("id2") &&
                  (!upload ? (
                    <div className="py-3 px-4 md:p-6">
                      <button
                        className="border border-gray-500 text-xs font-light px-1.5 py-0.5 rounded-sm hover:shadow hover:bg-gray-200"
                        onClick={() => {
                          if (data?.closeRunModal) {
                            // @ts-ignore
                            data?.closeRunModal();
                          }
                        }}
                      >
                        Import
                      </button>
                    </div>
                  ) : (
                    <div className="flex justify-between items-start sm:items-center w-full py-3 px-4 md:p-6">
                      <div className="action-item-card flex items-center justify-center gap-4">
                        <div className="h-10 w-10 relative flex justify-center items-center rounded-full flex-shrink-0 ">
                          <img src={Upload} alt="icon" />
                        </div>

                        <div className="flex flex-col w-fit items-center justify-between overflow-hidden text-gray-500 text-base">
                          <label className="font-light text-lg select-none pointer-events-none whitespace-no-wrap w-full break-words line-clamp-2">
                            Select a file or drop here
                          </label>
                          <label className="text-xs font-extralight select-none pointer-events-none whitespace-no-wrap w-full break-words line-clamp-2">
                            Excel, CSV and TSV file formats accepted
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
              </XLSXInput>
            </div>
            {upload && (
              <div className="py-3 px-4 md:p-6">
                <button className="border border-gray-500 text-xs font-light px-1.5 py-0.5 rounded-sm hover:shadow hover:bg-gray-200 w-fit text-nowrap">
                  Manual Config
                </button>
              </div>
            )}
          </div>
          {getStats(actionCounts)}
        </div>
        {!upload && <Handle type="source" position={Position.Bottom} isConnectable={true} id="a" />}
      </div>
    </>
  );
}

import { DatePicker, message, Radio } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PayloadStructure } from "@/utils/interfaces";
import dayjs from "dayjs";
import { SIGNAL_ID_TO_LIST_PREVIEW_URL, SIGNAL_ID_TO_URL } from "@/utils/urls";
import { createSignal, signalPreview } from "../../apis";
import HBfilterPreview from "./HBfilterPreview";
import { AiOutlineArrowLeft } from "react-icons/ai";
import CustomCronGenerator from "./CustomCronGenerator";
import LoaderButton from "@/Components/LoaderButtonBlack";
import FilePlusCreate from "@/assets/SVGs/FilePlusCreate";
import PipedriveInputModal from "./modals/PipedriveInputModal";
import { Switch } from "radix-ui";

type Step = {
  id: number;
  title: string;
  component: React.ReactNode;
};

type StepFiveProps = {
  prev: () => void;
  logo: string;
  payloadStructure: any;
  payloads: any;
  setPayloads: any;
  createList: () => Promise<void>;
};

type Props = {
  signal: any;
  payloads: any;
  setPayloads: any;
  name: string;
  setListType: any;
};

const getPayloadLayout = (payload: PayloadStructure, payloads: any, setPayloads: any) => {
  switch (payload.type) {
    case "date":
      const currentDate = dayjs().format("YYYY-MM-DD"); // Default to today's date
      const selectedDate = payloads[payload.payloadStructureId] || null; // Get saved date

      return (
        <div className="flex flex-col items-start gap-2 border-b w-full pb-2">
          <div className="flex gap-2 items-center">
            <h1>
              {payload.name} {payload.required && <span className="text-red-500">*</span>}
            </h1>
            <div className="flex items-center gap-4">
              <Radio.Group
                value={selectedDate ? "on" : "never"}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === "never") {
                    setPayloads((prev: any) => ({ ...prev, [payload.payloadStructureId]: null }));
                  } else {
                    setPayloads((prev: any) => ({
                      ...prev,
                      [payload.payloadStructureId]: currentDate, // Default to today when switching to "On"
                    }));
                  }
                }}
              >
                <Radio.Button value="never">Never</Radio.Button>
                <Radio.Button value="on">On</Radio.Button>
              </Radio.Group>

              {selectedDate && (
                <DatePicker
                  format="DD MMMM, YYYY"
                  className="p-1 rounded"
                  allowClear={false}
                  value={selectedDate ? dayjs(selectedDate, "YYYY-MM-DD") : null}
                  onChange={(value) =>
                    setPayloads((prev: any) => ({
                      ...prev,
                      [payload.payloadStructureId]: value?.format("YYYY-MM-DD"),
                    }))
                  }
                />
              )}
            </div>
          </div>
          <p className="text-xs text-gray-400">{payload.description}</p>
        </div>
      );

    case "crmFrequency":
      return (
        <div className="flex flex-col items-start gap-2 w-full">
          <h1>
            {payload.name} {payload.required && <span className="text-red-500">*</span>}
          </h1>
          <p className="text-xs text-gray-400">{payload.description}</p>
          <CustomCronGenerator
            onCronChange={(cron) =>
              setPayloads((prev: any) => ({
                ...prev,
                [payload.payloadStructureId]: cron,
              }))
            }
          />
        </div>
      );

    case "toggle":
      return (
        <div className="w-full flex flex-col items-start gap-2">
          <div className="flex items-center gap-2">
            <h1>{payload.name}</h1>
            <Switch.Root
              defaultChecked={payloads?.pullExisting || true}
              onCheckedChange={(value) => {
                setPayloads((prev: any) => ({
                  ...prev,
                  [payload.payloadStructureId]: value,
                }));
              }}
              className="w-14 h-6 flex items-center bg-gray-300 data-[state=checked]:bg-[#D9CFFF] rounded relative transition-all"
            >
              <Switch.Thumb className="w-7 h-5 rounded bg-white transition-transform data-[state=checked]:translate-x-6 translate-x-1" />
            </Switch.Root>
          </div>
          <p className="text-xs text-gray-400">{payload.description}</p>
        </div>
      );

    default:
      return;
  }
};

const StepFive = ({ prev, logo, payloadStructure, payloads, setPayloads, createList }: StepFiveProps) => (
  <div className="p-4 border w-[60%] mx-auto mt-10 flex flex-col items-center justify-center gap-8 rounded-lg">
    <div className="flex flex-col items-start justify-start w-full gap-3 mb-4">
      <div className="flex gap-2 items-center justify-between w-full">
        <h2 className="text-xl flex items-center gap-2">
          <img src={logo} alt="logo" className="h-6 w-6" />
          How often would you like to refresh this list?
        </h2>
        <button
          className="text-gray-800 px-1 py-1 rounded disabled:opacity-50 border border-gray-800 hover:bg-gray-100"
          onClick={prev}
        >
          <AiOutlineArrowLeft />
        </button>
      </div>
      <p className="text-sm text-gray-500">
        We'll automatically pull the newest information according to the frequency you select, keeping your list up to
        date.
      </p>
    </div>
    <div className="flex flex-col gap-4 rounded w-full items-center justify-center">
      {payloadStructure.map((payload: any) => getPayloadLayout(payload, payloads, setPayloads))}
    </div>
    <div className="mt-4 flex gap-2 w-full items-center">
      {/* <button className="bg-[#333333] flex gap-2 text-lg items-center text-white px-8 py-2 rounded" disabled={loading} onClick={async () => await createList()}>
        <img src={filePlus1} alt="" /> {loading ? "Creating..." : "Create list"}
      </button> */}
      <LoaderButton
        text="Create List"
        loaderText="Creating List..."
        btnClasses="bg-black text-white px-8"
        onClickCallback={async () => await createList()}
        error=""
        disabled={false}
        IconComponent={FilePlusCreate}
      />
    </div>
  </div>
);

// this is for active CRM list building

const PipedriveMultiStepForm = ({ signal, payloads, setPayloads, name, setListType }: Props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [runCondition, setRunCondition] = useState({ conditions: [] });
  const [loading, setLoading] = useState(false);
  const [previewData, setPreviewData] = useState<any>([]);
  const navigate = useNavigate();

  const validatePayload = (payloadData: Record<string, any>, payloadStructure: any[]) => {
    return payloadStructure.every((item) => {
      if (!item.required) return true; // Skip non-required fields
      const fieldValue = payloadData[item.payloadStructureId];
      // Check if the required field exists and is not empty
      if (fieldValue === undefined || fieldValue === null) return false;
      // Check if it's an array and is empty
      if (Array.isArray(fieldValue) && fieldValue.length === 0) return false;
      // Check if it's an object and is empty
      if (typeof fieldValue === "object" && Object.keys(fieldValue).length === 0) return false;
      return true;
    });
  };

  const generatePreview = async (payload: any) => {
    setLoading(true);
    try {
      const url = SIGNAL_ID_TO_LIST_PREVIEW_URL[signal.id as keyof typeof SIGNAL_ID_TO_LIST_PREVIEW_URL];
      const responses = await signalPreview(url, payload);
      setPreviewData(responses);
      setLoading(false);
    } catch (error: any) {
      message.error(error.message || error);
      setLoading(false);
    }
  };
  const createList = async () => {
    const isValidPayload = validatePayload(payloads, signal.payloadStructure);
    // check if all required payloads are present
    if (!isValidPayload) {
      message.error("Please fill all required fields");
      return;
    }
    const newId = await createSignal(SIGNAL_ID_TO_URL[signal.id as keyof typeof SIGNAL_ID_TO_URL], payloads, name);
    navigate(`/list/${newId}`);
  };

  const steps: Step[] = (
    [
      {
        id: 2,
        title: "Step Two",
        component: (
          <PipedriveInputModal
            payloadStructure={signal.payloadStructure}
            payloads={payloads}
            setPayloads={setPayloads}
            prev={() => setListType(null)}
            next={() => setCurrentStep(1)}
          />
        ),
      },
      {
        id: 3,
        title: "Step Three",
        component: (
          <HBfilterPreview
            prev={() => setCurrentStep(0)}
            next={() => (payloads.listType === "active" ? setCurrentStep(2) : setCurrentStep(1))}
            signal={signal}
            generatePreview={generatePreview}
            previewData={previewData}
            loading={loading}
            payloads={payloads}
            propertiesFilter={payloads?.crmProperties}
            setPayloads={setPayloads}
            runCondition={runCondition}
            setRunCondition={setRunCondition}
            name={name}
          />
        ),
      },
      payloads.listType === "active"
        ? {
            id: 4,
            title: "Step Four",
            component: (
              <StepFive
                prev={() => setCurrentStep(1)}
                logo={signal.logo}
                payloadStructure={signal.payloadStructure}
                payloads={payloads}
                setPayloads={setPayloads}
                createList={createList}
              />
            ),
          }
        : null,
    ] as Step[]
  ).filter(Boolean);

  return (
    <div className="newScroll h-full w-full flex flex-col !cursor-default !font-favorit" id="scroll-to-top-container">
      <div className="h-[70%]">
        <div className="h-full">{steps[currentStep].component}</div>
      </div>
    </div>
  );
};

export default PipedriveMultiStepForm;

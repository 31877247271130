const WorkflowSkeleton = () => {
  return (
    <div className="animate-pulse">
      {[...Array(5)].map((_, i) => (
        <div key={i} className="px-8 py-4 grid grid-cols-8 border-b border-gray-100">
          <div className="col-span-6 flex items-center gap-4">
            <div className="w-4 h-4 bg-gray-200 rounded" />
            <div className="h-4 bg-gray-200 rounded w-1/3" />
          </div>
          <div className="col-span-2">
            <div className="h-4 bg-gray-200 rounded w-1/2" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default WorkflowSkeleton;

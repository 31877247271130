import { finalConditions, getIconFromType, inputTypeFromVariableType, setStateType } from "../../../utils/constants";
import { useCallback, useEffect, useState, useRef } from "react";
import { useWorkflow } from "../../../contexts/WorkflowContext";
import { NextActionConfiguration, ResponseStructure } from "../../../utils/interfaces";
import { ConfigProvider, Divider, Drawer, Select } from "antd";
import { FaRegEdit } from "react-icons/fa";
import { useParams } from "react-router-dom";
import DeleteIcon from "@/assets/SVGs/DeleteIcon.svg";
import { LuPlus } from "react-icons/lu";
import ConditionalInput from "./ModalComponents/ConditionalInput";
import { TagInputRef } from "@/Components/Common/TagInput";

type Props = {
  source: string;
  idx: number;
  modal: boolean;
  setModal: setStateType<boolean>;
  nextActionConfiguration: NextActionConfiguration;
  variables: any[];
  nameEditable?: boolean;
};

const NewConditionalModal = ({
  source,
  idx,
  modal,
  setModal,
  variables,
  nextActionConfiguration,
  nameEditable = true,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const { setActions, saveWorkflowActions, setPublishWarning, actions } = useWorkflow();
  const [drawerWidth, setDrawerWidth] = useState("30%");
  const [name, setName] = useState(nextActionConfiguration?.name || "");
  const [changeName, setChangeName] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [conditions, setConditions] = useState<any[]>(nextActionConfiguration.conditions || []);
  const { id } = useParams();
  const [hasInputInTags, setHasInputInTags] = useState(false);
  const tagInputRefs = useRef<Record<string, TagInputRef | null>>({});

  useEffect(() => {
    setWidthPercent();
    window.addEventListener("resize", setWidthPercent);
    return () => window.removeEventListener("resize", setWidthPercent);
  }, []);

  useEffect(() => {
    if (!conditions || !modal) return;

    const updatedConditions = conditions.map((conditionGroup: any) => {
      if (conditionGroup.conditions) return conditionGroup;
      return {
        conditions: [{ ...conditionGroup, operator: "AND" }],
      };
    });
    setConditions(updatedConditions);
  }, [modal]);

  const setWidthPercent = useCallback(() => {
    try {
      // Adjust width for different screen sizes
      if (window.matchMedia("(min-width: 1024px) and (min-height: 1366px)").matches) {
        // iPad Pro and similar devices
        setDrawerWidth("50%");
      } else if (window.matchMedia("(min-width: 1024px)").matches) {
        setDrawerWidth("30%");
      } else if (window.matchMedia("(min-width: 640px)").matches) {
        setDrawerWidth("50%");
      } else {
        setDrawerWidth("100%");
      }
    } catch (err) {
      console.error("Error calculating width percent based on media size");
    }
  }, []);

  useEffect(() => {
    setConditions(
      nextActionConfiguration.conditions || [
        {
          variable: "",
          condition: "",
          variableType: "",
          values: [],
        },
      ]
    );
  }, [nextActionConfiguration]);

  useEffect(() => {
    if (conditions !== (nextActionConfiguration.conditions || [])) setChanged(true);
    else setChanged(false);
  }, [conditions]);

  useEffect(() => {
    if (!variables) return;
    const newOpts: any[] = [];
    variables.forEach((action) => {
      action?.variables?.forEach((variable: ResponseStructure) => {
        const option = {
          id: variable.responseId,
          logo: action.logo,
          type: variable.type || "string",
          name: variable.name,
          actionName: action.name,
        };
        newOpts.push(option);
      });
    });
    setOptions(newOpts);
  }, [variables]);

  const saveOutputStructure = async () => {
    setLoading(true);

    // Process any remaining input in tag inputs
    Object.values(tagInputRefs.current).forEach((ref) => {
      if (ref) {
        ref.processRemainingInput();
      }
    });

    // Small delay to ensure tag processing is complete
    setTimeout(async () => {
      const tempActions = [...actions];
      const sourceIdx = tempActions.findIndex((action) => action.id === source);
      const sourceAction = tempActions[sourceIdx];

      const payload = sourceAction.payloadConfiguration || [];
      const pathPayload = payload.find((config: any) => config.payloadStructureId === "path_conditions");
      pathPayload.inputString[idx] = {
        name,
        conditions,
      };

      setActions(tempActions);
      await saveWorkflowActions(id || "");
      setChanged(false);
      setLoading(false);
      setPublishWarning(true);
    }, 50);
  };

  // Update the changed state when tag inputs have content
  useEffect(() => {
    if (hasInputInTags) {
      setChanged(true);
    }
  }, [hasInputInTags]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={modal}
        mask={false}
        getContainer={!nameEditable && name !== "if" ? false : document.body}
        onClose={() => setModal(false)}
        width={drawerWidth}
        styles={{
          content: {
            transition: "width 0.3s",
          },
        }}
        classNames={{ header: "border-b !border-gray-300" }}
        className="ml-auto !font-favorit !p-0 border-l border-gray-500"
        footer={
          <div className="w-full flex flex-col gap-4 bg-white">
            <button
              className={` text-white w-full font-semibold rounded-lg p-2 mt-auto ${
                loading
                  ? "cursor-not-allowed bg-gray-500"
                  : changed || hasInputInTags
                    ? "bg-primary cursor-pointer"
                    : "cursor-not-allowed bg-gray-500"
              }`}
              onClick={saveOutputStructure}
              disabled={loading || (!changed && !hasInputInTags)}
            >
              {loading ? "Saving..." : !changed && !hasInputInTags ? "Saved" : "Save"}
            </button>
          </div>
        }
        title={
          <div className="flex items-center gap-2">
            <span
              id="actionName"
              contentEditable={changeName}
              className={changeName ? "border border-black px-2" : ""}
              suppressContentEditableWarning
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  e.target.blur();
                }
              }}
              onBlur={async (e) => {
                if (!e.target.textContent) return;
                setName(e.target.textContent);
                setChangeName(false);
                setChanged(true);
              }}
            >
              {name || ""}
            </span>
            {nameEditable && (
              <button
                onClick={() => {
                  setChangeName(!changeName);
                  const p = document.getElementById("actionName");
                  if (!p) return;
                  setTimeout(function () {
                    const range = document.createRange();
                    const selection = window.getSelection();
                    range.selectNodeContents(p);
                    range.collapse(false); // <-- Set the cursor at the end of the selection
                    selection?.removeAllRanges();
                    selection?.addRange(range);
                    p.focus();
                  }, 0);
                }}
                className={`p-2 rounded-md transition-opacity duration-600 ease-in-out hover:bg-gray-200 ${
                  changeName ? "opacity-50" : "opacity-100"
                }`}
              >
                <FaRegEdit />
              </button>
            )}
          </div>
        }
      >
        <div className="bg-white gap-10 flex flex-col">
          {conditions &&
            conditions?.map((conditionGroup, parentIdx) => (
              <div key={parentIdx} className="flex flex-col gap-4 bg-gray-100/20 py-4 border border-1 rounded-md">
                <div className="flex gap-2 px-4 w-full items-center justify-between">
                  <p className="text-headings font-medium text-md">Condition {parentIdx + 1}</p>
                  {parentIdx !== 0 && (
                    <Select
                      showSearch
                      className="flex gap-2 ml-2 mr-auto items-center !text-headings"
                      value={conditionGroup.operator || "AND"}
                      onChange={(value) => {
                        const temp = [...conditions];
                        temp[parentIdx].operator = value;
                        setConditions(temp);
                      }}
                      options={[
                        {
                          label: "AND",
                          value: "AND",
                        },
                        {
                          label: "OR",
                          value: "OR",
                        },
                      ]}
                    />
                  )}
                  <button
                    className="rounded-full w-10 h-10 px-3 flex gap-1 items-center text-red-500 hover:bg-red-50 hover:border-red-500"
                    onClick={() => {
                      const temp = [...conditions];
                      temp.splice(parentIdx, 1);
                      setConditions(temp);
                    }}
                  >
                    <img src={DeleteIcon} alt="Delete" className="w-4 h-4" />
                  </button>
                </div>
                {conditionGroup.conditions?.map((field: any, idx: number) => (
                  <div
                    key={idx}
                    className={`flex gap-4 rounded-lg ${idx !== 0 ? "ml-8 mr-4 outline-2 outline-dashed outline-gray-200" : ""}`}
                  >
                    <div className="w-full flex flex-col">
                      {idx !== 0 && (
                        <>
                          <div className="flex justify-between items-center p-4">
                            <Select
                              className="flex w-fit gap-2 items-center"
                              showSearch
                              value={field.operator || "AND"}
                              onChange={(value) => {
                                const temp = [...conditions];
                                temp[parentIdx].conditions[idx].operator = value;
                                setConditions(temp);
                              }}
                              options={[
                                {
                                  label: "AND",
                                  value: "AND",
                                },
                                {
                                  label: "OR",
                                  value: "OR",
                                },
                              ]}
                            />
                            <button
                              className="rounded-full w-10 h-10 px-3 flex gap-1 items-center text-red-500 hover:bg-red-50 hover:border-red-500"
                              onClick={() => {
                                const temp = [...conditions];
                                temp[parentIdx].conditions.splice(idx, 1);
                                setConditions(temp);
                              }}
                            >
                              <img src={DeleteIcon} alt="Delete" className="w-4 h-4" />
                            </button>
                          </div>
                          <div className="my-0 bg-gray-200 outline-dashed outline-1 outline-gray-200" />
                        </>
                      )}
                      <div className={`flex gap-2 items-start w-full ${idx === 0 ? "px-4" : "p-4"}`}>
                        <p className="font-medium text-md text-gray-500">if</p>
                        <div className="w-full flex flex-col gap-2">
                          <Select
                            showSearch
                            allowClear
                            placeholder="Select an output from an action"
                            className="w-full"
                            value={field.variable || undefined}
                            onClear={() => {
                              const temp = [...conditions];
                              temp[parentIdx].conditions[idx] = {
                                variable: "",
                                condition: "",
                                variableType: "",
                                values: [],
                                operator: temp[parentIdx].conditions[idx].operator,
                                caseSensitive: false,
                              };
                              setConditions(temp);
                            }}
                            onChange={(value) => {
                              const temp = [...conditions];
                              const option = options.find((option) => option.id === value);
                              if (field.variableType === option.type) temp[parentIdx].conditions[idx].variable = value;
                              else
                                temp[parentIdx].conditions[idx] = {
                                  variable: value,
                                  condition: "",
                                  variableType: option.type,
                                  values: [],
                                  operator: temp[parentIdx].conditions[idx].operator,
                                  caseSensitive: temp[parentIdx].conditions[idx].caseSensitive || false,
                                };
                              setConditions(temp);
                            }}
                            options={options.map((option, index: number) => ({
                              label: (
                                <div className="flex min-w-full flex-row items-center text-sm gap-2" key={index}>
                                  <img src={option.logo} alt="logo" className="w-6 h-6" />
                                  <span className="text-gray-400 text-xs max-w-[50%] whitespace-nowrap overflow-hidden text-ellipsis">
                                    {option.actionName}
                                  </span>
                                  <p className="">{">"}</p>
                                  <div className="flex items-center gap-2 justify-center">
                                    <p className="bg-gray-200 p-1 rounded">{getIconFromType(option.type)}</p>
                                    {option.name}
                                  </div>
                                </div>
                              ),
                              value: option.id,
                              name: `${option.actionName} - ${option.name}`,
                            }))}
                            optionFilterProp="name"
                          />
                          {field.variableType !== "" && (
                            <Select
                              showSearch
                              allowClear
                              className="w-[50%]"
                              placeholder="Select a condition"
                              value={field.condition || undefined}
                              onChange={(value) => {
                                const temp = [...conditions];
                                temp[parentIdx].conditions[idx].condition = value;
                                setConditions(temp);
                              }}
                              options={finalConditions(field.variableType) || []}
                            />
                          )}
                          {field.condition !== "" &&
                            !(field.condition === "is empty" || field.condition === "is not empty") && (
                              <div className="flex flex-col gap-2 w-[50%]">
                                <ConditionalInput
                                  field={field}
                                  onChange={({ values, caseSensitive }) => {
                                    const temp = [...conditions];
                                    if (values !== undefined) {
                                      temp[parentIdx].conditions[idx].values = values;
                                    }
                                    if (temp[parentIdx].conditions[idx].caseSensitive === undefined) {
                                      temp[parentIdx].conditions[idx].caseSensitive = false;
                                    }
                                    if (caseSensitive !== undefined) {
                                      temp[parentIdx].conditions[idx].caseSensitive = caseSensitive;
                                    }
                                    setConditions(temp);
                                  }}
                                  ref={(el) => {
                                    // Store a reference to each TagInput inside ConditionalInput
                                    tagInputRefs.current[`${parentIdx}-${idx}`] = el;
                                  }}
                                  onInputChange={(hasInput) => {
                                    if (hasInput) {
                                      setHasInputInTags(true);
                                    }
                                  }}
                                />
                              </div>
                            )}
                          {field.condition === "is between" && (
                            <>
                              <p>To:</p>
                              <input
                                type={inputTypeFromVariableType(field.variableType)}
                                placeholder="Enter another value..."
                                className="w-[50%] rounded-md p-1 px-2 border-gray-200"
                                value={field.values?.[1] || ""}
                                onChange={(e) => {
                                  const temp = [...conditions];
                                  temp[parentIdx].conditions[idx].values[1] = e.target.value;
                                  setConditions(temp);
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <Divider className="my-0" />
                <button
                  className="w-fit rounded-lg px-4 flex gap-1 hover:underline"
                  onClick={() => {
                    const temp = [...conditions];
                    temp[parentIdx].conditions.push({
                      variable: "",
                      condition: "",
                      variableType: "",
                      values: [],
                      operator: "AND",
                      caseSensitive: false,
                    });
                    setConditions(temp);
                  }}
                >
                  <LuPlus size={20} />
                  Add Sub-Condition
                </button>
              </div>
            ))}
          <button
            className="border w-fit border-black rounded-lg p-2"
            onClick={() => {
              const temp = [...conditions];
              temp.push({
                conditions: [
                  {
                    variable: "",
                    condition: "",
                    variableType: "",
                    values: [],
                    operator: "AND",
                    caseSensitive: false,
                  },
                ],
              });
              setConditions(temp);
            }}
          >
            + Add condition
          </button>
        </div>
      </Drawer>
    </ConfigProvider>
  );
};

export default NewConditionalModal;

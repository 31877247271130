import { useEffect, useState } from "react";
import { Select, Skeleton } from "antd";
import { FiBarChart } from "react-icons/fi";
import { BarChart } from "@mui/x-charts";
import { fetchPeriodicLeadData } from "../apis";

const { Option } = Select;

type AnalyticsProps = {
  totalLeads?: number;
  totalLeadsPushed?: number;
  listId: string;
};

type ChartData = {
  period: string;
  count: number;
};

const BarChartSkeleton = () => {
  return (
    <div className="flex items-end gap-2 p-8 h-full w-full">
      {Array.from({ length: 10 }).map((_, index) => (
        <Skeleton.Input
          key={index}
          active
          className="flex-1"
          style={{
            height: `${Math.floor(Math.random() * 300) + 100}px`,
            minWidth: "20px",
            maxWidth: "80px",
          }}
        />
      ))}
    </div>
  );
};

const Analytics = ({ totalLeads, totalLeadsPushed, listId }: AnalyticsProps) => {
  const [dataset, setDataset] = useState<ChartData[]>([]);
  const [timePeriod, setTimePeriod] = useState<string>("daily");
  const [timeRange, setTimeRange] = useState<string>("last3Months");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetchPeriodicLeadData(listId, timePeriod, timeRange);
        setDataset(response.formattedLeads);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [listId, timePeriod, timeRange]);

  const handleTimePeriodChange = (value: string) => setTimePeriod(value);
  const handleTimeRangeChange = (value: string) => setTimeRange(value);

  const chartSetting = {
    sx: {
      // Add any custom styles here
    },
  };

  const xAxis: any = [{ dataKey: "period", scaleType: "band" }];
  const yAxis: any = [{ scaleType: "linear" }];
  const series = [{ dataKey: "count", color: "#F1BAFF" }];

  return (
    <div className="flex flex-col gap-8 mt-5">
      <div className="flex items-center justify-between px-8">
        <span className="text-[#333333] flex items-center gap-2 text-2xl font-medium">
          <FiBarChart className="font-medium" /> Signal Analytics
        </span>
        <span className="flex items-center gap-2">
          <Select value={timePeriod} onChange={handleTimePeriodChange} className="w-full max-w-[120px]">
            <Option value="daily">Daily</Option>
            <Option value="monthly">Monthly</Option>
            <Option value="yearly">Yearly</Option>
          </Select>
          <Select value={timeRange} onChange={handleTimeRangeChange} className="w-full max-w-[150px]">
            <Option value="thisMonth">This Month</Option>
            <Option value="last30Days">Last 30 Days</Option>
            <Option value="lastMonth">Last Month</Option>
            <Option value="last3Months">Last 3 Months</Option>
            <Option value="thisYear">This Year</Option>
            <Option value="lastYear">Last Year</Option>
          </Select>
        </span>
      </div>
      <div className="flex gap-6 px-8">
        <div className="flex flex-col gap-4 text-[#333333]">
          <div className="flex flex-col gap-3 p-5 h-[120px] w-[350px] rounded-[9px] border border-[#333333]">
            <div className="text-lg flex items-center gap-3">
              <span className="h-5 w-5 rounded-full bg-[#F1BAFF]"></span>
              <span>Total leads generated</span>
            </div>
            <div className="text-4xl font-mono">{totalLeads}</div>
          </div>
          {totalLeadsPushed && (
            <div className="flex flex-col gap-3 p-5 h-[120px] w-[350px] rounded-[9px] border border-[#333333]">
              <div className="text-lg flex items-center gap-3">
                <span className="h-5 w-5 rounded-full bg-[#BAC7FF]"></span>
                <span>Pushed to Floqs</span>
              </div>
              <div className="text-4xl font-mono">{totalLeadsPushed}</div>
            </div>
          )}
        </div>
        <div className="flex flex-col h-[500px] overflow-hidden w-full p-5 rounded-[9px] border border-[#333333]">
          {loading ? (
            <BarChartSkeleton />
          ) : (
            <BarChart dataset={dataset} xAxis={xAxis} yAxis={yAxis} series={series} {...chartSetting} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Analytics;

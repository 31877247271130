import { getTypeFromValue } from "@/utils/constants";
import { useState } from "react";
import { BiCopy } from "react-icons/bi";
import { CiImageOn } from "react-icons/ci";
import { FaDollarSign, FaLink } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { IoCheckmark } from "react-icons/io5";
import { MdAlternateEmail, MdCalendarToday, MdDataArray, MdNumbers } from "react-icons/md";
import { TbBraces, TbTextSize } from "react-icons/tb";

type Props = {
  keyName: any;
  value: any;
  prev: string;
  showValue?: boolean;
  addField: (key: string) => void;
};

const PingViewer = ({ keyName, value, addField, prev = "", showValue = false }: Props) => {
  const isObject = value && typeof value === "object";
  const [isOpen, setIsOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isAdded, setIsAdded] = useState(false);

  const isAtomic = (value: any) => typeof value !== "object" || value === null;

  // Convert the value to a string for length check.
  const valueStr = value?.toString() || "";
  // Define a threshold for what we consider a "long" value.
  const LONG_THRESHOLD = 36;
  const isLongValue = showValue && isAtomic(value) && valueStr.length > LONG_THRESHOLD;

  const getIconFromType = (type: string) => {
    switch (type) {
      case "string":
        return <TbTextSize />;
      case "email":
        return <MdAlternateEmail />;
      case "url":
        return <FaLink />;
      case "imageUrl":
        return <CiImageOn />;
      case "number":
        return <MdNumbers />;
      case "currency":
        return <FaDollarSign />;
      case "date":
        return <MdCalendarToday />;
      case "array":
        return <MdDataArray />;
      case "json":
        return <TbBraces />;
      default:
        return <TbTextSize />;
    }
  };

  const handleAddField = () => {
    addField(prev ? prev + "." + keyName : keyName);
    setIsAdded(true);
    setTimeout(() => setIsAdded(false), 1000);
  };

  const copyAndAddButtons = (
    <>
      {!isCopied ? (
        <BiCopy
          className="hover:bg-gray-200 p-1 cursor-pointer rounded"
          size={24}
          onClick={async () => {
            setIsCopied(true);
            try {
              await navigator.clipboard.writeText(keyName);
            } catch (error) {
              console.error("Failed to copy text: ", error);
              setIsCopied(false);
            }
            setTimeout(() => setIsCopied(false), 1000);
          }}
        />
      ) : (
        <IoCheckmark className="bg-gray-200 p-1 cursor-pointer rounded" size={24} />
      )}
      <button
        onClick={handleAddField}
        className="p-1 whitespace-nowrap h-fit hover:bg-gray-200 rounded text-xs flex items-center gap-1"
      >
        {isAdded ? (
          <>
            <IoCheckmark size={12} />
            Added
          </>
        ) : (
          "+ Add Field"
        )}
      </button>
    </>
  );

  return (
    <div className="flex gap-1 items-baseline w-full">
      {isObject ? (
        !isOpen ? (
          <button onClick={() => setIsOpen(true)}>
            <IoIosArrowForward />
          </button>
        ) : (
          <button onClick={() => setIsOpen(false)}>
            <IoIosArrowDown />
          </button>
        )
      ) : null}
      <div className="w-full">
        {/* Wrap the header in a relative container */}
        <div className="flex justify-between items-center w-full group relative">
          <span className="flex gap-1 items-center text-sm bg-white w-fit p-1 rounded border border-gray-300">
            {getIconFromType(getTypeFromValue(value))} {keyName}
            {showValue && isAtomic(value) && <span className="text-xs text-gray-500 ml-1">{valueStr}</span>}
          </span>
          {/* If value is long, position the buttons absolutely above the value */}
          <div
            className={
              isLongValue
                ? "absolute right-0 top-0 z-10 flex gap-3 items-center group-hover:visible invisible group-hover:bg-white group-hover:bg-opacity-80 p-0.5 rounded"
                : "flex gap-3 items-center group-hover:visible invisible"
            }
          >
            {copyAndAddButtons}
          </div>
        </div>
        {isOpen &&
          Object.entries(value).map(([key, val], idx) => (
            <PingViewer
              value={val}
              keyName={key}
              key={idx}
              prev={prev ? prev + "." + keyName : keyName}
              addField={addField}
              showValue={showValue}
            />
          ))}
      </div>
    </div>
  );
};

export default PingViewer;

import { useEffect, useState, useCallback } from "react";
import { Select, Skeleton } from "antd";

import { PayloadStructure } from "@/utils/interfaces";
import { useWorkflow } from "@/contexts/WorkflowContext";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";

type Props = {
  payloadStructure: PayloadStructure[];
  payloads: any;
  setPayloads: any;
  prev: () => void;
  next: () => void;
};

const SalesforceInputModal = ({ payloadStructure, payloads, setPayloads, prev, next }: Props) => {
  const [sfObjects, setSfObjects] = useState<{ loading: boolean; sfObjects: any[] }>({
    loading: false,
    sfObjects: [],
  });
  // const [sfListViews, setSfListViews] = useState<{ loading: boolean; sfListViews: any[] }>({
  //   loading: false,
  //   sfListViews: [],
  // });
  const [sfObjectFields, setSfObjectFields] = useState<{ loading: boolean; sfFields: any[] }>({
    loading: false,
    sfFields: [],
  });

  const { getSalesforceObjects, getSfObjectsFields } = useWorkflow();

  useEffect(() => {
    setSfObjects({ loading: true, sfObjects: [] });
    getSalesforceObjects().then((data: any) => {
      setSfObjects({ loading: false, sfObjects: data.sfObjects });
    });
  }, []);

  // needed to auto-fetch selected object's list views after reopening the action modal
  useEffect(() => {
    if (payloads["objectId"]) {
      // setSfListViews({ loading: true, sfListViews: [] });
      // const selectedObject = payloads["objectId"];
      // getSfListViews(selectedObject).then((listViews) => {
      //   setSfListViews({
      //     loading: false,
      //     sfListViews: listViews,
      //   });
      // });

      setSfObjectFields({ loading: true, sfFields: [] });
      const selectedObject = payloads["objectId"];

      getSfObjectsFields(selectedObject).then((fields) => {
        setSfObjectFields({
          loading: false,
          sfFields: fields,
        });

        setPayloads((prev: any) => ({
          ...prev,
          crmProperties: fields,
          properties: [],
        }));
      });
    }
  }, [setSfObjects]);

  // need to auto-fetch selected listViews columns after reopening the action modal
  // useEffect(() => {
  //   if (payloads["objectId"] && payloads["listViewId"]) {
  //     setSfObjectFields({ loading: true, sfFields: [] });
  //     const selectedObject = payloads["objectId"];
  //     // const selectedListView = payloads[1]?.inputString;
  //     // some fields are repeated in the list view columns, so we need to filter them out
  //     getSfObjectsFields(selectedObject).then((fields) => {
  //       setSfObjectFields({
  //         loading: false,
  //         sfFields: fields,
  //       });
  //     });
  //   }
  // }, [setSfListViews]);

  // fetches the object's list views, on click
  // const handleObjectSelect = useCallback(
  //   async (selectedObjectName: any) => {
  //     setSfListViews({
  //       loading: true,
  //       sfListViews: [],
  //     });
  //     getSfListViews(selectedObjectName).then((listViews: any) => {
  //       setSfListViews({
  //         loading: false,
  //         sfListViews: listViews,
  //       });
  //     });
  //   },
  //   [sfListViews]
  // );

  // fetches the object fields, on click
  const handleListViewSelect = useCallback(
    async (selectedObject: any) => {
      setSfObjectFields({
        loading: true,
        sfFields: [],
      });
      // const selectedObject = payloads["objectId"];
      // some fields are repeated in the list view columns, so we need to filter them out
      getSfObjectsFields(selectedObject).then((fields: any) => {
        setSfObjectFields({
          loading: false,
          sfFields: fields,
        });
        setPayloads((prev: any) => ({
          ...prev,
          crmProperties: fields,
        }));
      });
    },
    [sfObjectFields, payloads]
  );

  const payloadSwitch = (payload: any) => {
    switch (payload.type) {
      case "objectDropdown":
        return (
          <div className="w-full flex flex-col">
            <p>{payload.name}</p>
            <p className="text-xs">{payload.description}</p>
            <Skeleton loading={sfObjects.loading} paragraph={false} active={sfObjects.loading}>
              <Select
                showSearch
                defaultValue={payloads[payload.payloadStructureId] || ""}
                onChange={(value) => {
                  // handleObjectSelect(value);
                  handleListViewSelect(value);
                  setPayloads((prev: any) => ({
                    ...prev,
                    [payload.payloadStructureId]: value,
                  }));
                }}
                options={sfObjects.sfObjects?.map((value: any) => ({
                  label: value.label,
                  value: value.name,
                }))}
              />
            </Skeleton>
          </div>
        );
      // case "listViewDropdown":
      //   return (
      //     <div className="w-full flex flex-col">
      //       <p>{payload.name}</p>
      //       <p className="text-xs">{payload.description}</p>
      //       <Skeleton loading={sfListViews.loading} paragraph={false} active={sfListViews.loading}>
      //         <Select
      //           showSearch
      //           defaultValue={payloads[payload.payloadStructureId] || ""}
      //           onChange={(value) => {
      //             handleListViewSelect();
      //             setPayloads((prev: any) => ({
      //               ...prev,
      //               [payload.payloadStructureId]: value,
      //             }));
      //           }}
      //           options={sfListViews.sfListViews?.map((value: any) => ({
      //             label: value.label,
      //             value: value.id,
      //           }))}
      //         />
      //       </Skeleton>
      //     </div>
      //   );
      //   case "columnsDropdown":
      //     return (
      //       <div className="w-full flex flex-col">
      //         <p>{payload.name}</p>
      //         <p className="text-xs">{payload.description}</p>
      //         <Skeleton loading={sfObjectFields.loading} paragraph={false} active={sfObjectFields.loading}>
      //           <Select
      //             key={payloads[payload.payloadStructureId]}
      //             mode="multiple"
      //             allowClear
      //             defaultValue={payloads[payload.payloadStructureId]}
      //             onChange={(value) => {
      //               setPayloads((prev: any) => ({
      //                 ...prev,
      //                 [payload.payloadStructureId]: value,
      //               }));
      //             }}
      //             options={sfObjectFields.sfFields?.map((field: any) => ({
      //               label: field.label,
      //               value: field.name,
      //             }))}
      //           />
      //         </Skeleton>
      //       </div>
      //     );
      default:
        return;
    }
  };

  return (
    <div className="p-4 border w-[60%] mx-auto mt-10 flex flex-col items-center justify-center gap-8 rounded-lg">
      <div className="w-full flex items-center justify-between gap-1 rounded-md">
        <p className="text-2xl font-medium">Configure your list</p>
        <div className="flex gap-2 items-center">
          <button
            className="text-gray-800 px-1 py-1 rounded disabled:opacity-50 border border-gray-800 hover:bg-gray-100"
            onClick={prev}
          >
            <AiOutlineArrowLeft />
          </button>
          {payloads?.crmProperties ? (
            <button
              className="text-gray-800 px-1 py-1 rounded disabled:opacity-50 border border-gray-800 hover:bg-gray-100"
              onClick={next}
            >
              <AiOutlineArrowRight />
            </button>
          ) : (
            sfObjectFields.loading && <SpinnerStatus />
          )}
        </div>
      </div>
      <div className="w-full flex flex-col gap-4">
        {payloadStructure.map((payload: any, index: number) => (
          <div key={index} className="flex flex-col gap-2 w-full">
            {payloadSwitch(payload)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SalesforceInputModal;

import React, { useState, ReactNode, useEffect } from "react";
import { message } from "antd";
import { createSignal } from "../../apis";
import { SIGNAL_ID_TO_URL } from "@/utils/urls";
import { convertNumberStringToNumber } from "@/Components/UserWorkflows/create/Components/utils";
import ExampleResponse from "../ExampleResponse";
import Filters from "../Filters";
import { notPrimaryFilters } from "../../models";
import { regions } from "@/utils/crustdataRegions";

interface SalesNavigatorProps {
  name: string;
  signalId: string;
  signal: any;
  preview?: boolean;
  data: any;
  generatePreview: (payload: any) => Promise<void>;
  tableNode: ReactNode;
  payloads: any;
  setPayloads: React.Dispatch<any>;
  leadsToImport: number;
  setLeadsToImport: React.Dispatch<React.SetStateAction<number>>;
  onCreateSignal?: (handler: () => Promise<void>) => void;
}

export const tableColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Company Type",
    dataIndex: "company_type",
    key: "company_type",
  },
  {
    title: "Decision Makers Count",
    dataIndex: "decision_makers_count",
    key: "decision_makers_count",
  },
  {
    title: "Employee Count Range",
    dataIndex: "employee_count_range",
    key: "employee_count_range",
  },
  {
    title: "Founded Year",
    dataIndex: "founded_year",
    key: "founded_year",
  },
  {
    title: "Industry",
    dataIndex: "industry",
    key: "industry",
  },
  {
    title: "LinkedIn Company URL",
    dataIndex: "linkedin_company_url",
    key: "linkedin_company_url",
    render: (text: string) => (
      <a href={text} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    ),
  },
  {
    title: "Location",
    dataIndex: "location",
    key: "location",
  },
  {
    title: "Website",
    dataIndex: "website",
    key: "website",
    render: (text: string) => (
      <a href={text} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    ),
  },
];

export const convertDataToTableFormat = (dataArray: any[]) => {
  return dataArray.map((data, index) => ({
    key: index,
    company_type: data.company_type,
    decision_makers_count: data.decision_makers_count,
    description: data.description,
    employee_count: data.employee_count,
    employee_count_range: data.employee_count_range,
    founded_year: data.founded_year,
    industry: data.industry,
    linkedin_company_url: data.linkedin_company_url,
    location: data.location,
    name: data.name,
    website: data.website,
  }));
};

export const getTotalItems = (totalResults: number, pageCount: number) => {
  if (!pageCount) return totalResults;
  const totalResultInPage = pageCount * 25;
  const leastAmount = totalResultInPage <= totalResults ? totalResultInPage : totalResults;

  return leastAmount;
};

export const getCostString = (totalResults: number, pageCount: number) =>
  `Cost: ${getTotalItems(totalResults, pageCount)} credits`;

const SalesNavList: React.FC<SalesNavigatorProps> = ({
  name,
  signalId,
  signal,
  preview,
  generatePreview,
  data,
  tableNode,
  payloads,
  setPayloads,
  leadsToImport,
  setLeadsToImport,
  onCreateSignal,
}) => {
  const [pageCount, setPageCount] = useState<number>(1);
  const [creditCost, setCreditCost] = useState<number>(1);
  const [activeToggle, setActiveToggle] = useState<"salesNavUrl" | "filters">("salesNavUrl");

  useEffect(() => {
    const totalItems = convertNumberStringToNumber(data?.metadata?.total_results ?? "");
    const totalLeads = getTotalItems(totalItems || 0, pageCount);
    const totalCost = totalLeads * signal.costPerLead;
    setLeadsToImport(totalLeads);
    setCreditCost(totalCost);
  }, [data, signal, pageCount]);

  const constructFilter = (filterKey: string) => {
    const filterDef = getPayloadStructure().find((structure: any) => structure.payloadStructureId === filterKey);
    const temp: any = {
      filter_type: filterKey,
    };
    payloads[`${filterKey}_operator`]
      ? (temp.type = payloads[`${filterKey}_operator`])
      : (temp.type = filterDef?.availableOperators[0]);
    temp.value = payloads[filterKey];
    if (filterDef?.has_sub_filter) {
      if (!payloads[`${filterKey}_subfilter`]) {
        return false;
      }
      temp.sub_filter = payloads[`${filterKey}_subfilter`];
    }
    return temp;
  };

  const createSignalHandler = async () => {
    const filters = [];

    for (const payload in payloads) {
      const isNotPrimaryFilter = notPrimaryFilters.filter((str) => payload.includes(str))?.length > 0;
      if (isNotPrimaryFilter) {
        continue;
      }
      const filter = constructFilter(payload);
      if (!filter) {
        message.error("please make sure to include subfilters", 3);
        return;
      }
      filters.push(filter);
    }

    const totalItems = convertNumberStringToNumber(data?.metadata?.total_results ?? "");

    const totalPage = getTotalItems(totalItems || 0, pageCount);

    if (filters.length === 0) {
      message.error("Please add filters");
      return;
    }
    if (totalPage == 0) {
      message.error("please generate the preview first", 3);
      return;
    }

    // setLoading(true);

    const res = await createSignal(
      SIGNAL_ID_TO_URL[signalId as keyof typeof SIGNAL_ID_TO_URL],
      { filters: filters, page: totalPage, type: "filters" },
      name
    );

    return res;
  };

  const createSignalHandlerViaURL = async () => {
    const totalItems = convertNumberStringToNumber(data?.metadata?.total_results ?? "");

    const { salesNavUrl: url } = payloads;
    if (!url) {
      message.error("Please provide a SalesNav URL");
      return;
    }
    const totalPage = getTotalItems(totalItems || 0, pageCount);
    if (totalPage == 0) {
      message.error("please generate the preview first", 3);
      return;
    }

    // setLoading(true);

    const res = await createSignal(
      SIGNAL_ID_TO_URL[signalId as keyof typeof SIGNAL_ID_TO_URL],
      {
        salesNavUrl: url,
        page: totalPage,
        type: "salesNavUrl",
      },
      name
    );

    return res;
  };

  const generatePreviewHandler = async (payloads: any) => {
    let body = {};
    if (activeToggle === "filters") {
      const filters = [];

      for (const payload in payloads) {
        const isNotPrimaryFilter = notPrimaryFilters.filter((str) => payload.includes(str))?.length > 0;
        if (isNotPrimaryFilter) {
          continue;
        }
        const filter = constructFilter(payload);
        if (!filter) {
          message.error("please make sure to include subfilters", 3);
          return;
        }
        filters.push(filter);
      }
      body = {
        filters,
        type: activeToggle,
      };
    }

    if (activeToggle === "salesNavUrl") {
      body = {
        salesNavUrl: payloads.salesNavUrl,
        type: activeToggle,
      };
    }

    await generatePreview(body);
  };

  useEffect(() => {
    if (!onCreateSignal) {
      return;
    }

    if (activeToggle === "salesNavUrl") {
      onCreateSignal(createSignalHandlerViaURL);
    } else {
      onCreateSignal(createSignalHandler);
    }
  }, [activeToggle, payloads, pageCount, data]);

  const getPayloadStructure = () => {
    if (activeToggle === "salesNavUrl") {
      return signal?.payloadStructure?.filter((structure: any) => {
        return structure.payloadStructureId == "salesNavUrl";
      });
    }
    const structArr = signal?.payloadStructure?.filter((structure: any) => {
      return structure.payloadStructureId !== "salesNavUrl";
    });

    return structArr?.map((struct: any) => {
      if (struct.payloadStructureId === "REGION") {
        struct.values = regions;
        struct.type = "multiDropdown";
      }
      return struct;
    });
  };

  return (
    <div>
      <div className="grid grid-cols-5">
        {/* controls section */}
        <div className="col-span-2 max-h-[calc(100vh-50px)] flex flex-col overflow-scroll">
          {/* filters */}
          <div className="h-[calc(100vh-50px)]">
            <div className="px-8 py-4 border-r border-[#D3D3D3]">
              <div className="text-xs text-[#5D5D5D] bg-[#EFEFEF] py-1 px-2 mb-2 rounded-sm w-fit">GET USING</div>
              <div className="flex gap-2 p-[0.35rem] border border-gray-300 rounded-[5px] w-fit">
                <button
                  className={`px-4 py-1 rounded-[4px] transition-all ${
                    activeToggle === "salesNavUrl" ? "bg-[#EFEFEF]" : "bg-white"
                  }`}
                  onClick={() => {
                    setPayloads({});
                    setActiveToggle("salesNavUrl");
                  }}
                >
                  URL
                </button>
                <button
                  className={`px-4 py-1 rounded-[4px] transition-all ${
                    activeToggle === "filters" ? "bg-[#EFEFEF]" : "bg-white"
                  }`}
                  onClick={() => {
                    setPayloads({});
                    setActiveToggle("filters");
                  }}
                >
                  Filters
                </button>
              </div>
              <div className="text-sm text-[#5D5D5D] mt-2">
                Lets you import companies using a range of filters from Sales Navigator.
              </div>
            </div>

            <Filters
              payloadStructure={getPayloadStructure()}
              signalId={signal.id}
              name={name}
              preview={signal.isPreviewAvailable}
              generatePreview={generatePreviewHandler}
              payloads={payloads}
              setPayloads={setPayloads}
              groupFilters={activeToggle === "filters" ? signal?.filterGroups : null}
              createSignalParentHandler={activeToggle === "filters" ? createSignalHandler : createSignalHandlerViaURL}
              pageCount={pageCount}
              handlePageCount={(value) => {
                setPageCount(value);
              }}
              creditCost={creditCost}
              showFilterSearch={activeToggle === "filters"}
              isList={true}
              itemsCount={leadsToImport}
              hideCreateSignalButton={true}
              pageCountToolTipText="Each page contains 25 leads. You can enter the number of pages you want to import."
            />
          </div>
        </div>
        {/* table section */}
        <div className="col-span-3 min-h-[calc(100vh-50px)]">
          {leadsToImport && preview ? (
            <div className="h-full px-8 flex flex-col justify-center">{tableNode}</div>
          ) : (
            <ExampleResponse
              exampleResponse={signal.exampleResponse}
              responseStructure={signal.responseStructure}
              signal={signal}
              payloads={payloads}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SalesNavList;

import LoaderButton from "@/Components/LoaderButton";
import { useWorkflow } from "@/contexts/WorkflowContext";
import { AUTOFILL_KEYWORDS, setStateType, TEMPLATE_TIPTAP_VARIABLE_VALUE } from "@/utils/constants";
import { ConfigProvider, Drawer } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Frame from "@/assets/SVGs/Frame.svg";
import { ResponseStructure } from "@/utils/interfaces";
import AddActionView from "./AddActionView";

export const getInputStringFromType = (field: string) => {
  switch (field) {
    case "string":
      return "";
    case "number":
      return 0;
    case "boolean":
    case "radio":
      return false;
    case "array":
      return [""];
    case "jsonArray":
    case "webhookArray":
    case "jsonArrayWithDropDown":
    case "dynamicJsonArray":
    case "jsonArrayStatic":
      return [];
    case "outputParser":
      return "fields";
    case "formula":
      return {};
    case "split_paths":
      return [
        {
          conditions: [],
          name: "Path 1",
        },
        {
          conditions: [],
          name: "Path 2",
        },
      ];
    case "if_else":
      return [
        {
          conditions: [],
          name: "if",
        },
        {
          conditions: [],
          name: "else",
        },
      ];
    case "path_filter":
      return [
        {
          conditions: [],
          name: "Filter",
        },
      ];
    case "hubspot_search_filters":
      return [
        {
          conditions: [],
          name: "hubspot_search_filters",
        },
      ];
    default:
      return "";
  }
};

type Props = {
  modal: boolean;
  setModal: setStateType<boolean>;
  availableActions: any[];
  source: string;
  variables: any[];
  target: string | null;
};

const AddActionModal = ({ modal, setModal, availableActions, source, target, variables }: Props) => {
  const [loading, setLoading] = useState(false);
  const [selectedAction, setSelectedAction] = useState<any>("");
  const { actions, setActions, saveWorkflowActions } = useWorkflow();

  const { id } = useParams();

  const handleAdd = async () => {
    setLoading(true);
    const action = availableActions.find((act) => act.id === selectedAction);
    const sourceIdx = actions.findIndex((act) => act.id === source);
    const targetIdx = actions.findIndex((act) => act.id === target);

    if (!action) return;
    const actId = uuidv4().toString();
    const vars: any[] = [];
    variables.forEach((action) => {
      action?.variables?.forEach((variable: ResponseStructure) => {
        const option = {
          id: variable.responseId,
          logo: action.logo,
          type: variable.type || "string",
          name: variable.name,
          actionName: action.name,
        };
        vars.push(option);
      });
    });

    const payloadConfig = action.payloadStructure.map((field: any) => {
      if (field.needVars && field.required) {
        const matchingVars = vars.filter((v) => v.type === field.type);
        let exactMatch = matchingVars.find((v) => v.name.toLowerCase() === field.name.toLowerCase());
        const keywords = AUTOFILL_KEYWORDS[field.name as keyof typeof AUTOFILL_KEYWORDS] || [];
        // compare each matching variable with the keywords
        for (let i = 0; i < matchingVars.length && !exactMatch; i++) {
          const variable = matchingVars[i];
          if (keywords.some((keyword) => variable.name.toLowerCase() === keyword.toLowerCase())) {
            exactMatch = variable;
            break;
          }
        }
        return {
          payloadStructureId: field.payloadStructureId,
          inputString: exactMatch ? `{{${exactMatch?.id}}}` : "",
          tiptapJson: exactMatch ? TEMPLATE_TIPTAP_VARIABLE_VALUE(exactMatch) : "",
        };
      } else {
        const config: any = {
          payloadStructureId: field.payloadStructureId,
          inputString: getInputStringFromType(field.type),
        };
        if (field.type === "stepDownSearch") {
          config["stepDownSearchConfig"] = (action.stepDownAPIs || field.stepDownAPIs)
            .filter((api: any) => !api?.floqerApiKeyNotAllowed && !api?.disabled)
            .map((api: any, idx: number) => ({
              apiId: api.apiId,
              order: api.order || idx + 1,
            }));
        }
        return config;
      }
    });

    const newAction: any = {
      id: actId,
      pk_id: actId,
      actionDetails: action,
      delayAfterActionPerProspectPerSecond: 0,
      actionName: action.id,
      nextAction: [
        {
          actionId: target,
          conditions: [],
          name: "Conditional Path",
        },
      ],
      prevAction: [
        {
          actionId: source,
          conditions: actions?.[sourceIdx]?.nextAction?.find((next: any) => next.actionId === target)?.conditions || [],
          name: "Conditional Path",
        },
      ],
      payloadConfiguration: payloadConfig,
    };
    if (action.outputStructureType === "predefined") {
      newAction["responseConfiguration"] = action.responseStructure.map((field: any) => {
        const x: any = {
          responseStructureId: field.responseStructureId,
          responseId: uuidv4().toString(),
        };
        if (field.type === "sectionList")
          x["sectionVariablesConfiguration"] = field.sectionVariablesStructure.map((section: any) => ({
            responseId: uuidv4().toString(),
            responseStructureId: section.responseStructureId,
          }));
        return x;
      });
    }

    if (sourceIdx !== -1)
      actions[sourceIdx].nextAction = actions[sourceIdx].nextAction
        .map((next: any) =>
          (next && typeof next === "object" ? next.actionId : next) === target
            ? {
                actionId: actId,
                conditions: next?.conditions || [],
                name: next?.name || "",
              }
            : next
        )
        // Remove duplicates
        .filter((obj1: any, i: number, arr: any[]) => arr.findIndex((obj2) => obj2.actionId === obj1.actionId) === i);

    if (targetIdx !== -1)
      actions[targetIdx].prevAction = actions[targetIdx].prevAction
        .map((prev: any) =>
          (prev && typeof prev === "object" ? prev.actionId : prev) === source
            ? {
                actionId: actId,
                conditions: [],
                name: "",
              }
            : prev
        )
        // Remove duplicates
        .filter((obj1: any, i: number, arr: any[]) => arr.findIndex((obj2) => obj2.actionId === obj1.actionId) === i);

    setActions([...actions, newAction]);

    await saveWorkflowActions(id || "");

    setLoading(false);
    setModal(false);
  };

  const handleActionSelection = (actionId: string) => {
    setSelectedAction(actionId);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={modal}
        onClose={() => setModal(false)}
        width="40%"
        className="!font-Lausanne !p-0"
        footer={
          <div className="w-full flex flex-col gap-4 bg-white">
            <LoaderButton
              disabled={loading || selectedAction === ""}
              onClickCallback={handleAdd}
              loaderText="Adding..."
              text="Add"
              btnClasses="!p-2"
              error=""
            />
          </div>
        }
        title={
          <div className="flex items-center gap-2 ml-2.5">
            <img src={Frame} alt="frame" />
            <p> Add Action </p>
          </div>
        }
      >
        <AddActionView
          availableActions={availableActions}
          source={source}
          target={target}
          handleActionSelection={handleActionSelection}
          actionsCategory={[]}
        />
      </Drawer>
    </ConfigProvider>
  );
};

export default AddActionModal;

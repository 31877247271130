import KeyValue from "./KeyValue";
import { useEffect, useState } from "react";
import { FaCoins } from "react-icons/fa";
import { calculateTotalCreditsConsumed } from "../utils";

type Props = {
  open: boolean;
  data: any;
  credits: number | { [key: string]: number };
};

/**
 * @todo: Design LinkedinCompanyProfileViewer to display data like LinkedinPublicProfileViewer
 */

/**
 * @Test_Cases for calculateTotalCreditsConsumed() function
 * 1. ✅old response with empty data
 * 2. ✅old response with data but no creditsConsumed field
 * 3. ✅new response with empty data
 * 4. ✅new response with data and with creditsConsumed field
 */

const LinkedinCompanyProfileViewer = ({ open, data, credits }: Props) => {
  const response = data?.largeActionResponse;
  const stepDownResponse = data?.stepDownResponse;
  const [newData, setNewData] = useState<any>({});
  useEffect(() => {
    if (!open) setNewData({});
    else {
      const filteredData = Object.fromEntries(Object.entries(response).filter(([key]) => key !== "undefined"));
      setNewData(filteredData);
    }
  }, [open, data]);

  return (
    <>
      <div className="flex flex-col gap-2">
        {Object.entries(newData).length > 0 ? (
          Object.entries(newData).map(([key, value]: [string, any]) => (
            <KeyValue key={key} keyName={key} value={value} open={open} />
          ))
        ) : (
          <KeyValue keyName="status" value="Completed" open={open} />
        )}
      </div>
      <div className="mt-4 flex items-center">
        <h1 className="font-semibold text-md">Total cost:</h1>
        <div className="flex items-center ml-2 bg-purple-100 rounded p-1">
          <span className="font-bold text-md">{calculateTotalCreditsConsumed(stepDownResponse, credits)}</span>
          <FaCoins className="ml-1 text-purple-500 w-5" />
        </div>
      </div>
    </>
  );
};

export default LinkedinCompanyProfileViewer;

import { CELL_STATUS_TYPE } from "./constants";

export type transactionStatus = "success" | "reimbursed" | "initiated" | "completed";
export const transactionStatusDropdownList = [
  { label: "Completed", value: "completed" },
  { label: "Reimbursed", value: "reimbursed" },
  { label: "Initiated", value: "initiated" },
];

export type IcacheConfig = {
  expiryDate: number | null;
};

export type TransactionLog = {
  API_PRICING_ID: number;
  actionName: string;
  created_at: string;
  email: string;
  error_message: string;
  org_id: string;
  status: string;
  totalCreditsNeeded: number;
  total_credits_consumed: number;
  transactionId: number;
  workflowId: string;
  workflow_action_data_part_id: string;
};

export type PayloadConfiguration = {
  payloadStructureId: string;
  inputString: any;
  type?: string;
  name?: string;
  description?: string;
  tiptapJson?: any;
  salesforceObjectId?: string;
  selectedList?: any;
  stepDownSearchConfig?: {
    apiId: string;
    order: number;
    connection?: boolean;
  }[];
};

export type Condition = {
  variable: string;
  variableType: string;
  condition: string;
  values: any[];
  operator?: string;
  caseSensitive?: boolean;
};

export type NextActionConfiguration = {
  actionId: string;
  conditions: Condition[];
  name: string;
};

export type ResponseConfiguration = {
  name: string;
  description: string;
  type?: string;
  responseId?: string;
  responseStructureId?: string;
  extraVar?: boolean;
  display_column?: boolean;
};

export type PayloadStructure = {
  payloadStructureId: string;
  name: string;
  type: string;
  description: string;
  required: boolean;
  needVars: boolean;
  stepDownAPIs?: any[];
  valuesId?: string;
  values?: any[];
  supports_ai_prompt?: boolean;
};

export type ResponseStructure = {
  name: string;
  description: string;
  responseId: string;
  display_column?: boolean;
  responseStructureId?: string;
  type?: string;
  sectionVariablesStructure?: ResponseStructure[];
  sectionVariablesConfiguration?: ResponseConfiguration[];
  responseStructure?: any;
  fields?: any;
  placeholder?: any;
};

export type Action = {
  id: string;
  name: string;
  actionName: string;
  logo: string;
  type: string;
  reviewAllowed?: boolean;
  reviewNeeded?: boolean;
  payloadStructure: PayloadStructure[];
  credits: number | Record<string, number>;
  continueOnFail?: boolean;
  responseStructure: ResponseStructure[];
  ping?: any;
  runCondition?: any;
  connectionId?: string;
  stepDownAPIs?: any[];
  billingUnit?: string;
  outputStructureType: string;
  subfloq_id?: string;
  responseSize: string;
};

type COUNTS = {
  pending: number;
  completed: number;
  failed: number;
  payloadFilled: number;
  readyToBeReviewed: number;
  checkingNextSource: number;
  conditionNotMet: number;
  error: number;
  outOfCredits: number;
};

export type ActionCounts = {
  [key: string]: COUNTS;
};

// export type Action
export type ActionResponses = {
  actionId: string;
  type: string;
  logo: string;
  name: string;
  displayName?: string;
  response: ResponseStructure[];
  payloadStructure: PayloadStructure[];
  responseConfiguration: ResponseConfiguration[];
  responseStructure?: any;
  reviewNeeded?: boolean;
  reviewAllowed?: boolean;
  responseSize: string;
  outputStructureType: string;
  credits: number | Record<string, number>;
  fsWfActionId?: string;
  "ui-style": string;
  actionDetails: any;
  actionName: string;
  id?:string
  subfloq_id?: string;
};

export type ActionCategory = {
  actions: string[];
  description: string;
  id: string;
  logo: string;
  name: string;
  parent_name: string;
  parent_logo: string;
  order: number;
};

export type ActionCategoryGroups = {
  [key: string]: {
    ids?: string[];
    logo?: string;
    order?: number;
    name?: string;
  };
};

export interface CategorizedAction extends Omit<ActionCategory, "actions"> {
  actions: Action[];
}

export type Payload = {
  inputString: string;
  payloadStructureId: string;
};

export type Response = {
  responseId: string;
  value: string;
  responseStructureId?: string;
};

export type DataValue = {
  payload?: Payload[];
  response?: Response[];
  status: string;
};

export type Data = {
  [key: string]: DataValue;
};

export type CellDataType = {
  // GeneratedEmail: string;
  // GeneratedSubject?: string;
  index: number;
  status: CELL_STATUS_TYPE;
  reviewNeeded: boolean;
  reviewed: boolean;
  workflowId: string;
  actionId: string;
  dataId: string;
  [key: string]: any;
};

export type CellDataWrapperType = CellDataType & {
  colData: CellDataType[];
  key: string;
};

export enum WKF_LIST_SORT_PARAM {
  date = "Date",
  name = "Name",
}

export enum CHAT_SRC {
  USER = "User",
  AI = "AI",
}

export interface Workflow {
  createdAt: string;
  draftWorkflowConfig: {
    firstAction: string;
    lastSavedAt: string;
    name: string;
  };
  id: string;
  publishedWorkflowConfig: {
    firstAction: string;
    lastPublishedAt: string;
    name: string;
  };
}

export interface Transaction {
  API_PRICING_ID: number;
  actionName: string;
  apiName: string;
  apiPricings: {
    id: number;
    apiName: string;
  };
  created_at: string;
  email: string;
  error_message: string;
  org_id: string;
  status: string;
  totalCreditsNeeded: number;
  total_credits_consumed: number;
  transactionId: number;
  workflowId: string;
}

export interface OrgInfo {
  name: string;
  description: string;
  payment_pending: boolean;
  credits: number;
  members: { name: string; role: string }[];
  id: string;
}

export interface ApiPrice {
  apiName: string;
  created_at: string;
  credits: number;
  id: number;
}

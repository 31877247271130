import React, { useState, useRef, useEffect } from "react";

type EditableNameProps = {
  name: string;
  updateName: (newName: string) => Promise<void> | void;
};

const EditableName = ({ name, updateName }: EditableNameProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(name);
  const [inputWidth, setInputWidth] = useState<number>(0);
  const spanRef = useRef<HTMLSpanElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const hiddenSpanRef = useRef<HTMLSpanElement>(null);

  // Handle click on the name to start editing
  const handleNameClick = () => {
    setIsEditing(true);
  };

  // Handle input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedName(e.target.value);
    // Recalculate the width of the input based on the new value
    if (hiddenSpanRef.current) {
      const width = Math.min(hiddenSpanRef.current.offsetWidth, 400);
      setInputWidth(width);
    }
  };

  // Handle saving the name on Enter or blur
  const saveName = async () => {
    if (editedName.trim() !== name) {
      await updateName(editedName.trim());
    }
    setIsEditing(false);
  };

  // Handle pressing Enter to save the name
  const handleInputKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      await saveName();
    }
  };

  // Calculate the initial width of the input based on the name
  useEffect(() => {
    if (spanRef.current) {
      const width = Math.min(spanRef.current.offsetWidth, 400);
      setInputWidth(width);
    }
  }, [name]);

  // Auto-focus and move cursor to the end when editing starts
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(editedName.length, editedName.length);
    }
  }, [isEditing]);

  return (
    <div className="editable-name-container inline-block max-w-[400px] truncate">
      {isEditing ? (
        <>
          <input
            ref={inputRef}
            type="text"
            value={editedName}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            onBlur={saveName}
            className="rounded-[2px] bg-transparent p-0 px-1 h-[24px]"
            style={{ width: inputWidth ? `${inputWidth}px` : "auto", maxWidth: "400px" }}
          />
          {/* Hidden span to measure the width of the text */}
          <span ref={hiddenSpanRef} className="absolute p-1 invisible whitespace-pre font-inherit">
            {editedName}
          </span>
        </>
      ) : (
        <span
          ref={spanRef}
          onClick={handleNameClick}
          className="cursor-pointer hover:bg-gray-100 px-1 py-1 rounded transition-colors duration-200 whitespace-nowrap"
        >
          {name}
        </span>
      )}
    </div>
  );
};

export default EditableName;

import { useEffect, useState } from "react";
import "./index.css";
import backArrow from "/assets/back-arrow.svg";
import { Link, useParams } from "react-router-dom";
import { useWorkflow } from "../../contexts/WorkflowContext";
import Loader from "../Loader";
import { LOADER_TYPES, TabEnum, setStateType } from "../../utils/constants";
import { ResponseConfiguration } from "../../utils/interfaces";
import Run from "./Tabs/Run/Run";
import TableTab from "./Tabs/Table";
// import TableChat from "./Tabs/TableChat";
import ReactFlowBuild from "@/Components/Workflow/Tabs/Build/ReactFlowBuild";
import TableAG from "./Tabs/Table/TableAG";
import ShareWorkflowButton from "./Actions/ShareWorkflow";
import { useUser } from "@/contexts/UserContext";
import { getEmailFromToken } from "@/utils/functions";
import floqIcon from "/assets/play-circle 1.svg";
import tableIcon from "/assets/table (1) 1.svg";
import webhookIcon from "/assets/webhook-icon.svg";
import { CollapsibleIcon } from "../NavbarNew/CollapsibleIcon";
import WebhookModal from "./ActionModals/WebhookModal";

const tabMapper: { [key: string]: string } = {
  build: "Floq",
  table: "Data",
};

const tabIcons = {
  build: <img src={floqIcon} className="translate-y-[1px]" />,
  table: <img src={tableIcon} />,
};

interface TabButtonProps {
  toSelect: TabEnum;
  selected: TabEnum;
  setSelected: setStateType<TabEnum>;
  beta?: boolean;
}

const TabButton: React.FC<TabButtonProps> = ({ toSelect, setSelected, beta }) => {
  return (
    <button className={` h-full flex justify-center items-center gap-1`} onClick={() => setSelected(toSelect)}>
      <span
        className={`font-medium px-4 py-[0.35rem]  rounded-[6px] flex items-center  justify-center gap-1 transition translate-x-[-2px]`}
      >
        {/* @ts-ignore */}
        <div className="translate-y-[-1px] ">{tabIcons[toSelect]}</div>
        <span className="z-20">{tabMapper[toSelect] || ""}</span>
      </span>
      {beta && (
        <span className="bg-gradient-to-r from-[#D4D0FF] from-0 to-[#FAF2FF] to-100% font-semibold tracking-widest px-1 py-0.5 rounded-[6px] text-gray-600">
          BETA
        </span>
      )}
    </button>
  );
};

const Workflow = () => {
  const [selected, setSelected] = useState<TabEnum>(TabEnum.build);
  const [loading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [workflow, setWorkflow] = useState<any>({});
  const [input, setInput] = useState<ResponseConfiguration[]>([]);
  const [v2, setV2] = useState(false);
  const [orgDetails, setOrgDetails] = useState({ org_name: "", org_members: [] });
  const [accessChanged, setAccessChanged] = useState(false);
  const [webhookModal, setWebhookModal] = useState(false);
  const [isWebhookInput, setIsWebhookInput] = useState(false);
  const [headerShift, setHeaderShift] = useState(0);

  const { id } = useParams();
  const { fetchOrg } = useUser();
  const { getAllWorkflowActionsDetails, actions, setIsSubfloq } = useWorkflow();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const action = searchParams.get("action");
    const v2 = searchParams.get("v2");

    setV2(v2 === "true");
    if (action && action !== "") setSelected(action as TabEnum);
    else {
      window.history.pushState({}, "", `${window.location.pathname}?action=build&v2=${v2}`);
      setSelected(TabEnum.build);
    }
    setIsSubfloq(false);
  }, []);

  useEffect(() => {
    setIsWebhookInput(actions[0]?.actionName?.includes("UPLOAD_CSV_OR_WEBHOOK") || false);
  }, [actions]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const v2 = searchParams.get("v2");
    setV2(v2 === "true");
    setHeaderShift(0);
    if (selected !== ("" as TabEnum))
      window.history.pushState({}, "", `${window.location.pathname}?action=${selected}&v2=${v2}`);
  }, [selected]);

  useEffect(() => {
    if (!id) return;

    if (firstLoad) setLoading(true);

    fetchOrg().then((res: any) => {
      setOrgDetails({ org_name: res.name, org_members: res.members });
    });

    getAllWorkflowActionsDetails(id)
      .then((data) => {
        setWorkflow(data.workflowDetails);
        const inp = data.actions.filter((act: any) => act.actionDetails.type === "input");
        if (inp.length > 0) setInput(inp[0].responseConfiguration || []);
      })
      .catch((err) => console.error("Error fetching workflow details", err))
      .finally(() => {
        if (firstLoad) {
          setLoading(false);
          setFirstLoad(false); // Disable loading for future updates
        }
      });
  }, [accessChanged]);

  const switchComponent = () => {
    switch (selected) {
      case TabEnum.build:
        return (
          <ReactFlowBuild
            firstActionId={workflow.draftWorkflowConfig?.firstAction}
            workflow={workflow}
            currentShift={headerShift}
            shiftHeader={(s) => setHeaderShift(s)}
          />
        );
      // @ts-ignore
      case TabEnum.run:
        return <Run actions={actions} template={input} firstActionId={workflow.publishedWorkflowConfig.firstAction} />;
      case TabEnum.table: {
        if (v2) return <TableAG setTab={setSelected} />;
        return <TableTab setTab={setSelected} />;
      }
      // case TabEnum.chat:
      //   return <TableChat userWorkflowId={id || ""} />;
      default:
        return (
          <ReactFlowBuild
            firstActionId={workflow.draftWorkflowConfig.firstAction}
            workflow={workflow}
            currentShift={headerShift}
            shiftHeader={(s) => setHeaderShift(s)}
          />
        );
    }
  };

  if (loading)
    return (
      <div className="w-full h-full">
        <Loader loaderType={LOADER_TYPES.fetching} payload="Workflow" />
      </div>
    );

  return (
    // <DotsBg>
    <div className="w-full flex h-full bg-transparent ">
      <WebhookModal
        modal={webhookModal}
        close={() => setWebhookModal(false)}
        action={actions[0]}
        responseConfiguration={actions[0]?.responseConfiguration}
        payloadConfiguration={actions[0].payloadConfiguration}
      />
      <div className="flex flex-col w-full items-center z-10">
        <div className="grid grid-cols-3 sticky top-0 items-center bg-transparent w-full z-[100] h-0 p-0 ">
          <div className="flex items-center gap-4 ml-7 mt-5">
            <CollapsibleIcon isHeader variant="rounded-border" />

            <div className="flex items-center border-[#C2C2C2] rounded-[8px] w-fit px-[0.35rem] py-[0.35rem] bg-gray-50  shadow-[0px_0px_0px_2px_rgba(224,224,224,0.46)]">
              <Link to="/workflows" className="">
                <div className="border border-[#C2C2C2] rounded-[6px] py-1 px-[0.35rem] cursor-pointer bg-white">
                  <img src={backArrow} className="min-w-fit" />
                </div>
              </Link>

              <div className="font-medium py-[0.35rem] px-4 max-w-[24ch] overflow-hidden whitespace-nowrap text-ellipsis">
                {workflow?.name || workflow?.publishedWorkflowConfig?.name}
              </div>
            </div>
          </div>

          <div
            style={{
              transform: `translateX(${selected === TabEnum.build ? "-" + headerShift.toString() + "%" : "0"})`,
            }}
            className="flex items-center gap-2 justify-center border-[#C2C2C2] rounded-[8px] w-fit py-1 px-[0.35rem] mx-auto bg-gray-50 mt-5 shadow-[0px_0px_0px_2px_rgba(224,224,224,0.46)]"
          >
            <div className="relative flex">
              {/* Animated background */}
              <div
                className="absolute h-full transition-transform duration-200 ease-out bg-white rounded-[6px] border border-slate-300"
                style={{
                  width: "85px", // Adjust based on tab width
                  transform: `translateX(${Object.values(TabEnum).indexOf(selected) * 90}px)`, // Adjust multiplier based on tab width
                }}
              />

              {/* Existing tab buttons */}
              {Object.values(TabEnum).map((tab, idx) => {
                return (
                  <TabButton
                    selected={selected}
                    setSelected={setSelected}
                    toSelect={tab}
                    key={idx}
                    // beta={tab === TabEnum.chat}
                  />
                );
              })}
            </div>
          </div>

          <div className="w-fit ml-auto mr-7">
            {v2 && (
              <div className="flex gap-2 items-center border-[#C2C2C2] rounded-[8px] w-fit px-[0.35rem] py-[0.35rem] bg-gray-50 mt-5 ml-7 shadow-[0px_0px_0px_2px_rgba(224,224,224,0.46)]">
                {isWebhookInput && (
                  <div
                    className="border border-[#C2C2C2] rounded-[6px] py-[0.35rem] px-4 cursor-pointer min-w-fit bg-white "
                    onClick={() => {
                      setWebhookModal(true);
                    }}
                  >
                    <img src={webhookIcon} />
                  </div>
                )}
                <div className="border border-[#C2C2C2] rounded-[6px] py-[0.35rem] px-4 cursor-pointer min-w-fit bg-white ">
                  <ShareWorkflowButton
                    emails={orgDetails?.org_members || []}
                    sharedUsers={workflow?.shared_with || []}
                    userEmail={getEmailFromToken()}
                    workflow={workflow}
                    setAccessChanged={() => setAccessChanged((prev) => !prev)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {switchComponent()}
      </div>
    </div>
    // </DotsBg>
  );
};

export default Workflow;

import { setStateType } from "../../../utils/constants";
import { useEffect, useState, useCallback } from "react";
import { useWorkflow } from "../../../contexts/WorkflowContext";
import TiptapField from "../Tiptap/TiptapField";
import { Action, PayloadConfiguration } from "../../../utils/interfaces";
import { Alert, ConfigProvider, Drawer, message, Select, Skeleton, Switch } from "antd";
import { FaPlus, FaRegEdit } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { RiExpandUpDownLine } from "react-icons/ri";
import { MdChevronLeft, MdChevronRight, MdDeleteOutline } from "react-icons/md";
import { useConnections } from "@/contexts/ConnectionContext";
import RunCondition from "./ModalComponents/RunCondition";
import { useRunOnFloq } from "@/contexts/RunOnFloqContext";

type Props = {
  action: Action;
  modal: boolean;
  setModal: setStateType<boolean>;
  payloadConfiguration: PayloadConfiguration[];
  variables: any[];
};

const GoogleSheetModal = ({ action, modal, setModal, payloadConfiguration, variables }: Props) => {
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);

  // sfObjects
  const [spreadsheets, setSpreadsheets] = useState<{ loading: boolean; spreadsheets: any[] }>({
    loading: false,
    spreadsheets: [],
  });
  // sfListViews
  const [inSheets, setInSheets] = useState<{ loading: boolean; inSheets: any[] }>({
    loading: false,
    inSheets: [],
  });

  // sfObjectFields
  const [sheetColumns, setSheetColumns] = useState<{ loading: boolean; sheetColumns: any[] }>({
    loading: false,
    sheetColumns: [],
  });

  const [updateField, setUpdateField] = useState(0);
  const [payloads, setPayloads] = useState<PayloadConfiguration[]>(payloadConfiguration);
  const [runCondition, setRunCondition] = useState<any>(action.runCondition);
  const { updatePayloadConfig, saveWorkflowActions, updateActionName, setPublishWarning, getSpreadsheets } =
    useWorkflow();
  const { getConnectionFromType } = useConnections();
  const [isConnectionExists, setIsConnectionExists] = useState(false);
  const [changeName, setChangeName] = useState(false);
  const { collapseConfig, setCollapseConfig } = useRunOnFloq();
  const [drawerWidth, setDrawerWidth] = useState(collapseConfig ? "3rem" : "30%");
  const { id } = useParams();

  useEffect(() => {
    setWidthPercent();
    window.addEventListener("resize", setWidthPercent);
    return () => window.removeEventListener("resize", setWidthPercent);
  }, [collapseConfig]);

  const setWidthPercent = useCallback(() => {
    try {
      if (collapseConfig) setDrawerWidth("3rem");
      // Adjust width for different screen sizes
      else if (window.matchMedia("(min-width: 1024px) and (min-height: 1366px)").matches)
        // iPad Pro and similar devices
        setDrawerWidth("50%");
      else if (window.matchMedia("(min-width: 1024px)").matches) setDrawerWidth("30%");
      else if (window.matchMedia("(min-width: 640px)").matches) setDrawerWidth("50%");
      else setDrawerWidth("100%");
    } catch (err) {
      console.error("Error calculating width percent based on media size");
    }
  }, [collapseConfig]);

  useEffect(() => {
    setSpreadsheets({ loading: true, spreadsheets: [] });
    getSpreadsheets("google_spreadsheets").then((data: any) => {
      setSpreadsheets({ loading: false, spreadsheets: data });
    });
  }, []);

  // needed to auto-fetch selected object's list views after reopening the action modal
  useEffect(() => {
    if (payloads[0]?.inputString) {
      setInSheets({ loading: true, inSheets: [] });
      const selectedObject = payloads[0]?.inputString;
      getSpreadsheets("google_spreadsheet_sheets", selectedObject).then((listViews) => {
        setInSheets({
          loading: false,
          inSheets: listViews,
        });
      });
    }
  }, [spreadsheets]);

  // need to auto-fetch selected listViews columns after reopening the action modal
  useEffect(() => {
    if (payloads[0]?.inputString && payloads[1]?.inputString) {
      setSheetColumns({ loading: true, sheetColumns: [] });
      const sheetId = payloads[0]?.inputString;
      const sheetName = payloads[1]?.inputString;
      // some fields are repeated in the list view columns, so we need to filter them out
      getSpreadsheets("google_sheet_columns", sheetId, sheetName).then((fields) => {
        setSheetColumns({
          loading: false,
          sheetColumns: fields,
        });
      });
    }
  }, [setInSheets]);

  useEffect(() => {
    setPayloads(payloadConfiguration);
    setUpdateField((prev) => prev + 1);
  }, [payloadConfiguration]);

  useEffect(() => {
    if (payloads !== payloadConfiguration || runCondition !== action.runCondition) setChanged(true);
    else setChanged(false);
  }, [payloads, runCondition]);

  useEffect(() => {
    if (!action?.connectionId) return;
    getConnectionFromType(action?.connectionId).then((connection: any) => {
      setIsConnectionExists(connection?.userConnection ? true : false);
    });
  }, [action?.connectionId]);

  const saveOutputStructure = async () => {
    setLoading(true);
    const updated = updatePayloadConfig(payloads, action.id, false, action.continueOnFail, runCondition);
    setPayloads(updated);
    await saveWorkflowActions(id || "");
    setLoading(false);
    setPublishWarning(true);
  };

  // fetches the object's list views, on click
  const handleObjectSelect = useCallback(
    async (selectedObjectName: any) => {
      setInSheets({
        loading: true,
        inSheets: [],
      });
      getSpreadsheets("google_spreadsheet_sheets", selectedObjectName).then((listViews: any) => {
        setInSheets({
          loading: false,
          inSheets: listViews,
        });
      });
    },
    [inSheets]
  );
  // fetches the columns fields, on click
  const handleListViewSelect = useCallback(async () => {
    setSheetColumns({
      loading: true,
      sheetColumns: [],
    });
    const sheetId = payloads[0]?.inputString;
    const sheetName = payloads[1]?.inputString;
    // some fields are repeated in the list view columns, so we need to filter them out
    getSpreadsheets("google_sheet_columns", sheetId, sheetName).then((fields: any) => {
      if (fields.length === 0) {
        message.error("No columns found in the selected sheet");
      }
      setSheetColumns({
        loading: false,
        sheetColumns: fields,
      });
    });
  }, [sheetColumns]);

  const fieldSwitchCase = (field: any) => {
    switch (field.type) {
      case "spreadsheetsDropdown":
        return (
          <Skeleton loading={spreadsheets.loading} paragraph={false} active={spreadsheets.loading}>
            <Select
              showSearch
              optionFilterProp="label"
              defaultValue={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""
              }
              onChange={(value) => {
                handleObjectSelect(value);
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                temp[index].inputString = value;
                setPayloads(temp);
              }}
              options={spreadsheets.spreadsheets?.map((value: any) => ({
                label: value.name,
                value: value.id,
              }))}
            />
          </Skeleton>
        );
      case "inSheetDropdown":
        return (
          <Skeleton loading={inSheets.loading} paragraph={false} active={inSheets.loading}>
            <Select
              showSearch
              optionFilterProp="label"
              defaultValue={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""
              }
              onChange={(value) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                temp[index].inputString = value;
                setPayloads(temp);
                handleListViewSelect();
              }}
              options={inSheets.inSheets?.map((value: any) => ({
                label: value.name,
                value: value.name,
              }))}
            />
          </Skeleton>
        );
      case "dropdown":
        return (
          <Select
            suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
            value={
              payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString ||
              field.values.find((value: any) => value?.default)?.id ||
              field.values.find((value: any) => value?.default)?.name ||
              ""
            }
            showSearch
            optionFilterProp="name"
            onChange={(value) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field?.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: value,
                });
              } else temp[index].inputString = value;
              setPayloads(temp);
            }}
            options={(field.values ?? []).map((value: any) => ({
              label: (
                <div className="flex justify-between items-center gap-2 p-1 w-full">
                  <div className="flex items-center gap-2 justify-end text-ellipsis">
                    {value?.logo && <img src={value?.logo} alt={value?.name} className="w-6 h-6" />}
                    <p>{value?.name}</p>
                  </div>
                </div>
              ),
              value: value?.id || value.name,
              name: value?.name,
            }))}
          />
        );
      case "jsonArray":
        return (
          <div className="w-full flex flex-col gap-4">
            {payloads
              .find((input) => input.payloadStructureId === field.payloadStructureId)
              ?.inputString.map((input: any, idx: number) => {
                return (
                  <div className="flex gap-2 justify-between" key={idx}>
                    <div className="w-full" key={idx}>
                      <Skeleton
                        loading={sheetColumns.loading}
                        paragraph={false}
                        active={sheetColumns.loading}
                        style={{ marginBottom: "0.25rem" }}
                      >
                        <Select
                          showSearch
                          style={{ width: "100%", marginBottom: "0.25rem" }}
                          value={
                            payloads.find((input) => input.payloadStructureId === field.payloadStructureId)
                              ?.inputString[idx].label || ""
                          }
                          onChange={(_value, options: any) => {
                            const selectedLabel = options.label;
                            const temp = [...payloads];
                            const index = payloads.findIndex(
                              (input) => input.payloadStructureId === field.payloadStructureId
                            );
                            // Assign original value and unique selection based on index
                            temp[index].inputString[idx] = {
                              ...temp[index].inputString[idx],
                              name: selectedLabel,
                              label: selectedLabel, // Keep original value for the backend
                              uniqueKey: `${selectedLabel}_${idx}`, // Track uniqueness with index
                            };
                            setSheetColumns({
                              loading: false,
                              sheetColumns: sheetColumns.sheetColumns,
                            });
                            setPayloads(temp);
                          }}
                          options={sheetColumns.sheetColumns.map((field: any, i: number) => ({
                            label: field.name,
                            value: `${field.name}_${i}`,
                          }))}
                        />
                      </Skeleton>
                      {input.type !== "boolean" ? (
                        <TiptapField
                          content={
                            payloads.find((input) => input.payloadStructureId === field.payloadStructureId)
                              ?.inputString[idx].tiptapJson || ""
                          }
                          setContent={(content: any, text: string) => {
                            const temp = [...payloads];
                            const index = payloads.findIndex(
                              (input) => input.payloadStructureId === field.payloadStructureId
                            );
                            temp[index].inputString[idx].value = text;
                            temp[index].inputString[idx].tiptapJson = content;
                            setPayloads(temp);
                          }}
                          refresh={updateField}
                          placeholder="Enter the value"
                          variables={variables}
                        />
                      ) : (
                        <Switch
                          style={{
                            width: "fit-content",
                            marginTop: "0.25rem",
                            marginLeft: "0.20rem",
                          }}
                          defaultChecked={
                            payloads.find((input) => input.payloadStructureId === field.payloadStructureId)
                              ?.inputString[idx].value === true
                          }
                          checkedChildren="True"
                          unCheckedChildren="False"
                          onChange={(value) => {
                            const temp = [...payloads];
                            const index = payloads.findIndex(
                              (input) => input.payloadStructureId === field.payloadStructureId
                            );
                            temp[index].inputString[idx].value = value;
                            setPayloads(temp);
                          }}
                        />
                      )}
                    </div>
                    <button
                      className="hover:bg-primary/10 h-fit w-fit p-2 rounded-full text-primary"
                      onClick={() => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        // setSfObjectFields({
                        //   loading: false,
                        //   sfFields: sfObjectFields.sfFields
                        //   .map(field => {
                        //     if (field.name === temp[index].inputString[idx].name) {
                        //       return { ...field, selected: false };
                        //     }
                        //     return field;
                        //   }),
                        // })
                        temp[index].inputString.splice(idx, 1);
                        setUpdateField((prev) => prev + 1);
                        setPayloads(temp);
                      }}
                    >
                      <MdDeleteOutline size={25} />
                    </button>
                  </div>
                );
              })}
            <button
              className="text-primary flex gap-2 items-center"
              onClick={() => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                temp[index].inputString.push({
                  name: "",
                  value: "",
                });
                setPayloads(temp);
              }}
            >
              <FaPlus />
              Add a new Variable Name and Variable Value pair
            </button>
          </div>
        );
      default:
        return (
          <textarea
            className="w-full h-32 rounded-lg"
            placeholder="Enter the value"
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""}
            onChange={(e) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: e.target.value,
                  type: field.type,
                });
              } else {
                temp[index].inputString = e.target.value;
              }
              setPayloads(temp);
            }}
          />
        );
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={modal}
        mask={false}
        onClose={() => setModal(false)}
        width={drawerWidth}
        styles={{
          content: {
            transition: "width 0.3s",
          },
          body: {
            padding: collapseConfig ? "0" : "24px",
          },
        }}
        classNames={{ header: !collapseConfig ? "border-b !border-gray-300" : "!border-none" }}
        className="ml-auto !font-favorit !p-0 border-l border-gray-500"
        getContainer={false}
        footer={
          !collapseConfig && (
            <div className="w-full flex flex-col gap-4 bg-white">
              {!isConnectionExists && (
                <Alert
                  message={
                    <>
                      Please add the required connection to proceed.{" "}
                      <Link to="/connections" target="_blank" rel="noopener noreferrer">
                        <i>Click here</i>
                      </Link>
                    </>
                  }
                  type="warning"
                  showIcon
                />
              )}
              <button
                className={` text-white w-full font-semibold rounded-lg p-2 mt-auto ${
                  loading || !changed || !isConnectionExists
                    ? "cursor-not-allowed bg-gray-500"
                    : "bg-primary cursor-pointer"
                }`}
                onClick={saveOutputStructure}
                disabled={loading || !changed || !isConnectionExists}
              >
                {loading ? "Saving..." : !changed ? "Saved" : "Save"}
              </button>
            </div>
          )
        }
        title={
          !collapseConfig && (
            <div className="flex items-center gap-2">
              <button className="p-0 rounded-sm" onClick={() => setCollapseConfig(true)}>
                <MdChevronRight size={25} />
              </button>
              <img src={action.logo} alt={action.name} className="w-6 h-6" />
              <span
                contentEditable={changeName}
                suppressContentEditableWarning
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.target.blur();
                  }
                }}
                onBlur={async (e) => {
                  if (!e.target.textContent) return;
                  setLoading(true);
                  updateActionName(action.id, e.target.textContent || action.name);
                  setChangeName(false);
                  await saveWorkflowActions(id || "");
                  setChanged(false);
                  setLoading(false);
                  setPublishWarning(true);
                }}
              >
                {action.name}
              </span>
              <button
                onClick={() => {
                  setChangeName(!changeName);
                  const p = document.getElementById("actionName");
                  if (!p) return;
                  setTimeout(function () {
                    const range = document.createRange();
                    const selection = window.getSelection();
                    range.selectNodeContents(p);
                    range.collapse(false); // <-- Set the cursor at the end of the selection
                    selection?.removeAllRanges();
                    selection?.addRange(range);
                    p.focus();
                  }, 0);
                }}
                className={`p-2 rounded-md transition-opacity duration-600 ease-in-out hover:bg-gray-200 ${
                  changeName ? "opacity-50" : "opacity-100"
                }`}
              >
                <FaRegEdit />
              </button>
            </div>
          )
        }
      >
        {collapseConfig ? (
          <button
            className="w-full h-full flex flex-col items-center justify-center p-3 gap-3"
            onClick={() => setCollapseConfig(false)}
          >
            <span className="p-0 border border-gray-300 rounded-sm">
              <MdChevronLeft size={25} />
            </span>
            <p className="[writing-mode:sideways-lr] text-center">CONFIG</p>
          </button>
        ) : (
          <div className="bg-white gap-10 flex flex-col">
            {action.payloadStructure.map((field, idx) => {
              return (
                <div key={idx} className="flex flex-col w-full gap-4 p-4 border border-gray-300 rounded-lg">
                  <div>
                    <p className="tracking-primary font-semibold">
                      {field.name.toUpperCase()}
                      {!field.required && (
                        <span className="text-sm font-normal tracking-normal text-gray-400"> (Optional)</span>
                      )}
                    </p>
                    <p className="text-sm font-light">{field.description}</p>
                  </div>
                  {field.needVars ? (
                    <TiptapField
                      content={
                        payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.tiptapJson ||
                        ""
                      }
                      setContent={(content: any, text: string) => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        if (index === -1) {
                          temp.push({
                            payloadStructureId: field.payloadStructureId,
                            tiptapJson: content,
                            inputString: text,
                            type: field.type,
                          });
                        } else {
                          temp[index].tiptapJson = content;
                          temp[index].inputString = text;
                        }
                        setPayloads(temp);
                      }}
                      refresh={updateField}
                      placeholder={field.name}
                      variables={variables}
                    />
                  ) : (
                    fieldSwitchCase(field)
                  )}
                </div>
              );
            })}
            <div>
              Continue workflow even if this fails:{" "}
              <Switch
                defaultChecked={action.continueOnFail}
                onChange={(value) => {
                  setChanged(true);
                  action.continueOnFail = value;
                }}
              />
            </div>
            <RunCondition
              variables={variables}
              responses={action.responseStructure}
              conditionsArr={
                runCondition || {
                  conditions: [],
                  otherwise: [],
                }
              }
              setConditionsArr={setRunCondition}
            />
          </div>
        )}
      </Drawer>
    </ConfigProvider>
  );
};

export default GoogleSheetModal;

import React, { useState, useRef, useEffect } from "react";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { ConfigProvider, Dropdown, MenuProps, message, Modal } from "antd";
import { ResponseConfiguration } from "@/utils/interfaces";
import { LuArrowLeft, LuArrowRight } from "react-icons/lu";
import SelectHeader from "./SelectHeader";
import MapColumns from "./MapColumns";
import { useWorkflow } from "@/contexts/WorkflowContext";
import { useParams } from "react-router-dom";
import { splitArrayIntoChunks } from "../../utils";
import { getV2 } from "@/utils/constants";
import { getTableInfoForWorkflow, getTableInfoForWorkflowV2 } from "@/utils/apis";
import _ from "lodash";
import PlayButton from "@Assets/SVGs/buildTab/Run.svg";
import { FaChevronDown } from "react-icons/fa";

type Props = {
  template: ResponseConfiguration[];
  children?: React.ReactNode;
  refresh: () => void;
};

const items: MenuProps["items"] = [
  {
    label: <p className="!text-black">Only import - don't run</p>,
    key: "1",
  },
  {
    label: (
      <div className="flex items-center gap-2 !text-black">
        <img src={PlayButton} alt="play" className="w-4 h-4" />
        <p>Import and Run 10</p>
      </div>
    ),
    key: "2",
  },
  {
    label: (
      <div className="flex items-center gap-2 !text-black">
        <img src={PlayButton} alt="play" className="w-4 h-4" />
        <p>Import and Run All</p>
      </div>
    ),
    key: "3",
  },
];

const XLSXInput: React.FC<Props> = ({ template, children, refresh }) => {
  const [resArr, setResArr] = useState<any[]>([]);
  const [fileName, setFileName] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [sheets, setSheets] = useState<string[]>([]);
  const [selectedSheet, setSelectedSheet] = useState<string>("");
  const [fileData, setFileData] = useState<any>(null);
  const [selected, setSelected] = useState<number>(0);
  const [rows, setRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [headers, setHeaders] = useState<any>(undefined);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [map, setMap] = useState<any>({});
  const [duplicateMap, setDuplicateMap] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState(false);
  const [newResponses, setNewResponses] = useState<ResponseConfiguration[]>([]);
  const { saveWorkflowRecord, triggerWorkflowForInput, updateResponseConfig, saveWorkflowActions } = useWorkflow();
  const { id } = useParams();

  useEffect(() => {
    if (resArr.length > 0) {
      setColumns(
        resArr[0].map((item: any, idx: number) => {
          return {
            title: item,
            dataIndex: idx,
            ellipsis: true,
            width: 200,
          };
        })
      );
      setRows(
        resArr
          .filter((item: any[]) => item.length !== 0 && !(item.length === 1 && item[0] === ""))
          .map((item: any[], idx: number) => {
            return { key: idx + 1, ...item };
          })
      );
    }
  }, [resArr]);

  useEffect(() => {
    if (rows.length > 0) setHeaders(rows[0]);
  }, [rows]);

  useEffect(() => {
    if (headers && headers.key !== undefined) setSelectedRows(rows.filter((row) => row.key > headers.key));
  }, [headers, rows]);

  const resetState = () => {
    setResArr([]);
    setFileName("");
    setSheets([]);
    setSelectedSheet("");
    setFileData(null);
    setSelected(0);
    setMap({});
    setDuplicateMap({});
    setSelectedRows([]);
    setNewResponses([]);
    setLoading(false);
    refresh();
    if (fileInputRef.current) fileInputRef.current.value = ""; // Reset the file input
  };

  const handleFileUpload = async (file: File | undefined) => {
    if (!file) return;

    setFileName(file.name);
    const reader = new FileReader();

    if (file.name.endsWith(".csv") || file.name.endsWith(".tsv")) {
      reader.onload = (e) => {
        const text = e.target?.result as string;

        // Get the first line to analyze
        const firstLine = text.split("\n")[0];

        // Detect delimiter by analyzing the first line
        let delimiter = ","; // Default to comma

        // If there are tabs in the first line and more tabs than commas, use tab as delimiter
        // This is hacky, but it works for 99.9% of the cases
        // Will break if the user purposely uses excessive commas or tabs in the first line
        // TODO: Make this better
        if (firstLine.includes("\t")) {
          const tabCount = (firstLine.match(/\t/g) || []).length;
          const commaCount = (firstLine.match(/,/g) || []).length;
          if (tabCount > commaCount) delimiter = "\t";
        }

        const rows = Papa.parse(text, {
          delimiter: delimiter,
          newline: "\n",
          quoteChar: '"',
          escapeChar: '"',
          skipEmptyLines: true,
          transform: (value) => {
            return value.trim() === "\r" ? "" : value.trim();
          },
        }).data as string[][];
        setResArr(rows.filter((item) => item.length !== 0 && !(item.length === 1 && item[0] === "")));
      };
      reader.readAsText(file);
    } else {
      reader.onload = (e) => {
        try {
          const data = new Uint8Array(e.target?.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetNames = workbook.SheetNames;
          setSheets(sheetNames);
          setFileData(workbook);

          // Set first sheet by default
          const firstSheet = sheetNames[0];
          setSelectedSheet(firstSheet);
          const worksheet = workbook.Sheets[firstSheet];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          setResArr(jsonData.filter((item: any) => item.length !== 0 && !(item.length === 1 && item[0] === "")));
        } catch (error) {
          message.error("Error parsing Excel file");
          resetState();
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    fileInputRef.current?.click();
  };

  const addInputs = async () => {
    if (newResponses.length === 0) return template;
    const mappedIds = Object.values(map).filter((item) => item && item !== "");
    const filteredResponses = template.concat(
      newResponses?.filter((res) => !!res?.name && mappedIds.includes(res.responseId))
    );
    if (_.isEqual(filteredResponses, template)) return template;
    const updated = updateResponseConfig(filteredResponses, "id1");
    await saveWorkflowActions(id || "");
    return updated;
  };

  const preventDuplicates = async (responses: ResponseConfiguration[]) => {
    if (id === undefined) return;
    setLoading(true);
    const v2 = getV2();
    const response = v2
      ? await getTableInfoForWorkflowV2(id, 0, 10000, 1)
      : await getTableInfoForWorkflow(id, 0, 100000, true, "");
    const action = response.responses.filter((item: { actionId: string }) => item.actionId === "id1");
    if (action.length === 0) return;

    const columns = action[0].response
      .filter((item: { responseId: string }) => duplicateMap[item.responseId])
      .map((item: { name: string; responseId: any }) => ({
        [item.name]: item.responseId,
      }));

    const row_data: Record<string, string[]> = {};
    Object.values(response.data).forEach((item) => {
      (item as { id1: Record<string, any> })["id1"].response.forEach((response: { responseId: string; value: any }) => {
        if (Object.keys(duplicateMap).includes(response.responseId))
          if (row_data[response.responseId] === undefined) row_data[response.responseId] = [response.value];
          else row_data[response.responseId].push(response.value);
      });
    });

    const cols = Object.entries(map).filter((item) => item[1] !== "");

    const r = selectedRows.map((row) => {
      const each = cols.map((item) => ({
        [responses.find((col) => col.responseId === item[1])?.name || ""]: row[item[0]],
      }));
      return each.reduce((acc, val) => ({ ...acc, ...val }), {});
    });

    const filtered = [];

    for (let i = 0; i < r.length; i++) {
      const row = r[i];
      let isDuplicate = false;
      Object.entries(row).forEach(([key, value]) => {
        const column = columns.find((item: Record<string, string>) => key in item);
        if (!column) return;
        if (row_data[column[key]] && row_data[column[key]].includes(value)) isDuplicate = true;
      });

      if (!isDuplicate) filtered.push(row);
    }
    return filtered;
  };

  const importCSV = async (option: number) => {
    try {
      if (loading) return;
      setLoading(true);
      const finalResponses: ResponseConfiguration[] = await addInputs();
      const rows = await preventDuplicates(finalResponses);

      if (!rows || rows.length === 0) return;
      const data = rows.map((row) => {
        const obj: any = {};
        finalResponses.find((res) => {
          Object.entries(row).forEach((item) => {
            if (res.name === item[0]) obj[res.responseId || ""] = item[1];
          });
        });
        return obj;
      });
      const firstArray = data.slice(0, 10); // First 10 items
      const secondArray = data.slice(10); // Remaining items
      // Convert array of objects into a string and calculate size in bytes
      const jsonString = JSON.stringify(data);
      const sizeInBytes = new TextEncoder().encode(jsonString).length;
      const sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
      const numberOfChunks = Math.ceil(parseInt(parseFloat(sizeInMB).toFixed()) / 10);

      const chunks = splitArrayIntoChunks(data, numberOfChunks || 1);

      switch (option) {
        case 0:
          console.log("saving the data.....");
          for (const chunk of chunks) {
            await saveWorkflowRecord(id || "", chunk);
          }
          break;
        case 1:
          console.log("Running 10, saving the rest.....");
          await saveWorkflowRecord(id || "", secondArray);
          await triggerWorkflowForInput(id || "", firstArray, true, -1);
          break;
        case 2:
          console.log("Running all.....");
          await triggerWorkflowForInput(id || "", data, false, -1);
          break;
        default:
          console.log("DEFAULT: saving the data.....");
          await saveWorkflowRecord(id || "", data);
          break;
      }
      message.success(
        <p>
          <span className="font-semibold">{data.length}</span> rows uploaded successfully from{" "}
          <span className="font-semibold">{fileName}</span>
        </p>
      );
    } catch {
      message.error("Failed to upload data, please try again.");
    } finally {
      resetState();
    }
  };

  return (
    <div
      className="relative max-h-[50vh]"
      onClick={(e) => e.stopPropagation()}
      onDragEnter={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onDragOver={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onDrop={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleFileUpload(e?.dataTransfer?.files?.[0]);
      }}
    >
      <input
        ref={fileInputRef}
        type="file"
        accept=".xlsx,.xls,.csv"
        onChange={(e) => handleFileUpload(e?.target?.files?.[0])}
        style={{ display: "none" }}
        id="xlsx-upload"
      />
      <div onClick={handleButtonClick}>{children}</div>
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              paddingContentHorizontalLG: 0,
              paddingMD: 0,
              borderRadiusLG: 8,
              fontFamily: "ABC Favorit",
            },
          },
        }}
      >
        <Modal
          title={
            <div className="flex items-center w-full border-b p-5">
              {selected === 1 && (
                <button
                  className="px-2 py-1 rounded border border-gray-500 hover:bg-gray-100"
                  onClick={() => setSelected(0)}
                >
                  <LuArrowLeft size={20} />
                </button>
              )}
              <h2 className="mx-auto text-2xl font-semibold">{["Select header row", "Map your columns"][selected]}</h2>
            </div>
          }
          width="75vw"
          centered
          open={resArr.length !== 0}
          onCancel={resetState}
          footer={
            <div className="flex justify-between items-center px-8 py-4 border-t">
              <button
                className="text-md font-medium text-red-600 hover:bg-red-50 hover:underline px-2 py-1 rounded"
                onClick={resetState}
              >
                Cancel
              </button>
              {selected === 0 ? (
                <button
                  className="bg-[#333333] px-8 py-1 rounded text-white flex gap-1 items-center text-md font-medium"
                  onClick={() => {
                    if (selected === 0) setSelected(1);
                    else {
                      refresh();
                      resetState();
                    }
                  }}
                >
                  Next
                  <LuArrowRight size={20} />
                </button>
              ) : (
                <ConfigProvider
                  theme={{
                    token: {
                      colorBgBase: "#333333",
                      colorTextBase: "#FFFFFF",
                      colorBgElevated: "#ffffff",
                      colorBorder: "#999999",
                      controlItemBgHover: "#cccccc",
                    },
                  }}
                >
                  <Dropdown.Button
                    loading={loading}
                    icon={<FaChevronDown />}
                    menu={{
                      items,
                      onClick: (info) => importCSV(parseInt(info.key)),
                    }}
                    rootClassName="w-fit"
                    onClick={() => importCSV(1)}
                  >
                    <p className="p-2">Import</p>
                  </Dropdown.Button>
                </ConfigProvider>
              )}
            </div>
          }
          onClose={resetState}
        >
          {selected === 0 ? (
            <SelectHeader
              isExcel={!fileName.endsWith(".csv")}
              sheets={sheets}
              selectedSheet={selectedSheet}
              onSheetSelect={(sheet) => {
                setSelectedSheet(sheet);
                if (fileData) {
                  const worksheet = fileData.Sheets[sheet];
                  const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                  setResArr(jsonData);
                }
              }}
              rows={rows}
              columns={columns}
              setHeaders={setHeaders}
            />
          ) : (
            <MapColumns
              data={selectedRows}
              headers={headers}
              responses={template}
              map={map}
              setMap={setMap}
              duplicateMap={duplicateMap}
              setDuplicateMap={setDuplicateMap}
              setNewResponses={setNewResponses}
            />
          )}
        </Modal>
      </ConfigProvider>
    </div>
  );
};

export default XLSXInput;

import { Response, ResponseConfiguration } from "@/utils/interfaces";
import KeyValue from "../../Modals/KeyValue";
import { USER_ACTION_STATUS, WATERFALL_ACTIONS } from "@/utils/constants";
import { IoMdClose } from "react-icons/io";
import { useEffect, useState } from "react";
import { fetchDocContent, isApplicableURL } from "@/utils/functions";
import { Skeleton } from "antd";
import { viewerComponent } from "../Table/ViewerUtil";
import { useParams } from "react-router-dom";

type Props = {
  responses: ResponseConfiguration[];
  row: any;
  close: () => void;
  action: any;
};

const STATUS_LABEL_MAP = {
  [USER_ACTION_STATUS.COMPLETED]: "Success",
  [USER_ACTION_STATUS.FAILED]: "𝘕𝘰 𝘥𝘢𝘵𝘢 𝘧𝘰𝘶𝘯𝘥",
  [USER_ACTION_STATUS.PENDING]: "𝘕𝘰𝘵 𝘳𝘶𝘯",
  [USER_ACTION_STATUS.PAYLOAD_FILLED]: "In Progress",
  [USER_ACTION_STATUS.ERROR]: "𝘌𝘳𝘳𝘰𝘳",
  [USER_ACTION_STATUS.OUT_OF_CREDITS]: "𝘖𝘶𝘵 𝘰𝘧 𝘤𝘳𝘦𝘥𝘪𝘵𝘴",
  [USER_ACTION_STATUS.CHECKING_NEXT_SOURCE]: "Checking Next Source",
  [USER_ACTION_STATUS.CONDITION_NOT_MET]: "Condition not met",
};

const ResponseModal = ({ responses, row, close, action }: Props) => {
  const [loading, setLoading] = useState(false);
  const [customViewer, setCustomViewer] = useState<any>({
    open: false,
    data: {},
    type: "",
    credits: 0,
    errorMessage: "",
  });
  const isWaterfallAction = WATERFALL_ACTIONS.includes(action.actionName);
  const actionResponseSize: string = action?.responseSize || "";
  const isActionResponseSizeSmall: boolean = actionResponseSize === "small";
  const isActionResponseSizeLarge: boolean = actionResponseSize === "large";

  const { id } = useParams();

  const jsonParser = (json: string) => {
    if (typeof json !== "string") return json;
    try {
      return JSON.parse(json);
    } catch (e) {
      return json;
    }
  };

  useEffect(() => {
    if (!row) return;
    const setResponses = async () => {
      setLoading(true);
      let response = row.response;
      if (row.status === USER_ACTION_STATUS.COMPLETED && typeof response === "string" && isApplicableURL(response))
        response = await fetchDocContent(response);
      createViewer(response);
      setLoading(false);
    };
    setResponses();
  }, [row]);

  const createViewer = (rowResponse: Response[]) => {
    const actionResponse: any = isActionResponseSizeSmall && rowResponse?.[0]?.value;
    // Remove undefined keys and values from colResponse
    const largeActionResponse: any =
      isActionResponseSizeLarge &&
      rowResponse
        .map((res) => {
          // Only return the key-value pair if the value is defined
          const config = responses.find((response) => response.responseId === res.responseId);
          return config && res.value !== undefined ? { [config.name]: res.value } : null;
        })
        .filter((item) => item !== null) // Remove null entries
        .reduce((acc, curr) => ({ ...acc, ...curr }), {});

    if (action.id === "id1") {
      setCustomViewer({
        open: true,
        data: {
          response: rowResponse,
          config: responses,
        },
        type: "inputAction",
      });
    } else if (isWaterfallAction) {
      const waterfallResponse = row.response;
      if (waterfallResponse && waterfallResponse.length > 0)
        setCustomViewer({
          open: true,
          data: {
            stepDownResponse: row.stepDownResponse,
            action,
            email: actionResponse,
            workflowDataActionPartId: row.id,
          },
          type: "email",
          credits: action.credits,
          errorMessage: row.error,
        });
    } else if (action.actionName === "PUBLIC_LINKEDIN_POSTS_ENRICH") {
      setCustomViewer({
        open: true,
        data: {
          stepDownResponse: row.stepDownResponse,
          response: largeActionResponse,
        },
        type: "linkedinPosts",
        credits: action.credits,
      });
    } else if (action.actionName === "SCRAPE_LINKEDIN_PUBLIC_DATA_2") {
      setCustomViewer({
        open: true,
        data: {
          responseStructure: action.responseStructure,
          stepDownResponse: row.stepDownResponse,
          response: largeActionResponse,
        },
        type: "linkedinProfile",
        credits: action.credits,
      });
    } else if (action.actionName === "SCRAPE_COMPANY_LINKEDIN_DATA_2") {
      setCustomViewer({
        open: true,
        data: {
          stepDownResponse: row.stepDownResponse,
          largeActionResponse,
        },
        type: "linkedinCompany",
        credits: action.credits,
      });
    } else if (action.actionName === "FIND_EMPLOYEES_USING_AI_BEES") {
      setCustomViewer({
        open: true,
        data: {
          data: largeActionResponse,
          action: action,
          workflowDataActionPartId: row?.id,
        },
        type: "aibeesEmployees",
        credits: action.credits,
      });
    } else if (action.actionName === "FIND_EMPLOYEES_FOR_COMPANY_USING_APOLLO") {
      setCustomViewer({
        open: true,
        data: largeActionResponse,
        type: "apolloEmployees",
        credits: action.credits,
      });
    } else if (action.actionName === "FIND_EMPLOYEES_USING_SALES_NAV_URL") {
      setCustomViewer({
        open: true,
        data: largeActionResponse,
        type: "salesNavEmployees",
        credits: action.credits,
      });
    } else if (action.actionName === "WELL_DB_OPERATOR_LOOKUP_AND_WELLS_SEARCH") {
      setCustomViewer({
        open: true,
        data: largeActionResponse,
        type: "wells",
        credits: 0,
      });
    } else if (action.actionName === "CRUNCHBASE_RECENT_FUNDRAISING_DATA_FROM_COMPANY") {
      setCustomViewer({
        open: true,
        data: largeActionResponse,
        type: "crunchBaseFundraising",
        credits: action.credits,
      });
    } else if (action.actionName === "ZENROWS_CRUNCHBASE_ACQUISITIONS_LIST") {
      setCustomViewer({
        open: true,
        data: largeActionResponse,
        type: "acquisitions",
        credits: action.credits,
      });
    } else if (action.type === "sending" && action.outputStructureType === "noOutput") {
      setCustomViewer({
        open: true,
        data: { status: actionResponse.value || row.status },
        type: "default",
        credits: action.credits,
      });
    } else if (action.actionName === "FIND_10K_DOC_FROM_TICKER_SYMBOL") {
      setCustomViewer({
        open: true,
        data: rowResponse,
        type: "textFileURL",
        credits: action.credits,
      });
    } else if (action.reviewNeeded) {
      setCustomViewer({
        open: true,
        data: rowResponse,
        type: "review",
        credits: action.credits,
      });
    } else if (action.actionName === "CRUSTDATA_COMPANY_ENRICH") {
      setCustomViewer({
        open: true,
        data: {
          responseStructure: action.responseStructure,
          stepDownResponse: row.stepDownResponse,
          largeActionResponse,
        },
        type: "crustdataCompany",
        credits: action.credits,
      });
    } else if (action.actionName === "HG_INSIGHTS_TECH_STACK_ENRICH") {
      setCustomViewer({
        open: true,
        data: largeActionResponse,
        type: "hgInsightsTechStack",
        credits: action.credits,
      });
    } else if (action.actionName === "ENRICH_IP_DATA_WATERFALL") {
      setCustomViewer({
        open: true,
        data: {
          response: rowResponse
            .map((res) => {
              // Only return the key-value pair if the value is defined
              const config = responses.find((response) => response.responseId === res.responseId);
              return config && res.value !== undefined ? { [config.name]: res.value } : null;
            })
            .filter((item) => item !== null) // Remove null entries
            .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
          stepDownResponse: row.stepDownResponse,
          action,
          workflowId: id,
          workflowDataActionPartId: row.id,
          status: row.status,
          successText: "Enriched IP Data",
          navigationText: "View enriched IP data",
        },
        type: "apnicIpData",
        credits: action.credits,
        errorMessage: row.error,
      });
    } else if (action.actionName === "HACKER_TARGET_ASN_LOOKUP") {
      setCustomViewer({
        open: true,
        data: {
          response: rowResponse
            .map((res) => {
              // Only return the key-value pair if the value is defined
              const config = responses.find((response) => response.responseId === res.responseId);
              return config && res.value !== undefined ? { [config.name]: res.value } : null;
            })
            .filter((item) => item !== null) // Remove null entries
            .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
          action,
          workflowId: id,
          workflowDataActionPartId: row.id,
        },
        type: "hackerTargetAsn",
        credits: action.credits,
      });
    } else {
      setCustomViewer({
        open: true,
        data: {
          response: rowResponse
            .map((res) => {
              const config = responses.find((response) => response.responseId === res.responseId);
              if (!config) return {};
              return {
                [config.name]: action.actionName === "HTTP_API_CALL" ? jsonParser(res.value) : res.value,
              };
            })
            .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
          workflowDataActionPartId: row.id,
          action,
          workflowId: id,
        },
        type: "default",
        credits: action.credits,
      });
    }
  };

  return (
    <div className="flex flex-col h-full border-l border-gray-500">
      <div className="flex items-center justify-between w-full px-4 py-[1.12rem] gap-2">
        <h2 className="text-md font-semibold">Response</h2>
        <button className="p-1 rounded-full hover:bg-gray-200" onClick={() => close()}>
          <IoMdClose size={20} />
        </button>
      </div>
      <Skeleton loading={loading} active>
        <div className="flex flex-col h-full gap-2 p-2 border-y border-gray-300 overflow-y-auto">
          {responses.length > 0 && row.response && row.response.length > 0 ? (
            viewerComponent(customViewer)
          ) : row.status === USER_ACTION_STATUS.COMPLETED ? (
            <KeyValue keyName="status" value="Completed" open={row.dataId} />
          ) : (
            <div>
              <p className="text-md font-semibold">
                Status:{" "}
                <span className="font-normal">{STATUS_LABEL_MAP[row.status as keyof typeof STATUS_LABEL_MAP]}</span>
              </p>
              <p className="text-md font-semibold">
                Message: <span className="font-normal">{row.error}</span>
              </p>
            </div>
          )}
        </div>
      </Skeleton>
    </div>
  );
};

export default ResponseModal;

import { isArray } from "lodash";
import { useEffect, useState } from "react";

export interface interfaceinputAction {
  name: string;
  id: string;
}
export interface filter {
  name: string;
  id: string;
  type: "input" | "action";
  operator?: InputFilterOperation;
  value?: string | string[];
}

type InputFilterOperation = "like" | "not_like" | "empty" | "not_empty" | "contains_any" | "not_contains_any" | null;

type InputFilter = {
  type: "like";
  value: string;
};

export interface InputFilterDataStructure {
  [key: string]: InputFilter;
}

export const inputActionOperations = [
  { title: "is like", value: "like" },
  { title: "not like", value: "not_like" },
  // { title: "is empty", value: "empty" },
  { title: "not empty", value: "not_empty" },
  {
    title: "contains any of",
    value: "contains_any",
    Placeholder: "sam, Daniel, eleanor...",
    tooltip: "Filter is case sensitive",
  },
  {
    title: "not contains any of",
    value: "not_contains_any",
    Placeholder: "sam, Daniel, eleanor...",
    tooltip: "Filter is case sensitive",
  },
];

export const nonInputFieldFilters = ["empty", "not_empty"];

export const useFilter = (
  actions: any[],
  inputActions: interfaceinputAction[],
  defaultActionFilters: any,
  defaultInputFilters: InputFilterDataStructure,
  isPopoverOpen?: boolean
) => {
  const [filters, setFilters] = useState<filter[]>([]);
  const [appliedFilters, setAppliedFilters] = useState<filter[]>([]);
  const [availableFilters, setAvailableFilters] = useState<filter[]>([]);

  const constructDefaultFilters = (inputs: { [key: string]: string[] }) => {
    if (!inputs || !actions) return [];
    return Object.keys(inputs)?.map((key) => {
      const action = actions.find((action) => action.id === key);
      return {
        id: action?.id,
        name: action?.displayName || action?.name,
        type: "action" as const,
        operator: null,
        value: inputs[key],
      };
    });
  };

  const constructDefaultInputFilters = (inputs: InputFilterDataStructure, inputActions: interfaceinputAction[]) => {
    if (!inputs) return [];
    const filters: any[] = [];
    Object.keys(inputs)?.map((key) => {
      const input = inputActions.find((input) => input.id === key);
      if (!input) return;

      filters.push({
        id: input?.id,
        name: input?.name?.toUpperCase(),
        type: "input" as const,
        operator: inputs[key].type || "",
        value: inputs[key].value || "",
      });
    });
    return filters;
  };

  useEffect(() => {
    if (isPopoverOpen) {
      return;
    }
    const filterOptions: filter[] = [];
    const defaultFilterOptions: filter[] = [];

    constructDefaultFilters(defaultActionFilters)?.forEach((filter) => {
      defaultFilterOptions.push(filter);
    });

    constructDefaultInputFilters(defaultInputFilters, inputActions)?.forEach((filter) => {
      defaultFilterOptions.push(filter);
    });

    actions?.forEach((action: any) => {
      if (defaultActionFilters?.[action.actionId]) {
        return;
      }
      filterOptions.push({
        id: action?.id,
        name: action?.displayName || action?.name,
        type: "action" as const,
        operator: null,
        value: [],
      });
    });

    inputActions?.forEach((action) => {
      if (defaultInputFilters?.[action.id]) {
        return;
      }
      filterOptions.push({
        name: action?.name?.toUpperCase(),
        id: action?.id,
        type: "input",
        operator: "like",
        value: "",
      });
    });

    setAppliedFilters(defaultFilterOptions);
    setFilters([...defaultFilterOptions, ...filterOptions]);
    setAvailableFilters(filterOptions);
  }, [actions, inputActions]);

  useEffect(() => {
    if (filters?.length && !appliedFilters?.length) {
      addFilter();
    }
  }, [filters]);

  useEffect(() => {
    const remaining = getRemainingFilters();
    setAvailableFilters(remaining);
  }, [appliedFilters]);

  const getRemainingFilters = () => {
    const appliedFilterIds = appliedFilters.map((filter) => filter.id);
    return filters.filter((filter) => !appliedFilterIds.includes(filter.id));
  };

  const getAppliedFilterKeys = () => {
    return appliedFilters.map((filter) => filter.id);
  };

  const addFilter = () => {
    if (!availableFilters?.length) return;

    const newFilter = availableFilters[0];

    setAppliedFilters((prev) => [...prev, newFilter]);
    setAvailableFilters((prev) => prev?.filter((filter) => filter.id !== newFilter.id));
  };

  const removeFilter = (filter: filter) => {
    const tempFilters = appliedFilters.filter((appliedFilter) => appliedFilter.id !== filter.id);
    setAppliedFilters(tempFilters);
  };

  const setFilter = (filter: filter, prevFilter: filter) => {
    const tempFilters = appliedFilters.map((appliedFilter) =>
      appliedFilter.id === prevFilter.id ? filter : appliedFilter
    );
    setAppliedFilters(tempFilters);
  };

  const showInputForFilter = (filter: filter) => {
    if (!filter.operator) {
      return false;
    }

    return (
      filter.type === "input" && ["like", "not_like", "contains_any", "not_contains_any"].includes(filter.operator)
    );
  };

  const clearFilters = () => {
    const filter = filters[0];
    if (isArray(filter.value)) {
      filter.value = [];
    } else {
      filter.value = "";
    }
    setAppliedFilters([filter]);
  };

  return {
    filters,
    appliedFilters,
    availableFilters,
    addFilter,
    removeFilter,
    setFilter,
    getRemainingFilters,
    getAppliedFilterKeys,
    showInputForFilter,
    clearFilters,
  };
};

/*global chrome*/
import { getV2, USER_ACTION_STATUS } from "@/utils/constants";
import { getBrowserTokens, handleUpdateTokens } from "@/utils/functions";
import { retryActionURL, retryActionURLV2 } from "@/utils/urls";
import { createContext, useContext, useState } from "react";
import { setStateType } from "@/utils/constants";

export const RunOnFloqContext = createContext({
  data: [] as any[],
  setData: (_data: any) => {},
  openRun: false,
  setOpenRun: (_openRun: boolean) => {},
  continueRun: false,
  setContinueRun: (_continueRun: boolean) => {},
  collapseConfig: false,
  setCollapseConfig: ((_val: boolean) => {}) as setStateType<boolean>,
  action: undefined as any,
  setAction: (_act: any) => {},
  runOnFloq: async (
    _actionNameId: string,
    _userWorkflowId: string,
    _section: number,
    _type: string,
    _singleId?: string
  ) => {},
});

const FAILED_STATUES = [
  USER_ACTION_STATUS.FAILED,
  USER_ACTION_STATUS.ERROR,
  USER_ACTION_STATUS.OUT_OF_CREDITS,
  USER_ACTION_STATUS.PENDING,
];

export const useRunOnFloq = () => useContext(RunOnFloqContext);
export default function RunOnFloqContextProvider({ children }: any) {
  const [data, setData] = useState<any[]>([]);
  const [openRun, setOpenRun] = useState(false);
  const [continueRun, setContinueRun] = useState<boolean>(false);
  const [collapseConfig, setCollapseConfig] = useState<boolean>(false);
  const [action, setAction] = useState<any>(undefined);

  const runOnFloq = async (
    actionNameId: string,
    userWorkflowId: string,
    section: number,
    type: string,
    singleId?: string
  ) => {
    try {
      const v2 = getV2();
      const url = v2 ? retryActionURLV2 : retryActionURL;
      const [accessToken, refreshToken] = getBrowserTokens();

      const ids =
        type === "failed"
          ? data.filter((row) => FAILED_STATUES.includes(row.data)).map((row) => row.dataId)
          : type === "singleCell"
            ? [singleId]
            : type === "all"
              ? data.map((row) => row.dataId)
              : type === "first5"
                ? data.slice(0, 5).map((row) => row.dataId)
                : data.slice(0, 10).map((row) => row.dataId);
      const runType = type === "singleCell" || continueRun ? type : type === "all" ? "singleColumn" : type + "Single";

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accessToken,
          refreshToken,
        },
        body: JSON.stringify({
          actionNameId,
          dataIds: ids,
          type: runType,
          section,
        }),
      };

      const res = await fetch(`${url}/${userWorkflowId}`, options);
      const response = await res.json();

      setData((prev) => {
        return prev.map((row) => {
          if (ids.includes(row.dataId)) {
            row.status = USER_ACTION_STATUS.PAYLOAD_FILLED;
            row.response = null;
          }
          return row;
        });
      });

      if (!res.ok) throw new Error(response.error || "Error updating workflow data");
      handleUpdateTokens(data, accessToken, refreshToken);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const value = {
    data,
    setData,
    openRun,
    setOpenRun,
    continueRun,
    setContinueRun,
    collapseConfig,
    setCollapseConfig,
    action,
    setAction,
    runOnFloq,
  };

  return <RunOnFloqContext.Provider value={value}>{children}</RunOnFloqContext.Provider>;
}

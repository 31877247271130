import {
  ALLOWED_TYPES_AUTO_DEDUPE,
  CRUST_DATA_PERSON_ENRICH_REALTIME_STRUCTURE_ID,
  EMAIL_VALIDATORS_STRUCTURE_ID,
  INCLUDED_ACTIONS_AUTO_DEDUPE,
  NON_AI_ACTIONS_WITH_API_PRICIING_IDS,
  setStateType,
  ZENROWS_PERSONAL_API_KEY_STRUCTURE_ID,
} from "../../../utils/constants";
import { useCallback, useEffect, useState } from "react";
import { useWorkflow } from "../../../contexts/WorkflowContext";
import TiptapField from "../Tiptap/TiptapField";
import { Action, PayloadConfiguration } from "../../../utils/interfaces";
import { Alert, ConfigProvider, Drawer, InputNumber, Select, Skeleton, Slider, Switch, message } from "antd";
import ArrayField from "./ArrayField";
import StepDownSearch from "./StepDownSearch";
import { FaCoins, FaPlus, FaRegEdit } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { IoCheckmark, IoWarning } from "react-icons/io5";
import Coins from "@/assets/SVGs/buildTab/Coins";
import { RiArrowDownSLine, RiExpandUpDownLine } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import { countriesDropdown } from "@/utils/countries";
import { useConnections } from "@/contexts/ConnectionContext";
import {
  dataFormatterPrompt,
  fetchClayCities,
  fetchClayCountries,
  fetchClayIndustries,
  fetchClayLocations,
  fetchClayStates,
  fetchLanguages,
  fetchTechnologies,
  fetchYoutubeCountries,
} from "@/utils/apis";
import { MdChevronLeft, MdChevronRight, MdClear } from "react-icons/md";
import { useRunOnFloq } from "@/contexts/RunOnFloqContext";
import AIButton from "../Shared/AIButton";
import ResponseStructureViewer from "./ModalComponents/ResponseStructure";
import RunCondition from "./ModalComponents/RunCondition";
import BlueVarLogo from "../../../assets/SVGs/BlueVarLogo.svg";
import YellowVarLogo from "../../../assets/SVGs/YellowVarLogo.svg";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";

// Helper functions for localStorage prompt storage
const getStoredPrompt = (actionId: string, fieldId: string) => {
  try {
    // Generate a unique key that includes the drawer instance ID
    const drawerInstanceId = localStorage.getItem(`floq_current_drawer_instance_${actionId}`) || "";
    const key = `floq_ai_prompt_${actionId}_${fieldId}_${drawerInstanceId}`;
    const stored = localStorage.getItem(key);
    return stored ? JSON.parse(stored) : null;
  } catch (e) {
    console.error("Error retrieving prompt from localStorage:", e);
    return null;
  }
};

const storePrompt = (actionId: string, fieldId: string, promptData: { content: any; text: string }) => {
  try {
    // Generate a unique key that includes the drawer instance ID
    const drawerInstanceId = localStorage.getItem(`floq_current_drawer_instance_${actionId}`) || "";
    const key = `floq_ai_prompt_${actionId}_${fieldId}_${drawerInstanceId}`;
    localStorage.setItem(key, JSON.stringify(promptData));
  } catch (e) {
    console.error("Error storing prompt in localStorage:", e);
  }
};

type Props = {
  action: Action;
  modal: boolean;
  setModal: setStateType<boolean>;
  payloadConfiguration: PayloadConfiguration[];
  responseConfiguration: any[];
  variables: any[];
  loadStates?: (actionId: string, settersMap: any) => void;
  saveSates?: (states: any, actionId: string) => void;
};

const ActionModal = ({
  action,
  modal,
  setModal,
  payloadConfiguration,
  responseConfiguration,
  variables,
  loadStates,
  saveSates,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [isGenerating, setIsGenerating] = useState<{ [key: string]: boolean }>({});
  const [prompts, setPrompts] = useState<{ [key: string]: { content: any; text: string } }>({});
  // Add regenerating state to track external regeneration status
  const [regenerating, setRegenerating] = useState(false);
  /**
   * @todo: also show the connection name in the error message
   */
  // const [isConnectionExists, setIsConnectionExists] = useState({ doExists: true, connectionName: "" });
  const [isConnectionExists, setIsConnectionExists] = useState(true);
  const [updateField, setUpdateField] = useState(0);
  const [review, setReview] = useState(action.reviewNeeded || false);
  const [payloads, setPayloads] = useState<PayloadConfiguration[]>(payloadConfiguration);
  const [responses, setResponses] = useState(responseConfiguration);
  const [runCondition, setRunCondition] = useState(action.runCondition);
  const {
    updatePayloadConfig,
    updateResponseConfig,
    saveWorkflowActions,
    updateActionName,
    setPublishWarning,
    fetchAllAPIPricing,
  } = useWorkflow();
  const { getConnectionFromType } = useConnections();
  const { collapseConfig, setCollapseConfig, runOnFloq, setOpenRun, setAction } = useRunOnFloq();
  const [drawerWidth, setDrawerWidth] = useState(collapseConfig ? "3rem" : "30%");
  const [providersWithCredits, setProvidersWithCredits] = useState<{ loading: boolean; providers: any[] }>({
    loading: true,
    providers: [],
  });
  const [changeName, setChangeName] = useState(false);
  const [isConnectionLoading, setIsConnectionLoading] = useState(false);
  // for actions with api key toggle
  const [isUserKeyEnable, setIsUserKeyEnable] = useState(false);
  const [userConnection, setUserConnection] = useState<any>();
  const [hasPrompt, setHasPrompt] = useState(false);
  // Feedback states
  const [technologies, setTechnologies] = useState<{
    technologies: { displayName: string; value: string }[];
    loading: boolean;
  }>({
    technologies: [],
    loading: false,
  });
  const [languages, setLanguages] = useState<{ languages: { displayName: string; value: string }[]; loading: boolean }>(
    {
      languages: [],
      loading: false,
    }
  );
  const [youtubeCountries, setYoutubeCountries] = useState<{
    countries: { displayName: string; value: string }[];
    loading: boolean;
  }>({
    countries: [],
    loading: false,
  });
  const [clayIndustries, setClayIndustries] = useState<{
    industries: { name: string; id: string }[];
    loading: boolean;
  }>({
    industries: [],
    loading: false,
  });
  const [clayCountries, setClayCountries] = useState<{
    countries: { name: string; id: string }[];
    loading: boolean;
  }>({
    countries: [],
    loading: false,
  });
  const [clayLocations, setClayLocations] = useState<{
    locations: { name: string; id: string }[];
    loading: boolean;
  }>({
    locations: [],
    loading: false,
  });
  const [clayCities, setClayCities] = useState<{
    cities: { name: string; id: string }[];
    loading: boolean;
  }>({
    cities: [],
    loading: false,
  });
  const [clayStates, setClayStates] = useState<{
    states: { name: string; id: string }[];
    loading: boolean;
  }>({
    states: [],
    loading: false,
  });

  const [additionalCredits, setAdditionalCredits] = useState(0);

  useEffect(() => {
    if (loadStates) {
      loadStates(action?.id, {
        prompts: setPrompts,
        payloads: setPayloads,
        changed: setChanged,
      });
    }
  }, []);

  useEffect(() => {
    return () => {
      if (saveSates) {
        saveSates(
          {
            prompts,
            payloads,
            changed,
          },
          action?.id
        );
      }
    };
  }, [prompts, payloads, changed]);

  // fetch technologies for HG insights from bucket
  useEffect(() => {
    if (action.actionName === "HG_INSIGHTS_TECH_STACK_ENRICH") {
      setTechnologies({ technologies: [], loading: true });
      fetchTechnologies()
        .then((data) => {
          setTechnologies({
            technologies: data,
            loading: false,
          });
        })
        .catch((err) => console.error(err));
    }
    if (
      ["YOUTUBE_EXTRACT_VIDEO_TRANSCRIPT", "YOUTUBE_ENRICH_VIDEO", "YOUTUBE_ENRICH_CHANNEL"].includes(action.actionName)
    ) {
      setLanguages({
        languages: [],
        loading: true,
      });
      fetchLanguages()
        .then((data) => {
          setLanguages({
            languages: data,
            loading: false,
          });
        })
        .catch((err) => console.error(err));
    }
    if (["YOUTUBE_ENRICH_VIDEO", "YOUTUBE_ENRICH_CHANNEL"].includes(action.actionName)) {
      setYoutubeCountries({
        countries: [],
        loading: false,
      });
      fetchYoutubeCountries()
        .then((data) => {
          setYoutubeCountries({
            countries: data,
            loading: false,
          });
        })
        .catch((err) => console.error(err));
    }
    if (action.actionName === "COMPANY_HEADCOUNT_BY_COUNTRY") {
      setClayIndustries({ industries: [], loading: true });
      setClayCountries({ countries: [], loading: true });
      setClayLocations({ locations: [], loading: true });

      Promise.all([
        fetchClayLocations(),
        fetchClayIndustries(),
        fetchClayCountries(),
        fetchClayCities(),
        fetchClayStates(),
      ])
        .then(([locationsData, industriesData, countriesData, citiesData, statesData]) => {
          setClayLocations({
            locations: locationsData,
            loading: false,
          });
          setClayIndustries({
            industries: industriesData,
            loading: false,
          });
          setClayCountries({
            countries: countriesData,
            loading: false,
          });
          setClayCities({
            cities: citiesData,
            loading: false,
          });
          setClayStates({
            states: statesData,
            loading: false,
          });
        })
        .catch((err) => console.error(err));
    }
  }, []);

  const { id } = useParams();

  useEffect(() => {
    setWidthPercent();
    window.addEventListener("resize", setWidthPercent);
    return () => window.removeEventListener("resize", setWidthPercent);
  }, [collapseConfig]);

  const setWidthPercent = useCallback(() => {
    try {
      if (collapseConfig) setDrawerWidth("3rem");
      // Adjust width for different screen sizes
      else if (window.matchMedia("(min-width: 1024px) and (min-height: 1366px)").matches)
        // iPad Pro and similar devices
        setDrawerWidth("50%");
      else if (window.matchMedia("(min-width: 1024px)").matches) setDrawerWidth("30%");
      else if (window.matchMedia("(min-width: 640px)").matches) setDrawerWidth("50%");
      else setDrawerWidth("100%");
    } catch (err) {
      console.error("Error calculating width percent based on media size");
    }
  }, [collapseConfig]);

  useEffect(() => {
    if (!action?.connectionId) return;
    setIsConnectionLoading(true);
    getConnectionFromType(action?.connectionId)
      .then((connection: any) => {
        // if case, only for actions with api key toggle
        if (payloads.find((input) => input.payloadStructureId === ZENROWS_PERSONAL_API_KEY_STRUCTURE_ID)) {
          const isKey =
            payloads.find((input) => input.payloadStructureId === ZENROWS_PERSONAL_API_KEY_STRUCTURE_ID)?.inputString ||
            false;
          setIsUserKeyEnable(isKey);
          if (isKey) setIsConnectionExists(connection?.userConnection ? true : false);
        } else setIsConnectionExists(connection?.userConnection ? true : false);
        setUserConnection(connection?.userConnection ? connection : null);
      })
      .finally(() => setIsConnectionLoading(false));
  }, []);

  /**
   * @todo: try to show user's openai api key, if the user has selected the "use personal api key" option
   */
  useEffect(() => {
    if (NON_AI_ACTIONS_WITH_API_PRICIING_IDS.includes(action?.actionName)) {
      const providersPayload = action.payloadStructure.find(
        (input) =>
          input.payloadStructureId === EMAIL_VALIDATORS_STRUCTURE_ID ||
          input.payloadStructureId === CRUST_DATA_PERSON_ENRICH_REALTIME_STRUCTURE_ID
      )?.values;
      setProvidersWithCredits({ loading: true, providers: [] });
      fetchAllAPIPricing().then((apiPricing: any) => {
        if (!providersPayload) return;
        const providers = providersPayload.map((provider: any) => {
          const pricing = apiPricing.api_pricing_pans.find((api: any) => api.id === provider.apiPricingId);
          return {
            id: provider.id || provider.name,
            name: provider.name,
            logo: provider.logo,
            credits: pricing?.credits,
          };
        });
        setProvidersWithCredits({ loading: false, providers });
      });
    }
  }, []);

  useEffect(() => {
    // if case, only for actions with api key toggle
    if (payloads.find((input) => input.payloadStructureId === ZENROWS_PERSONAL_API_KEY_STRUCTURE_ID)) {
      const isKey =
        payloads.find((input) => input.payloadStructureId === ZENROWS_PERSONAL_API_KEY_STRUCTURE_ID)?.inputString ||
        false;
      setIsUserKeyEnable(isKey);
      if (isKey) setIsConnectionExists(userConnection ? (userConnection?.userConnection ? true : false) : false);
    }
  }, [isUserKeyEnable]);

  useEffect(() => {
    if (
      payloads &&
      payloads.find((input) => input.payloadStructureId === CRUST_DATA_PERSON_ENRICH_REALTIME_STRUCTURE_ID)
    ) {
      if (
        payloads.find((input) => input.payloadStructureId === CRUST_DATA_PERSON_ENRICH_REALTIME_STRUCTURE_ID)
          ?.inputString === "Yes"
      ) {
        const providerCredits = providersWithCredits.providers.find((provider) => provider.name === "Yes")?.credits;
        console.log("providerCredits", providerCredits);

        setAdditionalCredits(providerCredits);
      }
    }
  }, [providersWithCredits]);

  useEffect(() => {
    setRunCondition(action.runCondition);
  }, [action]);

  useEffect(() => {
    setResponses(responseConfiguration);
  }, [responseConfiguration]);

  useEffect(() => {
    setPayloads(payloadConfiguration);
    setUpdateField((prev) => prev + 1);

    // Load prompts from localStorage if available
    if (action.id) {
      // Check all payload structures that might have prompts
      const newPrompts: { [key: string]: { content: any; text: string } } = {};
      action.payloadStructure.forEach((field) => {
        if (field.supports_ai_prompt) {
          const storedPrompt = getStoredPrompt(action.id, field.payloadStructureId);
          if (storedPrompt) {
            newPrompts[field.payloadStructureId] = storedPrompt;
          }

          // Also check for formula key (used for regenerated formulas)
          try {
            const formulaKey = `floq_formula_${action.id}_${field.payloadStructureId}`;
            const storedFormula = localStorage.getItem(formulaKey);

            if (storedFormula) {
              const formulaData = JSON.parse(storedFormula);

              // Update the payload with this formula
              const payloadIndex = payloadConfiguration.findIndex(
                (p) => p.payloadStructureId === field.payloadStructureId
              );

              if (payloadIndex !== -1) {
                const updatedPayloads = [...payloadConfiguration];
                updatedPayloads[payloadIndex] = {
                  ...updatedPayloads[payloadIndex],
                  tiptapJson: formulaData.tiptapJson,
                  inputString: formulaData.text,
                };

                // Update payloads state
                setPayloads(updatedPayloads);

                // Set changed flag to enable saving
                setChanged(true);
              }
            }
          } catch (e) {
            console.error("Error loading formula from localStorage:", e);
          }
        }
      });
      if (Object.keys(newPrompts).length > 0) {
        setPrompts(newPrompts);
      }
    }
  }, [payloadConfiguration, action.id]);

  // Listen for formula updates from RunModal
  useEffect(() => {
    if (!action.id) return;

    // Define a handler for formula updates
    const handleFormulaUpdate = (event: CustomEvent) => {
      const { actionId, fieldId, response, text } = event.detail;

      // Only process updates for this action
      if (actionId !== action.id) return;
      // Update the local payloads state
      setPayloads((currentPayloads) => {
        const updatedPayloads = [...currentPayloads];
        const index = updatedPayloads.findIndex((p) => p.payloadStructureId === fieldId);

        if (index !== -1) {
          updatedPayloads[index] = {
            ...updatedPayloads[index],
            tiptapJson: response,
            inputString: text,
          };
        } else {
          // Field doesn't exist yet, add it
          const fieldDef = action.payloadStructure.find((f) => f.payloadStructureId === fieldId);
          if (fieldDef) {
            updatedPayloads.push({
              payloadStructureId: fieldId,
              tiptapJson: response,
              inputString: text,
              type: fieldDef.type || "formula",
            });
          }
        }

        return updatedPayloads;
      });

      // Force refresh of the TipTap editor
      setUpdateField((prev) => prev + 1);

      // Set changed flag to enable saving
      setChanged(true);
    };

    // Define a handler for regeneration requests
    const handleRegenerationRequest = (event: CustomEvent) => {
      const { actionId, fieldId, sampleOutputs } = event.detail;

      // Only process regeneration for this action
      if (actionId !== action.id) return;

      // Get the prompt data for this field
      const promptData = prompts[fieldId];
      if (!promptData?.content) return;

      // IMPORTANT: For regeneration, we need to send the exact same TipTap JSON structure
      // as in the original generation, with sampleOutputs at the root level.
      // This ensures the formula field is populated correctly.
      const regenerationPrompt = {
        // Clone the original TipTap JSON content structure
        ...promptData.content,
        // Add sample outputs at the root level
        sampleOutputs,
      };

      // Call handleGenerateWithFeedback with this field
      handleGenerateWithFeedback(fieldId, regenerationPrompt);
    };

    // Add event listeners
    window.addEventListener("floqFormulaUpdated", handleFormulaUpdate as EventListener);
    window.addEventListener("floqRegenerationRequest", handleRegenerationRequest as EventListener);

    // Clean up
    return () => {
      window.removeEventListener("floqFormulaUpdated", handleFormulaUpdate as EventListener);
      window.removeEventListener("floqRegenerationRequest", handleRegenerationRequest as EventListener);
    };
  }, [action.id, prompts]);

  useEffect(() => {
    if (payloads !== payloadConfiguration || review !== action.reviewNeeded || runCondition !== action.runCondition) {
      // if case, only for actions with api key toggle
      if (payloads.find((input) => input.payloadStructureId === ZENROWS_PERSONAL_API_KEY_STRUCTURE_ID)) {
        const isKey =
          payloads.find((input) => input.payloadStructureId === ZENROWS_PERSONAL_API_KEY_STRUCTURE_ID)?.inputString ||
          false;
        setIsUserKeyEnable(isKey);
        if (isKey) setIsConnectionExists(userConnection ? (userConnection?.userConnection ? true : false) : false);
      }
      setChanged(true);
    } else setChanged(false);
  }, [payloads, review, runCondition]);

  const checkForPromptField = (payloads: PayloadConfiguration[]) => {
    const promptField = payloads.some((input: any) =>
      input.stepDownSearchConfig?.some((item: any) => item.apiId === "webAgentApiId")
    );
    setHasPrompt(promptField);
  };

  useEffect(() => {
    checkForPromptField(payloads);
  }, [payloads]);

  const saveOutputStructure = async (customPayloads?: PayloadConfiguration[]) => {
    setLoading(true);
    // Use provided custom payloads or the state payloads
    const payloadsToSave = customPayloads || payloads;
    const updated = updatePayloadConfig(
      payloadsToSave,
      action.id,
      review || false,
      action.continueOnFail,
      runCondition
    );
    setPayloads(updated);
    const updatedResponses = updateResponseConfig(responses, action.id);
    setResponses(updatedResponses);
    await saveWorkflowActions(id || "");
    setChanged(false);
    setLoading(false);
    setPublishWarning(true);
  };

  // Add a new function to run the first 5 rows
  const runFirstFiveRows = async () => {
    try {
      message.info("Running the first 5 rows...");
      // The runOnFloq function originally takes type: "first10", "all", "failed"
      // We'll modify the parameters to run just 5 rows
      await runOnFloq(action.id, id || "", 0, "first5");
      message.success("First 5 rows executed successfully");

      // Notify RunModal that this action has been run with generator
      // This will trigger showing the feedback buttons
      try {
        window.dispatchEvent(
          new CustomEvent("floqGeneratorRun", {
            detail: { actionId: action.id },
          })
        );
      } catch (e) {
        console.error("Error dispatching generator run event:", e);
      }
    } catch (error) {
      message.error("Failed to run the rows");
    }
  };

  const updateConnectionToStepDown = (structureId: string, apiId: string, value: boolean) => {
    const payl = payloads;
    payl.forEach((item) => {
      if (item.payloadStructureId === structureId) {
        let temp = item.stepDownSearchConfig;
        if (!temp) return;
        temp = temp.map((item) => {
          if (item.apiId === apiId) {
            item.connection = value;
          }
          return item;
        });
      }
    });
    setChanged(true);
    setPayloads(payl);
  };

  const updateStepDownOrder = (structureId: string, apiId: string, dropApiId: string, below: boolean) => {
    const payl = payloads;

    payl.forEach((item) => {
      if (item.payloadStructureId === structureId) {
        let temp = item.stepDownSearchConfig;
        if (!temp) return;
        const dropOrder = temp.find((item) => item.apiId === dropApiId)?.order;

        if (!dropOrder) return;
        const newOrder = below ? dropOrder + 1 : dropOrder;
        temp = temp.map((item) => {
          if (item.apiId === apiId) {
            item.order = newOrder;
          } else if (item.order >= newOrder) {
            item.order += 1;
          }
          return item;
        });
      }
    });
    setChanged(true);
    setPayloads(payl);
  };

  const updateStepDownConfig = (structureId: string, removedApiId: string | null, addedApiId: string | null) => {
    const payl = payloads;
    const payloadIndex = payloads.findIndex((input) => input.payloadStructureId === structureId) || 0;
    let temp = payl[payloadIndex].stepDownSearchConfig;
    if (!temp) return;
    if (removedApiId) {
      const removedOrder = temp.find((item) => item.apiId === removedApiId)?.order;
      if (!removedOrder) return;
      temp = temp.filter((item) => item.apiId !== removedApiId);
    }
    // If a node was added, place it at the end of the order
    if (addedApiId) {
      if (temp.length === 0) {
        temp.push({ apiId: addedApiId, order: 1 });
      } else {
        const maxOrder = Math.max(...temp.map((item) => item.order));
        temp.push({ apiId: addedApiId, order: maxOrder + 1 });
      }
    }

    payl[payloadIndex].stepDownSearchConfig = temp;
    setChanged(true);
    const updated = updatePayloadConfig(payl, action.id, review || false, action.continueOnFail);
    setPayloads(updated);
    checkForPromptField(updated);
  };

  const resetStepDownConfig = (structureId: string, config: any[]) => {
    const payl = payloads;
    const payloadIndex = payloads.findIndex((input) => input.payloadStructureId === structureId) || 0;
    payl[payloadIndex].stepDownSearchConfig = config;
    setChanged(true);
    const updated = updatePayloadConfig(payl, action.id, review || false, action.continueOnFail);
    setPayloads(updated);
    checkForPromptField(updated);
  };

  const checkValidVariable = (variableId: any, variables: any[]) => {
    for (const action of variables) {
      for (const variable of action.variables) {
        if (variable.responseId === variableId) return true;
      }
    }
    return false;
  };

  const checkValidExpression = (value: string): React.ReactNode => {
    try {
      let isValid = true;
      const vars = value.match(/{{(.*?)}}/g);
      if (vars) {
        vars.forEach((v: string) => {
          const responseId = v.slice(2, -2);
          if (!checkValidVariable(responseId, variables)) isValid = false;
        });
      }

      new Function(value);
      return (
        <div className="flex flex-col w-full items-start gap-y-1">
          {!isValid ? (
            <div className="flex">
              <RxCross2 className="text-red-400 mr-2" size={15} />
              <p className="text-red-400 text-xs">Invalid Data</p>
            </div>
          ) : (
            <div className="flex ">
              <IoCheckmark className="text-green-500 mr-2" size={15} />
              <p className="text-green-500 text-xs">Valid Expression</p>
            </div>
          )}
        </div>
      );
    } catch (e) {
      return (
        <div className="flex flex-row w-full items-center">
          <IoWarning className="text-red-500 mr-2" size={15} />
          <p className="text-red-500 text-xs">{(e as Error).message}</p>
        </div>
      );
    }
  };

  const fieldSwitchCase = (field: any) => {
    switch (field.type) {
      // pass the credits here
      case "stepDownSearch":
        return (
          <StepDownSearch
            structureId={field.payloadStructureId}
            updateStepDownOrder={updateStepDownOrder}
            updateConnectionToStepDown={updateConnectionToStepDown}
            updateStepDownConfig={updateStepDownConfig}
            resetStepDownConfig={resetStepDownConfig}
            stepDownAPIs={field.stepDownAPIs || []}
            payloadConfig={
              payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.stepDownSearchConfig ||
              []
            }
            action={action}
            credits={typeof action.credits === "object" ? action.credits : undefined}
            setIsConnectionExists={setIsConnectionExists}
          />
        );
      case "formula":
        return (
          <div className="flex flex-col w-full h-full">
            <TiptapField
              NotificationNode={checkValidExpression(
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""
              )}
              content={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.tiptapJson || ""
              }
              setContent={(content: any, text: string) => {
                // Update local state for prompts
                setPrompts((prev) => ({
                  ...prev,
                  [field.payloadStructureId]: { content, text },
                }));
                // Store prompt in localStorage for persistence
                if (action.id) storePrompt(action.id, field.payloadStructureId, { content, text });

                // Update payloads state as well
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                if (index === -1) {
                  temp.push({
                    payloadStructureId: field.payloadStructureId,
                    tiptapJson: content,
                    inputString: text,
                    type: field.type,
                  });
                } else {
                  temp[index].tiptapJson = content;
                  temp[index].inputString = text;
                }
                setPayloads(temp);

                setChanged(true);
              }}
              refresh={updateField}
              placeholder="Enter the value"
              variables={variables}
            />
          </div>
        );
      case "array":
        return (
          <ArrayField
            arr={
              payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString.length > 0
                ? payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString
                : [""]
            }
            setContent={(idx: number, content: string) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: [content],
                  type: field.type,
                });
              } else {
                temp[index].inputString[idx] = content;
              }
              setPayloads(temp);
            }}
            add={() => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) return;
              temp[index].inputString.push("");
              setPayloads(temp);
            }}
            description={field.description}
          />
        );
      case "dropdownWithCredits":
        return (
          <Skeleton active loading={providersWithCredits.loading} paragraph={{ rows: 1 }} title={{ width: "100%" }}>
            <Select
              suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
              value={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString ||
                field.values.find((value: any) => value?.default)?.id ||
                field.values.find((value: any) => value?.default)?.name ||
                ""
              }
              showSearch
              // allowClear
              optionFilterProp="name"
              onChange={(value) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field?.payloadStructureId);
                if (index === -1) {
                  temp.push({
                    payloadStructureId: field.payloadStructureId,
                    inputString: value,
                  });
                } else temp[index].inputString = value;
                if (value === "Yes") {
                  providersWithCredits.providers.forEach((provider: any) => {
                    if (provider.name === "Yes") {
                      setAdditionalCredits(provider.credits);
                    }
                  });
                } else {
                  setAdditionalCredits(0);
                }
                setPayloads(temp);
              }}
              // display providersWithCredits.providers here
              options={(providersWithCredits.providers ?? []).map((value: any) => ({
                label: (
                  <div className="flex justify-between items-center gap-2 p-1 w-full">
                    <div className="flex items-center gap-2 justify-end text-ellipsis">
                      {value?.logo && <img src={value?.logo} alt={value?.name} className="w-6 h-6" />}
                      <p>{value?.name}</p>
                    </div>
                    {value?.credits >= 0 ? (
                      <div className="flex items-center gap-1 justify-end">
                        <span className="text-xs">
                          <div className="flex items-center justify-center gap-1">
                            <Coins width="18" height="22" />
                            <span className="font-bold text-md text-black">{value?.credits}</span>
                          </div>
                        </span>
                      </div>
                    ) : null}
                  </div>
                ),
                value: value?.id || value.name,
                name: value?.name,
              }))}
            />
          </Skeleton>
        );
      case "dropdown":
        return (
          <Select
            suffixIcon={
              field?.required ? (
                <RiExpandUpDownLine className="w-5 h-5 text-[#999]" />
              ) : (
                <RiArrowDownSLine className="w-5 h-5 pl-[3px] text-[#999]" />
              )
            }
            value={
              payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString ||
              field.values.find((value: any) => value?.default)?.id ||
              field.values.find((value: any) => value?.default)?.name ||
              ""
            }
            allowClear={{
              clearIcon: <MdClear className="w-4 h-4 text-[#999]" />,
            }}
            showSearch
            optionFilterProp="name"
            onChange={(value) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field?.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: value,
                });
              } else temp[index].inputString = value;
              setPayloads(temp);
            }}
            options={(field.values ?? []).map((value: any) => ({
              label: (
                <div className="flex justify-between items-center gap-2 p-1 w-full">
                  <div className="flex items-center gap-2 justify-end text-ellipsis">
                    {value?.logo && <img src={value?.logo} alt={value?.name} className="w-6 h-6" />}
                    <p>{value?.name}</p>
                  </div>
                </div>
              ),
              value: value?.id || value.name,
              name: value?.name,
            }))}
          />
        );
      case "multiDropdown":
        return (
          <Select
            // suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || []}
            showSearch
            optionFilterProp="name"
            mode="multiple"
            onChange={(value) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field?.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: value,
                });
              } else temp[index].inputString = value;
              setPayloads(temp);
            }}
            options={field.values.map((value: any) => ({
              label: (
                <div className="flex justify-between items-center gap-2 p-1 w-full">
                  <div className="flex items-center gap-2 justify-end text-ellipsis">
                    {value?.logo && <img src={value?.logo} alt={value?.name} className="w-6 h-6" />}
                    <p>{value?.name}</p>
                  </div>
                </div>
              ),
              value: value?.id || value.name,
              name: value?.name,
            }))}
          />
        );
      case "countries":
        return (
          <Select
            suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || []}
            showSearch
            mode="multiple"
            optionFilterProp="name"
            onChange={(value) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field?.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: value,
                });
              } else temp[index].inputString = value;
              setPayloads(temp);
            }}
            options={countriesDropdown.map((value: any) => ({
              label: (
                <div className="flex justify-between items-center gap-2 p-1 w-full">
                  <div className="flex items-center gap-2 justify-end text-ellipsis">
                    {value?.logo && <img src={value?.logo} alt={value?.name} className="w-6 h-6" />}
                    <p>{value?.name}</p>
                  </div>
                </div>
              ),
              value: value?.id || value.name,
              name: value?.name,
            }))}
          />
        );
      case "youtube_countries":
        return (
          <Skeleton active loading={youtubeCountries.loading} paragraph={{ rows: 1 }} title={{ width: "100%" }}>
            <Select
              suffixIcon={<RiArrowDownSLine className="w-5 h-5 pl-[3px] text-[#999]" />}
              showSearch
              allowClear={{
                clearIcon: <MdClear className="w-4 h-4 text-[#999]" />,
              }}
              defaultValue={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""
              }
              onChange={(value) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                temp[index].inputString = value;
                setPayloads(temp);
              }}
              optionFilterProp="label"
              options={youtubeCountries.countries?.map((value: any) => ({
                label: value.displayName,
                value: value.value,
              }))}
            />
          </Skeleton>
        );
      case "technologies":
        return (
          <Skeleton active loading={technologies.loading} paragraph={{ rows: 1 }} title={{ width: "100%" }}>
            <Select
              mode="multiple"
              showSearch
              defaultValue={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || []
              }
              onChange={(value) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                temp[index].inputString = value;
                setPayloads(temp);
              }}
              optionFilterProp="label"
              options={technologies.technologies?.map((value: any) => ({
                label: value.displayName,
                value: value.value,
              }))}
            />
          </Skeleton>
        );
      case "languages":
        return (
          <Skeleton active loading={languages.loading} paragraph={{ rows: 1 }} title={{ width: "100%" }}>
            <Select
              suffixIcon={<RiArrowDownSLine className="w-5 h-5 pl-[3px] text-[#999]" />}
              showSearch
              allowClear={{
                clearIcon: <MdClear className="w-4 h-4 text-[#999]" />,
              }}
              defaultValue={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""
              }
              onChange={(value) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                temp[index].inputString = value;
                setPayloads(temp);
              }}
              optionFilterProp="label"
              options={languages.languages?.map((value: any) => ({
                label: value.displayName,
                value: value.value,
              }))}
            />
          </Skeleton>
        );
      case "slider":
        return (
          <div className="flex gap-4 items-center">
            <Slider
              min={0}
              max={1}
              step={0.01}
              style={{ width: "100%" }}
              value={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || 0.5
              }
              onChange={(e) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                if (index === -1) {
                  temp.push({
                    payloadStructureId: field.payloadStructureId,
                    inputString: e,
                  });
                } else {
                  temp[index].inputString = e;
                }
                setPayloads(temp);
              }}
            />
            <InputNumber
              min={0}
              max={1}
              step={0.01}
              style={{ width: "20%" }}
              placeholder="0.50"
              value={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || 0.5
              }
              onChange={(e) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                if (index === -1) {
                  temp.push({
                    payloadStructureId: field.payloadStructureId,
                    inputString: e,
                    type: field.type,
                  });
                } else {
                  temp[index].inputString = e;
                }
                setPayloads(temp);
              }}
            />
          </div>
        );
      case "radio":
        return (
          <Switch
            className="w-fit "
            defaultChecked={
              payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString === true
            }
            onChange={(value) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) return;
              temp[index].inputString = value;
              setPayloads(temp);
            }}
          />
        );
      case "jsonArray":
        return (
          <div className="flex flex-col gap-4">
            {payloads
              .find((input) => input.payloadStructureId === field.payloadStructureId)
              ?.inputString.map((input: any, idx: number) => {
                return (
                  <div className="" key={idx}>
                    <input
                      type="text"
                      className="w-full rounded-lg"
                      placeholder="Enter the name"
                      value={input.name}
                      onChange={(e) => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        if (index === -1) return;
                        temp[index].inputString[idx].name = e.target.value;
                        setPayloads(temp);
                      }}
                    />
                    <TiptapField
                      content={
                        payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString[
                          idx
                        ].tiptapJson || ""
                      }
                      setContent={(content: any, text: string) => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        if (index === -1) return;
                        temp[index].inputString[idx].value = text;
                        temp[index].inputString[idx].tiptapJson = content;
                        setPayloads(temp);
                      }}
                      refresh={updateField}
                      placeholder="Enter the value"
                      variables={variables}
                    />
                  </div>
                );
              })}
            <button
              className="text-primary flex gap-2 items-center"
              onClick={() => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                if (index === -1) return;
                temp[index].inputString.push({
                  name: "Variable Name",
                });
                setPayloads(temp);
              }}
            >
              <FaPlus />
              Add a new Variable Name and Variable Value pair
            </button>
          </div>
        );
      case "promptField":
        if (hasPrompt) {
          return (
            <TiptapField
              content={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.tiptapJson || ""
              }
              setContent={(content: any, text: string) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                if (index === -1) {
                  temp.push({
                    payloadStructureId: field.payloadStructureId,
                    tiptapJson: content,
                    inputString: text,
                    type: field.type,
                  });
                } else {
                  temp[index].tiptapJson = content;
                  temp[index].inputString = text;
                }
                setPayloads(temp);
              }}
              refresh={updateField}
              placeholder={field.name}
              variables={variables}
            />
          );
        }
        return null;
      case "input_variables_array":
        return (
          <Select
            suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || []}
            showSearch
            optionFilterProp="name"
            // mode="multiple"
            onChange={(value) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field?.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: value,
                });
              } else temp[index].inputString = value;
              setPayloads(temp);
            }}
            options={variables
              .filter((item) => INCLUDED_ACTIONS_AUTO_DEDUPE.includes(item.actionName))
              .flatMap((item) =>
                item.variables
                  .filter(
                    (variable: any) => ALLOWED_TYPES_AUTO_DEDUPE.includes(variable.type?.toLowerCase()) // Case-insensitive check
                  )
                  .map((variable: any) => ({
                    label: variable.name, // Display variable name
                    value: variable.responseId, // Use responseId as value
                    type: variable.type, // Keep the type
                  }))
              )}
          />
        );
      case "action_variables_array":
        return (
          <Select
            suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || []}
            showSearch
            optionFilterProp="name"
            mode="multiple"
            onChange={(value) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field?.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: value,
                });
              } else temp[index].inputString = value;
              setPayloads(temp);
            }}
            options={variables
              .filter((item) => item.id !== "id1")
              .flatMap((item) =>
                item.variables.map((variable: any) => ({
                  label: variable.name, // Display variable name
                  value: variable.responseId, // Use responseId as value
                }))
              )}
          />
        );
      case "multiSectionLists":
        return (
          <Select
            placeholder={`Select ${field.name}`}
            defaultValue={
              payloads.find((payload) => payload.payloadStructureId === field.payloadStructureId)?.inputString || []
            }
            mode="multiple"
            onChange={(value) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field?.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: value,
                });
              } else temp[index].inputString = value;
              setPayloads(temp);
            }}
            optionFilterProp="label"
            options={variables
              .filter(
                (action) =>
                  action.actionName.includes("EMPLOYEES") &&
                  action.variables.some((variable: any) => variable.type === "sectionList")
              )
              .map((variable) => {
                return {
                  label: (
                    <div className="flex gap-2">
                      <img src={variable.logo} alt={variable.name} className="w-4 h-4" />
                      <span>{variable.name}</span>
                    </div>
                  ),
                  title: variable.name,
                  options: variable.variables
                    .filter((variable: any) => variable.type === "sectionList")
                    .map((v: any, idx: number) => {
                      return {
                        label: (
                          <div
                            key={idx}
                            className={`px-2 flex items-center gap-2 rounded-lg
                    ${variable.type === "input" ? "bg-[#FFF3D4] text-[#AA7102]" : "bg-[#E3EDFF] text-[#0047C6]"}`}
                          >
                            <img
                              src={variable.type === "input" ? YellowVarLogo : BlueVarLogo}
                              alt={v.name}
                              className="w-4 h-4"
                            />
                            {v.name}
                          </div>
                        ),
                        value: v.responseId,
                        name: v.name,
                      };
                    }),
                };
              })}
          />
        );
      case "industries":
        return (
          <Skeleton active loading={clayIndustries.loading} paragraph={{ rows: 1 }} title={{ width: "100%" }}>
            <Select
              suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
              value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || []}
              showSearch
              mode="multiple"
              onChange={(value) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field?.payloadStructureId);
                if (index === -1) {
                  temp.push({
                    payloadStructureId: field.payloadStructureId,
                    inputString: value,
                  });
                } else temp[index].inputString = value;
                setPayloads(temp);
              }}
              optionFilterProp="label"
              options={clayIndustries?.industries?.map((value: any) => ({
                label: value?.name,
                value: value?.id || value.name,
              }))}
            />
          </Skeleton>
        );
      case "clay_countries":
        return (
          <Skeleton active loading={clayCountries.loading} paragraph={{ rows: 1 }} title={{ width: "100%" }}>
            <Select
              suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
              value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || []}
              showSearch
              mode="multiple"
              onChange={(value) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field?.payloadStructureId);
                if (index === -1) {
                  temp.push({
                    payloadStructureId: field.payloadStructureId,
                    inputString: value,
                  });
                } else temp[index].inputString = value;
                setPayloads(temp);
              }}
              optionFilterProp="label"
              options={clayCountries?.countries?.map((value: any) => ({
                label: value?.name,
                value: value?.id || value.name,
              }))}
            />
          </Skeleton>
        );
      case "clay_locations":
        return (
          <Skeleton active loading={clayLocations.loading} paragraph={{ rows: 1 }} title={{ width: "100%" }}>
            <Select
              suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
              value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || []}
              showSearch
              mode="multiple"
              // onChange={(value) => {
              //   const temp = [...payloads];
              //   const index = payloads.findIndex((input) => input.payloadStructureId === field?.payloadStructureId);
              //   if (index === -1) {
              //     temp.push({
              //       payloadStructureId: field.payloadStructureId,
              //       inputString: value,
              //     });
              //   } else temp[index].inputString = value;
              //   setPayloads(temp);
              // }}
              onChange={(value) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                temp[index].inputString = value;
                setPayloads(temp);
              }}
              optionFilterProp="label"
              options={clayLocations?.locations?.map((value: any) => ({
                label: value?.value,
                value: value?.value,
              }))}
            />
          </Skeleton>
        );
      case "clay_cities":
        return (
          <Skeleton active loading={clayCities.loading} paragraph={{ rows: 1 }} title={{ width: "100%" }}>
            <Select
              suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
              value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || []}
              showSearch
              mode="multiple"
              onChange={(value) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field?.payloadStructureId);
                if (index === -1) {
                  temp.push({
                    payloadStructureId: field.payloadStructureId,
                    inputString: value,
                  });
                } else temp[index].inputString = value;
                setPayloads(temp);
              }}
              optionFilterProp="label"
              options={clayCities?.cities?.map((value: any) => ({
                label: value?.value,
                value: value?.value,
              }))}
            />
          </Skeleton>
        );
      case "clay_states":
        return (
          <Skeleton active loading={clayStates.loading} paragraph={{ rows: 1 }} title={{ width: "100%" }}>
            <Select
              suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
              value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || []}
              showSearch
              mode="multiple"
              onChange={(value) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field?.payloadStructureId);
                if (index === -1) {
                  temp.push({
                    payloadStructureId: field.payloadStructureId,
                    inputString: value,
                  });
                } else temp[index].inputString = value;
                setPayloads(temp);
              }}
              optionFilterProp="label"
              options={clayStates?.states?.map((value: any) => ({
                label: value?.value,
                value: value?.value,
              }))}
            />
          </Skeleton>
        );
      default:
        return (
          <textarea
            className="w-full h-32 rounded-lg"
            placeholder="Enter the value"
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""}
            onChange={(e) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: e.target.value,
                  type: field.type,
                });
              } else {
                temp[index].inputString = e.target.value;
              }
              setPayloads(temp);
            }}
          />
        );
    }
  };

  // Add effect to check for regeneration status
  useEffect(() => {
    if (!action?.id) return;

    let wasRegenerating = false;

    const checkRegenerationStatus = () => {
      try {
        const isRegenerating = localStorage.getItem(`floq_regenerating_${action.id}`) === "true";

        // If regeneration just completed, save the changes
        if (wasRegenerating && !isRegenerating) {
          // Even if changed is false, we should check if there's a stored formula
          const formulaField = action.payloadStructure.find((field) => field.supports_ai_prompt);
          if (formulaField) {
            try {
              const formulaKey = `floq_formula_${action.id}_${formulaField.payloadStructureId}`;
              const storedFormula = localStorage.getItem(formulaKey);

              if (storedFormula) {
                const formulaData = JSON.parse(storedFormula);
                // Update payloads with this formula and save
                const payloadIndex = payloads.findIndex(
                  (p) => p.payloadStructureId === formulaField.payloadStructureId
                );

                if (payloadIndex !== -1) {
                  const updatedPayloads = [...payloads];
                  updatedPayloads[payloadIndex] = {
                    ...updatedPayloads[payloadIndex],
                    tiptapJson: formulaData.tiptapJson,
                    inputString: formulaData.text,
                  };

                  // Save these updated payloads
                  saveOutputStructure(updatedPayloads);

                  // Remove the temporary formula key since we've saved it properly
                  localStorage.removeItem(formulaKey);
                }
              } else if (changed)
                // If no stored formula but changes exist, save anyway
                saveOutputStructure();
            } catch (e) {
              if (changed) saveOutputStructure(); // Fallback
            }
          } else if (changed)
            // If no formula field found but changes exist, save anyway
            saveOutputStructure();
        }

        wasRegenerating = isRegenerating;
        setRegenerating(isRegenerating);
      } catch (e) {
        console.error("Error checking regeneration status:", e);
      }
    };

    // Check immediately
    checkRegenerationStatus();

    // And set up interval to check regularly
    const intervalId = setInterval(checkRegenerationStatus, 1000);
    return () => clearInterval(intervalId);
  }, [action?.id, changed, payloads]);

  // Add a new function that handles generation with feedback
  const handleGenerateWithFeedback = async (fieldId: string, customPrompt?: any) => {
    // Set regenerating state if this is a regeneration request
    const isRegeneration = !!customPrompt?.sampleOutputs;
    if (isRegeneration) {
      setRegenerating(true);
    } else {
      setIsGenerating((prev) => ({ ...prev, [fieldId]: true }));
    }

    try {
      // Get prompt data - either from the custom prompt or from state
      const promptData = customPrompt || prompts[fieldId];
      if (!promptData) {
        message.error("Please enter a prompt first");
        return;
      }

      // Store the original prompt in localStorage (without sample outputs)
      if (action.id && !isRegeneration) storePrompt(action.id, fieldId, promptData);
      // Log the actual API payload for debugging
      const apiPayload = isRegeneration ? customPrompt : promptData.content;

      // Make API call with the appropriate payload
      const response = await dataFormatterPrompt(id || "", apiPayload);

      if (response?.response) {
        // Update the formula field with the response
        const temp = [...payloads];
        const index = payloads.findIndex((input) => input.payloadStructureId === fieldId);

        if (index !== -1) {
          // Get the field definition to ensure we have the correct type
          const fieldDef = action.payloadStructure.find((f) => f.payloadStructureId === fieldId);
          // Extract text from TipTap JSON
          const extractedText = extractTextFromTipTap(response.response) || "";
          // If this is a regeneration, update the prompt in localStorage too
          if (isRegeneration && action.id) {
            // Store the updated response
            const currentPrompt = getStoredPrompt(action.id, fieldId);
            if (currentPrompt) {
              // Keep the original prompt but update with new result
              storePrompt(action.id, fieldId, {
                ...currentPrompt,
                // Don't store the feedback samples, just the original content
                content: currentPrompt.content,
                text: currentPrompt.text,
                // Store the regenerated response separately
                regeneratedContent: response.response,
                regeneratedText: extractedText,
              });

              // Also update the prompts state to ensure TipTap field shows correct data
              if (fieldId in prompts) {
                // Update the prompts state for the formula field
                const updatedPromptData = {
                  ...prompts[fieldId],
                  regeneratedContent: response.response,
                  regeneratedText: extractedText,
                };

                setPrompts((prev) => ({
                  ...prev,
                  [fieldId]: updatedPromptData,
                }));
              }
            }
          }

          temp[index] = {
            ...temp[index],
            // The response field contains the TipTap JSON
            tiptapJson: response.response,
            // Extract text from TipTap JSON for inputString
            inputString: extractedText,
            type: fieldDef?.type || temp[index].type || "formula",
          };
          // Save the updated payloads (update local state for UI feedback)
          setPayloads([...temp]);

          // Force a stronger refresh for the TipTap editor if regenerating
          if (isRegeneration)
            setTimeout(() => {
              setUpdateField((prev) => prev + 2);
            }, 100);
          else
            // Standard refresh for normal generation
            setUpdateField((prev) => prev + 1);
          setChanged(true);
          message.success(isRegeneration ? "Formula regenerated successfully" : "Formula generated successfully");

          // Sleep for 1 second
          await new Promise((resolve) => setTimeout(resolve, 1000));

          // sleep for 1 second
          await new Promise((resolve) => setTimeout(resolve, 1000));
          // Directly pass the updated payloads to saveOutputStructure
          await saveOutputStructure([...temp]);

          // Record that this action has been run with a generator
          try {
            localStorage.setItem(`floq_generator_run_${action.id}`, "true");
          } catch (e) {
            console.error("Error setting generator run status:", e);
          }

          if (isRegeneration && action.id) {
            // Dispatch event to indicate regeneration is complete
            window.dispatchEvent(
              new CustomEvent("floqRegenerationComplete", {
                detail: { actionId: action.id, success: true },
              })
            );

            // Clear regeneration status from localStorage
            localStorage.removeItem("regeneratingField");
          } else if (!isRegeneration) {
            // Only open run modal and run rows for initial generation, not regeneration
            // Open the Run in Floq modal
            setAction(action);
            setOpenRun(true);

            // Run the first 5 rows after saving
            await runFirstFiveRows();
          }
        } else {
          // If the field doesn't exist yet, create it
          const fieldDef = action.payloadStructure.find((f) => f.payloadStructureId === fieldId);
          if (fieldDef) {
            const extractedText = extractTextFromTipTap(response.response) || "";

            // If this is a regeneration, update the prompt in localStorage too
            if (isRegeneration && action.id) {
              // Store the updated response
              const currentPrompt = getStoredPrompt(action.id, fieldId);
              if (currentPrompt) {
                // Keep the original prompt but update with new result
                storePrompt(action.id, fieldId, {
                  ...currentPrompt,
                  // Don't store the feedback samples, just the original content
                  content: currentPrompt.content,
                  text: currentPrompt.text,
                  // Store the regenerated response separately
                  regeneratedContent: response.response,
                  regeneratedText: extractedText,
                });

                // Also update the prompts state to ensure TipTap field shows correct data
                if (fieldId in prompts) {
                  // Update the prompts state for the formula field
                  const updatedPromptData = {
                    ...prompts[fieldId],
                    regeneratedContent: response.response,
                    regeneratedText: extractedText,
                  };

                  setPrompts((prev) => ({
                    ...prev,
                    [fieldId]: updatedPromptData,
                  }));
                }
              }
            }

            temp.push({
              payloadStructureId: fieldId,
              tiptapJson: response.response,
              inputString: extractedText,
              type: fieldDef.type || "formula",
            });

            // Save the updated payloads (update local state for UI feedback)
            setPayloads([...temp]);
            // Force a stronger refresh for the TipTap editor if regenerating
            if (isRegeneration)
              setTimeout(() => {
                setUpdateField((prev) => prev + 2);
              }, 100);
            else
              // Standard refresh for normal generation
              setUpdateField((prev) => prev + 1);

            // Directly pass the updated payloads to saveOutputStructure
            await saveOutputStructure([...temp]);

            if (!isRegeneration) {
              // Only open run modal and run rows for initial generation, not regeneration
              // Open the Run in Floq modal
              setAction(action);
              setOpenRun(true);
              // Run the first 5 rows after saving
              await runFirstFiveRows();
            }
          } else message.error("Failed to update formula field");
        }
      } else message.error("Invalid response from server");
    } catch (error) {
      message.error("Failed to generate formula");
      // If this was a regeneration attempt, dispatch a completion event with error
      if (isRegeneration && action.id) {
        try {
          window.dispatchEvent(
            new CustomEvent("floqRegenerationComplete", {
              detail: { actionId: action.id, success: false, error: error },
            })
          );
        } catch (e) {
          console.error("Error dispatching regeneration complete event:", e);
        }
      }
    } finally {
      if (isRegeneration) {
        setRegenerating(false);
        // Clear regeneration status in localStorage
        try {
          localStorage.removeItem(`floq_regenerating_${action.id}`);
        } catch (e) {
          console.error("Error clearing regeneration status:", e);
        }
      } else {
        setIsGenerating((prev) => ({ ...prev, [fieldId]: false }));
      }
    }
  };

  const handleGenerate = async (fieldId: string) => {
    // Check if regeneration is in progress
    if (regenerating) {
      message.warning("Please wait for the current regeneration to complete");
      return;
    }

    // Call the shared function without custom prompt (regular generation)
    await handleGenerateWithFeedback(fieldId);
  };

  // Helper function to extract text from TipTap JSON
  const extractTextFromTipTap = (tiptapJson: any): string => {
    if (!tiptapJson || !tiptapJson.content) return "";

    let text = "";
    const extractFromNode = (node: any) => {
      if (node.type === "text") {
        text += node.text;
      } else if (node.type === "customTag" && node.attrs) {
        text += `{{${node.attrs.id}}}`;
      }

      if (node.content && Array.isArray(node.content)) {
        node.content.forEach(extractFromNode);
      }
    };

    tiptapJson.content.forEach(extractFromNode);
    return text;
  };

  // Set drawer instance ID on component mount to ensure unique prompt storage per drawer open
  useEffect(() => {
    if (modal && action?.id) {
      // Generate a new unique ID for this drawer instance
      const drawerInstanceId = Date.now().toString();
      localStorage.setItem(`floq_current_drawer_instance_${action.id}`, drawerInstanceId);

      // Clear any previous prompts for this action when opening a new drawer
      // setPrompts({});
    }
  }, [modal, action?.id]);

  // Add cleanup function to reset prompts when modal closes
  useEffect(() => {
    return () => {
      if (!modal && action?.id) {
        // Clear prompts when modal closes
        // setPrompts({});
      }
    };
  }, [modal, action?.id]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={modal}
        onClose={() => setModal(false)}
        width={drawerWidth}
        styles={{
          content: {
            transition: "width 0.3s",
          },
          body: {
            padding: collapseConfig ? "0" : "0",
          },
        }}
        classNames={{ header: !collapseConfig ? "border-b !border-gray-300" : "!border-none" }}
        mask={false}
        className="ml-auto !font-favorit !p-0 border-l border-gray-500"
        getContainer={false}
        footer={
          !collapseConfig && (
            <div className="w-full flex flex-col gap-4 bg-white">
              {action.reviewAllowed && (
                <div className="flex gap-2 items-center">
                  <input type="checkbox" checked={review} onChange={() => setReview(!review)} />
                  <span className="font-medium">
                    Enable this if you'd like to review before moving on to the next step
                  </span>
                </div>
              )}
              {!isConnectionExists &&
              (payloads.find((input) => input.payloadStructureId === ZENROWS_PERSONAL_API_KEY_STRUCTURE_ID)
                ? isUserKeyEnable
                : true) &&
              !isConnectionLoading ? (
                <Alert
                  message={
                    <>
                      Please add the required connection to proceed.{" "}
                      <Link to="/connections" target="_blank" rel="noopener noreferrer">
                        <i>Click here</i>
                      </Link>
                    </>
                  }
                  type="warning"
                  showIcon
                />
              ) : null}

              <div className="flex flex-row items-center justify-between">
                {!isUserKeyEnable && (
                  <span className="text-sm mt-1 font-light text-gray-400 flex-col">
                    {typeof action.credits === "number" && (
                      <div className="flex items-center gap-2 ml-2 bg-purple-100 hover:bg-purple-200 transition-colors duration-200 rounded-lg p-1 shadow-sm">
                        <div className="flex items-center">
                          <span className="font-semibold text-purple-900">
                            {action.credits + (additionalCredits || 0)}
                          </span>
                          <FaCoins className="ml-1.5 text-purple-600 w-3 h-3" />
                        </div>

                        <div className="flex items-center text-purple-800">
                          <span className="mr-1 text-purple-400">/</span>
                          {action.billingUnit ? (
                            <div className="text-xs font-light pr-1">{action.billingUnit}</div>
                          ) : (
                            <div className="text-xs font-light flex flex-col pr-1">
                              <span>row</span>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </span>
                )}
                <button
                  className={` text-white font-semibold rounded-lg p-2 mt-auto ${
                    loading ||
                    !changed ||
                    (!isConnectionExists &&
                      (payloads.find((input) => input.payloadStructureId === ZENROWS_PERSONAL_API_KEY_STRUCTURE_ID)
                        ? isUserKeyEnable
                        : true) &&
                      !isConnectionLoading)
                      ? "cursor-not-allowed bg-gray-500"
                      : "bg-primary cursor-pointer"
                  }
                 ${!isUserKeyEnable ? "w-[30%]" : "w-full"}
                `}
                  onClick={() => saveOutputStructure()}
                  disabled={
                    loading ||
                    !changed ||
                    (!isConnectionExists &&
                      (payloads.find((input) => input.payloadStructureId === ZENROWS_PERSONAL_API_KEY_STRUCTURE_ID)
                        ? isUserKeyEnable
                        : true) &&
                      !isConnectionLoading)
                  }
                >
                  {loading ? "Saving..." : !changed ? "Saved" : "Save"}
                </button>
              </div>
            </div>
          )
        }
        title={
          !collapseConfig && (
            <div className="flex items-center gap-2">
              <button className="p-0 border border-gray-300 rounded-sm" onClick={() => setCollapseConfig(true)}>
                <MdChevronRight size={25} />
              </button>
              <img src={action.logo} alt={action.name} className="w-6 h-6" />
              <span
                id="actionName"
                contentEditable={changeName}
                className={changeName ? "border border-black px-2" : ""}
                suppressContentEditableWarning
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.target.blur();
                  }
                }}
                onBlur={async (e) => {
                  if (!e.target.textContent) return;
                  setLoading(true);
                  updateActionName(action.id, e.target.textContent || action.name);
                  setChangeName(false);
                  await saveOutputStructure();
                  setChanged(false);
                  setLoading(false);
                  setPublishWarning(true);
                }}
              >
                {action.name}
              </span>
              <button
                onClick={() => {
                  setChangeName(!changeName);
                  const p = document.getElementById("actionName");
                  if (!p) return;
                  setTimeout(function () {
                    const range = document.createRange();
                    const selection = window.getSelection();
                    range.selectNodeContents(p);
                    range.collapse(false); // <-- Set the cursor at the end of the selection
                    selection?.removeAllRanges();
                    selection?.addRange(range);
                    p.focus();
                  }, 0);
                }}
                className={`p-2 rounded-md transition-opacity duration-600 ease-in-out hover:bg-gray-200 ${
                  changeName ? "opacity-50" : "opacity-100"
                }`}
              >
                <FaRegEdit />
              </button>
            </div>
          )
        }
      >
        {collapseConfig ? (
          <button
            className="w-full h-full flex flex-col items-center justify-center p-3 gap-3"
            onClick={() => setCollapseConfig(false)}
          >
            <span className="p-0 border border-gray-300 rounded-sm">
              <MdChevronLeft size={25} />
            </span>
            <p className="[writing-mode:sideways-lr] text-center">CONFIG</p>
          </button>
        ) : (
          // <div className="flex">
          // <ResizeableButton onSizeChange={(percentage) => setDrawerWidth(percentage)} />
          <div className="bg-white gap-10 flex flex-col p-[24px]">
            {action.payloadStructure.map((field, idx) => {
              return (
                <div key={idx} className="flex flex-col w-full gap-4 p-4 border border-gray-300 rounded-lg">
                  <div>
                    <p className="tracking-primary font-semibold">
                      {field.name.toUpperCase()}
                      {!field.required && (
                        <span className="text-sm font-normal tracking-normal text-gray-400"> (Optional)</span>
                      )}
                    </p>
                    <p className="text-sm font-light">{field.description}</p>
                  </div>
                  {field.name === "Data Formatter" && (
                    <>
                      {field.supports_ai_prompt && (
                        <div className="flex flex-col gap-3 mb-6">
                          <div className="mb-1">
                            <p className="text-sm font-light text-gray-600">What should your formula do?</p>
                            {regenerating && (
                              <div className="flex items-center mt-1 text-purple-600">
                                <SpinnerStatus />
                                <span className="ml-2 text-sm">Regenerating formula based on feedback...</span>
                              </div>
                            )}
                          </div>
                          <div className="flex flex-col gap-3">
                            <TiptapField
                              content={prompts[field.payloadStructureId]?.content || ""}
                              setContent={(content: any, text: string) => {
                                // Update local state
                                setPrompts((prev) => ({
                                  ...prev,
                                  [field.payloadStructureId]: { content, text },
                                }));
                                // Store prompt in localStorage for persistence
                                if (action.id) storePrompt(action.id, field.payloadStructureId, { content, text });
                                setChanged(true);
                              }}
                              refresh={updateField}
                              placeholderText="Describe the data transformation you need, e.g., 'Convert employee names to uppercase' or 'Calculate total from the price and quantity variables'"
                              variables={variables}
                            />
                            <div className="flex justify-end mt-1">
                              <AIButton
                                text="Generate"
                                loading={isGenerating[field.payloadStructureId] || regenerating}
                                onClick={() => handleGenerate(field.payloadStructureId)}
                                disabled={!prompts[field.payloadStructureId]?.text || regenerating}
                              />
                            </div>
                            {/* add a seperator */}
                            <div className="w-full h-[1px] bg-gray-300"></div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {field.supports_ai_prompt && field.name === "Data Formatter" && (
                    <div className="flex flex-col gap-3">
                      <div className="mb-1">
                        <p className="text-lg">or write your own formula</p>
                      </div>
                    </div>
                  )}
                  {field.needVars ? (
                    <TiptapField
                      content={
                        payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.tiptapJson ||
                        ""
                      }
                      setContent={(content: any, text: string) => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        if (index === -1) {
                          temp.push({
                            payloadStructureId: field.payloadStructureId,
                            tiptapJson: content,
                            inputString: text,
                            type: field.type,
                          });
                        } else {
                          temp[index].tiptapJson = content;
                          temp[index].inputString = text;
                        }
                        setPayloads(temp);
                      }}
                      refresh={updateField}
                      placeholder={field.name}
                      variables={variables}
                    />
                  ) : (
                    fieldSwitchCase(field)
                  )}
                </div>
              );
            })}
            <div>
              {action.actionName === "WORKFLOW_AUTO_DEDUPE"
                ? "Continue workflow even if Duplicate:"
                : "Continue workflow even if this fails:"}{" "}
              <Switch
                defaultChecked={action.continueOnFail}
                onChange={(value) => {
                  setChanged(true);
                  action.continueOnFail = value;
                }}
              />
            </div>
            <RunCondition
              variables={variables}
              responses={action.responseStructure}
              conditionsArr={
                runCondition || {
                  conditions: [],
                  otherwise: [],
                }
              }
              setConditionsArr={setRunCondition}
            />
            <ResponseStructureViewer
              setResponses={setResponses}
              responses={responses}
              action={action}
              setChanged={setChanged}
            />
          </div>
          // </div>
        )}
      </Drawer>
    </ConfigProvider>
  );
};

export default ActionModal;

import { setStateType } from "../../../utils/constants";
import { useEffect, useState, useCallback } from "react";
import { useWorkflow } from "../../../contexts/WorkflowContext";
import TiptapField from "../Tiptap/TiptapField";
import { Action, PayloadConfiguration, ResponseConfiguration } from "../../../utils/interfaces";
import { ConfigProvider, Drawer, Select, Skeleton } from "antd";
import { FaRegEdit } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useRunOnFloq } from "@/contexts/RunOnFloqContext";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

type Props = {
  action: Action;
  modal: boolean;
  setModal: setStateType<boolean>;
  variables: any[];
  payloadConfiguration: PayloadConfiguration[];
  responseConfiguration: ResponseConfiguration[];
};

const CrmPullModal = ({ action, modal, setModal, variables, payloadConfiguration, responseConfiguration }: Props) => {
  const [loading, setLoading] = useState(false);
  // const [changed, setChanged] = useState(false);
  // const [open, setOpen] = useState(false);
  const [sfObjects, setSfObjects] = useState<{ loading: boolean; sfObjects: any[] }>({
    loading: false,
    sfObjects: [],
  });
  const [sfListViews, setSfListViews] = useState<{ loading: boolean; sfListViews: any[] }>({
    loading: false,
    sfListViews: [],
  });
  // const [sfListViewColumns, setSfListViewColumns] = useState<{ loading: boolean; sfListViewColumns: any[] }>({
  //   loading: false,
  //   sfListViewColumns: [],
  // });
  const [sfObjectFields, setSfObjectFields] = useState<{ loading: boolean; sfFields: any[] }>({
    loading: false,
    sfFields: [],
  });

  const [responses, setResponses] = useState<ResponseConfiguration[]>(responseConfiguration);
  const [updateField, setUpdateField] = useState(0);
  const [payloads, setPayloads] = useState<PayloadConfiguration[]>(payloadConfiguration);
  const {
    updateResponseConfig,
    updatePayloadConfig,
    getSalesforceObjects,
    getSfListViews,
    getSfObjectsFields,
    saveWorkflowActions,
    updateActionName,
    setPublishWarning,
  } = useWorkflow();
  const [changeName, setChangeName] = useState(false);
  const { collapseConfig, setCollapseConfig } = useRunOnFloq();
  const [drawerWidth, setDrawerWidth] = useState(collapseConfig ? "3rem" : "30%");
  const { id } = useParams();

  useEffect(() => {
    setWidthPercent();
    window.addEventListener("resize", setWidthPercent);
    return () => window.removeEventListener("resize", setWidthPercent);
  }, [collapseConfig]);

  const setWidthPercent = useCallback(() => {
    try {
      if (collapseConfig) setDrawerWidth("3rem");
      // Adjust width for different screen sizes
      else if (window.matchMedia("(min-width: 1024px) and (min-height: 1366px)").matches)
        // iPad Pro and similar devices
        setDrawerWidth("50%");
      else if (window.matchMedia("(min-width: 1024px)").matches) setDrawerWidth("30%");
      else if (window.matchMedia("(min-width: 640px)").matches) setDrawerWidth("50%");
      else setDrawerWidth("100%");
    } catch (err) {
      console.error("Error calculating width percent based on media size");
    }
  }, [collapseConfig]);

  useEffect(() => {
    setSfObjects({ loading: true, sfObjects: [] });
    getSalesforceObjects().then((data: any) => {
      setSfObjects({ loading: false, sfObjects: data.sfObjects });
    });
  }, []);

  // needed to auto-fetch selected object's list views after reopening the action modal
  useEffect(() => {
    if (payloads[0]?.inputString) {
      setSfListViews({ loading: true, sfListViews: [] });
      const selectedObject = payloads[0]?.inputString;
      getSfListViews(selectedObject).then((listViews) => {
        setSfListViews({
          loading: false,
          sfListViews: listViews,
        });
      });
    }
  }, [setSfObjects]);

  // need to auto-fetch selected listViews columns after reopening the action modal
  useEffect(() => {
    if (payloads[0]?.inputString && payloads[1]?.inputString) {
      setSfObjectFields({ loading: true, sfFields: [] });
      const selectedObject = payloads[0]?.inputString;
      // const selectedListView = payloads[1]?.inputString;
      // some fields are repeated in the list view columns, so we need to filter them out
      getSfObjectsFields(selectedObject).then((fields) => {
        // const uniqueFields = new Set();
        // const uniqueColumns = columns.filter((field: any) => {
        //   if (uniqueFields.has(field.label)) {
        //     return false;
        //   } else {
        //     uniqueFields.add(field.label);
        //     return true;
        //   }
        // });
        setSfObjectFields({
          loading: false,
          sfFields: fields,
        });
      });
    }
  }, [setSfListViews]);

  useEffect(() => {
    setPayloads(payloadConfiguration);
    setUpdateField((prev) => prev + 1);
  }, [payloadConfiguration]);

  const saveOutputStructure = async () => {
    setLoading(true);
    const updatedPayloads = updatePayloadConfig(payloads, action.id, false);
    setPayloads(updatedPayloads);
    const updatedResponses = updateResponseConfig(responses, action.id);
    setResponses(updatedResponses);
    await saveWorkflowActions(id || "");
    setPublishWarning(true);
    setLoading(false);
  };

  // fetches the object's list views, on click
  const handleObjectSelect = useCallback(
    async (selectedObjectName: any) => {
      setSfListViews({
        loading: true,
        sfListViews: [],
      });
      getSfListViews(selectedObjectName).then((listViews: any) => {
        setSfListViews({
          loading: false,
          sfListViews: listViews,
        });
      });
    },
    [sfListViews]
  );

  // fetches the object fields, on click
  const handleListViewSelect = useCallback(async () => {
    setSfObjectFields({
      loading: true,
      sfFields: [],
    });
    const selectedObject = payloads[0]?.inputString;
    // some fields are repeated in the list view columns, so we need to filter them out
    getSfObjectsFields(selectedObject).then((fields: any) => {
      // const uniqueFields = new Set();
      // const uniqueColumns = columns.filter((field: any) => {
      //   if (uniqueFields.has(field.label)) {
      //     return false;
      //   } else {
      //     uniqueFields.add(field.label);
      //     return true;
      //   }
      // });
      setSfObjectFields({
        loading: false,
        sfFields: fields,
      });
    });
  }, [sfObjectFields]);

  const fieldSwitchCase = (field: any) => {
    switch (field.type) {
      case "objectDropdown":
        return (
          <Skeleton loading={sfObjects.loading} paragraph={false} active={sfObjects.loading}>
            <Select
              showSearch
              defaultValue={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""
              }
              onChange={(value) => {
                handleObjectSelect(value);
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                temp[index].inputString = value;
                setPayloads(temp);
              }}
              options={sfObjects.sfObjects?.map((value: any) => ({
                label: value.label,
                value: value.name,
              }))}
            />
          </Skeleton>
        );
      case "listViewDropdown":
        return (
          <Skeleton loading={sfListViews.loading} paragraph={false} active={sfListViews.loading}>
            <Select
              showSearch
              defaultValue={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""
              }
              onChange={(value) => {
                handleListViewSelect();
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                temp[index].inputString = value;
                setPayloads(temp);
              }}
              options={sfListViews.sfListViews?.map((value: any) => ({
                label: value.label,
                value: value.id,
              }))}
            />
          </Skeleton>
        );
      case "columnsDropdown":
        return (
          <Skeleton loading={sfObjectFields.loading} paragraph={false} active={sfObjectFields.loading}>
            <Select
              key={responses.map((response) => response.responseStructureId).join("")}
              mode="multiple"
              allowClear
              defaultValue={responses.map((response) => response.responseStructureId)}
              // open={open}
              // onFocus={() => setOpen(true)}
              // onBlur={() => setOpen(false)}
              // onDropdownVisibleChange={(visible) => setOpen(visible)}
              onChange={(value) => {
                setResponses(
                  value.map((res) => {
                    const p: any = sfObjectFields.sfFields.find((field: any) => field.name === res);
                    return {
                      name: p.label,
                      description: p.label,
                      responseStructureId: p.name,
                    };
                  })
                );
              }}
              options={sfObjectFields.sfFields?.map((field: any) => ({
                label: field.label,
                value: field.name,
              }))}
              // optionFilterProp="label"
            />
          </Skeleton>
        );
      default:
        return (
          <textarea
            className="w-full h-32 rounded-lg"
            placeholder="Enter the value"
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""}
            onChange={(e) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: e.target.value,
                  type: field.type,
                });
              } else {
                temp[index].inputString = e.target.value;
              }
              setPayloads(temp);
            }}
          />
        );
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={modal}
        mask={false}
        onClose={() => setModal(false)}
        width={drawerWidth}
        styles={{
          content: {
            transition: "width 0.3s",
          },
          body: {
            padding: collapseConfig ? "0" : "24px",
          },
        }}
        classNames={{ header: !collapseConfig ? "border-b !border-gray-300" : "!border-none" }}
        className="ml-auto !font-favorit !p-0 border-l border-gray-500"
        getContainer={false}
        footer={
          !collapseConfig && (
            <div className="w-full flex flex-col gap-4 bg-white">
              <button
                className={` text-white w-full font-semibold rounded-lg p-2 mt-auto ${
                  loading ? "cursor-not-allowed bg-gray-500" : "bg-primary cursor-pointer"
                }`}
                onClick={saveOutputStructure}
                disabled={loading}
              >
                {loading ? "Saving..." : "Save"}
              </button>
            </div>
          )
        }
        title={
          !collapseConfig && (
            <div className="flex items-center gap-2">
              <button className="p-0 rounded-sm" onClick={() => setCollapseConfig(true)}>
                <MdChevronRight size={25} />
              </button>
              <img src={action.logo} alt={action.name} className="w-6 h-6" />
              <span
                contentEditable={changeName}
                suppressContentEditableWarning
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.target.blur();
                  }
                }}
                onBlur={async (e) => {
                  if (!e.target.textContent) return;
                  setLoading(true);
                  updateActionName(action.id, e.target.textContent || action.name);
                  setChangeName(false);
                  await saveWorkflowActions(id || "");
                  // setChanged(false);
                  setLoading(false);
                  setPublishWarning(true);
                }}
              >
                {action.name}
              </span>
              <button
                onClick={() => {
                  setChangeName(!changeName);
                  const p = document.getElementById("actionName");
                  if (!p) return;
                  setTimeout(function () {
                    const range = document.createRange();
                    const selection = window.getSelection();
                    range.selectNodeContents(p);
                    range.collapse(false); // <-- Set the cursor at the end of the selection
                    selection?.removeAllRanges();
                    selection?.addRange(range);
                    p.focus();
                  }, 0);
                }}
                className={`p-2 rounded-md transition-opacity duration-600 ease-in-out hover:bg-gray-200 ${
                  changeName ? "opacity-50" : "opacity-100"
                }`}
              >
                <FaRegEdit />
              </button>
            </div>
          )
        }
      >
        {collapseConfig ? (
          <button
            className="w-full h-full flex flex-col items-center justify-center p-3 gap-3"
            onClick={() => setCollapseConfig(false)}
          >
            <span className="p-0 border border-gray-300 rounded-sm">
              <MdChevronLeft size={25} />
            </span>
            <p className="[writing-mode:sideways-lr] text-center">CONFIG</p>
          </button>
        ) : (
          <div className="bg-white gap-10 flex flex-col">
            {action.payloadStructure.map((field, idx) => {
              return (
                <div key={idx} className="flex flex-col w-full gap-4 p-4 border border-gray-300 rounded-lg">
                  <div>
                    <p className="tracking-primary font-semibold">
                      {field.name.toUpperCase()}
                      {!field.required && (
                        <span className="text-sm font-normal tracking-normal text-gray-400"> (Optional)</span>
                      )}
                    </p>
                    <p className="text-sm font-light">{field.description}</p>
                  </div>
                  {field.needVars ? (
                    <TiptapField
                      content={
                        payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.tiptapJson ||
                        ""
                      }
                      setContent={(content: any, text: string) => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        if (index === -1) {
                          temp.push({
                            payloadStructureId: field.payloadStructureId,
                            tiptapJson: content,
                            inputString: text,
                            type: field.type,
                          });
                        } else {
                          temp[index].tiptapJson = content;
                          temp[index].inputString = text;
                        }
                        setPayloads(temp);
                      }}
                      refresh={updateField}
                      placeholder={field.name}
                      variables={variables}
                    />
                  ) : (
                    fieldSwitchCase(field)
                  )}
                </div>
              );
            })}
          </div>
        )}
      </Drawer>
    </ConfigProvider>
  );
};

export default CrmPullModal;

import { Handle, NodeProps, Position, useNodeConnections, useReactFlow } from "@xyflow/react";
import DefaultAction from "@/assets/SVGs/DefaultAction";
import DeleteAction from "@/assets/SVGs/DeleteAction";
import { useWorkflow } from "@/contexts/WorkflowContext";
import { IoDuplicateOutline } from "react-icons/io5";
import PlayButton from "@/assets/SVGs/buildTab/Run.svg";
import { ReactNode, useState } from "react";
import { useParams } from "react-router-dom";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import { useRunOnFloq } from "@/contexts/RunOnFloqContext";
import { CELL_STATUS_DISPLAY_2, CELL_STATUS_SYMBOL_2, USER_ACTION_STATUS } from "@/utils/constants";
import { ActionCounts } from "@/utils/interfaces";

export function ActionNode({ data, id }: NodeProps) {
  const [loading, setLoading] = useState(false);
  const { deleteElements } = useReactFlow();
  const { actions, actionCounts, isSubfloq } = useWorkflow();
  const { id: wfId } = useParams();
  const { runOnFloq, action } = useRunOnFloq();
  const connections = useNodeConnections({
    handleType: "target",
  });
  const currAction = actions?.find((action) => action.id === id);

  const getStats = (actionCounts: ActionCounts) => {
    const badges: ReactNode[] = [];

    Object.values(USER_ACTION_STATUS).forEach((status) => {
      if (!actionCounts?.[id]?.[status]) {
        return null;
      }
      const StatsWrapper = (children: ReactNode) => {
        return (
          <div
            key={status}
            className="bg-gray-100 items-center justify-center rounded-[2px] !text-[8px] min-w-fit text-nowrap flex gap-[0.15rem] px-[0.35rem] min-h-[1rem]"
          >
            {children}
          </div>
        );
      };
      if (status === "checkingNextSource") return null;
      if (status === "payloadFilled") {
        badges.push(
          StatsWrapper(
            <>
              <span>{CELL_STATUS_SYMBOL_2[status]}</span>
              <span>{(actionCounts?.[id]?.[status] || 0) + (actionCounts[id]?.checkingNextSource || 0)}</span>
              <span>{CELL_STATUS_DISPLAY_2[status]}</span>
            </>
          )
        );
        return;
      }

      badges.push(
        StatsWrapper(
          <>
            <span>{CELL_STATUS_SYMBOL_2[status]}</span>
            <span>{actionCounts?.[id]?.[status] || 0}</span>
            <span>{CELL_STATUS_DISPLAY_2[status]}</span>
          </>
        )
      );
    });

    if (badges.length === 0) return null;
    return <div className="py-2 px-2 border-t border-gray-400 flex gap-1 justify-start">{badges}</div>;
  };

  return (
    <div className="nodrag w-[36vw] cursor-pointer">
      {(data.type !== "input" || (!isSubfloq && id.includes("id2"))) && (
        <Handle
          type="target"
          position={Position.Top}
          isConnectable={currAction?.actionName === "MERGE_LISTS" || connections.length === 0}
        />
      )}
      <div
        className={`transition-opacity duration-300 w-full border rounded relative z-10 ${
          data.error
            ? "border-red-500 bg-red-100"
            : action && action?.id === id
              ? "border-primary bg-gray-50"
              : "border-gray-400 bg-white  hover:border-primary hover:bg-gray-50"
        }`}
      >
        <div className="absolute group top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full px-16 box-content">
          {data.type !== "input" && (
            <div className="flex items-col space-y-2 absolute top-1/2 transform -translate-y-1/2 duration-200 right-1 opacity-0 group-hover:-right-0  translate-x-[-1.5rem] group-hover:opacity-100">
              <button
                onClick={async (e) => {
                  e.stopPropagation();
                  await deleteElements({
                    nodes: [
                      {
                        id: id,
                      },
                    ],
                  });
                }}
                className="group focus:outline-none flex select-none px-3 py-2 font-semibold focus:ring-purple-500 border bg-white text-purple-800 hover:bg-gray-50 hover:border-purple-600 rounded-full !h-8 !w-8 items-center justify-center !p-0 border-grey-500 ring-offset-2 focus:ring-2"
              >
                <DeleteAction />
              </button>
            </div>
          )}
          {data.type !== "input" && data.type !== "output" && (
            <div className="flex items-col space-y-2 absolute top-1/2 transform -translate-y-1/2 duration-200 right-1 opacity-0 group-hover:-right-10 translate-x-[-1.5rem] group-hover:opacity-100">
              <button
                onClick={async (e) => {
                  e.stopPropagation();
                  const temp = actions?.find((action) => action.id === id);
                  // @ts-ignore
                  data?.duplicateNode(temp?.actionName, temp?.id, temp?.nextAction[0]?.actionId);
                }}
                className="group focus:outline-none flex select-none px-3 py-2 font-semibold focus:ring-purple-500 border bg-white text-purple-800 hover:bg-gray-50 hover:border-purple-600 rounded-full !h-8 !w-8 items-center justify-center !p-0 border-grey-500 ring-offset-2 focus:ring-2"
              >
                <IoDuplicateOutline />
              </button>
            </div>
          )}
        </div>
        <div className="flex justify-between items-start sm:items-center py-3 px-4 md:p-6">
          <div className="action-item-card flex items-center justify-center gap-4">
            <div className="h-8 w-8 relative flex justify-center items-center rounded-full flex-shrink-0 ">
              {(data.logo as string).includes("https") ? (
                <img src={data.logo as string} alt="icon" />
              ) : (
                <DefaultAction />
              )}
            </div>

            <div className="flex w-fit items-center justify-between overflow-hidden">
              <label className="text-grey-900 text-base font-semibold select-none pointer-events-none whitespace-no-wrap w-full break-words line-clamp-2">
                {data.name as string}
              </label>
            </div>
          </div>
          {!isSubfloq && !(data?.name as string)?.toLowerCase()?.includes("section") && (
            <div className="flex items-center gap-2">
              <button
                className="border border-gray-500 text-xs font-light px-1.5 py-0.5 rounded-sm hover:shadow flex items-center gap-1 hover:bg-gray-200 z-[11]"
                onClick={async (e) => {
                  e.stopPropagation();
                  setLoading(true);
                  await runOnFloq(id, wfId || "", 0, "first10");
                  setLoading(false);
                  // @ts-ignore
                  data?.openAction(id);
                }}
                disabled={loading}
              >
                {loading ? <SpinnerStatus /> : <img src={PlayButton} alt="Run" className="w-4 h-4" />}
                <p className="text-center">First 10</p>
              </button>
            </div>
          )}
        </div>
        {getStats(actionCounts)}
      </div>
      {id !== "idout" && <Handle type="source" position={Position.Bottom} isConnectable={true} id="a" />}
    </div>
  );
}

import { ConfigProvider, Table } from "antd";
import React, { useState } from "react";
import { LuPlus } from "react-icons/lu";
import TiptapField from "../../Tiptap/TiptapField";
import ConfirmDeleteButton from "../ConfirmDeleteButton";

type Props = {
  payloads: any[];
  inputChangeHandler: (value: string, tiptapJson: any, name: string) => void;
  variables: any[];
  selectedWorkflowName: string;
  selectedWorkflowLink: string;
  currentWorkflowName: string;
  selectedList: string;
  setModal: (value: boolean) => void;
  deleteInputs: (ids: any[]) => Promise<void>;
  refresh: number;
};

const InputColumnsBox = ({
  payloads,
  inputChangeHandler,
  variables,
  selectedWorkflowName,
  selectedWorkflowLink,
  currentWorkflowName,
  selectedList,
  setModal,
  deleteInputs,
  refresh,
}: Props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);
  const [expandRender, setExpandRender] = useState<any>(null);

  const updateExpandedRowKeys = (record: any, render: any) => {
    const rowKey = record.key;
    const isExpanded = expandedRowKeys.find((key) => key === rowKey);
    let expandedRowKeysNew: any[] = [];
    if (isExpanded)
      expandedRowKeysNew = expandedRowKeysNew.reduce((acc, key) => {
        if (key !== rowKey) acc.push(key);
        return acc;
      }, []);
    else expandedRowKeysNew.push(rowKey);

    setExpandedRowKeys(expandedRowKeysNew);
    setExpandRender(render);
  };

  return (
    <>
      <div className="border border-gray-300 rounded-md w-full">
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBg: "white",
                borderRadius: 6,
              },
            },
          }}
        >
          <Table
            dataSource={payloads.map((payload) => ({
              key: payload.responseId,
              name: payload.name,
              tiptapJson: payload.tiptapJson,
            }))}
            columns={[
              Table.SELECTION_COLUMN,
              Table.EXPAND_COLUMN,

              {
                title: (
                  <p>
                    <span className="text-headings text-sm font-medium">
                      Inputs{" "}
                      <a
                        href={selectedWorkflowLink}
                        target="_blank"
                        className="text-primary font-normal w-[40%] truncate hover:underline hover:text-primary"
                      >
                        ({selectedWorkflowName})
                      </a>
                    </span>
                  </p>
                ),
                dataIndex: "name",
                key: "name",
                width: "35%",
              },
              {
                title: (
                  <span className="text-headings text-sm font-medium">
                    Inputs <span className="text-sm text-gray-500 font-normal">({currentWorkflowName})</span>
                  </span>
                ),
                dataIndex: "tiptapJson",
                key: "tiptapJson",
                render: (_text, record) => {
                  return (
                    <TiptapField
                      content={record.tiptapJson || ""}
                      variables={variables}
                      setContent={(content: any, text: string) => inputChangeHandler(text, content, record.name)}
                      placeholderText="You can use variables by using '/'"
                      refresh={refresh}
                      skipDataValues
                      className="border-none rounded-md min-h-8 p-0 px-3 py-2 m-0 break-words break-all overflow-y-auto"
                      applyStyles={false}
                      explodeSection={selectedList?.split("{{")[1]?.split("}}")[0] || ""}
                      noCopyButton
                      dropdownDataValues
                      dropdownExpand={(render: any) => updateExpandedRowKeys(record, render)}
                    />
                  );
                },
                width: "65%",
              },
            ]}
            rowSelection={{
              selectedRowKeys,
              onChange: (newSelectedRowKeys: React.Key[]) => setSelectedRowKeys(newSelectedRowKeys),
            }}
            expandable={{
              expandedRowKeys,
              expandedRowRender: (_record) => expandRender,
              expandIcon: () => <></>,
              onExpand: (_expanded, record) => updateExpandedRowKeys(record, expandRender),
            }}
            size="small"
            pagination={false}
          />
        </ConfigProvider>
        <button onClick={() => setModal(true)} className="m-1 flex items-center gap-1 p-2 rounded-md hover:underline">
          <LuPlus size={20} />
          Add Input
        </button>
      </div>
      {payloads.length > 0 && (
        <ConfirmDeleteButton
          onDelete={async () => deleteInputs(selectedRowKeys)}
          hoverText={`Delete ${selectedRowKeys.length} rows`}
          disabled={selectedRowKeys.length === 0}
        />
      )}
    </>
  );
};

export default InputColumnsBox;

import { Table } from "antd";
import { FaCoins } from "react-icons/fa";

type Props = {
  data: any;
  credits: any;
};

const TableViewer = ({ data, credits }: Props) => {
  const rows = data[Object.keys(data)[0]].locations;

  return (
    <>
      <Table
        dataSource={Object.entries(rows).map(([location, employees]) => ({
          key: location,
          location,
          employees,
        }))}
        pagination={false}
        columns={[
          {
            title: "Location",
            dataIndex: "location",
            key: "location",
            sorter: (a: any, b: any) => a.location.localeCompare(b.location),
          },
          {
            title: "No. of employees",
            dataIndex: "employees",
            key: "employees",
            sorter: (a: any, b: any) => a.employees - b.employees,
          },
        ]}
      />
      <div className="mt-4 flex items-center">
        <h1 className="font-semibold text-md">Total cost:</h1>
        <div className="flex items-center ml-2 bg-purple-100 rounded p-1">
          <span className="font-bold text-md">{credits}</span>
          <FaCoins className="ml-1 text-purple-500 w-5" />
        </div>
      </div>
    </>
  );
};

export default TableViewer;

import { Handle, NodeProps, Position, useReactFlow } from "@xyflow/react";
import DefaultAction from "@/assets/SVGs/DefaultAction";
import DeleteAction from "@/assets/SVGs/DeleteAction";
import { Popconfirm } from "antd";

export function FlowBasicsRootNode({ data, id }: NodeProps) {
  const { deleteElements } = useReactFlow();

  return (
    <>
      <div className="nodrag w-[36vw] cursor-pointer">
        <Handle type="target" position={Position.Top} isConnectable={true} />
        <div
          className={`p-1 transition-opacity duration-300 min-w-fit w-[15vw] mx-auto py-2 px-4 md:p-3 border rounded-lg relative z-100 shadow-[0_0_0_5px_#E5ECFF] ${
            data.error ? "border-red-500 bg-red-100" : "border-gray-400 bg-white hover:border-white hover:bg-[#F6F8FF]"
          }`}
        >
          <div className="absolute group peer top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full z-20 px-16 box-content">
            <div className="flex items-col space-y-2 absolute top-1/2 transform -translate-y-1/2 duration-200 right-1 opacity-0 group-hover:-right-0  translate-x-[-1.5rem] group-hover:opacity-100">
              <Popconfirm
                title="Delete all paths?"
                description={() => (
                  <div>
                    <p>Are you sure?</p>
                    <p>This will delete all the actions associated with all the paths.</p>
                  </div>
                )}
                okText="Yes"
                cancelText="No"
                onConfirm={async (e) => {
                  e?.stopPropagation();
                  await deleteElements({
                    nodes: [
                      {
                        id: id,
                      },
                    ],
                  });
                }}
              >
                <button className="group focus:outline-none flex select-none px-3 py-2 font-semibold focus:ring-purple-500 border bg-white text-purple-800 hover:bg-gray-50 hover:border-purple-600 rounded-full !h-8 !w-8 items-center justify-center !p-0 border-grey-500 ring-offset-2 focus:ring-2">
                  <DeleteAction />
                </button>
              </Popconfirm>
            </div>
          </div>
          <div className="flex justify-start items-start sm:items-center action-item-card gap-4">
            <div className="h-12 w-12 relative flex justify-center items-center rounded-full flex-shrink-0 ">
              {(data.logo as string).includes("https") ? (
                <img src={data.logo as string} alt="icon" />
              ) : (
                <DefaultAction />
              )}
            </div>
            <div className="flex flex-col justify-center items-start">
              <p className="text-xs text-headings font-light">Flow Basics</p>
              <label className="text-grey-900 text-lg text-headings font-semibold select-none pointer-events-none whitespace-no-wrap w-full break-words line-clamp-2">
                {data.name as string}
              </label>
            </div>
          </div>
        </div>
      </div>
      <Handle type="source" position={Position.Bottom} isConnectable={true} id="a" />
    </>
  );
}

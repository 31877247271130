import { App } from "antd";
import KeyValue from "./KeyValue";
import { useEffect, useState } from "react";
import { fetchTransactionsForWorkflowActionDataPart } from "@/utils/apis";
import WaterfallIconBlack from "@/assets/SVGs/WaterfallIconBlack.svg";
import WaterfallView from "./WaterfallView";

type Props = {
  open: boolean;
  data: any;
  credits: number | { [key: string]: number };
};

const ApnicIPViewer = ({ open, data, credits }: Props) => {
  const { response, status, action, workflowDataActionPartId, successText, navigationText, workflowId } = data;
  if (!action) return null;

  const [_, setTransactionHistory] = useState<any[]>([]);
  const [newData, setNewData] = useState<any>({});
  const [openWaterfall, setOpenWaterfall] = useState<boolean>(false);

  useEffect(() => {
    if (!open) setNewData({});
    else {
      const filteredData = Object.fromEntries(Object.entries(response).filter(([key]) => key !== "undefined"));
      setNewData(filteredData);
    }
    getTransactionHistory(workflowDataActionPartId, action);
  }, [open, data]);

  const getTransactionHistory = async (workflowDataActionPartId: string, action: any) => {
    const id = workflowDataActionPartId;
    const wfId = action?.workflowId || workflowId;
    
    if (!wfId || !id) return;

    const response = await fetchTransactionsForWorkflowActionDataPart(wfId, id);
    setTransactionHistory(response.transactions || []);
  };

  return (
    <App>
      {status == "completed" && (
        <div className="flex justify-end px-3 pb-2">
          <button
            className="font-favorit font-normal flex items-center gap-1 px-3 py-1 ring-[0.5px] ring-primaryBlack rounded-[3px] hover:bg-[#f2f2f2]"
            onClick={() => setOpenWaterfall(!openWaterfall)}
          >
            <img src={WaterfallIconBlack} alt={action.name} className="h-5 w-5" />
            <span>{openWaterfall ? `${navigationText} ` : "View waterfall"}</span>
          </button>
        </div>
      )}
      {status != "completed" || openWaterfall ? (
        <WaterfallView data={data} credits={credits} successText={successText} />
      ) : (
        <div className="flex flex-col gap-2">
          {Object.entries(newData).length > 0 ? (
            Object.entries(newData).map(([key, value]: [string, any]) => (
              <KeyValue key={key} keyName={key} value={value} open={open} />
            ))
          ) : (
            <KeyValue keyName="status" value="Completed" open={open} />
          )}
        </div>
      )}
    </App>
  );
};

export default ApnicIPViewer;

import React from 'react';
import { ThunderboltFilled } from '@ant-design/icons';

interface AIButtonProps {
  text: string;
  loading: boolean;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
}

const AIButton: React.FC<AIButtonProps> = ({ 
  text, 
  loading, 
  onClick, 
  disabled = false,
  className = '' 
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled || loading}
      className={`flex items-center justify-center gap-2 py-2 px-4 
        text-black font-medium transition-all
        disabled:opacity-70 disabled:cursor-not-allowed
        ${className}`}
      style={{
        border: '0.5px solid #333',
        background: 'linear-gradient(90deg, #F2EFFF 0%, #FFF1EA 100%)',
        minHeight: '40px',
        borderRadius: '2px',
      }}
    >
      {loading ? (
        <div className="animate-spin rounded-full h-3 w-3 border-t-2 border-b-2 border-purple-400"></div>
      ) : (
        <ThunderboltFilled className="text-base text-purple-500" />
      )}
      <span className="text-sm">{loading ? 'Generating...' : text}</span>
    </button>
  );
};

export default AIButton;
import { useEffect, useState } from "react";
import { CELL_STATUS_SYMBOL, CELL_STATUS_TYPE } from "@/utils/constants";
import Coins from "@/assets/SVGs/buildTab/Coins";
import { fetchTransactionsForWorkflowActionDataPart } from "@/utils/apis";
import CreditHistoryViewer from "./CreditHistorViewer";

type Props = {
  data: any;
  credits: any;
  successText?: string;
  error?: any;
};

const WaterfallView = ({ data, credits, error }: Props) => {
  const [apis, setApis] = useState<any[]>([]);
  const [creds, setCreds] = useState<any>(credits);
  const [transactionHistory, setTransactionHistory] = useState<any[]>([]);
  let totalCost = 0;
  const stepDownResponses = data.stepDownResponse;

  useEffect(() => {
    getTransactionHistory(data);
    const config = data.action.payloadConfiguration.find(
      (conf: any) => conf.stepDownSearchConfig
    )?.stepDownSearchConfig;
    const stepApis =
      data.action.payloadStructure.find((payload: any) => payload.stepDownAPIs)?.stepDownAPIs ||
      data.action?.stepDownAPIs;
    if (!config || !stepApis) return;

    const tempCreds: any = credits;
    config.forEach((c: any) => {
      if (c.connection) tempCreds[c.apiId] = "CUSTOM";
    });
    setCreds(tempCreds);

    if (!stepDownResponses) {
      setApis([]);
      return;
    }
    const modifiedProviders = stepApis.filter((api: any) => Object.keys(stepDownResponses).includes(api.apiId));
    const sortedProviders = modifiedProviders.sort((a: any, b: any) => {
      const aOrder = config.find((c: any) => c.apiId === a.apiId)?.order;
      const bOrder = config.find((c: any) => c.apiId === b.apiId)?.order;
      return aOrder - bOrder;
    });

    setApis(sortedProviders);
  }, [data]);

  const getTransactionHistory = async (data: any) => {
    const dataId = data?.workflowDataActionPartId;
    const workflowId = data?.action?.workflowId || data?.workflowId;
    if (!workflowId || !dataId) return;

    const response = await fetchTransactionsForWorkflowActionDataPart(workflowId, dataId);
    setTransactionHistory(response.transactions || []);
  };

  const updateCost = (cost: any) => {
    totalCost += parseFloat(cost);
    totalCost = Math.round(totalCost * 100) / 100;
  };

  const renderStatus = (apiId: string) => {
    if (!stepDownResponses) return null;
    const response = Object.keys(stepDownResponses).find((key) => key === apiId);

    if (response) {
      const { error } = stepDownResponses[response];
      if (error) {
        return (
          <span className="flex items-center ml-2 text-red-500">
            {CELL_STATUS_SYMBOL.failed} {error || CELL_STATUS_TYPE.FAILED}
          </span>
        );
      } else {
        return (
          <span className="flex items-center ml-2 text-green-500 gap-2">
            {CELL_STATUS_SYMBOL.completed} 
            {/* {successText || CELL_STATUS_TYPE.COMPLETED} */}
            <span className="text-xs">
              <div className="flex items-center justify-center gap-1 rounded-sm p-1">
                {creds[apiId] !== "CUSTOM" && <Coins width="18" height="22" />}
                <span className="font-bold text-md text-black">
                  {creds[apiId] === "CUSTOM" ? "Custom API Key" : creds[apiId]}
                  {creds[apiId] !== "CUSTOM" && updateCost(creds[apiId])}
                </span>
              </div>
            </span>
          </span>
        );
      }
    }

    return <span className="flex items-center ml-2 text-gray-500">{CELL_STATUS_SYMBOL.pending} Pending</span>;
  };

  if (!data) {
    return <div className="p-5">No data available.</div>;
  }

  return (
    <div>
      <div className="p-2 ">
        <div className="flex items-center mb-4">
          <h2 className="text-sm font-bold mr-2">Waterfall details</h2>
        </div>
        {apis && apis.length > 0 ? (
          <div className="rounded-[10px] p-4 py-5 bg-[#F3F4F6] flex flex-col gap-2">
            <div className="flex items-center gap-4 mb-4 w-full overflow-hidden">
              <span className="font-medium min-w-[100px] max-w-[100px]">First we tried</span>
              <span className="flex items-center gap-2 bg-white p-2 relative min-w-[150px] max-w-[150px]">
                <img src={apis[0].logo} alt={apis[0].apiName} className="w-6 h-6" />
                <span className="truncate">{apis[0].apiName}</span>
              </span>
              <span className="flex-wrap break-words whitespace-normal w-[200px]">{renderStatus(apis[0].apiId)}</span>
            </div>
            {apis.slice(1).map((api: any) => (
              <div key={api.apiId} className="flex items-center gap-4 mb-4 w-full overflow-hidden">
                <span className="font-medium min-w-[100px] max-w-[100px]">Then we tried</span>
                <span className="flex items-center gap-2 bg-white p-2 relative min-w-[150px] max-w-[150px]">
                  <img src={api.logo} alt={api.apiName} className="w-6 h-6" />
                  <span className="truncate">{api.apiName}</span>
                </span>
                {renderStatus(api.apiId)}
              </div>
            ))}
            <span className="font-bold flex items-center gap-x-2">
              <CreditHistoryViewer transactionHistory={transactionHistory} />
            </span>
            {error && <div className="text-red-500 mt-3 font-bold">Error: {error}</div>}
          </div>
        ) : (
          <div className="border border-gray-300 rounded-lg p-4 bg-gray-100 flex flex-col">
            <span className="font-semibold">Used Data Value specified in the Condition</span>
            {error && <div className="text-red-500 mt-3 font-bold">Error: {error}</div>}
          </div>
        )}
      </div>
    </div>
  );
};

export default WaterfallView;

import FloqerLogo from "@Assets/SVGs/floqerLogo.svg";

type ActionCardProps = {
  name: string;
  logo: string;
  description: string;
  selected: boolean;
  disabled?: boolean;
  onClick: () => void;
  isSidebarCollapsed?: boolean;
};

const ActionCard = ({ name, logo, description, selected, disabled, onClick, isSidebarCollapsed }: ActionCardProps) => {
  return (
    <button
      key={`input-${name}`}
      disabled={disabled}
      className={`flex flex-col gap-2 border border-[#565656] overflow-hidden rounded-[5px] p-5 hover:shadow-md hover:ring-[#d4c7ff] hover:ring-2 cursor-pointer h-[135px] tracking-tight transition-all duration-200 ease-in-out ${selected ? `ring-[#d4c7ff] ring-2` : ""}`}
      onClick={onClick}
    >
      <div className={`flex w-full items-center gap-2 ${isSidebarCollapsed ? "w-[80%]" : "w-[95%]"}`}>
        <img src={logo || FloqerLogo} alt={name} className="w-8 h-8 object-contain" />
        <span className="leading-tight line-clamp-2 text-start font-medium text-[#3F3F3F]">{name}</span>
      </div>
      <p className={`tracking-normal text-start font-light ${isSidebarCollapsed ? "text-[15px]" : "text-[14px]"}`}>
        {description}
      </p>
    </button>
  );
};

export default ActionCard;

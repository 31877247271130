import React from "react";
import { Tag } from "antd";
import moment from "moment";
import Coins from "@/assets/SVGs/buildTab/Coins";
import TooltipComp from "@/Components/Generics/Tooltip";
import { TransactionLog } from "@/utils/interfaces";
import { getStatusColor } from "../utils";

interface CreditHistoryProps {
  transactionHistory: TransactionLog[];
}

const CreditHistoryViewer: React.FC<CreditHistoryProps> = ({ transactionHistory }) => {
  const totalCreditsConsumed = transactionHistory.reduce((acc, curr) => acc + curr.total_credits_consumed, 0);

  const transactionBox = (transaction: TransactionLog) => (
    <div className="py-3 px-4 bg-gray-100 font-normal border border-gray-200 rounded-lg">
      <div className="grid grid-cols-2 gap-10">
        <div>
          <div className="flex items-center gap-1">
            <span className="font-medium">{moment(transaction.created_at).format("MMM DD, YYYY")}</span>
          </div>
        </div>
        <div>
          <Tag color={getStatusColor(transaction.status)} className="uppercase">
            {transaction.status}
          </Tag>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-10 mt-2">
        <div>
          <p className="text-sm text-gray-500">Consumed</p>
          <div className="flex items-center gap-1">
            <span className="font-medium">{transaction.total_credits_consumed}</span>
            <Coins width="18" height="22" />
          </div>
        </div>
        <div>
          <p className="text-sm text-gray-500">Needed</p>
          <div className="flex items-center gap-1">
            <span className="font-medium">{transaction.totalCreditsNeeded}</span>
            <Coins width="18" height="22" />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex items-center gap-x-2">
      Total cost: <Coins width="18" height="22" />
      {totalCreditsConsumed}
      <TooltipComp
        trigger={<div className="text-sm underline underline-offset-2 cursor-pointer">History</div>}
        content={
          <div className="flex flex-col gap-3 max-h-[35rem] overflow-y-scroll">
            {transactionHistory
              .sort((a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf())
              .map((transaction: TransactionLog, index: number) => (
                <React.Fragment key={index}>{transactionBox(transaction)}</React.Fragment>
              ))}
          </div>
        }
      />
    </div>
  );
};

export default CreditHistoryViewer;

import { ReactNode } from "react";

export const CustomTooltip = ({ content, children }: { content: ReactNode; children: ReactNode }) => {
  return (
    <div className="relative group">
      {children}

      {/* Tooltip Box */}
      <div
        className="absolute left-1/2 top-full z-50 w-64 -translate-x-1/2 scale-0 
                      rounded-[2px] bg-white py-2 px-0 text-xs text-white 
                      opacity-0 shadow-[0_0_0_4px_#dadada] 
                      border border-[#333333] transition-all 
                      group-hover:scale-100 group-hover:opacity-100"
      >
        {content}

        {/* Tooltip Tip */}
        <div
          className="absolute left-1/2 top-0 h-3 w-3 -translate-x-1/2 -translate-y-1/2 
                        rotate-45 bg-white border-t border-l border-[#333] 
                        shadow-[-2px_-2px_0_0_#dadada]"
        ></div>
      </div>
    </div>
  );
};

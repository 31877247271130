import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "../ui/Command";
import { IFiltersProps, MultiSelectValues } from "../filter.schema";
import { LuCheck } from "react-icons/lu";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../ui/Form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { areObjectsEqual } from "../utils";

const formSchema = z.object({
  // field: z.string(),
  min: z.string(),
  max: z.string().optional(),
});

export function SelectSubFilterPreview({
  data,
  availableValues,
  primary,
}: {
  data: IFiltersProps;
  availableValues: MultiSelectValues;
  primary: "value" | "subValue";
}) {
  if (primary === "value") {
    const name = availableValues.find((item) => item.id === data?.value)?.name;
    return <p className="text-sm">{name}</p>;
  } else {
    const string = Array.isArray(data?.subValue) ? data?.subValue.join(", ") : data?.subValue;
    return <p className="text-sm">{string}</p>;
  }
}

export function SelectSubFilter({
  value,
  typedValue,
  onChangeValue,
  availableValues,
  closePopover,
}: {
  onChangeValue: (value: string | string[] | undefined, preventClose?: boolean, isSubvalue?: boolean) => void;
  availableValues: MultiSelectValues;
  typedValue: string[];
  value: string;
  closePopover: () => void;
}) {
  const onChangeCondition = (newValue: string) => {
    if (newValue == value) return;
    onChangeValue(newValue, true);
  };

  function onSubmit(values: z.infer<typeof formSchema>) {
    const newValue = [values.min || `0`, values.max || `0`];
    if (areObjectsEqual(newValue, value)) {
      closePopover();
      return;
    }
    onChangeValue(newValue.length > 0 ? newValue : undefined, false, true);
  }

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      min: value ? typedValue?.[0] : "",
      max: value ? typedValue?.[1] : "",
    },
  });

  return (
    <Form {...form}>
      <form className="grid max-w-sm items-center gap-y-4" onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="min"
          render={({ field }) => {
            return (
              <FormItem>
                <FormControl>
                  <input
                    className="flex w-full rounded-md border border-input bg-popover px-3 py-2 text-sm ring-offset-popover placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                    placeholder="Min"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <FormField
          control={form.control}
          name="max"
          render={({ field }) => {
            return (
              <FormItem>
                <FormControl>
                  <input
                    className="flex w-full rounded-md border border-input bg-popover px-3 py-2 text-sm ring-offset-popover placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                    placeholder="Max"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <Command>
          {availableValues.length > 5 && <CommandInput placeholder="Type filter..." />}
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {availableValues.map((item) => (
                <CommandItem key={item.id} onSelect={() => onChangeCondition(item.id)} disabled={value == item.id}>
                  {value == item.id && <LuCheck className="h-4 w-4 mr-2" />}
                  {value != item.id && <div className="h-4 w-4 mr-2" />}

                  <span className="flex gap-2 items-center">{item.name}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
        <button className="btn py-2" type="submit">
          Save
        </button>
      </form>
    </Form>
  );
}

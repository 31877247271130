import * as Tooltip from "@radix-ui/react-tooltip";
import { useEffect, useRef } from "react";

interface Props {
  trigger: React.ReactNode;
  content: React.ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  preventTooltipClose?: boolean;
}

const TooltipComp: React.FC<Props> = ({ trigger, content, open, onOpenChange, preventTooltipClose }) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);

  // Handle document clicks for controlled mode
  useEffect(() => {
    if (!open) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (preventTooltipClose) return;
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        if (triggerRef.current && triggerRef.current.contains(event.target as Node)) {
          return;
        }
        onOpenChange?.(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onOpenChange, preventTooltipClose]);
  return (
    <Tooltip.Provider delayDuration={0}>
      <Tooltip.Root open={open}>
        <Tooltip.Trigger asChild ref={triggerRef}>
          {trigger}
        </Tooltip.Trigger>

        <Tooltip.Content
          className="p-2 text-sm border border-[#333333] rounded-[8px] mt-[-4px] z-[20] bg-white shadow-[0_0_0_4px_#dadada] "
          sideOffset={5}
          side="bottom"
          ref={tooltipRef}
        >
          {content}
          <Tooltip.Arrow
            className="h-[18px] w-[20px] fill-white"
            style={{
              strokeWidth: "1px",
              stroke: "rgba(51, 51, 51, 0.5)",
              clipPath: "polygon(50% 6%, -250% 100%, 350% 100%)",
              filter: "drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.7))",
            }}
          />
        </Tooltip.Content>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default TooltipComp;

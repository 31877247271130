// import { useMemo } from "react";
import { ResponseStructure } from "../models";
import { CustomTooltip } from "./CustomTooltip";
import BoxIcon from "@Assets/SVGs/BoxIcon.svg";

type Props = {
  responseStructure: ResponseStructure[];
  exampleResponse: any;
  signal?: any;
  payloads?: any;
};

const getNestedValue = (obj: any, path: string): any => {
  if (path.includes(".")) {
    return path.split(".").reduce((acc, key) => {
      return acc && (acc as any)[key] !== undefined ? (acc as any)[key] : undefined;
    }, obj);
  }
  return obj[path];
};

const ExampleResponse = ({ exampleResponse, responseStructure, signal, payloads }: Props) => {
  const { returnType = {}, action = {} } = signal || {};

  // const countAppliedFilters = useMemo(() => {
  //   if (!payloads || !signal?.payloadStructure) return 0;

  //   // Get the required payload structures
  //   const requiredPayloadStructures = signal.payloadStructure.filter((payload: any) => payload.required);

  //   // Count the number of required payloads that have been applied
  //   const count = requiredPayloadStructures.filter((payload: any) => {
  //     const value = payloads[payload.payloadStructureId];

  //     // Handle min-max case
  //     if (typeof value === "object" && value !== null && !Array.isArray(value)) {
  //       return (value.min !== undefined && value.min !== "") || (value.max !== undefined && value.max !== "");
  //     }

  //     // Handle arrays (e.g., multi-select dropdowns)
  //     if (Array.isArray(value)) {
  //       return value.length > 0;
  //     }

  //     // Handle other cases (string, number, boolean, etc.)
  //     return value !== undefined && value !== null && value !== "";
  //   }).length;

  //   return count;
  // }, [payloads, signal?.payloadStructure]);

  const getActionStyles = (actionName: string) => {
    switch (actionName) {
      case "RECENTLY FUNDRAISED":
      case "HEADCOUNT GROWTH":
        return "text-[#7B0094] bg-[#FBE7FF]";
      case "BEGUN NEW JOB":
      case "NEW JOB POSTINGS":
        return "text-[#1B7B00] bg-[#ECFFEA]";
      case "POST MATCHING KEYWORD":
        return "text-[#00658D] bg-[#E3F7FF]";
      default:
        return "text-[#000000] bg-[#F2F2F2]";
    }
  };

  const actionTagStyles = getActionStyles(action?.name);

  return (
    <div className="w-full h-screen flex flex-col gap-4 font-favorit pt-8 px-8 relative">
      <div className="w-full flex items-center gap-4">
        <span className="text-2xl font-medium text-primaryBlack">Your data will look like</span>
        <span className="h-5 border-r border-[#BDBDBD] mx-1" />
        <div className="flex items-stretch gap-2">
          <CustomTooltip
            content={
              <div className="text-[#6b6b6b] text-center text-xs font-extralight tracking-wider">
                {returnType?.description}
              </div>
            }
          >
            <span className="text-primaryBlack flex items-center gap-2 font-medium rounded-sm text-[13px] px-2 bg-[#F2F2F2] min-h-[24px]">
              {returnType?.logo && <img src={returnType?.logo} alt="icon" className="w-4 h-4" />}
              {returnType?.name}
            </span>
          </CustomTooltip>
          <CustomTooltip
            content={
              <div className="text-[#6b6b6b] text-xs text-center font-extralight tracking-wider">
                {action?.description}
              </div>
            }
          >
            <span className={`px-2 text-sm rounded-sm min-h-[24px] flex items-center ${actionTagStyles}`}>
              {action?.name}
            </span>
          </CustomTooltip>
        </div>
      </div>

      {responseStructure && responseStructure.length > 0 ? (
        <div className="flex flex-col rounded-[7px] ring-[1px] ring-[#D3D3D3] shadow-[0_0_0_3px_#F1F1F1] h-fit max-h-[82%] overflow-y-auto">
          {responseStructure?.map((response, index) => {
            const responseValue = getNestedValue(exampleResponse, response.responseStructureId);
            let isFilterApplied = false;
            let isPayloadRequired = false;

            if (response.responseStructureId === "employee_growth_percentages") {
              isFilterApplied = payloads && payloads["COMPANY_HEADCOUNT_GROWTH"] !== undefined;
              if (signal?.payloadStructure) {
                const payloadStructure = signal.payloadStructure.find(
                  (payload: any) => payload.payloadStructureId === "COMPANY_HEADCOUNT_GROWTH"
                );
                isPayloadRequired = payloadStructure ? payloadStructure.required : false;
              }
            } else if (
              response.responseStructureId === "location" ||
              response.responseStructureId === "location_text"
            ) {
              isFilterApplied = payloads && payloads["REGION"] !== undefined;
              if (signal?.payloadStructure) {
                const payloadStructure = signal.payloadStructure.find(
                  (payload: any) => payload.payloadStructureId === "REGION"
                );
                isPayloadRequired = payloadStructure ? payloadStructure.required : false;
              }
            } else if (response.responseStructureId === "text") {
              isFilterApplied = payloads && payloads["KEYWORD"] !== undefined;
              if (signal?.payloadStructure) {
                const payloadStructure = signal.payloadStructure.find(
                  (payload: any) => payload.payloadStructureId === "KEYWORD"
                );
                isPayloadRequired = payloadStructure ? payloadStructure.required : false;
              }
            } else if (
              response.responseStructureId === "total_comments" ||
              response.responseStructureId === "total_reactions" ||
              response.responseStructureId === "reactions_by_type"
            ) {
              isFilterApplied = payloads && payloads["REACTORS"] !== undefined;
              if (signal?.payloadStructure) {
                const payloadStructure = signal.payloadStructure.find(
                  (payload: any) => payload.payloadStructureId === "REACTORS"
                );
                isPayloadRequired = payloadStructure ? payloadStructure.required : false;
              }
            } else if (response.responseStructureId === "person_title") {
              isFilterApplied = payloads && payloads["CURRENT_TITLE"] !== undefined;
              if (signal?.payloadStructure) {
                const payloadStructure = signal.payloadStructure.find(
                  (payload: any) => payload.payloadStructureId === "CURRENT_TITLE"
                );
                isPayloadRequired = payloadStructure ? payloadStructure.required : false;
              }
            } else {
              isFilterApplied = payloads && Object.keys(payloads).includes(response.responseStructureId.toUpperCase());
              if (isFilterApplied && signal?.payloadStructure) {
                const payloadStructure = signal.payloadStructure.find(
                  (payload: any) => payload.payloadStructureId === response.responseStructureId.toUpperCase()
                );
                isPayloadRequired = payloadStructure ? payloadStructure.required : false;
              }
            }
            return (
              <div key={response.responseStructureId} className="first:mt-4 last:mb-4">
                <div className="px-5">
                  <span
                    className={`inline-flex items-center text-primaryBlack font-favoritMono font-medium rounded-sm px-2 ${
                      isFilterApplied
                        ? isPayloadRequired
                          ? "bg-[#FFF6CC] ring-1 ring-primaryBlack"
                          : "bg-[#F2F2F2] ring-1 ring-primaryBlack"
                        : "bg-[#F2F2F2]"
                    }`}
                  >
                    {response.name}:
                  </span>
                  <span className="text-[#6B6B6B] ml-2">
                    {typeof responseValue === "object" && responseValue !== null
                      ? JSON.stringify(responseValue)
                      : responseValue}
                  </span>
                </div>
                {index < responseStructure.length - 1 && <hr className="my-4 border-[#e5e5e5]" />}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center gap-4 rounded-[7px] ring-[1px] ring-[#D3D3D3] shadow-[0_0_0_3px_#F1F1F1] h-[500px]">
          <div>
            <img src={BoxIcon} alt="empty-icon" className="h-[125px] w-[125px]" />
          </div>
          <div className="font-favorit text-[#565656] font-medium text-2xl">
            Apply at least one required filter to see example data
          </div>
        </div>
      )}
    </div>
  );
};

export default ExampleResponse;

import posthog from "posthog-js";

const isProd = import.meta.env.VITE_IS_PROD === "true";
export const posthogKey = import.meta.env.VITE_PUBLIC_POSTHOG_KEY;

// Helper to check if PostHog is properly configured
export const isPostHogEnabled = (): boolean => {
  return isProd && !!posthogKey;
};
export const isFeatureEnabled = (flagKey: string): boolean => {
  if (!isProd) {
    return true;
  }
  if (!isPostHogEnabled()) {
    return false;
  }
  try {
    const isEnabled = posthog.isFeatureEnabled(flagKey) ?? false;
    return isEnabled;
  } catch (error) {
    console.error("Error checking feature flag:", error);
    return false;
  }
};

export function identifyUser(user: {
  org_id: string;
  last_name: string;
  first_name: string;
  job_title: string;
  email: string;
}) {
  if (!isPostHogEnabled()) {
    return;
  }
  const user_posthog_id = user.org_id + "_" + user.email;
  posthog.identify(user_posthog_id, user);
}

export function resetUser() {
  if (!isPostHogEnabled()) {
    return;
  }
  posthog.reset();
}

export function captureEvent(event: string, properties: object) {
  if (!isPostHogEnabled()) {
    return;
  }
  posthog.capture(event, properties);
}

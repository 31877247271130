import { setStateType } from "../../../utils/constants";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useWorkflow } from "../../../contexts/WorkflowContext";
import TiptapField from "../Tiptap/TiptapField";
import { Action, PayloadConfiguration } from "../../../utils/interfaces";
import { Alert, ConfigProvider, Drawer, Select, Skeleton, Switch } from "antd";
import { FaPlus, FaRegEdit } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { MdChevronLeft, MdChevronRight, MdDeleteOutline } from "react-icons/md";
import RunCondition from "./ModalComponents/RunCondition";
import ResponseStructureViewer from "./ModalComponents/ResponseStructure";
import { useConnections } from "@/contexts/ConnectionContext";
import { getCampaigns } from "@/utils/apis";
import { useRunOnFloq } from "@/contexts/RunOnFloqContext";

type Props = {
  action: Action;
  modal: boolean;
  setModal: setStateType<boolean>;
  payloadConfiguration: PayloadConfiguration[];
  responseConfiguration: any[];
  variables: any[];
};

const CloseCrmModal = ({ action, modal, setModal, payloadConfiguration, responseConfiguration, variables }: Props) => {
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [campaignsLoading, setCampaignsLoading] = useState<Record<string, boolean>>({});
  const [campaigns, setCampaigns] = useState<Record<string, any[]>>({});
  const [updateField, setUpdateField] = useState(0);
  const [payloads, setPayloads] = useState<PayloadConfiguration[]>(payloadConfiguration);
  const [runCondition, setRunCondition] = useState(action.runCondition);
  const [responses, setResponses] = useState(responseConfiguration);
  const { updatePayloadConfig, saveWorkflowActions, updateActionName, setPublishWarning, updateResponseConfig } =
    useWorkflow();
  const { getConnectionFromType } = useConnections();
  const { collapseConfig, setCollapseConfig } = useRunOnFloq();
  const [drawerWidth, setDrawerWidth] = useState(collapseConfig ? "3rem" : "30%");
  const [isConnectionExists, setIsConnectionExists] = useState(true);
  const [changeName, setChangeName] = useState(false);
  const { id } = useParams();
  const types = useMemo<string[]>(
    () =>
      action.payloadStructure
        .filter((field) => field.type === "dynamicDropdown" || field.type === "jsonArray")
        .map((field) => field.valuesId ?? ""),
    [action.payloadStructure]
  );

  useEffect(() => {
    setWidthPercent();
    window.addEventListener("resize", setWidthPercent);
    return () => window.removeEventListener("resize", setWidthPercent);
  }, [collapseConfig]);

  const setWidthPercent = useCallback(() => {
    try {
      if (collapseConfig) setDrawerWidth("3rem");
      // Adjust width for different screen sizes
      else if (window.matchMedia("(min-width: 1024px) and (min-height: 1366px)").matches)
        // iPad Pro and similar devices
        setDrawerWidth("50%");
      else if (window.matchMedia("(min-width: 1024px)").matches) setDrawerWidth("30%");
      else if (window.matchMedia("(min-width: 640px)").matches) setDrawerWidth("50%");
      else setDrawerWidth("100%");
    } catch (err) {
      console.error("Error calculating width percent based on media size");
    }
  }, [collapseConfig]);

  useEffect(() => {
    if (types.length === 0) return;
    types.forEach((type) => {
      setCampaignsLoading((prev) => ({ ...prev, [type]: true }));
      getCampaigns(type)
        .then((data: any[]) => {
          setCampaigns((prev) => ({ ...prev, [type]: data }));
        })
        .catch((error) => {
          console.log(error);
          setCampaigns((prev) => ({ ...prev, [type]: [] }));
        })
        .finally(() => {
          setCampaignsLoading((prev) => ({ ...prev, [type]: false }));
        });
    });
  }, [types]);

  useEffect(() => {
    setPayloads(payloadConfiguration);
    setUpdateField((prev) => prev + 1);
  }, [payloadConfiguration]);

  useEffect(() => {
    if (payloads !== payloadConfiguration) setChanged(true);
    else setChanged(false);
  }, [payloads]);

  useEffect(() => {
    if (!action?.connectionId) return;
    getConnectionFromType(action?.connectionId).then((connection: any) => {
      setIsConnectionExists(connection?.userConnection ? true : false);
    });
  }, [action?.connectionId]);

  const saveOutputStructure = async () => {
    setLoading(true);
    const updated = updatePayloadConfig(payloads, action.id, false, action.continueOnFail, runCondition);
    setPayloads(updated);
    const updatedResponses = updateResponseConfig(responses, action.id);
    setResponses(updatedResponses);
    await saveWorkflowActions(id || "");
    setChanged(false);
    setLoading(false);
    setPublishWarning(true);
  };

  useEffect(() => {
    if (payloads !== payloadConfiguration || runCondition !== action.runCondition) {
      setChanged(true);
    } else setChanged(false);
  }, [payloads, runCondition]);

  const fieldSwitchCase = (field: any) => {
    switch (field.type) {
      case "dynamicDropdown":
        return (
          <Skeleton
            loading={campaignsLoading[field.valuesId] ?? true}
            paragraph={false}
            active={campaignsLoading[field.valuesId] ?? true}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""}
              onChange={(value) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                if (index === -1) {
                  temp.push({
                    payloadStructureId: field.payloadStructureId,
                    inputString: value,
                    type: field.type,
                  });
                } else {
                  temp[index].inputString = value;
                }
                setPayloads(temp);
              }}
              options={(campaigns[field.valuesId] ?? []).map((option: any) => ({
                label: option.label,
                value: option.id,
              }))}
            />
          </Skeleton>
        );
      case "dropdown":
        return (
          <Select
            showSearch
            style={{ width: "100%" }}
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""}
            onChange={(value) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: value,
                  type: field.type,
                });
              } else {
                temp[index].inputString = value;
              }
              setPayloads(temp);
            }}
            options={
              field?.values &&
              field.values?.map((option: any) => ({
                label: option.label,
                value: option.id,
              }))
            }
          />
        );
      case "jsonArray":
        return (
          <div className="w-full flex flex-col gap-4">
            {payloads
              .find((input) => input.payloadStructureId === field.payloadStructureId)
              ?.inputString?.map((_: any, idx: number) => {
                return (
                  <div className="flex gap-2 justify-between">
                    <div className="w-full" key={idx}>
                      <Skeleton
                        loading={campaignsLoading[field.valuesId] ?? true}
                        paragraph={false}
                        active={campaignsLoading[field.valuesId] ?? true}
                        style={{ marginBottom: "0.25rem" }}
                      >
                        <Select
                          showSearch
                          style={{ width: "100%", marginBottom: "0.25rem" }}
                          value={
                            payloads.find((input) => input.payloadStructureId === field.payloadStructureId)
                              ?.inputString[idx]?.label || ""
                          }
                          onChange={(value, options: any) => {
                            const temp = [...payloads];
                            const index = payloads.findIndex(
                              (input) => input.payloadStructureId === field.payloadStructureId
                            );
                            temp[index].inputString[idx].name = value;
                            temp[index].inputString[idx].label = options.label;
                            temp[index].inputString[idx].multi = options.active;
                            temp[index].inputString[idx].inputType = options.inputType;

                            setCampaignsLoading((prev) => ({ ...prev, [field?.valuesId]: false }));
                            setPayloads(temp);
                          }}
                          options={(campaigns[field.valuesId] ?? []).map((field: any) => ({
                            label: field?.name,
                            value: field?.id,
                            active: field?.accepts_multiple_values,
                            inputType: field?.type || field?.dataType,
                          }))}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                          }
                        />
                      </Skeleton>
                      <TiptapField
                        content={
                          payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString[
                            idx
                          ]?.tiptapJson || ""
                        }
                        setContent={(content: any, text: string) => {
                          const temp = [...payloads];
                          const index = payloads.findIndex(
                            (input) => input.payloadStructureId === field.payloadStructureId
                          );
                          temp[index].inputString[idx].value = text;
                          temp[index].inputString[idx].tiptapJson = content;
                          setPayloads(temp);
                        }}
                        refresh={updateField}
                        placeholder="Enter the value"
                        variables={variables}
                      />
                    </div>
                    <button
                      className="hover:bg-primary/10 h-fit w-fit p-2 rounded-full text-primary"
                      onClick={() => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        temp[index].inputString.splice(idx, 1);
                        setUpdateField((prev) => prev + 1);
                        setPayloads(temp);
                      }}
                    >
                      <MdDeleteOutline size={25} />
                    </button>
                  </div>
                );
              })}
            <button
              className="text-primary flex gap-2 items-center"
              onClick={() => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                temp[index].inputString.push({
                  name: "",
                  value: "",
                });
                setPayloads(temp);
              }}
            >
              <FaPlus />
              Add a new Variable Name and Variable Value pair
            </button>
          </div>
        );
      case "jsonArrayWithDropDown":
        return (
          <div className="w-full flex flex-col gap-4">
            {/* Handle Phones, Emails, URLs, etc. */}
            {payloads
              .find((input) => input.payloadStructureId === field.payloadStructureId)
              ?.inputString?.map((_: any, idx: number) => {
                return (
                  <div className="flex gap-2 justify-between" key={idx}>
                    {/* Dropdown for Type Selection */}
                    <Select
                      className="w-1/4"
                      value={
                        payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString[
                          idx
                        ]?.type || ""
                      }
                      onChange={(value) => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        temp[index].inputString[idx].type = value;
                        setPayloads(temp);
                      }}
                      options={field?.values?.map((option: any) => ({
                        label: option.label,
                        value: option.value,
                      }))}
                    />

                    {/* TiptapField for variables input */}
                    <div className="w-full">
                      <TiptapField
                        content={
                          payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString[
                            idx
                          ]?.tiptapJson || ""
                        }
                        setContent={(content: any, text: string) => {
                          const temp = [...payloads];
                          const index = payloads.findIndex(
                            (input) => input.payloadStructureId === field.payloadStructureId
                          );
                          temp[index].inputString[idx].value = text;
                          temp[index].inputString[idx].tiptapJson = content;
                          setPayloads(temp);
                        }}
                        refresh={updateField}
                        placeholder={`Enter ${
                          payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString[
                            idx
                          ]?.type || "value"
                        }`}
                        variables={variables}
                      />
                    </div>

                    {/* Delete Button */}
                    <button
                      className="hover:bg-red-200 h-fit w-fit p-2 rounded-full text-red-500"
                      onClick={() => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        temp[index].inputString.splice(idx, 1);
                        setPayloads(temp);
                      }}
                    >
                      <MdDeleteOutline size={25} />
                    </button>
                  </div>
                );
              })}

            {/* Add New Entry Button */}
            <button
              className="text-primary flex gap-2 items-center"
              onClick={() => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                temp[index].inputString.push({
                  type: "mobile", // Default type
                  value: "",
                  tiptapJson: "", // Initialize Tiptap content
                });
                setPayloads(temp);
              }}
            >
              <FaPlus />
              Add New Entry
            </button>
          </div>
        );
      case "jsonArrayStatic":
        // this was temporarily added for the GoHighLevel action
        return (
          <div className="flex flex-col gap-4">
            {payloads
              .find((input) => input.payloadStructureId === field.payloadStructureId)
              ?.inputString.map((input: any, idx: number) => {
                return (
                  <div className="flex flex-col gap-2 justify-between">
                    <div className="flex flex-col gap-2" key={idx}>
                      <div className="flex flex-row gap-2 items-center justify-between">
                        <input
                          type="text"
                          className="w-full rounded-lg"
                          placeholder="Enter the name"
                          value={input.name}
                          onChange={(e) => {
                            const temp = [...payloads];
                            const index = payloads.findIndex(
                              (input) => input.payloadStructureId === field.payloadStructureId
                            );
                            temp[index].inputString[idx].name = e.target.value;
                            setPayloads(temp);
                          }}
                        />
                        <button
                          className="hover:bg-primary/20 h-fit w-fit p-2 rounded-md text-primary border-2 bg-primary/10 transition-colors duration-300 ease-in-out"
                          onClick={() => {
                            const temp = [...payloads];
                            const index = payloads.findIndex(
                              (input) => input.payloadStructureId === field.payloadStructureId
                            );
                            temp[index].inputString.splice(idx, 1);
                            setUpdateField((prev) => prev + 1);
                            setPayloads(temp);
                          }}
                        >
                          <MdDeleteOutline size={25} />
                        </button>
                      </div>
                      <TiptapField
                        content={
                          payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString[
                            idx
                          ].tiptapJson || ""
                        }
                        setContent={(content: any, text: string) => {
                          const temp = [...payloads];
                          const index = payloads.findIndex(
                            (input) => input.payloadStructureId === field.payloadStructureId
                          );
                          temp[index].inputString[idx].value = text;
                          temp[index].inputString[idx].tiptapJson = content;
                          setPayloads(temp);
                        }}
                        refresh={updateField}
                        placeholder="Enter the value"
                        variables={variables}
                      />
                    </div>
                  </div>
                );
              })}
            <div className="flex flex-row gap-2">
              <button
                className="text-gray-900 font-semibold flex flex-row gap-2 items-center border-2 shadow-sm rounded-sm border-gray-400 w-fit px-3 py-2 text-md"
                onClick={() => {
                  const temp = [...payloads];
                  const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                  temp[index].inputString.push({
                    name: "",
                    value: "",
                    type: "json_array",
                  });
                  setPayloads(temp);
                }}
              >
                <FaPlus />
                Add key-value pair
              </button>
            </div>
          </div>
        );

      default:
        return (
          <textarea
            className="w-full h-32 rounded-lg"
            placeholder="Enter the value"
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""}
            onChange={(e) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: e.target.value,
                  type: field.type,
                });
              } else {
                temp[index].inputString = e.target.value;
              }
              setPayloads(temp);
            }}
          />
        );
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={modal}
        mask={false}
        onClose={() => setModal(false)}
        width={drawerWidth}
        styles={{
          content: {
            transition: "width 0.3s",
          },
          body: {
            padding: collapseConfig ? "0" : "24px",
          },
        }}
        classNames={{ header: !collapseConfig ? "border-b !border-gray-300" : "!border-none" }}
        className="ml-auto !font-favorit !p-0 border-l border-gray-500"
        getContainer={false}
        footer={
          !collapseConfig && (
            <div className="w-full flex flex-col gap-4 bg-white">
              {!isConnectionExists && (
                <Alert
                  message={
                    <>
                      Please add the required connection to proceed.{" "}
                      <Link to="/connections" target="_blank" rel="noopener noreferrer">
                        <i>Click here</i>
                      </Link>
                    </>
                  }
                  type="warning"
                  showIcon
                />
              )}
              <button
                className={` text-white w-full font-semibold rounded-lg p-2 mt-auto ${
                  loading || !changed || !isConnectionExists
                    ? "cursor-not-allowed bg-gray-500"
                    : "bg-primary cursor-pointer"
                }`}
                onClick={saveOutputStructure}
                disabled={loading || !changed || !isConnectionExists}
              >
                {loading ? "Saving..." : !changed ? "Saved" : "Save"}
              </button>
            </div>
          )
        }
        // check if the credits is a number , if (number) display here
        title={
          !collapseConfig && (
            <div className="flex items-center gap-2">
              <button className="p-0 rounded-sm" onClick={() => setCollapseConfig(true)}>
                <MdChevronRight size={25} />
              </button>
              <img src={action.logo} alt={action.name} className="w-6 h-6" />
              <span
                id="actionName"
                contentEditable={changeName}
                className={changeName ? "border border-black px-2" : ""}
                suppressContentEditableWarning
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.target.blur();
                  }
                }}
                onBlur={async (e) => {
                  if (!e.target.textContent) return;
                  setLoading(true);
                  updateActionName(action.id, e.target.textContent || action.name);
                  setChangeName(false);
                  await saveWorkflowActions(id || "");
                  setChanged(false);
                  setLoading(false);
                  setPublishWarning(true);
                }}
              >
                {action.name}
              </span>
              <button
                onClick={() => {
                  setChangeName(!changeName);
                  const p = document.getElementById("actionName");
                  if (!p) return;
                  setTimeout(function () {
                    const range = document.createRange();
                    const selection = window.getSelection();
                    range.selectNodeContents(p);
                    range.collapse(false); // <-- Set the cursor at the end of the selection
                    selection?.removeAllRanges();
                    selection?.addRange(range);
                    p.focus();
                  }, 0);
                }}
                className={`p-2 rounded-md transition-opacity duration-600 ease-in-out hover:bg-gray-200 ${
                  changeName ? "opacity-50" : "opacity-100"
                }`}
              >
                <FaRegEdit />
              </button>
            </div>
          )
        }
      >
        {collapseConfig ? (
          <button
            className="w-full h-full flex flex-col items-center justify-center p-3 gap-3"
            onClick={() => setCollapseConfig(false)}
          >
            <span className="p-0 border border-gray-300 rounded-sm">
              <MdChevronLeft size={25} />
            </span>
            <p className="[writing-mode:sideways-lr] text-center">CONFIG</p>
          </button>
        ) : (
          <div className="bg-white gap-10 flex flex-col">
            {action.payloadStructure.map((field, idx) => {
              return (
                <div key={idx} className="flex flex-col w-full gap-4 p-4 border border-gray-300 rounded-lg">
                  <div>
                    <p className="tracking-primary font-semibold">
                      {field.name.toUpperCase()}
                      {!field.required && (
                        <span className="text-sm font-normal tracking-normal text-gray-400"> (Optional)</span>
                      )}
                    </p>
                    <p className="text-sm font-light">{field.description}</p>
                  </div>
                  {field.needVars ? (
                    <TiptapField
                      content={
                        payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.tiptapJson ||
                        ""
                      }
                      setContent={(content: any, text: string) => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        if (index === -1) {
                          temp.push({
                            payloadStructureId: field.payloadStructureId,
                            tiptapJson: content,
                            inputString: text,
                            type: field.type,
                          });
                        } else {
                          temp[index].tiptapJson = content;
                          temp[index].inputString = text;
                        }
                        setPayloads(temp);
                      }}
                      refresh={updateField}
                      placeholder={field.name}
                      variables={variables}
                    />
                  ) : (
                    fieldSwitchCase(field)
                  )}
                </div>
              );
            })}
            <div>
              Continue workflow even if this fails:{" "}
              <Switch
                defaultChecked={action.continueOnFail}
                onChange={(value) => {
                  setChanged(true);
                  action.continueOnFail = value;
                }}
              />
            </div>
            <RunCondition
              variables={variables}
              responses={action.responseStructure}
              conditionsArr={
                runCondition || {
                  conditions: [],
                  otherwise: [],
                }
              }
              setConditionsArr={setRunCondition}
            />
            <ResponseStructureViewer
              setResponses={setResponses}
              responses={responses}
              action={action}
              setChanged={setChanged}
            />
          </div>
        )}
      </Drawer>
    </ConfigProvider>
  );
};

export default CloseCrmModal;

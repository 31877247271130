import { useCallback, useEffect, useState } from "react";
import { Button, Input, Menu, message, Popover, Select } from "antd";

import { cn } from "@/utils/cn";

import RenameIcon from "../../assets/SVGs/RenameIcon.svg";

import { renameWorkflow, shareWorkflowAccess, getAllSubfloqs, createAndSaveSubfloq } from "@/utils/apis";
import _debounce from "lodash/debounce";
import _ from "lodash";
import { getEmailFromToken } from "@/utils/functions";
import clsx from "clsx";
import { IoMdShare } from "react-icons/io";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import SearchIcon from "../../assets/SVGs/SearchIcon.svg";
import DeleteWorkflow from "../UserWorkflows/DeleteWorkflow";
import SubfloqRenderer from "../UserWorkflows/TitleRender/SubfloqRenderer";
import CreateIcon from "../../assets/SVGs/CreateIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import LoaderButton from "../LoaderButton";
import Workflows from "../UserWorkflows";
import NavTabs from "../Generics/NavTabs";
import WorkflowSkeleton from "../Generics/WorkflowSkeleton";

const UserSubfloqs = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteWorkflow, setDeleteWorkflow] = useState<any>(null);
  const [isV2, _setIsV2] = useState(false);
  const [toRenameWorkflow, setToRenameWorkflow] = useState<any>(null);
  // const [shareType, setShareType] = useState<"individual" | "organization" | "community">("individual");

  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [allSubfloqs, setAllSubfloqs] = useState<Map<string, any>>();
  const [filteredSubfloqs, setFilteredSubfloqs] = useState<Map<string, any>>(new Map());
  const [currentURL, setCurrentURL] = useState<string>("");

  const navigate = useNavigate();
  const location = useLocation();

  const [visible, setVisible] = useState(false);
  const [subfloqName, setSubfloqName] = useState("");

  const handleCreate = async () => {
    if (!subfloqName.trim()) {
      message.error("Please name your subfloq");
      return;
    }
    const subfloqId = await createAndSaveSubfloq(
      subfloqName,
      "",
      { requiredInputs: [], responses: [], selectedActions: [], shareLevel: "individual" },
      true
    );
    if (subfloqId) navigate(`/subfloqs/${subfloqId}?v2=true`);
    message.info("SubFloq created successfully!");
    setSubfloqName("");
    setVisible(false);
  };

  const userEmail = getEmailFromToken();

  useEffect(() => {
    const fetchSubfloqs = async () => {
      try {
        setLoading(true);
        const subfloqs = await getAllSubfloqs();

        // Step 2: Create a Map for instant lookup
        const subfloqsMap: Map<string, any> = new Map(
          subfloqs.map((subfloq: any) => {
            return [subfloq.id, subfloq]; // Map key: `id`, value: `enrichedWorkflow`
          })
        );

        setAllSubfloqs(subfloqsMap);
        setFilteredSubfloqs(subfloqsMap);
        setLoading(false);
      } catch (error) {
        window.location.reload();
      }
    };
    fetchSubfloqs();
    setCurrentURL(location.pathname);
  }, []);

  const debounceFn = useCallback(
    _debounce((value: string) => {
      if (!allSubfloqs) return;
      // Filter subfloqs based on the search query
      const filtered = new Map(
        Array.from(allSubfloqs.entries()).filter(([_key, subfloq]) =>
          subfloq.name?.toLowerCase().includes(value.toLowerCase())
        )
      );

      setFilteredSubfloqs(filtered);
    }, 500),
    [allSubfloqs]
  );

  // const handleDeleteClick = (workflow: any) => {
  //   setDeleteWorkflow(workflow);
  //   setIsV2(workflow.supabase || false);
  // };

  const renameWorkflowFunc = async (workflow: any, newWorkflowName: string) => {
    setToRenameWorkflow(null);

    if ((workflow.name || workflow.draftWorkflowConfig.name) === newWorkflowName || !newWorkflowName.trim()) return;

    setAllSubfloqs((prev: any) => {
      const w = prev.get(workflow.id);
      if (w.name) w.name = newWorkflowName;
      else w.draftWorkflowConfig.name = newWorkflowName;

      return prev;
    });

    try {
      await renameWorkflow(workflow.id, newWorkflowName, workflow.supabase || false);
    } catch (error) {
      message.error("Failed to rename workflow.");
      window.location.reload();
    }
  };

  const handleRenamed = (workflow: any) => setToRenameWorkflow(workflow);

  const handleShareWorkflow = async (selectedEmails: string[], workflow: any) => {
    if (selectedEmails.length === 0) return;
    const workflowId = workflow?.id;

    const sharingData = {
      v2: workflow.supabase,
      key: `workflow=${workflow.id}`,
      isLeaf: true,
    };

    try {
      await shareWorkflowAccess(workflowId, selectedEmails, "add", sharingData);
    } catch (error) {
      message.error("Failed to share workflow and save the structure.");
      window.location.reload();
    }
    setSelectedEmails([]);
  };

  const getWorkflowActionMenu = (subfloq: any) => (
    <Menu
      className="rounded-lg"
      items={[
        {
          key: "rename",
          label: (
            <div className="flex items-center text-base gap-2 py-1">
              <img src={RenameIcon} alt="rename" width={16} className="opacity-60" />
              <span>Rename</span>
            </div>
          ),
          onClick: () => handleRenamed(subfloq),
        },
        subfloq && {
          key: "share",
          label: (
            <div className="flex items-center text-base gap-2 py-1">
              <IoMdShare size={16} className="text-gray-500" />
              <span>Share</span>
            </div>
          ),
          children: [
            {
              key: "share-checkboxes",
              label: (
                <div className="space-y-2 h-40 overflow-y-auto">
                  <Select
                    defaultValue={subfloq.share_type}
                    className="w-full"
                    // onChange={(value) => setShareType(value)}
                    options={[
                      { label: "Individual (Only you can access)", value: "individual" },
                      { label: "Organization (Everyone from your org.)", value: "organization" },
                      { label: "Community (Everyone using Floqer)", value: "community" },
                    ]}
                  />
                </div>
              ),
            },
            {
              key: "share-button",
              label: (
                <Button
                  onClick={() => handleShareWorkflow(selectedEmails, subfloq)}
                  className={clsx(
                    "mt-4 w-full py-2 rounded border border-gray-800 text-gray-800 hover:bg-gray-800 hover:text-white",
                    selectedEmails.length === 0 && "opacity-50 cursor-not-allowed"
                  )}
                  disabled={selectedEmails.length === 0}
                >
                  Share Permission
                </Button>
              ),
            },
          ],
        },
        // {
        //   type: "divider",
        // },
        // {
        //   key: "delete",
        //   label: (
        //     <div className="flex items-center text-base gap-2 py-1 text-red-600">
        //       <img src={DeleteIcon} alt="delete" width={16} className="opacity-60" />
        //       <span>Delete</span>
        //     </div>
        //   ),
        //   onClick: () => handleDeleteClick(subfloq),
        // },
      ]}
    />
  );

  const popoverContent = (
    <div className="flex flex-col gap-2">
      <Input
        className="rounded"
        placeholder="Enter subfloq name"
        value={subfloqName}
        onChange={(e) => setSubfloqName(e.target.value)}
      />
      <LoaderButton
        text="Create"
        loaderText="Creating..."
        btnClasses="mt-auto !bg-black"
        onClickCallback={async () => await handleCreate()}
        error=""
        disabled={false}
      />
    </div>
  );

  const RenameWorkflowInput = () => {
    const { register } = useFormContext();

    return (
      <input
        {...register("workflowName")}
        className={cn(
          "focus:!outline-none",
          "w-[85%] rounded-sm focus:!border-[#efe5ffc7] focus:!ring-0 focus:!ring-offset-0 !text-md !text-black px-0 py-1"
        )}
        autoFocus
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.currentTarget.blur();
          }
        }}
      />
    );
  };

  const RenameWorkflow = () => {
    const methods = useForm({
      defaultValues: {
        workflowName: toRenameWorkflow?.name || toRenameWorkflow?.draftWorkflowConfig?.name || "",
      },
    });

    const onBlur = async (data: { workflowName: string }) => {
      await renameWorkflowFunc(toRenameWorkflow, data.workflowName);
    };

    return (
      <FormProvider {...methods}>
        <form className="inputStyleCSS flex items-center gap-x-4 w-full" onBlur={methods.handleSubmit(onBlur)}>
          <RenameWorkflowInput />
        </form>
      </FormProvider>
    );
  };

  if (currentURL === "/workflows") {
    return <Workflows />;
  }

  return (
    <div className="h-full w-full flex flex-col !cursor-default gap-5">
      <NavTabs currentURL={currentURL} />
      <div className="flex flex-row justify-between items-center w-full px-5">
        <div className="inputStyleCSS h-fit w-2/3 flex justify-between items-center px-4 border border-black rounded-md">
          <img src={SearchIcon} alt="search icon" width={20} />
          <input
            type="text"
            placeholder="Search Your Subfloqs"
            onChange={(e) => debounceFn(e?.target?.value)}
            className={cn(
              "focus:!outline-none ",
              "w-full rounded-md focus:!border-none !border-none focus:!ring-0 focus:!ring-offset-0 !text-black"
            )}
          />
        </div>
        <Popover
          content={popoverContent}
          title="Create Subfloq"
          trigger="click"
          open={visible}
          onOpenChange={setVisible}
          placement="left"
        >
          <button
            onClick={(e) => e.preventDefault()}
            className="flex gap-x-2 items-center bg-black hover:bg-gray-800 py-2 px-4 rounded-md font-medium text-white"
          >
            <img src={CreateIcon} alt="create-workflow" width={16} /> Create
          </button>
        </Popover>
      </div>

      <div>
        <div className="px-8 py-2 text-md grid grid-cols-8 font-semibold text-gray-700 border-b-[0.5px] border-gray-300">
          <div className="text-lg col-span-6">Title</div>
          <div className="text-lg col-span-2">Created On</div>
        </div>
        {!loading && filteredSubfloqs ? (
          Array.from(filteredSubfloqs.values()).map((subfloq: any) => (
            <SubfloqRenderer
              subfloq={subfloq}
              getWorkflowActionMenu={getWorkflowActionMenu}
              RenameWorkflow={RenameWorkflow}
              toRenameWorkflow={toRenameWorkflow}
            />
          ))
        ) : (
          <WorkflowSkeleton />
        )}
      </div>

      {deleteWorkflow && (
        <DeleteWorkflow
          modal={!!deleteWorkflow}
          close={() => setDeleteWorkflow(null)}
          workflow={deleteWorkflow}
          isV2={isV2}
          userEmail={userEmail}
        />
      )}
    </div>
  );
};
export default UserSubfloqs;

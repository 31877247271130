import { App, Modal, Tag } from "antd";
import { useEffect, useState } from "react";
import { fetchTransactionsForWorkflowActionDataPart } from "@/utils/apis";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import moment from "moment";
import { FaCoins } from "react-icons/fa";
import { useWorkflow } from "@/contexts/WorkflowContext";
import { getStatusColor } from "../utils";

interface Transaction {
  created_at: string;
  actionName: string;
  email: string;
  total_credits_consumed: number;
  status: string;
  error_message: string;
  totalCreditsNeeded: number;
}

type Props = {
  open: boolean;
  close: () => void;
  workflowId: string;
  dataId: string;
};

const CreditUsageModal = ({ open, close, workflowId, dataId }: Props) => {
  const { actions } = useWorkflow();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!open) return;

    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const response = await fetchTransactionsForWorkflowActionDataPart(workflowId, dataId);
        setTransactions(
          (response.transactions || []).sort((a: any, b: any) => b.created_at.localeCompare(a.created_at))
        );
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [open, workflowId, dataId]);

  const getTotalCreditsConsumed = () => {
    if (!Array.isArray(transactions)) return 0;
    return transactions.reduce((acc, curr) => acc + curr.total_credits_consumed, 0);
  };

  const getActionName = (actionId: string) => {
    const action = actions.find((a) => a.actionName === actionId);
    return action?.actionDetails?.name || action?.displayName || actionId;
  };

  return (
    <Modal
      title={
        <div className="flex justify-between items-center">
          <span>Credit Usage History</span>
          <div className="flex items-center gap-2 bg-purple-100 px-3 py-1 rounded">
            <span className="text-sm font-semibold">Total Credits: {getTotalCreditsConsumed()}</span>
            <FaCoins className="text-purple-500" />
          </div>
        </div>
      }
      open={open}
      onCancel={close}
      footer={null}
      width={800}
    >
      <App className="h-[75vh] overflow-auto">
        {loading ? (
          <div className="flex justify-center items-center py-8">
            <SpinnerStatus /> Loading transactions...
          </div>
        ) : transactions.length > 0 ? (
          <div className="space-y-4">
            {transactions.map((transaction, index) => (
              <div key={index} className="border rounded-lg p-4 bg-white hover:bg-gray-50 transition-colors">
                <div className="flex justify-between items-start mb-2">
                  <div>
                    <h3 className="text-lg font-semibold">{getActionName(transaction.actionName)}</h3>
                    <p className="text-sm text-gray-500">
                      {moment(transaction.created_at).format("MMM DD, YYYY HH:mm:ss")}
                    </p>
                  </div>
                  <Tag color={getStatusColor(transaction.status)} className="uppercase">
                    {transaction.status}
                  </Tag>
                </div>

                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div>
                    <p className="text-sm text-gray-500">Credits Consumed</p>
                    <div className="flex items-center gap-1">
                      <span className="font-medium">{transaction.total_credits_consumed}</span>
                      <FaCoins className="text-purple-500 text-sm" />
                    </div>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Credits Needed</p>
                    <div className="flex items-center gap-1">
                      <span className="font-medium">{transaction.totalCreditsNeeded}</span>
                      <FaCoins className="text-purple-500 text-sm" />
                    </div>
                  </div>
                </div>

                {transaction.error_message && (
                  <div className="mt-4">
                    <p className="text-sm text-gray-500">Message</p>
                    <p className="text-sm font-medium text-gray-700">{transaction.error_message}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-8 text-gray-500">No transaction data available</div>
        )}
      </App>
    </Modal>
  );
};

export default CreditUsageModal;

import { useEffect, useState } from "react";
import CollapseItemWrapper from "./DocViewer/CollapseItemWrapper";
import { FaCoins } from "react-icons/fa";

type Props = {
  open: boolean;
  data: any;
  credits: any;
};

const TenKDocViewer = ({ open, data, credits }: Props) => {
  const [arr, setArr] = useState<any[]>([]);

  useEffect(() => {
    if (!open) setArr([]);
  }, [open]);

  useEffect(() => {
    if (!data) return;
    setArr(() => [...Object.values(data).filter((itr: any) => itr.value)]);
  }, [data]);

  return (
    <>
      <div className="w-full h-full overflow-y-auto overflow-x-hidden">
        {arr.map((itr, idx) => (
          <CollapseItemWrapper idx={idx} itr={itr} />
        ))}
      </div>
      <div className="mt-4 flex items-center">
        <h1 className="font-semibold text-md">Total cost:</h1>
        <div className="flex items-center ml-2 bg-purple-100 rounded p-1">
          <span className="font-bold text-md">{credits}</span>
          <FaCoins className="ml-1 text-purple-500 w-5" />
        </div>
      </div>
    </>
  );
};

export default TenKDocViewer;

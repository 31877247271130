import { message, Pagination, Skeleton, Splitter, Switch } from "antd";
import { useEffect, useState } from "react";
import { getActionDataOnBuild, updateDataForRunOnFloq, updateWorkflowSettings } from "@/utils/apis";
import { useParams } from "react-router-dom";
import PlayButton from "@/assets/SVGs/buildTab/Run.svg";
import WhitePlayButton from "@/assets/SVGs/buildTab/WhiteRun.svg";
import { CELL_STATUS_SYMBOL, USER_ACTION_STATUS } from "@/utils/constants";
import { fetchDocContent, isApplicableURL } from "@/utils/functions";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import { Action, PayloadConfiguration, ResponseConfiguration } from "@/utils/interfaces";
import { MdChevronRight } from "react-icons/md";
import ResponseModal from "./ResponseModal";
import { useInterval } from "usehooks-ts";
import { useRunOnFloq } from "@/contexts/RunOnFloqContext";
import InputViewer from "../../Modals/InputViewer";
import { FeedbackButtons, OutputFeedback } from "../../ActionModals/FeedbackForm";

type Props = {
  action: Action;
  responseConfiguration: ResponseConfiguration[];
  payloadConfiguration: PayloadConfiguration[];
  close: () => void;
  openRow: boolean;
  setOpenRow: (value: boolean) => void;
  sections: any;
};

const STATUS_LABEL_MAP = {
  [USER_ACTION_STATUS.COMPLETED]: "View",
  [USER_ACTION_STATUS.FAILED]: "𝘕𝘰 𝘥𝘢𝘵𝘢 𝘧𝘰𝘶𝘯𝘥",
  [USER_ACTION_STATUS.PENDING]: "𝘕𝘰𝘵 𝘳𝘶𝘯",
  [USER_ACTION_STATUS.PAYLOAD_FILLED]: "In Progress",
  [USER_ACTION_STATUS.ERROR]: "𝘌𝘳𝘳𝘰𝘳",
  [USER_ACTION_STATUS.OUT_OF_CREDITS]: "𝘖𝘶𝘵 𝘰𝘧 𝘤𝘳𝘦𝘥𝘪𝘵𝘴",
  [USER_ACTION_STATUS.CHECKING_NEXT_SOURCE]: "Checking Next Source",
  [USER_ACTION_STATUS.CONDITION_NOT_MET]: "Condition not met",
};

const NO_OPEN_STATUS = [
  USER_ACTION_STATUS.CHECKING_NEXT_SOURCE,
  USER_ACTION_STATUS.PAYLOAD_FILLED,
  USER_ACTION_STATUS.CONDITION_NOT_MET,
  USER_ACTION_STATUS.OUT_OF_CREDITS,
  USER_ACTION_STATUS.PENDING,
];

const RunModal = ({
  action,
  responseConfiguration,
  payloadConfiguration,
  close,
  openRow,
  setOpenRow,
  sections,
}: Props) => {
  const { data, setData, runOnFloq, continueRun, setContinueRun } = useRunOnFloq();
  const [responses, setResponses] = useState<ResponseConfiguration[]>([]);
  const [loading, setLoading] = useState(false);
  const [runLoading, setRunLoading] = useState(false);
  const [_, setRegenerating] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 20, total: 0 });
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [currWidth, setCurrWidth] = useState<number | string>("75%");
  const [actionFeedbackState, setActionFeedbackState] = useState<
    Record<
      string,
      {
        showButtons: boolean;
        showForm: boolean;
      }
    >
  >({});
  const [actionsWithGeneratorRun, setActionsWithGeneratorRun] = useState<Record<string, boolean>>({});
  const { id } = useParams();
  const key = `${id}-${action.id}`;

  useEffect(() => {
    const storedWidth = localStorage.getItem(key);
    if (storedWidth) setCurrWidth(storedWidth.includes("%") ? storedWidth : Number(storedWidth));
    else localStorage.setItem(key, currWidth.toString());
  }, []);

  useEffect(() => {
    const storedWidth = localStorage.getItem(key);
    if (storedWidth) localStorage.setItem(key, currWidth.toString());
  }, [currWidth]);

  useEffect(() => {
    if (!action) return setData([]);
    const finalResponse = responseConfiguration?.map((res) => {
      if (action.outputStructureType === "predefined") {
        const structures: any[] = action.responseStructure;
        const match = structures.find((structure) => structure.responseStructureId === res.responseStructureId);
        return { ...res, ...match };
      }
      return res;
    });
    setResponses(finalResponse || []);
  }, [action]);

  // Initialize feedback state based on localStorage when component mounts
  useEffect(() => {
    if (!action?.id) return;

    try {
      // Check if this action has been run with a generator previously in this session
      const hasGeneratorRun = localStorage.getItem(`floq_generator_run_${action.id}`) === "true";

      if (hasGeneratorRun) {
        // If it has, update our state to reflect that
        setActionsWithGeneratorRun((prev) => ({ ...prev, [action.id]: true }));
        setActionFeedbackState((prev) => ({
          ...prev,
          [action.id]: {
            showButtons: true,
            showForm: false,
          },
        }));
      }
    } catch (e) {
      console.error("Error checking generator run status:", e);
    }
  }, [action?.id]);

  useEffect(() => {
    if (!action) return setPagination({ current: 1, pageSize: 20, total: 0 });
    setLoading(true);
    getActionDataOnBuild(id || "", action.id, sections[action.id] || 0, pagination.current, pagination.pageSize)
      .then(async (res) => {
        const normalizedData = await Promise.all(
          res.data.map(async (row: any) => {
            let response = row.response;
            if (
              row.status === USER_ACTION_STATUS.COMPLETED &&
              typeof response === "string" &&
              isApplicableURL(response)
            )
              response = await fetchDocContent(response);
            return { ...row, response };
          })
        );
        setData(normalizedData);
        setPagination({ ...pagination, total: res.count });
      })
      .catch(() => {
        message.error("Error fetching data");
      })
      .finally(() => setLoading(false));
  }, [action, pagination.current, pagination.pageSize]);

  useInterval(() => {
    const inProgressIds = data
      .filter((row) =>
        [USER_ACTION_STATUS.PAYLOAD_FILLED, USER_ACTION_STATUS.CHECKING_NEXT_SOURCE].includes(row.status)
      )
      .map((row) => row.dataId);
    if (inProgressIds.length === 0) return;
    updateDataForRunOnFloq(action.id, id || "", inProgressIds, sections[action.id] || 0).then((res) => {
      res.map((row: any) => {
        setData((prev: any) => {
          const index = prev.findIndex((item: any) => item.dataId === row.dataId);
          if (index !== -1) prev[index] = row;
          return [...prev];
        });
      });
    });
  }, 7000);

  // Add effect to check regeneration status
  useEffect(() => {
    if (!action?.id) return;

    const checkRegenerationStatus = () => {
      try {
        const isRegenerating = localStorage.getItem(`floq_regenerating_${action.id}`) === "true";
        setRegenerating(isRegenerating);
      } catch (e) {
        console.error("Error checking regeneration status:", e);
      }
    };

    // Check immediately
    checkRegenerationStatus();

    // And set up interval to check regularly
    const intervalId = setInterval(checkRegenerationStatus, 1000);
    return () => clearInterval(intervalId);
  }, [action?.id]);

  // Add event listener for generator run events from ActionModal
  useEffect(() => {
    if (!action?.id) return;

    const handleGeneratorRun = (event: CustomEvent) => {
      const { actionId } = event.detail;

      // Only process if this is for our current action
      if (actionId === action.id) {
        console.log("Received generator run event for action:", actionId);

        // Update the UI state to show feedback buttons
        setActionsWithGeneratorRun((prev) => ({ ...prev, [action.id]: true }));
        setActionFeedbackState((prev) => ({
          ...prev,
          [action.id]: {
            showButtons: true,
            showForm: false,
          },
        }));
      }
    };

    // Add event listener
    window.addEventListener("floqGeneratorRun", handleGeneratorRun as EventListener);

    // Clean up
    return () => {
      window.removeEventListener("floqGeneratorRun", handleGeneratorRun as EventListener);
    };
  }, [action?.id]);

  const getLocaleNumber = (number: string | number) => {
    const formattedNumber = number.toLocaleString("en-US");
    return formattedNumber;
  };

  const runRows = async (type: string, singleId?: string) => {
    setRunLoading(true);
    await runOnFloq(action.id, id || "", sections[action.id] || 0, type, singleId);
    setRunLoading(false);

    // If this is a data formatter action with AI prompt support, show feedback buttons after running
    if (action?.payloadStructure?.some((field) => field.supports_ai_prompt)) {
      // Update action-specific feedback state
      setActionFeedbackState((prev) => ({
        ...prev,
        [action.id]: {
          showButtons: true,
          showForm: false,
        },
      }));
      setActionsWithGeneratorRun((prev) => ({ ...prev, [action.id]: true }));

      // Store this information in localStorage for persistence
      try {
        localStorage.setItem(`floq_generator_run_${action.id}`, "true");
      } catch (e) {
        console.error("Error storing generator run status:", e);
      }
    }
  };

  // Feedback handlers
  const handleWrongOutput = () => {
    setActionFeedbackState((prev) => ({
      ...prev,
      [action.id]: {
        ...prev[action.id],
        showForm: true,
      },
    }));
  };

  const handleFeedbackSubmit = (feedback: string[]) => {
    console.log("Feedback submitted:", feedback);

    // Close the form - update action-specific state
    setActionFeedbackState((prev) => ({
      ...prev,
      [action.id]: {
        showButtons: false,
        showForm: false,
      },
    }));
    setRegenerating(true);

    // Set regeneration status in localStorage to communicate with other components
    if (action?.id) {
      try {
        localStorage.setItem(`floq_regenerating_${action.id}`, "true");
      } catch (e) {
        console.error("Error setting regeneration status:", e);
      }
    }

    message.success("Thanks for your feedback! Regenerating formula...");

    // Get the current action's field that needs regeneration
    if (action && action.id) {
      // Find the field that supports AI prompt (typically Data Formatter)
      const promptField = action.payloadStructure?.find((field) => field.supports_ai_prompt);

      if (promptField) {
        console.log("Found prompt field:", promptField.payloadStructureId);

        try {
          // Create a Promise that can be resolved by the ActionModal when regeneration is complete
          const regenerationCompletedPromise = new Promise<void>((resolve) => {
            // Add a one-time event listener that will be triggered when regeneration completes
            const handleRegenerationComplete = () => {
              console.log("Received regeneration complete event");
              resolve();
              window.removeEventListener("floqRegenerationComplete", handleRegenerationComplete);
            };

            window.addEventListener("floqRegenerationComplete", handleRegenerationComplete, { once: true });

            // Set a timeout to resolve the promise after 15 seconds if no response
            // This prevents hanging if something goes wrong
            setTimeout(() => {
              console.log("Regeneration timeout reached, proceeding anyway");
              resolve();
              window.removeEventListener("floqRegenerationComplete", handleRegenerationComplete);
            }, 15000);

            // Dispatch a regeneration request event for ActionModal to handle
            const regenerationEvent = new CustomEvent("floqRegenerationRequest", {
              detail: {
                actionId: action.id,
                fieldId: promptField.payloadStructureId,
                sampleOutputs: feedback,
              },
            });

            // Dispatch the event
            window.dispatchEvent(regenerationEvent);
          });

          // Wait for regeneration to complete, then run the first 5 rows
          regenerationCompletedPromise
            .then(() => {
              console.log("Regeneration completed, running first 5 rows");
              return runRows("first5");
            })
            .then(() => {
              console.log("First 5 rows run completed");
              // Re-enable feedback buttons and reset regeneration state
              setActionFeedbackState((prev) => ({
                ...prev,
                [action.id]: {
                  ...prev[action.id],
                  showButtons: true,
                },
              }));
              setRegenerating(false);

              // Clear regeneration status in localStorage
              try {
                localStorage.removeItem(`floq_regenerating_${action.id}`);
              } catch (e) {
                console.error("Error clearing regeneration status:", e);
              }
            })
            .catch((error) => {
              console.error("Error during regeneration process:", error);
              message.error("An error occurred during regeneration");
              setActionFeedbackState((prev) => ({
                ...prev,
                [action.id]: {
                  ...prev[action.id],
                  showButtons: true,
                },
              }));
              setRegenerating(false);

              // Clear regeneration status in localStorage
              try {
                localStorage.removeItem(`floq_regenerating_${action.id}`);
              } catch (e) {
                console.error("Error clearing regeneration status:", e);
              }
            });
        } catch (error) {
          console.error("Error setting up regeneration:", error);
          message.error("Failed to regenerate formula");
          setActionFeedbackState((prev) => ({
            ...prev,
            [action.id]: {
              ...prev[action.id],
              showButtons: true,
            },
          }));
          setRegenerating(false);

          // Clear regeneration status in localStorage
          try {
            localStorage.removeItem(`floq_regenerating_${action.id}`);
          } catch (e) {
            console.error("Error clearing regeneration status:", e);
          }
        }
      } else {
        console.error("No field with supports_ai_prompt found in action:", action);
        message.error("Could not find a field that supports AI prompts");
        setActionFeedbackState((prev) => ({
          ...prev,
          [action.id]: {
            ...prev[action.id],
            showButtons: true,
          },
        }));
        setRegenerating(false);

        // Clear regeneration status in localStorage
        try {
          localStorage.removeItem(`floq_regenerating_${action.id}`);
        } catch (e) {
          console.error("Error clearing regeneration status:", e);
        }
      }
    } else {
      console.error("Invalid action:", action);
      message.error("Invalid action configuration");
      setActionFeedbackState((prev) => ({
        ...prev,
        [action.id]: {
          ...prev[action.id],
          showButtons: true,
        },
      }));
      setRegenerating(false);

      // Clear regeneration status in localStorage if we have an action ID
      if (action?.id) {
        try {
          localStorage.removeItem(`floq_regenerating_${action.id}`);
        } catch (e) {
          console.error("Error clearing regeneration status:", e);
        }
      }
    }
  };

  const handleFeedbackCancel = () => {
    setActionFeedbackState((prev) => ({
      ...prev,
      [action.id]: {
        ...prev[action.id],
        showForm: false,
      },
    }));
  };

  // Get current action's feedback state
  const currentActionFeedback = actionFeedbackState[action.id] || { showButtons: false, showForm: false };

  return (
    <div className="flex h-full w-full">
      <Splitter onResize={(x) => setCurrWidth(x[0])}>
        <Splitter.Panel size={currWidth} min="37%" max="85%">
          <div className="flex flex-col h-full border-l border-gray-500" onClick={(e) => e.stopPropagation()}>
            <div className="flex items-center w-full px-4 py-[1.17rem] gap-1">
              <button className="p-0 border border-gray-300 rounded-sm mr-2" onClick={() => close()}>
                <MdChevronRight size={25} />
              </button>
              <img src={WhitePlayButton} alt="Run" className="w-5 h-5" />
              <h2 className="text-md font-semibold">Runs</h2>
            </div>
            {action.id !== "id1" && (
              <div className="flex flex-col p-4 gap-4 bg-white border-y border-gray-300">
                <div className="flex items-center gap-2">
                  <Switch
                    defaultChecked={continueRun}
                    onChange={async () => {
                      try {
                        setContinueRun(!continueRun);
                        await updateWorkflowSettings(id || "", {
                          continue_run: !continueRun,
                        });
                      } catch (err) {
                        message.error("Error updating continue all");
                      }
                    }}
                    className="w-fit py-2 bg-gray-100"
                  />
                  <p>Continue all?</p>
                </div>
                <div className="flex justify-between items-center">
                  <button
                    className="flex items-center gap-1 border border-primaryBlack px-2 py-1 rounded hover:bg-[#D9CFFF]/25 hover:shadow-md"
                    onClick={() => runRows("first10")}
                    disabled={runLoading}
                  >
                    {runLoading ? <SpinnerStatus /> : <img src={PlayButton} alt="Run" className="w-5 h-5" />}
                    <p className="text-sm font-normal">First 10</p>
                  </button>
                  <div className="flex items-center gap-2">
                    <button
                      className="flex items-center gap-1 border border-primaryBlack px-2 py-1 rounded hover:bg-[#D9CFFF]/25 hover:shadow-md"
                      disabled={runLoading}
                      onClick={() => runRows("all")}
                    >
                      {runLoading ? <SpinnerStatus /> : <img src={PlayButton} alt="Run" className="w-5 h-5" />}
                      <p className="text-sm font-normal">All</p>
                    </button>
                    <button
                      className="flex items-center gap-1 border border-primaryBlack px-2 py-1 rounded hover:bg-[#D9CFFF]/25 hover:shadow-md"
                      onClick={() => runRows("failed")}
                      disabled={runLoading}
                    >
                      {runLoading ? <SpinnerStatus /> : <img src={PlayButton} alt="Run" className="w-5 h-5" />}
                      <p className="text-sm font-normal">Failed/Pending</p>
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="flex flex-col overflow-y-auto flex-grow">
              <p className="sticky top-0 px-4 py-2 border-y border-gray-300 text-gray-500 font-medium text-md bg-gray-100">
                {action.name}
              </p>
              <Skeleton loading={loading} active>
                {action.id === "id1" ? (
                  <InputViewer responseConfig={responseConfiguration} responses={data} />
                ) : (
                  data.map((row: any, idx: number) => {
                    return (
                      <div
                        className="flex items-center gap-2 px-4 py-2 border-y border-gray-300 hover:bg-gray-50 cursor-pointer group"
                        onClick={() => {
                          if (NO_OPEN_STATUS.includes(row.status)) return setOpenRow(false);
                          setOpenRow(true);
                          setSelectedRow(row);
                        }}
                        key={idx}
                      >
                        {CELL_STATUS_SYMBOL[row.status]}
                        <p className="font-normal text-md text-left line-clamp-1 w-[99%]">
                          {row.response && !Array.isArray(row.response) && row.response.length === 1
                            ? String(row.response[0]?.value)
                            : STATUS_LABEL_MAP[row.status as keyof typeof STATUS_LABEL_MAP]}
                        </p>
                        {action.id !== "id1" && (
                          <button
                            className="invisible group-hover:visible flex items-center gap-1 border border-primaryBlack px-2 py-1 rounded hover:bg-[#D9CFFF]/25 hover:shadow-md"
                            onClick={(e) => {
                              e.stopPropagation();
                              runRows("singleCell", row.dataId);
                            }}
                          >
                            <img src={PlayButton} alt="Run" className="w-4 h-4" />
                          </button>
                        )}
                      </div>
                    );
                  })
                )}
              </Skeleton>
            </div>

            <Pagination
              className="px-4 py-2 bg-gray-100 flex items-center border-t border-gray-300 mt-0"
              total={pagination.total}
              showTotal={(total, range) => (
                <p className="text-sm text-gray-500 h-full flex items-center justify-center">
                  {range[1]}/{getLocaleNumber(total)} rows
                </p>
              )}
              pageSize={pagination.pageSize || 20}
              current={pagination.current}
              pageSizeOptions={[10, 20, 50]}
              showSizeChanger
              showLessItems
              simple
              onChange={(page, pageSize) => {
                if (loading) {
                  return;
                }
                setPagination({ ...pagination, current: page, pageSize });
              }}
            />

            <div className="px-4 py-4 border-t border-gray-300 bg-white">
              {/* Use action-specific feedback state */}
              {action?.payloadStructure?.some((field) => field.supports_ai_prompt) &&
                actionsWithGeneratorRun[action.id] &&
                currentActionFeedback.showButtons &&
                !currentActionFeedback.showForm && <FeedbackButtons onWrongOutput={handleWrongOutput} />}

              {action?.payloadStructure?.some((field) => field.supports_ai_prompt) &&
                actionsWithGeneratorRun[action.id] &&
                currentActionFeedback.showForm && (
                  <OutputFeedback
                    onSubmit={handleFeedbackSubmit}
                    onCancel={handleFeedbackCancel}
                    onRegenerate={() => {}} // This is handled in the onSubmit function
                  />
                )}
            </div>
          </div>
        </Splitter.Panel>
        {openRow && selectedRow && (
          <Splitter.Panel defaultSize="25%">
            <ResponseModal
              responses={responses}
              row={selectedRow}
              close={() => {
                setOpenRow(false);
                setSelectedRow(null);
              }}
              action={{
                ...action,
                payloadConfiguration: payloadConfiguration,
                responseConfiguration,
              }}
            />
          </Splitter.Panel>
        )}
      </Splitter>
    </div>
  );
};

export default RunModal;

import { memo } from "react";
import WorkflowIcon from "../../../assets/SVGs/newWorkflowBlue.svg";
import moment from "moment";
import { DISPLAY_DATE_TIME_FORMAT } from "@/utils/constants";
import { Link } from "react-router-dom";
import { Dropdown } from "antd";

interface SubfloqProps {
  subfloq: any;
  getWorkflowActionMenu: any;
  RenameWorkflow: any;
  toRenameWorkflow: any | null;
}

const SubfloqRenderer = memo(
  ({ subfloq, getWorkflowActionMenu, RenameWorkflow, toRenameWorkflow }: SubfloqProps) => {
    // console.log("workflow", subfloq);
    if (!subfloq) return;

    return (
      <div className="px-8 py-1 grid grid-cols-8 items-center hover:bg-primary/10 rounded-md group">
        <Link
          to={`/subfloqs/${subfloq.id}?v2=true`}
          className="flex gap-x-3 items-center font-normal col-span-6"
        >
          <img src={WorkflowIcon} alt="workflow-icon" width={18} />
          {!toRenameWorkflow || toRenameWorkflow.id !== subfloq.id ? (
            <p className="!text-gray-700 text-md">
              {(subfloq.name || subfloq.draftWorkflowConfig.name).length > 70
                ? `${(subfloq.name || subfloq.draftWorkflowConfig.name).slice(0, 67)}...`
                : subfloq.name || subfloq.draftWorkflowConfig.name}
            </p>
          ) : (
            <RenameWorkflow />
          )}
        </Link>
        <p className="text-sm w-full text-gray-500 font-light">
          {moment.utc(subfloq.created_at).local().format(DISPLAY_DATE_TIME_FORMAT)}
        </p>
        <div className="opacity-0 group-hover:opacity-100 flex items-center justify-center">
          <Dropdown
            overlay={getWorkflowActionMenu(subfloq)}
            trigger={["click"]}
            overlayStyle={{
              width: "11rem",
            }}
          >
            {/* <button
              className="flex justify-center h-fit items-center p-1 rounded-md transition-opacity duration-600 ease-in-out hover:opacity-100 hover:bg-gray-200"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <MdMoreHoriz size={20} />
            </button> */}
          </Dropdown>
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    // Skip comparison for props that never change
    return (
      prevProps.subfloq === nextProps.subfloq &&
      prevProps.toRenameWorkflow === nextProps.toRenameWorkflow &&
      prevProps.getWorkflowActionMenu === nextProps.getWorkflowActionMenu
    );
  }
);

export default SubfloqRenderer;

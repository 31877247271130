import {
  CLAUDE_PERSONAL_API_KEY_STRUCTURE_ID,
  GROQ_PERSONAL_API_KEY_STRUCTURE_ID,
  DEEPSEEK_PERSONAL_API_KEY_STRUCTURE_ID,
  OPENAI_ACTIONS_CONNECTION_ID,
  OPENAI_MODELS_PAYLOAD_STRUCTURE_ID,
  OPENAI_PERSONAL_API_KEY_STRUCTURE_ID,
  setStateType,
} from "../../../utils/constants";
import { useCallback, useEffect, useState } from "react";
import { useWorkflow } from "../../../contexts/WorkflowContext";
import TiptapField from "../Tiptap/TiptapField";
import { Action, PayloadConfiguration } from "../../../utils/interfaces";
import { Alert, ConfigProvider, Drawer, InputNumber, Radio, Select, Skeleton, Slider, Switch } from "antd";
import ArrayField from "./ArrayField";
import StepDownSearch from "./StepDownSearch";
import { FaCoins, FaPlus, FaRegEdit } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import ResponseStructureViewer from "./ModalComponents/ResponseStructure";
import { IoCalendarNumberOutline, IoCheckmark, IoWarning } from "react-icons/io5";
import RunCondition from "./ModalComponents/RunCondition";
import Coins from "@/assets/SVGs/buildTab/Coins";
import { RiExpandUpDownLine } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import { countriesDropdown } from "@/utils/countries";
import { useConnections } from "@/contexts/ConnectionContext";
import { getCampaigns } from "@/utils/apis";
import { MdAlternateEmail, MdChevronLeft, MdChevronRight, MdDeleteOutline, MdNumbers } from "react-icons/md";
import { TbTextSize } from "react-icons/tb";
import { IoIosLink, IoMdCheckboxOutline } from "react-icons/io";
import { v4 as uuidv4 } from "uuid";
import { useRunOnFloq } from "@/contexts/RunOnFloqContext";

type Props = {
  action: Action;
  modal: boolean;
  setModal: setStateType<boolean>;
  payloadConfiguration: PayloadConfiguration[];
  responseConfiguration: any[];
  variables: any[];
};

type OpenAIModelType = {
  name: string;
  id: string;
  logo?: string;
  credits?: number;
};

const OUTPUT_FIELD_TYPES = [
  {
    name: "Text",
    value: "string",
    label: (
      <span className="flex items-center gap-1">
        <TbTextSize size={15} />
        <span>Text</span>
      </span>
    ),
  },
  {
    name: "Email",
    value: "email",
    label: (
      <span className="flex items-center gap-1">
        <MdAlternateEmail size={15} />
        <span>Email</span>
      </span>
    ),
  },
  {
    name: "URL",
    value: "url",
    label: (
      <span className="flex items-center gap-1">
        <IoIosLink size={15} />
        <span>URL</span>
      </span>
    ),
  },
  {
    name: "Number",
    value: "number",
    label: (
      <span className="flex items-center gap-1">
        <MdNumbers size={15} />
        <span>Number</span>
      </span>
    ),
  },
  {
    name: "Date",
    value: "date",
    label: (
      <span className="flex items-center gap-1">
        <IoCalendarNumberOutline size={15} />
        <span>Date</span>
      </span>
    ),
  },
  {
    name: "True/False",
    value: "boolean",
    label: (
      <span className="flex items-center gap-1">
        <IoMdCheckboxOutline size={15} />
        <span>True/False</span>
      </span>
    ),
  },
];

const AiActionModal = ({ action, modal, setModal, payloadConfiguration, responseConfiguration, variables }: Props) => {
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  /**
   * @todo: also show the connection name in the error message
   */
  // const [isConnectionExists, setIsConnectionExists] = useState({ doExists: true, connectionName: "" });
  const [isConnectionExists, setIsConnectionExists] = useState(true);
  const [updateField, setUpdateField] = useState(0);
  const [review, setReview] = useState(action.reviewNeeded || false);
  const { collapseConfig, setCollapseConfig } = useRunOnFloq();
  const [drawerWidth, setDrawerWidth] = useState(collapseConfig ? "3rem" : "30%");
  const [payloads, setPayloads] = useState<PayloadConfiguration[]>(payloadConfiguration);
  const [responses, setResponses] = useState(responseConfiguration);
  const [runCondition, setRunCondition] = useState(action.runCondition);
  const {
    updatePayloadConfig,
    updateResponseConfig,
    saveWorkflowActions,
    updateActionName,
    setPublishWarning,
    fetchAllAPIPricing,
  } = useWorkflow();
  const { getConnectionFromType } = useConnections();
  const [changeName, setChangeName] = useState(false);
  // const [isActionWithKeyToggle, setIsActionWithKeyToggle] = useState(false);
  const [isUserKeyEnable, setIsUserKeyEnable] = useState(false);
  const [openaiModels, setOpenAIModelTypes] = useState<{ loading: boolean; models: OpenAIModelType[] }>({
    loading: false,
    models: [],
  });
  const [outputParserType, setOutputParserType] = useState("fields");
  const [jsonValue, setJsonValue] = useState<any>("{}");
  const [error, setError] = useState<string>("");
  const { id } = useParams();

  useEffect(() => {
    setWidthPercent();
    window.addEventListener("resize", setWidthPercent);
    return () => window.removeEventListener("resize", setWidthPercent);
  }, [collapseConfig]);

  const setWidthPercent = useCallback(() => {
    try {
      if (collapseConfig) setDrawerWidth("3rem");
      // Adjust width for different screen sizes
      else if (window.matchMedia("(min-width: 1024px) and (min-height: 1366px)").matches) setDrawerWidth("50%");
      else if (window.matchMedia("(min-width: 1024px)").matches) setDrawerWidth("30%");
      else if (window.matchMedia("(min-width: 640px)").matches) setDrawerWidth("50%");
      else setDrawerWidth("100%");
    } catch (err) {
      console.error("Error calculating width percent based on media size");
    }
  }, [collapseConfig]);

  /**
   * @todo: try to show user's openai api key, if the user has selected the "use personal api key" option
   * @todo: if the user has selected the "use personal api key" option,
   * user key is there but it is wrong or whatever and thereby it fails to get the models,
   * show warning like "Please enter correct API key to get the models" -> this is similar to validating API Key
   */

  useEffect(() => {
    // ACTIONS_WITH_USER_KEY_TOGGLE.includes(action.actionName)
    //   ? setIsActionWithKeyToggle(true)
    //   : setIsActionWithKeyToggle(false);
    const isKey =
      payloads.find((input) =>
        [
          OPENAI_PERSONAL_API_KEY_STRUCTURE_ID,
          CLAUDE_PERSONAL_API_KEY_STRUCTURE_ID,
          DEEPSEEK_PERSONAL_API_KEY_STRUCTURE_ID,
          GROQ_PERSONAL_API_KEY_STRUCTURE_ID,
        ].includes(input.payloadStructureId)
      )?.inputString || false;
    setIsUserKeyEnable(isKey);
    // if (!action?.connectionId) return;
    if (action?.connectionId) {
      getConnectionFromType(action.connectionId).then((connection: any) => {
        setIsConnectionExists(connection?.userConnection ? true : false);
      });
      // fetch openai models from action payload and assign them credits based on apiPricingId and store them in the state
      if (action?.connectionId === OPENAI_ACTIONS_CONNECTION_ID) {
        const modelsPayload = action.payloadStructure.find(
          (input) => input.payloadStructureId === OPENAI_MODELS_PAYLOAD_STRUCTURE_ID
        )?.values;
        setOpenAIModelTypes({ loading: true, models: [] });
        fetchAllAPIPricing().then((apiPricing: any) => {
          if (!modelsPayload) return;
          const models = modelsPayload.map((model: any) => {
            const pricing = apiPricing.api_pricing_pans.find((api: any) => api.id === model.apiPricingId);
            return {
              id: model.id,
              name: model.name,
              logo: model.logo,
              credits: pricing?.credits,
            };
          });
          // if (!isUserKeyEnable)
          setOpenAIModelTypes({ loading: false, models });
          // else setOpenAIModelTypes({ loading: false, models: [] });
        });
      }
    }
  }, [isUserKeyEnable]);

  useEffect(() => {
    setPayloads(payloadConfiguration);
    setUpdateField((prev) => prev + 1);
    const outputParserPayloadStructureId = action.payloadStructure.find(
      (input) => input.type === "outputParser"
    )?.payloadStructureId;
    if (!outputParserPayloadStructureId) return;
    const outputParserPayload = payloadConfiguration.find(
      (input) => input.payloadStructureId === outputParserPayloadStructureId
    )?.inputString;
    if (!outputParserPayload) return;
    setOutputParserType(outputParserPayload || "fields");
  }, [payloadConfiguration]);

  useEffect(() => {
    setRunCondition(action.runCondition);
  }, [action]);

  useEffect(() => {
    if (responseConfiguration.length === 0) setResponses([{ name: "response", type: "string", responseId: uuidv4() }]);
    else setResponses(responseConfiguration);
  }, [responseConfiguration]);

  useEffect(() => {
    const newJson: any = {};
    responses.forEach((response) => {
      newJson[response.name] = response.type;
    });
    setJsonValue(JSON.stringify(newJson, null, 2));
  }, [responses]);

  useEffect(() => {
    if (
      payloads !== payloadConfiguration ||
      review !== action.reviewNeeded ||
      runCondition !== action.runCondition ||
      responses !== responseConfiguration
    ) {
      // if (isActionWithKeyToggle) {
      const isKey =
        payloads.find((input) =>
          [
            OPENAI_PERSONAL_API_KEY_STRUCTURE_ID,
            CLAUDE_PERSONAL_API_KEY_STRUCTURE_ID,
            DEEPSEEK_PERSONAL_API_KEY_STRUCTURE_ID,
            GROQ_PERSONAL_API_KEY_STRUCTURE_ID,
          ].includes(input.payloadStructureId)
        )?.inputString || false;
      setIsUserKeyEnable(isKey);
      // setIsConnectionExists(isKey);
      // }
      setChanged(true);
    } else setChanged(false);
  }, [payloads, review, runCondition, responses]);

  useEffect(() => {
    if (action?.connectionId === "openai" && isUserKeyEnable) {
      setOpenAIModelTypes({ loading: true, models: [] });
      getCampaigns("openai").then((res: any[]) => {
        if (res) {
          const filteredModels = res
            .map((model: any) => {
              // Find the matching model in OPENAI_MODELS based on the ID
              const foundModel = openaiModels.models.find(
                (openaiModel: OpenAIModelType) => openaiModel.id === model.id
              );
              // If found, return the complete model structure; otherwise return null
              return foundModel ? { ...foundModel } : null;
            })
            // Remove any null entries from the result
            .filter((model) => model !== null);
          setOpenAIModelTypes({ loading: false, models: filteredModels });
        }
      });
    }
  }, [isUserKeyEnable]);

  const saveOutputStructure = async () => {
    setLoading(true);
    const updated = updatePayloadConfig(payloads, action.id, review || false, action.continueOnFail, runCondition);
    setPayloads(updated);
    const updatedResponses = updateResponseConfig(responses, action.id);
    setResponses(updatedResponses);
    await saveWorkflowActions(id || "");
    setChanged(false);
    setLoading(false);
    setPublishWarning(true);
  };

  const updateConnectionToStepDown = (structureId: string, apiId: string, value: boolean) => {
    const payl = payloads;
    payl.forEach((item) => {
      if (item.payloadStructureId === structureId) {
        let temp = item.stepDownSearchConfig;
        if (!temp) return;
        temp = temp.map((item) => {
          if (item.apiId === apiId) {
            item.connection = value;
          }
          return item;
        });
      }
    });
    setChanged(true);
    setPayloads(payl);
  };

  const updateStepDownOrder = (structureId: string, apiId: string, dropApiId: string, below: boolean) => {
    const payl = payloads;

    payl.forEach((item) => {
      if (item.payloadStructureId === structureId) {
        let temp = item.stepDownSearchConfig;
        if (!temp) return;
        const dropOrder = temp.find((item) => item.apiId === dropApiId)?.order;

        if (!dropOrder) return;
        const newOrder = below ? dropOrder + 1 : dropOrder;
        temp = temp.map((item) => {
          if (item.apiId === apiId) {
            item.order = newOrder;
          } else if (item.order >= newOrder) {
            item.order += 1;
          }
          return item;
        });
      }
    });
    setChanged(true);
    setPayloads(payl);
  };

  const updateStepDownConfig = (structureId: string, removedApiId: string | null, addedApiId: string | null) => {
    const payl = payloads;
    const payloadIndex = payloads.findIndex((input) => input.payloadStructureId === structureId) || 0;
    let temp = payl[payloadIndex].stepDownSearchConfig;
    if (!temp) return;
    if (removedApiId) {
      const removedOrder = temp.find((item) => item.apiId === removedApiId)?.order;
      if (!removedOrder) return;
      temp = temp.filter((item) => item.apiId !== removedApiId);
    }
    // If a node was added, place it at the end of the order
    if (addedApiId) {
      if (temp.length === 0) temp.push({ apiId: addedApiId, order: 1 });
      else {
        const maxOrder = Math.max(...temp.map((item) => item.order));
        temp.push({ apiId: addedApiId, order: maxOrder + 1 });
      }
    }

    payl[payloadIndex].stepDownSearchConfig = temp;
    setChanged(true);
    const updated = updatePayloadConfig(payl, action.id, review || false, action.continueOnFail);
    setPayloads(updated);
  };

  const resetStepDownConfig = (structureId: string, config: any[]) => {
    const payl = payloads;
    const payloadIndex = payloads.findIndex((input) => input.payloadStructureId === structureId) || 0;
    payl[payloadIndex].stepDownSearchConfig = config;
    setChanged(true);
    const updated = updatePayloadConfig(payl, action.id, review || false, action.continueOnFail);
    setPayloads(updated);
  };

  const checkValidVariable = (variableId: any, variables: any[]) => {
    for (const action of variables) {
      for (const variable of action.variables) {
        if (variable.responseId === variableId) return true;
      }
    }
    return false;
  };

  const checkValidExpression = (value: string): React.ReactNode => {
    try {
      let isValid = true;
      const vars = value.match(/{{(.*?)}}/g);
      if (vars) {
        vars.forEach((v: string) => {
          const responseId = v.slice(2, -2);
          if (!checkValidVariable(responseId, variables)) isValid = false;
        });
      }

      new Function(value);
      return (
        <div className="flex flex-col w-full items-start gap-y-1">
          {!isValid ? (
            <div className="flex">
              <RxCross2 className="text-red-400 mr-2" size={15} />
              <p className="text-red-400 text-xs">Invalid Data</p>
            </div>
          ) : (
            <div className="flex ">
              <IoCheckmark className="text-green-500 mr-2" size={15} />
              <p className="text-green-500 text-xs">Valid Expression</p>
            </div>
          )}
        </div>
      );
    } catch (e) {
      return (
        <div className="flex flex-row w-full items-center">
          <IoWarning className="text-red-500 mr-2" size={15} />
          <p className="text-red-500 text-xs">{(e as Error).message}</p>
        </div>
      );
    }
  };

  const validJSON = (json: string) => {
    try {
      const parsed = JSON.parse(json);
      if (typeof parsed !== "object") throw new Error("Invalid JSON");
      const resp = [...responses];
      Object.keys(parsed).forEach((key, idx) => {
        if (!OUTPUT_FIELD_TYPES.find((type) => type.value === parsed[key])) throw new Error("Invalid Type");
        resp[idx] = { ...resp[idx], name: key, type: parsed[key] };
        if (!resp[idx].responseId) resp[idx].responseId = uuidv4();
      });
      setError("");
      setResponses(resp);
      return true;
    } catch (e: any) {
      console.log(e);
      setError(e.message);
      return false;
    }
  };

  const fieldSwitchCase = (field: any) => {
    switch (field.type) {
      // pass the credits here
      case "stepDownSearch":
        return (
          <StepDownSearch
            structureId={field.payloadStructureId}
            updateStepDownOrder={updateStepDownOrder}
            updateConnectionToStepDown={updateConnectionToStepDown}
            updateStepDownConfig={updateStepDownConfig}
            resetStepDownConfig={resetStepDownConfig}
            stepDownAPIs={field.stepDownAPIs || []}
            payloadConfig={
              payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.stepDownSearchConfig ||
              []
            }
            action={action}
            credits={typeof action.credits === "object" ? action.credits : undefined}
            setIsConnectionExists={setIsConnectionExists}
          />
        );
      case "formula":
        return (
          <div className="flex flex-col w-full h-full">
            <TiptapField
              NotificationNode={checkValidExpression(
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""
              )}
              content={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.tiptapJson || ""
              }
              setContent={(content: any, text: string) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                if (index === -1) return;
                temp[index].inputString = text;
                temp[index].tiptapJson = content;
                setPayloads(temp);
              }}
              refresh={updateField}
              placeholder="Enter the value"
              variables={variables}
            />
          </div>
        );
      case "array":
        return (
          <ArrayField
            arr={
              payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString.length > 0
                ? payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString
                : [""]
            }
            setContent={(idx: number, content: string) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: [content],
                  type: field.type,
                });
              } else {
                temp[index].inputString[idx] = content;
              }
              setPayloads(temp);
            }}
            add={() => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) return;
              temp[index].inputString.push("");
              setPayloads(temp);
            }}
            description={field.description}
          />
        );
      case "dropdownWithCredits":
        return (
          <Skeleton loading={openaiModels.loading} active={openaiModels.loading} paragraph={false}>
            <Select
              suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
              value={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString ||
                field.values.find((value: any) => value?.default)?.id ||
                field.values.find((value: any) => value?.default)?.name ||
                ""
              }
              showSearch
              optionFilterProp="name"
              onChange={(value) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field?.payloadStructureId);
                if (index === -1) {
                  temp.push({
                    payloadStructureId: field.payloadStructureId,
                    inputString: value,
                  });
                } else temp[index].inputString = value;
                setPayloads(temp);
              }}
              options={
                // (
                // isActionWithKeyToggle &&
                // openaiModels.models &&
                // openaiModels.models.length > 0 &&
                // isUserKeyEnable &&
                !(isUserKeyEnable && !isConnectionExists)
                  ? openaiModels.models?.map((model: OpenAIModelType) => ({
                      label: (
                        <div className="flex justify-between items-center gap-2 p-1 w-full">
                          <div className="flex items-center gap-2 justify-end text-ellipsis">
                            {model.logo && <img src={model.logo} alt={model.id} className="w-6 h-6" />}
                            <p>{model.name}</p>
                          </div>
                          <div className="flex items-center gap-1 justify-end">
                            {model?.credits && !isUserKeyEnable && (
                              <>
                                <span className="text-xs">
                                  <div className="flex items-center justify-center gap-1">
                                    <Coins width="18" height="22" />
                                    <span className="font-bold text-md text-black">{model.credits}</span>
                                  </div>
                                </span>
                                {/* {model?.perUnit && (
                                <span className="text-xs">
                                  <div className="flex items-center justify-center gap-1 p-1">
                                    <span className="font-bold text-md text-black">
                                      {"/"}
                                      {model.perUnit}
                                    </span>
                                  </div>
                                </span>
                              )} */}
                              </>
                            )}
                          </div>
                        </div>
                      ),
                      value: model.id,
                    }))
                  : [].map((value: any) => ({
                      label: (
                        <div className="flex justify-between items-center gap-2 p-1 w-full">
                          <div className="flex items-center gap-2 justify-end text-ellipsis">
                            {value?.logo && <img src={value?.logo} alt={value?.name} className="w-6 h-6" />}
                            <p>{value?.name}</p>
                          </div>
                        </div>
                      ),
                      value: value?.id || value.name,
                      name: value?.name,
                    }))
              }
            />
          </Skeleton>
        );
      case "multiDropdown":
        return (
          <Select
            suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || []}
            showSearch
            optionFilterProp="name"
            mode="multiple"
            onChange={(value) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field?.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: value,
                });
              } else temp[index].inputString = value;
              setPayloads(temp);
            }}
            options={field.values.map((value: any) => ({
              label: (
                <div className="flex justify-between items-center gap-2 p-1 w-full">
                  <div className="flex items-center gap-2 justify-end text-ellipsis">
                    {value?.logo && <img src={value?.logo} alt={value?.name} className="w-6 h-6" />}
                    <p>{value?.name}</p>
                  </div>
                </div>
              ),
              value: value?.id || value.name,
              name: value?.name,
            }))}
          />
        );
      case "dropdown":
        return (
          <Select
            suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
            value={
              payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString ||
              field.values.find((value: any) => value?.default)?.id ||
              field.values.find((value: any) => value?.default)?.name ||
              ""
            }
            showSearch
            optionFilterProp="name"
            onChange={(value) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field?.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: value,
                });
              } else temp[index].inputString = value;
              setPayloads(temp);
            }}
            options={(field.values ?? []).map((value: any) => ({
              label: (
                <div className="flex justify-between items-center gap-2 p-1 w-full">
                  <div className="flex items-center gap-2 justify-end text-ellipsis">
                    {value?.logo && <img src={value?.logo} alt={value?.name} className="w-6 h-6" />}
                    <p>{value?.label || value?.name}</p>
                  </div>
                </div>
              ),
              value: value?.id || value.name,
              name: value?.name,
            }))}
          />
        );
      case "countries":
        return (
          <Select
            suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || []}
            showSearch
            mode="multiple"
            optionFilterProp="name"
            onChange={(value) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field?.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: value,
                });
              } else temp[index].inputString = value;
              setPayloads(temp);
            }}
            options={countriesDropdown.map((value: any) => ({
              label: (
                <div className="flex justify-between items-center gap-2 p-1 w-full">
                  <div className="flex items-center gap-2 justify-end text-ellipsis">
                    {value?.logo && <img src={value?.logo} alt={value?.name} className="w-6 h-6" />}
                    <p>{value?.name}</p>
                  </div>
                </div>
              ),
              value: value?.id || value.name,
              name: value?.name,
            }))}
          />
        );
      case "slider":
        return (
          <div className="flex gap-4 items-center">
            <Slider
              min={0}
              max={1}
              step={0.01}
              style={{ width: "100%" }}
              value={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || 0.5
              }
              onChange={(e) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                if (index === -1) {
                  temp.push({
                    payloadStructureId: field.payloadStructureId,
                    inputString: e,
                  });
                } else {
                  temp[index].inputString = e;
                }
                setPayloads(temp);
              }}
            />
            <InputNumber
              min={0}
              max={1}
              step={0.01}
              style={{ width: "20%" }}
              placeholder="0.50"
              value={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || 0.5
              }
              onChange={(e) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                if (index === -1) {
                  temp.push({
                    payloadStructureId: field.payloadStructureId,
                    inputString: e,
                    type: field.type,
                  });
                } else {
                  temp[index].inputString = e;
                }
                setPayloads(temp);
              }}
            />
          </div>
        );
      case "radio":
        return (
          <Switch
            className="w-fit "
            defaultChecked={
              payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString === true
            }
            onChange={(value) => {
              setIsUserKeyEnable(value);
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) return;
              temp[index].inputString = value;
              setPayloads(temp);
            }}
          />
        );
      case "jsonArray":
        return (
          <div className="flex flex-col gap-4">
            {payloads
              .find((input) => input.payloadStructureId === field.payloadStructureId)
              ?.inputString.map((input: any, idx: number) => {
                return (
                  <div className="" key={idx}>
                    <input
                      type="text"
                      className="w-full rounded-lg"
                      placeholder="Enter the name"
                      value={input.name}
                      onChange={(e) => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        if (index === -1) return;
                        temp[index].inputString[idx].name = e.target.value;
                        setPayloads(temp);
                      }}
                    />
                    <TiptapField
                      content={
                        payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString[
                          idx
                        ].tiptapJson || ""
                      }
                      setContent={(content: any, text: string) => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        if (index === -1) return;
                        temp[index].inputString[idx].value = text;
                        temp[index].inputString[idx].tiptapJson = content;
                        setPayloads(temp);
                      }}
                      refresh={updateField}
                      placeholder="Enter the value"
                      variables={variables}
                    />
                  </div>
                );
              })}
            <button
              className="text-primary flex gap-2 items-center"
              onClick={() => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                if (index === -1) return;
                temp[index].inputString.push({
                  name: "Variable Name",
                });
                setPayloads(temp);
              }}
            >
              <FaPlus />
              Add a new Variable Name and Variable Value pair
            </button>
          </div>
        );
      case "outputParser":
        return (
          <div>
            <Radio.Group
              options={[
                {
                  label: "Fields",
                  value: "fields",
                },
                {
                  label: "JSON Schema",
                  value: "json",
                },
              ]}
              value={outputParserType}
              onChange={(e) => {
                setOutputParserType(e.target.value);
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                if (index === -1)
                  temp.push({
                    payloadStructureId: field.payloadStructureId,
                    inputString: e.target.value,
                  });
                else temp[index].inputString = e.target.value;
                setPayloads(temp);
              }}
            />
            {outputParserType === "fields" ? (
              <div className="flex flex-col w-full justify-center items-start mt-3">
                {responses.map((response, index) => {
                  return (
                    <div className="w-full flex justify-between gap-2 items-center p-1" key={index}>
                      <input
                        className="w-1/2 px-2 pt-1 pb-0.5 rounded-sm border border-black"
                        type="text"
                        name="name"
                        value={response.name}
                        onChange={(e) => {
                          const temp = [...responses];
                          temp[index].name = e.target.value;
                          // if (!temp[index].type || temp[index].type === "string")
                          // temp[index].type = getExpectedTypeFromName(e.target.value);
                          setResponses(temp);
                        }}
                        // onPaste={(e) => handlePaste(e, index, "name")}
                        placeholder="Name of the Field"
                      />
                      <Select
                        showSearch
                        className={`w-1/2 rounded`}
                        value={response.type || "string"}
                        onChange={(value) => {
                          const temp = [...responses];
                          temp[index].type = value;
                          setResponses(temp);
                        }}
                        optionFilterProp="name"
                        options={OUTPUT_FIELD_TYPES}
                        style={{ border: "none" }}
                      />
                      <button
                        className="hover:bg-red-600/10 h-fit w-fit p-1 text-primary rounded-full"
                        onClick={() => {
                          const temp = [...responses];
                          temp.splice(index, 1);
                          setResponses(temp);
                        }}
                      >
                        <MdDeleteOutline size={16} color="red" />
                      </button>
                    </div>
                  );
                })}
                <button
                  className="p-2 font-bold"
                  onClick={() => {
                    const temp = [...responses];
                    temp.push({
                      name: "response" + (responses.length + 1),
                      type: "string",
                      responseId: uuidv4(),
                    });
                    setResponses(temp);
                  }}
                >
                  + Add Output
                </button>
              </div>
            ) : (
              <div className="flex flex-col w-full h-full mt-1">
                {error ? (
                  <div className="w-full p-2 bg-red-100 rounded-sn text-red-700">{error}</div>
                ) : (
                  <div className="w-fit p-2 bg-green-100 rounded-sm text-green-700">Valid JSON</div>
                )}
                <TiptapField
                  className="bg-[#01001fd2] text-[#c6c6c6] mt-1"
                  content={jsonValue}
                  setContent={(_content: any, text: string) => {
                    setJsonValue(text);
                    validJSON(text);
                  }}
                  refresh={updateField}
                  // placeholder="Enter the value"
                  placeholderText="Enter the JSON Schema"
                  variables={variables}
                  customStyles={true}
                  skipDataValues
                />
              </div>
            )}
          </div>
        );
      default:
        return (
          <textarea
            className="w-full h-32 rounded-lg"
            placeholder="Enter the value"
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""}
            onChange={(e) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: e.target.value,
                  type: field.type,
                });
              } else {
                temp[index].inputString = e.target.value;
              }
              setPayloads(temp);
            }}
          />
        );
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={modal}
        mask={false}
        onClose={() => setModal(false)}
        width={drawerWidth}
        styles={{
          content: {
            transition: "width 0.3s",
          },
          body: {
            padding: collapseConfig ? "0" : "24px",
          },
        }}
        classNames={{ header: !collapseConfig ? "border-b !border-gray-300" : "!border-none" }}
        className="ml-auto !font-favorit !p-0 border-l border-gray-500"
        getContainer={false}
        footer={
          !collapseConfig && (
            <div className="w-full flex flex-col gap-4 bg-white">
              {action.reviewAllowed && (
                <div className="flex gap-2 items-center">
                  <input type="checkbox" checked={review} onChange={() => setReview(!review)} />
                  <span className="font-medium">
                    Enable this if you’d like to review before moving on to the next step
                  </span>
                </div>
              )}
              {!isConnectionExists && isUserKeyEnable ? (
                <Alert
                  message={
                    <>
                      Please add the required connection to proceed.{" "}
                      <Link to="/connections" target="_blank" rel="noopener noreferrer">
                        <i>Click here</i>
                      </Link>
                    </>
                  }
                  type="warning"
                  showIcon
                />
              ) : null}
              <div className="flex flex-row items-center justify-between">
                {/* show credits dynamically, based on the openAI model selected */}
                {!isUserKeyEnable && action.connectionId !== "openai" && (
                  <span className="text-sm mt-1 font-light text-gray-400 flex-col">
                    {typeof action.credits === "number" && (
                      <div className="flex items-center gap-2 ml-2 bg-purple-100 hover:bg-purple-200 transition-colors duration-200 rounded-lg p-1 shadow-sm">
                        <div className="flex items-center">
                          <span className="font-semibold text-purple-900">{action.credits}</span>
                          <FaCoins className="ml-1.5 text-purple-600 w-3 h-3" />
                        </div>

                        <div className="flex items-center text-purple-800">
                          <span className="mr-1 text-purple-400">/</span>
                          {action.billingUnit ? (
                            <div className="text-xs font-light pr-1">{action.billingUnit}</div>
                          ) : (
                            <div className="text-xs font-light flex flex-col pr-1">
                              <span>row</span>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </span>
                )}
                <button
                  className={` text-white font-semibold rounded-lg p-2 mt-auto ${
                    loading || !changed || (!isConnectionExists && isUserKeyEnable) || error
                      ? "cursor-not-allowed bg-gray-500"
                      : "bg-primary cursor-pointer"
                  }
                ${!isUserKeyEnable && action.connectionId !== "openai" ? "w-[30%]" : "w-full"}
                `}
                  onClick={saveOutputStructure}
                  disabled={loading || !changed || (!isConnectionExists && isUserKeyEnable) || error !== ""}
                >
                  {loading ? "Saving..." : !changed ? "Saved" : "Save"}
                </button>
              </div>
            </div>
          )
        }
        // check if the credits is a number , if (number) display here
        title={
          !collapseConfig && (
            <div className="flex items-center gap-2">
              <button className="p-0 border border-gray-300 rounded-sm" onClick={() => setCollapseConfig(true)}>
                <MdChevronRight size={25} />
              </button>
              <img src={action.logo} alt={action.name} className="w-6 h-6" />
              <span
                id="actionName"
                contentEditable={changeName}
                className={changeName ? "border border-black px-2" : ""}
                suppressContentEditableWarning
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.target.blur();
                  }
                }}
                onBlur={async (e) => {
                  if (!e.target.textContent) return;
                  setLoading(true);
                  updateActionName(action.id, e.target.textContent || action.name);
                  setChangeName(false);
                  await saveWorkflowActions(id || "");
                  setChanged(false);
                  setLoading(false);
                  setPublishWarning(true);
                }}
              >
                {action.name}
              </span>
              <button
                onClick={() => {
                  setChangeName(!changeName);
                  const p = document.getElementById("actionName");
                  if (!p) return;
                  setTimeout(function () {
                    const range = document.createRange();
                    const selection = window.getSelection();
                    range.selectNodeContents(p);
                    range.collapse(false); // <-- Set the cursor at the end of the selection
                    selection?.removeAllRanges();
                    selection?.addRange(range);
                    p.focus();
                  }, 0);
                }}
                className={`p-2 rounded-md transition-opacity duration-600 ease-in-out hover:bg-gray-200 ${
                  changeName ? "opacity-50" : "opacity-100"
                }`}
              >
                <FaRegEdit />
              </button>
              {/* </section> */}
            </div>
          )
        }
      >
        {collapseConfig ? (
          <button
            className="w-full h-full flex flex-col items-center justify-center p-3 gap-3"
            onClick={() => setCollapseConfig(false)}
          >
            <span className="p-0 border border-gray-300 rounded-sm">
              <MdChevronLeft size={25} />
            </span>
            <p className="[writing-mode:sideways-lr] text-center">CONFIG</p>
          </button>
        ) : (
          <div className="bg-white gap-10 flex flex-col">
            {action.payloadStructure.map((field, idx) => {
              return (
                <div key={idx} className="flex flex-col w-full gap-4 p-4 border border-gray-300 rounded-lg">
                  <div>
                    <p className="tracking-primary font-semibold">
                      {field.name.toUpperCase()}
                      {!field.required && (
                        <span className="text-sm font-normal tracking-normal text-gray-400"> (Optional)</span>
                      )}
                    </p>
                    <p className="text-sm font-light">{field.description}</p>
                  </div>
                  {field.needVars ? (
                    <TiptapField
                      content={
                        payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.tiptapJson ||
                        ""
                      }
                      setContent={(content: any, text: string) => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        if (index === -1) {
                          temp.push({
                            payloadStructureId: field.payloadStructureId,
                            tiptapJson: content,
                            inputString: text,
                            type: field.type,
                          });
                        } else {
                          temp[index].tiptapJson = content;
                          temp[index].inputString = text;
                        }
                        setPayloads(temp);
                      }}
                      refresh={updateField}
                      placeholder={field.name}
                      variables={variables}
                    />
                  ) : (
                    fieldSwitchCase(field)
                  )}
                </div>
              );
            })}
            <div>
              Continue workflow even if this fails:{" "}
              <Switch
                defaultChecked={action.continueOnFail}
                onChange={(value) => {
                  setChanged(true);
                  action.continueOnFail = value;
                }}
              />
            </div>
            <RunCondition
              variables={variables}
              responses={
                action.outputStructureType === "userDefined"
                  ? responses?.[0]?.name
                    ? responses
                    : [
                        {
                          ...responses[0],
                          name: "response",
                          type: "string",
                        },
                      ]
                  : action.responseStructure
              }
              conditionsArr={
                runCondition || {
                  conditions: [],
                  otherwise: [],
                }
              }
              setConditionsArr={setRunCondition}
            />
            <ResponseStructureViewer
              setResponses={setResponses}
              responses={responses}
              action={action}
              setChanged={setChanged}
              userDefined={action.outputStructureType === "userDefined"}
            />
          </div>
        )}
      </Drawer>
    </ConfigProvider>
  );
};

export default AiActionModal;

import Filters from "../Components/Filters";
import ExampleResponse from "../Components/ExampleResponse";
import { useEffect, useMemo, useRef, useState } from "react";
import { CollapsibleIcon } from "@/Components/NavbarNew/CollapsibleIcon";
import PreviewData from "../Components/PreviewData";
import { signalPreview } from "../apis";
import { SIGNAL_ID_TO_LIST_PREVIEW_URL } from "@/utils/urls";
import CompaniesListComponent from "../Components/ListBuilding/CompaniesList";
import SalesNavList from "../Components/ListBuilding/SalesNavList";
import { Breadcrumb, message } from "antd";
import { Link } from "react-router-dom";
import EditableName from "../Components/EditableName";
import RadioIconGray from "@Assets/SVGs/RadioIconGray.svg";
import DatabaseIcon from "@Assets/SVGs/DatabaseIcon.svg";
import ListIcon from "@Assets/SVGs/ListIcon.svg";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import { FiUpload, FiWifi } from "react-icons/fi";
import Connection from "../Modals/Connection";
import moment from "moment";
import { getUserWorkflows } from "@/utils/apis";
import { useNavigate } from "react-router-dom";
import { IoSaveOutline } from "react-icons/io5";

type Props = {
  signal: any;
};

const Create = ({ signal }: Props) => {
  const [name, setName] = useState("New Signal-" + signal.shortName);
  const [loading, setLoading] = useState(false);
  const [payloads, setPayloads] = useState<any>({});
  const [previewData, setPreviewData] = useState<any>([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [removeMapping, setRemoveMapping] = useState(false);
  const [connections, setConnections] = useState<any[]>([]);
  const [workflows, setWorkflows] = useState<any[]>([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState<any>(undefined);
  const [leadsToImport, setLeadsToImport] = useState<number>(0);
  const createSignalHandlerRef = useRef<() => Promise<any>>();

  const navigate = useNavigate();

  useEffect(() => {
    const getConnectionsAndWorkflows = async () => {
      setLoading(true);
      try {
        const [flows] = await Promise.all([
          getUserWorkflows(true).catch((error) => {
            message.error("Failed to fetch workflows: " + error.message);
            throw error;
          }),
        ]);

        const firebase = flows.workflows;
        const supabase = flows.workflowsV2;
        const allWorkflows: any[] = (firebase?.workflows || [])
          .concat(supabase?.workflows || [])
          .map((workflow: any) => {
            if ((firebase?.workflows || []).find((w: any) => w.id === workflow.id)) workflow.supabase = false;
            else workflow.supabase = true;
            return workflow;
          });
        allWorkflows.sort((a: any, b: any) => {
          const aDate = moment.utc(a.createdAt);
          const bDate = moment.utc(b.createdAt);

          if (aDate.isAfter(bDate)) return -1;
          if (aDate.isBefore(bDate)) return 1;
          return 0;
        });
        setWorkflows(allWorkflows);
      } catch (error) {
        console.error("Failed: getConnectionsAndWorkflows", error);
        message.error("Something went wrong while fetching Connections and Workflows");
      } finally {
        setLoading(false);
      }
    };
    getConnectionsAndWorkflows();
  }, []);

  const generatePreview = async (payload: any) => {
    try {
      const url = SIGNAL_ID_TO_LIST_PREVIEW_URL[signal.id as keyof typeof SIGNAL_ID_TO_LIST_PREVIEW_URL];
      const responses = await signalPreview(url, payload);
      setPreviewData(responses);
    } catch (error: any) {
      message.error(error.message || error);
    }
  };

  const getSignalLayout = (signal: any) => {
    const signal_id = signal.id;

    switch (signal_id) {
      default:
        return (
          <Filters
            payloadStructure={signal.payloadStructure}
            signalId={signal.id}
            name={name}
            preview={signal.isPreviewAvailable}
            generatePreview={generatePreview}
            payloads={payloads}
            setPayloads={setPayloads}
          />
        );
    }
  };

  const breadcrumbItems = useMemo(() => {
    if (!signal) return [];

    const { type, logo } = signal;

    let path = "";
    let label = "";
    let icon = "";

    switch (type) {
      case "watcher":
        path = "/intent-signals";
        label = "Intent Signals";
        icon = RadioIconGray;
        break;
      case "list":
        path = "/list-building";
        label = "List Building";
        icon = ListIcon;
        break;
      case "crmWatcher":
      case "crmList":
        path = "/crm";
        label = "CRM";
        icon = DatabaseIcon;
        break;
      default:
        path = "/";
        label = "Home";
    }

    return [
      {
        title: (
          <Link to={path} style={{ display: "flex", alignItems: "center", gap: 4 }}>
            <img src={icon} alt="logo" className="h-5 w-5" />
            {label}
          </Link>
        ),
      },
      {
        title: (
          <span className="flex">
            <img src={logo} alt="icon" className="h-6 w-6" />
            <EditableName name={name} updateName={(newName) => setName(newName)} />
          </span>
        ),
      },
    ];
  }, [signal, name]);

  const getLayout = (signal: any) => {
    const signal_id = signal.id;

    switch (signal_id) {
      case "FLOQER_LIST_BUILDING_SN":
        return (
          <div>
            <SalesNavList
              name={name}
              signalId={signal.id}
              signal={signal}
              preview={signal.isPreviewAvailable}
              generatePreview={generatePreview}
              data={previewData}
              tableNode={
                signal.isPreviewAvailable ? (
                  <PreviewData
                    responseStructure={signal.responseStructure}
                    dynamic={signal.outputStructureType === "dataDefined"}
                    data={previewData}
                    generating={loading}
                    signalId={signal.id}
                  />
                ) : (
                  <ExampleResponse
                    exampleResponse={signal.exampleResponse}
                    responseStructure={signal.responseStructure}
                  />
                )
              }
              payloads={payloads}
              setPayloads={setPayloads}
              leadsToImport={leadsToImport}
              setLeadsToImport={setLeadsToImport}
              onCreateSignal={(handler) => {
                createSignalHandlerRef.current = handler;
              }}
            />
          </div>
        );

      case "FLOQER_COMPANY_LIST_BUILDING_TS":
      case "THEIRSTACK_JOBS_SIGNAL":
        return (
          <div>
            <CompaniesListComponent
              name={name}
              signalId={signal.id}
              signal={signal}
              preview={signal.isPreviewAvailable}
              generatePreview={generatePreview}
              data={previewData}
              tableNode={
                signal.isPreviewAvailable ? (
                  <PreviewData
                    responseStructure={signal.responseStructure}
                    dynamic={signal.outputStructureType === "dataDefined"}
                    data={previewData}
                    generating={loading}
                    signalId={signal.id}
                  />
                ) : (
                  <ExampleResponse
                    exampleResponse={signal.exampleResponse}
                    responseStructure={signal.responseStructure}
                  />
                )
              }
              payloads={payloads}
              setPayloads={setPayloads}
              leadsToImport={leadsToImport}
              setLeadsToImport={setLeadsToImport}
              onCreateSignal={(handler) => {
                createSignalHandlerRef.current = handler;
              }}
            />
          </div>
        );

      default:
        return (
          <div className="grid grid-cols-4 h-full">
            {getSignalLayout(signal)}
            <div className="col-span-3">
              {signal.isPreviewAvailable ? (
                <PreviewData
                  responseStructure={signal.responseStructure}
                  dynamic={signal.outputStructureType === "dataDefined"}
                  data={previewData}
                  generating={loading}
                  signalId={signal.id}
                />
              ) : (
                <ExampleResponse
                  exampleResponse={signal.exampleResponse}
                  responseStructure={signal.responseStructure}
                  signal={signal}
                  payloads={payloads}
                />
              )}
            </div>
          </div>
        );
    }
  };

  return (
    <div className="w-full h-full overflow-hidden flex flex-col !cursor-default">
      {/* Header */}
      <div className="w-full flex items-center justify-between px-8 min-h-[50px] z-40 sticky top-0 bg-white border-b-[0.5px] border-[#D3D3D3]">
        {/* Breadcrumb */}
        <span className="flex gap-4">
          <CollapsibleIcon isHeader />
          <span className=" font-medium text-center text-[#3F3F3F] flex gap-[5px] max-h-[24px]">
            <Breadcrumb className="text-base">
              {breadcrumbItems.map((item, index) => (
                <Breadcrumb.Item key={index}>{item.title}</Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </span>
        </span>
        <div className="flex gap-1">
          <button
            type="button"
            className="flex items-center gap-2 bg-[#F3F4F6] text-[#333333] py-1 px-2 rounded-md hover:bg-[#d4d5d7] disabled:cursor-not-allowed"
            onClick={async () => {
              setLoading(true);
              if (!createSignalHandlerRef?.current) return;

              const newId = await createSignalHandlerRef?.current();
              setLoading(false);
              if (!newId) {
                return;
              }
              navigate(`/list/${newId}?triggerExport=true`);
            }}
            disabled={!leadsToImport}
          >
            {loading ? (
              <SpinnerStatus />
            ) : (
              <>
                {`Export List`}
                <FiUpload />
              </>
            )}
          </button>
          <button
            className={`flex items-center gap-2 font-medium ${"bg-[#333333] text-[#F3F4F6] hover:bg-[#151515]"} py-1 px-2 rounded-md transition-colors duration-200 disabled:cursor-not-allowed`}
            onClick={() => setOpenDrawer(true)}
            disabled={!leadsToImport}
          >
            Sync with Floq
            <FiWifi className="text-[#BBA8FF]" />
          </button>
          <button
            className={`flex items-center gap-2 font-medium ${"bg-[#333333] text-[#F3F4F6] hover:bg-[#151515]"} py-1 px-2 rounded-md transition-colors duration-200 disabled:cursor-not-allowed`}
            onClick={async () => {
              if (!createSignalHandlerRef?.current) return;
              setLoading(true);
              const newId = await createSignalHandlerRef?.current();
              setLoading(false);
              if(!newId){
                message.error("Failed to create signal. Please try again.");
                return;
              }
              navigate(`/list/${newId}`);
            }}
            disabled={!leadsToImport}
          >
            {loading ? (
              <SpinnerStatus />
            ) : (
              <div className="flex gap-2 items-center">
                Save List <IoSaveOutline />
              </div>
            )}
          </button>
        </div>
      </div>
      {getLayout(signal)}
      <Connection
        open={openDrawer}
        update={undefined}
        removeMapping={removeMapping}
        close={() => {
          setRemoveMapping(true);
          // setSelectedWorkflow(undefined);
          setOpenDrawer(false);
        }}
        existingConnections={connections}
        workflows={workflows}
        setWorkflows={setWorkflows}
        loading={loading}
        selectedWorkflow={selectedWorkflow}
        setSelectedWorkflow={(workflow: any) => setSelectedWorkflow(workflow)}
        responseStructure={
          signal?.responseStructure?.length
            ? Object.keys(signal?.exampleResponse)
                .map((field: string) => {
                  const skip = ["_id", "created_at", "list_id", "type"];
                  if (skip.includes(field)) return null;
                  return {
                    name: field,
                  };
                })
                .filter((field) => field)
            : []
        }
        addConnection={(connection: any) => setConnections([connection, ...connections])}
        updateExistingConnection={(connection: any) => {
          const updatedConnections = connections.map((conn) =>
            conn.workflow_id === connection.workflow_id ? connection : conn
          );
          setConnections(updatedConnections);
        }}
        preCreateSyncFunction={createSignalHandlerRef?.current}
        postCreateSyncFunction={async (newId: string) => {
          navigate(`/list/${newId}`);
        }}
      />
    </div>
  );
};

export default Create;

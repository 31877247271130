import React, { useState, useEffect } from "react";
import { Action, ResponseConfiguration, ResponseStructure } from "../../../../utils/interfaces";
import { TbTextSize, TbBraces } from "react-icons/tb";
import { IoIosArrowDown, IoIosArrowForward, IoIosLink, IoMdCheckboxOutline, IoMdLink } from "react-icons/io";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { PiBracketsCurly, PiBracketsSquareBold } from "react-icons/pi";
import { IconType } from "react-icons/lib";
import { MdAlternateEmail, MdNumbers } from "react-icons/md";
import { FaDollarSign } from "react-icons/fa";
import { CiImageOn } from "react-icons/ci";
import { BsBorderAll } from "react-icons/bs";

// -----------------------------------------------------------------------------
// Extended interface with an additional "rawType" property (to store the original type)
// and an optional "children" property (for nested fields)
interface KeyValueItem {
  _id: string;
  name: string;
  value: any;
  // Display label (mapped from rawType via typeMap)
  type: string;
  // Original type string (e.g. "string", "jsonArray", "array", etc.)
  rawType: string;
  icon: IconType;
  display_column: boolean;
  description?: string;
  children?: KeyValueItem[];
}

// -----------------------------------------------------------------------------
// Map of types to display labels.
const typeMap: Record<string, string> = {
  string: "Text",
  email: "Email",
  url: "URL",
  imageUrl: "Image URL",
  number: "Number",
  currency: "Currency",
  boolean: "Boolean",
  textFileURL: "Text File URL",
  json: "Json",
  object: "Json",
  table: "Table",
  jsonArray: "jsonArray",
  array: "Array",
  sectionList: "List",
  date: "Date",
  unknown: "Unknown",
};

// -----------------------------------------------------------------------------
// Icon mapping for each type.
const ICON_MAP: Record<string, IconType> = {
  string: TbTextSize,
  email: MdAlternateEmail,
  url: IoIosLink,
  imageUrl: CiImageOn,
  number: MdNumbers,
  currency: FaDollarSign,
  boolean: IoMdCheckboxOutline,
  textFileURL: IoMdLink,
  json: PiBracketsCurly,
  object: PiBracketsCurly,
  table: BsBorderAll,
  jsonArray: PiBracketsSquareBold,
  array: PiBracketsSquareBold,
  sectionList: BsBorderAll,
  date: IoCalendarNumberOutline,
  unknown: TbBraces,
};

// -----------------------------------------------------------------------------
// Helper: Format a value for display.
// • If it's a string, return it as is (no added quotes).
// • If it's an array of strings, join with commas without quotes.
// • For other types, fall back to String().
function formatValue(value: any): string {
  if (typeof value === "string") {
    return value;
  } else if (Array.isArray(value)) {
    if (value.every((v) => typeof v === "string")) {
      return value.join(", ");
    } else {
      return value.map(formatValue).join(", ");
    }
  }
  return String(value);
}

// -----------------------------------------------------------------------------
// Generates dummy/sample data for a ResponseStructure (used as a leaf value).
// For types "json", "object", and "jsonArray" the placeholder is ignored.
function dummyData(structure: ResponseStructure): any {
  if (!structure.type) return "unknown";

  switch (structure.type) {
    case "string":
      return structure.placeholder || "sample string";
    case "email":
      return structure.placeholder || "example@email.com";
    case "url":
      return structure.placeholder || "https://example.com";
    case "imageUrl":
      return structure.placeholder || "https://example.com/image.jpg";
    case "number":
      return structure.placeholder ? Number(structure.placeholder) : 0;
    case "currency":
      return structure.placeholder || "$1000000";
    case "boolean":
      if (typeof structure.placeholder === "boolean") return structure.placeholder;
      if (typeof structure.placeholder === "string") return structure.placeholder.toLowerCase() === "true";
      return false;
    case "textFileURL":
      return structure.placeholder || "https://storage.googleapis.com/file.txt";
    case "date":
      return structure.placeholder || new Date().toISOString();
    case "array":
      if (structure.responseStructure) {
        return structure.responseStructure.map((item: ResponseStructure) => dummyData(item));
      }
      return Array.isArray(structure.placeholder)
        ? structure.placeholder
        : [structure.placeholder || "sample1", structure.placeholder || "sample2"];
    case "json":
    case "object":
      // Ignore placeholder; build the value from the structure.
      if (structure.responseStructure) {
        const data: Record<string, any> = {};
        structure.responseStructure.forEach((item: ResponseStructure) => {
          data[item.name || ""] = dummyData(item);
        });
        return data;
      } else if (structure.fields) {
        const data: Record<string, any> = {};
        structure.fields.forEach((item: ResponseStructure) => {
          data[item.name || ""] = dummyData(item);
        });
        return data;
      }
      return {};
    case "jsonArray":
      // Ignore placeholder; return an array with one sample object.
      if (structure.responseStructure) {
        const sampleObject = structure.responseStructure.reduce((acc: Record<string, any>, item: ResponseStructure) => {
          acc[item.name || ""] = dummyData(item);
          return acc;
        }, {});
        return [sampleObject];
      }
      return [];
    case "table":
      if (structure.responseStructure) {
        const data: Record<string, any> = {};
        structure.responseStructure.forEach((item: ResponseStructure) => {
          data[item.name || ""] = dummyData(item);
        });
        return data;
      }
      return {};
    case "sectionList":
      if (structure.sectionVariablesStructure) {
        return structure.sectionVariablesStructure.reduce((acc: Record<string, any>, item: ResponseStructure) => {
          acc[item.name || ""] = dummyData(item);
          return acc;
        }, {});
      }
      return {};
    default:
      return "unknown";
  }
}

// -----------------------------------------------------------------------------
// Recursively builds a tree of KeyValueItem objects from a ResponseStructure.
function buildKeyValueTree(structure: ResponseStructure): KeyValueItem {
  const base: KeyValueItem = {
    _id: structure.responseStructureId ?? "",
    name: structure.name,
    value: dummyData(structure),
    // Display label from our typeMap.
    type: typeMap[structure.type ?? "unknown"],
    // Keep the original type.
    rawType: structure.type || "unknown",
    icon: ICON_MAP[structure.type ?? "unknown"],
    display_column: structure.display_column ?? false,
    description: structure.description,
  };

  // For types that support nested structures, build children.
  if (structure.type && ["object", "json", "table"].includes(structure.type)) {
    if (structure.responseStructure) {
      base.children = structure.responseStructure.map(buildKeyValueTree);
    } else if (structure.fields) {
      base.children = structure.fields.map(buildKeyValueTree);
    }
  } else if (structure.type === "array") {
    // For "array", we want an expand button instead of showing the comma separated placeholder.
    if (structure.responseStructure) {
      base.children = structure.responseStructure.map(buildKeyValueTree);
    } else {
      // Convert base.value to an array (if not already) and create children items.
      const arr = Array.isArray(base.value) ? base.value : [base.value];
      base.children = arr.map((elem, index) => ({
        _id: base._id + "_" + index,
        name: String(index),
        value: elem,
        type: "Text", // Using "Text" as display type for each element.
        rawType: "arrayItem", // Custom raw type for array items.
        icon: ICON_MAP["string"],
        display_column: false,
      }));
    }
  } else if (structure.type === "jsonArray") {
    if (structure.responseStructure) {
      base.children = structure.responseStructure.map(buildKeyValueTree);
    }
  } else if (structure.type === "sectionList") {
    if (structure.sectionVariablesStructure) {
      base.children = structure.sectionVariablesStructure.map(buildKeyValueTree);
    }
  }
  return base;
}

// -----------------------------------------------------------------------------
// Recursive component to render a KeyValueItem.
// For nested items (outer === false) the display switch is hidden.
// • On hover over the name, it shows the description.
// • On hover over the placeholder, it shows the placeholder value.
// • On hover over the data type icon, it shows the data type label.
// • On hover over the switch, it shows "display column" or "hide column" based on state.
interface KeyValueProps {
  item: KeyValueItem;
  outer?: boolean;
}

function KeyValue({ item }: KeyValueProps): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  // For types "json", "jsonArray", "sectionList", and "array" (the main array node) we hide the placeholder.
  // (For array children, rawType will be "arrayItem", so their placeholder will be shown.)
  const hidePlaceholder = ["json", "jsonArray", "sectionList"].includes(item.rawType) || item.rawType === "array";
  return (
    <div className="flex flex-col px-1">
      <div className="py-1 px-2 flex items-center flex-wrap gap-2 max-w-full overflow-x-auto relative group bg-gray-50 mt-1">
        <div className="flex flex-row w-full items-center justify-between">
          <div className="flex items-center">
            {item.children && item.children.length > 0 && (
              <button className="mr-2" onClick={() => setIsOpen((prev) => !prev)}>
                {isOpen ? <IoIosArrowDown /> : <IoIosArrowForward />}
              </button>
            )}
            <p className="flex flex-row self-start items-center text-gray-700 mt-0.5">
              {/* Name with description tooltip */}
              <span
                className="font-semibold text-[10px] tracking-wide truncate text-ellipsis max-w-32"
                title={item.name || ""}
              >
                {item.name}:
              </span>
              {/* Placeholder value with its own tooltip (hidden for main array types) */}
              <span
                className="ml-2 text-[10px] max-w-32 text-gray-500 truncate text-ellipsis"
                title={hidePlaceholder ? "" : formatValue(item.value)}
              >
                {hidePlaceholder ? "" : formatValue(item.value)}
              </span>
            </p>
          </div>
          <div className="flex items-center text-[10px] gap-[2px]">
            {/* Data type icon with tooltip showing the data type label */}
            <span title={item.type}>{item.icon && <item.icon />}</span>
            <span title={item.name}> {item.type}</span>
          </div>
        </div>
      </div>

      {isOpen && item.children && item.children.length > 0 && (
        <div className="ml-4">
          {item.children.map((child) => (
            <KeyValue key={child._id || child.name} item={child} />
          ))}
        </div>
      )}
    </div>
  );
}

// -----------------------------------------------------------------------------
// Main component that renders the response structure tree along with a search input.
// The header (with the search field and "x column(s) selected" count) is sticky so that it remains visible while scrolling.
export default function ResponseStructureViewer({
  action,
  responses,
}: {
  action: Action;
  responses: ResponseConfiguration[];
}): React.ReactElement {
  // Build the tree from the response structure (top-level)
  const [tree, setTree] = useState<KeyValueItem[]>(action.responseStructure.map(buildKeyValueTree));
  const [showAll, setShowAll] = useState(false);

  const displayTree = showAll ? tree : tree.slice(0, 4);

  // Update top-level display_column flags based on responses.
  useEffect(() => {
    setTree((prevTree: any[]) =>
      prevTree.map((item) => {
        const resp = responses.find((r) => r.responseStructureId === item._id);
        return resp ? { ...item, display_column: resp.display_column } : item;
      })
    );
  }, [responses]);

  return (
    <div className="flex flex-col">
      {/* Sticky header with title, search input, and columns selected count */}

      {/* Scrollable container for the response fields */}
      <div className="overflow-y-auto max-h-96 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
        {displayTree.length > 0 ? (
          displayTree.map((item) => {
            return <KeyValue key={item._id || item.name} item={item} outer={true} />;
          })
        ) : (
          <div className="text-gray-500">No matching fields found.</div>
        )}
      </div>
      {tree.length > 4 && (
        <div
          className="text-black text-[10px] font-light mt-1 ml-1 py-1 underline cursor-pointer"
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? "Show less" : `Show all`}
        </div>
      )}
    </div>
  );
}

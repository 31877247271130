import { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { PiCopySimpleThin } from "react-icons/pi";

interface Props {
  data: any;
}

const CopyHeadersButtons: React.FC<Props> = ({ data }) => {
  const [isCopied, setIsCopied] = useState(false);
  const handleCopy = async (responses: any[]) => {
    if (!responses) {
      return;
    }

    setIsCopied(true);
    try {
      const names = responses.map((item) => item?.headerName);
      const clipboardText = names.join("\t");
      await navigator.clipboard.writeText(clipboardText);
    } catch (err) {
      console.error("Failed to copy to clipboard:", err);
    }
    setTimeout(() => setIsCopied(false), 1500);
  };

  return (
    <button
      className="p-2 rounded-md bg-[#F3F4F6] hover:bg-gray-200 flex gap-2 text-sm align-center"
      onClick={() => handleCopy(data)}
    >
      <span className="translate-y-[2px]">{isCopied ? <FaCheck /> : <PiCopySimpleThin size={16} />}</span>
      Copy column names
    </button>
  );
};

export default CopyHeadersButtons;

import React from 'react';
import { CloseOutlined } from '@ant-design/icons';

type Props = {
  onWrongOutput: () => void;
  disabled?: boolean;
};

const FeedbackButtons: React.FC<Props> = ({ onWrongOutput, disabled = false }) => {
  return (
    <div className="mb-6">
      <button 
        onClick={onWrongOutput}
        className={`w-full py-2 ${disabled ? 'bg-gray-100 text-gray-400 cursor-not-allowed' : 'bg-red-50 text-red-600 hover:bg-red-100'} border border-red-200 rounded-md text-center`}
        disabled={disabled}
      >
        <CloseOutlined className="mr-1" /> Wrong output
      </button>
    </div>
  );
};

export default FeedbackButtons; 
import { App, message } from "antd";
import { FaRegCopy, FaCoins } from "react-icons/fa";
import { useState } from "react";

interface Props {
  data: any;
  credits: any;
}

// Add this interface for job openings
interface JobOpening {
  url: string;
  title: string;
  category: string;
  date_added: string;
  location_text?: string;
}

// Add interface for milestone data
interface FundingMilestone {
  date: string;
  funding_round: string;
  funding_milestone_amount_usd?: number;
  funding_milestone_investors: string;
}

// First, add interfaces for the headcount data structure
interface HeadcountTimeseriesEntry {
  date: string;
  employee_count: number;
}

interface RoleMetrics {
  all_roles: string;
  "0_to_10_percent": string;
  "11_to_30_percent": string | null;
  "31_to_50_percent": string | null;
  "51_to_70_percent": string | null;
  "71_to_100_percent": string | null;
}

interface RoleGrowth {
  yoy: Record<string, number>;
  six_months: Record<string, number>;
}

interface SkillMetrics {
  all_skills: string;
  "0_to_10_percent": string | null;
  "11_to_30_percent": string | null;
  "31_to_50_percent": string | null;
  "51_to_70_percent": string | null;
  "71_to_100_percent": string | null;
}

interface HeadcountData {
  linkedin_headcount: number;
  role_metrics: RoleMetrics;
  role_percentages: Record<string, number>;
  role_absolute: Record<string, number>;
  role_growth: RoleGrowth;
  region_metrics: {
    all_regions: string;
    [key: string]: string | null;
  };
  region_percentages: Record<string, number>;
  region_absolute: Record<string, number>;
  total_growth_percent: {
    mom: number;
    qoq: number;
    yoy: number;
    six_months: number;
    two_years: number;
  };
  total_growth_absolute: {
    mom: number;
    qoq: number;
    yoy: number;
    six_months: number;
    two_years: number;
  };
  headcount_timeseries: HeadcountTimeseriesEntry[];
  headcount_by_function_timeseries: {
    CURRENT_FUNCTION: Record<string, HeadcountTimeseriesEntry[]>;
    GEO_REGION: Record<string, HeadcountTimeseriesEntry[]>;
  };
  skill_metrics: SkillMetrics;
  skill_percentages: Record<string, number>;
  skill_absolute: Record<string, number>;
}

// Add interfaces for timeseries data
interface TimeseriesEntry {
  date: string;
  monthly_visitors: number;
}

interface TrafficSourceEntry {
  date: string;
  traffic_source_direct_pct: number;
  traffic_source_search_pct: number;
  traffic_source_referral_pct: number;
  traffic_source_social_pct: number;
}

// Add interface for traffic source data
interface TrafficSource {
  name: string;
  value: number | null;
}

// Add this interface with the other interfaces at the top of the file
interface JobOpeningTimeseriesEntry {
  date: string;
  open_jobs: number;
}

// Then update the job_openings interface to include the proper type
interface job_openings {
  job_openings_count: number | null;
  growth_percent: {
    mom: number | null;
    qoq: number | null;
    yoy: number | null;
  };
  function_growth: {
    qoq: Record<string, number | null>;
    six_months: Record<string, number | null>;
  };
  recent_openings: JobOpening[];
  openings_timeseries: JobOpeningTimeseriesEntry[];
}

// First, add this interface near the top with other interfaces
interface NewsArticle {
  article_publish_date: string;
  article_publisher_name: string;
  article_title: string;
  article_url: string;
  company_id: number;
}

const CrustDataCompanyViewer = ({ data, credits }: Props) => {
  const [activeIndices, setActiveIndices] = useState<number[]>([]);

  // Access the nested data
  const companyData = data.largeActionResponse;
  const responseStructure = data.responseStructure;

  const findResponseName = (data: any, responseStructureId: string) => {
    const responseItem = responseStructure.find((item: any) => item.responseStructureId === responseStructureId);
    return data?.[responseItem?.name || ""] || null;
  };

  const findNestedResponseName = (data: any, path: string) => {
    const pathArray = path.split(".");
    let nestedData;
    if (pathArray.length === 1) {
      nestedData = findResponseName(data, path) || null;
    } else if (pathArray.length === 2) {
      nestedData = findResponseName(data, pathArray[0])[pathArray[1]] || null;
    } else if (pathArray.length === 3) {
      nestedData = findResponseName(data, pathArray[0])[pathArray[1]][pathArray[2]] || null;
    }
    return nestedData;
  };

  const linkedInFollowersInfo = {
    linkedin_followers: findNestedResponseName(companyData, "linkedin_follower_info.linkedin_followers"),
    linkedin_follower_count_timeseries:
      findNestedResponseName(companyData, "linkedin_follower_info.linkedin_follower_count_timeseries") || [],
    linkedin_followers_mom_percent: findNestedResponseName(
      companyData,
      "linkedin_follower_info.linkedin_followers_mom_percent"
    ),
    linkedin_followers_qoq_percent: findNestedResponseName(
      companyData,
      "linkedin_follower_info.linkedin_followers_qoq_percent"
    ),
    linkedin_followers_six_months_growth_percent: findNestedResponseName(
      companyData,
      "linkedin_follower_info.linkedin_followers_six_months_growth_percent"
    ),
    linkedin_followers_yoy_percent: findNestedResponseName(
      companyData,
      "linkedin_follower_info.linkedin_followers_yoy_percent"
    ),
  };

  // Basic company info section
  const basicInfo = {
    company_name: findResponseName(companyData, "company_name"),
    linkedin_profile_url: findResponseName(companyData, "linkedin_profile_url"),
    linkedin_id: findResponseName(companyData, "linkedin_id"),
    linkedin_logo_url: findResponseName(companyData, "linkedin_logo_url"),
    company_website: findResponseName(companyData, "company_website"),
    description: findResponseName(companyData, "description"),
    headquarters: findResponseName(companyData, "headquarters"),
    year_founded: findResponseName(companyData, "year_founded"),
    company_type: findResponseName(companyData, "company_type"),
    employee_count_range: findResponseName(companyData, "employee_count_range"),
    estimated_revenue: `USD ${findResponseName(companyData, "estimated_revenue_lower_bound_usd")} - USD ${findResponseName(companyData, "estimated_revenue_higher_bound_usd")}`,
    hq_country: findResponseName(companyData, "hq_country"),
    hq_street_address: findResponseName(companyData, "hq_street_address"),
    largest_headcount_country: findResponseName(companyData, "largest_headcount_country"),
    company_twitter_url: findResponseName(companyData, "company_twitter_url"),
    ceo_location: findResponseName(companyData, "ceo_location"),
    decision_makers: findResponseName(companyData, "decision_makers"),
    acquisition_status: findResponseName(companyData, "acquisition_status") || "No data found",
    all_office_addresses: findResponseName(companyData, "all_office_addresses") || [],
  };

  // Add this after the basicInfo constant
  const founders = {
    profiles: findNestedResponseName(companyData, "founders.profiles") || [],
    founders_locations: findNestedResponseName(companyData, "founders.founders_locations") || [],
    founders_degree_name: findNestedResponseName(companyData, "founders.founders_degree_name") || [],
    founders_previous_titles: findNestedResponseName(companyData, "founders.founders_previous_titles") || [],
    founders_previous_companies: findNestedResponseName(companyData, "founders.founders_previous_companies") || [],
    founders_education_institute: findNestedResponseName(companyData, "founders.founders_education_institute") || [],
  };

  // SEO data
  const seo = {
    // Traffic metrics
    monthly_visitors: findNestedResponseName(companyData, "seo.monthly_visitors"),
    monthly_visitor_mom_pct: findNestedResponseName(companyData, "seo.monthly_visitor_mom_pct"),
    monthly_visitor_qoq_pct: findNestedResponseName(companyData, "seo.monthly_visitor_qoq_pct"),

    // Traffic sources
    traffic_source_social_pct: findNestedResponseName(companyData, "seo.traffic_source_social_pct"),
    traffic_source_search_pct: findNestedResponseName(companyData, "seo.traffic_source_search_pct"),
    traffic_source_direct_pct: findNestedResponseName(companyData, "seo.traffic_source_direct_pct"),
    traffic_source_referral_pct: findNestedResponseName(companyData, "seo.traffic_source_referral_pct"),

    // SEO metrics
    average_ad_rank: findNestedResponseName(companyData, "seo.average_ad_rank"),
    monthly_paid_clicks: findNestedResponseName(companyData, "seo.monthly_paid_clicks"),
    total_ads_purchased: findNestedResponseName(companyData, "seo.total_ads_purchased"),
    monthly_organic_value: findNestedResponseName(companyData, "seo.monthly_organic_value"),
    total_organic_results: findNestedResponseName(companyData, "seo.total_organic_results"),
    monthly_organic_clicks: findNestedResponseName(companyData, "seo.monthly_organic_clicks"),
    average_seo_organic_rank: findNestedResponseName(companyData, "seo.average_seo_organic_rank"),
    lost_ranked_seo_keywords: findNestedResponseName(companyData, "seo.lost_ranked_seo_keywords"),
    monthly_google_ads_budget: findNestedResponseName(companyData, "seo.monthly_google_ads_budget"),
    newly_ranked_seo_keywords: findNestedResponseName(companyData, "seo.newly_ranked_seo_keywords"),
    gained_ranked_seo_keywords: findNestedResponseName(companyData, "seo.gained_ranked_seo_keywords"),
  };

  // Job openings data
  const job_openings: job_openings = {
    // Basic metrics
    job_openings_count: findNestedResponseName(companyData, "job_openings.job_openings_count"),

    // Growth metrics
    growth_percent: {
      mom: findNestedResponseName(companyData, "job_openings.job_openings_count_growth_percent.mom"),
      qoq: findNestedResponseName(companyData, "job_openings.job_openings_count_growth_percent.qoq"),
      yoy: findNestedResponseName(companyData, "job_openings.job_openings_count_growth_percent.yoy"),
    },

    // Function growth metrics
    function_growth: {
      qoq: findNestedResponseName(companyData, "job_openings.job_openings_by_function_qoq_pct") || {},
      six_months:
        findNestedResponseName(companyData, "job_openings.job_openings_by_function_six_months_growth_pct") || {},
    },

    // Recent openings
    recent_openings: findNestedResponseName(companyData, "job_openings.recent_job_openings") || [],
    // Timeseries data
    openings_timeseries: findNestedResponseName(companyData, "job_openings.open_jobs_timeseries") || [],
  };

  // Add this after other data constants
  const funding_and_investment = {
    crunchbase_investors: findNestedResponseName(companyData, "funding_and_investment.crunchbase_investors") || [],
    last_funding_round_type:
      findNestedResponseName(companyData, "funding_and_investment.last_funding_round_type") || "",
    days_since_last_fundraise: findNestedResponseName(companyData, "funding_and_investment.days_since_last_fundraise"),
    funding_milestones_timeseries:
      findNestedResponseName(companyData, "funding_and_investment.funding_milestones_timeseries") || [],
    crunchbase_total_investment_usd: findNestedResponseName(
      companyData,
      "funding_and_investment.crunchbase_total_investment_usd"
    ),
    last_funding_round_investment_usd: findNestedResponseName(
      companyData,
      "funding_and_investment.last_funding_round_investment_usd"
    ),
  };

  // Add this after other data constants
  const competitors = {
    competitor_website_domains: findNestedResponseName(companyData, "competitors.competitor_website_domains") || [],
    paid_seo_competitors_website_domains:
      findNestedResponseName(companyData, "competitors.paid_seo_competitors_website_domains") || [],
    organic_seo_competitors_website_domains:
      findNestedResponseName(companyData, "competitors.organic_seo_competitors_website_domains") || [],
  };

  // Add this after other data constants
  const g2 = {
    g2_review_count: findNestedResponseName(companyData, "g2.g2_review_count"),
    g2_average_rating: findNestedResponseName(companyData, "g2.g2_average_rating"),
    g2_review_count_mom_pct: findNestedResponseName(companyData, "g2.g2_review_count_mom_pct"),
    g2_review_count_qoq_pct: findNestedResponseName(companyData, "g2.g2_review_count_qoq_pct"),
    g2_review_count_yoy_pct: findNestedResponseName(companyData, "g2.g2_review_count_yoy_pct"),
  };

  // Add this after other data constants
  const glassdoor = {
    glassdoor_review_count: findNestedResponseName(companyData, "glassdoor.glassdoor_review_count"),
    glassdoor_culture_rating: findNestedResponseName(companyData, "glassdoor.glassdoor_culture_rating"),
    glassdoor_overall_rating: findNestedResponseName(companyData, "glassdoor.glassdoor_overall_rating"),
    glassdoor_ceo_approval_pct: findNestedResponseName(companyData, "glassdoor.glassdoor_ceo_approval_pct"),
    glassdoor_diversity_rating: findNestedResponseName(companyData, "glassdoor.glassdoor_diversity_rating"),
    glassdoor_compensation_rating: findNestedResponseName(companyData, "glassdoor.glassdoor_compensation_rating"),
    glassdoor_business_outlook_pct: findNestedResponseName(companyData, "glassdoor.glassdoor_business_outlook_pct"),
    glassdoor_recommend_to_friend_pct: findNestedResponseName(
      companyData,
      "glassdoor.glassdoor_recommend_to_friend_pct"
    ),
    glassdoor_senior_management_rating: findNestedResponseName(
      companyData,
      "glassdoor.glassdoor_senior_management_rating"
    ),
    glassdoor_work_life_balance_rating: findNestedResponseName(
      companyData,
      "glassdoor.glassdoor_work_life_balance_rating"
    ),
    glassdoor_career_opportunities_rating: findNestedResponseName(
      companyData,
      "glassdoor.glassdoor_career_opportunities_rating"
    ),
    glassdoor_ceo_approval_growth_percent: findNestedResponseName(
      companyData,
      "glassdoor.glassdoor_ceo_approval_growth_percent"
    ),
    glassdoor_review_count_growth_percent: findNestedResponseName(
      companyData,
      "glassdoor.glassdoor_review_count_growth_percent"
    ),
  };

  // Then update the headcount declaration
  const headcount: HeadcountData = {
    linkedin_headcount: findNestedResponseName(companyData, "headcount.linkedin_headcount") || "",
    role_metrics: findNestedResponseName(companyData, "headcount.linkedin_role_metrics") || {},
    role_percentages: findNestedResponseName(companyData, "headcount.linkedin_headcount_by_role_percent"),
    role_absolute: findNestedResponseName(companyData, "headcount.linkedin_headcount_by_role_absolute") || {},
    role_growth: {
      yoy: findNestedResponseName(companyData, "headcount.linkedin_headcount_by_role_yoy_growth_percent") || {},
      six_months:
        findNestedResponseName(companyData, "headcount.linkedin_headcount_by_role_six_months_growth_percent") || {},
    },
    region_metrics: findNestedResponseName(companyData, "headcount.linkedin_region_metrics") || {},
    region_percentages: findNestedResponseName(companyData, "headcount.linkedin_headcount_by_region_percent"),
    region_absolute: findNestedResponseName(companyData, "headcount.linkedin_headcount_by_region_absolute"),
    total_growth_percent: findNestedResponseName(companyData, "headcount.linkedin_headcount_total_growth_percent"),
    total_growth_absolute: findNestedResponseName(companyData, "headcount.linkedin_headcount_total_growth_absolute"),
    headcount_timeseries: findNestedResponseName(companyData, "headcount.linkedin_headcount_timeseries"),
    headcount_by_function_timeseries: findNestedResponseName(
      companyData,
      "headcount.linkedin_headcount_by_function_timeseries"
    ),
    skill_metrics: findNestedResponseName(companyData, "headcount.linkedin_skill_metrics") || {},
    skill_percentages: findNestedResponseName(companyData, "headcount.linkedin_headcount_by_skill_percent"),
    skill_absolute: findNestedResponseName(companyData, "headcount.linkedin_headcount_by_skill_absolute") || {},
  };
  // Add this after other data constants
  const markets = {
    markets: findNestedResponseName(companyData, "markets") || [],
  };

  // Add this after other data constants
  const stockData = {
    symbols: findNestedResponseName(companyData, "stock_symbols") || [],
  };

  // Add this after other data constants
  const taxonomy = {
    linkedin_industries: findNestedResponseName(companyData, "taxonomy.linkedin_industries") || [],
    crunchbase_categories: findNestedResponseName(companyData, "taxonomy.crunchbase_categories") || [],
    linkedin_specialities: findNestedResponseName(companyData, "taxonomy.linkedin_specialities") || [],
  };

  // Update the web_traffic constant
  const web_traffic = {
    // Basic metrics
    monthly_visitors: findNestedResponseName(companyData, "web_traffic.monthly_visitors"),
    monthly_visitor_mom_pct: findNestedResponseName(companyData, "web_traffic.monthly_visitor_mom_pct"),
    monthly_visitor_qoq_pct: findNestedResponseName(companyData, "web_traffic.monthly_visitor_qoq_pct"),

    // Traffic sources
    traffic_sources: {
      direct: findNestedResponseName(companyData, "web_traffic.traffic_source_direct_pct"),
      search: findNestedResponseName(companyData, "web_traffic.traffic_source_search_pct"),
      social: findNestedResponseName(companyData, "web_traffic.traffic_source_social_pct"),
      referral: findNestedResponseName(companyData, "web_traffic.traffic_source_referral_pct"),
      paid_referral: findNestedResponseName(companyData, "web_traffic.traffic_source_paid_referral_pct"),
    },

    // Timeseries data
    monthly_visitors_timeseries:
      // companyData["Web Traffic"]?.monthly_visitors_timeseries,
      findNestedResponseName(companyData, "web_traffic.monthly_visitors_timeseries"),
    traffic_sources_timeseries: {
      direct: findNestedResponseName(companyData, "web_traffic.traffic_source_direct_pct_timeseries"),
      search: findNestedResponseName(companyData, "web_traffic.traffic_source_search_pct_timeseries"),
      social: findNestedResponseName(companyData, "web_traffic.traffic_source_social_pct_timeseries"),
      referral: findNestedResponseName(companyData, "web_traffic.traffic_source_referral_pct_timeseries"),
      paid_referral: findNestedResponseName(companyData, "web_traffic.traffic_source_paid_referral_pct_timeseries"),
    },
  };

  // First, add a new constant for LinkedIn data
  const linkedinData = {
    linkedin_company_description: findNestedResponseName(companyData, "linkedin_company_description"),
    followers: data.linkedin_followers?.linkedin_followers,
    followers_mom_growth: data.linkedin_followers?.linkedin_followers_mom_percent,
    followers_yoy_growth: data.linkedin_followers?.linkedin_followers_yoy_percent,
    linkedin_id: findNestedResponseName(companyData, "linkedin_id"),
    linkedin_logo_url: findNestedResponseName(companyData, "linkedin_logo_url"),
    linkedin_profile_url: findNestedResponseName(companyData, "linkedin_profile_url"),
    linkedin_follower_count_timeseries: findNestedResponseName(companyData, "linkedin_follower_count_timeseries"),
  };

  const handleCopyText = async (text: string, e: React.MouseEvent) => {
    e.stopPropagation();
    if (!text) return;
    try {
      await navigator.clipboard.writeText(text);
      message.success("Content copied to clipboard!");
    } catch (err) {
      message.error("Failed to copy content.");
      console.error("Copy error:", err);
    }
  };

  const renderMetricSection = (title: string, data: any, index: number) => {
    if (!data) return null;

    const handleClick = () => {
      // If the section is already open, close it
      if (activeIndices.includes(index)) {
        setActiveIndices(activeIndices.filter((i) => i !== index));
      } else {
        // Close any other open sections and open this one
        setActiveIndices([index]);
      }
    };

    return (
      <div
        className="flex items-center justify-between p-2 bg-gray-50 cursor-pointer hover:bg-gray-100"
        onClick={handleClick}
      >
        <span className="font-medium">{title}</span>
        <span className={`transform transition-transform ${activeIndices.includes(index) ? "rotate-180" : ""}`}>▼</span>
      </div>
    );
  };

  const renderField = (label: string, value: any) => {
    if (value === undefined || value === null) return null;

    // Handle array values
    if (Array.isArray(value)) {
      return (
        <div className="w-auto flex-1 group flex flex-col items-start justify-between">
          <p className="text-[#575757]">{label}</p>
          <div className="bg-[#F2FBFF] w-full text-left px-2 py-1">
            <div className="max-h-40 overflow-y-auto">
              {value?.map((item, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between py-1 border-b border-gray-100 last:border-0"
                >
                  <p className="w-[96%]">{item}</p>
                  <span
                    className="cursor-pointer hidden group-hover:block"
                    onClick={(e) => handleCopyText(String(item), e)}
                  >
                    <FaRegCopy className="text-gray-600" />
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }

    // Handle string/number values
    return (
      <div className="w-auto flex-1 group flex flex-col items-start justify-between">
        <p className="text-[#575757]">{label}</p>
        <div className="bg-[#F2FBFF] w-full text-left px-2 py-1">
          <div className="max-h-40 overflow-y-auto">
            <div className="flex items-center justify-between">
              <p className="w-[96%]">{value || "\u00A0"}</p>
              <span
                className="cursor-pointer hidden group-hover:block"
                onClick={(e) => handleCopyText(String(value), e)}
              >
                <FaRegCopy className="text-gray-600" />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // First, add a helper function to format the date
  const formatDate = (dateString: string) => {
    return new Date(dateString)?.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  return (
    <>
      <App>
        <div className="space-y-4">
          {/* Basic Info Section - Always Visible */}
          <div className="space-y-4 mb-6">
            {/* Company Details */}
            <div>
              <p className="text-[#575757] font-semibold mb-2">Company Information</p>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                {/* First Column */}
                <div className="space-y-2">
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">Company Name</p>
                    <div className="max-h-40 overflow-y-auto">
                      <p className="break-words">{basicInfo.company_name}</p>
                    </div>
                  </div>
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">Company Type</p>
                    <div className="max-h-40 overflow-y-auto">
                      <p className="break-words">{basicInfo.company_type}</p>
                    </div>
                  </div>
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">Year Founded</p>
                    <div className="max-h-40 overflow-y-auto">
                      <p className="break-words">{basicInfo.year_founded}</p>
                    </div>
                  </div>
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">Employee Count Range</p>
                    <div className="max-h-40 overflow-y-auto">
                      <p className="break-words">{basicInfo.employee_count_range}</p>
                    </div>
                  </div>
                </div>
                {/* Second Column */}
                <div className="space-y-2 self-start">
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">Estimated Revenue Range (USD)</p>
                    <div className="max-h-40 overflow-y-auto">
                      <p className="break-words">{basicInfo.estimated_revenue}</p>
                    </div>
                  </div>
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">Company Website</p>
                    <div className="max-h-40 overflow-y-auto">
                      <a
                        href={basicInfo.company_website}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline break-words"
                      >
                        {basicInfo.company_website}
                      </a>
                    </div>
                  </div>
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">Acquisition Status</p>
                    <div className="max-h-40 overflow-y-auto">
                      <p className="break-words">{basicInfo.acquisition_status}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Location Details */}
            <div>
              <p className="text-[#575757] font-semibold mb-2">Location Information</p>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                {/* First Column */}
                <div className="space-y-2">
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">Headquarters</p>
                    <div className="max-h-40 overflow-y-auto">
                      <p className="break-words">{basicInfo.headquarters}</p>
                    </div>
                  </div>
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">HQ Country</p>
                    <div className="max-h-40 overflow-y-auto">
                      <p className="break-words">{basicInfo.hq_country}</p>
                    </div>
                  </div>
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">HQ Address</p>
                    <div className="max-h-40 overflow-y-auto">
                      <p className="break-words">{basicInfo.hq_street_address}</p>
                    </div>
                  </div>
                </div>
                {/* Second Column */}
                <div className="space-y-2 self-start">
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">CEO Location</p>
                    <div className="max-h-40 overflow-y-auto">
                      <p className="break-words">{basicInfo.ceo_location}</p>
                    </div>
                  </div>
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">Largest Headcount Country</p>
                    <div className="max-h-40 overflow-y-auto">
                      <p className="break-words">{basicInfo.largest_headcount_country}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* All Office Addresses */}
            {basicInfo.all_office_addresses.length > 0 && (
              <div>
                <p className="text-[#575757] font-semibold mb-2">All Office Addresses</p>
                <div className="bg-[#F2FBFF] p-2">
                  <div className="max-h-60 overflow-y-auto">
                    {basicInfo.all_office_addresses?.map((address: string, index: number) => (
                      <div
                        key={index}
                        className="group flex items-center justify-between py-1 border-b border-gray-100 last:border-0"
                      >
                        <span className="break-words">{address}</span>
                        <FaRegCopy
                          className="text-gray-600 cursor-pointer hidden group-hover:block"
                          onClick={(e) => handleCopyText(address, e)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* LinkedIn Followers Section - after basic info */}
          <div className="space-y-4 mb-6">
            <p className="text-[#575757] font-semibold mb-2">LinkedIn Followers</p>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              {/* Current Followers and Growth */}
              <div className="space-y-2">
                <div className="bg-[#F2FBFF] p-2">
                  <p className="text-sm text-gray-600">Current Followers</p>
                  <p className="break-words">{linkedInFollowersInfo.linkedin_followers?.toLocaleString()}</p>
                </div>
                <div className="bg-[#F2FBFF] p-2">
                  <p className="text-sm text-gray-600">Growth Metrics</p>
                  <div className="space-y-1">
                    <div className="flex justify-between">
                      <span>MoM:</span>
                      <span>{linkedInFollowersInfo.linkedin_followers_mom_percent?.toFixed(2)}%</span>
                    </div>
                    <div className="flex justify-between">
                      <span>QoQ:</span>
                      <span>{linkedInFollowersInfo.linkedin_followers_qoq_percent?.toFixed(2)}%</span>
                    </div>
                    <div className="flex justify-between">
                      <span>YoY:</span>
                      <span>{linkedInFollowersInfo.linkedin_followers_yoy_percent?.toFixed(2)}%</span>
                    </div>
                    <div className="flex justify-between">
                      <span>6 Month:</span>
                      <span>{linkedInFollowersInfo.linkedin_followers_six_months_growth_percent?.toFixed(2)}%</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Followers Timeline */}
              <div className="space-y-2">
                <div className="bg-[#F2FBFF] p-2">
                  <p className="text-sm text-gray-600 mb-2">Followers Timeline</p>
                  <div className="h-[200px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
                    {linkedInFollowersInfo.linkedin_follower_count_timeseries
                      ?.sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime())
                      .map((entry: any, index: number) => (
                        <div
                          key={index}
                          className="flex justify-between items-center py-1.5 border-b border-gray-100 last:border-0 hover:bg-gray-50"
                        >
                          <span className="text-sm">{formatDate(entry.date)}</span>
                          <span className="text-sm font-medium">{entry.follower_count.toLocaleString()} followers</span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Other sections with dropdowns continue below */}
          {/* LinkedIn Details */}
          <div className="space-y-4 mt-2">
            <div>
              <p className="text-[#575757] font-semibold mb-2">LinkedIn Details</p>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="space-y-2">
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">LinkedIn ID</p>
                    <p className="break-words">{linkedinData.linkedin_id}</p>
                  </div>
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">Profile URL</p>
                    <a
                      href={linkedinData.linkedin_profile_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:underline break-words"
                    >
                      {linkedinData.linkedin_profile_url}
                    </a>
                  </div>
                </div>
                <div className="space-y-2 self-start">
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">Logo URL</p>
                    <a
                      href={linkedinData.linkedin_profile_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:underline break-words"
                    >
                      {linkedinData.linkedin_logo_url}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* Company Description */}
            <div>
              <p className="text-[#575757] font-semibold mb-2">Company Description</p>
              <div className="bg-[#F2FBFF] p-2">
                <p className="whitespace-pre-wrap">{linkedinData.linkedin_company_description}</p>
              </div>
            </div>
          </div>

          {/* Founders Information */}
          {renderMetricSection("Founders Information", founders, 8)}
          {activeIndices.includes(8) && (
            <div className="space-y-2 mt-2">
              {founders.founders_locations.length === 0 &&
                founders.founders_previous_companies.length === 0 &&
                (!founders.founders_degree_name?.length || founders.founders_degree_name.length === 0) &&
                (!founders.founders_education_institute?.length ||
                  founders.founders_education_institute.length === 0) && (
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-gray-600">No data found</p>
                  </div>
                )}

              {founders.founders_locations.length > 0 && (
                <div className="w-full">
                  <p className="text-[#575757]">Founder Locations</p>
                  <div className="bg-[#F2FBFF] p-2">
                    <div className="max-h-40 overflow-y-auto">
                      {founders.founders_locations?.map((location: string, index: number) => (
                        <div
                          key={index}
                          className="group flex items-center justify-between py-1 border-b border-gray-100 last:border-0"
                        >
                          <p>{location}</p>
                          <FaRegCopy
                            className="text-gray-600 cursor-pointer hidden group-hover:block"
                            onClick={(e) => handleCopyText(location, e)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {founders.founders_previous_companies.length > 0 && (
                <div className="w-full mt-4">
                  <p className="text-[#575757]">Previous Companies</p>
                  <div className="bg-[#F2FBFF] p-2">
                    <div className="max-h-40 overflow-y-auto">
                      {founders.founders_previous_companies?.map((company: string, index: number) => (
                        <div
                          key={index}
                          className="group flex items-center justify-between py-1 border-b border-gray-100 last:border-0"
                        >
                          <p>{company}</p>
                          <FaRegCopy
                            className="text-gray-600 cursor-pointer hidden group-hover:block"
                            onClick={(e) => handleCopyText(company, e)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {/* Education Information */}
              {(founders.founders_degree_name?.length > 0 || founders.founders_education_institute?.length > 0) && (
                <div className="grid grid-cols-1 gap-4 mt-4">
                  {founders.founders_degree_name?.length > 0 &&
                    renderField("Education Degrees", founders.founders_degree_name)}
                  {founders.founders_education_institute?.length > 0 &&
                    renderField("Education Institutes", founders.founders_education_institute)}
                </div>
              )}
            </div>
          )}

          {/* Headcount Section */}
          {renderMetricSection("LinkedIn Headcount Metrics", headcount, 1)}
          {activeIndices.includes(1) && (
            <div className="space-y-4 mt-2">
              {/* Basic Metrics */}
              <div>
                <p className="text-[#575757] font-semibold mb-2">Basic Metrics</p>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  {/* First Column */}
                  <div className="space-y-2">
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Current Headcount</p>
                      <p className="break-words">{headcount.linkedin_headcount?.toLocaleString()}</p>
                    </div>
                  </div>
                  {/* Second Column */}
                  <div className="space-y-2 self-start">
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Growth Metrics</p>
                      <div className="space-y-1">
                        <div className="flex justify-between">
                          <span>MoM:</span>
                          <div>
                            <span>{headcount.total_growth_percent?.mom}%</span>
                            <span className="ml-2 text-gray-500">
                              ({headcount.total_growth_absolute?.mom} employees)
                            </span>
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <span>QoQ:</span>
                          <div>
                            <span>{headcount.total_growth_percent?.qoq}%</span>
                            <span className="ml-2 text-gray-500">
                              ({headcount.total_growth_absolute?.qoq} employees)
                            </span>
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <span>YoY:</span>
                          <div>
                            <span>{headcount.total_growth_percent?.yoy}%</span>
                            <span className="ml-2 text-gray-500">
                              ({headcount.total_growth_absolute?.yoy} employees)
                            </span>
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <span>6 Month:</span>
                          <div>
                            <span>{headcount.total_growth_percent?.six_months}%</span>
                            <span className="ml-2 text-gray-500">
                              ({headcount.total_growth_absolute?.six_months} employees)
                            </span>
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <span>2 Year:</span>
                          <div>
                            <span>{headcount.total_growth_percent?.two_years}%</span>
                            <span className="ml-2 text-gray-500">
                              ({headcount.total_growth_absolute?.two_years} employees)
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Role Information */}
              <div className="mt-4">
                <p className="text-[#575757] font-semibold mb-2">Role Distribution</p>

                {/* All Roles Overview */}
                <div className="bg-[#F2FBFF] p-2 mb-4">
                  <p className="text-sm text-gray-600 mb-2">All Roles</p>
                  <p className="break-words">{headcount.role_metrics.all_roles?.split(", ")?.sort()?.join(", ")}</p>
                </div>

                {/* Role Distribution by Percentage Range */}
                <div className="mb-4">
                  <p className="text-sm text-gray-600 mb-2">Distribution by Percentage Range</p>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    {Object.entries(headcount.role_metrics)
                      ?.filter(([key]) => key !== "all_roles")
                      ?.map(
                        ([range, roles]) =>
                          roles && (
                            <div key={range} className="bg-[#F2FBFF] p-2">
                              <p className="text-sm text-gray-600 font-medium">
                                {range === "0_to_10_percent"
                                  ? "0-10%"
                                  : range === "11_to_30_percent"
                                    ? "11-30%"
                                    : range === "31_to_50_percent"
                                      ? "31-50%"
                                      : range === "51_to_70_percent"
                                        ? "51-70%"
                                        : range === "71_to_100_percent"
                                          ? "71-100%"
                                          : range}
                              </p>
                              <p className="break-words text-sm mt-1">
                                {String(roles)?.replace(/['"]/g, "")?.split(", ")?.join(", ") || ""}
                              </p>
                            </div>
                          )
                      )}
                  </div>
                </div>

                {/* Role Counts and Percentages */}
                <div className="mb-4">
                  <p className="text-sm text-gray-600 mb-2">Role Distribution Details</p>
                  <div className="bg-[#F2FBFF] p-2">
                    {Object.entries(headcount.role_absolute)
                      ?.sort(([, a], [, b]) => (b as number) - (a as number))
                      ?.map(([role, count], index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0"
                        >
                          <span className="text-sm">{role}</span>
                          <div className="flex items-center gap-4">
                            <span className="text-sm">{count?.toLocaleString()} employees</span>
                            <span className="text-sm">({headcount.role_percentages[role]?.toFixed(2)}%)</span>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                {/* Role Growth */}
                <div>
                  <p className="text-sm text-gray-600 mb-2">Role Growth</p>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    {/* YoY Growth */}
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600 font-medium mb-2">Year over Year Growth</p>
                      {Object.entries(headcount.role_growth.yoy)
                        ?.sort(([, a], [, b]) => (b as number) - (a as number))
                        ?.map(([role, growth], index) => (
                          <div
                            key={index}
                            className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0"
                          >
                            <span className="text-sm capitalize">{role?.replace(/_/g, " ")}</span>
                            <span className="text-sm">{(growth as number)?.toFixed(2)}%</span>
                          </div>
                        ))}
                    </div>

                    {/* 6-Month Growth */}
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600 font-medium mb-2">6-Month Growth</p>
                      {Object.entries(headcount.role_growth.six_months)
                        ?.sort(([, a], [, b]) => (b as number) - (a as number))
                        ?.map(([role, growth], index) => (
                          <div
                            key={index}
                            className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0"
                          >
                            <span className="text-sm capitalize">{role?.replace(/_/g, " ")}</span>
                            <span className="text-sm">{(growth as number)?.toFixed(2)}%</span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>

              {/* Skills Information */}
              <div className="mt-4">
                <p className="text-[#575757] font-semibold mb-2">Skills Distribution</p>

                {/* All Skills Overview */}
                <div className="bg-[#F2FBFF] p-2 mb-4">
                  <p className="text-sm text-gray-600 mb-2">All Skills</p>
                  <p className="break-words">
                    {headcount.skill_metrics?.all_skills
                      ? headcount.skill_metrics.all_skills?.split(", ")?.sort()?.join(", ")
                      : "No skills data available"}
                  </p>
                </div>

                {/* Skills Distribution by Percentage Range */}
                <div className="mb-4">
                  <p className="text-sm text-gray-600 mb-2">Distribution by Percentage Range</p>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    {Object.entries(headcount.skill_metrics)
                      ?.filter(([key]) => key !== "all_skills" && key !== "__typename")
                      ?.map(
                        ([range, skills]) =>
                          skills && (
                            <div key={range} className="bg-[#F2FBFF] p-2">
                              <p className="text-sm text-gray-600 font-medium">
                                {range === "0_to_10_percent"
                                  ? "0-10%"
                                  : range === "11_to_30_percent"
                                    ? "11-30%"
                                    : range === "31_to_50_percent"
                                      ? "31-50%"
                                      : range === "51_to_70_percent"
                                        ? "51-70%"
                                        : range === "71_to_100_percent"
                                          ? "71-100%"
                                          : range}
                              </p>
                              <p className="break-words text-sm mt-1">
                                {typeof skills === "string"
                                  ? skills?.replace(/['"]/g, "")?.split(", ")?.join(", ")
                                  : "No data available"}
                              </p>
                            </div>
                          )
                      )}
                  </div>
                </div>

                {/* Skills Counts and Percentages */}
                <div className="mb-4">
                  <p className="text-sm text-gray-600 mb-2">Skills Distribution Details</p>
                  <div className="bg-[#F2FBFF] p-2">
                    {Object.entries(headcount.skill_absolute)
                      ?.sort(([, a], [, b]) => b - a)
                      ?.map(([skill, count], index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0"
                        >
                          <span className="text-sm">{skill}</span>
                          <div className="flex items-center gap-4">
                            <span className="text-sm">{count?.toLocaleString()} employees</span>
                            <span className="text-sm">({headcount.skill_percentages[skill]?.toFixed(2)}%)</span>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              {/* Regional Distribution */}
              <div>
                <p className="text-[#575757] font-semibold mb-2">Region Distribution by Percentage</p>
                <div className="space-y-4">
                  {/* All Regions */}
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">All Regions</p>
                    <p className="break-words">
                      {headcount.region_metrics.all_regions
                        ? String(headcount.region_metrics.all_regions)
                            ?.replace(/['"]/g, "") // Remove all quotes
                            ?.split(", ") // Split into array
                            ?.join(", ") // Join back with commas
                        : "None"}
                    </p>
                  </div>

                  {/* Distribution by Percentage Ranges */}
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    {Object.entries(headcount.region_metrics)
                      ?.filter(([key]) => key !== "all_regions")
                      ?.map(([range, regions]) => (
                        <div key={range} className="bg-[#F2FBFF] p-2">
                          <p className="text-sm text-gray-600">
                            {range === "0_to_10_percent"
                              ? "0-10%"
                              : range === "11_to_30_percent"
                                ? "11-30%"
                                : range === "31_to_50_percent"
                                  ? "31-50%"
                                  : range === "51_to_70_percent"
                                    ? "51-70%"
                                    : range === "71_to_100_percent"
                                      ? "71-100%"
                                      : range}
                          </p>
                          <p className="break-words">
                            {regions
                              ? String(regions)
                                  ?.replace(/['"]/g, "") // Remove quotes
                                  ?.split(", ") // Split into array
                                  ?.join(", ") // Join back with commas
                              : "None"}
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              {/* Region Absolute Counts */}
              <div className="mt-4">
                <p className="text-[#575757] font-semibold mb-2">Regional Headcount Distribution</p>
                <div className="bg-[#F2FBFF] p-2">
                  {Object.entries(headcount.region_absolute || {})
                    ?.sort(([, a], [, b]) => (b as number) - (a as number))
                    ?.map(([region, count], index) => (
                      <div
                        key={index}
                        className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0"
                      >
                        <span className="text-sm">{region}</span>
                        <span className="text-sm font-medium">{count?.toLocaleString()} employees</span>
                      </div>
                    ))}
                </div>
              </div>

              {/* Region Percentages */}
              <div className="mt-4">
                <p className="text-[#575757] font-semibold mb-2">Regional Distribution Percentages</p>
                <div className="bg-[#F2FBFF] p-2">
                  {Object.entries(headcount.region_percentages || {})
                    ?.sort(([, a], [, b]) => (b as number) - (a as number))
                    ?.map(([region, percentage], index) => (
                      <div
                        key={index}
                        className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0"
                      >
                        <span className="text-sm">{region}</span>
                        <span className="text-sm font-medium">{(percentage as number)?.toFixed(2)}%</span>
                      </div>
                    ))}
                </div>
              </div>

              {/* Headcount Time Series */}
              <div className="mt-4">
                <p className="text-[#575757] font-semibold mb-2">Headcount Time Series</p>

                {/* Function-wise Headcount */}
                <div className="space-y-4">
                  <p className="text-[#575757] font-medium">By Function</p>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    {Object.entries(headcount.headcount_by_function_timeseries?.CURRENT_FUNCTION || {})?.map(
                      ([function_name, data]) => (
                        <div key={function_name} className="bg-[#F2FBFF] p-2">
                          <p className="text-sm text-gray-600 font-medium mb-2">
                            {function_name?.replace(/_/g, " ")?.replace(/\b\w/g, (l) => l?.toUpperCase())}
                          </p>
                          <div className="max-h-40 overflow-y-auto">
                            {(data as Array<{ date: string; employee_count: number }>)
                              ?.sort((a, b) => new Date(b.date)?.getTime() - new Date(a.date)?.getTime())
                              ?.map((entry, index) => (
                                <div
                                  key={index}
                                  className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0"
                                >
                                  <span className="text-sm">{formatDate(entry.date)}</span>
                                  <span className="text-sm font-medium">
                                    {entry.employee_count?.toLocaleString()} employees
                                  </span>
                                </div>
                              ))}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>

                {/* Region-wise Headcount */}
                <div className="space-y-4 mt-4">
                  <p className="text-[#575757] font-medium">By Region</p>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    {Object.entries(headcount.headcount_by_function_timeseries?.GEO_REGION || {})?.map(
                      ([region, data]) => (
                        <div key={region} className="bg-[#F2FBFF] p-2">
                          <p className="text-sm text-gray-600 font-medium mb-2">{region}</p>
                          <div className="max-h-40 overflow-y-auto">
                            {(data as Array<{ date: string; employee_count: number }>)
                              ?.sort((a, b) => new Date(b.date)?.getTime() - new Date(a.date)?.getTime())
                              ?.map((entry, index) => (
                                <div
                                  key={index}
                                  className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0"
                                >
                                  <span className="text-sm">{formatDate(entry.date)}</span>
                                  <span className="text-sm font-medium">
                                    {entry.employee_count?.toLocaleString()} employees
                                  </span>
                                </div>
                              ))}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>

                {/* Overall Headcount Time Series */}
                <div className="mt-4">
                  <p className="text-[#575757] font-semibold mb-2">Overall Headcount Timeline</p>
                  <div className="bg-[#F2FBFF] p-2">
                    <div className="max-h-60 overflow-y-auto">
                      {headcount.headcount_timeseries
                        ?.sort((a, b) => new Date(b.date)?.getTime() - new Date(a.date)?.getTime())
                        ?.map((entry, index) => (
                          <div
                            key={index}
                            className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0"
                          >
                            <span className="text-sm">{formatDate(entry.date)}</span>
                            <span className="text-sm font-medium">
                              {entry.employee_count?.toLocaleString()} employees
                            </span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Glassdoor */}
          {renderMetricSection("Glassdoor Metrics", glassdoor, 3)}
          {activeIndices.includes(3) && (
            <div className="space-y-4 mt-2">
              {/* Basic Metrics */}
              <div className="grid grid-cols-2 gap-4">
                {renderField("Review Count", glassdoor.glassdoor_review_count?.toLocaleString())}
                {renderField("Overall Rating", glassdoor.glassdoor_overall_rating?.toFixed(2))}
                {renderField("CEO Approval %", glassdoor.glassdoor_ceo_approval_pct)}
                {renderField("Business Outlook %", glassdoor.glassdoor_business_outlook_pct)}
                {renderField("Recommend to Friend %", glassdoor.glassdoor_recommend_to_friend_pct)}
              </div>

              {/* Detailed Ratings */}
              <div className="mt-4">
                <p className="text-[#575757] font-semibold mb-2">Detailed Ratings</p>
                <div className="grid grid-cols-2 gap-4">
                  {renderField("Culture Rating", glassdoor.glassdoor_culture_rating)}
                  {renderField("Diversity Rating", glassdoor.glassdoor_diversity_rating)}
                  {renderField("Compensation Rating", glassdoor.glassdoor_compensation_rating?.toFixed(2))}
                  {renderField("Senior Management Rating", glassdoor.glassdoor_senior_management_rating?.toFixed(2))}
                  {renderField("Work Life Balance", glassdoor.glassdoor_work_life_balance_rating)}
                  {renderField("Career Opportunities", glassdoor.glassdoor_career_opportunities_rating?.toFixed(2))}
                </div>
              </div>

              {/* Growth Metrics */}
              <div className="mt-4">
                <p className="text-[#575757] font-semibold mb-2">Growth Metrics</p>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-[#575757]">Review Count Growth %</p>
                    <div className="space-y-1">
                      {renderField("MoM", glassdoor.glassdoor_review_count_growth_percent?.mom)}
                      {renderField("QoQ", glassdoor.glassdoor_review_count_growth_percent?.qoq)}
                      {renderField("YoY", glassdoor.glassdoor_review_count_growth_percent?.yoy)}
                    </div>
                  </div>
                  <div>
                    <p className="text-[#575757]">CEO Approval Growth %</p>
                    <div className="space-y-1">
                      {renderField("MoM", glassdoor.glassdoor_ceo_approval_growth_percent?.mom)}
                      {renderField("QoQ", glassdoor.glassdoor_ceo_approval_growth_percent?.qoq)}
                      {renderField("YoY", glassdoor.glassdoor_ceo_approval_growth_percent?.yoy)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* G2 Reviews */}
          {renderMetricSection("G2 Reviews", g2, 4)}
          {activeIndices.includes(4) && (
            <div className="space-y-2 mt-2">
              <div className="grid grid-cols-2 gap-4">
                {renderField("Review Count", g2.g2_review_count?.toLocaleString())}
                {renderField("Average Rating", g2.g2_average_rating)}
                {renderField("MoM Growth %", g2.g2_review_count_mom_pct)}
                {renderField("QoQ Growth %", g2.g2_review_count_qoq_pct)}
                {renderField("YoY Growth %", g2.g2_review_count_yoy_pct)}
              </div>
            </div>
          )}

          {/* LinkedIn Followers */}
          {renderMetricSection("LinkedIn Followers", data.linkedin_followers, 5)}
          {activeIndices.includes(5) && (
            <div className="space-y-2 mt-2">
              <div className="grid grid-cols-2 gap-4">
                {renderField("Follower Count", data.linkedin_followers?.linkedin_followers)}
                {renderField("MoM Growth %", data.linkedin_followers?.linkedin_followers_mom_percent)}
                {renderField("YoY Growth %", data.linkedin_followers?.linkedin_followers_yoy_percent)}
              </div>
            </div>
          )}

          {/* Funding Information */}
          {renderMetricSection("Funding & Investment", funding_and_investment, 6)}
          {activeIndices.includes(6) && (
            <div className="space-y-4 mt-2">
              <div className="grid grid-cols-2 gap-4">
                {renderField(
                  "Total Investment (USD)",
                  `$ ${funding_and_investment.crunchbase_total_investment_usd?.toLocaleString()}`
                )}
                {renderField("Last Round Type", funding_and_investment.last_funding_round_type)}
                {renderField(
                  "Last Round Amount (USD)",
                  `$ ${funding_and_investment.last_funding_round_investment_usd?.toLocaleString()}`
                )}
                {renderField("Days Since Last Fundraise", funding_and_investment.days_since_last_fundraise)}
              </div>

              {/* Investors List */}
              {funding_and_investment.crunchbase_investors?.length > 0 && (
                <div className="w-full">
                  <p className="text-[#575757] font-semibold">Investors</p>
                  <div className="bg-[#F2FBFF] p-2 flex flex-col gap-2">
                    {funding_and_investment.crunchbase_investors?.map((investor: string, index: number) => (
                      <div key={index} className="group flex items-center justify-between">
                        <p>{investor}</p>
                        <FaRegCopy
                          className="text-gray-600 cursor-pointer hidden group-hover:block"
                          onClick={(e) => handleCopyText(investor, e)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Funding Timeline */}
              {funding_and_investment.funding_milestones_timeseries?.length > 0 && (
                <div className="w-full">
                  <p className="text-[#575757] font-semibold">Funding Timeline</p>
                  <div className="bg-[#F2FBFF] p-2 flex flex-col gap-4">
                    {funding_and_investment.funding_milestones_timeseries
                      ?.sort(
                        (a: FundingMilestone, b: FundingMilestone) =>
                          new Date(b.date)?.getTime() - new Date(a.date)?.getTime()
                      )
                      ?.map((milestone: FundingMilestone, index: number) => (
                        <div key={index} className="space-y-2 border-b border-gray-200 pb-2 last:border-0">
                          <div className="flex justify-between items-center">
                            <p className="font-medium">{milestone.funding_round}</p>
                            <p className="text-sm text-gray-600">{new Date(milestone.date)?.toLocaleDateString()}</p>
                          </div>
                          <p className="text-sm">Amount: ${milestone.funding_milestone_amount_usd?.toLocaleString()}</p>
                          <div className="text-sm">
                            <p className="text-gray-600">Investors:</p>
                            <p>{milestone.funding_milestone_investors?.split(",")?.filter(Boolean)?.join(", ")}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Job Openings */}
          {renderMetricSection("Job Openings", job_openings, 7)}
          {activeIndices.includes(7) && (
            <div className="space-y-4 mt-2">
              {/* Basic Metrics & Growth in a grid */}
              <div className="grid grid-cols-2 gap-4">
                {/* Current Job Openings */}
                <div className="bg-[#F2FBFF] p-2">
                  <p className="text-sm text-gray-600 font-medium">Current Job Openings</p>
                  <div className="max-h-40 overflow-y-auto">
                    <p className="text-sm font-medium mt-1">
                      {job_openings.job_openings_count?.toLocaleString() || "0"}
                    </p>
                  </div>
                </div>

                {/* Growth Percentages */}
                <div className="bg-[#F2FBFF] p-2">
                  <p className="text-sm text-gray-600 font-medium">Growth Percentages</p>
                  <div className="space-y-2 mt-1">
                    {job_openings.growth_percent?.mom !== null && (
                      <div className="flex justify-between items-center">
                        <span>MoM Growth</span>
                        <span className="font-medium">{job_openings.growth_percent.mom?.toFixed(2)}%</span>
                      </div>
                    )}
                    {job_openings.growth_percent?.qoq !== null && (
                      <div className="flex justify-between items-center">
                        <span>QoQ Growth</span>
                        <span className="font-medium">{job_openings.growth_percent.qoq?.toFixed(2)}%</span>
                      </div>
                    )}
                    {job_openings.growth_percent?.yoy !== null && (
                      <div className="flex justify-between items-center">
                        <span>YoY Growth</span>
                        <span className="font-medium">{job_openings.growth_percent.yoy?.toFixed(2)}%</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Job Openings Timeline */}
              <div className="mt-4">
                <p className="text-[#575757] font-semibold mb-2">Job Openings Timeline</p>
                <div className="bg-[#F2FBFF] p-2">
                  <div className="max-h-60 overflow-y-auto">
                    {job_openings.openings_timeseries
                      ?.sort(
                        (a: JobOpeningTimeseriesEntry, b: JobOpeningTimeseriesEntry) =>
                          new Date(b.date)?.getTime() - new Date(a.date)?.getTime()
                      )
                      ?.map((entry: JobOpeningTimeseriesEntry, index: number) => (
                        <div
                          key={index}
                          className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0"
                        >
                          <span className="text-sm">{formatDate(entry.date)}</span>
                          <span className="text-sm font-medium">
                            {entry.open_jobs?.toLocaleString()} open {entry.open_jobs === 1 ? "position" : "positions"}
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              {/* Recent Job Openings - Updated with scrollable container */}
              {job_openings.recent_openings?.length > 0 && (
                <div className="mt-4">
                  <p className="text-[#575757] font-semibold mb-2">Recent Job Openings</p>
                  <div className="bg-[#F2FBFF] p-2">
                    <div className="max-h-[400px] overflow-y-auto">
                      {job_openings.recent_openings?.map((job: JobOpening, index: number) => (
                        <div key={index} className="border-b border-gray-200 pb-2 last:border-0">
                          <div className="flex justify-between">
                            <a
                              href={job?.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-600 hover:underline"
                            >
                              {job?.title}
                            </a>
                            <span className="text-gray-600">{new Date(job?.date_added)?.toLocaleDateString()}</span>
                          </div>
                          <div className="text-sm text-gray-600 mt-1">
                            <span>{job?.category}</span>
                            {job?.location_text && <span> • {job?.location_text}</span>}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {/* Function Growth */}
              {(Object.keys(job_openings.function_growth.qoq || {}).length > 0 ||
                Object.keys(job_openings.function_growth.six_months || {}).length > 0) && (
                <div className="mt-4">
                  <p className="text-[#575757] font-semibold mb-2">Function Growth</p>
                  <div className="grid grid-cols-2 gap-4">
                    {Object.keys(job_openings.function_growth.qoq || {}).length > 0 && (
                      <div>
                        <p className="font-medium mb-2">QoQ Growth %</p>
                        <div className="bg-[#F2FBFF] p-2">
                          {Object.entries(job_openings.function_growth.qoq || {})
                            ?.filter(([, value]) => value !== null)
                            ?.map(([func, growth], index) => (
                              <div key={index} className="flex justify-between items-center py-1">
                                <span className="capitalize">{func?.replace(/_/g, " ")}</span>
                                <span>{(growth as number)?.toFixed(1)}%</span>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                    {Object.keys(job_openings.function_growth.six_months || {}).length > 0 && (
                      <div>
                        <p className="font-medium mb-2">6 Month Growth %</p>
                        <div className="bg-[#F2FBFF] p-2">
                          {Object.entries(job_openings.function_growth.six_months || {})
                            ?.filter(([, value]) => value !== null)
                            ?.map(([func, growth], index) => (
                              <div key={index} className="flex justify-between items-center py-1">
                                <span className="capitalize">{func?.replace(/_/g, " ")}</span>
                                <span>{(growth as number)?.toFixed(1)}%</span>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Competitors Section */}
          {renderMetricSection("Competitors", competitors, 9)}
          {activeIndices.includes(9) && (
            <div className="space-y-4 mt-2">
              {/* Direct Competitors */}
              {competitors.competitor_website_domains?.length > 0 && (
                <div className="w-full">
                  <p className="text-[#575757] font-semibold">Direct Competitors</p>
                  <div className="bg-[#F2FBFF] p-2 flex flex-col gap-2">
                    {competitors.competitor_website_domains?.map((domain: string, index: number) => (
                      <div key={index} className="group flex items-center justify-between">
                        <p>{domain}</p>
                        <FaRegCopy
                          className="text-gray-600 cursor-pointer hidden group-hover:block"
                          onClick={(e) => handleCopyText(domain, e)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Paid SEO Competitors */}
              {competitors.paid_seo_competitors_website_domains?.length > 0 && (
                <div className="w-full mt-4">
                  <p className="text-[#575757] font-semibold">Paid SEO Competitors</p>
                  <div className="bg-[#F2FBFF] p-2 flex flex-col gap-2">
                    {competitors.paid_seo_competitors_website_domains?.map((domain: string, index: number) => (
                      <div key={index} className="group flex items-center justify-between">
                        <p>{domain}</p>
                        <FaRegCopy
                          className="text-gray-600 cursor-pointer hidden group-hover:block"
                          onClick={(e) => handleCopyText(domain, e)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Organic SEO Competitors */}
              {competitors.organic_seo_competitors_website_domains?.length > 0 && (
                <div className="w-full mt-4">
                  <p className="text-[#575757] font-semibold">Organic SEO Competitors</p>
                  <div className="bg-[#F2FBFF] p-2 flex flex-col gap-2">
                    {competitors.organic_seo_competitors_website_domains?.map((domain: string, index: number) => (
                      <div key={index} className="group flex items-center justify-between">
                        <p>{domain}</p>
                        <FaRegCopy
                          className="text-gray-600 cursor-pointer hidden group-hover:block"
                          onClick={(e) => handleCopyText(domain, e)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Markets Section */}
          {renderMetricSection("Markets", markets, 10)}
          {activeIndices.includes(10) && markets.markets.length > 0 && (
            <div className="space-y-2 mt-2">
              <div className="bg-[#F2FBFF] p-2">
                {markets.markets?.map((market: string, index: number) => (
                  <div key={index} className="group flex items-center justify-between py-1">
                    <span>{market}</span>
                    <FaRegCopy
                      className="text-gray-600 cursor-pointer hidden group-hover:block"
                      onClick={(e) => handleCopyText(market, e)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Stock Symbols Section */}
          {renderMetricSection("Stock Symbols", stockData, 11)}
          {activeIndices.includes(11) && stockData.symbols.length > 0 && (
            <div className="space-y-2 mt-2">
              <div className="bg-[#F2FBFF] p-2">
                {stockData.symbols?.map((symbol: string, index: number) => (
                  <div key={index} className="group flex items-center justify-between py-1">
                    <span>{symbol}</span>
                    <FaRegCopy
                      className="text-gray-600 cursor-pointer hidden group-hover:block"
                      onClick={(e) => handleCopyText(symbol, e)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Taxonomy Section */}
          {renderMetricSection("Taxonomy", taxonomy, 12)}
          {activeIndices.includes(12) && (
            <div className="space-y-4 mt-2">
              {/* LinkedIn Industries */}
              {taxonomy.linkedin_industries.length > 0 && (
                <div>
                  <p className="text-[#575757] font-semibold mb-2">LinkedIn Industries</p>
                  <div className="bg-[#F2FBFF] p-2">
                    {taxonomy.linkedin_industries?.map((industry: string, index: number) => (
                      <div key={index} className="group flex items-center justify-between py-1">
                        <span>{industry}</span>
                        <FaRegCopy
                          className="text-gray-600 cursor-pointer hidden group-hover:block"
                          onClick={(e) => handleCopyText(industry, e)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Crunchbase Categories */}
              {taxonomy.crunchbase_categories.length > 0 && (
                <div>
                  <p className="text-[#575757] font-semibold mb-2">Crunchbase Categories</p>
                  <div className="bg-[#F2FBFF] p-2">
                    {taxonomy.crunchbase_categories?.map((category: string, index: number) => (
                      <div key={index} className="group flex items-center justify-between py-1">
                        <span>{category}</span>
                        <FaRegCopy
                          className="text-gray-600 cursor-pointer hidden group-hover:block"
                          onClick={(e) => handleCopyText(category, e)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* LinkedIn Specialties */}
              {taxonomy.linkedin_specialities.length > 0 && (
                <div>
                  <p className="text-[#575757] font-semibold mb-2">LinkedIn Specialties</p>
                  <div className="bg-[#F2FBFF] p-2">
                    {taxonomy.linkedin_specialities?.map((specialty: string, index: number) => (
                      <div key={index} className="group flex items-center justify-between py-1">
                        <span className="capitalize">{specialty}</span>
                        <FaRegCopy
                          className="text-gray-600 cursor-pointer hidden group-hover:block"
                          onClick={(e) => handleCopyText(specialty, e)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* SEO Section */}
          {renderMetricSection("SEO Metrics", seo, 13)}
          {activeIndices.includes(13) && (
            <div className="space-y-4 mt-2">
              {/* Paid Search Metrics */}
              <div>
                <p className="text-[#575757] font-semibold mb-2">Paid Search</p>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Monthly Ad Budget</p>
                      <p className="break-words">${seo.monthly_google_ads_budget?.toLocaleString()}</p>
                    </div>
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Monthly Paid Clicks</p>
                      <p className="break-words">{seo.monthly_paid_clicks?.toLocaleString()}</p>
                    </div>
                  </div>
                  <div className="space-y-2 self-start">
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Total Ads Purchased</p>
                      <p className="break-words">{seo.total_ads_purchased?.toLocaleString()}</p>
                    </div>
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Average Ad Rank</p>
                      <p className="break-words">{seo.average_ad_rank}</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Organic Search Metrics */}
              <div>
                <p className="text-[#575757] font-semibold mb-2">Organic Search</p>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Monthly Organic Value</p>
                      <p className="break-words">${seo.monthly_organic_value?.toLocaleString()}</p>
                    </div>
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Monthly Organic Clicks</p>
                      <p className="break-words">{seo.monthly_organic_clicks?.toLocaleString()}</p>
                    </div>
                  </div>
                  <div className="space-y-2 self-start">
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Total Organic Results</p>
                      <p className="break-words">{seo.total_organic_results?.toLocaleString()}</p>
                    </div>
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Average Organic Rank</p>
                      <p className="break-words">{seo.average_seo_organic_rank}</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Keyword Changes */}
              <div>
                <p className="text-[#575757] font-semibold mb-2">Keyword Changes</p>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Newly Ranked Keywords</p>
                      <p className="break-words">{seo.newly_ranked_seo_keywords?.toLocaleString()}</p>
                    </div>
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Gained Ranked Keywords</p>
                      <p className="break-words">{seo.gained_ranked_seo_keywords?.toLocaleString()}</p>
                    </div>
                  </div>
                  <div className="space-y-2 self-start">
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Lost Ranked Keywords</p>
                      <p className="break-words">{seo.lost_ranked_seo_keywords?.toLocaleString()}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Web Traffic Section */}
          {renderMetricSection("Web Traffic", web_traffic, 14)}
          {activeIndices.includes(14) && (
            <div className="space-y-4 mt-2">
              {/* Basic Metrics */}
              <div>
                <p className="text-[#575757] font-semibold mb-2">Traffic Overview</p>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Monthly Visitors</p>
                      <p className="break-words">{web_traffic.monthly_visitors?.toLocaleString()}</p>
                    </div>
                  </div>
                  <div className="space-y-2 self-start">
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Growth Percentages</p>
                      <div className="space-y-1">
                        <div>MoM: {web_traffic.monthly_visitor_mom_pct?.toFixed(2)}%</div>
                        <div>QoQ: {web_traffic.monthly_visitor_qoq_pct?.toFixed(2)}%</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Monthly Visitors Timeline */}
              <div>
                <p className="text-[#575757] font-semibold mb-2">Monthly Visitors Timeline</p>
                <div className="bg-[#F2FBFF] p-2">
                  <div className="max-h-60 overflow-y-auto">
                    {web_traffic.monthly_visitors_timeseries
                      ?.sort(
                        (a: TimeseriesEntry, b: TimeseriesEntry) =>
                          new Date(b.date)?.getTime() - new Date(a.date)?.getTime()
                      )
                      ?.map((entry: TimeseriesEntry, index: number) => (
                        <div
                          key={index}
                          className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0"
                        >
                          <span className="text-sm">{formatDate(entry.date)}</span>
                          <span className="text-sm font-medium">
                            {entry.monthly_visitors?.toLocaleString()} visitors
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              {/* Current Traffic Sources */}
              <div>
                <p className="text-[#575757] font-semibold mb-2">Current Traffic Sources</p>
                <div className="bg-[#F2FBFF] p-2">
                  {[
                    { name: "Direct", value: web_traffic.traffic_sources?.direct },
                    { name: "Search", value: web_traffic.traffic_sources?.search },
                    { name: "Referral", value: web_traffic.traffic_sources?.referral },
                    { name: "Paid Referral", value: web_traffic.traffic_sources?.paid_referral },
                    { name: "Social", value: web_traffic.traffic_sources?.social },
                  ]
                    ?.sort((a: TrafficSource, b: TrafficSource) => (b.value || 0) - (a.value || 0))
                    ?.map((source: TrafficSource, index: number) => (
                      <div
                        key={index}
                        className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0"
                      >
                        <span className="text-sm">{source?.name}</span>
                        <span className="text-sm">{source?.value?.toFixed(2)}%</span>
                      </div>
                    ))}
                </div>
              </div>

              {/* Traffic Sources Timeline */}
              <div>
                <p className="text-[#575757] font-semibold mb-2">Traffic Sources Timeline</p>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  {/* Direct Traffic */}
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600 font-medium mb-2">Direct Traffic</p>
                    <div className="max-h-40 overflow-y-auto">
                      {web_traffic.traffic_sources_timeseries.direct
                        ?.sort(
                          (a: TrafficSourceEntry, b: TrafficSourceEntry) =>
                            new Date(b.date)?.getTime() - new Date(a.date)?.getTime()
                        )
                        ?.map((entry: TrafficSourceEntry, index: number) => (
                          <div
                            key={index}
                            className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0"
                          >
                            <span className="text-sm">{formatDate(entry.date)}</span>
                            <span className="text-sm">{entry.traffic_source_direct_pct?.toFixed(2)}%</span>
                          </div>
                        ))}
                    </div>
                  </div>

                  {/* Search Traffic */}
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600 font-medium mb-2">Search Traffic</p>
                    <div className="max-h-40 overflow-y-auto">
                      {web_traffic.traffic_sources_timeseries.search
                        ?.sort(
                          (a: TrafficSourceEntry, b: TrafficSourceEntry) =>
                            new Date(b.date)?.getTime() - new Date(a.date)?.getTime()
                        )
                        ?.map((entry: TrafficSourceEntry, index: number) => (
                          <div
                            key={index}
                            className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0"
                          >
                            <span className="text-sm">{formatDate(entry.date)}</span>
                            <span className="text-sm">{entry.traffic_source_search_pct?.toFixed(2)}%</span>
                          </div>
                        ))}
                    </div>
                  </div>

                  {/* Referral Traffic */}
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600 font-medium mb-2">Referral Traffic</p>
                    <div className="max-h-40 overflow-y-auto">
                      {web_traffic.traffic_sources_timeseries.referral
                        ?.sort(
                          (a: TrafficSourceEntry, b: TrafficSourceEntry) =>
                            new Date(b.date)?.getTime() - new Date(a.date)?.getTime()
                        )
                        ?.map((entry: TrafficSourceEntry, index: number) => (
                          <div
                            key={index}
                            className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0"
                          >
                            <span className="text-sm">{formatDate(entry.date)}</span>
                            <span className="text-sm">{entry.traffic_source_referral_pct?.toFixed(2)}%</span>
                          </div>
                        ))}
                    </div>
                  </div>

                  {/* Social Traffic */}
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600 font-medium mb-2">Social Traffic</p>
                    <div className="max-h-40 overflow-y-auto">
                      {web_traffic.traffic_sources_timeseries.social
                        ?.sort(
                          (a: TrafficSourceEntry, b: TrafficSourceEntry) =>
                            new Date(b.date)?.getTime() - new Date(a.date)?.getTime()
                        )
                        ?.map((entry: TrafficSourceEntry, index: number) => (
                          <div
                            key={index}
                            className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0"
                          >
                            <span className="text-sm">{formatDate(entry.date)}</span>
                            <span className="text-sm">{entry.traffic_source_social_pct?.toFixed(2)}%</span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* News Articles Section */}
          {renderMetricSection("News Articles", companyData["News Articles"], 15)}
          {activeIndices.includes(15) && companyData["News Articles"]?.length > 0 && (
            <div className="space-y-4 mt-2">
              <div className="bg-[#F2FBFF] p-2">
                <div className="h-[400px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
                  {companyData["News Articles"]
                    ?.sort(
                      (a: NewsArticle, b: NewsArticle) =>
                        new Date(b.article_publish_date)?.getTime() - new Date(a.article_publish_date)?.getTime()
                    )
                    ?.map((article: NewsArticle, index: number) => (
                      <div key={index} className="py-3 border-b border-gray-200 last:border-0 hover:bg-gray-50">
                        <div className="flex justify-between items-start mb-2">
                          <a
                            href={article?.article_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:underline font-medium flex-1 mr-4"
                          >
                            {article?.article_title}
                          </a>
                          <FaRegCopy
                            className="text-gray-600 cursor-pointer hover:text-gray-800 mt-1"
                            onClick={(e) => handleCopyText(article?.article_url, e)}
                          />
                        </div>
                        <div className="flex justify-between items-center text-sm text-gray-600">
                          <span>{article?.article_publisher_name}</span>
                          <span>{formatDate(article?.article_publish_date)}</span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}

          {/* Decision Makers Section */}
          {basicInfo.decision_makers &&
            basicInfo.decision_makers !== "No data found" &&
            Array.isArray(basicInfo.decision_makers) &&
            basicInfo.decision_makers.length > 0 && (
              <div className="space-y-4 mb-6">
                <p className="text-[#575757] font-semibold mb-2">Decision Makers</p>
                <div className="bg-[#F2FBFF] p-2">
                  <div className="max-h-[400px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
                    {basicInfo.decision_makers?.map((person: string, index: number) => (
                      <div key={index} className="py-2 border-b border-gray-200 last:border-0 hover:bg-gray-50 group">
                        <div className="flex justify-between items-center">
                          <span>{person}</span>
                          <FaRegCopy
                            className="text-gray-600 cursor-pointer hidden group-hover:block"
                            onClick={(e) => handleCopyText(person, e)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
        </div>
      </App>
      <div className="mt-4 flex items-center">
        <h1 className="font-semibold text-md">Total cost:</h1>
        <div className="flex items-center ml-2 bg-purple-100 rounded p-1">
          <span className="font-bold text-md">{credits}</span>
          <FaCoins className="ml-1 text-purple-500 w-5" />
        </div>
      </div>
    </>
  );
};

export const DownArrowNew = ({ color }: { color?: string }) => (
  <svg width="14" height="9" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0.296875V5.16511L9 12.999L18 5.16511V0.296875L9 8.11216L0 0.296875Z" fill={color} />
  </svg>
);

export const UpArrowNew = ({ color }: { color?: string }) => (
  <svg width="14" height="9" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.999 12.6953V7.82708L8.99902 -0.00685804L-0.000980854 7.82708V12.6953L8.99902 4.88003L17.999 12.6953Z"
      fill={color}
    />
  </svg>
);

export default CrustDataCompanyViewer;

import { useUser } from "@/contexts/UserContext";
import { motion } from "framer-motion";
import { useState } from "react";
import SidebarToggleIcon from "/assets/SidebarToggleIcon.svg";

type CollapsibleIconProps = {
  isHeader?: boolean;
  variant?: "rounded-border";
};

export const CollapsibleIcon = ({ isHeader, variant }: CollapsibleIconProps) => {
  const { setCollapsed, collapsed } = useUser();
  const [_isHover, setIsHover] = useState(false);

  if (!collapsed && isHeader) return null;

  return (
    <div
      className={`${variant === "rounded-border" ? "h-[30px] w-[30px] flex items-center justify-center rounded-full shadow-[0px_0px_0px_2px_rgba(200,200,200,0.46)]" : "flex items-center justify-center"}`}
    >
      <motion.button
        className="cursor-pointer"
        onClick={() => setCollapsed(!collapsed)}
        onHoverStart={() => setIsHover(true)}
        onHoverEnd={() => setIsHover(false)}
        title={collapsed ? "Expand Sidebar" : "Collapse Sidebar"}
      >
        <img src={SidebarToggleIcon} />
      </motion.button>
    </div>
  );
};

import { setStateType } from "@/utils/constants";
import { message, Popconfirm, Table } from "antd";
import { useMemo, useState } from "react";
import SignalCard from "../Components/SignalCard";
import { Link, useNavigate } from "react-router-dom";
import FloqerLogo from "@Assets/SVGs/floqerLogo.svg";
import moment from "moment";
import { GoListUnordered } from "react-icons/go";
import SearchInput from "../Components/SearchInput";
import DeleteIconNew from "@Assets/SVGs/DeleteIconNew.svg";
import { deleteWatcher } from "../apis";
import { CustomTooltip } from "../Components/CustomTooltip";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";

type Props = {
  isSidebarCollapsed: boolean;
  availableSignals: any[];
  userSignals: any[];
  setStaticSignals: setStateType<any[]>;
};

const Lists = ({ isSidebarCollapsed, availableSignals, userSignals, setStaticSignals }: Props) => {
  const [visibleCount, setVisibleCount] = useState(4);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchTermList, setSearchTermList] = useState<string>("");
  const [isDeleting, setIsDeleting] = useState<Record<string, boolean>>({});

  const navigate = useNavigate();

  const handleLoadMore = () => {
    if (visibleCount >= sortedSignals.length) {
      // Reset to initial count if all signals are visible
      setVisibleCount(4);
    } else {
      // Load 4 more signals
      setVisibleCount((prevCount) => prevCount + 4);
    }
  };

  const sortedSignals = useMemo(() => {
    return [...availableSignals].sort((a, b) => a.id.localeCompare(b.id));
  }, [availableSignals]);

  const filteredSignals = sortedSignals.filter((signal) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return (
      signal.name.toLowerCase().includes(lowerCaseSearchTerm) ||
      signal.description.toLowerCase().includes(lowerCaseSearchTerm)
    );
  });

  const filteredSignalsList = userSignals.filter((signal) => {
    const lowerCaseSearchTerm = searchTermList.toLowerCase();

    // Fields to search
    const fieldsToSearch = [signal.name || "", signal.id || "", signal.lead_count?.toString() || ""];

    // Check if any field matches the search term
    return fieldsToSearch.some((field) => field.toLowerCase().includes(lowerCaseSearchTerm));
  });

  const handleDeleteSignal = async (id: string): Promise<void> => {
    setIsDeleting((prev) => ({ ...prev, [id]: true }));
    try {
      const response = await deleteWatcher(id);

      if (response.message) {
        setStaticSignals((prevSignals) => prevSignals.filter((signal) => signal.id !== id));
        message.success("Signal deleted successfully!");
      } else {
        message.error("Failed to delete signal. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting signal:", error);
      message.error("An error occurred while deleting the signal.");
    } finally {
      setIsDeleting((prev) => ({ ...prev, [id]: false }));
    }
  };

  const getActionStyles = (actionName: string) => {
    switch (actionName) {
      case "COMPANIES FOUND":
        return "text-[#7B0094] bg-[#FBE7FF]";
      case "SCRAPED LEADS":
        return "text-[#1A007B] bg-[#F2EEFF]";
      case "PEOPLE FOUND":
        return "text-[#0050B5] bg-[#E6F0FF]";
      case "OPERATORS FOUND":
        return "text-[#B50000] bg-[#FFE6E6]";
      default:
        return "text-[#000000] bg-[#F2F2F2]";
    }
  };

  const tableDataSource = useMemo(() => {
    return (searchTermList ? filteredSignalsList : userSignals).map((signal) => {
      const { signal_id, created_at, name, id, lead_count } = signal;
      const matchingSignal = availableSignals.find((availableSignal) => availableSignal.id === signal_id) || {};
      const { logo = "", returnType = {}, action = {} } = matchingSignal;

      const actionTagStyles = getActionStyles(action?.name);
      return {
        key: id,
        name: (
          <Link to={`/list/${id}`} className="text-base tracking-[0.02em] flex items-center gap-3 pl-4">
            <img src={logo || FloqerLogo} alt={name} className="w-10 h-10" />
            <h3 className="font-normal truncate">{name}</h3>
          </Link>
        ),
        type: (
          <div className="flex items-stretch gap-2">
            <CustomTooltip
              content={
                <div className="text-[#6b6b6b] text-center text-xs font-extralight tracking-wider">
                  {returnType?.description}
                </div>
              }
            >
              <span className="text-black flex items-center gap-2 font-medium rounded-sm text-[12px] px-2 bg-[#F2F2F2] min-h-[24px]">
                {returnType?.logo && <img src={returnType?.logo} alt="icon" className="w-4 h-4" />}
                {returnType?.name}
              </span>
            </CustomTooltip>
            <CustomTooltip
              content={
                <div className="text-[#6b6b6b] text-xs text-center font-extralight tracking-wider">
                  {action?.description}
                </div>
              }
            >
              <span className={`px-2 rounded-sm min-h-[24px] flex items-center ${actionTagStyles}`}>
                {action?.name}
              </span>
            </CustomTooltip>
          </div>
        ),
        lead_count: lead_count,
        created_at: <div className="font-light">{moment(created_at).utc().local().format("DD MMM, YYYY")}</div>,
        actions: (
          <>
            {isDeleting[id] ? (
              <span className="h-6 w-6 flex justify-center items-center">
                <SpinnerStatus />
              </span>
            ) : (
              <Popconfirm
                title="Are you sure you want to delete?"
                icon={""}
                placement="topLeft"
                onConfirm={() => handleDeleteSignal(id)}
                okText="Delete"
                cancelText="Cancel"
                okButtonProps={{ danger: true }}
              >
                <button title="Delete Signal">
                  <img src={DeleteIconNew} alt="delete" className="h-6 w-6" />
                </button>
              </Popconfirm>
            )}
          </>
        ),
      };
    });
  }, [searchTermList, filteredSignalsList, userSignals, availableSignals]);

  const tableColumns = [
    {
      title: <div className="pl-4">Signal</div>,
      dataIndex: "name",
      key: "name",
      width: "350px",
      ellipsis: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "350px",
    },
    {
      title: "Leads Generated",
      dataIndex: "lead_count",
      key: "lead_count",
      width: "150px",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      width: "150px",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "110px",
      fixed: "right" as const,
    },
  ];

  const tableHeaderStyle = {
    borderBlock: "0.5px solid #333333",
    paddingBlock: "8px",
    background: "white",
  };

  return (
    <div className="w-full h-full flex flex-col gap-8">
      <div className="flex flex-col gap-4 px-8">
        <div className="flex items-center justify-between h-[40px]">
          <h2 className="flex items-center gap-2 text-2xl font-medium tracking-tighter text-[#333333]">
            <GoListUnordered className="text-2xl text-[#565656]" />
            Lists builder
          </h2>
          <SearchInput placeholder="Search for list builder" onSearch={setSearchTerm} />
        </div>
        <div className="grid grid-cols-4 gap-6">
          {(searchTerm ? filteredSignals : sortedSignals).slice(0, visibleCount).map((signal) => (
            <SignalCard
              key={signal.id}
              id={signal.id}
              name={signal.name}
              logo={signal.logo}
              description={signal.description}
              onClick={() => navigate(`/list-building?create=${signal.id}`)}
              isSidebarCollapsed={isSidebarCollapsed}
            />
          ))}
        </div>

        {(searchTerm ? filteredSignals : sortedSignals).length > 0 ? (
          (searchTerm ? filteredSignals : sortedSignals).length >= 5 && (
            <div>
              <button
                onClick={handleLoadMore}
                className="text-[#333333] text-sm decoration-[1.5px] underline underline-offset-2 leading-relaxed font-medium"
              >
                {visibleCount >= (searchTerm ? filteredSignals : sortedSignals).length ? "Show less" : "Load More"}
              </button>
            </div>
          )
        ) : (
          <div className="text-center py-4">
            <p className="text-gray-500 text-sm">No results found.</p>
            <p className="text-gray-400 text-xs mt-1">Please try a different search term.</p>
          </div>
        )}
      </div>
      <hr />
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between px-8 h-[40px] bg-white sticky top-[49px] z-30">
          <h2 className="text-2xl font-medium text-[#3F3F3F]">All lists</h2>
          <SearchInput placeholder="Search for list" onSearch={setSearchTermList} />
        </div>
        <Table
          dataSource={tableDataSource}
          columns={tableColumns.map((column) => ({
            ...column,
            title: <div className="font-favorit text-[#828282] font-normal text-sm">{column.title}</div>,
            className: "font-favorit",
          }))}
          pagination={false}
          className="font-favorit"
          scroll={{ y: 780 }}
          components={{
            header: {
              cell: (props: any) => (
                <th
                  {...props}
                  style={{
                    ...tableHeaderStyle,
                    ...props.style,
                  }}
                />
              ),
            },
          }}
        />
      </div>
    </div>
  );
};

export default Lists;

import { ConfigProvider, GetProp, Select, Table, TableProps } from "antd";
import { useState } from "react";

type Props = {
  isExcel: boolean;
  sheets: string[];
  selectedSheet: string;
  onSheetSelect?: (sheet: string) => void;
  setHeaders: any;
  rows: any[];
  columns: any[];
};

type TablePaginationConfig = Exclude<GetProp<TableProps, "pagination">, boolean>;

interface TableParams {
  pagination?: TablePaginationConfig;
}

const SelectHeader = ({ isExcel, selectedSheet, sheets, onSheetSelect, rows, columns, setHeaders }: Props) => {
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      pageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ["10", "20", "50", "100"],
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      total: 0,
    },
  });

  const handleTableChange = (page: number, pageSize: number) => {
    setTableParams((prev) => {
      return {
        ...prev,
        pagination: {
          ...prev.pagination,
          current: page,
          pageSize: pageSize,
        },
      };
    });
  };

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (_selectedRowKey: React.Key[], selectedRow: any[]) => {
      console.log("selectedRow: ", selectedRow);
      setHeaders(selectedRow[0]);
    },
  };

  return (
    <div className="w-full h-full flex flex-col max-h-[65vh]">
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "ABC Favorit",
          },
          components: {
            Table: {
              rowSelectedBg: "#F2F2F6",
              rowHoverBg: "#F3F4F6",
            },
          },
        }}
      >
        {isExcel && sheets.length > 1 && (
          <div className="flex items-center gap-2 px-6 py-4">
            <span className="font-medium text-md">Select Sheet:</span>
            <Select
              value={selectedSheet}
              onChange={(value) => onSheetSelect?.(value)}
              style={{ width: 150 }}
              options={sheets.map((sheet) => ({ value: sheet, label: sheet }))}
            />
          </div>
        )}
        <div className="relative w-full h-[55vh] overflow-auto py-2 ">
          <Table
            rowClassName={(_record, index) => (index % 2 === 0 ? "bg-gray-50" : "bg-white")}
            rowSelection={{
              type: "radio",
              ...rowSelection,
              defaultSelectedRowKeys: [1],
            }}
            columns={columns}
            dataSource={rows}
            showHeader={false}
            bordered
            pagination={{
              ...tableParams.pagination,
              onChange: handleTableChange,
              className: "px-8 pt-4 sticky bottom-0 bg-white !m-0",
            }}
          />
        </div>
      </ConfigProvider>
    </div>
  );
};

export default SelectHeader;

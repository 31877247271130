import { App, message } from "antd";
import { useEffect, useState } from "react";
import { FaRegCopy } from "react-icons/fa";

type Props = {
  open: boolean;
  data: any;
};

const AcquisitionsViewer = ({ open, data }: Props) => {
  const [newData, setNewData] = useState<any>(data);
  const [activeIndices, setActiveIndices] = useState<number[]>([]);

  useEffect(() => {
    if (!open) setNewData({});
    else setNewData(data);
  }, [open, data]);

  const toggleAccordion = (index: number) => {
    setActiveIndices((prevIndices) =>
      prevIndices.includes(index) ? prevIndices.filter((i) => i !== index) : [...prevIndices, index]
    );
  };
  const handleCopyJSON = async (content: any, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      const jsonString = JSON.stringify(content, null, 2); // Convert JSON to string with indentation
      await navigator.clipboard.writeText(jsonString);
      message.success("Content copied to clipboard!");
      // Check the data that got copied
      await navigator.clipboard.readText();
    } catch (err) {
      message.error("Failed to copy content.");
      console.error("Copy error:", err);
    }
  };
  const handleCopyText = async (content: any, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(content);
      message.success("Content copied to clipboard!");
      // Check the data that got copied
      await navigator.clipboard.readText();
    } catch (err) {
      message.error("Failed to copy content.");
      console.error("Copy error:", err);
    }
  };

  const fields = [
    { label: "Acquiree Name", index: 0 },
    { label: "Transaction Name", index: 1 },
    { label: "Announced Date", index: 2 },
    { label: "Price", index: 3 },
  ];

  return (
    <App>
      <div className="flex flex-col gap-2 !text-[14px]">
        <div className="text-[20px] font-bold">10 Recent Mergers and Acquisitions</div>
        <hr />
        <div className="space-y-2">
          {newData &&
            newData["10 Recent acquisitions"] &&
            Object?.values(newData["10 Recent acquisitions"])?.map((well: any, idx: number) => (
              <div key={idx}>
                <div
                  className={`${
                    activeIndices.includes(1001 + idx + 1) ? "" : " bg-[#FAFAFA] border border-gray-200 p-2 rounded-sm"
                  } cursor-pointer mt-2 mb-2`}
                >
                  <div className="flex items-center justify-between" onClick={() => toggleAccordion(1001 + idx + 1)}>
                    <p className="text-[16px] font-bold flex items-center gap-x-2">
                      Acquisition {idx + 1 < 10 ? "0" + (idx + 1) : idx + 1}
                      {activeIndices.includes(1001 + idx + 1) ? (
                        <UpArrowNew color="black" />
                      ) : (
                        <DownArrowNew color="black" />
                      )}
                    </p>
                    <p className="cursor-pointer flex items-center gap-x-2" onClick={(e) => handleCopyJSON(well, e)}>
                      Copy All <FaRegCopy className="text-gray-600" />
                    </p>
                  </div>

                  {activeIndices.includes(1001 + idx + 1) && (
                    <div className="space-y-2 mt-2 border p-2 rounded-sm">
                      {fields.map((field, fieldIdx) => (
                        <div key={fieldIdx} className="flex flex-col w-full group relative">
                          <span className="w-full text-[#575757]">{field.label}</span>{" "}
                          <div className="w-full bg-[#dce7dc] px-2 py-1 break-words flex justify-between items-center">
                            <p className="w-[95%]">
                              {!well[field.index]?.value || well[field.index]?.value === "No data found"
                                ? "\u00A0"
                                : well[field.index]?.value}
                            </p>
                            <p
                              className="cursor-pointer hidden group-hover:block"
                              onClick={(e) => handleCopyText(well[field.index]?.value, e)}
                            >
                              <FaRegCopy className="text-gray-600" />
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
        <div className="text-[20px] font-bold">Total Mergers and Acquisitions</div>
        <hr />
        {newData && newData["Number of Acquisitions"] && (
          <div className={"bg-[#FAFAFA] border border-gray-200 p-2 rounded-sm cursor-pointer mt-2 mb-2"}>
            <div className="flex items-center justify-between">
              <p className="text-[16px] flex items-center gap-x-2">{newData["Number of Acquisitions"]}</p>
              <p
                className="cursor-pointer flex items-center gap-x-2"
                onClick={(e) => handleCopyJSON(newData["Number of Acquisitions"], e)}
              >
                Copy All <FaRegCopy className="text-gray-600" />
              </p>
            </div>
          </div>
        )}
      </div>
    </App>
  );
};

export const DownArrowNew = ({ color }: { color?: string }) => (
  <svg width="14" height="9" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0.296875V5.16511L9 12.999L18 5.16511V0.296875L9 8.11216L0 0.296875Z" fill={color} />
  </svg>
);
export const UpArrowNew = ({ color }: { color?: string }) => (
  <svg width="14" height="9" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.999 12.6953V7.82708L8.99902 -0.00685804L-0.000980854 7.82708V12.6953L8.99902 4.88003L17.999 12.6953Z"
      fill={color}
    />
  </svg>
);

export default AcquisitionsViewer;

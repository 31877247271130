import React, { useCallback, useEffect, useState } from "react";
import { Select, Skeleton, ConfigProvider, Drawer } from "antd";
import { useWorkflow } from "@/contexts/WorkflowContext";
import { FaRegEdit } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useRunOnFloq } from "@/contexts/RunOnFloqContext";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

interface AirtableImportModalProps {
  action: any;
  modal: boolean;
  setModal: (value: boolean) => void;
  variables: any[];
  payloadConfiguration: any[];
  responseConfiguration: any[];
}

interface TableData {
  loading: boolean;
  tables: Array<{
    id: string;
    name: string;
    fields: Array<{
      name: string;
      type: string;
    }>;
  }>;
}

interface ColumnData {
  loading: boolean;
  columns: Array<{
    name: string;
    type: string;
  }>;
}

const AirtableImportModal: React.FC<AirtableImportModalProps> = ({ action, modal, setModal, payloadConfiguration }) => {
  const [bases, setBases] = useState<{ loading: boolean; bases: any[] }>({
    loading: false,
    bases: [],
  });
  const [tables, setTables] = useState<TableData>({
    loading: false,
    tables: [],
  });
  const [columns, setColumns] = useState<ColumnData>({
    loading: false,
    columns: [],
  });
  const [loading, setLoading] = useState(false);
  const [changeName, setChangeName] = useState(false);
  const [payloads, setPayloads] = useState<any[]>(payloadConfiguration);
  const [existingWebhook, setExistingWebhook] = useState<any>(null);
  const [checkingWebhook, setCheckingWebhook] = useState(true);
  const [webhookError, setWebhookError] = useState<string | null>(null);
  const { collapseConfig, setCollapseConfig } = useRunOnFloq();
  const [drawerWidth, setDrawerWidth] = useState(collapseConfig ? "3rem" : "30%");

  const { id: workflowId } = useParams();
  const {
    getAirtableData,
    updatePayloadConfig,
    saveWorkflowActions,
    updateActionName,
    setPublishWarning,
    createAirtableWebhook,
    updateResponseConfig,
  } = useWorkflow();

  useEffect(() => {
    setBases({ loading: true, bases: [] });
    getAirtableData("airtable_bases").then((data: any) => {
      setBases({ loading: false, bases: data.bases });
    });
  }, [getAirtableData]);

  useEffect(() => {
    setWidthPercent();
    window.addEventListener("resize", setWidthPercent);
    return () => window.removeEventListener("resize", setWidthPercent);
  }, [collapseConfig]);

  const setWidthPercent = useCallback(() => {
    try {
      if (collapseConfig) setDrawerWidth("3rem");
      // Adjust width for different screen sizes
      else if (window.matchMedia("(min-width: 1024px) and (min-height: 1366px)").matches)
        // iPad Pro and similar devices
        setDrawerWidth("50%");
      else if (window.matchMedia("(min-width: 1024px)").matches) setDrawerWidth("30%");
      else if (window.matchMedia("(min-width: 640px)").matches) setDrawerWidth("50%");
      else setDrawerWidth("100%");
    } catch (err) {
      console.error("Error calculating width percent based on media size");
    }
  }, [collapseConfig]);

  useEffect(() => {
    const baseId = payloads.find((p) => p.payloadStructureId === "baseId")?.inputString;
    if (baseId) {
      setTables({
        loading: true,
        tables: [],
      });
      getAirtableData("airtable_tables", baseId).then((response: any) => {
        setTables({
          loading: false,
          tables: response.tables,
        });
      });
    }
  }, [payloads.find((p) => p.payloadStructureId === "baseId")?.inputString, getAirtableData]);

  useEffect(() => {
    const tableId = payloads.find((p) => p.payloadStructureId === "tableId")?.inputString;
    if (tableId) {
      const selectedTable = tables.tables.find((table) => table.id === tableId);
      if (selectedTable) {
        setColumns({
          loading: false,
          columns: selectedTable.fields,
        });
      }
    }
  }, [payloads.find((p) => p.payloadStructureId === "tableId")?.inputString, tables.tables]);

  useEffect(() => {
    const checkExistingWebhook = async () => {
      setCheckingWebhook(true);
      try {
        const baseId = payloads.find((p) => p.payloadStructureId === "baseId")?.inputString;
        if (!baseId) {
          setExistingWebhook(null);
          return;
        }

        const response = await getAirtableData("webhook_details", baseId);
        if (response) {
          setExistingWebhook(response);
        } else {
          setExistingWebhook(null);
        }
      } catch (error) {
        console.error("Error checking webhook:", error);
        setExistingWebhook(null);
      } finally {
        setCheckingWebhook(false);
      }
    };

    checkExistingWebhook();
  }, [payloads.find((p) => p.payloadStructureId === "baseId")?.inputString, getAirtableData]);

  const saveOutputStructure = async () => {
    try {
      setLoading(true);
      setWebhookError(null);
      const baseId = payloads.find((p) => p.payloadStructureId === "baseId")?.inputString;
      const tableId = payloads.find((p) => p.payloadStructureId === "tableId")?.inputString;
      const selectedColumns = payloads.find((p) => p.payloadStructureId === "columnsId")?.inputString;

      if (!baseId || !tableId || !selectedColumns) {
        setWebhookError("Please select base, table and columns");
        return;
      }

      if (!workflowId) {
        throw new Error("Workflow ID not found");
      }

      // Create or update webhook
      await createAirtableWebhook(baseId, tableId, workflowId, action.id, selectedColumns);

      // Create response configuration for selected columns
      const webhookResponses = selectedColumns.map((columnName: string) => ({
        responseId: uuidv4(),
        name: columnName,
        description: `Airtable ${columnName} field`,
        type: "string",
        required: false,
        webhookArray: true,
      }));

      await updateResponseConfig(webhookResponses, action.id);
      await updatePayloadConfig(payloads, action.id, false);
      await saveWorkflowActions(workflowId);

      setPublishWarning(true);
      setModal(false);
    } catch (error: any) {
      console.error("Failed to save webhook configuration:", error.message);
      setWebhookError(error.message || "Failed to create webhook configuration");
    } finally {
      setLoading(false);
    }
  };

  const getOptionsForField = (field: any) => {
    switch (field.payloadStructureId) {
      case "baseId":
        return bases.bases?.map((base: any) => ({
          label: base.name,
          value: base.id,
        }));
      case "tableId":
        return tables.tables?.map((table: any) => ({
          label: table.name,
          value: table.id,
        }));
      case "columnsId":
        return columns.columns?.map((column: any) => ({
          label: column.name,
          value: column.id,
        }));
      default:
        return [];
    }
  };

  const getLoadingState = (field: any) => {
    switch (field.payloadStructureId) {
      case "baseId":
        return bases.loading;
      case "tableId":
        return tables.loading;
      case "columnsId":
        return columns.loading;
      default:
        return false;
    }
  };

  const renderField = (field: any) => {
    if (field.valuesId === "columnsId") {
      return (
        <div className="flex flex-col w-full gap-4">
          <div>
            <p className="tracking-primary font-semibold">
              {field.name.toUpperCase()}
              {!field.required && (
                <span className="text-sm font-normal tracking-normal text-gray-400"> (Optional)</span>
              )}
            </p>
            <p className="text-sm font-light">{field.description}</p>
          </div>
          <Skeleton loading={getLoadingState(field)} paragraph={false} active={getLoadingState(field)}>
            <Select
              mode="multiple"
              showSearch
              optionFilterProp="label"
              value={payloads.find((p) => p.payloadStructureId === field.payloadStructureId)?.inputString || []}
              onChange={(values) => {
                const temp = [...payloads];
                const index = temp.findIndex((p) => p.payloadStructureId === field.payloadStructureId);
                if (index === -1) {
                  temp.push({
                    payloadStructureId: field.payloadStructureId,
                    inputString: values,
                  });
                } else {
                  temp[index].inputString = values;
                }
                setPayloads(temp);
              }}
              placeholder={`Select ${field.name}`}
              className="w-full"
              options={columns.columns?.map((column: any) => ({
                label: column.name,
                value: column.name,
              }))}
              disabled={!payloads.find((p) => p.payloadStructureId === "tableId")?.inputString}
            />
          </Skeleton>
        </div>
      );
    }

    return (
      <div className="flex flex-col w-full gap-4">
        <div>
          <p className="tracking-primary font-semibold">
            {field.name.toUpperCase()}
            {!field.required && <span className="text-sm font-normal tracking-normal text-gray-400"> (Optional)</span>}
          </p>
          <p className="text-sm font-light">{field.description}</p>
        </div>
        <Skeleton loading={getLoadingState(field)} paragraph={false} active={getLoadingState(field)}>
          <Select
            showSearch
            optionFilterProp="label"
            value={payloads.find((p) => p.payloadStructureId === field.payloadStructureId)?.inputString}
            onChange={(value) => {
              const temp = [...payloads];
              const index = temp.findIndex((p) => p.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: value,
                });
              } else {
                temp[index].inputString = value;
              }
              setPayloads(temp);
            }}
            placeholder={`Select ${field.name}`}
            className="w-full"
            options={getOptionsForField(field)}
            disabled={
              field.payloadStructureId === "tableId" &&
              !payloads.find((p) => p.payloadStructureId === "baseId")?.inputString
            }
          />
        </Skeleton>
      </div>
    );
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={modal}
        mask={false}
        onClose={() => setModal(false)}
        width={drawerWidth}
        styles={{
          content: {
            transition: "width 0.3s",
          },
          body: {
            padding: collapseConfig ? "0" : "24px",
          },
        }}
        classNames={{ header: !collapseConfig ? "border-b !border-gray-300" : "!border-none" }}
        className="ml-auto !font-favorit !p-0 border-l border-gray-500"
        getContainer={false}
        footer={
          !collapseConfig && (
            <div className="w-full flex flex-col gap-4 bg-white">
              {webhookError && (
                <div className="text-sm text-red-600 border border-red-200 rounded-lg p-3 bg-red-50">
                  <p className="font-semibold">Error:</p>
                  <p>{webhookError}</p>
                </div>
              )}
              {existingWebhook && (
                <div className="text-sm text-gray-600 border rounded-lg p-3 bg-gray-50">
                  <p className="font-semibold mb-1">Webhook Configuration:</p>
                  <p className="break-all">URL: {existingWebhook.notificationUrl}</p>
                  <p className="mt-2 text-xs text-gray-500">
                    Created on: {new Date(existingWebhook.createdAt).toLocaleString()}
                  </p>
                  <p className="mt-2 font-semibold">Associated Workflows:</p>
                  <ul className="list-disc pl-5 mt-1">
                    {Object.keys(existingWebhook?.workflows || {}).map((wfId) => (
                      <li key={wfId} className="text-xs">
                        {wfId === workflowId ? (
                          "Current workflow"
                        ) : (
                          <a
                            href={`https://app.floqer.com/workflow/${wfId}?action=build&v2=true`}
                            target="_blank"
                            rel="noreferrer"
                            className="hover:text-primary"
                          >
                            Workflow <span> {wfId}</span>
                          </a>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <button
                className={`text-white w-full font-semibold rounded-lg p-2 mt-auto 
                ${loading || checkingWebhook ? "cursor-not-allowed bg-gray-500" : "bg-primary cursor-pointer"}`}
                onClick={saveOutputStructure}
                disabled={loading || checkingWebhook}
              >
                {loading
                  ? "Saving Configuration..."
                  : checkingWebhook
                    ? "Checking Webhook..."
                    : existingWebhook
                      ? "Update Webhook Configuration"
                      : "Create Webhook Configuration"}
              </button>
            </div>
          )
        }
        title={
          !collapseConfig && (
            <div className="flex items-center gap-2">
              <button className="p-0 border border-gray-300 rounded-sm" onClick={() => setCollapseConfig(true)}>
                <MdChevronRight size={25} />
              </button>
              <img src={action.logo} alt={action.name} className="w-6 h-6" />
              <span
                contentEditable={changeName}
                suppressContentEditableWarning
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.target.blur();
                  }
                }}
                onBlur={async (e) => {
                  if (!e.target.textContent) return;
                  setLoading(true);
                  updateActionName(action.id, e.target.textContent || action.name);
                  setChangeName(false);
                  await saveWorkflowActions(action.id);
                  setLoading(false);
                  setPublishWarning(true);
                }}
              >
                {action.name}
              </span>
              <button
                onClick={() => {
                  setChangeName(!changeName);
                }}
                className={`p-2 rounded-md transition-opacity duration-600 ease-in-out hover:bg-gray-200 ${
                  changeName ? "opacity-50" : "opacity-100"
                }`}
              >
                <FaRegEdit />
              </button>
            </div>
          )
        }
      >
        {collapseConfig ? (
          <button
            className="w-full h-full flex flex-col items-center justify-center p-3 gap-3"
            onClick={() => setCollapseConfig(false)}
          >
            <span className="p-0 border border-gray-300 rounded-sm">
              <MdChevronLeft size={25} />
            </span>
            <p className="[writing-mode:sideways-lr] text-center">CONFIG</p>
          </button>
        ) : (
          <div className="bg-white gap-10 flex flex-col">
            {action.payloadStructure.map((field: any, idx: number) => (
              <div key={idx} className="flex flex-col w-full gap-4 p-4 border border-gray-300 rounded-lg">
                {renderField(field)}
              </div>
            ))}
          </div>
        )}
      </Drawer>
    </ConfigProvider>
  );
};

export default AirtableImportModal;

import React, { KeyboardEvent, useState, useImperativeHandle, forwardRef, useEffect } from 'react';

interface TagInputProps {
  values: string[];
  onChange: (values: string[]) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  onInputChange?: (hasInput: boolean, inputValue?: string) => void;
}

export interface TagInputRef {
  processRemainingInput: () => void;
  hasInput: boolean;
}

const TagInput = forwardRef<TagInputRef, TagInputProps>(({
  values,
  onChange,
  placeholder = "Enter values...",
  className = "",
  disabled = false,
  onInputChange
}, ref) => {
  const [inputValue, setInputValue] = useState('');
  const [hasInput, setHasInput] = useState(false);

  useEffect(() => {
    setHasInput(inputValue.trim().length > 0);
    if (onInputChange) {
      onInputChange(inputValue.trim().length > 0, inputValue);
    }
  }, [inputValue, onInputChange]);

  // Expose methods to parent component
  useImperativeHandle(ref, () => ({
    processRemainingInput: () => {
      processInput();
    },
    hasInput
  }));

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (disabled) return;
    
    // Handle backspace
    if (e.key === 'Backspace' && inputValue === '' && values.length > 0) {
      e.preventDefault();
      const newValues = values.slice(0, -1);
      onChange(newValues);
      return;
    }

    // Handle enter and comma
    if ((e.key === 'Enter' || e.key === ',') && inputValue.trim()) {
      e.preventDefault();
      const newValue = inputValue.trim();
      if (!values.includes(newValue)) {
        const newValues = [...values, newValue];
        onChange(newValues);
      }
      setInputValue('');
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    if (disabled) return;
    e.preventDefault();

    // Get clipboard content
    const pastedData = e.clipboardData.getData('text');
    
    // Split by newlines, commas, tabs, or multiple spaces, then flatten and clean the array
    const newTags = pastedData
      .split(/[\n,\t]|\s+/) // Split by newline, comma, tab, or multiple spaces
      .map(tag => tag.trim())
      .filter(tag => tag.length > 0 && !values.includes(tag));

    if (newTags.length > 0) {
      const updatedValues = [...values, ...newTags];
      onChange(updatedValues);
      setInputValue('');
    }
  };

  const processInput = () => {
    if (disabled || !inputValue.trim()) return;
    
    // Process any remaining input value
    const remainingInput = inputValue.trim();
    
    // Split by commas or spaces if present
    if (remainingInput.includes(',') || remainingInput.includes(' ')) {
      const newTags = remainingInput
        .split(/[,\s]+/)
        .map(tag => tag.trim())
        .filter(tag => tag.length > 0 && !values.includes(tag));
      
      if (newTags.length > 0) {
        const updatedValues = [...values, ...newTags];
        onChange(updatedValues);
        setInputValue('');
      }
    } else if (!values.includes(remainingInput)) {
      // Add as a single tag if no delimiters
      const newValues = [...values, remainingInput];
      onChange(newValues);
      setInputValue('');
    }
  };

  const removeTag = (indexToRemove: number) => {
    if (disabled) return;
    const newValues = values.filter((_, index) => index !== indexToRemove);
    onChange(newValues);
  };

  return (
    <div className={`flex flex-wrap gap-2 p-2 border rounded-md bg-white ${className}`}>
      {values.map((tag, index) => (
        <span
          key={index}
          className={`flex items-center gap-1 px-2 py-1 bg-gray-100 rounded-md text-sm
            ${disabled ? 'text-gray-500' : 'text-gray-700'}`}
        >
          {tag}
          {!disabled && (
            <button
              onClick={() => removeTag(index)}
              className="ml-1 text-gray-500 hover:text-gray-700"
            >
              ×
            </button>
          )}
        </span>
      ))}
      <input
        type="text"
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
        onBlur={processInput}
        placeholder={values.length === 0 ? placeholder : ''}
        className={`outline-none flex-grow min-w-[100px] ${disabled ? 'cursor-not-allowed bg-gray-50' : ''}`}
        disabled={disabled}
      />
    </div>
  );
});

export default TagInput; 
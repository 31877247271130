import React, { useState, useRef } from "react";
import * as XLSX from "xlsx";
import { ResponseConfiguration } from "../../utils/interfaces";
import CSVModal from "./Modals/CSVModal";
import Papa from "papaparse";

type Props = {
  template: ResponseConfiguration[];
  workflowId: string;
  children?: React.ReactNode;
  refresh: () => void;
};

const XLSXInput: React.FC<Props> = ({ template, workflowId, children, refresh }) => {
  const [resArr, setResArr] = useState<any[]>([]);
  const [fileName, setFileName] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [sheets, setSheets] = useState<string[]>([]);
  const [selectedSheet, setSelectedSheet] = useState<string>("");
  const [fileData, setFileData] = useState<any>(null);

  const resetState = () => {
    setResArr([]);
    setFileName("");
    setSheets([]);
    setSelectedSheet("");
    setFileData(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the file input
    }
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    setFileName(file.name);
    const reader = new FileReader();

    if (file.name.endsWith(".csv") || file.name.endsWith(".tsv")) {
      reader.onload = (e) => {
        const text = e.target?.result as string;
        
        // Get the first line to analyze
        const firstLine = text.split('\n')[0];
        
        // Detect delimiter by analyzing the first line
        let delimiter = ","; // Default to comma
        
        // If there are tabs in the first line and more tabs than commas, use tab as delimiter
        // This is hacky, but it works for 99.9% of the cases
        // Will break if the user purposely uses excessive commas or tabs in the first line
        // TODO: Make this better
        if (firstLine.includes('\t')) {
          const tabCount = (firstLine.match(/\t/g) || []).length;
          const commaCount = (firstLine.match(/,/g) || []).length;
          
          if (tabCount > commaCount) {
            delimiter = '\t';
          }
        }
        
        const rows = Papa.parse(text, {
          delimiter: delimiter,
          newline: "\n",
          quoteChar: '"',
          escapeChar: '"',
          skipEmptyLines: true,
          transform: (value) => {
            return value.trim() === "\r" ? "" : value.trim();
          },
        }).data as string[][];
        setResArr(rows);
      };
      reader.readAsText(file);
    } else {
      reader.onload = (e) => {
        try {
          const data = new Uint8Array(e.target?.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetNames = workbook.SheetNames;
          setSheets(sheetNames);
          setFileData(workbook);

          // Set first sheet by default
          const firstSheet = sheetNames[0];
          setSelectedSheet(firstSheet);
          const worksheet = workbook.Sheets[firstSheet];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          setResArr(jsonData);
        } catch (error) {
          console.error("Error parsing Excel file:", error);
          resetState();
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    fileInputRef.current?.click();
  };

  return (
    <div className="relative">
      <input
        ref={fileInputRef}
        type="file"
        accept=".xlsx,.xls,.csv"
        onChange={handleFileUpload}
        style={{ display: "none" }}
        id="xlsx-upload"
      />
      <div onClick={handleButtonClick}>
        {resArr.length === 0 ? (
          children
        ) : (
          <CSVModal
            workflowId={workflowId}
            name={fileName}
            getRemoveFileProps={() => ({
              onClick: () => {
                resetState();
              },
            })}
            resArr={resArr}
            template={template}
            refresh={refresh}
            sheets={sheets}
            selectedSheet={selectedSheet}
            onSheetSelect={(sheet) => {
              setSelectedSheet(sheet);
              if (fileData) {
                const worksheet = fileData.Sheets[sheet];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                setResArr(jsonData);
              }
            }}
            isExcel={!fileName.endsWith(".csv")}
          />
        )}
      </div>
    </div>
  );
};

export default XLSXInput;

import { getConditionsForType, setStateType } from "../../../utils/constants";
import { useCallback, useEffect, useState } from "react";
import { useWorkflow } from "../../../contexts/WorkflowContext";
import TiptapField from "../Tiptap/TiptapField";
import { Action, PayloadConfiguration } from "../../../utils/interfaces";
import { Alert, ConfigProvider, Divider, Drawer, notification, Select, Switch } from "antd";
import { FaRegEdit } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import RunCondition from "./ModalComponents/RunCondition";
import ResponseStructureViewer from "./ModalComponents/ResponseStructure";
import { useConnections } from "@/contexts/ConnectionContext";
import DeleteIcon from "@/assets/SVGs/DeleteIcon.svg";
import { useRunOnFloq } from "@/contexts/RunOnFloqContext";
import { LuPlus } from "react-icons/lu";
import { GrAdd } from "react-icons/gr";

const MAX_FILTER_GROUPS = 3;
const MAX_FILTERS = 6;

/**
 * @todo 19.03.2025
 * - [x] Add limit max 3 conditions groups(OR conditions)
 * - [x] Add limit max 6 conditions in each group(AND conditions)
 * - [x] Add warning message when limit is reached, like a notification
 * - [x] Put "Add filter" button on the bottom of payload text, when there are no filters initially
 * - [x] Add OR filters with this divider: ----------- OR -------------- Take inspiration from hubspot search filters
 * - [ ] Check if we can use same property in multiple filters in hubspot, then put checks accordingly
 */

type Props = {
  action: Action;
  modal: boolean;
  setModal: setStateType<boolean>;
  payloadConfiguration: PayloadConfiguration[];
  responseConfiguration: any[];
  variables: any[];
};

const HubspotSearchFilters = ({
  action,
  modal,
  setModal,
  payloadConfiguration,
  responseConfiguration,
  variables,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [updateField, setUpdateField] = useState(0);
  const [payloads, setPayloads] = useState<PayloadConfiguration[]>(payloadConfiguration);
  const [runCondition, setRunCondition] = useState(action.runCondition);
  const [responses, setResponses] = useState(responseConfiguration);
  const { updatePayloadConfig, saveWorkflowActions, updateActionName, setPublishWarning, updateResponseConfig } =
    useWorkflow();
  const { getConnectionFromType } = useConnections();
  const { collapseConfig, setCollapseConfig } = useRunOnFloq();
  const [drawerWidth, setDrawerWidth] = useState(collapseConfig ? "3rem" : "30%");
  const [isConnectionExists, setIsConnectionExists] = useState(true);
  const [changeName, setChangeName] = useState(false);
  const [conditions, setConditions] = useState<any[]>([]);
  // const [options, setOptions] = useState<any[]>([]);
  const { id } = useParams();

  useEffect(() => {
    setWidthPercent();
    window.addEventListener("resize", setWidthPercent);
    return () => window.removeEventListener("resize", setWidthPercent);
  }, [collapseConfig]);

  const setWidthPercent = useCallback(() => {
    try {
      if (collapseConfig) setDrawerWidth("3rem");
      // Adjust width for different screen sizes
      else if (window.matchMedia("(min-width: 1024px) and (min-height: 1366px)").matches)
        // iPad Pro and similar devices
        setDrawerWidth("50%");
      else if (window.matchMedia("(min-width: 1024px)").matches) setDrawerWidth("30%");
      else if (window.matchMedia("(min-width: 640px)").matches) setDrawerWidth("50%");
      else setDrawerWidth("100%");
    } catch (err) {
      console.error("Error calculating width percent based on media size");
    }
  }, [collapseConfig]);

  useEffect(() => {
    setPayloads(payloadConfiguration);
    setUpdateField((prev) => prev + 1);
  }, [payloadConfiguration]);

  useEffect(() => {
    if (!conditions || !modal) return;

    const updatedConditions = conditions.map((conditionGroup: any) => {
      if (conditionGroup.conditions) return conditionGroup;
      return {
        conditions: [{ ...conditionGroup, operator: "AND" }],
      };
    });
    setConditions(updatedConditions);
  }, [modal]);

  // put a max limit of 3 on conditions size
  const showWarning = (message: string) => {
    notification.warning({
      message: "Max limit reached",
      description: message,
    });
  };

  useEffect(() => {
    if (payloads !== payloadConfiguration) setChanged(true);
    else setChanged(false);
  }, [payloads]);

  useEffect(() => {
    if (!action?.connectionId) return;
    getConnectionFromType(action?.connectionId).then((connection: any) => {
      setIsConnectionExists(connection?.userConnection ? true : false);
    });
  }, [action?.connectionId]);

  useEffect(() => {
    const payload = payloadConfiguration || [];
    const pathPayload = payload.find((config: any) => config.payloadStructureId === "hubspot_search_filters");
    if (!pathPayload) return;
    setPayloads(payload);
    setConditions(pathPayload.inputString[0]?.conditions || []);
  }, []);

  const saveOutputStructure = async () => {
    setLoading(true);
    const payload = payloadConfiguration || [];
    const pathPayload = payload.find((config: any) => config.payloadStructureId === "hubspot_search_filters");
    if (!pathPayload) return;
    pathPayload.inputString[0] = {
      conditions,
    };
    const updated = updatePayloadConfig(payloads, action.id, false, action.continueOnFail, runCondition);
    setPayloads(updated);
    const updatedResponses = updateResponseConfig(responses, action.id);
    setResponses(updatedResponses);
    await saveWorkflowActions(id || "");
    setChanged(false);
    setLoading(false);
    setPublishWarning(true);
  };

  // useEffect(() => {
  //   if (!variables) return;
  //   const newOpts: any[] = [];
  //   variables.forEach((action) => {
  //     action?.variables?.forEach((variable: ResponseStructure) => {
  //       const option = {
  //         id: variable.responseId,
  //         logo: action.logo,
  //         type: variable.type || "string",
  //         name: variable.name,
  //         actionName: action.name,
  //       };
  //       newOpts.push(option);
  //     });
  //   });
  //   setOptions(newOpts);
  // }, [variables]);

  useEffect(() => {
    if (payloads !== payloadConfiguration || runCondition !== action.runCondition) {
      setChanged(true);
    } else setChanged(false);
  }, [payloads, runCondition]);

  const hubspotProperties = [
    {
      label: "First Name",
      value: "firstname",
    },
    {
      label: "Last Name",
      value: "lastname",
    },
    {
      label: "Email",
      value: "email",
    },
    {
      label: "LinkedIn URL",
      value: "hs_linkedin_url",
    },
  ];

  const fieldSwitchCase = (field: any) => {
    switch (field?.type) {
      case "hubspot_search_filters":
        return (
          <div className="bg-white gap-6 flex flex-col items-center">
            {/* mapping filter groups(OR filters) */}
            {conditions &&
              conditions?.map((conditionGroup, parentIdx) => (
                <>
                  <div key={parentIdx} className="flex flex-col gap-4 bg-gray-100/20 py-4 border border-1 rounded-md">
                    <div className="flex gap-2 px-4 w-full items-center justify-between">
                      <p className="text-headings font-medium text-md">Filter Group {parentIdx + 1}</p>
                      <button
                        className="rounded-full w-10 h-10 px-3 flex gap-1 items-center text-red-500 hover:bg-red-50 hover:border-red-500"
                        onClick={() => {
                          const temp = [...conditions];
                          temp.splice(parentIdx, 1);
                          setChanged(true);
                          setConditions(temp);
                        }}
                      >
                        <img src={DeleteIcon} alt="Delete" className="w-4 h-4" />
                      </button>
                    </div>
                    {/* mapping filters inside each filter group(AND filters) */}
                    {conditionGroup.conditions?.map((field: any, idx: number) => (
                      <div
                        key={idx}
                        className={`flex gap-4 rounded-lg ${idx !== 0 ? "ml-8 mr-4 outline-2 outline-dashed outline-gray-200" : ""}`}
                      >
                        <div className="w-full flex flex-col">
                          {idx !== 0 && (
                            <>
                              <div className="flex justify-between items-center p-4">
                                <p className="font-medium text-md text-gray-500">AND</p>
                                <button
                                  className="rounded-full w-10 h-10 px-3 flex gap-1 items-center text-red-500 hover:bg-red-50 hover:border-red-500"
                                  onClick={() => {
                                    const temp = [...conditions];
                                    temp[parentIdx].conditions.splice(idx, 1);
                                    setChanged(true);
                                    setConditions(temp);
                                  }}
                                >
                                  <img src={DeleteIcon} alt="Delete" className="w-4 h-4" />
                                </button>
                              </div>
                              <div className="my-0 bg-gray-200 outline-dashed outline-1 outline-gray-200" />
                            </>
                          )}
                          <div className={`flex gap-2 items-start w-full ${idx === 0 ? "px-4" : "p-4"}`}>
                            <p className="font-medium text-md text-gray-500">if</p>
                            <div className="w-full flex flex-col gap-2">
                              {/* dropdown for hubspot properties */}
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select a hubspot property to filter upon"
                                className="w-full"
                                value={field.propertyName || undefined}
                                onClear={() => {
                                  const temp = [...conditions];
                                  temp[parentIdx].conditions[idx] = {
                                    propertyName: "",
                                    condition: "",
                                    variableType: "",
                                    value: "",
                                    operator: temp[parentIdx].conditions[idx].operator,
                                    tiptapJson: {
                                      type: "doc",
                                      content: [
                                        {
                                          type: "paragraph",
                                          content: [],
                                        },
                                      ],
                                    },
                                  };
                                  setConditions(temp);
                                }}
                                onChange={(value) => {
                                  const temp = [...conditions];
                                  temp[parentIdx].conditions[idx] = {
                                    propertyName: value,
                                    condition: "",
                                    variableType: "string",
                                    value: "",
                                    operator: temp[parentIdx].conditions[idx].operator,
                                    tiptapJson: {
                                      type: "doc",
                                      content: [
                                        {
                                          type: "paragraph",
                                          content: [],
                                        },
                                      ],
                                    },
                                  };
                                  setChanged(true);
                                  setConditions(temp);
                                }}
                                options={hubspotProperties}
                                optionFilterProp="label"
                              />
                              {/* dropdown for search operators */}
                              <Select
                                showSearch
                                allowClear
                                className="w-full"
                                placeholder="Select a search operator"
                                value={field.condition || undefined}
                                onChange={(value) => {
                                  const temp = [...conditions];
                                  temp[parentIdx].conditions[idx].condition = value;
                                  setChanged(true);
                                  setConditions(temp);
                                }}
                                options={getConditionsForType("hubspot_search_filters") || []}
                              />
                              {/* editor for text and variables */}
                              <div className="flex flex-col gap-2 w-full">
                                <TiptapField
                                  content={
                                    payloads.find((input) => input.payloadStructureId === "hubspot_search_filters")
                                      ? payloads.find((input) => input.payloadStructureId === "hubspot_search_filters")
                                          ?.inputString[0]?.conditions[parentIdx]?.conditions[idx]?.tiptapJson
                                      : ""
                                  }
                                  setContent={(content: any, text: string) => {
                                    const temp = [...conditions];
                                    temp[parentIdx].conditions[idx].value = text;
                                    temp[parentIdx].conditions[idx].tiptapJson = content;
                                    setChanged(true);
                                    setConditions(temp);
                                  }}
                                  refresh={updateField}
                                  placeholder={"Enter your search query"}
                                  variables={variables}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    {conditions[parentIdx].conditions.length < MAX_FILTERS && (
                      <>
                        <Divider className="my-0" />
                        <button
                          className="w-fit rounded-lg px-4 flex gap-1 hover:underline"
                          onClick={() => {
                            const temp = [...conditions];
                            if (temp[parentIdx].conditions.length >= MAX_FILTERS) {
                              showWarning("You can only have up to 6 Filters in each Filter Group.");
                              return;
                            }
                            temp[parentIdx].conditions.push({
                              propertyName: "",
                              condition: "",
                              variableType: "",
                              value: "",
                              operator: "AND",
                              tiptapJson: {
                                type: "doc",
                                content: [
                                  {
                                    type: "paragraph",
                                    content: [],
                                  },
                                ],
                              },
                            });
                            setConditions(temp);
                          }}
                        >
                          <LuPlus size={20} />
                          Add filter
                        </button>
                      </>
                    )}
                  </div>
                  {parentIdx !== MAX_FILTER_GROUPS - 1 && (
                    <Divider className="my-0 " orientation="center">
                      <p className="font-medium text-md text-gray-500">OR</p>
                    </Divider>
                  )}
                </>
              ))}
            {conditions.length === 0 && (
              <p className="text-gray-500">
                No filters added yet. Add advanced filters to search for specific records.
              </p>
            )}
            {conditions.length < MAX_FILTER_GROUPS && (
              <button
                className="border w-fit border-black rounded-md p-2 flex flex-row items-center gap-2 hover:underline"
                onClick={() => {
                  const temp = [...conditions];
                  if (temp.length >= MAX_FILTER_GROUPS) {
                    showWarning("You can only have up to 3 Filter Groups.");
                    return;
                  }
                  temp.push({
                    conditions: [
                      {
                        propertyName: "",
                        condition: "",
                        variableType: "",
                        value: "",
                        operator: "AND",
                        tiptapJson: {
                          type: "doc",
                          content: [
                            {
                              type: "paragraph",
                              content: [],
                            },
                          ],
                        },
                      },
                    ],
                  });
                  setConditions(temp);
                }}
              >
                <GrAdd size={14} />
                Add filter group
              </button>
            )}
          </div>
        );
      default:
        return (
          <textarea
            className="w-full h-32 rounded-lg"
            placeholder="Enter the value"
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""}
            onChange={(e) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: e.target.value,
                  type: field?.type,
                });
              } else {
                temp[index].inputString = e.target.value;
              }
              setConditions(temp);
            }}
          />
        );
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={modal}
        mask={false}
        onClose={() => setModal(false)}
        width={drawerWidth}
        styles={{
          content: {
            transition: "width 0.3s",
          },
          body: {
            padding: collapseConfig ? "0" : "24px",
          },
        }}
        classNames={{ header: !collapseConfig ? "border-b !border-gray-300" : "!border-none" }}
        className="ml-auto !font-favorit !p-0 border-l border-gray-500"
        getContainer={false}
        footer={
          !collapseConfig && (
            <div className="w-full flex flex-col gap-4 bg-white">
              {!isConnectionExists && (
                <Alert
                  message={
                    <>
                      Please add the required connection to proceed.{" "}
                      <Link to="/connections" target="_blank" rel="noopener noreferrer">
                        <i>Click here</i>
                      </Link>
                    </>
                  }
                  type="warning"
                  showIcon
                />
              )}
              <button
                className={` text-white w-full font-semibold rounded-lg p-2 mt-auto ${
                  loading || !changed || !isConnectionExists
                    ? "cursor-not-allowed bg-gray-500"
                    : "bg-primary cursor-pointer"
                }`}
                onClick={saveOutputStructure}
                disabled={loading || !changed || !isConnectionExists}
              >
                {loading ? "Saving..." : !changed ? "Saved" : "Save"}
              </button>
            </div>
          )
        }
        // check if the credits is a number , if (number) display here
        title={
          !collapseConfig && (
            <div className="flex items-center gap-2">
              <button className="p-0 rounded-sm" onClick={() => setCollapseConfig(true)}>
                <MdChevronRight size={25} />
              </button>
              <img src={action.logo} alt={action.name} className="w-6 h-6" />
              <span
                id="actionName"
                contentEditable={changeName}
                className={changeName ? "border border-black px-2" : ""}
                suppressContentEditableWarning
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.target.blur();
                  }
                }}
                onBlur={async (e) => {
                  if (!e.target.textContent) return;
                  setLoading(true);
                  updateActionName(action.id, e.target.textContent || action.name);
                  setChangeName(false);
                  await saveWorkflowActions(id || "");
                  setChanged(false);
                  setLoading(false);
                  setPublishWarning(true);
                }}
              >
                {action.name}
              </span>
              <button
                onClick={() => {
                  setChangeName(!changeName);
                  const p = document.getElementById("actionName");
                  if (!p) return;
                  setTimeout(function () {
                    const range = document.createRange();
                    const selection = window.getSelection();
                    range.selectNodeContents(p);
                    range.collapse(false); // <-- Set the cursor at the end of the selection
                    selection?.removeAllRanges();
                    selection?.addRange(range);
                    p.focus();
                  }, 0);
                }}
                className={`p-2 rounded-md transition-opacity duration-600 ease-in-out hover:bg-gray-200 ${
                  changeName ? "opacity-50" : "opacity-100"
                }`}
              >
                <FaRegEdit />
              </button>
            </div>
          )
        }
      >
        {collapseConfig ? (
          <button
            className="w-full h-full flex flex-col items-center justify-center p-3 gap-3"
            onClick={() => setCollapseConfig(false)}
          >
            <span className="p-0 border border-gray-300 rounded-sm">
              <MdChevronLeft size={25} />
            </span>
            <p className="[writing-mode:sideways-lr] text-center">CONFIG</p>
          </button>
        ) : (
          <div className="bg-white gap-10 flex flex-col">
            {action.payloadStructure.map((field, idx) => {
              return (
                <div key={idx} className="flex flex-col w-full gap-4 p-4 border border-gray-300 rounded-lg">
                  <div>
                    <p className="tracking-primary font-semibold">
                      {field.name.toUpperCase()}
                      {!field.required && (
                        <span className="text-sm font-normal tracking-normal text-gray-400"> (Optional)</span>
                      )}
                    </p>
                    <p className="text-sm font-light">{field.description}</p>
                  </div>
                  {field.needVars ? (
                    <TiptapField
                      content={
                        payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.tiptapJson ||
                        ""
                      }
                      setContent={(content: any, text: string) => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        if (index === -1) {
                          temp.push({
                            payloadStructureId: field.payloadStructureId,
                            tiptapJson: content,
                            inputString: text,
                            type: field?.type,
                          });
                        } else {
                          temp[index].tiptapJson = content;
                          temp[index].inputString = text;
                        }
                        setPayloads(temp);
                      }}
                      refresh={updateField}
                      placeholder={field.name}
                      variables={variables}
                    />
                  ) : (
                    fieldSwitchCase(field)
                  )}
                </div>
              );
            })}
            <div>
              Continue workflow even if this fails:{" "}
              <Switch
                defaultChecked={action.continueOnFail}
                onChange={(value) => {
                  setChanged(true);
                  action.continueOnFail = value;
                }}
              />
            </div>
            <RunCondition
              variables={variables}
              responses={action.responseStructure}
              conditionsArr={
                runCondition || {
                  conditions: [],
                  otherwise: [],
                }
              }
              setConditionsArr={setRunCondition}
            />
            <ResponseStructureViewer
              setResponses={setResponses}
              responses={responses}
              action={action}
              setChanged={setChanged}
            />
          </div>
        )}
      </Drawer>
    </ConfigProvider>
  );
};

export default HubspotSearchFilters;

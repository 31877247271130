import { getBrowserTokens, getEmailFromAccessToken, handleUpdateTokens } from "@/utils/functions";
import {
  DELETE_LIST_DATA_URL,
  DELETE_WATCHER_URL,
  EXPORT_LIST_DATA_URL,
  GET_LIST_CONNECTIONS_URL,
  GET_LIST_DATA_URL,
  GET_PERIODIC_LEAD_DATA_URL,
  GET_SIGNALS_URL,
  GET_WATCHER_DATA_URL,
  PUSH_LIST_TO_FLOQ_URL,
  UPDATE_WATCHER_NAME_URL,
} from "@/utils/urls";
import { message } from "antd";

export const getSignals = async (): Promise<any> => {
  try {
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const res = await fetch(GET_SIGNALS_URL, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.response;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch signals");
  }
};

export const createSignal = async (url: string, payload: any, name: string): Promise<any> => {
  try {
    if (!url) throw new Error("Invalid URL");
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({ payload, name }),
    };
    const res = await fetch(url, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.watcherId;
  } catch (error) {
    console.log(error);
    message.error("Failed to create signal");
  }
};

export const signalPreview = async (url: string, payload: any): Promise<any> => {
  try {
    if (!url) throw new Error("Invalid URL");
    const [accessToken, refreshToken] = getBrowserTokens();
    const userEmail = getEmailFromAccessToken(accessToken);
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
        email: userEmail,
      },
      body: JSON.stringify({ payload }),
    };
    const res = await fetch(url, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.responses;
  } catch (error) {
    console.log(error);
    message.error("Failed to generate signal preview");
  }
};

export const updateWatcherName = async (watcherId: string, newName: string): Promise<{ message: string }> => {
  const url = UPDATE_WATCHER_NAME_URL;
  const [accessToken, refreshToken] = getBrowserTokens();

  if (!accessToken || !refreshToken) throw new Error("Authentication tokens are missing.");

  const response = await fetch(`${url}/${watcherId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      accessToken,
      refreshToken,
    },
    body: JSON.stringify({ name: newName }),
  });

  const responseData = await response.json();
  if (!response.ok) throw new Error(responseData.error || "Failed to update watcher name.");
  return responseData;
};

export const deleteWatcher = async (watcherId: string): Promise<{ message: string }> => {
  const url = DELETE_WATCHER_URL;
  const [accessToken, refreshToken] = getBrowserTokens();

  if (!accessToken || !refreshToken) {
    throw new Error("Authentication tokens are missing.");
  }

  const response = await fetch(`${url}/${watcherId}`, {
    method: "DELETE",
    headers: {
      accessToken,
      refreshToken,
    },
  });

  const responseData = await response.json();
  if (!response.ok) throw new Error(responseData.error || "Failed to delete watcher.");
  return responseData;
};

export const updateSignal = async (id: string, url: string, status: any): Promise<any> => {
  try {
    if (!url) throw new Error("Invalid URL");
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "PATCH",
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const finalURL = new URL(url);
    finalURL.pathname = finalURL.pathname + "/" + id;
    finalURL.searchParams.append("status", status);
    const res = await fetch(finalURL.toString(), options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.watcherId;
  } catch (error) {
    throw new Error("Failed to update signal");
  }
};

export const updateSignalStaticData = async (id: string, url: string, payload: any): Promise<any> => {
  try {
    if (!url) throw new Error("Invalid URL");
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({ payload }),
    };
    const finalURL = new URL(url);
    finalURL.pathname = finalURL.pathname + "/" + id;
    const res = await fetch(finalURL.toString(), options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.watcherId;
  } catch (error) {
    throw new Error("Failed to update signal");
  }
};

export const fetchWatcherData = async (id: string): Promise<any> => {
  try {
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const res = await fetch(`${GET_WATCHER_DATA_URL}/${id}`, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.response;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch watcher");
  }
};

export const fetchPaginatedListData = async (id: string, pageNo: number = 1, pageSize: number = 20): Promise<any> => {
  try {
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const url = `${GET_LIST_DATA_URL}/${id}?pageNo=${pageNo}&pageSize=${pageSize}`;
    const res = await fetch(url, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.response;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch list data");
  }
};

export const fetchPeriodicLeadData = async (id: string, timePeriod: string, timeRange: string): Promise<any> => {
  try {
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const url = `${GET_PERIODIC_LEAD_DATA_URL}/${id}?timePeriod=${timePeriod}&timeRange=${timeRange}`;
    const res = await fetch(url, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.response;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch leads data");
  }
};

export const exportListData = async (id: string, signalId: string, selectedRowIds?: string[]): Promise<any> => {
  try {
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "POST",
      headers: {
        accessToken,
        refreshToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ selectedRowIds: selectedRowIds ?? [], signalId }),
    };

    const url = `${EXPORT_LIST_DATA_URL}/${id}`;
    const res = await fetch(url, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.fileUrl;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to export list data");
  }
};

export const deleteListData = async (listId: string, leads: string[]): Promise<any> => {
  try {
    const [accessToken, refreshToken] = getBrowserTokens();

    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({ leads }),
    };

    const url = `${DELETE_LIST_DATA_URL}/${listId}`;
    const res = await fetch(url, options);
    const responseData = await res.json();

    if (!res.ok) throw new Error(responseData.error || "Failed to delete list data");

    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData;
  } catch (error) {
    console.error("Error in deleteListData:", error);
    throw new Error("Failed to delete list data");
  }
};

export const fetchListConnections = async (id: string): Promise<any> => {
  try {
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const url = `${GET_LIST_CONNECTIONS_URL}/${id}`;
    const res = await fetch(url, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.connections;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch connections");
  }
};

export const deleteConnection = async (id: string, workflowId: string): Promise<any> => {
  try {
    const [accessToken, refreshToken] = getBrowserTokens();

    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({ workflowId }),
    };

    const url = `${GET_LIST_CONNECTIONS_URL}/${id}`;
    const res = await fetch(url, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error || "Failed to delete connection");
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData;
  } catch (error) {
    console.error("Error in deleteConnection:", error);
    throw new Error("Failed to delete connection");
  }
};

export const createListConnection = async (
  id: string,
  workflowId: string,
  payload_map: any,
  existing: boolean
): Promise<any> => {
  try {
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "POST",
      headers: {
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({ workflowId, payload_map, existing }),
    };
    const url = `${GET_LIST_CONNECTIONS_URL}/${id}`;
    const res = await fetch(url, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.newConnection;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to create connection");
  }
};

export const updateListConnection = async (id: string, workflowId: string, payload_map: any): Promise<any> => {
  try {
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "PATCH",
      headers: {
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({ workflowId, payload_map }),
    };
    const url = `${GET_LIST_CONNECTIONS_URL}/${id}`;
    const res = await fetch(url, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.newConnection;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to update connection");
  }
};

export const updateListConnectionStatus = async (listId: string, workflowId: string, status: string): Promise<any> => {
  try {
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({ workflowId, status }),
    };
    const url = `${GET_LIST_CONNECTIONS_URL}/${listId}/status`;
    const res = await fetch(url, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.newConnection;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to update connection status");
  }
};

export const pushListToFloq = async (id: string, workflowId: string, payload_map: any): Promise<any> => {
  try {
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "POST",
      headers: {
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({ workflowId, payload_map }),
    };
    const url = `${PUSH_LIST_TO_FLOQ_URL}/${id}`;
    const res = await fetch(url, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.newConnection;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to push to Floq");
  }
};


import { useState, createContext, useContext, useEffect } from "react";
import { motion } from "framer-motion";
import { cn } from "@/utils/cn";
import { Link, LinkProps, useLocation } from "react-router-dom";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi2";

interface Links {
  label: string;
  href: string;
  selected: string;
  icon: React.JSX.Element | React.ReactNode;
  selectedIcon?: React.JSX.Element | React.ReactNode;
  subLinks?: Links[];
}

interface SidebarContextProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  animate: boolean;
}

const SidebarContext = createContext<SidebarContextProps | undefined>(undefined);

export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error("useSidebar must be used within a SidebarProvider");
  }
  return context;
};

export const SidebarProvider = ({
  children,
  open: openProp,
  setOpen: setOpenProp,
  animate = true,
}: {
  children: React.ReactNode;
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  animate?: boolean;
}) => {
  const [openState, setOpenState] = useState(false);

  const open = openProp !== undefined ? openProp : openState;
  const setOpen = setOpenProp !== undefined ? setOpenProp : setOpenState;

  return <SidebarContext.Provider value={{ open, setOpen, animate: animate }}>{children}</SidebarContext.Provider>;
};

export const Sidebar = ({
  children,
  open,
  setOpen,
  animate,
}: {
  children: React.ReactNode;
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  animate?: boolean;
}) => {
  return (
    <SidebarProvider open={open} setOpen={setOpen} animate={animate}>
      {children}
    </SidebarProvider>
  );
};

export const SidebarBody = (props: React.ComponentProps<typeof motion.div>) => {
  return <DesktopSidebar {...props} />;
};

export const DesktopSidebar = ({ className, children, ...props }: React.ComponentProps<typeof motion.div>) => {
  const { open, animate } = useSidebar();
  return (
    <>
      <motion.div
        className={cn("h-full z-[100] bg-white ", className, !animate && "min-w-64")}
        animate={{
          position: animate ? "absolute" : "relative",
          width: animate ? (open ? "16rem" : "0.3rem") : "16rem",
          padding: animate ? (open ? "10px 0px 10px 10px" : "0") : "0",
        }}
        transition={{
          duration: 0.15,
          ease: "easeInOut",
        }}
        // onMouseEnter={() => animate && setOpen(true)}
        // onMouseLeave={() => animate && setOpen(false)}
        {...props}
      >
        <motion.div
          animate={{
            border: animate ? (open ? "1px solid #E5E7EB" : "none") : "1px solid #E5E7EB",
            // boxShadow: animate
            //   ? open
            //     ? "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
            //     : "none"
            //   : "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
            // padding: animate ? (open ? "0 0.75rem" : "0") : "0 0.75rem",
          }}
          transition={{
            duration: 0.15,
            ease: "easeInOut",
          }}
          className="flex flex-col flex-shrink-0 group/sidebar h-full rounded-lg"
        >
          {children}
        </motion.div>
      </motion.div>
    </>
  );
};

export const SidebarLink = ({ link, className, ...props }: { link: Links; className?: string; props?: LinkProps }) => {
  const { open, animate } = useSidebar();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const selected =
    location.pathname.includes(link.selected) ||
    link.subLinks?.some((subLink) => location.pathname.includes(subLink.selected));

  // const selected = location.pathname.includes(link.selected) ||
  // link.subLinks?.some(subLink => location.pathname.includes(subLink.selected));

  // const bg = open || !animate;

  const hasSubLinks = link.subLinks && link.subLinks.length > 0;

  useEffect(() => {
    const shouldBeOpen = link.subLinks?.some((subLink) => location.pathname.includes(subLink.selected));
    setIsOpen(!!shouldBeOpen);
  }, [location, link.selected, link.subLinks]);

  return (
    <div className="flex flex-col font-favorit text-[#222222] text-base">
      <Link
        to={link.href}
        className={cn(
          "flex items-center justify-start gap-2 group/sidebar p-2 rounded-[3px]",
          selected
            ? "outline outline-1 outline-[#E5E7EB] bg-[#F8F8F8] hover:bg-white"
            : "hover:outline hover:outline-1 hover:outline-[#E5E7EB]",
          className
        )}
        {...props}
        onClick={(e) => {
          if (hasSubLinks) {
            e.preventDefault(); // Prevent navigation if there are sublinks
            setIsOpen((prev) => !prev);
          }
        }}
      >
        {selected ? link.selectedIcon : link.icon}

        <motion.span
          animate={{
            visibility: animate ? (open ? "visible" : "hidden") : "visible",
            opacity: animate ? (open ? 1 : 0) : 1,
          }}
          transition={{
            duration: 0.15,
            ease: "easeInOut",
          }}
          className={`font-light whitespace-pre inline-block !p-0 !m-0 ${selected ? "font-normal" : "font-light"}`}
        >
          {link.label}
        </motion.span>
        {link.subLinks &&
          (isOpen ? (
            <HiOutlineChevronUp className="ml-auto transition-transform duration-300" />
          ) : (
            <HiOutlineChevronDown className="ml-auto transition-transform duration-300" />
          ))}
      </Link>

      {hasSubLinks && isOpen && (
        <div className="dropdown-content my-2 transition-all duration-300 flex flex-col gap-1 border-0 border-l-[1px] ml-4 pl-1">
          {link.subLinks?.map((subLink, idx) => {
            const isSelected = location.pathname.includes(subLink.selected);
            return (
              <Link
                key={idx}
                to={subLink.href}
                className={cn(
                  "flex items-center justify-start gap-2 p-2 rounded-[3px]",
                  isSelected
                    ? "outline outline-1 outline-[#E5E7EB] bg-[#F8F8F8] hover:bg-white"
                    : "hover:outline hover:outline-1 hover:outline-[#E5E7EB]"
                )}
              >
                {isSelected ? subLink.selectedIcon : subLink.icon}
                <span className={`${isSelected ? "font-normal" : "font-light"}`}>{subLink.label}</span>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

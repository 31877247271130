export function calculateTotalCreditsConsumed(stepDownResponse: Record<string, any>, credits: any): number {
  // if stepDownResponse is empty(or undefined) or credits is empty, return the first value of credits
  if (!stepDownResponse || !credits || Object.keys(stepDownResponse).length === 0) {
    return (credits && Object.values(credits)[0]) || 0;
  }
  // Calculate the total creditsConsumed
  return Object.values(stepDownResponse).reduce((total, user, index) => {
    const userKey = Object.keys(stepDownResponse)[index];
    // Use user.creditsConsumed if defined; otherwise, use the corresponding value from credits
    const userCredits = user?.creditsConsumed !== undefined ? user.creditsConsumed : credits[userKey];
    return total + (userCredits || 0);
  }, 0);
}

export function splitArrayIntoChunks<T>(array: T[], numChunks: number): T[][] {
  if (numChunks <= 0) throw new Error("Number of chunks must be positive");
  if (array.length === 0) return [];

  const chunkSize = Math.ceil(array.length / numChunks);
  const chunks: T[][] = [];

  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }

  return chunks;
}

export const generateTimestampForDays = (days: number): number => {
  const now = Date.now(); // Current timestamp in ms
  const futureDate = now + days * 24 * 60 * 60 * 1000; // Add days in milliseconds
  return Math.floor(futureDate / 1000); // Convert to seconds
};

export const formatTTLRelative = (ttl: number): string => {
  const now = Math.floor(Date.now() / 1000);
  const diffSeconds = ttl - now;

  const days = Math.floor(diffSeconds / (24 * 60 * 60));
  const hours = Math.floor((diffSeconds % (24 * 60 * 60)) / (60 * 60));

  if (days > 0) {
    return `in ${days} day${days > 1 ? "s" : ""} and ${hours} hour${hours > 1 ? "s" : ""}`;
  } else if (hours > 0) {
    return `in ${hours} hour${hours > 1 ? "s" : ""}`;
  } else {
    return "less than an hour";
  }
};

export const generatePastTimestamp = (days: number): number => {
  const now = new Date();
  const pastDate = new Date(now.getTime() - days * 24 * 60 * 60 * 1000);
  return pastDate.getTime();
};

export const timestampToDate = (timestamp: number): string => {
  // Convert negative timestamp to positive if needed
  const absTimestamp = Math.abs(timestamp);
  // Create a new Date object using the timestamp (in milliseconds)
  const date = new Date(absTimestamp);
  // Format the date to a readable string
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    // hour: "2-digit",
    // minute: "2-digit",
  });
};

export const getStatusColor = (status: string) => {
  switch (status.toLowerCase()) {
    case "completed":
      return "success";
    case "reimbursed":
      return "warning";
    case "failed":
      return "error";
    default:
      return "default";
  }
};

export const mapInputsToComunm = (inputs: any[], coldef: any[]) => {
  const mapping: any = {};

  inputs.forEach((input) => {
    coldef?.forEach((col) => {
      const colName = col?.name?.toLowerCase();
      const inputName = input?.name?.toLowerCase();

      console.log(colName, inputName, colName.includes(inputName));
      if (inputName.includes(colName)) {
        const existingMappedKeys = Object.values(mapping);
        if (existingMappedKeys.includes(col?.responseStructureId)) {
          return;
        }
        mapping[input?.responseId] = col?.responseStructureId;
      }
    });
  });

  return mapping;
};

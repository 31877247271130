import DeleteIconNew from "@/assets/SVGs/DeleteIconNew.svg";
import { finalConditions, getIconFromType, inputTypeFromVariableType, setStateType } from "@/utils/constants";
import { Select } from "antd";
import { useEffect, useState } from "react";

type Props = {
  variables: any[];
  conditionsArr: {
    conditions: any[];
    operator?: string;
  };
  setConditionsArr: setStateType<any>;
  setPayloads: (update: any) => void;
};

const CRMfilterComponent = ({ variables, conditionsArr, setConditionsArr, setPayloads }: Props) => {
  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    if (!variables) return;
    setOptions([]);
    variables.forEach((variable) => {
      const option = {
        id: variable.name,
        type: variable?.type || "string",
        name: variable.label,
        options: variable?.options || variable?.picklistValues || [],
      };
      setOptions((prev) => [...prev, option]);
    });
  }, [variables]);

  return (
    <div className="flex flex-col w-full px-2">
      <div className="flex flex-col gap-2 mb-4">
        {/* <h2 className="text-lg font-semibold">Run this only if</h2>
        <p className="text-sm text-gray-500">Run this only if</p> */}
      </div>
      <div className="bg-white gap-10 flex flex-col">
        {conditionsArr?.conditions?.map((conditionGroup, parentIdx) => (
          <div key={parentIdx} className="flex flex-col gap-2 bg-gray-100/20 p-2 border border-1 rounded-md">
            <div className="flex gap-2 w-full items-center">
              {parentIdx !== 0 && (
                <div className="flex gap-2 w-full items-center">
                  <Select
                    showSearch
                    value={conditionGroup.operator || "AND"}
                    onChange={(value) => {
                      const temp = { ...conditionsArr };
                      temp.conditions[parentIdx].operator = value;
                      setConditionsArr(temp);
                    }}
                    options={[
                      {
                        label: "AND",
                        value: "AND",
                      },
                      {
                        label: "OR",
                        value: "OR",
                      },
                    ]}
                  />
                </div>
              )}
              <button
                className="ml-auto h-8 w-8 flex items-center justify-center rounded-md"
                onClick={() => {
                  const temp = { ...conditionsArr };

                  // Extract all variables from the group being removed
                  const removedVariables = temp.conditions[parentIdx].conditions.map((cond: any) => cond.variable);

                  temp.conditions.splice(parentIdx, 1); // Remove the entire group
                  setConditionsArr(temp);

                  // Remove all associated variables from payloads.properties
                  setPayloads((prev: any) => {
                    const updatedProperties = prev.properties.filter((p: string) => !removedVariables.includes(p));
                    return { ...prev, properties: updatedProperties };
                  });
                }}
              >
                <img src={DeleteIconNew} alt="" />
              </button>
            </div>
            {conditionGroup.conditions?.map((field: any, idx: number) => (
              <div key={idx} className="flex gap-4 p-4 border border-gray-300 rounded-lg">
                <div className="w-[90%] flex flex-col">
                  {idx !== 0 && (
                    <div className="flex gap-2 w-full items-center">
                      <Select
                        showSearch
                        value={field.operator || "AND"}
                        onChange={(value) => {
                          const temp = { ...conditionsArr };
                          temp.conditions[parentIdx].conditions[idx].operator = value;
                          setConditionsArr(temp);
                        }}
                        options={[
                          {
                            label: "AND",
                            value: "AND",
                          },
                          {
                            label: "OR",
                            value: "OR",
                          },
                        ]}
                      />
                    </div>
                  )}
                  <div className="flex gap-2 w-full">
                    <div className="w-full flex flex-col gap-2">
                      <Select
                        showSearch
                        allowClear
                        className="w-[95%]"
                        value={field.variable}
                        onClear={() => {
                          const temp = { ...conditionsArr };
                          const prevValue = temp.conditions[parentIdx].conditions[idx].variable;
                          temp.conditions[parentIdx].conditions[idx] = {
                            variable: "",
                            condition: "",
                            variableType: "",
                            values: [],
                            operator: temp.conditions[parentIdx].conditions[idx].operator,
                          };
                          setConditionsArr(temp);
                          setPayloads((prev: any) => {
                            const updatedProperties = prev.properties.filter((p: string) => p !== prevValue); // Remove cleared value
                            return { ...prev, properties: updatedProperties };
                          });
                        }}
                        onChange={(value) => {
                          const temp = { ...conditionsArr };
                          const option = options.find((option) => option.id === value);
                          const prevValue = temp.conditions[parentIdx].conditions[idx].variable; // get the previous value
                          if (field.variableType === option.type)
                            temp.conditions[parentIdx].conditions[idx].variable = value;
                          else
                            temp.conditions[parentIdx].conditions[idx] = {
                              variable: value,
                              condition: "",
                              variableType: option.type,
                              values: [],
                              operator: temp.conditions[parentIdx].conditions[idx].operator,
                            };
                          setConditionsArr(temp);
                          setPayloads((prev: any) => {
                            const updatedProperties = Array.from(
                              new Set(prev.properties.filter((p: string) => p !== prevValue).concat(value)) // Ensure unique values
                            );

                            return { ...prev, properties: updatedProperties };
                          });
                        }}
                        options={options.map((option, index: number) => ({
                          label: (
                            <div className="flex min-w-full flex-row items-center text-sm gap-2" key={index}>
                              <p className="flex items-center gap-2 justify-center">
                                <div className="bg-gray-200 p-1 rounded">{getIconFromType(option.type)}</div>
                                {option.name}
                              </p>
                            </div>
                          ),
                          value: option.id,
                          name: `${option.actionName} - ${option.name}`,
                        }))}
                        optionFilterProp="name"
                      />
                      {field.variableType !== "" && (
                        <Select
                          showSearch
                          allowClear
                          className="w-[95%]"
                          value={field.condition}
                          onChange={(value) => {
                            const temp = { ...conditionsArr };
                            temp.conditions[parentIdx].conditions[idx].condition = value;
                            setConditionsArr(temp);
                          }}
                          options={finalConditions(field.variableType) || []}
                        />
                      )}
                      {!(
                        field.condition === "" ||
                        field.condition === "is empty" ||
                        field.condition === "is not empty"
                      ) &&
                        (["enumeration", "enum", "set", "dropdown", "multidropdown", "picklist"].includes(
                          field.variableType
                        ) ? (
                          <Select
                            showSearch
                            allowClear
                            className="w-[95%]"
                            mode={field.variableType === "multidropdown" ? "multiple" : undefined} // Dynamically set mode
                            value={
                              field.variableType === "multidropdown"
                                ? field.values || []
                                : field.values?.[0] || undefined
                            } // Handle multi/single value
                            placeholder="Select an option..."
                            onChange={(value) => {
                              const temp = { ...conditionsArr };

                              if (field.variableType === "multidropdown") {
                                // Multi-select: Store as an array
                                temp.conditions[parentIdx].conditions[idx].values = value;
                              } else {
                                // Single-select: Store as a single value
                                temp.conditions[parentIdx].conditions[idx].values[0] = value;
                              }

                              setConditionsArr(temp);
                            }}
                            onClear={() => {
                              const temp = { ...conditionsArr };
                              temp.conditions[parentIdx].conditions[idx].values = [];
                              setConditionsArr(temp);
                            }}
                            options={options
                              .find((option) => option.id === field.variable)
                              ?.options?.map((option: any, index: number) => ({
                                label: option.label,
                                value: option?.value || option.id,
                                key: index,
                              }))}
                            optionFilterProp="label"
                          />
                        ) : (
                          <input
                            type={inputTypeFromVariableType(field.variableType)}
                            placeholder="value..."
                            className="w-[95%] rounded-md p-1 px-2 border-gray-200"
                            value={field.values?.[0] || ""}
                            onChange={(e) => {
                              const temp = { ...conditionsArr };
                              temp.conditions[parentIdx].conditions[idx].values[0] = e.target.value;
                              setConditionsArr(temp);
                            }}
                          />
                        ))}
                      {field.condition === "is between" && (
                        <>
                          <p>To:</p>
                          <input
                            type={inputTypeFromVariableType(field.variableType)}
                            placeholder="value 2..."
                            className="w-[95%] rounded-md p-1 px-2 border-gray-200"
                            value={field.values?.[1] || ""}
                            onChange={(e) => {
                              const temp = { ...conditionsArr };
                              temp.conditions[parentIdx].conditions[idx].values[1] = e.target.value;
                              setConditionsArr(temp);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {idx !== 0 && (
                  <button
                    className="ml-auto h-8 w-8 flex items-center justify-center rounded-md"
                    onClick={() => {
                      const temp = { ...conditionsArr };
                      const removedValue = temp.conditions[parentIdx].conditions[idx].variable; // Get the value being removed

                      temp.conditions[parentIdx].conditions.splice(idx, 1); // Remove the condition
                      setConditionsArr(temp);

                      setPayloads((prev: any) => {
                        const updatedProperties = prev.properties.filter((p: string) => p !== removedValue); // Remove from payloads
                        return { ...prev, properties: updatedProperties };
                      });
                    }}
                  >
                    <img src={DeleteIconNew} alt="" />
                  </button>
                )}
              </div>
            ))}
            <button
              className="border w-fit border-black rounded-lg p-2"
              onClick={() => {
                const temp = { ...conditionsArr };
                temp.conditions[parentIdx].conditions.push({
                  variable: "",
                  condition: "",
                  variableType: "",
                  values: [],
                  operator: "AND",
                });
                setConditionsArr(temp);
              }}
            >
              + Add sub-filter
            </button>
          </div>
        ))}
        <button
          className="border w-full border-black rounded p-2 text-left"
          onClick={() => {
            const temp = { ...conditionsArr };
            temp.conditions.push({
              conditions: [
                {
                  variable: "",
                  condition: "",
                  variableType: "",
                  values: [],
                  operator: "AND",
                },
              ],
            });
            setConditionsArr(temp);
          }}
        >
          + Add filter
        </button>
      </div>
    </div>
  );
};

export default CRMfilterComponent;

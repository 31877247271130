import { useState, useEffect } from "react";
import { AiOutlinePlus, AiOutlineArrowLeft } from "react-icons/ai";
import DeleteIconNew from "@/assets/SVGs/DeleteIconNew.svg";
import dayjs from "dayjs";
import { PayloadStructure } from "@/utils/interfaces";
import CustomCronGenerator from "./CustomCronGenerator";
import { HB_SUBSCRIPTION_PROPERTIES } from "../../constants";
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from "@radix-ui/react-accordion";
import { Button, DatePicker, Radio, Select } from "antd";
import { Switch } from "radix-ui";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import LoaderButton from "@/Components/LoaderButtonBlack";
import FilePlusCreate from "@/assets/SVGs/FilePlusCreate";
import "../../styles.css";

type HBfinalStepProps = {
  prev: () => void;
  logo: string;
  payloadStructure: PayloadStructure[];
  payloads: any;
  setPayloads: (payload: any) => void;
  createList: () => Promise<void>;
};

const HBfinalStep = ({ prev, logo, payloadStructure, payloads, setPayloads, createList }: HBfinalStepProps) => {
  const [eventTriggers, setEventTriggers] = useState<string[]>([
    ...payloads.properties.filter((prop: string) =>
      HB_SUBSCRIPTION_PROPERTIES.some((subProp) => subProp.name === prop)
    ),
  ]);
  const [selectedKey, setSelectedKey] = useState<string>("event-triggers");

  // Ensure payloads update before `createList`
  useEffect(() => {
    setPayloads((prev: any) => ({
      ...prev,
      eventTriggers,
      selectedKey,
    }));
  }, [eventTriggers, selectedKey]);

  const addTrigger = () => setEventTriggers((prev) => [...prev, ""]);
  const removeTrigger = (index: number) => setEventTriggers(eventTriggers.filter((_, i) => i !== index));
  const updateTrigger = (index: number, value: string) => {
    const updated = [...eventTriggers];
    updated[index] = value;
    setEventTriggers(updated);
  };

  const getPayloadLayout = (payload: PayloadStructure) => {
    switch (payload.type) {
      case "date":
        const selectedDate = payloads[payload.payloadStructureId] || null;
        return (
          <div key={payload.payloadStructureId} className="flex flex-col items-start gap-2 border-b w-full py-4">
            <h1>
              {payload.name} {payload.required && <span className="text-red-500">*</span>}
            </h1>
            <p className="text-xs text-gray-400">{payload.description}</p>
            <div className="flex items-center gap-4">
              <Radio.Group
                value={selectedDate ? "on" : "never"}
                onChange={(e) =>
                  setPayloads((prev: any) => ({
                    ...prev,
                    [payload.payloadStructureId]: e.target.value === "never" ? null : dayjs().format("YYYY-MM-DD"),
                  }))
                }
              >
                <Radio.Button value="never">Never</Radio.Button>
                <Radio.Button value="on">On</Radio.Button>
              </Radio.Group>

              {selectedDate && (
                <DatePicker
                  format="DD MMMM, YYYY"
                  className="p-1 rounded"
                  allowClear={false}
                  value={dayjs(selectedDate)}
                  onChange={(value) =>
                    setPayloads((prev: any) => ({
                      ...prev,
                      [payload.payloadStructureId]: value?.format("YYYY-MM-DD"),
                    }))
                  }
                />
              )}
            </div>
          </div>
        );

      case "crmFrequency":
        return (
          <div key={payload.payloadStructureId} className="flex flex-col items-start gap-2 w-full">
            <h1>
              {payload.name} {payload.required && <span className="text-red-500">*</span>}
            </h1>
            <p className="text-xs text-gray-400">{payload.description}</p>
            <CustomCronGenerator
              onCronChange={(cron) =>
                setPayloads((prev: any) => ({
                  ...prev,
                  [payload.payloadStructureId]: cron,
                }))
              }
            />
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="p-4 border w-[60%] mx-auto mt-10 flex flex-col items-center gap-8 rounded-lg">
      <div className="flex items-center justify-between w-full mb-4">
        <h2 className="text-xl flex items-center gap-2">
          <img src={logo} alt="logo" className="h-6 w-6" />
          How often would you like to refresh this list?
        </h2>
        <Button className="border p-2 rounded" onClick={prev}>
          <AiOutlineArrowLeft />
        </Button>
      </div>

      <Accordion
        type="single"
        collapsible
        className="w-full space-y-3"
        value={selectedKey}
        onValueChange={setSelectedKey}
      >
        <AccordionItem value="event-triggers" className="border border-black rounded">
          <AccordionTrigger className="p-2 bg-gray-100 rounded w-full text-left flex flex-col justify-between items-center">
            <div className="flex w-full justify-between items-center">
              Event triggers {selectedKey === "event-triggers" ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </div>
            {selectedKey === "event-triggers" && (
              <p className="text-xs w-full text-gray-500">
                We'll automatically add leads when conditions in your event triggers are met.
              </p>
            )}
          </AccordionTrigger>
          <AccordionContent className="p-4 bg-white rounded w-[70%] space-y-3">
            <div className="flex items-center justify-between">
              <p>Property changes</p>
              <Button onClick={addTrigger} className="flex items-center gap-2">
                <AiOutlinePlus /> Add Property
              </Button>
            </div>

            {eventTriggers.map((property, index) => (
              <div key={index} className="flex gap-3 items-center justify-between border p-2 rounded">
                WHEN
                <Select
                  showSearch
                  className="w-[95%]"
                  value={HB_SUBSCRIPTION_PROPERTIES.find((option) => option.name === property)?.label}
                  onChange={(value) => updateTrigger(index, value)}
                  options={HB_SUBSCRIPTION_PROPERTIES.filter((subProp) => !eventTriggers.includes(subProp.name))}
                  fieldNames={{ label: "label", value: "name" }}
                  optionFilterProp="label"
                />
                {["hubspot_owner_id", "contact.creation"].includes(property) ? "added/changes" : "changes"}
                <button onClick={() => removeTrigger(index)}>
                  <img src={DeleteIconNew} alt="delete" />
                </button>
              </div>
            ))}
          </AccordionContent>
        </AccordionItem>

        <div className="w-full flex items-center justify-center gap-8">
          <span className="w-full h-[0.05rem] bg-gray-300" />
          <span className="text-xl">OR</span>
          <span className="w-full h-[0.05rem] bg-gray-300" />
        </div>

        <AccordionItem value="schedule" className="border border-black rounded">
          <AccordionTrigger className="p-2 bg-gray-100 rounded w-full text-left flex justify-between items-center">
            On a schedule {selectedKey === "schedule" ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </AccordionTrigger>
          <AccordionContent className="p-4 bg-white rounded divide-y-2">
            {payloadStructure.map((payload) => getPayloadLayout(payload))}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <div className="w-full flex flex-col items-start gap-2 p-2 border border-black rounded">
        <div className="flex items-center gap-2">
          <h1>
            {payloadStructure.find((payload: any) => payload.payloadStructureId === "pullExisting")?.name ||
              "Default Name"}
          </h1>
          <Switch.Root
            defaultChecked={payloads?.pullExisting || true}
            // checked={payloads?.pullExisting || true}
            onCheckedChange={(value) => {
              setPayloads((prev: any) => ({
                ...prev,
                pullExisting: value,
              }));
            }}
            className="w-14 h-6 flex items-center bg-gray-300 data-[state=checked]:bg-[#D9CFFF] rounded relative transition-all"
          >
            <Switch.Thumb className="w-7 h-5 rounded bg-white transition-transform data-[state=checked]:translate-x-6 translate-x-1" />
          </Switch.Root>
        </div>
        <p className="text-xs text-gray-400">
          {payloadStructure.find((payload: any) => payload.payloadStructureId === "pullExisting")?.description}
        </p>
      </div>

      <div className="w-full">
        <LoaderButton
          text="Create List"
          loaderText="Creating List..."
          btnClasses="bg-black text-white px-14"
          onClickCallback={createList}
          error=""
          disabled={false}
          IconComponent={FilePlusCreate}
        />
      </div>
    </div>
  );
};

export default HBfinalStep;

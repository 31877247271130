import { Collapse, ConfigProvider } from "antd";
import BlueVarLogo from "../../../assets/SVGs/BlueVarLogo.svg";
import YellowVarLogo from "../../../assets/SVGs/YellowVarLogo.svg";
type Props = {
  variables: any[];
  addVariable: (variable: any, actionName: string) => void;
};

const DropdownVariables = ({ variables, addVariable }: Props) => {
  const variableItems: any[] = variables.map((variable: any, idx: number) => {
    return {
      key: idx.toString(),
      label: (
        <div className="flex gap-2">
          <img src={variable.logo} alt={variable.name} className="w-6 h-6" />
          <p className="font-medium">{variable.name}</p>
        </div>
      ),
      children: (
        <div className="flex flex-wrap gap-2">
          {variable.variables.map((value: any, index: number) => (
            <button
              key={index}
              className={`px-2 py-1 flex items-center gap-2 rounded-lg ${
                variable.type === "input" ? "bg-[#FFF3D4] text-[#AA7102]" : "bg-[#E3EDFF] text-[#0047C6]"
              }`}
              onClick={() => {
                addVariable({ ...value, logo: variable.logo }, variable.name);
              }}
            >
              <img src={variable.type === "input" ? YellowVarLogo : BlueVarLogo} alt={value.name} className="w-4 h-4" />
              {value.name}
            </button>
          ))}
        </div>
      ),
    };
  });
  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            headerPadding: "5px !important",
            contentPadding: "5px !important",
          },
        },
      }}
    >
      <Collapse
        rootClassName="!font-Lausanne mt-2"
        ghost
        items={variableItems}
        expandIconPosition="end"
        destroyInactivePanel
      />
    </ConfigProvider>
  );
};

export default DropdownVariables;

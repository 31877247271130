import { Button, ConfigProvider, Divider, Drawer, Input, message, Select, Switch, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { createListConnection, updateListConnection } from "../apis";
import { useParams } from "react-router-dom";
import SaveIconBlack from "@Assets/SVGs/SaveIconBlack.svg";
import { AddInputColumnsModal } from "@/Components/Workflow/ActionModals/ModalComponents/AddInputColumnsModal";
import { ResponseConfiguration } from "@/utils/interfaces";
import { FiPlus } from "react-icons/fi";
import { getUserWorkflows, getWorkflowDetailsWithActions, publishDraft2 } from "@/utils/apis";
import { useWorkflow } from "@/contexts/WorkflowContext";
import PlusIconBlack from "@Assets/SVGs/PlusIconBlack.svg";
import { LuChevronDown } from "react-icons/lu";
import moment from "moment";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import { v4 as uuidv4 } from "uuid";
import { mapInputsToComunm } from "@/Components/Workflow/utils";

type Props = {
  update: string | undefined;
  loading: boolean;
  open: boolean;
  removeMapping: boolean;
  close: () => void;
  existingConnections: any[];
  workflows: any[];
  responseStructure: any[];
  addConnection: (connection: any) => void;
  setWorkflows: (workflows: any) => void;
  updateExistingConnection: (connection: any) => void;
  selectedWorkflow: any;
  setSelectedWorkflow: (workflow: any) => void;
  preCreateSyncFunction?: () => Promise<void | string>;
  postCreateSyncFunction?: (id: string) => Promise<void>;
  columnsDefination?: any;
};

const Connection = ({
  open,
  update,
  close,
  existingConnections,
  workflows,
  removeMapping,
  loading,
  responseStructure,
  addConnection,
  updateExistingConnection,
  selectedWorkflow,
  setSelectedWorkflow,
  setWorkflows,
  preCreateSyncFunction,
  postCreateSyncFunction,
  columnsDefination,
}: Props) => {
  const selectedConnection = update
    ? existingConnections.find((connection) => connection.workflow_id === update)
    : undefined;
  const { id } = useParams();
  const [inputs, setInputs] = useState({ loading: false, fields: [] });
  const [mapping, setMapping] = useState<any>(selectedConnection ? selectedConnection.payload_map : {});
  const [existingLeads, setExistingLeads] = useState(true);
  const [openAddInputModal, setOpenAddInputModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCreatingWorkflow, setIsCreatingWorkflow] = useState(false);
  const [responses, setResponses] = useState<ResponseConfiguration[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectValue, setSelectValue] = useState<string | undefined>(undefined);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isSavingSync, setIsSavingSync] = useState(false);

  const [showCreateWorkflowInput, setShowCreateWorkflowInput] = useState(false);
  const [newWorkflowName, setNewWorkflowName] = useState("");

  const { saveAnotherWorkflowActions, createNewWorkflow } = useWorkflow();

  const handleCreateWorkflow = async () => {
    setIsCreatingWorkflow(true);
    if (!newWorkflowName.trim()) {
      message.error("Please enter a workflow name");
      return;
    }

    try {
      const isV2 = true;
      const id = await createNewWorkflow(newWorkflowName, "csv_or_webhook", "root", {}, isV2);
      const flows = await getUserWorkflows(true);
      const firebase = flows.workflows;
      const supabase = flows.workflowsV2;
      const allWorkflows: any[] = (firebase?.workflows || []).concat(supabase?.workflows || []).map((workflow: any) => {
        if ((firebase?.workflows || []).find((w: any) => w.id === workflow.id)) workflow.supabase = false;
        else workflow.supabase = true;
        return workflow;
      });
      allWorkflows.sort((a: any, b: any) => {
        const aDate = moment.utc(a.createdAt);
        const bDate = moment.utc(b.createdAt);

        if (aDate.isAfter(bDate)) return -1;
        if (aDate.isBefore(bDate)) return 1;
        return 0;
      });
      setWorkflows(allWorkflows);
      setSelectedWorkflow(allWorkflows.find((w: any) => w.id === id));
      setSelectValue(id);
      setShowCreateWorkflowInput(false);
      setNewWorkflowName("");
      setDropdownVisible(false);
      message.success("Workflow created successfully!");
    } catch (error) {
      console.error("Error creating workflow:", error);
      message.error("Failed to create workflow. Please try again.");
    } finally {
      setIsCreatingWorkflow(false);
    }
  };

  useEffect(() => {
    setSelectedWorkflow(workflows.find((workflow) => workflow.id === update) || undefined);
    const connection = existingConnections.find((connection) => connection.workflow_id === update);
    setMapping(connection?.payload_map || {});
  }, [update]);

  useEffect(() => {
    selectedWorkflow && setSelectValue(selectedWorkflow.id);
    const actions = selectedWorkflow?.actions || [];
    const inputs = actions.find((action: any) => action.id === "id1")?.responseConfiguration || [];
    if (selectedWorkflow && !inputs?.length && columnsDefination?.length) {
      const mappings: any = {};
      setInputs({
        loading: false,
        fields: columnsDefination?.map((column: any) => {
          const uid = uuidv4();
          mappings[uid] = column?.field;
          return {
            responseId: uid,
            name: column.headerName,
            type: getExpectedTypeFromName(column.headerName),
            description: "",
          };
        }),
      });
      setMapping(mappings);
    } else {
      const mappings = mapInputsToComunm(inputs, responseStructure);
      setInputs({ loading: false, fields: inputs });
      setMapping(mappings);
    }
  }, [selectedWorkflow]);

  useEffect(() => {
    removeMapping && setMapping({});
  }, [removeMapping]);

  const updateConnection = async () => {
    let watcherId = id;
    if (preCreateSyncFunction) {
      const id = await preCreateSyncFunction();
      if (id) watcherId = id;
    }
    if (!selectedWorkflow || !watcherId) return;
    setIsSavingSync(true);
    try {
      let dashes = selectedWorkflow.id.split("-");
      let isV2 = dashes.length === 5 ? true : false;

      const responseData = await getWorkflowDetailsWithActions(selectedWorkflow.id, isV2);
      const action = responseData.response.actions.find((action: any) => action.id === "id1");

      action.responseConfiguration = inputs.fields;

      await saveAnotherWorkflowActions(selectedWorkflow.id || "", [action], isV2);
      await publishDraft2(selectedWorkflow.id, isV2);

      if (!update) {
        const newConn = await createListConnection(watcherId, selectedWorkflow.id, mapping, existingLeads);
        addConnection(newConn);
        message.success("Sync created successfully.");
      } else {
        const updated = await updateListConnection(watcherId, selectedWorkflow.id, mapping);
        updateExistingConnection(updated);
        message.success("Sync updated successfully.");
      }
    } catch (error) {
      console.error(error);
      message.error(`Failed to ${update ? "update" : "create"} sync. Please try again.`);
    } finally {
      setIsSavingSync(false);
      if (postCreateSyncFunction) {
        postCreateSyncFunction(watcherId);
      }
    }
    close();
  };

  const handleDeleteSelectedRows = async () => {
    setIsDeleting(true);

    try {
      const selectedWorkflowId = selectedWorkflow.id;
      let dashes = selectedWorkflowId.split("-");
      let isV2 = dashes.length === 5 ? true : false;

      const responseData = await getWorkflowDetailsWithActions(selectedWorkflowId, isV2);
      const action1 = responseData.response.actions.find((action: any) => action.id === "id1");

      action1.responseConfiguration = action1.responseConfiguration.filter(
        (field: any) => !selectedRowKeys.includes(field.responseId)
      );

      await saveAnotherWorkflowActions(selectedWorkflowId || "", [action1], isV2);
      await publishDraft2(selectedWorkflowId, isV2);

      const newFields = inputs.fields.filter((field: any) => !selectedRowKeys.includes(field.responseId));
      setInputs({ ...inputs, fields: newFields });
      setSelectedRowKeys([]);

      message.success("Selected fields deleted successfully!");
    } catch (error) {
      console.error("Error deleting fields:", error);
      message.error("Failed to delete selected fields. Please try again.");
    } finally {
      setIsDeleting(false);
    }
  };

  const TABLE_COLUMNS: any[] = [
    {
      title: "Fields from Floq",
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (name: string, record: any) => (
        <input
          className="max-w-[160px] h-4 px-2 py-4 rounded border-none focus:outline-none focus:ring-0 focus:border-transparent"
          type="text"
          name="name"
          value={name}
          onChange={(e) => {
            setInputs((prevInputs: any) => ({
              ...prevInputs,
              fields: prevInputs.fields.map((field: any) => {
                if (field.responseId === record.key) {
                  return {
                    ...field,
                    name: e.target.value,
                    type: getExpectedTypeFromName(e.target.value),
                  };
                }
                return field;
              }),
            }));
          }}
          placeholder="Name of the Field"
        />
      ),
    },
    {
      title: "Fields from this signal",
      dataIndex: "field",
      key: "field",
      width: 250,
    },
  ];

  const tableHeaderStyle = {
    borderBlock: "0.5px solid #333333",
    paddingBlock: "8px",
    background: "#FAFAFA",
  };

  const saveOutputStructure = async () => {
    return;
  };

  const TYPE_MAPS = {
    imageUrl: ["image"],
    email: ["email", "mail", "e-mail"],
    url: ["url", "link", "website", "domain", "linkedin"],
    number: ["num", "quantity", "amount", "count", "total", "no.", "no ", " no", "integer", "float"],
    currency: ["currency", "price", "revenue", "cost"],
    date: ["date"],
  };

  const getExpectedTypeFromName = (name: string) => {
    const lowerCaseName = name.toLowerCase();
    for (const type in TYPE_MAPS)
      if (TYPE_MAPS[type as keyof typeof TYPE_MAPS].some((keyword) => lowerCaseName.includes(keyword))) return type;
    return "string";
  };

  const tagRender = (props: any) => {
    const { label, closable, onClose } = props;
    return (
      <Tag
        closable={closable}
        onClose={onClose}
        className="bg-[#FBE7FF] text-[#7B0094] tracking-wider uppercase border-none m-1"
      >
        {label}
      </Tag>
    );
  };

  const dropdownFooter = (
    <div className="bg-white border-t-[0.5px] border-[#333333] mt-2">
      {showCreateWorkflowInput ? (
        <div className="flex items-center gap-2 p-2 py-3 animate-slideRightAndFade">
          <Input
            placeholder="Enter workflow name"
            value={newWorkflowName}
            onChange={(e) => setNewWorkflowName(e.target.value)}
            className="flex-1 h-[50px] rounded-[3px]"
          />
          <button
            className={`text-white bg-primaryBlack text-base font-bold rounded-[5px] px-4 h-[44px] flex items-center gap-2 transition ${
              isCreatingWorkflow ? "opacity-50 cursor-not-allowed" : "hover:bg-black"
            }`}
            onClick={handleCreateWorkflow}
            disabled={isCreatingWorkflow}
          >
            {isCreatingWorkflow ? (
              <>
                Creating <SpinnerStatus />
              </>
            ) : (
              <>
                Create <FiPlus className="text-[#E5E4FF] text-lg" />
              </>
            )}
          </button>
        </div>
      ) : (
        <button
          className="flex items-center font-medium gap-2 p-2 py-3 hover:text-[#333333] w-full"
          onClick={() => {
            setShowCreateWorkflowInput(true);
          }}
        >
          <img src={PlusIconBlack} alt="add-icon" className="h-5 w-5" />
          Create a new workflow
        </button>
      )}
    </div>
  );

  const handleAddInput = () => {
    if (!selectedWorkflow?.id) {
      message.error("Please select a workflow to add the new input.");
      return;
    }

    const newInput = {
      responseId: uuidv4(),
      name: "",
      type: "string",
      description: "",
    };

    setInputs((prevInputs: any) => ({
      ...prevInputs,
      fields: [...prevInputs.fields, newInput],
    }));

    message.success("New input added successfully!");
  };

  const tableFooter = (
    <button className="text-[#333333] text-base font-medium flex gap-2 items-center" onClick={handleAddInput}>
      <FiPlus className="text-xl" />
      Add input in Floq
    </button>
  );

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
          Checkbox: {
            colorPrimary: "#333333",
            colorBgContainer: "#F3F4F6",
            colorBorder: "#333333",
            colorPrimaryHover: "#333333",
          },
        },
      }}
    >
      <Drawer
        open={open}
        onClose={() => {
          // setSelectedRowKeys([]);
          // setSelectValue(undefined);
          close();
        }}
        closable={!isCreatingWorkflow}
        width="33%"
        className="!font-favorit !p-0 rounded-lg"
        styles={{
          header: {
            height: "50px",
            borderBottomColor: "#333333",
            borderBottomWidth: "0.5px",
          },
          body: {
            paddingInline: 0,
          },
        }}
        footer={
          <div className="w-full flex flex-col gap-4 bg-white">
            <button
              className={`flex items-center justify-center gap-2 text-white w-full font-semibold text-md rounded-[7px] p-2 py-3 mt-auto ${
                loading || !selectedWorkflow ? "cursor-not-allowed bg-[#474747]" : "bg-[#333333] cursor-pointer"
              }`}
              onClick={updateConnection}
              disabled={loading || !selectedWorkflow || isSavingSync}
            >
              {loading || isSavingSync ? (
                <>
                  Saving... <SpinnerStatus />
                </>
              ) : (
                <>
                  Save sync <img src={SaveIconBlack} alt="save-icon" className="h-5 w-5" />
                </>
              )}
            </button>
          </div>
        }
        title={<p className="text-xl font-medium">Setup sync with Floq</p>}
      >
        <div className="bg-white overflow-x-hidden">
          <div className="text-primaryBlack px-6">
            <div className="flex items-center gap-2">
              <span className="font-medium inline-flex items-center justify-center text-xs w-4 h-4 rounded-full bg-[#FFFFFF] ring-[1.5px] ring-primaryBlack">
                1
              </span>
              <h3 className="font-medium text-xl">Select or create a new workflow</h3>
            </div>
            <p className="font-light text-sm">
              Select a workflow (Floq) you would like to push your list’s data to or create a new one.
            </p>
            <div className="mt-4">
              <Select
                showSearch
                placeholder="Search or select from the list below"
                optionFilterProp="children"
                open={dropdownVisible}
                value={selectValue}
                onChange={(value) => {
                  const selectedWorkflow = workflows.find((workflow) => workflow.id === value);
                  setSelectedWorkflow(selectedWorkflow);
                  setSelectValue(value);
                }}
                onDropdownVisibleChange={(visible) => {
                  setDropdownVisible(visible);
                  if (!visible) {
                    setShowCreateWorkflowInput(false);
                  }
                }}
                onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                  e.target.style.border = "none";
                  e.target.style.outline = "none";
                  e.target.style.boxShadow = "none";
                }}
                filterOption={(input, option) => {
                  const workflow = workflows.find((workflow) => workflow.id === option?.key);
                  const name =
                    workflow?.name || workflow?.publishedWorkflowConfig?.name || workflow?.draftWorkflowConfig?.name;
                  return name?.toLowerCase().includes(input.toLowerCase());
                }}
                className="customSelectBorder relative rounded-[5px] flex items-center h-[50px] w-full bg-[#F3F4F6] text-[#8D8D8D]"
                suffixIcon={<LuChevronDown className="text-[#333333] text-xl mr-1" />}
                dropdownStyle={{
                  borderRadius: "10px",
                  border: "0.5px solid #333333",
                  boxShadow: "0 0 0 4px #DADADA",
                }}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    {dropdownFooter}
                  </>
                )}
              >
                {workflows?.map((workflow) => (
                  <Select.Option key={workflow.id} value={workflow.id}>
                    {workflow.name || workflow.publishedWorkflowConfig?.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
          <Divider className="border-gray-300" />
          <div className="flex flex-col w-full gap-3 text-primaryBlack">
            <div className="flex flex-col w-full gap-1 px-6 mb-2">
              <div className="flex items-center gap-2">
                <span className="font-medium inline-flex items-center justify-center text-xs w-4 h-4 rounded-full bg-[#FFFFFF] ring-[1.5px] ring-primaryBlack">
                  2
                </span>
                <h3 className="font-medium text-xl">Column mapping</h3>
              </div>
              <p className="text-sm font-light">
                The input fields from the workflow chosen in
                <span className="inline-flex mx-2 items-center justify-center text-xs w-4 h-4 rounded-full bg-[#E4FFEE] text-[#025F26] ring-1 ring-[#025F26]">
                  1
                </span>
                will appear here. Match your signal's outputs to the fields you want to send to the selected workflow.
              </p>
            </div>
            {selectedRowKeys.length > 0 && (
              <Button
                type="primary"
                danger
                onClick={handleDeleteSelectedRows}
                className="mb-5 rounded-none"
                loading={isDeleting}
                disabled={isDeleting}
              >
                {`${isDeleting ? "Deleting" : "Delete"} Selected ${selectedRowKeys.length > 1 ? "Rows" : "Row"} (${selectedRowKeys.length})`}
              </Button>
            )}
            <Table
              dataSource={inputs.fields.map((input: any) => {
                return {
                  key: input.responseId,
                  name: input.name,
                  field: (
                    <div className="flex items-center gap-2">
                      <Select
                        mode="multiple"
                        tagRender={tagRender}
                        maxTagTextLength={16}
                        allowClear
                        className="w-full rounded-md"
                        showSearch
                        placeholder="Select for input"
                        optionFilterProp="label"
                        defaultValue={mapping[input.responseId]}
                        options={responseStructure.map((structure) => ({
                          label: structure.name,
                          value: structure.responseStructureId || structure.name,
                        }))}
                        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                          e.target.style.border = "none";
                          e.target.style.outline = "none";
                          e.target.style.boxShadow = "none";
                        }}
                        dropdownStyle={{
                          borderRadius: "10px",
                          border: "0.5px solid #333333",
                          boxShadow: "0 0 0 3.5px #dadada",
                        }}
                        onChange={(value) => {
                          setMapping((prev: any) => ({ ...prev, [input.responseId]: value }));
                        }}
                      />
                      <button
                        className="flex items-center gap-1 px-2 py-1 text-red-500 hover:text-red-700 transition-colors"
                        onClick={(e) => {
                          e.stopPropagation();
                          const newFields = inputs.fields.filter((field: any) => field.responseId !== input.responseId);
                          setInputs({ ...inputs, fields: newFields });
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M3 6h18"></path>
                          <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
                          <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
                        </svg>
                      </button>
                    </div>
                  ),
                };
              })}
              columns={TABLE_COLUMNS.map((column) => ({
                ...column,
                title: <div className="font-favorit text-[#828282] font-normal text-sm">{column.title}</div>,
                className: "font-favorit",
              }))}
              className="font-favorit customTableFooter"
              components={{
                header: {
                  cell: (props: any) => (
                    <th
                      {...props}
                      style={{
                        ...tableHeaderStyle,
                        ...props.style,
                      }}
                    />
                  ),
                },
              }}
              pagination={false}
              footer={() => tableFooter}
            />
          </div>
          <Divider className="border-gray-300" />
          <div className="flex flex-col w-full gap-3 px-3">
            <div className="flex flex-col w-full gap-1">
              <p className="font-medium text-lg">Push existing leads</p>
              <p className="text-sm font-light">
                This will add and trigger the workflow for all the leads currently in the list.
              </p>
            </div>
            <Switch
              className="w-fit ml-1 mb-1"
              defaultChecked={existingLeads}
              onChange={(checked) => setExistingLeads(checked)}
            />
          </div>
          {/* )} */}
        </div>
      </Drawer>
      <AddInputColumnsModal
        modal={openAddInputModal}
        responses={responses}
        selectedWorkflow={selectedWorkflow?.id}
        inputs={inputs}
        setInputs={setInputs}
        setModal={setOpenAddInputModal}
        setResponses={setResponses}
        saveOutputStructure={saveOutputStructure}
      />
    </ConfigProvider>
  );
};

export default Connection;

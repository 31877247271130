import { Handle, NodeProps, Position } from "@xyflow/react";
import DeleteAction from "@/assets/SVGs/DeleteAction";
import { useWorkflow } from "@/contexts/WorkflowContext";
import { message, Popconfirm } from "antd";
import { useParams } from "react-router-dom";
import NewConditionalModal from "../../ActionModals/NewConditionalModal";
import { useState } from "react";
import { NextActionConfiguration } from "@/utils/interfaces";
import PathSVG from "@/assets/SVGs/buildTab/path.svg";
import IfSVG from "@/assets/SVGs/buildTab/If.svg";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

export function PathConditionNode({ data }: NodeProps) {
  const { id: wfId } = useParams();
  const { actions, setActions, saveWorkflowActions } = useWorkflow();
  const [conditionalModal, setConditionalModal] = useState(false);
  const [editing, setEditing] = useState(false);
  const NON_EDITABLE = ["if", "else"];

  const deletePath = async () => {
    const updatedActions = [...actions];
    const actId = data?.actId;
    const currentIdx = data?.idx;
    if (currentIdx === undefined) return message.error("Try again");
    if (!actId) return message.error("Try again");

    const sourceIndex = updatedActions.findIndex((act) => act.id === actId);
    const sourceAction = updatedActions[sourceIndex];

    const payload = sourceAction.payloadConfiguration || [];
    const pathPayload = payload.find((config: any) => config.payloadStructureId === "path_conditions");

    pathPayload.inputString.splice(currentIdx, 1);
    sourceAction.payloadConfiguration = payload;
    sourceAction.nextAction.splice(currentIdx, 1);
    setActions(updatedActions);
    await saveWorkflowActions(wfId || "");
  };

  const updatePathName = (name: string) => {
    const updatedActions = [...actions];
    const actId = data?.actId;
    const currentIdx = data?.idx as number;
    if (currentIdx === undefined || currentIdx === null) return message.error("Try again");
    if (!actId) return message.error("Try again");

    const sourceIndex = updatedActions.findIndex((act) => act.id === actId);
    const sourceAction = updatedActions[sourceIndex];

    const payload = sourceAction.payloadConfiguration || [];
    const pathPayload = payload.find((config: any) => config.payloadStructureId === "path_conditions");

    pathPayload.inputString[currentIdx].name = name;
    sourceAction.payloadConfiguration = payload;
    setActions(updatedActions);
  };

  return (
    <>
      <div className="nodrag w-[36vw]">
        <Handle type="target" position={Position.Top} isConnectable={true} />
        <div className="w-2/5 mx-auto px-4 py-1 bg-[#F6F8FF] border border-gray-400 flex justify-between items-center rounded-lg shadow-[0_0_0_5px_#E5ECFF]">
          <p
            id="pathName"
            suppressContentEditableWarning
            contentEditable={NON_EDITABLE.includes(data?.name as string) ? false : editing}
            onDoubleClick={() => {
              setEditing(true);
              const p = document.getElementById("pathName");
              if (!p) return;
              setTimeout(function () {
                const range = document.createRange();
                const selection = window.getSelection();
                range.selectNodeContents(p);
                range.collapse(false); // <-- Set the cursor at the end of the selection
                selection?.removeAllRanges();
                selection?.addRange(range);
                p.focus();
              }, 0);
            }}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                e.preventDefault();
                e.target.blur();
              }
            }}
            onBlur={async (e) => {
              if (!e.target.textContent) return;
              updatePathName(e.target.textContent);
              setEditing(false);
            }}
            className={`font-medium text-headings cursor-text ${!NON_EDITABLE.includes(data?.name as string) && editing && "border border-black"}`}
          >
            {data?.name as string}
          </p>
          <button
            className="w-5"
            onClick={() => {
              if (typeof data.hidePath === "function") data.hidePath();
            }}
          >
            {!data?.expanded ? (
              <BiChevronDown className="text-headings" size={25} />
            ) : (
              <BiChevronUp className="text-headings" size={25} />
            )}
          </button>
        </div>
        {data?.name !== "else" && (
          <div
            className={`w-full mt-5 mx-auto p-1 transition-opacity duration-300 py-3 px-4 md:p-3 border rounded-lg relative z-10 shadow-[0_0_0_5px_#E5ECFF] cursor-pointer ${
              data.error
                ? "border-red-500 bg-red-100"
                : "border-gray-400 bg-white hover:border-white hover:bg-[#F6F8FF]"
            }`}
          >
            {!NON_EDITABLE.includes(data?.name as string) && (
              <div
                className="absolute group peer top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full z-20 px-16 box-content"
                onClick={() => setConditionalModal(true)}
              >
                <div className="flex items-col space-y-2 absolute top-1/2 transform -translate-y-1/2 duration-200 right-1 opacity-0 group-hover:-right-0  translate-x-[-1.5rem] group-hover:opacity-100">
                  <Popconfirm
                    title="Delete the path?"
                    description={() => (
                      <div>
                        <p>Are you sure?</p>
                        <p>This will delete all the actions associated with this path.</p>
                      </div>
                    )}
                    okText="Yes"
                    cancelText="No"
                    onPopupClick={(e) => e.stopPropagation()}
                    onConfirm={async (e) => {
                      e?.stopPropagation();
                      await deletePath();
                    }}
                  >
                    <button
                      className="group focus:outline-none flex select-none px-3 py-2 font-semibold focus:ring-purple-500 border bg-white text-purple-800 hover:bg-gray-50 hover:border-purple-600 rounded-full !h-8 !w-8 items-center justify-center !p-0 border-grey-500 ring-offset-2 focus:ring-2"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <DeleteAction />
                    </button>
                  </Popconfirm>
                </div>
              </div>
            )}
            <div
              className="flex justify-start items-start sm:items-center action-item-card gap-4"
              onClick={() => setConditionalModal(true)}
            >
              <div className="h-12 w-12 relative flex justify-center items-center rounded-full flex-shrink-0 ">
                {data.name === "if" ? <img src={IfSVG} alt="path" /> : <img src={PathSVG} alt="path" />}
              </div>

              <div className="flex flex-col justify-center items-start">
                <p className="text-xs text-headings font-light">Flow Basics</p>
                <label className="text-grey-900 text-lg text-headings font-semibold select-none pointer-events-none whitespace-no-wrap w-full break-words line-clamp-2">
                  {data.name as string} condition
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
      <Handle type="source" position={Position.Bottom} isConnectable={true} id="a" />
      <NewConditionalModal
        source={data?.actId as string}
        idx={data?.idx as number}
        nextActionConfiguration={data?.payload as NextActionConfiguration}
        modal={conditionalModal}
        setModal={setConditionalModal}
        variables={data?.variables as any[]}
        nameEditable={!NON_EDITABLE.includes(data?.name as string)}
      />
    </>
  );
}

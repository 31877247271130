import { AUTOFILL_KEYWORDS, setStateType, TEMPLATE_TIPTAP_VARIABLE_VALUE } from "../../../utils/constants";
import { useCallback, useEffect, useState } from "react";
import { useWorkflow } from "../../../contexts/WorkflowContext";
import { Action, PayloadConfiguration, ResponseConfiguration, ResponseStructure } from "../../../utils/interfaces";
import BlueVarLogo from "../../../assets/SVGs/BlueVarLogo.svg";
import One from "../../../assets/SVGs/One.svg";
import GreenOne from "../../../assets/SVGs/GreenOne.svg";
import Two from "../../../assets/SVGs/Two.svg";
import YellowVarLogo from "../../../assets/SVGs/YellowVarLogo.svg";
import { ConfigProvider, Divider, Drawer, Select, Skeleton, Switch, notification } from "antd";
import { FaRegEdit } from "react-icons/fa";
import { useLocation, useParams } from "react-router-dom";
import { getUserWorkflows, getWorkflowDetailsWithActions, publishDraft2 } from "@/utils/apis";
import { CreateWorkflowModal } from "./ModalComponents/CreateWorkflowModal";
import { AddInputColumnsModal } from "./ModalComponents/AddInputColumnsModal";
import InputColumnsBox from "./ModalComponents/InputColumnsBox";
import moment from "moment";
import RunConditionNew from "./ModalComponents/RunConditionNew";
import { useRunOnFloq } from "@/contexts/RunOnFloqContext";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

type Props = {
  action: Action;
  modal: boolean;
  setModal: setStateType<boolean>;
  payloadConfiguration: PayloadConfiguration[];
  variables: any[];
};

const WORKFLOW_STRUCTURE_ID = "workflow";
const INPUT_STRUCTURE_ID = "variables";
const LIST_STRUCTURE_ID = "listId_floq";

const NoInputsMessage = () => (
  <div className="flex flex-col items-center justify-center p-4 text-center bg-gray-50 rounded-lg border border-gray-200">
    <p className="text-gray-600 mb-2">The selected workflow has no input fields configured.</p>
    <p className="text-sm text-gray-500">Please configure input fields in the target workflow first.</p>
  </div>
);

const FloqPushModal = ({ action, modal, setModal, payloadConfiguration, variables }: Props) => {
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentWorkflow, setCurrentWorkflow] = useState<string>("");
  const [workflows, setWorkflows] = useState<{
    loading: boolean;
    workflows: any[];
  }>({
    loading: false,
    workflows: [],
  });
  const [initialWorkflow, setInitialWorkflow] = useState<any>(
    payloadConfiguration.find((input) => input.payloadStructureId === WORKFLOW_STRUCTURE_ID)?.inputString || null
  );
  const [initialList, setInitialList] = useState<any>(
    payloadConfiguration.find((input) => input.payloadStructureId === LIST_STRUCTURE_ID)?.inputString || ""
  );
  const [selectedWorkflow, setSelectedWorkflow] = useState<any>(null);
  const [selectedWorkflowName, setSelectedWorkflowName] = useState<string>("");
  const [selectedWorkflowUrl, setSelectedWorkflowUrl] = useState<string>("");

  const [selectedList, setSelectedList] = useState<any>("");
  // input fields of selected workflow
  const [inputs, setInputs] = useState<{
    loading: boolean;
    fields: any[];
  }>({
    loading: false,
    fields: [],
  });
  const [payloads, setPayloads] = useState<PayloadConfiguration[]>(structuredClone(payloadConfiguration));
  const [runCondition, setRunCondition] = useState(action.runCondition);
  const { updatePayloadConfig, saveWorkflowActions, updateActionName, setPublishWarning, saveAnotherWorkflowActions } =
    useWorkflow();
  const [changeName, setChangeName] = useState(false);
  const [webhookError, setWebhookError] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const { collapseConfig, setCollapseConfig } = useRunOnFloq();
  const [drawerWidth, setDrawerWidth] = useState(collapseConfig ? "3rem" : "30%");

  /**
   * @todo add folder logic while creating a new workflow
   */
  const [createWorkflowParams, setCreateWorkflowParams] = useState<{
    name: string;
    type: string;
    // folderId: string;
  }>({
    name: "",
    type: "v2",
    // folderId: "root",
  });
  const [responses, setResponses] = useState<ResponseConfiguration[]>([]);
  const { id } = useParams();

  const location = useLocation();

  const createSelectedWorkflowUrl = () => {
    const urlSearchParams = new URLSearchParams(location.search);
    let newUrl = `/workflow/${selectedWorkflow}?action=build`;
    if (urlSearchParams.has("v2") && urlSearchParams.get("v2") === "true") newUrl += `&v2=true`;
    setSelectedWorkflowUrl(newUrl);
  };

  const initializeWorkflows = async () => {
    setWorkflows({ ...workflows, loading: true });
    let totalWorkflows: any[] = [];
    try {
      const data = await getUserWorkflows();
      const firebase = data.workflows;
      const supabase = data.workflowsV2;

      totalWorkflows = (firebase?.workflows || [])
        .concat(supabase?.workflows || [])
        .filter((workflow: any) => workflow.id !== id)
        .map((workflow: any) => {
          if ((firebase?.workflows || []).find((w: any) => w.id === workflow.id)) workflow.supabase = false;
          else workflow.supabase = true;
          return workflow;
        });

      totalWorkflows.sort((a: any, b: any) => {
        const aDate = moment.utc(a.createdAt);
        const bDate = moment.utc(b.createdAt);

        if (aDate.isAfter(bDate)) return -1;
        if (aDate.isBefore(bDate)) return 1;
        return 0;
      });

      // Find and set the current workflow
      const currentWorkflow = (firebase?.workflows || [])
        .concat(supabase?.workflows || [])
        .find((workflow: any) => workflow.id === id);

      setCurrentWorkflow(currentWorkflow.name);
    } catch (error) {
      setWorkflows({ loading: false, workflows: [] });
    } finally {
      setWorkflows({ loading: false, workflows: totalWorkflows });
    }
  };

  // here get the workflow details for the selected workflow from "createWorkflowModal"  and set its name in the dropdown
  /**
   * @description This useEffect will run when the selectedWorkflow is changed and it will fetch the details of the selected workflow like input columns
   * and set the input columns in the inputs state and changes the loading state to false
   */
  const initializeWorkflowInputCols = async () => {
    if (!selectedWorkflow) return;
    if (workflows.workflows.length === 0) return;

    setInputs({ loading: true, fields: [] });
    if (initialWorkflow !== selectedWorkflow) payloads[2].inputString = [];
    const isV2 = workflows.workflows.find((wf: any) => wf.id === selectedWorkflow)?.supabase || false;

    let respConfig: any[] = [];
    try {
      const data = await getWorkflowDetailsWithActions(selectedWorkflow, isV2);
      const firstAction = data.response.actions.find((action: any) => action.id === "id1");

      // Updated check for excluded actions
      if (["WEBHOOK_AS_INPUT", "PHANTOMBUSTER_AGENT", "ENGAGEBAY_CONTACT_ADDED"].includes(firstAction?.actionName)) {
        setSelectedWorkflow(null);
        setInputs({ loading: false, fields: [] });
        setWebhookError(true);
        throw new Error("Cannot select webhook actions");
      }

      setWebhookError(false);
      respConfig = firstAction.responseConfiguration;
    } catch (error) {
      // Show error message if it's webhook error
      if (error instanceof Error && error.message === "Cannot select webhook actions") {
        notification.error({
          message: "Invalid Selection",
          description: "Cannot select webhook actions as target workflow",
        });
      }
      setInputs({ loading: false, fields: [] });
    } finally {
      setInputs({ loading: false, fields: respConfig });
    }
  };

  useEffect(() => {
    setWidthPercent();
    window.addEventListener("resize", setWidthPercent);
    return () => window.removeEventListener("resize", setWidthPercent);
  }, [collapseConfig]);

  const setWidthPercent = useCallback(() => {
    try {
      if (collapseConfig) setDrawerWidth("3rem");
      // Adjust width for different screen sizes
      else if (window.matchMedia("(min-width: 1024px) and (min-height: 1366px)").matches)
        // iPad Pro and similar devices
        setDrawerWidth("50%");
      else if (window.matchMedia("(min-width: 1024px)").matches) setDrawerWidth("30%");
      else if (window.matchMedia("(min-width: 640px)").matches) setDrawerWidth("50%");
      else setDrawerWidth("100%");
    } catch (err) {
      console.error("Error calculating width percent based on media size");
    }
  }, [collapseConfig]);

  useEffect(() => {
    initializeWorkflows();
  }, []);

  useEffect(() => {
    initializeWorkflowInputCols();
    const selectedWorkflowLabel =
      workflows.workflows.find((item: any) => item.id === selectedWorkflow)?.name ||
      workflows.workflows.find((item: any) => item.id === selectedWorkflow)?.publishedWorkflowConfig?.name;

    setSelectedWorkflowName(selectedWorkflowLabel);
    createSelectedWorkflowUrl();
  }, [selectedWorkflow, workflows.workflows]);
  // dependency workflows is added to run the useEffect when the workflows are fetched

  useEffect(() => {
    setPayloads(structuredClone(payloadConfiguration));
    setSelectedWorkflow(initialWorkflow);
    setSelectedList(initialList);
  }, [payloadConfiguration]);

  useEffect(() => {
    setRunCondition(action.runCondition);
  }, [action]);

  // Auto-save when drawer closes
  const handleClose = async () => {
    try {
      // Only save if there are changes AND input fields are configured
      if (!loading && changed && !webhookError && hasConfiguredInputs()) await saveOutputStructure();
    } catch (error) {
      console.error("Failed to save drawer contents on close", error);
    }
  };

  const initializeVariables = () => {
    // Generate list of variables for auto mapping
    const vars: any[] = [];
    const list = selectedList?.split("{{")[1]?.split("}}")[0] || "";
    variables.forEach((action) => {
      action?.variables?.forEach((variable: ResponseStructure) => {
        if (list === variable.responseId && variable.type === "sectionList") {
          const structure = variable.sectionVariablesStructure || [];
          const config = variable.sectionVariablesConfiguration || [];
          for (const sectionVar of structure) {
            const currConfig = config.find((item) => item.responseStructureId === sectionVar.responseStructureId);
            if (!currConfig) continue;
            const option = {
              id: currConfig.responseId,
              logo: action.logo,
              type: sectionVar.type || "string",
              name: sectionVar.name,
              sectionName: variable.name,
              actionName: action.name,
            };
            vars.push(option);
          }
        } else {
          const option = {
            id: variable.responseId,
            logo: action.logo,
            type: variable.type || "string",
            name: variable.name,
            actionName: action.name,
          };
          vars.push(option);
        }
      });
    });

    return vars;
  };

  useEffect(() => {
    const temp = [...payloads];
    const index = payloads.findIndex((input) => input.payloadStructureId === INPUT_STRUCTURE_ID);

    // Get existing payload data if it exists
    const existingPayloadData = temp[index]?.inputString || [];
    if (inputs.fields.length === 0) return;

    const vars: any[] = initializeVariables();

    // Map through inputs.fields and preserve existing payload data where possible
    const updatedInputString = inputs.fields.map((field: any) => {
      // Try to find matching payload entry by name and responseId
      const existingEntry = existingPayloadData.find(
        (payload: any) => payload.name === field.name && payload.responseId === field.responseId
      );

      // If matching entry found, preserve its data, otherwise create new empty entry
      if (existingEntry && existingEntry.value && existingEntry.value !== "")
        return {
          ...existingEntry,
          name: field.name,
          responseId: field.responseId,
        };
      const matchingVars = vars?.length ? vars.filter((v) => v.type === field.type) : [];

      let exactMatch = matchingVars?.length
        ? matchingVars.find((v) => (v.name?.toLowerCase() === field.name?.toLowerCase()))
        : null;
      const keywords = AUTOFILL_KEYWORDS[field.name as keyof typeof AUTOFILL_KEYWORDS] || [];
      // compare each matching variable with the keywords
      for (let i = 0; i < matchingVars.length && !exactMatch; i++) {
        const variable = matchingVars[i];
        if (
          keywords.some((keyword) =>
            variable.name?.toLowerCase() === keyword?.toLowerCase()
          )
        ) {
          exactMatch = variable;
          break;
        }
      }
      return {
        name: field.name,
        responseId: field.responseId,
        value: exactMatch ? `{{${exactMatch?.id}}}` : "",
        tiptapJson: exactMatch ? TEMPLATE_TIPTAP_VARIABLE_VALUE(exactMatch) : "",
      };
    });
    // Update the payloads array
    // If no payload exists for this structure ID, create new entry
    if (index === -1)
      temp.push({
        payloadStructureId: INPUT_STRUCTURE_ID,
        inputString: updatedInputString,
        type: "input", // adjust type as needed
      });
    // Update existing payload
    else
      temp[index] = {
        ...temp[index],
        inputString: updatedInputString,
      };

    setInitialWorkflow("");
    setInitialList("");
    setPayloads(temp);
    setRefresh(refresh + 1);
  }, [inputs.fields, selectedList]);

  useEffect(() => {
    if (payloads !== payloadConfiguration || runCondition !== action.runCondition) setChanged(true);
    else setChanged(false);
  }, [payloads, runCondition]);

  const saveOutputStructure = async () => {
    setLoading(true);
    const updated = updatePayloadConfig(payloads, action.id, false);
    setPayloads(updated);
    await saveWorkflowActions(id || "");
    setChanged(false);
    setLoading(false);
    setPublishWarning(true);
  };

  const handleSelectList = (value: any, options: any) => {
    setSelectedList(value ? `{{${value}}}` : "");

    const temp = [...payloads];
    const index = temp.findIndex((payload) => payload.payloadStructureId === LIST_STRUCTURE_ID);
    if (index === -1) return;

    if (!value) temp[index].inputString = "";
    else {
      temp[index].selectedList = options.name;
      temp[index].inputString = `{{${value}}}`;
    }
    setPayloads(temp);
  };

  const hasConfiguredInputs = () => {
    const inputPayload = payloads.find((input) => input.payloadStructureId === INPUT_STRUCTURE_ID);
    return inputPayload?.inputString?.length > 0;
  };

  const fieldSwitchCase = (type: string) => {
    switch (type) {
      case "workflow":
        return (
          <Skeleton loading={workflows.loading}>
            <Select
              showSearch
              placeholder="Choose workflow to send data to"
              value={
                payloads.find((input) => input.payloadStructureId === WORKFLOW_STRUCTURE_ID)?.inputString || undefined
              }
              onChange={(value) => {
                setSelectedWorkflow(value);
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === WORKFLOW_STRUCTURE_ID);
                temp[index].inputString = value;
                setPayloads(temp);

                const selectedWorkflowLabel =
                  workflows.workflows.find((item: any) => item.id === value)?.name ||
                  workflows.workflows.find((item: any) => item.id === value)?.publishedWorkflowConfig?.name;

                setSelectedWorkflowName(selectedWorkflowLabel);
              }}
              optionFilterProp="label"
              options={workflows.workflows.map((value: any) => ({
                label: value.name || value.publishedWorkflowConfig.name,
                value: value.id,
              }))}
              dropdownRender={(menu) => <>{menu}</>}
              popupClassName="custom-dropdown"
            />
          </Skeleton>
        );
      case "sectionList":
        return (
          <Select
            placeholder="Select a list to generate additional table rows"
            defaultValue={
              payloads
                .find((payload) => payload.payloadStructureId === LIST_STRUCTURE_ID)
                ?.inputString.split("{{")[1]
                ?.split("}}")[0]
            }
            allowClear
            showSearch
            onChange={handleSelectList}
            optionFilterProp="name"
            options={variables
              .filter((action) => action.variables.some((variable: any) => variable.type === "sectionList"))
              .map((variable) => {
                return {
                  label: (
                    <div className="flex gap-2">
                      <img src={variable.logo} alt={variable.name} className="w-4 h-4" />
                      <span>{variable.name}</span>
                    </div>
                  ),
                  title: variable.name,
                  options: variable.variables
                    .filter((variable: any) => variable.type === "sectionList")
                    .map((v: any, idx: number) => {
                      return {
                        label: (
                          <div
                            key={idx}
                            className={`px-2 flex items-center gap-2 rounded-lg
                          ${variable.type === "input" ? "bg-[#FFF3D4] text-[#AA7102]" : "bg-[#E3EDFF] text-[#0047C6]"}`}
                          >
                            <img
                              src={variable.type === "input" ? YellowVarLogo : BlueVarLogo}
                              alt={v.name}
                              className="w-4 h-4"
                            />
                            {v.name}
                          </div>
                        ),
                        value: v.responseId,
                        name: v.name,
                      };
                    }),
                };
              })}
          />
        );
      case "inputsMap":
        return (
          <div className="flex flex-col gap-4">
            <Skeleton loading={inputs.loading} active={inputs.loading}>
              <InputColumnsBox
                payloads={payloads.find((input) => input.payloadStructureId === INPUT_STRUCTURE_ID)?.inputString || []}
                inputChangeHandler={(value: string, tiptapJson: any, name: string) => {
                  const temp = [...payloads];
                  const payloadIndex = payloads.findIndex((input) => input.payloadStructureId === INPUT_STRUCTURE_ID);
                  if (payloadIndex === -1) return;

                  const inputString = temp[payloadIndex].inputString;
                  if (Array.isArray(inputString)) {
                    const itemIndex = inputString.findIndex((item: any) => item.name === name);
                    if (itemIndex !== -1) {
                      inputString[itemIndex].value = value;
                      inputString[itemIndex].tiptapJson = tiptapJson;
                    }
                  } else return;

                  setPayloads(temp);
                }}
                variables={variables}
                selectedWorkflowName={selectedWorkflowName}
                selectedWorkflowLink={selectedWorkflowUrl}
                currentWorkflowName={currentWorkflow}
                selectedList={selectedList}
                setModal={setModal3}
                deleteInputs={deleteInputs}
                refresh={refresh}
              />
            </Skeleton>
          </div>
        );
      default:
        return (
          <div className="flex flex-col gap-4">
            <Skeleton loading={inputs.loading} active={inputs.loading}>
              <NoInputsMessage />
            </Skeleton>
          </div>
        );
    }
  };

  const deleteInputs = async (responseIds: string[]) => {
    setInputs((prev) => ({ ...prev, loading: true }));
    let dashes = selectedWorkflow.split("-");
    let isV2 = dashes.length === 5 ? true : false;
    const responseData = await getWorkflowDetailsWithActions(selectedWorkflow, isV2);
    const action1 = responseData.response.actions.find((action: any) => action.id === "id1");

    action1.responseConfiguration = action1.responseConfiguration.filter(
      (config: any) => !responseIds.includes(config.responseId)
    );

    await saveAnotherWorkflowActions(selectedWorkflow || "", [action1], isV2);
    await publishDraft2(selectedWorkflow, isV2);

    const payloadIndex = payloads.findIndex((input) => input.payloadStructureId === INPUT_STRUCTURE_ID);

    const updatedPayloads = [...payloads];
    // Remove the deleted inputs from the payload
    updatedPayloads[payloadIndex].inputString = updatedPayloads[payloadIndex].inputString.filter(
      (input: any) => !responseIds.includes(input.responseId)
    );
    setInputs({ loading: false, fields: action1.responseConfiguration });
    setPayloads(updatedPayloads);
    await saveOutputStructure();
  };

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Drawer: {
              footerPaddingBlock: 16,
              footerPaddingInline: 24,
            },
            Select: {
              borderRadius: 8,
              colorBorder: "#ababab",
              colorTextPlaceholder: "#ababab",
            },
          },
        }}
      >
        <Drawer
          open={modal}
          mask={false}
          onClose={() => setModal(false)}
          width={drawerWidth}
          styles={{
            content: {
              transition: "width 0.3s",
            },
            body: {
              padding: collapseConfig ? "0" : "24px",
            },
          }}
          classNames={{ header: !collapseConfig ? "border-b !border-gray-300" : "!border-none" }}
          className="ml-auto !font-favorit !p-0 border-l border-gray-500"
          getContainer={false}
          footer={
            !collapseConfig && (
              <div className="w-full flex flex-col gap-4 bg-white">
                <button
                  className={`text-white w-full font-medium rounded-[40px] p-2 mt-auto ${
                    loading || !changed || webhookError || !hasConfiguredInputs()
                      ? "cursor-not-allowed bg-gray-500"
                      : "bg-primary cursor-pointer"
                  }`}
                  onClick={handleClose}
                  disabled={loading || !changed || webhookError || !hasConfiguredInputs()}
                >
                  {loading
                    ? "Saving..."
                    : !changed
                      ? "Saved"
                      : !hasConfiguredInputs()
                        ? "Configure Input Fields First"
                        : "Save changes"}
                </button>
              </div>
            )
          }
          title={
            !collapseConfig && (
              <div className="flex items-center gap-2">
                <button className="p-0 rounded-sm" onClick={() => setCollapseConfig(true)}>
                  <MdChevronRight size={25} />
                </button>
                <img src={action.logo} alt={action.name} className="w-6 h-6" />
                <span
                  id="actionName"
                  contentEditable={changeName}
                  className="text-headings text-xl"
                  suppressContentEditableWarning
                  onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      e.target.blur();
                    }
                  }}
                  onBlur={async (e) => {
                    if (!e.target.textContent) return;
                    setLoading(true);
                    updateActionName(action.id, e.target.textContent || action.name);
                    setChangeName(false);
                    await saveWorkflowActions(id || "");
                    setChanged(false);
                    setLoading(false);
                    setPublishWarning(true);
                  }}
                >
                  {action.name}
                </span>
                <button
                  onClick={() => {
                    setChangeName(!changeName);
                    if (!changeName) {
                      setTimeout(() => {
                        const nameElement = document.getElementById("actionName");
                        if (nameElement) {
                          const range = document.createRange();
                          const selection = window.getSelection();
                          range.selectNodeContents(nameElement);
                          selection?.removeAllRanges();
                          selection?.addRange(range);
                          nameElement.focus();
                        }
                      }, 0);
                    }
                  }}
                  className={`p-2 rounded-md transition-opacity duration-600 ease-in-out hover:bg-gray-200 ${
                    changeName ? "opacity-50" : "opacity-100"
                  }`}
                >
                  <FaRegEdit />
                </button>
              </div>
            )
          }
        >
          {collapseConfig ? (
            <button
              className="w-full h-full flex flex-col items-center justify-center p-3 gap-3"
              onClick={() => setCollapseConfig(false)}
            >
              <span className="p-0 border border-gray-300 rounded-sm">
                <MdChevronLeft size={25} />
              </span>
              <p className="[writing-mode:sideways-lr] text-center">CONFIG</p>
            </button>
          ) : (
            <div className="bg-white gap-6 flex flex-col overflow-x-hidden">
              <div className="text-sm font-medium">
                <span className="text-[#4B4B4B]">Current workflow: </span>
                <span className="text-headings">{currentWorkflow}</span>
              </div>
              <p className="font-semibold flex items-center gap-1">
                <img src={One} alt="one" className="w-4 h-4" />
                <span className="text-headings text-lg font-semibold">Choose or create a new workflow</span>
              </p>
              {fieldSwitchCase("workflow")}
              <div>
                <button
                  onClick={() => setModal2(true)}
                  className="text-primary text-sm font-medium underline cursor-pointer"
                >
                  or create a new workflow
                </button>
              </div>
              {selectedWorkflowName && (
                <>
                  <Divider className="my-0" />
                  <div className="flex flex-col gap-2">
                    <p className="font-semibold flex items-center gap-1">
                      <img src={Two} alt="Two" className="w-4 h-4" />
                      <span className="text-headings text-lg font-semibold">
                        Choose columns to send to{" "}
                        <a
                          href={selectedWorkflowUrl}
                          target="_blank"
                          className="text-base text-primary font-medium w-[40%] truncate hover:underline hover:text-primary"
                        >
                          {selectedWorkflowName}
                        </a>
                      </span>
                    </p>
                    <span className="text-sm font-light text-gray-500">
                      The input fields from <span className="font-medium">the workflow</span> chosen in{" "}
                      <img src={GreenOne} alt="GreenOne" className="w-4 h-4 inline align-text-top" />
                      &nbsp;will appear here. Map your current workflow's inputs to the workflow selected.
                    </span>
                  </div>
                  <div className="flex flex-col gap-2">
                    <p className="flex items-center gap-1">
                      <span className="text-headings text-md font-semibold">Section List</span>
                      <span className="text-sm font-normal tracking-normal text-gray-400"> (Optional)</span>
                    </p>
                    {fieldSwitchCase("sectionList")}
                  </div>
                  {fieldSwitchCase("inputsMap")}
                </>
              )}
              <Divider className="my-0" />
              <div className="flex items-center gap-2">
                Continue workflow even if this fails:{" "}
                <Switch
                  defaultChecked={action.continueOnFail}
                  onChange={(value) => {
                    setChanged(true);
                    action.continueOnFail = value;
                  }}
                />
              </div>
              <RunConditionNew
                variables={variables}
                responses={action.responseStructure}
                conditionsArr={
                  runCondition || {
                    conditions: [],
                    otherwise: [],
                  }
                }
                setConditionsArr={setRunCondition}
              />
            </div>
          )}
          <CreateWorkflowModal
            modal={modal2}
            createWorkflowParams={createWorkflowParams}
            responses={responses}
            payloads={payloads}
            parentWorkflowId={id}
            setPayloads={setPayloads}
            setSelectedWorkflow={setSelectedWorkflow}
            setModal={setModal2}
            setCreateWorkflowParams={setCreateWorkflowParams}
            setWorkflows={setWorkflows}
          />
          <AddInputColumnsModal
            modal={modal3}
            responses={responses}
            selectedWorkflow={selectedWorkflow}
            inputs={inputs}
            setInputs={setInputs}
            setModal={setModal3}
            setResponses={setResponses}
            saveOutputStructure={saveOutputStructure}
          />
        </Drawer>
      </ConfigProvider>
    </>
  );
};

export default FloqPushModal;

import { MdAlternateEmail, MdCalendarToday, MdDataArray, MdDataObject, MdNumbers } from "react-icons/md";
import { BASE_URL, TURBO_URL } from "./urls";
import { TbTextSize } from "react-icons/tb";
import { FaDollarSign, FaLink } from "react-icons/fa";
import { CiImageOn } from "react-icons/ci";
import moment from "moment";
import completedIcon from "/assets/completed.svg";
import errorIcon from "/assets/error.svg";
import conditionNotMetIcon from "/assets/condition_not_met.svg";
import inProgressIcon from "/assets/in_progress.svg";
import inReviewIcon from "/assets/in_review.svg";
import noDataIcon from "/assets/no_data.svg";
import outOfCreditsIcon from "/assets/out_of_credits.svg";
import pendingIcon from "/assets/pending.svg";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import Coins from "@/assets/SVGs/buildTab/Coins";

export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DISPLAY_DATE_TIME_FORMAT = "MMM DD, hh:mm A";
export const isProd = import.meta.env.VITE_IS_PROD === "true";
export const isV2 = import.meta.env.VITE_IS_V2 === "true";

export const GrayCrossIcon = () => (
  <svg
    style={{
      display: "inline-block",
      verticalAlign: "middle",
    }}
    width="20"
    height="20"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" fill="" />
    <path d="M12 12L36 36M36 12L12 36" stroke="gray" strokeWidth="6" strokeLinecap="round" />
  </svg>
);

export const keyDownArr1 = ["KeyT", "KeyK", "KeyM", "KeyI", "KeyC", "KeyF", "KeyJ", "KeyL", "KeyP", "KeyN"];
// no shift, no alt, no ctrl, no meta
export const keyDownArr2 = [
  "Space",
  "Enter",
  "Comma",
  "Period",
  "Delete",
  "Backspace",
  "ArrowLeft",
  "ArrowRight",
  "ArrowUp",
  "ArrowDown",
];

export enum TabEnum {
  "build" = "build",
  "table" = "table",
  // "chat" = "chat",
}

export enum LOADER_TYPES {
  generating = "generating",
  loading = "loading",
  fetching = "fetching",
  specific = "specific",
  pasting = "pasting",
}

export const enum CELL_STATUS_TYPE {
  PENDING = "pending",
  COMPLETED = "completed",
  FAILED = "failed",
  PAYLOAD_FILLED = "payloadFilled",
  READY_TO_BE_REVIEWED = "readyToBeReviewed",
  REVIEWED = "reviewed",
  CHECKING_NEXT_SOURCE = "checkingNextSource",
  MISSING = "missing",
  ERROR = "error",
  OUT_OF_CREDITS = "outOfCredits",
}

export const CELL_STATUS_DISPLAY: { [key: string]: string } = {
  pending: "Pending",
  completed: "View",
  failed: "𝘕𝘰 𝘥𝘢𝘵𝘢 𝘧𝘰𝘶𝘯𝘥",
  payloadFilled: "In progress",
  readyToBeReviewed: "Review",
  checkingNextSource: "Checking next source",
  conditionNotMet: "Condition not met",
  error: "𝘌𝘳𝘳𝘰𝘳",
  outOfCredits: "𝘖𝘶𝘵 𝘰𝘧 𝘤𝘳𝘦𝘥𝘪𝘵𝘴",
  duplicate: "𝘋𝘶𝘱𝘭𝘪𝘤𝘢𝘵𝘦",
};

export const CELL_STATUS_DISPLAY_2: { [key: string]: string } = {
  pending: "Pending",
  completed: "Completed",
  failed: "𝘕𝘰 𝘥𝘢𝘵𝘢 𝘧𝘰𝘶𝘯𝘥",
  payloadFilled: "In progress",
  readyToBeReviewed: "Review",
  checkingNextSource: "Checking next source",
  conditionNotMet: "Condition not met",
  error: "𝘌𝘳𝘳𝘰𝘳s",
  outOfCredits: "𝘖𝘶𝘵 𝘰𝘧 𝘤𝘳𝘦𝘥𝘪𝘵𝘴",
  duplicate: "𝘋𝘶𝘱𝘭𝘪𝘤𝘢𝘵𝘦",
};

export const CELL_STATUS_SYMBOL: { [key: string]: React.ReactNode } = {
  completed: "✅",
  failed: "",
  pending: "⏲",
  payloadFilled: <SpinnerStatus />,
  checkingNextSource: <SpinnerStatus />,
  readyToBeReviewed: "✔",
  conditionNotMet: <GrayCrossIcon />,
  error: "",
  outOfCredits: <Coins width="15" height="15" />,
};

export const CELL_STATUS_SYMBOL_2: { [key: string]: React.ReactNode } = {
  completed: <img src={completedIcon} className="translate-y-[-1px]" height={10} width={10} />,
  failed: <img src={noDataIcon} className="translate-y-[-1px]" height={10} width={10} />,
  pending: <img src={pendingIcon} className="translate-y-[-1px]" height={10} width={10} />,
  payloadFilled: <img src={inProgressIcon} className="translate-y-[-1px]" height={10} width={10} />,
  checkingNextSource: <img src={completedIcon} className="translate-y-[-1px]" height={10} width={10} />, //<SpinnerStatus />
  readyToBeReviewed: <img src={inReviewIcon} className="translate-y-[-1px]" height={10} width={10} />,
  conditionNotMet: <img src={conditionNotMetIcon} className="translate-y-[-1px]" height={10} width={10} />, //<GrayCrossIcon />
  error: <img src={errorIcon} className="translate-y-[-1px]" height={10} width={10} />,
  outOfCredits: <img src={outOfCreditsIcon} className="translate-y-[-1px]" height={10} width={10} />, //<Coins width="15" height="15" />
};

export const TABLE_COLUMN_STATUS_FILTERS = Object.keys(CELL_STATUS_DISPLAY)
  .filter((key) => key !== "pending")
  .map((key) => ({
    text: (
      <span className="flex gap-1 w-full items-center">
        {CELL_STATUS_SYMBOL[key as keyof typeof CELL_STATUS_SYMBOL]} {CELL_STATUS_DISPLAY[key]}
      </span>
    ),
    value: key,
  }));

export enum USER_ACTION_STATUS {
  PENDING = "pending",
  COMPLETED = "completed",
  FAILED = "failed",
  PAYLOAD_FILLED = "payloadFilled",
  READY_TO_BE_REVIEWED = "readyToBeReviewed",
  CHECKING_NEXT_SOURCE = "checkingNextSource",
  CONDITION_NOT_MET = "conditionNotMet",
  ERROR = "error",
  OUT_OF_CREDITS = "outOfCredits",
}

export enum ACTION_TYPES {
  ADD_SECTION = "add_section",
  AI = "AI",
  EXTRACT = "extract",
  DATA_ENRICHMENT = "data_enrichment",
  SENDING = "sending",
  SCRAPE_DATA = "scrape_data",
  INPUT = "input",
}

// export const ACTIONS_WITH_USER_KEY_TOGGLE = [
//   "GENERATE_TEXT",
//   "GENERATE_SUBJECT_USING_TEMPLATES_AND_PROMPT",
//   "GENERATE_EMAIL_USING_TEMPLATES_AND_PROMPT",
//   "ANALYZE_RISKS_FOR_10K_DOC",
//   "GROQ_GENERATE_TEXT",
//   "CLAUDE_GENERATE_TEXT"
// ];

export const NON_AI_ACTIONS_WITH_API_PRICIING_IDS = [
  "FIND_EMAIL_USING_NAME_AND_COMPANY_NAME",
  "FIND_WORK_EMAIL_BY_LINKEDIN_URL",
  "FIND_EMAIL_COMBINED",
  "CRUST_DATA_PERSON_ENRICH",
];

export const ENGAGEBAY_SYSTEM_FIELDS = [
  {
    field_label: "First Name",
    field_name: "name",
    type: "SYSTEM",
  },
  {
    field_label: "Last Name",
    field_name: "last_name",
    type: "SYSTEM",
  },
  {
    field_label: "Email",
    field_name: "email",
    type: "SYSTEM",
  },
  {
    field_label: "Role",
    field_name: "role",
    type: "SYSTEM",
  },
  {
    field_label: "Phone Number",
    field_name: "phone",
    type: "SYSTEM",
  },
  {
    field_label: "Website",
    field_name: "website",
    type: "SYSTEM",
  },
  {
    field_label: "Address",
    field_name: "address",
    type: "SYSTEM",
  },
  {
    field_label: "Contact ID",
    field_name: "id",
    type: "SYSTEM",
  },
];

export const SALESFORCE_RESPONSE_NAME = "Records Found";
export const APOLLO_EMAIL_STATUS_RESPONSE_STRUCTURE_ID = "4dfd9c98-c018-48b1-b8da-20c9e4c2fc34";
export const APOLLO_EMAIL_RESPONSE_STRUCTURE_NAME = "729aa56a-a019-4712-a469-c11fc9bbb880";
export const OPENAI_PERSONAL_API_KEY_STRUCTURE_ID = "openaiApiKey";
export const CLAUDE_PERSONAL_API_KEY_STRUCTURE_ID = "claudeApiKey";
export const GROQ_PERSONAL_API_KEY_STRUCTURE_ID = "59237791-8107-4796-a68f-0adc81efa783";
export const OPENAI_MODELS_PAYLOAD_STRUCTURE_ID = "cc82c01d-e9be-415a-aa4a-7517693f4ff5";
export const OPENAI_ACTIONS_CONNECTION_ID = "openai";
export const EMAIL_VALIDATORS_STRUCTURE_ID = "emailValidation";
export const ZENROWS_PERSONAL_API_KEY_STRUCTURE_ID = "zenrowsApiKey";
export const DEEPSEEK_PERSONAL_API_KEY_STRUCTURE_ID = "deepseekApiKey";
export const CRUST_DATA_PERSON_ENRICH_REALTIME_STRUCTURE_ID = "a0925bf5-28b3-4b33-8e6f-9929546d852g";

// payments stuff

export const pricings = {
  Starter: {
    monthly: {
      price: "$60",
      link: "https://buy.stripe.com/cN2dUkg4Z0sK8XSbIL",
      // link: "https://buy.stripe.com/test_dR6dTe2jy5Nm8IUbII",
    },
    yearly: {
      price: "$48",
      link: "https://buy.stripe.com/4gw17y061b7o3Dy8wA",
    },
    features: ["Personalization at Scale", "Store 100 Leads", "500 Emails Monthly", "Email Support"],
  },
  Growth: {
    monthly: {
      price: "$110",
      link: "https://buy.stripe.com/dR64jK1a57Vc2zu9AA",
    },
    yearly: {
      price: "$88",
      link: "https://buy.stripe.com/7sIdUk2e9fnEa1WeV2",
    },
    features: ["Personalization at Scale", "Store 500 Leads", "1,000 Emails Monthly", "Premium Support"],
  },
  HyperGrowth: {
    monthly: {
      price: "$299",
      link: "https://buy.stripe.com/3cs03u3id2AS0rmbIR",
    },
    yearly: {
      price: "$239",
      link: "https://buy.stripe.com/4gwg2sbOJejAde8aEO",
    },
    features: ["Personalization at Scale", "Store unlimited Leads", "Unlimited Emails Monthly", "Premium Support"],
  },
};

export const firebaseAccessToken = "firebase_access_token";

export const firebaseRefreshToken = "firebase_refresh_token";

export const chrome_extension_key = "mgpmocfddaldjiccjhdhbdefmhjeedoa";

export type setStateType<T> = React.Dispatch<React.SetStateAction<T>>;

export const ADMIN_EMAILS = ["avishjain0@gmail.com", "shivamtest@gmail.com", "admin@floqer.com"];

export const CHAT_FILLER_PAYLOAD = `Hey I am Floqer, I am here to help with any questions you have regarding your data in this table. You can ask me questions like: 
Who does management identify as a threat? Can I help them compete?
Does management think [what I’m solving for] is a risk?
Where does power reside in the org? Who are the execs & what is their contact info?`;

const bucketName = isProd ? "10-k_doc_sections_bucket_prod" : "10-k_doc_sections_bucket";
export const tenkDocFolder = `9dbf7f3a-59f5-46ea-a5d6-914c616fb655/`;

const workflowBucketName = isProd ? "workflow_response_bucket_prod" : "workflow_response_bucket";

export const FOLDERS_APPLICABLE_FOR_PROMPTS = isProd
  ? [`${bucketName}/${tenkDocFolder}`, `${workflowBucketName}`]
  : ["10-k_doc_sections_bucket/9dbf7f3a-59f5-46ea-a5d6-914c616fb655", "workflow_response_bucket/"];

const isUrl = (cellValue: string) => {
  if (new RegExp("^(http://|https://|www\\.)").test(cellValue)) {
    return true;
  }
  const pattern = new RegExp(
    "^((https?:\\/\\/)?)" + // protocol (http or https)
      "(([a-z\\d]+(-[a-z\\d]+)*\\.)+[a-z]{2,}|" + // domain name and extension
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\/[\\w\\.&-]*)*\\/?$", // path
    "i"
  );
  return !!pattern.test(cellValue);
};

const isEmail = (email: string) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

const isImageUrl = (url: string) => {
  return url.match(/\.(jpg|gif|png|JPG|GIF|PNG|JPEG|jpeg)$/) != null;
};

const isDate = (date: string) => {
  const d = moment(
    date,
    [
      "YYYY-MM-DD",
      "MM-DD-YYYY",
      "DD-MM-YYYY",
      "YYYY/MM/DD",
      "MM/DD/YYYY",
      "DD/MM/YYYY",
      "MMM DD, YYYY",
      "MMMM DD, YYYY",
      "DD MMM YYYY",
      "DD MMMM YYYY",
      "YYYY", // For just year
      "YYYY-MM", // For year and month
    ],
    true
  ); // Strict mode to avoid partial matching

  return d.isValid();
};

export const getIconFromType = (type: string) => {
  switch (type) {
    case "string":
      return <TbTextSize />;
    case "email":
      return <MdAlternateEmail />;
    case "url":
      return <FaLink />;
    case "imageUrl":
      return <CiImageOn />;
    case "number":
      return <MdNumbers />;
    case "currency":
      return <FaDollarSign />;
    case "date":
    case "datetime":
      return <MdCalendarToday />;
    case "array":
      return <MdDataArray />;
    case "sectionList":
      return <MdDataArray />;
    case "json":
      return <MdDataObject />;
    default:
      return <TbTextSize />;
  }
};

export const getTypeFromValue = (value: any) => {
  if (!value) return "string";
  if (typeof value === "boolean") return "boolean";
  if (Array.isArray(value)) return "array";
  if (typeof value === "object") return "json";
  if (typeof value === "number" || typeof value === "bigint") return "number";
  if (isEmail(value)) return "email";
  if (isImageUrl(value)) return "imageUrl";
  if (isUrl(value)) return "url";
  if (isDate(value)) return "date";
  return typeof value;
};

export const getConditionsForType = (type: string) => {
  switch (type) {
    case "string":
    case "email":
    case "url":
    case "imageUrl":
      return [
        {
          label: "is",
          value: "is",
        },
        {
          label: "is not",
          value: "is not",
        },
        {
          label: "contains",
          value: "contains",
        },
        {
          label: "does not contain",
          value: "does not contain",
        },
        {
          label: "starts with",
          value: "starts with",
        },
        {
          label: "ends with",
          value: "ends with",
        },
        {
          label: "does not start with",
          value: "does not start with",
        },
        {
          label: "does not end with",
          value: "does not end with",
        },
      ];
    case "number":
    case "currency":
      return [
        {
          label: "is",
          value: "is",
        },
        {
          label: "is not",
          value: "is not",
        },
        {
          label: "greater than",
          value: "greater than",
        },
        {
          label: "less than",
          value: "less than",
        },
        {
          label: "greater than or equal to",
          value: "greater than or equal to",
        },
        {
          label: "less than or equal to",
          value: "less than or equal to",
        },
        {
          label: "is between",
          value: "is between",
        },
      ];
    case "date":
    case "datetime":
      return [
        {
          label: "is",
          value: "is",
        },
        {
          label: "is not",
          value: "is not",
        },
        {
          label: "is after",
          value: "is after",
        },
        {
          label: "is before",
          value: "is before",
        },
        {
          label: "is between",
          value: "is between",
        },
      ];
    case "array":
    case "sectionList":
    case "json":
    case "multidropdown":
    case "multiDropdown":
      return [
        {
          label: "contains",
          value: "contains",
        },
        {
          label: "does not contain",
          value: "does not contain",
        },
      ];
    case "hubspot_search_filters":
      return [
        {
          label: "is exactly equal to",
          value: "EQ",
        },
        {
          label: "is not exactly equal to",
          value: "NEQ",
        },
        // {
        //   label: "Less than",
        //   value: "LT",
        // },
        // {
        //   label: "Less than or equal to",
        //   value: "LTE",
        // },
        // {
        //   label: "Greater than",
        //   value: "GT",
        // },
        // {
        //   label: "Greater than or equal to",
        //   value: "GTE",
        // },
        // {
        //   label: "Within the specified range",
        //   value: "BETWEEN",
        // },
        // {
        //   label: "Included within the specified list",
        //   value: "IN",
        // },
        // {
        //   label: "Not included within the specified list",
        //   value: "NOT_IN",
        // },
        // {
        //   label: "Has a value for the specified property",
        //   value: "HAS_PROPERTY",
        // },
        // {
        //   label: "Doesn't have a value for the specified property",
        //   value: "NOT_HAS_PROPERTY",
        // },

        {
          label: "contains",
          value: "CONTAINS_TOKEN",
        },
        {
          label: "doesn't contain",
          value: "NOT_CONTAINS_TOKEN",
        },
      ];
    default:
      return [
        {
          label: "is",
          value: "is",
        },
        {
          label: "is not",
          value: "is not",
        },
      ];
  }
};

export const finalConditions = (type: string) => {
  // console.log("type", type);
  return type
    ? [
        {
          value: "is empty",
          label: "is empty",
        },
        {
          value: "is not empty",
          label: "is not empty",
        },
        ...getConditionsForType(type),
      ]
    : [
        {
          value: "is empty",
          label: "is empty",
        },
        {
          value: "is not empty",
          label: "is not empty",
        },
      ];
};

export const inputTypeFromVariableType = (type: string) => {
  switch (type) {
    case "string":
      return "text";
    case "number":
      return "number";
    case "date":
    case "datetime":
      return "date";
    default:
      return "text";
  }
};

// Added for webhook endpoints so that even if we change the backend url, we don't have to update the db and just use the id param instead of using the entire url
// For existing endpoints which have the entire url, we will just extract the id from the url and use that
export const getEndpointFromNameAndId = (name: string, id: string) => {
  let newId = id;
  if (typeof id === "string" && (id.includes("https") || id.includes("http"))) newId = id.split("id=")[1];
  if (name.toLowerCase().includes("webhook")) return BASE_URL + (getV2() ? "/v2" : "") + "/trigger/webhook?id=" + newId;
  if (name.toLowerCase().includes("input")) return BASE_URL + (getV2() ? "/v2" : "") + "/trigger/webhook?id=" + newId;
  if (name.toLowerCase().includes("phantom"))
    return TURBO_URL + (getV2() ? "/v2" : "") + "/trigger/phantom?id=" + newId;
  if (name.toLowerCase().includes("engagebay"))
    return BASE_URL + (getV2() ? "/v2" : "") + "/trigger/engagebay?id=" + newId;
};

export const TABLE_ERROR_MESSAGES = ["Not enough credits", "Missing input data"];

export const WATERFALL_ACTIONS = [
  "FIND_WORK_EMAIL_BY_LINKEDIN_URL",
  "FIND_PHONE_NUMBER_BY_LINKEDIN_URL",
  "FIND_COMPANY_DOMAIN_BY_NAME",
  "FIND_EMAIL_USING_NAME_AND_COMPANY_NAME",
  "FIND_LINKEDIN_URL_BY_WORK_EMAIL",
  "FIND_EMAIL_COMBINED",
  "FIND_PERSONAL_EMAIL_BY_LINKEDIN_URL",
];

export const WATERFALL_ACTIONS_WITH_LARGE_RESPONSE = ["ENRICH_IP_DATA_WATERFALL"];

export const BRANCH_ACTIONS_TYPES = ["path_splitter", "if_else"];

export const getV2 = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const v2 = searchParams.get("v2");
  return v2 !== "false";
};

export const DYNAMIC_PAYLOAD_TYPES = ["dynamicDropdown", "dynamicJsonArray"];

export const INPUT_TYPES = [
  {
    label: "Text",
    value: "string",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "URL",
    value: "url",
  },
  {
    label: "Image from URL",
    value: "imageUrl",
  },
  {
    label: "Number",
    value: "number",
  },
  {
    label: "Currency",
    value: "currency",
  },
  {
    label: "Date",
    value: "date",
  },
];

export const AUTOFILL_KEYWORDS = {
  "First Name": ["first name", "fname", "fn", "f_name", "firstname"],
  "Last Name": ["last name", "lname", "ln", "l_name", "lastname"],
  "Full Name": [
    "full name",
    "name",
    "person name",
    "prospect name",
    "fullname",
    "full_name",
    "person_name",
    "prospect_name",
  ],
  Email: [
    "email",
    "primary email",
    "prospect email",
    "email address",
    "email_address",
    "prospect_email",
    "email_id",
    "emailid",
    "email id",
    "email add",
    "work email",
    "work_email",
    "workemail",
  ],
  "Work Email": [
    "work email",
    "work_email",
    "workemail",
    "email",
    "primary email",
    "prospect email",
    "email address",
    "email_address",
    "prospect_email",
    "email_id",
    "emailid",
    "email id",
    "email add",
  ],
  "Personal Email": ["personal email", "personal_email", "personalemail"],
  "LinkedIn URL": [
    "linkedin url",
    "linkedinurl",
    "linkedin",
    "LinkedIn",
    "linkedIn",
    "linkedin profile",
    "linkedin_profile",
    "social profile",
    "social_profile",
    "prospect linkedin",
    "prospect_linkedin",
    "lk url",
    "lkurl",
    "lkdin",
  ],
  "Sales Navigator URL": [
    "sales navigator url",
    "sales_navigator_url",
    "sales navigator",
    "sales_navigator",
    "sales nav url",
    "sales_nav_url",
    "sales nav",
    "sales_nav",
  ],
  "Mobile Number": ["phone", "phone number", "mobile", "mobile number", "phone_number", "mobile_number"],
  Website: ["website"],
  "Company Name": [
    "company name",
    "company",
    "company_name",
    "companyname",
    "cname",
    "c_name",
    "c name",
    "org name",
    "org_name",
    "orgname",
    "organization name",
    "organization_name",
    "organizationname",
    "org",
    "organization",
  ],
  "Company Domain": [
    "company domain",
    "domain",
    "companydomain",
    "org domain",
    "company url",
    "url",
    "company",
    "company_domain",
    "org_domain",
    "company website",
    "website",
    "org website",
    "org_website",
    "companyurl",
    "orgurl",
  ],
  "Company Website": [
    "website",
    "company website",
    "company_website",
    "companywebsite",
    "org website",
    "org_website",
    "orgwebsite",
  ],
  "Company LinkedIn URL": [
    "company linkedin url",
    "company linkedin",
    "company linkedinurl",
    "companylinkedinurl",
    "companylinkedin",
  ],
  "Company Identifier": [
    "company linkedin url",
    "company linkedin",
    "company linkedinurl",
    "companylinkedinurl",
    "companylinkedin",
    "company domain",
    "domain",
    "org domain",
    "company url",
    "url",
    "company",
    "company_domain",
    "org_domain",
    "company website",
    "website",
    "org website",
    "org_website",
    "companyurl",
    "orgurl",
    "company sales navigator url",
    "company sales navigator",
    "company sales navigatorurl",
    "sales navigator url",
    "sales navigatorurl",
    "sales navigator",
    "companysalesnavigator",
    "companysalesnavigatorurl",
    "sales_nav",
    "sales_nav_url",
  ],
  "Company Sales Navigator URL": [
    "company sales navigator url",
    "company sales navigator",
    "company sales navigatorurl",
    "sales navigator url",
    "sales navigatorurl",
    "sales navigator",
    "companysalesnavigator",
    "companysalesnavigatorurl",
    "sales_nav",
    "sales_nav_url",
  ],
  "Crunchbase URL": ["crunchbase url", "crunchbase"],
  "Youtube Video or Share URL": [
    "youtube video",
    "youtube",
    "youtube url",
    "youtube video url",
    "youtube share url",
    "youtube share",
    "youtube link",
    "youtube video link",
    "youtube video share link",
    "youtube share link",
    "yt video",
    "yt",
    "yt url",
    "yt video url",
    "yt share url",
    "yt share",
    "yt link",
    "yt video link",
    "yt video share link",
    "yt share link",
    "ytube video",
    "ytube",
    "ytube url",
    "ytube video url",
    "ytube share url",
    "ytube share",
    "ytube link",
    "ytube video link",
    "ytube video share link",
    "ytube share link",
    "youtubelink",
    "ytubelink",
    "ytlink",
  ],
  "YouTube Channel URL or Handle": [
    "youtube channel url",
    "youtube channel",
    "youtube channel link",
    "youtube channel handle",
    "youtube handle",
    "yt channel url",
    "yt channel",
    "yt channel link",
    "yt channel handle",
    "yt handle",
    "ytube channel url",
    "ytube channel",
    "ytube channel link",
    "ytube channel handle",
    "ytube handle",
    "youtubechannelurl",
    "youtubechannel",
    "youtubechannelhandle",
    "ytchannelurl",
    "ytchannel",
    "ytchannelhandle",
    "ytubechannelurl",
    "ytubechannel",
    "ytubechannelhandle",
    "channel url",
    "channel",
    "channel link",
    "channel handle",
    "yt channel",
    "ytube channel",
    "youtube channel",
    "youtube channel id",
    "yt channel id",
    "ytube channel id",
    "channel id",
    "youtube channel profile",
    "yt channel profile",
    "ytube channel profile",
    "channel profile",
    "youtube profile",
    "yt profile",
    "ytube profile",
    "channel page",
    "youtube page",
    "yt page",
    "ytube page",
    "youtube account",
    "yt account",
    "ytube account",
    "channel account",
    "youtube user",
    "yt user",
    "ytube user",
    "channel user",
    "youtube username",
    "yt username",
    "ytube username",
    "channel username",
  ],
};

export const TEMPLATE_TIPTAP_VARIABLE_VALUE = (variable: any) => ({
  type: "doc",
  content: [
    {
      type: "paragraph",
      content: [
        {
          type: "customTag",
          attrs: {
            id: variable.id,
            logo: variable.logo,
            label: variable.sectionName ? variable.sectionName + " - " + variable.name : variable.name,
            parent: variable.actionName,
          },
        },
      ],
    },
  ],
});

const TYPE_MAPS = {
  imageUrl: ["image"],
  email: ["email", "mail", "e-mail"],
  url: [
    "url",
    "link",
    "website",
    "domain",
    "linkedin",
    "youtube",
    "ytube",
    "yt",
    "youtube link",
    "ytube link",
    "yt link",
    "youtube url",
  ],
  number: ["num", "quantity", "amount", "count", "total", "no.", "no ", " no", "integer", "float"],
  currency: ["currency", "price", "revenue", "cost"],
  date: ["date"],
};
export const getExpectedTypeFromName = (name: string) => {
  const lowerCaseName = name.toLowerCase();
  for (const type in TYPE_MAPS)
    if (TYPE_MAPS[type as keyof typeof TYPE_MAPS].some((keyword) => lowerCaseName.includes(keyword))) return type;
  return "string";
};

export const CRM_FREQUENCY = [
  {
    label: "Twice Every Day",
    value: "0 9,21 * * *",
  },
  {
    label: "Every Day at Noon",
    value: "0 12 * * *",
  },
  {
    label: "Once Every Two Days",
    value: "0 10 */2 * *",
  },
  {
    label: "Twice a Week",
    value: "0 9 * * 1,4",
  },
  {
    label: "Once a Week",
    value: "0 10 * * 1",
  },
  {
    label: "Once a Month",
    value: "0 12 1 * *",
  },
];

export const CRM_FILTERS_OPTIONS = {
  string: ["is empty", "is not empty", "contains", "not contains", "equals", "not equals"],
  array: ["is empty", "is not empty", "contains", "not contains"],
  json: ["is empty", "is not empty", "contains", "not contains"],
  number: ["is empty", "is not empty", "equals", "not equals"],
};

export const INCLUDED_ACTIONS_AUTO_DEDUPE = [
  "ADD_SECTION_TO_FLOQ",
  "APOLLO_ENRICH_PEOPLE_DATA",
  "BUILTWITH_TECH_STACK_ENRICH",
  "CAPTERRA_COMPANY_ENRICH",
  "CLAUDE_GENERATE_TEXT",
  "COMPANY_HEADCOUNT_BY_COUNTRY",
  "COMPANY_HEADCOUNT_BY_DEPARTMENT",
  "COMPANY_LEAD_SCORE_FROM_EMAIL",
  "CRUSTDATA_COMPANY_ENRICH",
  "DEEPSEEK_GENERATE_TEXT",
  "EMAIL_VERIFICATION_MILLION_VERIFIER",
  "EMAIL_VERIFICATION_NEVERBOUNCE",
  "EMAIL_VERIFICATION_ZEROBOUNCE",
  "ENRICH_IP_DATA_WATERFALL",
  "EXTRACT_DOMAIN_FROM_EMAIL",
  "FIND_COMPANY_DOMAIN_BY_NAME",
  "FIND_EMAIL_COMBINED",
  "FIND_LINKEDIN_URL_BY_WORK_EMAIL",
  "FIND_PERSONAL_EMAIL_BY_LINKEDIN_URL",
  "FIND_PHONE_NUMBER_BY_LINKEDIN_URL",
  "FLOQER_WEB_AGENT",
  "GENERATE_EMAIL_USING_TEMPLATES_AND_PROMPT",
  "GENERATE_PERPLEXITY_CONTENT",
  "GENERATE_SUBJECT_USING_TEMPLATES_AND_PROMPT",
  "GENERATE_TEXT",
  "GOOGLE_SCHOLAR_ENRICH_AUTHOR",
  "GROQ_GENERATE_TEXT",
  "HACKER_TARGET_ASN_LOOKUP",
  "IGNITEPOST_INTEGRATION",
  "IPINFO_IP_ENRICH",
  "OWLER_COMPANY_ENRICH",
  "PEOPLE_DATA_LABS_COMPANY_ENRICH",
  "PEOPLE_DATA_LABS_PERSON_ENRICH",
  "PHONE_VERIFICATION_CLEAROUT_PHONE",
  "SCRAPE_COMPANY_LINKEDIN_DATA_2",
  "SCRAPE_LINKEDIN_PUBLIC_DATA_2",
  "STORE_LEADS_COMPANY_ENRICHMENT",
  "THEIR_STACK_ENRICHMENT",
  "TWAIN_INTEGRATION",
  "UPLOAD_CSV_OR_WEBHOOK",
  "UPLOAD_PROSPECT_CSV_MANUAL",
  "WORKFLOW_DATA_FORMATTER",
  "YOUTUBE_ENRICH_CHANNEL",
  "YOUTUBE_ENRICH_VIDEO",
];

export const ALLOWED_TYPES_AUTO_DEDUPE = [
  "string",
  "email",
  "url",
  "imageurl",
  "number",
  "currency",
  "boolean",
  "date",
];

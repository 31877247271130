import { ResponseStructure } from "@/utils/interfaces";

export const constructCommandList = (
  variables: {
    logo: string;
    name: string;
    variables: ResponseStructure[];
  }[],
  explodeSection: string
) => {
  const commandList = [];
  for (const action of variables) {
    for (const variable of action.variables) {
      if (explodeSection === variable.responseId && variable.type === "sectionList") {
        const structure = variable.sectionVariablesStructure || [];
        const config = variable.sectionVariablesConfiguration || [];
        for (const sectionVar of structure) {
          const currConfig = config.find((item) => item.responseStructureId === sectionVar.responseStructureId);
          if (!currConfig) continue;
          commandList.push({
            title: sectionVar.name + " - " + variable.name,
            logo: action.logo,
            response: currConfig.responseId,
            parent: action.name,
            command: ({ editor, range }: any) => {
              editor?.chain().focus().deleteRange(range).run();
              if (editor?.isFocused) {
                editor?.commands.insertContentAt(editor?.state.selection.$anchor.pos, {
                  type: "customTag",
                  attrs: {
                    id: currConfig.responseId,
                    parent: action.name,
                    label: sectionVar.name + " - " + variable.name,
                    logo: action.logo,
                  },
                });
              } else
                editor
                  ?.chain()
                  .focus()
                  .insertContent({
                    type: "customTag",
                    attrs: {
                      id: currConfig.responseId,
                      parent: action.name,
                      label: sectionVar.name + " - " + variable.name,
                      logo: action.logo,
                    },
                  })
                  .run();
              editor?.commands.focus();
            },
          });
        }
      } else
        commandList.push({
          title: variable.name,
          logo: action.logo,
          response: variable.responseId,
          parent: action.name,
          command: ({ editor, range }: any) => {
            editor?.chain().focus().deleteRange(range).run();
            if (editor?.isFocused) {
              editor?.commands.insertContentAt(editor?.state.selection.$anchor.pos, {
                type: "customTag",
                attrs: {
                  id: variable.responseId,
                  parent: action.name,
                  label: variable.name,
                  logo: action.logo,
                },
              });
            } else
              editor
                ?.chain()
                .focus()
                .insertContent({
                  type: "customTag",
                  attrs: {
                    id: variable.responseId,
                    parent: action.name,
                    label: variable.name,
                    logo: action.logo,
                  },
                })
                .run();
            editor?.commands.focus();
          },
        });
    }
  }
  return commandList;
};

export const getCommandSuggestions = ({
  query,
  variables,
  explodeSection,
}: {
  query: string;
  variables: {
    logo: string;
    name: string;
    variables: ResponseStructure[];
  }[];
  explodeSection: string;
}) => {
  const commandList = constructCommandList(variables, explodeSection);
  return commandList.filter(
    (item) =>
      item.title?.toLowerCase().includes((query ?? "").toLowerCase()) ||
      item.parent?.toLowerCase().includes((query ?? "").toLowerCase())
  );
};

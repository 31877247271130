import { forwardRef } from 'react';
import { Checkbox } from 'antd';
import TagInput, { TagInputRef } from '@/Components/Common/TagInput';
import { inputTypeFromVariableType } from '@/utils/constants';

interface ConditionalInputProps {
  field: {
    condition: string;
    variableType: string;
    values: string[];
    caseSensitive?: boolean;
  };
  onChange: (updates: {
    values?: string[];
    caseSensitive?: boolean;
  }) => void;
  onInputChange?: (hasInput: boolean, inputValue?: string) => void;
}

const ConditionalInput = forwardRef<TagInputRef, ConditionalInputProps>(({ field, onChange, onInputChange }, ref) => {
  const isStringCondition = [
    "contains",
    "does not contain",
    "is",
    "is not",
    "starts with",
    "ends with",
    "does not start with",
    "does not end with"
  ].includes(field.condition);

  if (field.condition === "" || 
      field.condition === "is empty" || 
      field.condition === "is not empty") {
    return null;
  }

  if (isStringCondition) {
    return (
      <div className="flex flex-col gap-2 w-full">
        <TagInput
          values={field.values || []}
          onChange={(values) => onChange({ values })}
          placeholder="Enter values and press Enter or comma to add"
          className="w-full"
          ref={ref}
          onInputChange={onInputChange}
        />
        {(field.variableType === "string" || field.variableType === "email") && (
          <div className="flex items-center gap-2">
            <Checkbox
              checked={field.caseSensitive === false || field.caseSensitive === undefined}
              onChange={(e) => {
                onChange({ caseSensitive: !e.target.checked });
              }}
            >
              Ignore case
            </Checkbox>
          </div>
        )}
      </div>
    );
  }

  return (
    <input
      type={inputTypeFromVariableType(field.variableType)}
      placeholder="Enter a value..."
      className="w-full rounded-md p-1 px-2 border-gray-200"
      value={field.values?.[0] || ""}
      onChange={(e) => onChange({ values: [e.target.value] })}
    />
  );
});

export default ConditionalInput; 
import { memo } from "react";
import WorkflowIcon from "../../../assets/SVGs/newWorkflowBlue.svg";
import { MdMoreHoriz } from "react-icons/md";
import moment from "moment";
import { DISPLAY_DATE_TIME_FORMAT } from "@/utils/constants";
import { Link } from "react-router-dom";
import { Dropdown } from "antd";

interface WorkflowProps {
  workflow: any;
  getWorkflowActionMenu: any;
  RenameWorkflow: any;
  toRenameWorkflow: any | null;
}

const Workflow = memo(
  ({ workflow, getWorkflowActionMenu, RenameWorkflow, toRenameWorkflow }: WorkflowProps) => {
    if (!workflow) return;

    return (
      <div className="px-2 py-1 grid grid-cols-8 items-center hover:bg-primary/10 rounded-md">
        <div className="flex gap-x-3 items-center font-normal col-span-6">
          <img src={WorkflowIcon} alt="workflow-icon" width={18} />
          {!toRenameWorkflow || toRenameWorkflow.id !== workflow.id ? (
            <Link to={`/workflow/${workflow.id}?v2=${workflow.supabase}`} className="w-full">
              <p className="!text-gray-700 text-md w-full">
                {(workflow.name || workflow.draftWorkflowConfig.name).length > 70
                  ? `${(workflow.name || workflow.draftWorkflowConfig.name).slice(0, 67)}...`
                  : workflow.name || workflow.draftWorkflowConfig.name}
              </p>
            </Link>
          ) : (
            <RenameWorkflow />
          )}
        </div>
        <p className="text-sm w-full text-gray-500 font-light">
          {moment.utc(workflow.createdAt).local().format(DISPLAY_DATE_TIME_FORMAT)}
        </p>
        <div className="hoverClass flex items-center justify-center">
          <Dropdown
            overlay={getWorkflowActionMenu(workflow)}
            trigger={["click"]}
            overlayStyle={{
              width: "11rem",
            }}
          >
            <button
              className="flex justify-center h-fit items-center p-1 rounded-md transition-opacity duration-600 ease-in-out hover:opacity-100 hover:bg-gray-200"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <MdMoreHoriz size={20} />
            </button>
          </Dropdown>
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    // Skip comparison for props that never change
    return (
      prevProps.workflow === nextProps.workflow &&
      prevProps.toRenameWorkflow === nextProps.toRenameWorkflow &&
      prevProps.getWorkflowActionMenu === nextProps.getWorkflowActionMenu
    );
  }
);

export default Workflow;

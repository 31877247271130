import React, { useEffect, useState } from "react";
import { useWorkflow } from "@/contexts/WorkflowContext";
import Loader from "@/Components/Loader";
import { LOADER_TYPES } from "@/utils/constants";

interface PlanProps {
  title: string;
  price: number;
  description: string;
  features: string[];
  gradient: string;
  paymentUrl: string;
}

const gradients = [
  "bg-gradient-to-r from-pink-100 to-pink-200",
  "bg-gradient-to-r from-blue-100 to-blue-200",
  "bg-gradient-to-r from-green-100 to-green-200",
  "bg-gradient-to-r from-yellow-100 to-yellow-200",
  "bg-gradient-to-r from-purple-100 to-purple-200",
  "bg-gradient-to-r from-red-100 to-red-200",
];

const PricingCard: React.FC<PlanProps> = ({ title, price, description, features, gradient, paymentUrl }) => (
  <div
    className={`bg-white rounded-xl shadow-md overflow-hidden flex flex-col h-[400px] ${gradient} transform transition duration-500 hover:scale-105`}
  >
    <div className="p-6 flex-grow">
      <h2 className="text-2xl font-bold text-gray-800 mb-2">{title}</h2>
      <p className="text-3xl font-bold text-gray-800 mb-2">
        ${price}
        <span className="text-xl font-normal">/month</span>
      </p>
      <p className="text-sm text-gray-600 mb-4">{description}</p>
    </div>
    <div className="p-6 bg-white flex-grow flex flex-col justify-between">
      <ul className="space-y-3 mb-6">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center text-gray-700 text-sm">
            <svg
              className="w-5 h-5 text-green-500 mr-2 flex-shrink-0"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
            </svg>
            <span>{feature}</span>
          </li>
        ))}
      </ul>
      <button
        onClick={() => window.open(paymentUrl, "_blank")}
        className="w-full bg-blue-500 text-white rounded-lg py-3 px-4 font-semibold hover:bg-blue-600 transition duration-300"
      >
        Select Plan
      </button>
    </div>
  </div>
);

const Pricings: React.FC = () => {
  const [plans, setPlans] = useState<PlanProps[]>([]);
  const [loading, setLoading] = useState(true);
  const { fetchPricingPlansForUser } = useWorkflow();

  useEffect(() => {
    setLoading(true);
    fetchPricingPlansForUser().then((response) => {
      const fetchedPlans = response.pricingPlans.map((plan: any, index: number) => ({
        title: plan.title,
        price: plan.price,
        description: plan.description,
        features: plan.benefits,
        gradient: gradients[index % gradients.length],
        paymentUrl: plan.paymentUrl,
      }));

      fetchedPlans.sort((a: { price: number }, b: { price: number }) => a.price - b.price);

      setPlans(fetchedPlans);
      setLoading(false);
    });
  }, []);

  if (loading)
    return (
      <div className="flex h-screen w-screen justify-center items-center">
        <Loader payload={"floqer"} loaderType={LOADER_TYPES.fetching} />
      </div>
    );

  return (
    <div className="bg-gray-100 h-screen flex flex-col gap-10 justify-center items-center">
      <h1 className="text-4xl font-bold text-center text-gray-800">Choose Your Plan</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6">
        {plans.map((plan, index) => (
          <PricingCard key={index} {...plan} />
        ))}
      </div>
    </div>
  );
};

export default Pricings;

import { message } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCampaigns } from "@/utils/apis";
import { SIGNAL_ID_TO_LIST_PREVIEW_URL, SIGNAL_ID_TO_URL } from "@/utils/urls";
import { createSignal, signalPreview } from "../../apis";
import HBfilterPreview from "./HBfilterPreview";
import HBfinalStep from "./HBfinalStep";

type Step = {
  id: number;
  title: string;
  component: React.ReactNode;
};

// type StepFiveProps = {
//   prev: () => void;
//   logo: string;
//   payloadStructure: any;
//   payloads: any;
//   setPayloads: any;
//   createList: () => Promise<void>;
// };

type Props = {
  signal: any;
  payloads: any;
  setPayloads: any;
  name: string;
  setListType: any;
};

// const getPayloadLayout = (payload: PayloadStructure, payloads: any, setPayloads: any) => {
//   switch (payload.type) {
//     case "date":
//       const currentDate = dayjs().format("YYYY-MM-DD"); // Default to today's date
//       const selectedDate = payloads[payload.payloadStructureId] || null; // Get saved date

//       return (
//         <div className="flex flex-col items-start gap-2 border-b w-full py-4">
//           <div className="flex flex-col gap-2 items-start justify-center w-full">
//             <h1>
//               {payload.name} {payload.required && <span className="text-red-500">*</span>}
//             </h1>
//               <p className="text-xs text-gray-400">{payload.description}</p>
//           </div>
//             <div className="flex items-center justify-start gap-4">
//               <Radio.Group
//                 value={selectedDate ? "on" : "never"}
//                 onChange={(e) => {
//                   const value = e.target.value;
//                   if (value === "never") {
//                     setPayloads((prev: any) => ({ ...prev, [payload.payloadStructureId]: null }));
//                   } else {
//                     setPayloads((prev: any) => ({
//                       ...prev,
//                       [payload.payloadStructureId]: currentDate, // Default to today when switching to "On"
//                     }));
//                   }
//                 }}
//               >
//                 <Radio.Button value="never">Never</Radio.Button>
//                 <Radio.Button value="on">On</Radio.Button>
//               </Radio.Group>

//               {selectedDate && (
//                 <DatePicker
//                   format="DD MMMM, YYYY"
//                   className="p-1 rounded"
//                   allowClear={false}
//                   value={selectedDate ? dayjs(selectedDate, "YYYY-MM-DD") : null}
//                   onChange={(value) =>
//                     setPayloads((prev: any) => ({
//                       ...prev,
//                       [payload.payloadStructureId]: value?.format("YYYY-MM-DD"),
//                     }))
//                   }
//                 />
//               )}
//             </div>
//         </div>
//       );

//     case "crmFrequency":
//       return (
//         <div className="flex flex-col items-start gap-2 w-full">
//           <h1>
//             {payload.name} {payload.required && <span className="text-red-500">*</span>}
//           </h1>
//           <p className="text-xs text-gray-400">{payload.description}</p>
//           <CustomCronGenerator
//             onCronChange={(cron) =>
//               setPayloads((prev: any) => ({
//                 ...prev,
//                 [payload.payloadStructureId]: cron,
//               }))
//             }
//           />
//         </div>
//       );

//     case "toggle":
//       return (
//         <div className="w-full flex flex-col items-start gap-2">
//           <div className="flex items-center gap-2">
//             <h1>{payload.name}</h1>
//             <Switch
//               className="w-fit "
//               defaultChecked={true}
//               onChange={(value) => {
//                 setPayloads((prev: any) => ({
//                   ...prev,
//                   [payload.payloadStructureId]: value,
//                 }));
//               }}
//             />
//           </div>
//           <p className="text-xs text-gray-400">{payload.description}</p>
//         </div>
//       );

//     default:
//       return;
//   }
// };

// const StepFive = ({ prev, logo, payloadStructure, payloads, setPayloads, createList }: StepFiveProps) => {
//   console.log(payloads, "payloads");
//   // Extract relevant properties for event triggers
//   const filteredProperties = payloads.properties.filter((property: string) =>
//     subscriptionProperty.some((prop) => prop.name === property)
//   );

//   return (
//     <div className="p-4 border w-[60%] mx-auto mt-10 flex flex-col items-center justify-center gap-8 rounded-lg">
//       <div className="flex flex-col items-start justify-start w-full gap-3 mb-4">
//         <div className="flex gap-2 items-center justify-between w-full">
//           <h2 className="text-xl flex items-center gap-2">
//             <img src={logo} alt="logo" className="h-6 w-6" />
//             How often would you like to refresh this list?
//           </h2>
//           <button
//             className="text-gray-800 px-1 py-1 rounded disabled:opacity-50 border border-gray-800 hover:bg-gray-100"
//             onClick={prev}
//           >
//             <AiOutlineArrowLeft />
//           </button>
//         </div>
//         <p className="text-sm text-gray-500">
//           We'll automatically pull the newest information according to the frequency you select, keeping your list up to
//           date.
//         </p>
//       </div>
//       <Collapse accordion className="w-full">
//         <Panel header="On a schedule" key="1">
//           {payloadStructure.map((payload: any) => getPayloadLayout(payload, payloads, setPayloads))}
//         </Panel>
//         <Panel header="Event triggers" key="2">
//           <div className="flex flex-col gap-2">
//             {filteredProperties.length > 0 ? (
//               <div className="flex flex-col gap-2">
//                 {filteredProperties.map((prop: any) => (
//                   <div key={prop} className="flex items-center justify-between border p-2 rounded">
//                     <span>{subscriptionProperty.find((subProp) => subProp.name === prop)?.label || prop}</span>
//                     <span className="text-gray-500">changes</span>
//                   </div>
//                 ))}
//               </div>
//             ) : (
//               <p className="text-gray-500 text-sm">No event triggers selected.</p>
//             )}
//           </div>
//         </Panel>
//       </Collapse>
//       <div className="mt-4 flex gap-2 w-full items-center">
//         <LoaderButton
//           text="Create List"
//           loaderText="Creating List..."
//           btnClasses="bg-black text-white px-8"
//           onClickCallback={async () => await createList()}
//           error=""
//           disabled={false}
//           IconComponent={FilePlusCreate}
//         />
//       </div>
//     </div>
//   );
// };

// this is for active CRM list building

const HubspotMultiStepForm = ({ signal, payloads, setPayloads, name, setListType }: Props) => {
  const [currentStep, setCurrentStep] = useState(0);

  const [propertiesFilter, setPropertiesFilter] = useState<any[]>([]);
  const [runCondition, setRunCondition] = useState({ conditions: [] });
  const [loading, setLoading] = useState(false);
  const [previewData, setPreviewData] = useState<any>([]);
  const navigate = useNavigate();

  const types = useMemo<string[]>(
    () =>
      signal.payloadStructure
        .filter((field: any) => field.type.includes("dynamicDropdown"))
        .map((field: any) => field.valuesId ?? ""),
    [signal.payloadStructure]
  );

  useEffect(() => {
    setLoading(true);
    if (types.length === 0) return;
    types.forEach((type) => {
      getCampaigns(type)
        .then((data: any[]) => {
          if (type.includes("properties")) {
            setPropertiesFilter(data);
            setPayloads((prev: any) => ({
              ...prev,
              crmProperties: data,
            }));
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        })
        .finally(() => {});
    });
  }, [types]);

  const validatePayload = (payloadData: Record<string, any>, payloadStructure: any[]) => {
    return payloadStructure.every((item) => {
      if (!item.required) return true; // Skip non-required fields

      const fieldValue = payloadData[item.payloadStructureId];

      // Check if the required field exists and is not empty
      if (fieldValue === undefined || fieldValue === null) return false;

      // Check if it's an array and is empty
      if (Array.isArray(fieldValue) && fieldValue.length === 0) return false;

      // Check if it's an object and is empty
      if (typeof fieldValue === "object" && Object.keys(fieldValue).length === 0) return false;

      return true;
    });
  };

  const generatePreview = async (payload: any) => {
    setLoading(true);
    try {
      const url = SIGNAL_ID_TO_LIST_PREVIEW_URL[signal.id as keyof typeof SIGNAL_ID_TO_LIST_PREVIEW_URL];
      const responses = await signalPreview(url, payload);
      setPreviewData(responses);
      setLoading(false);
    } catch (error: any) {
      message.error(error.message || error);
      setLoading(false);
    }
  };
  const createList = async () => {
    const isValidPayload = validatePayload(payloads, signal.payloadStructure);
    // check if all required payloads are present
    if (!isValidPayload) {
      message.error("Please fill all required fields");
      return;
    }
    const newId = await createSignal(SIGNAL_ID_TO_URL[signal.id as keyof typeof SIGNAL_ID_TO_URL], payloads, name);
    navigate(`/list/${newId}`);
  };

  const steps: Step[] = (
    [
      {
        id: 2,
        title: "Step Two",
        component: (
          <HBfilterPreview
            prev={() => setListType(null)}
            next={() => (payloads.listType === "active" ? setCurrentStep(1) : setCurrentStep(0))}
            signal={signal}
            generatePreview={generatePreview}
            previewData={previewData}
            loading={loading}
            payloads={payloads}
            propertiesFilter={propertiesFilter}
            setPayloads={setPayloads}
            runCondition={runCondition}
            setRunCondition={setRunCondition}
          />
        ),
      },
      payloads.listType === "active"
        ? {
            id: 3,
            title: "Step Three",
            component: (
              <HBfinalStep
                prev={() => setCurrentStep(0)}
                logo={signal.logo}
                payloadStructure={signal.payloadStructure}
                payloads={payloads}
                setPayloads={setPayloads}
                createList={createList}
              />
            ),
          }
        : null,
    ] as Step[]
  ).filter(Boolean);

  return (
    <div className="newScroll h-full w-full flex flex-col !cursor-default !font-favorit" id="scroll-to-top-container">
      <div className="h-[70%]">
        <div className="h-full">{steps[currentStep].component}</div>
      </div>
    </div>
  );
};

export default HubspotMultiStepForm;

import { Breadcrumb, Tooltip } from "antd";
import { useEffect, useMemo, useState } from "react";
import RadioIconBrown from "@Assets/SVGs/RadioIconBrown.svg";
import PlayIcon from "@Assets/SVGs/PlayIcon.svg";
import HubspotMultiStepForm from "./HubspotMultiStepForm";
import { Link } from "react-router-dom";
import EditableName from "../EditableName";
import RadioIconGray from "@Assets/SVGs/RadioIconGray.svg";
import ListIcon from "@Assets/SVGs/ListIcon.svg";
import DatabaseIcon from "@Assets/SVGs/DatabaseIcon.svg";
import { CollapsibleIcon } from "@/Components/NavbarNew/CollapsibleIcon";
import { TbHelp } from "react-icons/tb";
import SalesforceMultiStepFormActive from "./SalesforceMultiStepFormActive";
import PipedriveMultiStepForm from "./PipedriveMultiStepForm";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useConnections } from "@/contexts/ConnectionContext";

type Props = {
  signal: any;
};
type Step = {
  id: number;
  title: string;
  component: React.ReactNode;
};

type StepTwoProps = {
  next: () => void;
  logo: string;
  connectionDetials: any;
  isConnectionExist: boolean;
  name: string;
};

export default function OptionSelector({ signal }: Props) {
  const [listType, setListType] = useState<"static" | "active" | null>(null);
  const [payloads, setPayloads] = useState<any>({});
  const [name, setName] = useState("New Signal-" + signal.shortName);
  const [currentStep, setCurrentStep] = useState(0);

  const { getConnectionFromType } = useConnections();
  const [connectionDetails, setConnectionDetails] = useState();
  const [isConnectionExists, setIsConnectionExists] = useState(true);

  useEffect(() => {
    if (!signal?.connectionId) return;
    getConnectionFromType(signal?.connectionId).then((connection: any) => {
      setIsConnectionExists(connection?.userConnection ? true : false);
      setConnectionDetails(connection?.connectionDoc);
    });
  }, [signal?.connectionId]);

  const changeWatcherNameHandler = async (newName: string): Promise<void> => {
    setName(newName);
  };

  const breadcrumbItems = useMemo(() => {
    const { type, logo } = signal;

    let path = "";
    let label = "";
    let icon = "";

    switch (type) {
      case "watcher":
        path = "/intent-signals";
        label = "Intent Signals";
        icon = RadioIconGray;
        break;
      case "list":
        path = "/list-building";
        label = "List Building";
        icon = ListIcon;
        break;
      case "crmWatcher":
      case "crmList":
        path = "/crm";
        label = "CRM";
        icon = DatabaseIcon;
        break;
      default:
        path = "/";
        label = "Home";
    }

    return [
      {
        title: (
          <Link to={path} style={{ display: "flex", alignItems: "center", gap: 4 }}>
            <img src={icon} alt="logo" className="h-5 w-5" />
            {label}
          </Link>
        ),
      },
      {
        title: (
          <span className="flex items-center gap-2">
            <img src={logo} alt="icon" className="h-4 w-4" />
            <EditableName name={name} updateName={changeWatcherNameHandler} />
          </span>
        ),
      },
    ];
  }, [signal, name]);

  const getActiveUI = (id: string) => {
    switch (id) {
      case "HUBSPOT_CONTACT_ADDED":
        return (
          <HubspotMultiStepForm
            signal={signal}
            payloads={payloads}
            setPayloads={setPayloads}
            name={name}
            setListType={setListType}
          />
        );

      case "SALESFORCE_PULL_DATA":
        return (
          <SalesforceMultiStepFormActive
            signal={signal}
            payloads={payloads}
            setPayloads={setPayloads}
            name={name}
            setListType={setListType}
          />
        );

      case "PIPEDRIVE_IMPORT_DATA":
        return (
          <PipedriveMultiStepForm
            signal={signal}
            payloads={payloads}
            setPayloads={setPayloads}
            name={name}
            setListType={setListType}
          />
        );

      default:
        return (
          <HubspotMultiStepForm
            signal={signal}
            payloads={payloads}
            setPayloads={setPayloads}
            name={name}
            setListType={setListType}
          />
        );
    }
  };
  const getStaticUI = (id: string) => {
    switch (id) {
      case "HUBSPOT_CONTACT_ADDED":
        return (
          <HubspotMultiStepForm
            signal={signal}
            payloads={payloads}
            setPayloads={setPayloads}
            name={name}
            setListType={setListType}
          />
        );

      case "SALESFORCE_PULL_DATA":
        return (
          <SalesforceMultiStepFormActive
            signal={signal}
            payloads={payloads}
            setPayloads={setPayloads}
            name={name}
            setListType={setListType}
          />
        );

      case "PIPEDRIVE_IMPORT_DATA":
        return (
          <PipedriveMultiStepForm
            signal={signal}
            payloads={payloads}
            setPayloads={setPayloads}
            name={name}
            setListType={setListType}
          />
        );

      default:
        return (
          <HubspotMultiStepForm
            signal={signal}
            payloads={payloads}
            setPayloads={setPayloads}
            name={name}
            setListType={setListType}
          />
        );
    }
  };

  const getTypeUI = (type: string | null) => {
    switch (type) {
      case "active":
        return getActiveUI(signal.id);

      case "static":
        return getStaticUI(signal.id);
      default:
        return (
          <div>
            <div className="px-6 pt-6 pb-8 border w-[60%] mx-auto mt-10 flex flex-col gap-6 items-center justify-center rounded-lg bg-[#FEFEFE]">
              <button
                onClick={() => setCurrentStep(0)}
                className="mr-auto border border-gray-800 rounded p-1 hover:bg-gray-100"
              >
                <AiOutlineArrowLeft />
              </button>
              <div className="flex items-center justify-start w-full gap-4">
                <img src={signal.logo} alt="logo" className="h-6 w-6" />
                <h2 className="text-xl">How would you like to bring in your data?</h2>
              </div>
              <div className="flex flex-col w-full gap-4">
                {["active", "static"].map((type) => (
                  <button
                    key={type}
                    onClick={() => {
                      setListType(type as "static" | "active" | null);
                      setPayloads((prev: any) => ({
                        ...prev,
                        listType: type,
                      }));
                    }}
                    className={`px-6 py-3 border-2 rounded-lg ${
                      listType === type ? "border-blue-500 bg-blue-100" : "border-gray-300 bg-white"
                    } transition-all flex flex-col items-start gap-2`}
                  >
                    <div className="flex items-center gap-2">
                      <img src={type.includes("active") ? RadioIconBrown : PlayIcon} alt="logo" className="h-5 w-5" />
                      {type.charAt(0).toUpperCase() + type.slice(1)}
                    </div>
                    <p className="text-xs text-gray-500 text-left">
                      {type.includes("active")
                        ? "Automatically refresh your list on a set schedule or via event triggers, keeping data continuously up to date."
                        : "Import all data as per your chosen options into Floqer once, without ongoing updates."}
                    </p>
                  </button>
                ))}
              </div>
            </div>
          </div>
        );
    }
  };

  const AccountStep = ({ next, logo, connectionDetials, isConnectionExist, name }: StepTwoProps) => (
    <div className="p-4 border w-[60%] mx-auto mt-10 flex flex-col items-center justify-center gap-8 rounded-lg">
      <div className="flex w-full item-center ">
        <div className="flex flex-col items-start justify-start w-full gap-3">
          <div className="flex  gap-2 items-center">
            <img src={logo} alt="logo" className="h-6 w-6" />
            <h2 className="text-xl">Now, let's connect your {name} account</h2>
          </div>
          <p className="text-sm text-gray-500">This connection allows you to sync {name} changes with Floqer</p>
        </div>

        <div className="flex gap-2 h-fit">
          <button
            className="text-gray-800 px-1 py-1 rounded disabled:opacity-50 border border-gray-800 hover:bg-gray-100"
            onClick={next}
          >
            <AiOutlineArrowRight />
          </button>
        </div>
      </div>
      <div className="flex gap-4 border rounded w-full p-3 items-center justify-center">
        {isConnectionExist ? (
          <a
            className="w-full flex items-center justify-center gap-2"
            href={connectionDetials?.authURL || "/connections"}
            target="_blank"
            rel="noreferrer"
          >
            <img src={logo} alt="logo" className="h-5 w-5" />
            <h1>Account connected</h1>
          </a>
        ) : (
          <a
            className="w-full flex items-center justify-center gap-2 cursor-pointer"
            href={connectionDetials?.authURL || "/connections"}
            target="_blank"
            rel="noreferrer"
          >
            <img src={logo} alt="logo" className="h-5 w-5" />
            <h1>Sign in to {name}</h1>
          </a>
        )}
      </div>
    </div>
  );

  const getCRMName = (id: string) => {
    switch (id) {
      case "HUBSPOT_CONTACT_ADDED":
        return "HubSpot";
      case "SALESFORCE_PULL_DATA":
        return "Salesforce";
      case "PIPEDRIVE_IMPORT_DATA":
        return "Pipedrive";
      default:
        return "HubSpot";
    }
  };

  const steps: Step[] = (
    [
      {
        id: 1,
        title: "Step Two",
        component: (
          <AccountStep
            next={() => setCurrentStep(1)}
            logo={signal.logo}
            connectionDetials={connectionDetails}
            isConnectionExist={isConnectionExists}
            name={getCRMName(signal.id)}
          />
        ),
      },
      {
        id: 2,
        title: "Step Two",
        component: <div>{getTypeUI(listType)}</div>,
      },
    ] as Step[]
  ).filter(Boolean);

  return (
    <div className="newScroll h-full w-full flex flex-col !cursor-default !font-favorit" id="scroll-to-top-container">
      <div className="w-full flex items-center justify-between px-8 py-3 z-40 mb-8 sticky top-0 bg-white border-b border-gray-300">
        <span className="flex items-center font-medium text-center text-[#3F3F3F] gap-[5px] max-h-[24px]">
          <CollapsibleIcon isHeader />
          <Breadcrumb className="text-base">
            {breadcrumbItems.map((item, index) => (
              <Breadcrumb.Item key={index}>{item.title}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </span>

        <Tooltip title="Click for help or more info">
          <span className="flex items-center gap-1 text-[#5b5b5b] cursor-pointer">
            <TbHelp className="text-lg" />
            <span className="text-sm font-semibold">Help</span>
          </span>
        </Tooltip>
      </div>
      <div className="h-[70%]">
        <div className="h-full">{steps[currentStep].component}</div>
      </div>
    </div>
  );
}

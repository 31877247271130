import { finalConditions, getIconFromType, inputTypeFromVariableType, setStateType } from "@/utils/constants";
import { ResponseStructure } from "@/utils/interfaces";
import { Divider, Select } from "antd";
import { useEffect, useState } from "react";
import OtherwiseModal from "./OtherwiseModal";
import { LuPlus } from "react-icons/lu";
import DeleteIcon from "@/assets/SVGs/DeleteIcon.svg";

type Props = {
  variables: any[];
  responses: ResponseStructure[];
  conditionsArr: {
    conditions: any[];
    otherwise: any[];
    operator?: string;
  };
  setConditionsArr: setStateType<any>;
};

const RunConditionNew = ({ variables, responses, conditionsArr, setConditionsArr }: Props) => {
  const [options, setOptions] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (!variables) return;
    setOptions([]);
    variables.forEach((action) => {
      action?.variables?.forEach((variable: ResponseStructure) => {
        const option = {
          id: variable.responseId,
          logo: action.logo,
          type: variable.type || "string",
          name: variable.name,
          actionName: action.name,
        };
        setOptions((prev) => [...prev, option]);
      });
    });
  }, [variables]);

  return (
    <div className="flex flex-col gap-4 rounded-lg">
      <Divider className="my-0" />
      <div className="flex flex-col gap-1">
        <h2 className="text-lg font-semibold text-headings">Specify a Condition</h2>
        <p className="text-sm text-gray-500">Will only run this action if the condition specified below is met.</p>
      </div>
      <div className="bg-white gap-10 flex flex-col">
        {conditionsArr?.conditions?.map((conditionGroup, parentIdx) => (
          <div key={parentIdx} className="flex flex-col gap-4 bg-gray-100/20 py-4 border border-1 rounded-md">
            <div className="flex gap-2 px-4 w-full items-center justify-between">
              <p className="text-headings font-medium text-md">Condition {parentIdx + 1}</p>
              {parentIdx !== 0 && (
                <Select
                  showSearch
                  className="flex gap-2 ml-2 mr-auto items-center !text-headings"
                  value={conditionGroup.operator || "AND"}
                  onChange={(value) => {
                    const temp = { ...conditionsArr };
                    temp.conditions[parentIdx].operator = value;
                    setConditionsArr(temp);
                  }}
                  options={[
                    {
                      label: "AND",
                      value: "AND",
                    },
                    {
                      label: "OR",
                      value: "OR",
                    },
                  ]}
                />
              )}
              <button
                className="rounded-full w-10 h-10 px-3 flex gap-1 items-center text-red-500 hover:bg-red-50 hover:border-red-500"
                onClick={() => {
                  const temp = { ...conditionsArr };
                  temp.conditions.splice(parentIdx, 1);
                  setConditionsArr(temp);
                }}
              >
                <img src={DeleteIcon} alt="Delete" className="w-4 h-4" />
              </button>
            </div>
            {conditionGroup.conditions?.map((field: any, idx: number) => (
              <div
                key={idx}
                className={`flex gap-4 rounded-lg ${idx !== 0 ? "ml-8 mr-4 outline-2 outline-dashed outline-gray-200" : ""}`}
              >
                <div className="w-full flex flex-col">
                  {idx !== 0 && (
                    <>
                      <div className="flex justify-between items-center p-4">
                        <Select
                          className="flex w-fit gap-2 items-center"
                          showSearch
                          value={field.operator || "AND"}
                          onChange={(value) => {
                            const temp = { ...conditionsArr };
                            temp.conditions[parentIdx].conditions[idx].operator = value;
                            setConditionsArr(temp);
                          }}
                          options={[
                            {
                              label: "AND",
                              value: "AND",
                            },
                            {
                              label: "OR",
                              value: "OR",
                            },
                          ]}
                        />
                        <button
                          className="rounded-full w-10 h-10 px-3 flex gap-1 items-center text-red-500 hover:bg-red-50 hover:border-red-500"
                          onClick={() => {
                            const temp = { ...conditionsArr };
                            temp.conditions[parentIdx].conditions.splice(idx, 1);
                            setConditionsArr(temp);
                          }}
                        >
                          <img src={DeleteIcon} alt="Delete" className="w-4 h-4" />
                        </button>
                      </div>
                      <div className="my-0 bg-gray-200 outline-dashed outline-1 outline-gray-200" />
                    </>
                  )}
                  <div className={`flex gap-2 items-start w-full ${idx === 0 ? "px-4" : "p-4"}`}>
                    <p className="font-medium text-md text-gray-500">if</p>
                    <div className="w-full flex flex-col gap-2">
                      <Select
                        showSearch
                        allowClear
                        placeholder="Select an output from an action"
                        className="w-full"
                        value={field.variable || undefined}
                        onClear={() => {
                          const temp = { ...conditionsArr };
                          temp.conditions[parentIdx].conditions[idx] = {
                            variable: "",
                            condition: "",
                            variableType: "",
                            values: [],
                            operator: temp.conditions[parentIdx].conditions[idx].operator,
                          };
                          setConditionsArr(temp);
                        }}
                        onChange={(value) => {
                          const temp = { ...conditionsArr };
                          const option = options.find((option) => option.id === value);
                          if (field.variableType === option.type)
                            temp.conditions[parentIdx].conditions[idx].variable = value;
                          else
                            temp.conditions[parentIdx].conditions[idx] = {
                              variable: value,
                              condition: "",
                              variableType: option.type,
                              values: [],
                              operator: temp.conditions[parentIdx].conditions[idx].operator,
                            };
                          setConditionsArr(temp);
                        }}
                        options={options.map((option, index: number) => ({
                          label: (
                            <div className="flex min-w-full flex-row items-center text-sm gap-2" key={index}>
                              <img src={option.logo} alt="logo" className="w-6 h-6" />
                              <span className="text-gray-400 text-xs max-w-[50%] whitespace-nowrap overflow-hidden text-ellipsis">
                                {option.actionName}
                              </span>
                              <p className="">{">"}</p>
                              <p className="flex items-center gap-2 justify-center">
                                <div className="bg-gray-200 p-1 rounded">{getIconFromType(option.type)}</div>
                                {option.name}
                              </p>
                            </div>
                          ),
                          value: option.id,
                          name: `${option.actionName} - ${option.name}`,
                        }))}
                        optionFilterProp="name"
                      />
                      {field.variableType !== "" && (
                        <Select
                          showSearch
                          allowClear
                          className="w-[50%]"
                          placeholder="Select a condition"
                          value={field.condition || undefined}
                          onChange={(value) => {
                            const temp = { ...conditionsArr };
                            temp.conditions[parentIdx].conditions[idx].condition = value;
                            setConditionsArr(temp);
                          }}
                          options={finalConditions(field.variableType) || []}
                        />
                      )}
                      {!(
                        field.condition === "" ||
                        field.condition === "is empty" ||
                        field.condition === "is not empty"
                      ) && (
                        <input
                          type={inputTypeFromVariableType(field.variableType)}
                          placeholder="Enter a value..."
                          className="w-[50%] rounded-md p-1 px-2 border-gray-200"
                          value={field.values?.[0] || ""}
                          onChange={(e) => {
                            const temp = { ...conditionsArr };
                            temp.conditions[parentIdx].conditions[idx].values[0] = e.target.value;
                            setConditionsArr(temp);
                          }}
                        />
                      )}
                      {field.condition === "is between" && (
                        <>
                          <p>To:</p>
                          <input
                            type={inputTypeFromVariableType(field.variableType)}
                            placeholder="Enter another value..."
                            className="w-[50%] rounded-md p-1 px-2 border-gray-200"
                            value={field.values?.[1] || ""}
                            onChange={(e) => {
                              const temp = { ...conditionsArr };
                              temp.conditions[parentIdx].conditions[idx].values[1] = e.target.value;
                              setConditionsArr(temp);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <Divider className="my-0" />
            <button
              className="w-fit rounded-lg px-4 flex gap-1 hover:underline"
              onClick={() => {
                const temp = { ...conditionsArr };
                temp.conditions[parentIdx].conditions.push({
                  variable: "",
                  condition: "",
                  variableType: "",
                  values: [],
                  operator: "AND",
                });
                setConditionsArr(temp);
              }}
            >
              <LuPlus size={20} />
              Add Sub-Condition
            </button>
          </div>
        ))}
        <button
          className="border w-fit border-black rounded-lg p-2 flex items-center gap-1"
          onClick={() => {
            const temp = { ...conditionsArr };
            temp.conditions.push({
              conditions: [
                {
                  variable: "",
                  condition: "",
                  variableType: "",
                  values: [],
                  operator: "AND",
                },
              ],
            });
            setConditionsArr(temp);
          }}
        >
          <LuPlus size={20} />
          Add condition
        </button>
        {conditionsArr.conditions && conditionsArr.conditions.length > 0 && responses && Array.isArray(responses) && (
          <div className="text-sm text-gray-500">
            Otherwise: &nbsp;
            <button className="text-primary hover:underline" onClick={() => setOpenModal(true)}>
              Add Response
            </button>
          </div>
        )}
      </div>
      <OtherwiseModal
        open={openModal}
        setOpen={setOpenModal}
        responses={responses}
        variables={variables}
        values={conditionsArr.otherwise}
        saveValues={(values) =>
          setConditionsArr({
            ...conditionsArr,
            otherwise: values,
          })
        }
      />
      <Divider className="my-0" />
    </div>
  );
};

export default RunConditionNew;

import React, { useState, useMemo } from "react";
import { Breadcrumb, Dropdown, Menu, message } from "antd";
import WorkflowFolderIcon from "../../../assets/SVGs/WorkflowFolderIcon.svg";
import HomeIcon from "../../../assets/SVGs/HomeIcon.svg";
import { useNavigate } from "react-router-dom";
import { useWorkflow } from "@/contexts/WorkflowContext";
import LoaderButtonBlack from "@/Components/LoaderButtonBlack";
import SalesNavigatorComponent from "./SalesNavigator";
import CompaniesListComponent from "./CompaniesList";
import OperatorsListComponent from "./WelldbOperatorsList";
import CompaniesListViaLinkedin from "./CompaniesListViaLinkedin";
import PipedriveComponent from "./Pipedrive";
import { CollapsibleIcon } from "@/Components/NavbarNew/CollapsibleIcon";
import PlayIcon from "/assets/play-icon.svg";
import { FiChevronDown } from "react-icons/fi";
import { useUser } from "@/contexts/UserContext";
import ActionCard from "./Components/ActionCard";

interface WorkflowFolder {
  id: string;
  createdAt: string;
  name: string;
  workflows: string[];
}

interface FolderDropdownProps {
  workflowFolders: WorkflowFolder[];
  selectedFolder: string;
  setSelectedFolder: React.Dispatch<React.SetStateAction<string>>;
}

interface WorkflowUIProps {
  folders: WorkflowFolder[];
  close: () => void;
  selectedFolderId: string;
  v2?: boolean;
}

const FolderDropdown = ({ workflowFolders, selectedFolder, setSelectedFolder }: FolderDropdownProps) => {
  const menu = useMemo(() => {
    return (
      <Menu className="max-h-[450px]">
        <Menu.Item key="root" onClick={() => setSelectedFolder("root")}>
          <span className="flex items-center gap-2">
            <img src={HomeIcon} alt="root-icon" className="w-4 h-4" />
            <span>Root</span>
          </span>
        </Menu.Item>
        {workflowFolders.map((folder) => (
          <Menu.Item key={folder.id} onClick={() => setSelectedFolder(folder.id)}>
            <span className="flex items-center gap-2">
              <img src={WorkflowFolderIcon} alt={folder.name} className="w-4 h-4" />
              <span>{folder.name}</span>
            </span>
          </Menu.Item>
        ))}
      </Menu>
    );
  }, [workflowFolders, setSelectedFolder]);

  return (
    <Dropdown overlay={menu} trigger={["click"]} className="w-full rounded border border-black px-4">
      <div className="flex items-center justify-between cursor-pointer">
        <span className="flex items-center gap-2">
          {workflowFolders.find((folder) => folder.id === selectedFolder) ? (
            <img src={WorkflowFolderIcon} alt="folder" className="w-5 h-5 shrink-0" />
          ) : (
            <img src={HomeIcon} alt="root-icon" className="w-5 h-5 shrink-0" />
          )}
          <span className="text-black font-normal text-md truncate py-2">
            {workflowFolders.find((folder) => folder.id === selectedFolder)?.name || "Root"}
          </span>
        </span>
        <FiChevronDown className="text-xl" />
      </div>
    </Dropdown>
  );
};

const WorkflowUI = ({ close, folders, selectedFolderId, v2 = true }: WorkflowUIProps) => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [next, setNext] = useState(false);
  const [workflowName, setWorkflowName] = useState("");
  const [selectedFolder, setSelectedFolder] = useState(selectedFolderId || "root");
  const [selectedSource, setSelectedSource] = useState("csv_or_webhook");

  const { createNewWorkflow } = useWorkflow();
  const { collapsed: isSidebarCollapsed } = useUser();

  const handleCreateWorkflow = async () => {
    if (!workflowName.trim()) {
      setError("Workflow Name cannot be empty");
      return;
    }
    const id = await createNewWorkflow(workflowName, selectedSource, selectedFolder, {}, v2, false);
    if (id === "") setError("Something went wrong. Please try again. If the problem persists, contact support.");
    else if (id.includes("Hubspot connection not found"))
      setError("Please connect your Hubspot account on the connections page.");
    else if (id.includes("Salesforce connection found"))
      setError("Please connect your Salesforce account on the connections page.");
    else if (id.includes("Monday connection not found"))
      setError("Please visit the connections page and insert your monday.com API key");
    else if (id.includes("Airtable connection not found"))
      setError("Please connect your Airtable account on the connections page.");
    else navigate(`/workflow/${id}?v2=${v2}`);
  };

  const newInputSource: {
    name: string;
    action: string;
    logo: string;
    description: string;
    v2Only?: boolean;
    disabled?: boolean;
  }[] = [
    {
      name: "Input",
      action: "csv_or_webhook",
      logo: "https://storage.googleapis.com/public_image_assets/DownloadIconPurple.svg",
      description: "Import your data via CSV files or set up a webhook to import data into your workflow",
    },
  ];

  const legacyInputSources: {
    name: string;
    action: string;
    logo: string;
    description: string;
    v2Only?: boolean;
    disabled?: boolean;
  }[] = [
    {
      name: "Manual/CSV",
      action: "csv",
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/upload-csv-logo.svg",
      description: "Import Email Addresses, LinkedIn URLs, or Names",
    },
    {
      name: "Webhook",
      action: "webhook",
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/Group%2038162%20(1).png",
      description: "Pull in data from a webhook URL",
    },
    {
      name: "Hubspot",
      action: "hubspot",
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/HubSpot2.svg",
      description: "Import data from your HubSpot CRM and enrich it.",
    },
    {
      name: "Salesforce",
      action: "salesforce",
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/salesforce.svg",
      description: "Import data from your SalesForce CRM and enrich.",
    },
    {
      name: "PhantomBuster",
      action: "phantombuster",
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/phantombuster.svg",
      description: "Import data from PhantomBuster and enrich.",
    },
    {
      name: "EngageBay",
      action: "engagebay",
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/engagebay.png",
      description: "Import data from your EngageBay CRM and enrich.",
    },
    {
      name: "Sales Navigator",
      action: "sales_navigator",
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/navigator.svg",
      description: "Import people from a sales navigator URL and enrich their data.",
    },
    {
      name: "Operators List from Well Database",
      action: "operators_list",
      v2Only: true,
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/welldb.svg",
      description:
        "Import a list of operators from the Well Database filtering by number of wells, country, state, etc.",
    },
    {
      name: "Monday",
      action: "monday",
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/monday.svg",
      description: "Import data from Monday.com and enrich it.",
    },
    {
      name: "Pipedrive",
      action: "pipedrive",
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/pipedrive.png",
      description: "Import Organizations/Persons from Pipedrive and enrich them.",
    },
    {
      name: "Airtable",
      action: "airtable",
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/Airtable.svg",
      description: "Import data from Airtable and enrich it.",
    },
    {
      name: "List of Companies",
      action: "comapnies_list",
      v2Only: true,
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/floqer.svg",
      description:
        "Import a list of companies filtering by technologies they use, job openings, industries, size, revenue, etc.",
    },
    {
      name: "List of Companies via linkedin",
      action: "comapnies_crustdata_realtime_linkedin_list",
      v2Only: true,
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/floqer.svg",
      description:
        "Import a list of companies from linkedin filtering by keyword, comapny activities, department growth, etc.",
    },
  ];

  const NEXT_STEP_ACTIONS = [
    "sales_navigator",
    "comapnies_list",
    "operators_list",
    "comapnies_crustdata_realtime_linkedin_list",
    "pipedrive",
  ];

  if (next && selectedSource === "sales_navigator")
    return (
      <SalesNavigatorComponent
        stepsClose={close}
        workflowName={workflowName}
        selectedSource={selectedSource}
        selectedFolder={selectedFolder}
        close={setNext}
        v2={v2}
      />
    );

  if (next && selectedSource === "comapnies_list")
    return (
      <CompaniesListComponent
        stepsClose={close}
        workflowName={workflowName}
        selectedSource={selectedSource}
        selectedFolder={selectedFolder}
        close={setNext}
        v2={v2}
      />
    );

  if (next && selectedSource === "operators_list")
    return (
      <OperatorsListComponent
        stepsClose={close}
        workflowName={workflowName}
        selectedSource={selectedSource}
        selectedFolder={selectedFolder}
        close={setNext}
        v2={v2}
      />
    );

  if (next && selectedSource === "comapnies_crustdata_realtime_linkedin_list")
    return (
      <CompaniesListViaLinkedin
        stepsClose={close}
        workflowName={workflowName}
        selectedSource={selectedSource}
        selectedFolder={selectedFolder}
        close={setNext}
        v2={v2}
      />
    );

  if (next && selectedSource === "pipedrive")
    return (
      <PipedriveComponent
        stepsClose={close}
        workflowName={workflowName}
        selectedSource={selectedSource}
        selectedFolder={selectedFolder}
        close={setNext}
        v2={v2}
      />
    );

  return (
    <div className="flex flex-col font-favorit">
      {/* Breadcrumb */}
      <div className="w-full flex items-center justify-between px-8 min-h-[50px] z-30 sticky top-0 bg-white border-b-[0.5px] border-[#D3D3D3]">
        <span className="flex gap-4">
          <CollapsibleIcon isHeader />
          <span className=" font-medium text-center text-[#3F3F3F] flex gap-[5px] max-h-[24px]">
            <Breadcrumb className="text-base font-favorit">
              <Breadcrumb.Item onClick={close} className="cursor-pointer">
                <span className="flex items-center gap-1">
                  <img src={PlayIcon} className="h-5 w-5" />
                  <span className="font-light text-[#333333] hover:text-[#000000]">Your floqs</span>
                </span>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Create a new workflow</Breadcrumb.Item>
            </Breadcrumb>
          </span>
        </span>
      </div>

      <div className="p-5 px-0 flex flex-col gap-6 overflow-y-auto">
        <section className="grid grid-cols-4 gap-4 px-8 py-4">
          <div className="flex-1">
            <label className="block text-[#333333] text-lg mb-2">Name your workflow</label>
            <input
              value={workflowName}
              onChange={(e) => setWorkflowName(e.target.value)}
              placeholder="Enter name"
              onKeyDown={async(e) => {
                if (e.key === "Enter") {
                  NEXT_STEP_ACTIONS.includes(selectedSource)
                    ? (() => {
                        if (!workflowName.trim()) {
                          setError("Workflow Name cannot be empty");
                          return;
                        }
                        setNext(true);
                      })()
                    : (async () => {
                      message.info("Creating workflow...", 6);
                      await handleCreateWorkflow();
                    })();
                }
              }}
              className="w-full border-r border-black rounded"
            />
          </div>

          <div className="flex-1">
            <label className="block text-[#333333] text-lg mb-2">Select a folder</label>
            <FolderDropdown
              workflowFolders={folders}
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder}
            />
          </div>
          <div className="flex col-span-2 items-center justify-end space-x-2 w-full p-4 py-[11px]">
            <div className="text-red-500 font-semibold">
              {error && "Error creating Workflow: "}
              {error}
            </div>
            {NEXT_STEP_ACTIONS.includes(selectedSource) ? (
              <LoaderButtonBlack
                disabled={false}
                error=""
                loaderText="Loading..."
                text="Go To Next Step"
                onClickCallback={async () => {
                  if (!workflowName.trim()) {
                    setError("Workflow Name cannot be empty");
                    return;
                  }
                  setNext(true);
                }}
                btnClasses="w-fit text-white bg-black hover:bg-gray-800"
              />
            ) : (
              <LoaderButtonBlack
                disabled={false}
                error=""
                loaderText="Creating Workflow..."
                text="Create Workflow"
                onClickCallback={handleCreateWorkflow}
                btnClasses="w-fit text-white bg-black hover:bg-gray-800"
              />
            )}
          </div>
        </section>
        <hr />

        <section className="flex flex-col gap-6 pb-6">
          <div className="px-8">
            <span className="rounded-sm text-center min-h-[26px] px-2 py-[2px] text-[#1A007B] bg-[#F2EEFF]">NEW</span>
          </div>
          <div className="grid grid-cols-4 gap-4 px-8">
            {newInputSource.map((source) => {
              if (source.v2Only && !v2) return null;
              return (
                <ActionCard
                  key={source.name}
                  name={source.name}
                  logo={source.logo}
                  description={source.description}
                  selected={selectedSource === source.action}
                  disabled={source.disabled}
                  onClick={() => setSelectedSource(source.action)}
                  isSidebarCollapsed={isSidebarCollapsed}
                />
              );
            })}
          </div>
        </section>
        <hr />

        <div className="px-8">
          <span>LEGACY</span>
        </div>
        <div className="grid grid-cols-4 gap-4 px-8">
          {legacyInputSources.map((source) => {
            if (source.v2Only && !v2) return null;
            return (
              <ActionCard
                key={source.name}
                name={source.name}
                logo={source.logo}
                description={source.description}
                selected={selectedSource === source.action}
                disabled={source.disabled}
                onClick={() => setSelectedSource(source.action)}
                isSidebarCollapsed={isSidebarCollapsed}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default WorkflowUI;

import FloqerLogo from "@Assets/SVGs/floqerLogo.svg";
import RadioIconBrown from "@Assets/SVGs/RadioIconBrown.svg";
import { useState } from "react";

type SignalCardProps = {
  id: string;
  name: string;
  description?: string;
  logo?: string;
  onClick: () => void;
  isSidebarCollapsed: boolean;
  isActive?: boolean;
};

const signalIdToRingClass: Record<string, string> = {
  CRUSTDATA_MONITOR_COMPANY_HEADCOUNT_INCREASED: "ring-[#E3AFF0]",
  CRUSTDATA_MONITOR_FUNDING_ANNOUNCEMENTS: "ring-[#E3AFF0]",
  CRUSTDATA_MONITOR_JOBS: "ring-[#B9FFA6]",
  CRUSTDATA_MONITOR_LINKEDIN_POSTS_WITH_KEYWORDS: "ring-[#A0E4FF]",
  CRUSTDATA_MONITOR_PERSON_STARTING_NEW_JOB: "ring-[#B9FFA6]",
  FIRECRAWL_SCRAPE_URL: "ring-[#B6B3FF]",
  FLOQER_COMPANY_LIST_BUILDING_TS: "ring-[#E3AFF0]",
  FLOQER_LIST_BUILDING_SN: "ring-[#70A9FF]",
  SALESNAV_PEOPLE_SEARCH: "ring-[#70A9FF]",
  WELL_DB_CREATE_OPERATORS_LIST: "ring-[#FFA6A6]",
  HUBSPOT_CONTACT_ADDED: "ring-[#FFE9DE]",
  HUBSPOT_PULL_DATA: "ring-[#FFE9DE]",
  MONDAY_ITEM_CREATED: "ring-[#FFF2BF]",
  PIPEDRIVE_IMPORT_DATA: "ring-[#C9FFE2]",
  SALESFORCE_PULL_DATA: "ring-[#DDEFFF]",
};

const SignalCard = ({ id, name, description, logo, onClick, isSidebarCollapsed, isActive }: SignalCardProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const ringColorClass = signalIdToRingClass[id] || "ring-[#DADADA]";

  return (
    <div
      key={id}
      className={`flex flex-col gap-2 border border-gray-300 rounded-xl p-5 hover:shadow-md cursor-pointer h-[140px] tracking-tight transition-all duration-200 ease-in-out ${isHovered ? `${ringColorClass} ring-2` : ""}`}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={`flex w-full items-center gap-2 ${isSidebarCollapsed ? "w-[80%]" : "w-[95%]"}`}>
        <img src={logo || FloqerLogo} alt="signal-logo" className="w-12 h-12 object-contain" />
        <h3
          className={`leading-tight ${isSidebarCollapsed ? "text-[18px]" : "text-[15px]"} line-clamp-2 font-medium text-[#3F3F3F]`}
        >
          {name}
        </h3>
        {isActive && <img src={RadioIconBrown} alt="active-signal" className="w-6 h-6" />}
      </div>
      <p className={`text-[#333333] tracking-normal font-light ${isSidebarCollapsed ? "text-[13px]" : "text-[11px]"}`}>
        {description}
      </p>
    </div>
  );
};

export default SignalCard;

import { setStateType } from "@/utils/constants";
import { ResponseConfiguration } from "@/utils/interfaces";
import { ConfigProvider, Table, TableColumnsType, Checkbox } from "antd";
import { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import InputDropdown from "./InputDropdown";

type Template = {
  label: string;
  value: string;
  idx: number;
  type: string;
};

type Map = {
  [key: string]: string;
};

type Props = {
  headers: any;
  data: any[];
  responses: ResponseConfiguration[];
  map: Map;
  setMap: setStateType<Map>;
  setDuplicateMap: setStateType<Record<string, boolean>>;
  duplicateMap: Record<string, boolean>;
  setNewResponses: setStateType<ResponseConfiguration[]>;
};

const MapColumns = ({
  headers,
  data,
  responses,
  map,
  setMap,
  setDuplicateMap,
  duplicateMap,
  setNewResponses,
}: Props) => {
  const [rows, setRows] = useState<any[]>([]);
  const [err, setErr] = useState<string[]>([]);
  const [renderedRowsCount, setRenderedRowsCount] = useState(0);

  useEffect(() => {
    if (Object.keys(headers).length > 0) {
      setRows(
        Object.keys(headers)
          .filter((item: any) => item !== "key")
          .map((item: any, idx: number) => {
            return {
              key: idx,
              columns: headers[item],
              sample: data
                .map((row) => {
                  if (!row[idx] || rows[idx] === "" || (typeof row[idx] === "string" && row[idx]?.trim() === ""))
                    return "---";
                  return row[idx];
                })
                .slice(0, 3),
              template: responses.map((item: ResponseConfiguration) => {
                return {
                  label: item.name,
                  value: item.responseId + "",
                  idx: idx,
                  type: item.type || "string",
                };
              }),
            };
          })
      );
      if (Object.values(map).length === 0) {
        setMap(
          Object.keys(headers)
            .filter((item: any) => item !== "key")
            .reduce((acc: Map, item: any) => {
              acc[item] = "";
              return acc;
            }, {})
        );
      }
    }
  }, [headers, data, JSON.stringify(responses)]);

  const mapper = (idx: string, responseId: string) => {
    if (err.includes(idx)) {
      const same = Object.entries(map).filter((item) => item[1] !== "" && item[1] === map[idx]);
      if (same.length === 2) setErr((prev) => prev.filter((item) => item !== idx && item !== same[0][0]));
      else if (same.length > 2) setErr((prev) => prev.filter((item) => item !== idx));
    }
    const same = Object.entries(map).filter((item) => item[1] === responseId);
    if (same.length > 0 && responseId !== "") setErr((prev) => [...prev, same[0][0], idx]);

    setMap((prev) => ({
      ...prev,
      [idx]: responseId,
    }));
  };

  const getTypeFromSamples = (samples: string[]) => {
    const filteredSamples = samples.filter((item) => item && item !== "---").map((item) => item.toString());
    if (filteredSamples.length === 0) return "string";
    if (filteredSamples.every((item) => !isNaN(Number(item)))) return "number";
    else if (filteredSamples.every((item) => item.match(/^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/)))
      return "url";
    else if (filteredSamples.every((item) => item.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)))
      return "email";
    else return "string";
  };

  const columns: TableColumnsType<any> = [
    {
      title: "CSV Headers",
      dataIndex: "columns",
      width: "20%",
    },
    {
      title: "Sample data",
      dataIndex: "sample",
      width: "35%",
      render: (textArr: string[]) => {
        return (
          <div className="flex flex-col">
            {textArr.map((item: string, idx: number) => (
              <div key={idx} className="overflow-hidden whitespace-nowrap text-ellipsis w-[400px]">
                {item}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex justify-center">
          <FaArrowRight color="#333333" />
        </div>
      ),
      dataIndex: "columns",
      width: "5%",
      render: () => {
        return (
          <div className="flex justify-center">
            <FaArrowRight color="#333333" />
          </div>
        );
      },
    },
    {
      title: "Your Floq's inputs",
      dataIndex: "template",
      width: "30%",
      render: (textArr: Template[], record: any) => {
        if (renderedRowsCount <= record.key) return null;
        const columnLabel = record.columns;
        const columnType = getTypeFromSamples(record.sample);
        return (
          <InputDropdown
            options={textArr.filter((item) => !Object.values(map).includes(item.value))}
            mapper={mapper}
            idx={record?.key}
            err={err}
            allResponses={textArr}
            map={map}
            columnLabel={columnLabel}
            columnType={columnType}
            addNewResponse={(response: ResponseConfiguration) => setNewResponses((prev) => [...prev, response])}
          />
        );
      },
    },
    {
      title: "Filter dupes",
      dataIndex: "key",
      width: "10%",
      render: (text: number) => {
        const name = map[text];
        if (name === "") return null;
        return (
          <Checkbox
            onClick={(_) => {
              setDuplicateMap((prev) => {
                const temp =
                  name !== ""
                    ? {
                        ...prev,
                        // @ts-ignore
                        [name]: !duplicateMap?.[name],
                      }
                    : prev;
                return temp;
              });
            }}
            checked={duplicateMap?.[name]}
          />
        );
      },
    },
  ];

  // Render rows sequentially with a slight delay
  useEffect(() => {
    if (renderedRowsCount < rows.length) {
      const timer = setTimeout(() => {
        setRenderedRowsCount(renderedRowsCount + 1);
      }, 50); // Delay for each row
      return () => clearTimeout(timer);
    }
  }, [renderedRowsCount, rows]);

  return (
    <div className="relative w-full h-full overflow-auto py-2 max-h-[65vh]">
      <ConfigProvider>
        <Table columns={columns} dataSource={rows} showHeader bordered pagination={false} />
      </ConfigProvider>
    </div>
  );
};

export default MapColumns;

import React, { useState } from 'react';
import { CheckOutlined } from '@ant-design/icons';

type Props = {
  onSubmit: (feedback: string[]) => void;
  onCancel: () => void;
  onRegenerate: () => void;
};

const OutputFeedback: React.FC<Props> = ({ onSubmit, onCancel }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [feedback, setFeedback] = useState<string[]>(['', '', '']);
  
  const steps = [
    {
      title: 'Define output you were expecting for each row',
      isRequired: true,
    },
    {
      title: 'What did you expect to see?',
      isRequired: false,
    },
    {
      title: 'Any other feedback?',
      isRequired: false,
    },
  ];

  const updateFeedback = (value: string) => {
    const newFeedback = [...feedback];
    newFeedback[currentStep] = value;
    setFeedback(newFeedback);
  };

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      onSubmit(feedback);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const isNextDisabled = steps[currentStep].isRequired && !feedback[currentStep];

  return (
    <div className="mb-6">
      <h3 className="font-medium text-gray-800 mb-4">{steps[currentStep].title}</h3>
      
      <textarea
        value={feedback[currentStep]}
        onChange={(e) => updateFeedback(e.target.value)}
        placeholder=""
        rows={4}
        className="w-full p-2 mb-4 border border-gray-300 rounded-md"
      />
      
      {/* Navigation row */}
      <div className="flex items-center justify-start mb-4">
        <button 
          onClick={prevStep} 
          disabled={currentStep === 0}
          className="flex items-center justify-center w-8 h-8 rounded-md border border-gray-300"
        >
          ←
        </button>
        
        <span className="text-sm text-gray-500 mx-2">
          {currentStep + 1} / {steps.length}
        </span>
        
        <button 
          onClick={nextStep} 
          disabled={isNextDisabled}
          className="flex items-center justify-center w-8 h-8 rounded-md border border-gray-300"
        >
          →
        </button>
      </div>
      
      {/* Buttons row */}
      <div className="grid grid-cols-2 gap-2">
        <button 
          onClick={onCancel} 
          className="w-full py-2 bg-red-50 text-red-600 hover:bg-red-100 border border-red-200 rounded-md text-center"
        >
          Cancel
        </button>
        <button 
          onClick={() => onSubmit(feedback)}
          className="w-full py-2 bg-gray-800 text-white rounded-md flex items-center justify-center gap-1"
        >
          <CheckOutlined /> Regenerate
        </button>
      </div>
    </div>
  );
};

export default OutputFeedback; 
import { Select, SelectProps, Spin } from "antd";
import { useEffect, useState, useCallback } from "react";
import debounce from "lodash/debounce";

interface InfiniteSelectProps extends Omit<SelectProps, "options"> {
  fetchData: (page: number) => Promise<any[]>;
  searchFetch?: (searchTerm: string) => Promise<any[]>;
  transformOption?: (item: any) => { label: React.ReactNode; value: string | number };
  pageSize?: number;
}

const InfiniteSelect = ({
  fetchData,
  searchFetch,
  transformOption,
  pageSize = 20,
  ...selectProps
}: InfiniteSelectProps) => {
  const [page, setPage] = useState(1);
  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const loadMoreData = useCallback(async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const newData = await fetchData(page);
      if (newData.length < pageSize) {
        // setHasMore(false);
      }
      setOptions((prev) => [...prev, ...newData]);
      setPage((prev) => prev + 1);
    } catch (error) {
      console.error("Error loading more data:", error);
    } finally {
      setLoading(false);
    }
  }, [loading, hasMore, page, pageSize, fetchData]);

  const handleSearch = useCallback(
    debounce(async (value: string) => {
      if (!value.trim() || !searchFetch) return;

      setLoading(true);
      try {
        const searchResults = await searchFetch(value);
        setOptions(searchResults);
        setHasMore(false); // Disable infinite scroll during search
      } catch (error) {
        console.error("Error searching data:", error);
      } finally {
        setLoading(false);
      }
    }, 300),
    [searchFetch]
  );

  const handlePopupScroll = useCallback(
    debounce((e: React.UIEvent<HTMLDivElement>) => {
      const target = e.target as HTMLDivElement;
      const scrollPosition = Math.abs(target.scrollHeight - target.scrollTop - target.clientHeight);

      if (scrollPosition <= 10 && !loading && hasMore && !searchTerm) {
        loadMoreData();
      }
    }, 150),
    [loadMoreData, loading, hasMore, searchTerm]
  );

  useEffect(() => {
    loadMoreData();
    return () => {
      handlePopupScroll.cancel();
      handleSearch.cancel();
    };
  }, []);

  const defaultTransform = (item: any) => ({
    label: item.name || item.label,
    value: item.id || item.value,
  });

  const onSearch = (value: string) => {
    setSearchTerm(value);
    if (!value.trim()) {
      // Reset state when search is cleared
      setPage(1);
      setOptions([]);
      setLoading(false);
      setHasMore(true);
      setLoading(true);
      fetchData(page).then((res) => {
        setOptions((prev) => [...prev, ...res]);
        setPage((prev) => prev + 1);
        setLoading(false);
      });
    } else if (searchFetch) {
      handleSearch(value);
    }
  };

  const filterOption = (input: string, option: any) => {
    if (searchFetch) return true; // Don't filter when using API search
    return (
      (option?.name?.toLowerCase() ?? "").includes(input.toLowerCase()) ||
      (option?.label?.toString().toLowerCase() ?? "").includes(input.toLowerCase())
    );
  };

  return (
    <Select
      {...selectProps}
      options={options.map(transformOption || defaultTransform)}
      onPopupScroll={handlePopupScroll}
      onSearch={onSearch}
      filterOption={filterOption}
      listHeight={256}
      menuItemSelectedIcon={null}
      showSearch
      loading={loading}
      dropdownRender={(menu) => (
        <>
          {menu}
          {loading && (
            <div style={{ padding: "8px", textAlign: "center" }}>
              <Spin size="small" />
            </div>
          )}
        </>
      )}
    />
  );
};

export default InfiniteSelect;

import { message, Popconfirm, Popover, Table } from "antd";
import { useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { deleteWatcher, updateSignal } from "../apis";
import { setStateType } from "@/utils/constants";
import FloqerLogo from "@Assets/SVGs/floqerLogo.svg";
import SignalCard from "../Components/SignalCard";
import PauseIcon from "@Assets/SVGs/PauseIcon.svg";
import PlayIcon from "@Assets/SVGs/PlayIcon.svg";
import DeleteIconNew from "@Assets/SVGs/DeleteIconNew.svg";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import { SIGNAL_ID_TO_URL } from "@/utils/urls";
import RadioIconBlack from "@Assets/SVGs/RadioIconBlack.svg";
import SearchInput from "../Components/SearchInput";
import { CustomTooltip } from "../Components/CustomTooltip";

type Props = {
  isSidebarCollapsed: boolean;
  availableSignals: any[];
  userSignals: any[];
  setCrmSignals: setStateType<any[]>;
};
// const types = [
//   {
//     name: "CRM Watcher(Signal)",
//     id: "crmWatcher",
//   },
//   {
//     name: "CRM Lists(Static)",
//     id: "crmList",
//   },
// ];

// const CRM = ({ availableSignals, setCreate }: Props) => {
//   return (
//     <div className="w-full h-full flex flex-col gap-16 px-5">
//       hello
//       {types.map((type) => (
//         <div key={type.id} className="flex flex-col gap-4">
//           <h2 className="text-lg font-medium text-[#1E0068]">{type.name}</h2>
//           <div className="grid grid-cols-4 gap-4">
//             {availableSignals
//               .filter((signal) => signal.type === type.id)
//               .map((signal) => (
//                 <button
//                   key={signal.id}
//                   className="flex items-center text-start gap-3 border-2 border-gray-300 rounded-md p-3 hover:border-primary hover:shadow-md transition"
//                   onClick={() => setCreate(signal.id)}
//                 >
//                   <img src={signal.logo || FloqerLogo} alt={signal.name} className="w-8 h-8" />
//                   <h3 className="text-md font-normal">{signal.name}</h3>
//                 </button>
//               ))}
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };
const CRM = ({ isSidebarCollapsed, availableSignals, userSignals, setCrmSignals }: Props) => {
  const [loading, setLoading] = useState<any>({});
  const [visibleCount, setVisibleCount] = useState(4);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchTermList, setSearchTermList] = useState<string>("");
  const [isDeleting, setIsDeleting] = useState<Record<string, boolean>>({});
  const navigate = useNavigate();

  const handleLoadMore = () => {
    if (visibleCount >= sortedSignals.length) {
      // Reset to initial count if all signals are visible
      setVisibleCount(4);
    } else {
      // Load 4 more signals
      setVisibleCount((prevCount) => prevCount + 4);
    }
  };

  const sortedSignals = useMemo(() => {
    return [...availableSignals].sort((a, b) => a.id.localeCompare(b.id));
  }, [availableSignals]);

  const filteredSignals = sortedSignals.filter((signal) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return (
      signal.name.toLowerCase().includes(lowerCaseSearchTerm) ||
      signal.description.toLowerCase().includes(lowerCaseSearchTerm)
    );
  });

  const filteredSignalsList = userSignals.filter((signal) => {
    const lowerCaseSearchTerm = searchTermList.toLowerCase();
    return signal.name.toLowerCase().includes(lowerCaseSearchTerm);
  });

  const handleUpdateSignal = async (id: string, signal_id: string, status: string) => {
    try {
      setLoading((prev: any) => ({ ...prev, [id]: true }));
      await updateSignal(id, SIGNAL_ID_TO_URL[signal_id as keyof typeof SIGNAL_ID_TO_URL], status);
      setCrmSignals((prev) => prev.map((signal) => (signal.id === id ? { ...signal, status } : signal)));
    } catch (error) {
      message.error("Failed to update signal");
    } finally {
      setLoading((prev: any) => ({ ...prev, [id]: false }));
    }
  };

  const handleDeleteSignal = async (id: string): Promise<void> => {
    setIsDeleting((prev) => ({ ...prev, [id]: true }));
    try {
      const response = await deleteWatcher(id);

      if (response.message) {
        setCrmSignals((prevSignals) => prevSignals.filter((signal) => signal.id !== id));
        message.success("Signal deleted successfully!");
      } else {
        message.error("Failed to delete signal. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting signal:", error);
      message.error("An error occurred while deleting the signal.");
    } finally {
      setIsDeleting((prev) => ({ ...prev, [id]: false }));
    }
  };

  const getActionStyles = (actionName: string) => {
    switch (actionName) {
      case "HUBSPOT ACTIVE LIST":
      case "HUBSPOT CONTACT PULL":
        return "text-[#C34203] bg-[#FFE9DE]";
      case "SALESFORCE LIST":
        return "text-[#005C7F] bg-[#DDEFFF]";
      case "MONDAY CRM LIST":
        return "text-[#684D00] bg-[#FFF2BF]";
      case "PIPEDRIVE LIST":
        return "text-[#017737] bg-[#C9FFE2]";
      case "ENGAGE BAY LIST":
        return "text-[#683993] bg-[#F3E7FF]";
      default:
        return "text-[#000000] bg-[#F2F2F2]";
    }
  };

  const formatValue = (value: any): string | JSX.Element => {
    if (typeof value === "object" && value !== null) {
      if (Array.isArray(value)) {
        return (
          <span>
            {value.map((item, index) => (
              <span key={index} className="inline-block p-1 rounded text-sm mr-2">
                {formatValue(item)}
              </span>
            ))}
          </span>
        );
      } else {
        return (
          <span className="inline-block p-1 rounded text-sm mr-2">
            {Object.entries(value).map(([k, v]) => (
              <span key={k}>
                <strong>{k}:</strong> {formatValue(v)}{" "}
              </span>
            ))}
          </span>
        );
      }
    }
    return String(value);
  };

  const getStatusIcon = (status: string, id: string, signal_id: string) => {
    switch (status) {
      case "crm-active":
        return (
          <button
            className="text-sm font-medium text-primary"
            title="Pause Signal"
            onClick={() => handleUpdateSignal(id, signal_id, "crm-paused")}
          >
            <img src={PauseIcon} alt="pause" className="h-6 w-6" />
          </button>
        );
      case "crm-paused":
        return (
          <button
            className="text-sm font-medium text-primary"
            title="Resume Signal"
            onClick={() => handleUpdateSignal(id, signal_id, "crm-active")}
          >
            <img src={PlayIcon} alt="play" className="h-6 w-6" />
          </button>
        );
      case "crm-static":
        return (
          <button
            className="text-sm font-medium text-primary"
            title="Static Signal"
            onClick={() => message.info("This is a static signal")}
          >
            <img src={PlayIcon} alt="play" className="h-6 w-6" />
          </button>
        );
      default:
        return (
          <button
            className="text-sm font-medium text-primary"
            title="Resume Signal"
            onClick={() => handleUpdateSignal(id, signal_id, "active")}
          >
            <img src={PlayIcon} alt="play" className="h-6 w-6" />
          </button>
        );
    }
  };

  const tableDataSource = useMemo(
    () =>
      (searchTermList ? filteredSignalsList : userSignals).map((signal) => {
        const {
          signal_id,
          created_at,
          expiration_date,
          payload,
          paused_at,
          name,
          status,
          id,
          lead_count,
          refreshed_at,
        } = signal;
        const matchingSignal = availableSignals.find((availableSignal) => availableSignal.id === signal_id) || {};
        const { logo = "", action = {} } = matchingSignal;

        const actionTagStyles = getActionStyles(action?.name);
        return {
          key: id,
          name: (
            <Link to={`/list/${id}`} className="text-base tracking-[0.02em] flex items-center gap-3 pl-4">
              <img src={logo || FloqerLogo} alt={name} className="w-10 h-10" />
              <h3 className="font-normal truncate">{name}</h3>
            </Link>
          ),
          type: (
            <div className="flex items-stretch gap-2">
              <CustomTooltip
                content={
                  <div className="text-[#6b6b6b] text-xs text-center font-extralight tracking-wider">
                    {action?.description}
                  </div>
                }
              >
                <span className={`px-2 rounded-sm min-h-[24px] flex items-center ${actionTagStyles}`}>
                  {action?.name}
                </span>
              </CustomTooltip>
            </div>
          ),
          lead_count: lead_count,
          status,
          payload: payload,
          refreshed_at: <div className="font-light">{moment(refreshed_at).utc().local().format("DD MMM, YYYY")}</div>,
          created_at: <div className="font-light">{moment(created_at).utc().local().format("DD MMM, YYYY")}</div>,
          paused_at: (
            <div className="font-light">
              {paused_at ? moment(paused_at).utc().local().format("DD MMM, YYYY") : "Not Paused"}
            </div>
          ),
          expiration_date: (
            <div className="font-light">{moment(expiration_date).utc().local().format("DD MMM, YYYY")}</div>
          ),
          actions: (
            <div className="flex items-center gap-2">
              {loading[id] ? (
                <span className="h-6 w-6 flex justify-center items-center">
                  <SpinnerStatus />
                </span>
              ) : (
                getStatusIcon(status, id, signal_id)
              )}
              <div className="h-6 border-r border-gray-300 mx-1" />
              {isDeleting[id] ? (
                <span className="h-6 w-6 flex justify-center items-center">
                  <SpinnerStatus />
                </span>
              ) : (
                <Popconfirm
                  title="Are you sure you want to delete?"
                  icon={""}
                  placement="topLeft"
                  onConfirm={() => handleDeleteSignal(id)}
                  okText="Delete"
                  cancelText="Cancel"
                  okButtonProps={{ danger: true }}
                >
                  <button title="Delete Signal">
                    <img src={DeleteIconNew} alt="delete" className="h-6 w-6" />
                  </button>
                </Popconfirm>
              )}
            </div>
          ),
        };
      }),
    [
      filteredSignalsList,
      userSignals,
      searchTermList,
      availableSignals,
      loading,
      handleUpdateSignal,
      handleDeleteSignal,
    ]
  );

  const tableColumns = [
    {
      title: <div className="pl-4">Signal</div>,
      dataIndex: "name",
      key: "name",
      width: "350px",
      ellipsis: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "350px",
    },
    {
      title: "Leads",
      dataIndex: "lead_count",
      key: "lead_count",
      width: "150px",
    },
    {
      title: "Filters Applied",
      dataIndex: "payload",
      key: "payload",
      width: "250px",
      render: (payload: any) => {
        const formatValue = (val: any) => {
          if (Array.isArray(val)) {
            return val.slice(0, 3).join(", ") + (val.length > 3 ? "..." : "");
          }
          if (typeof val === "string") {
            return val.length > 30 ? val.substring(0, 30) + "..." : val;
          }
          if (typeof val === "object" && val !== null) {
            return "{...}";
          }
          return JSON.stringify(val);
        };

        const formattedPreview = Object.entries(payload)
          .slice(0, 2)
          .map(([key, value]) => `${key}: ${formatValue(value)}`)
          .join(", ");

        const fullContent = (
          <div className="w-96 max-h-60 text-xs font-mono overflow-y-auto whitespace-normal break-all">
            {Object.entries(payload).map(([key, value]) => (
              <div key={key} className="mb-1 flex border-b py-2">
                <span className="font-bold bg-gray-200 px-1 py-[2px] text-nowrap h-fit">{key}:</span>
                <span className="ml-2 text-black">{JSON.stringify(value)}</span>
              </div>
            ))}
          </div>
        );

        return (
          <Popover content={fullContent} trigger="click" placement="bottom">
            <span className="text-black cursor-pointer bg-gray-200 p-1">
              {formattedPreview} {Object.keys(payload).length > 2 && "..."}
            </span>
          </Popover>
        );
      },
    },
    {
      title: "Stop Date",
      dataIndex: "expiration_date",
      key: "expiration_date",
      width: "150px",
    },
    {
      title: "Last Refreshed",
      dataIndex: "refreshed_at",
      key: "refreshed_at",
      width: "150px",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      width: "150px",
    },
    {
      title: "Paused At",
      dataIndex: "paused_at",
      key: "paused_at",
      width: "150px",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "110px",
      fixed: "right" as const,
    },
  ];

  const tableHeaderStyle = {
    borderBlock: "0.5px solid #333333",
    paddingBlock: "8px",
    background: "white",
  };

  return (
    <div className="w-full h-full flex flex-col gap-8">
      <div className="flex flex-col gap-4 px-8">
        <div className="flex items-center justify-between h-[40px]">
          <h2 className="flex items-center gap-2 text-2xl font-medium tracking-tighter text-[#333333]">
            <img src={RadioIconBlack} alt="signal-icon" className="h-7 w-7" />
            CRM
          </h2>
          <SearchInput placeholder="Search for signal" onSearch={setSearchTerm} />
        </div>
        <div className="grid grid-cols-4 gap-6">
          {(searchTerm ? filteredSignals : sortedSignals).slice(0, visibleCount).map((signal) => (
            <SignalCard
              key={signal.id}
              id={signal.id}
              name={signal.name}
              logo={signal.logo}
              description={signal.description}
              onClick={() => navigate(`/crm?create=${signal.id}`)}
              isSidebarCollapsed={isSidebarCollapsed}
              isActive={signal.type === "crmWatcher"}
            />
          ))}
        </div>

        {(searchTerm ? filteredSignals : sortedSignals).length > 0 ? (
          (searchTerm ? filteredSignals : sortedSignals).length >= 5 && (
            <div>
              <button
                onClick={handleLoadMore}
                className="text-[#333333] text-sm decoration-[1.5px] underline underline-offset-2 leading-relaxed font-medium"
              >
                {visibleCount >= (searchTerm ? filteredSignals : sortedSignals).length ? "Show less" : "Load More"}
              </button>
            </div>
          )
        ) : (
          <div className="text-center py-4">
            <p className="text-gray-500 text-sm">No results found.</p>
            <p className="text-gray-400 text-xs mt-1">Please try a different search term.</p>
          </div>
        )}
      </div>
      <hr />
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between px-8 h-[40px] bg-white sticky top-[49px] z-30">
          <h2 className="text-2xl font-medium text-[#3F3F3F]">All signals</h2>
          <SearchInput placeholder="Search for signal name" onSearch={setSearchTermList} />
        </div>
        <Table
          dataSource={tableDataSource}
          columns={tableColumns.map((column) => ({
            ...column,
            title: <div className="font-favorit text-[#828282] font-normal text-sm">{column.title}</div>,
            className: "font-favorit",
          }))}
          pagination={false}
          className="font-favorit"
          scroll={{ x: 1500, y: 780 }}
          components={{
            header: {
              cell: (props: any) => (
                <th
                  {...props}
                  style={{
                    ...tableHeaderStyle,
                    ...props.style,
                  }}
                />
              ),
            },
          }}
        />
      </div>
    </div>
  );
};

export default CRM;

import { useState } from "react";
import { motion, useTransform, AnimatePresence, useMotionValue, useSpring } from "framer-motion";

type TooltipProps = {
  children: React.ReactNode;
  text: string;
  position?: "top" | "bottom" | "left" | "right";
};

export const AnimatedTooltipItem = ({ children, text, position = "top" }: TooltipProps) => {
  const [isHovered, setIsHovered] = useState(false);
  // Create a motion value to allow a slight horizontal parallax effect.
  const x = useMotionValue(0);
  const springConfig = { stiffness: 100, damping: 5 };
  // We remove any rotation so the text remains horizontal.
  const translateX = useSpring(useTransform(x, [-100, 100], [-30, 30]), springConfig);

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    const halfWidth = event.currentTarget.offsetWidth / 2;
    x.set(event.nativeEvent.offsetX - halfWidth);
  };

  const positionClasses: Record<string, string> = {
    top: "bottom-full left-1/2 -translate-x-1/2 -mb-3",
    bottom: "top-full left-1/2 -translate-x-1/2 mt-3",
    left: "right-full top-1/2 -translate-y-1/2 -mr-3",
    right: "left-full top-1 -translate-y-1/2 ml-4",
  };

  return (
    <div
      className="relative inline-block"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseMove={handleMouseMove}
    >
      {children}
      <AnimatePresence>
        {isHovered && (
          <motion.div
            initial={{ opacity: 0, scale: 0.6 }}
            animate={{ opacity: 1, scale: 1 }}
            // exit={{ opacity: 0, scale: 0.6 }}
            transition={{ type: "spring", stiffness: 260, damping: 10 }}
            // Only apply horizontal translation, ensuring no rotation so the text remains horizontal.
            style={{ translateX }}
            className={`absolute ${positionClasses[position]} bg-black text-white text-md px-3 py-1 rounded shadow-lg whitespace-nowrap z-50`}
          >
            {text}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

import { useWorkflow } from "@/contexts/WorkflowContext";
import { addWorkflowToSubfloq } from "@/utils/apis";
import { _debounce } from "ag-grid-enterprise";
import { Input, message } from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import CreditsIcon from "@/assets/SVGs/GoldDollarCoin.svg";

type AddSubflowViewerProps = {
  setMode: (mode: "Shift" | null) => void;
  mode: "Shift" | null;
  onActionDropAction?: (action: any, actions: any[], hoveredSource?: string, targetX?: string | null) => void; // Add this
  doubleClickAction?: (actionId: string) => void;
};

// This is the modal that is integrated with the AddAction modal, where you'll see all your personal/organizational/community subfloqs.
const AddSubfloqModal = ({ setMode, mode, onActionDropAction, doubleClickAction }: AddSubflowViewerProps) => {
  const [hoveredActionSource, setHoveredActionSource] = useState("");
  const [hoveredActionTarget, setHoveredActionTarget] = useState<string | null>(null);
  const [filteredSubfloqs, setFilteredSubfloqs] = useState<any[]>([]);

  const { availableActions, availableSubfloqs, workflowConfig } = useWorkflow();
  const subfloqCreditsMap = new Map<string, number>();

  useEffect(() => {
    if (mode !== "Shift") message.info("You've entered selection mode, click on 'Cancel Creation' to exit", 6);
  }, [mode]);

  useEffect(() => {
    setFilteredSubfloqs(availableSubfloqs);
  }, []);

  const debounceFn = useCallback(
    _debounce((value: string) => {
      if (!availableSubfloqs) return;
      // Filter subfloqs based on the search query
      const filtered = availableSubfloqs.filter((subfloq) => subfloq.name?.toLowerCase().includes(value.toLowerCase()));

      setFilteredSubfloqs(filtered);
    }, 500),
    [availableSubfloqs]
  );

  const onDragHandler = (e: React.DragEvent) => {
    const connections = document.querySelectorAll(`.connection-svg`);
    connections?.forEach((connection) => {
      connection.classList.forEach((cls) => {
        if (cls.includes("path-highlight") || cls.includes("path-highlight-error")) {
          connection.classList.remove("path-highlight");
          connection.classList.remove("path-highlight-error");
          if (hoveredActionSource) setHoveredActionSource("");
          if (hoveredActionTarget) setHoveredActionTarget(null);
        }
      });
    });

    const addActionButtons = document.querySelectorAll(".nodrag.nopan.add-action-button");
    let hovering = false;
    let hoveredButton: Element | null = null;
    // offset to factor in the drag button distance
    let offSetX = 0;
    addActionButtons.forEach((button) => {
      const buttonRect = button.getBoundingClientRect();
      const buttonCenterX = buttonRect.left + buttonRect.width / 2 + offSetX;
      const buttonCenterY = buttonRect.top + buttonRect.height / 2;

      const mouseX = e.pageX || e.clientX + (document.documentElement.scrollLeft || document.body.scrollLeft);
      const mouseY = e.pageY || e.clientY + (document.documentElement.scrollTop || document.body.scrollTop);
      const distance = Math.sqrt(Math.pow(mouseX - buttonCenterX, 2) + Math.pow(mouseY - buttonCenterY, 2));
      if (distance <= 100) {
        hovering = true;
        hoveredButton = button;
      }
    });

    const containerElement = document.querySelector(".add-action-container");
    if (containerElement && hovering) {
      if (!hoveredButton) return;

      let source = "";
      let target = "";
      (hoveredButton as Element).classList?.forEach((cls) => {
        if (cls.includes("source-")) source = cls.replace("source-", "");
        if (cls.includes("target-")) target = cls.replace("target-", "");
      });

      if (source) {
        const connections = document.querySelectorAll(`.connection-svg.source-${source}.target-${target}`);
        connections.forEach((connection) => {
          if (target.includes("-cond") && !target.includes("-end")) connection.classList.add("path-highlight-error");
          else connection.classList.add("path-highlight");
          setHoveredActionSource(source);
          setHoveredActionTarget(target.includes("-end") ? null : target);
        });
      }
    }
  };

  const onDragEnd = async (e: React.DragEvent, subfloq: any) => {
    e.preventDefault();
    const containerElement = document.querySelector(".add-action-container");
    if (containerElement) {
      const containerRect = containerElement.getBoundingClientRect();
      const isOverFlow =
        e.clientX >= containerRect.left &&
        e.clientX <= containerRect.right &&
        e.clientY >= containerRect.top &&
        e.clientY <= containerRect.bottom;

      const actIdSub = uuidv4().toString();

      const updatedActions = subfloq.actions.map((action: any) => {
        const actPKId = uuidv4().toString();
        const actDetails = availableActions.find((act) => act.id === action.actionName) || {};
        return {
          ...action,
          actionDetails: actDetails,
          id: `${action.id}_${actIdSub}`,
          pk_id: actPKId,
          prevAction: action.prevAction.map((prev: any) => ({
            ...prev,
            actionId:
              prev.actionId !== null
                ? `${prev.actionId}_${actIdSub}`
                : action.id.includes("id2")
                  ? hoveredActionSource
                  : null,
          })),
          nextAction: action.nextAction.map((next: any) => ({
            ...next,
            actionId:
              next.actionId !== null
                ? `${next.actionId}_${actIdSub}`
                : action.id.includes("idout")
                  ? hoveredActionTarget
                  : null,
          })),
        };
      });
      const firstAction = updatedActions.find((act: any) => act.id.includes("id2"));
      // console.log("firstAction", firstAction, updatedActions);
      // console.log("source", hoveredActionSource, "target", hoveredActionTarget);

      if (isOverFlow || hoveredActionTarget?.includes("-cond")) return message.error("Cannot add an action here");
      onActionDropAction && onActionDropAction(firstAction, updatedActions, hoveredActionSource, hoveredActionTarget);
    }
    await addWorkflowToSubfloq(subfloq.id, workflowConfig.id, workflowConfig.name, "add");
  };

  const updateCredits = (subfloqId: string, credits: number) => {
    const credit = subfloqCreditsMap.get(subfloqId);
    if (!credit) {
      const cred =
        typeof credits === "number"
          ? credits
          : Object.keys(credits).reduce((acc: number, key: string) => acc + credits[key], 0);
      subfloqCreditsMap.set(subfloqId, cred);
    } else {
      const cred =
        typeof credits === "number"
          ? credits
          : Object.keys(credits).reduce((acc: number, key: string) => acc + credits[key], 0);
      subfloqCreditsMap.set(subfloqId, credit + cred);
    }
  };

  return (
    <div className="mt-2 flex flex-col add-action-container h-[80vh]">
      <div className="flex flex-col gap-2 px-2 flex-none">
        <Input
          type="text"
          className="w-full rounded"
          placeholder="Search for subfloqs"
          onChange={(e) => debounceFn(e?.target?.value)}
        />
        <div className="flex items-center justify-between">
          <p></p>
          {mode === "Shift" ? (
            <span onClick={() => setMode(null)} className="bg-black text-white rounded px-6 py-1 cursor-pointer">
              + Create
            </span>
          ) : (
            <span
              onClick={() => setMode("Shift")}
              className="bg-white text-[#9B0000] border border-gray-500 rounded px-6 py-1 cursor-pointer"
            >
              Cancel Creation
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-2 px-2 pb-4 overflow-y-auto">
        <p>Your Subfloqs</p>
        {filteredSubfloqs.length>0 ? filteredSubfloqs
          .filter((subfloq) => subfloq.share_type === "individual")
          .map((subfloq: any) => (
            <div
              key={subfloq.id}
              className="flex flex-col items-start justify-between gap-2 bg-white border-[1.5px] rounded-lg p-4 border-gray-400"
              draggable // Make it draggable
              onDrag={(e) => onDragHandler(e)} // Dragging
              onDragEnd={(e) => onDragEnd(e, subfloq)} // End drag
              onDoubleClickCapture={() => {
                doubleClickAction && doubleClickAction(subfloq);
              }}
            >
              <div>
                <p className="capitalize font-bold text-md">{subfloq.name}</p>
                <p className="text-gray-500 text-sm">{subfloq.description}</p>
              </div>
              <div className="w-full flex justify-between items-center py-2">
                <div className="flex gap-2 max-w-[70%] overflow-x-auto">
                  {subfloq.subfloq_actions
                    .map((actionId: string) => availableActions.find((action) => action.id === actionId))
                    .filter(Boolean) // Remove undefined values in case some actions are missing
                    .map((action: any) => {
                      updateCredits(subfloq.id, action!.credits);
                      return (
                        <div key={action!.id} className="flex items-center gap-2 border p-1 rounded">
                          <img src={action!.logo} alt={action!.name} className="w-6 h-6" />
                        </div>
                      );
                    })}
                </div>
                  <div className="py-2 space-x-[6px] flex items-center ">
                    <span className="text-[12px] font-light text-gray-600 bg-gray-100 w-fit px-1">Total Cost:</span>
                    <img src={CreditsIcon} alt="Credits" className="h-4 w-4" />
                    <span className="text-black text-sm">{subfloqCreditsMap.get(subfloq.id)}</span>
                  </div>
              </div>
              <div className="text-xs text-gray-400">Created: {moment(subfloq.created_at).format("Do MMMM, YYYY")}</div>
            </div>
          )) : 
          (
            <div className="flex items-center justify-center h-full mt-10">
              <p className="text-gray-500">No Subfloqs Found!</p>
            </div>
          )}
      </div>
    </div>
  );
};

export default AddSubfloqModal;

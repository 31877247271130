import { useState } from "react";
import { Checkbox, Input, Popover } from "antd";
import { AiOutlineSearch } from "react-icons/ai";
import { BsEye } from "react-icons/bs";

interface ColumnSelectorProps {
  payloads: any;
  setPayloads: (update: any) => void;
  propertiesFilter: any[];
  defaultSelectedValues: string[];
}

const ColumnSelector = ({ payloads, setPayloads, propertiesFilter, defaultSelectedValues }: ColumnSelectorProps) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredOptions = propertiesFilter
    ? propertiesFilter.filter(
        (property) =>
          property?.label?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          property?.name?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  return (
    <div className="flex gap-2 items-center border px-2">
      <div className="border-r px-2">{`${payloads?.properties?.length}/${propertiesFilter?.length}`}</div>

      <Popover
        trigger={["click"]}
        content={
          <div className="w-64 p-2">
            <Input
              prefix={<AiOutlineSearch className="text-[#999]" />}
              placeholder="Search properties/fields"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="max-h-60 overflow-auto mt-2">
              {filteredOptions.map((property) => {
                const propertyValue = property?.id || property?.name;
                return (
                  <div key={propertyValue} className="flex items-center gap-2 py-1">
                    <Checkbox
                      checked={payloads?.properties?.includes(propertyValue)}
                      disabled={defaultSelectedValues.includes(propertyValue)}
                      onChange={(e) => {
                        setPayloads((prev: any) => {
                          const updatedProperties = e.target.checked
                            ? Array.from(new Set([...prev.properties, propertyValue]))
                            : prev.properties.filter((p: string) => p !== propertyValue);
                          return { ...prev, properties: updatedProperties };
                        });
                      }}
                    />
                    <span className="truncate">{property?.label || property.name}</span>
                  </div>
                );
              })}
            </div>
          </div>
        }
      >
        <span className="min-h-[24px] flex gap-2 items-center cursor-pointer">
          <BsEye /> Edit columns
        </span>
      </Popover>
    </div>
  );
};

export default ColumnSelector;

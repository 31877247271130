import { fetchTransactionsForWorkflowActionDataPart } from "@/utils/apis";
import KeyValue from "./KeyValue";
import { useEffect, useState } from "react";
import CreditHistoryViewer from "./CreditHistorViewer";

type Props = {
  open: boolean;
  data: any;
  credits: number | { [key: string]: number };
};

const JsonViewer = ({ open, data }: Props) => {
  const { response, workflowDataActionPartId, action, workflowId } = data;
  const [newData, setNewData] = useState<any>({});
  const [transactionHistory, setTransactionHistory] = useState<any[]>([]);

  useEffect(() => {
    if (!open) setNewData({});
    if (!response || !workflowDataActionPartId || !action) {
      const filteredData = Object.fromEntries(Object.entries(data).filter(([key]) => key !== "undefined"));
      setNewData(filteredData);
    } else {
      const filteredData = Object.fromEntries(Object.entries(response).filter(([key]) => key !== "undefined"));
      setNewData(filteredData);
    }
    getTransactionHistory(workflowDataActionPartId, action);
  }, [open, data]);

  const getTransactionHistory = async (workflowDataActionPartId: string, action: any) => {
    const dataId = workflowDataActionPartId;
    const wfId = action?.workflowId || workflowId;
    if (!wfId || !dataId) return;

    const response = await fetchTransactionsForWorkflowActionDataPart(wfId, dataId);
    setTransactionHistory(response.transactions || []);
  };

  return (
    <>
      <div className="flex flex-col gap-2">
        {Object.entries(newData).length > 0 ? (
          Object.entries(newData).map(([key, value]: [string, any]) => (
            <KeyValue key={key} keyName={key} value={value} open={open} />
          ))
        ) : (
          <KeyValue keyName="status" value="Completed" open={open} />
        )}
      </div>
      <div className="mt-4 flex items-center">
        <CreditHistoryViewer transactionHistory={transactionHistory} />
      </div>
    </>
  );
};

export default JsonViewer;

import React, { KeyboardEvent, useState } from "react";

interface MultiLineInputProps {
  value: string[];
  onChange: (values: string[]) => void;
  className?: string;
  placeholder?: string;
}

const MultiLineInput: React.FC<MultiLineInputProps> = ({ value, onChange, className = "", placeholder = "" }) => {
  const [currentInput, setCurrentInput] = useState("");

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (currentInput.trim()) {
        const newValues = [...value, currentInput.trim()];
        onChange(newValues);
        setCurrentInput("");
      }
    }
  };

  return (
    <div className="relative flex flex-col">
      <textarea
        value={currentInput}
        onChange={(e) => setCurrentInput(e.target.value)}
        onKeyDown={handleKeyDown}
        className={`w-full p-1 rounded ${className}`}
        placeholder={placeholder}
        rows={2}
      />
    </div>
  );
};

export default MultiLineInput;

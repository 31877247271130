import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../contexts/UserContext.tsx";
import { LOADER_TYPES, setStateType } from "../../utils/constants.tsx";
import Loader from "../../Components/Loader.tsx";

type Props = {
  setRefresh: setStateType<number>;
};

const ProfileSetup = ({ setRefresh }: Props) => {
  const [profileData, setProfileData] = useState({
    first_name: "",
    last_name: "",
    job_title: "",
    org_id: "",
  });
  const [loading, setLoading] = useState(false);
  const { updateProfile, getOrgName, currentUser, logout } = useUser();
  const [orgName, setOrgName] = useState("");
  //   const { addProspectList } = useData();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) return;
    const getOrg = async () => {
      setLoading(true);
      const org = await getOrgName();
      if (!org) {
        alert(
          "No account found for your organization with this email, please contact us or the appropriate team lead in your organization or Sign Up for your organization as a lead."
        );
        await logout();
        navigate("/");
        return;
      }
      setOrgName(org.name);
      setProfileData((p) => {
        return {
          ...p,
          org_id: org.id,
        };
      });
      setLoading(false);
    };
    getOrg();
  }, []);

  const profileSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    await updateProfile(profileData);
    // await addProspectList("Default");
    setRefresh((r: number) => r + 1);
    navigate("/");
    setLoading(false);
  };

  const handleLogout = async () => {
    await logout();
  };

  if (loading) {
    return (
      <div className="h-screen w-screen flex justify-center items-center">
        <Loader payload={"profile setup"} loaderType={LOADER_TYPES.generating} />
      </div>
    );
  }
  return (
    <div className="bg-white w-screen h-screen rounded-lg p-8">
      <div className="m-auto w-fit h-full justify-center flex flex-col items-start gap-8">
        <div className="flex flex-col gap-2">
          <h2 className="text-4xl font-bold">
            Let's get your <span className="work-email">profile</span> setup now
          </h2>
          <p>Setup your account to skyrocket your sales</p>
        </div>
        <form className="flex flex-col items-start gap-8 w-full" onSubmit={profileSubmit}>
          <div className="flex justify-start gap-4 w-full">
            <div className="flex flex-col gap-1 w-full">
              <label>First name</label>
              <input
                className="p-2 rounded-md border border-black w-full"
                type="name"
                value={profileData.first_name}
                autoComplete="off"
                onChange={(e) => setProfileData({ ...profileData, first_name: e.target.value })}
                required
                placeholder="First name"
              />
            </div>
            <div className="flex flex-col gap-1 w-full">
              <label>Last name</label>
              <input
                className="p-2 rounded-md border border-black w-full"
                type="name"
                value={profileData.last_name}
                onChange={(e) => setProfileData({ ...profileData, last_name: e.target.value })}
                required
                placeholder="Last name"
              />
            </div>
          </div>
          <div className="flex flex-col gap-1 w-full">
            <label>Job title</label>
            <input
              className="p-2 rounded-md border border-black w-full"
              type="name"
              value={profileData.job_title}
              onChange={(e) => setProfileData({ ...profileData, job_title: e.target.value })}
              required
              placeholder="Job title"
            />
          </div>
          <div className="flex flex-col gap-1 w-full">
            <label>Company name</label>
            <input className="p-2 rounded-md border border-black w-full" type="name" value={orgName} readOnly />
          </div>
          <div className="flex items-center justify-between w-full">
            <button className="btn p-4" type="submit">
              Create profile
            </button>
            <button
              className="p-4 rounded-lg border-2 border-primary bg-white hover:bg-gray-200 transition cursor-pointer font-semibold"
              type="button"
              onClick={() => handleLogout()}
            >
              Sign Out
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileSetup;

import { App, message } from "antd";
import { useEffect, useState } from "react";
import { FaCoins, FaRegCopy } from "react-icons/fa";

type Props = {
  open: boolean;
  data: any;
  credits: any;
};

const HgInsightsTechStackViewer = ({ open, data, credits }: Props) => {
  const [newData, setNewData] = useState<any>(data);
  const [activeIndices, setActiveIndices] = useState<number[]>([]);

  useEffect(() => {
    if (!open) setNewData({});
    else setNewData(data);
  }, [open, data]);

  const toggleAccordion = (index: number) => {
    setActiveIndices((prevIndices) =>
      prevIndices.includes(index) ? prevIndices.filter((i) => i !== index) : [...prevIndices, index]
    );
  };

  const handleCopy = async (content: any, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      const textToCopy = typeof content === "object" ? JSON.stringify(content, null, 2) : content;
      await navigator.clipboard.writeText(textToCopy);
      message.success("Content copied to clipboard!");
    } catch (err) {
      message.error("Failed to copy content.");
    }
  };

  const fieldsMapping: Record<string, string> = {
    vendor_name: "Vendor Name",
    hg_vendor_id: "HG Vendor ID",
    product_name: "Product Name",
    hg_product_id: "HG Product ID",
    vendor_domain: "Vendor Domain",
    product_attributes: "Product Attributes",
    product_description: "Product Description",
    hg_product_categories: "HG Product Categories",
    hg_product_category_id: "HG Product Category ID",
    most_specific_category: "Most Specific Category",
    product_intensity_total: "Product Intensity Total",
    product_locations_total: "Product Locations Total",
    product_last_verified_date: "Product Last Verified Date",
    product_first_verified_date: "Product First Verified Date",
  };

  return (
    <>
      <App>
        <div className="flex flex-col gap-2 text-[14px]">
          <div className="text-[20px] font-bold">Technologies Found</div>
          <hr />
          <div className="space-y-2">
            {newData &&
              newData["Technologies Found"] &&
              Object.entries(newData["Technologies Found"] || {}).map(([_, well]: [string, any], idx: number) => (
                <div key={idx} className="border border-gray-200 p-2 rounded-sm">
                  <div
                    className="flex justify-between items-center cursor-pointer"
                    onClick={() => toggleAccordion(idx)}
                  >
                    <p className="text-[16px] font-bold">Technology {idx + 1}</p>
                    <FaRegCopy className="text-gray-600" onClick={(e) => handleCopy(well, e)} />
                  </div>
                  {activeIndices.includes(idx) && (
                    <div className="space-y-2 mt-2 border p-2 rounded-sm">
                      {well.map((item: any, i: number) => (
                        <div key={i} className="flex flex-col group relative">
                          <span className="text-[#575757] font-semibold">
                            {fieldsMapping[item.responseStructureId]}
                          </span>
                          <div className="bg-[#FFF7DF] px-2 py-1 flex justify-between items-center">
                            {Array.isArray(item.value) ? (
                              <pre className="text-xs w-[90%] whitespace-pre-wrap">
                                {JSON.stringify(item.value, null, 2)}
                              </pre>
                            ) : typeof item.value === "object" ? (
                              <pre className="text-xs w-[90%] whitespace-pre-wrap">
                                {JSON.stringify(item.value, null, 2)}
                              </pre>
                            ) : (
                              <p className="w-[95%]">{item.value || "No data found"}</p>
                            )}
                            <FaRegCopy
                              className="text-gray-600 cursor-pointer"
                              onClick={(e) => handleCopy(item.value, e)}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
          </div>
          <div className="text-[20px] font-bold">Num of Technologies Found</div>
          <hr />
          <div className="border border-gray-200 p-2 rounded-sm">
            <div className="flex justify-between">
              <p className="text-[16px]">{newData["Num of Technologies Found"] || ""}</p>
              <FaRegCopy
                className="text-gray-600 cursor-pointer"
                onClick={(e) => handleCopy(newData["Num of Technologies Found"], e)}
              />
            </div>
          </div>
        </div>
      </App>
      <div className="mt-4 flex items-center">
        <h1 className="font-semibold text-md">Total cost:</h1>
        <div className="flex items-center ml-2 bg-purple-100 rounded p-1">
          <span className="font-bold text-md">{newData["Num of Technologies Found"] * credits}</span>
          <FaCoins className="ml-1 text-purple-500 w-5" />
        </div>
      </div>
    </>
  );
};

export default HgInsightsTechStackViewer;

import { App, message } from "antd";
import { useEffect, useState } from "react";
import { FaCoins, FaRegCopy } from "react-icons/fa";

type Props = {
  open: boolean;
  data: any;
  credits: number | { [key: string]: number };
};

const SalesNavEmployeeViewer = ({ open, data, credits }: Props) => {
  const [_, setNewData] = useState<any>(data);
  const [activeIndices, setActiveIndices] = useState<number[]>([]);

  useEffect(() => {
    if (!open) setNewData({});
    else setNewData(data);
  }, [open, data]);

  const toggleAccordion = (index: number) => {
    setActiveIndices((prevIndices) =>
      prevIndices.includes(index) ? prevIndices.filter((i) => i !== index) : [...prevIndices, index]
    );
  };
  const handleCopyJSON = async (content: any, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      const jsonString = JSON.stringify(content, null, 2); // Convert JSON to string with indentation
      await navigator.clipboard.writeText(jsonString);
      message.success("Content copied to clipboard!");
      // Check the data that got copied
      await navigator.clipboard.readText();
    } catch (err) {
      message.error("Failed to copy content.");
      console.error("Copy error:", err);
    }
  };
  const handleCopyText = async (content: any, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(content);
      message.success("Content copied to clipboard!");
      // Check the data that got copied
      await navigator.clipboard.readText();
    } catch (err) {
      message.error("Failed to copy content.");
      console.error("Copy error:", err);
    }
  };

  const EmployeeLengthKey: any =
    data && Object.keys(data).length ? Object.keys(data).find((key) => typeof data[key] === "number") : "";
  const EmployeeLength = EmployeeLengthKey ? data[EmployeeLengthKey] : 0;
  const employeesKey =
    data && Object.keys(data).length ? Object.keys(data).find((key) => typeof data[key] === "object") : "";
  const employees = employeesKey ? data[employeesKey] : [];

  return (
    <>
      <App>
        <div className="flex flex-col gap-2 !text-[14px]">
          <div className="flex w-full justify-between">
            <div className="text-[20px] font-bold">Retrieved list of employees</div>
            <div className="text-[20px] font-bold">Count: {EmployeeLength}</div>
          </div>
          <hr />

          <div className="space-y-2">
            {employees &&
              Object?.values(employees)?.map((employee: any, idx: number) => (
                <div key={idx}>
                  <div
                    key={idx}
                    className={`${
                      activeIndices.includes(1001 + idx + 1)
                        ? ""
                        : " bg-[#FAFAFA] border border-gray-200 p-2 rounded-sm"
                    }  cursor-pointer mt-2 mb-2`}
                  >
                    <div className="flex items-center justify-between" onClick={() => toggleAccordion(1001 + idx + 1)}>
                      <p className="text-[16px] font-bold flex items-center gap-x-2">
                        Employee {idx + 1 < 10 ? "0" + (idx + 1) : idx + 1}
                        {activeIndices.includes(1001 + idx + 1) ? (
                          <UpArrowNew color="black" />
                        ) : (
                          <DownArrowNew color="black" />
                        )}
                      </p>
                      <p
                        className="cursor-pointer flex items-center gap-x-2"
                        onClick={(e) => handleCopyJSON(employee, e)}
                      >
                        Copy All <FaRegCopy className="text-gray-600" />
                      </p>
                    </div>
                    {activeIndices.includes(1001 + idx + 1) && (
                      <div className="">
                        <div className="space-y-2 mt-2 border p-2 rounded-sm">
                          <div className="col-span-1 flex flex-col w-full group relative">
                            <span className="w-full text-[#575757]">Full Name</span>
                            <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                              {!employee[0]?.value || employee[0]?.value === "No data found"
                                ? "\u00A0"
                                : employee[0]?.value}
                              <p
                                className="cursor-pointer hidden group-hover:block"
                                onClick={(e) => handleCopyText(employee[0]?.value, e)}
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col w-full group">
                            <span className="w-full text-[#575757]">Current Title</span>{" "}
                            <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                              {!employee[21]?.value || employee[21]?.value === "No data found"
                                ? "\u00A0"
                                : employee[21]?.value}
                              <p
                                className="cursor-pointer hidden group-hover:block "
                                onClick={(e) => handleCopyText(employee[21]?.value, e)}
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col w-full group relative">
                            <span className="w-full text-[#575757]">Linkedin Profile URL</span>{" "}
                            <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                              <p className="w-[95%]">
                                {!employee[2]?.value || employee[2]?.value === "No data found"
                                  ? "\u00A0"
                                  : employee[2]?.value}
                              </p>
                              <p
                                className="cursor-pointer hidden group-hover:block "
                                onClick={(e) => handleCopyText(employee[2]?.value, e)}
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col w-full group relative">
                            <span className="w-full text-[#575757]">Linkedin Profile URN</span>{" "}
                            <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                              <p className="w-[95%]">
                                {!employee[3]?.value || employee[3]?.value === "No data found"
                                  ? "\u00A0"
                                  : employee[3]?.value}
                              </p>
                              <p
                                className="cursor-pointer hidden group-hover:block "
                                onClick={(e) => handleCopyText(employee[3]?.value, e)}
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col w-full group relative">
                            <span className="w-full text-[#575757]">Flagship Profile URL</span>{" "}
                            <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                              <p className="w-[95%]">
                                {!employee[7]?.value || employee[7]?.value === "No data found"
                                  ? "\u00A0"
                                  : employee[7]?.value}
                              </p>
                              <p
                                className="cursor-pointer hidden group-hover:block "
                                onClick={(e) => handleCopyText(employee[7]?.value, e)}
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col w-full group relative">
                            <span className="w-full text-[#575757]">Headline</span>{" "}
                            <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                              <p className="w-[95%]">
                                {!employee[9]?.value || employee[9]?.value === "No data found"
                                  ? "\u00A0"
                                  : employee[9]?.value}
                              </p>
                              <p
                                className="cursor-pointer hidden group-hover:block"
                                onClick={(e) => handleCopyText(employee[9]?.value, e)}
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col w-full group relative">
                            <span className="w-full text-[#575757]">Summary</span>{" "}
                            <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                              <p className="w-[95%]">
                                {!employee[10]?.value || employee[10]?.value === "No data found"
                                  ? "\u00A0"
                                  : employee[10]?.value}
                              </p>
                              <p
                                className="cursor-pointer hidden group-hover:block"
                                onClick={(e) => handleCopyText(employee[10]?.value, e)}
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col w-full group relative">
                            <span className="w-full text-[#575757]">Twitter Handle</span>{" "}
                            <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                              <p className="w-[95%]">
                                {!employee[18]?.value || employee[18]?.value === "No data found"
                                  ? "\u00A0"
                                  : employee[18]?.value}
                              </p>
                              <p
                                className="cursor-pointer hidden group-hover:block "
                                onClick={(e) => handleCopyText(employee[20]?.value, e)}
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>
                          {/* <div className="flex flex-col w-full relative gap-y-1">
                            <span className="w-full text-[#575757]">Emails</span>
                            {!employee[18]?.value || employee[18]?.value === "No data found"
                              ? "\u00A0"
                              : employee[18]?.value.map((phone: any, idx: number) => (
                                  <div
                                    key={idx}
                                    className="w-full bg-[#FFF7DF] px-2 py-1 break-words group flex justify-between items-center"
                                  >
                                    <p className="w-[95%]">
                                      {!phone.raw_number || phone.raw_number === "No data found"
                                        ? "\u00A0"
                                        : phone.raw_number}
                                    </p>
                                    <p
                                      className="cursor-pointer hidden group-hover:block "
                                      onClick={(e) => handleCopyText(phone.raw_number, e)}
                                    >
                                      <FaRegCopy className="text-gray-600" />
                                    </p>
                                  </div>
                                ))}
                          </div> */}
                          <div className="flex flex-col w-full group relative">
                            <span className="w-full text-[#575757]">Location</span>{" "}
                            <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                              {!employee[1]?.value || employee[1]?.value === "No data found"
                                ? "\u00A0"
                                : employee[1]?.value}
                              <p
                                className="cursor-pointer hidden group-hover:block "
                                onClick={(e) => handleCopyText(employee[1]?.value, e)}
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col w-full group relative">
                            <span className="w-full text-[#575757]">Num Of Connections</span>{" "}
                            <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                              {!employee[11]?.value || employee[11]?.value === "No data found"
                                ? "\u00A0"
                                : employee[11]?.value}
                              <p
                                className="cursor-pointer hidden group-hover:block "
                                onClick={(e) => handleCopyText(employee[11]?.value, e)}
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col w-full group relative">
                            <span className="w-full text-[#575757]">Default Position Company Linkedin Id</span>{" "}
                            <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                              {!employee[5]?.value || employee[5]?.value === "No data found"
                                ? "\u00A0"
                                : employee[5]?.value}
                              <p
                                className="cursor-pointer hidden group-hover:block "
                                onClick={(e) => handleCopyText(employee[5]?.value, e)}
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col w-full group relative">
                            <span className="w-full text-[#575757]">Related Colleague Company Id</span>{" "}
                            <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                              {!employee[12]?.value || employee[12]?.value === "No data found"
                                ? "\u00A0"
                                : employee[12]?.value}
                              <p
                                className="cursor-pointer hidden group-hover:block "
                                onClick={(e) => handleCopyText(employee[12]?.value, e)}
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>
                        </div>

                        <div
                          className={`${
                            activeIndices.includes(2001) ? "" : "border border-gray-200 rounded-sm bg-[#FAFAFA] p-2"
                          } cursor-pointer space-y-2 mt-2`}
                        >
                          <div className="flex items-center justify-between" onClick={() => toggleAccordion(2001)}>
                            <p className="!text-[16px] font-semibold flex items-center gap-x-2">
                              Experiences
                              {activeIndices.includes(2001) ? (
                                <UpArrowNew color="black" />
                              ) : (
                                <DownArrowNew color="black" />
                              )}
                            </p>
                            <p
                              className="cursor-pointer flex items-center gap-x-2"
                              onClick={(e) => handleCopyJSON(employee[14]?.value, e)}
                            >
                              Copy All <FaRegCopy className="text-gray-600" />
                            </p>
                          </div>
                          {activeIndices.includes(2001) && (
                            <div className="space-y-2">
                              {employee[14]?.value.map((exp: any, idx: number) => (
                                <div
                                  key={idx}
                                  className={`${
                                    activeIndices.includes(2001 + idx + 1) ? "" : " bg-[#FAFAFA]"
                                  } border border-gray-200 p-2 rounded-sm cursor-pointer `}
                                >
                                  <div
                                    className="flex items-center justify-between"
                                    onClick={() => toggleAccordion(2001 + idx + 1)}
                                  >
                                    <p className="text-[16px] font-semibold flex items-center gap-x-2">
                                      {idx + 1 < 10 ? "0" + (idx + 1) : idx + 1}
                                      {activeIndices.includes(2001 + idx + 1) ? (
                                        <UpArrowNew color="black" />
                                      ) : (
                                        <DownArrowNew color="black" />
                                      )}
                                    </p>
                                    <p
                                      className="cursor-pointer flex items-center gap-x-2"
                                      onClick={(e) => handleCopyJSON(exp, e)}
                                    >
                                      Copy All <FaRegCopy className="text-gray-600" />
                                    </p>
                                  </div>
                                  {activeIndices.includes(2001 + idx + 1) && (
                                    <div className="space-y-2">
                                      <div className="flex flex-col w-full group relative">
                                        <span className="w-full text-[#575757]">Title</span>{" "}
                                        <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                                          {!exp.title || exp.title === "No data found" ? "\u00A0" : exp.title}
                                          <p
                                            className="cursor-pointer hidden group-hover:block "
                                            onClick={(e) => handleCopyText(exp.title, e)}
                                          >
                                            <FaRegCopy className="text-gray-600" />
                                          </p>
                                        </div>
                                      </div>
                                      <div className="flex flex-col w-full group relative">
                                        <span className="w-full text-[#575757]">Company Name</span>{" "}
                                        <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                                          {!exp.company_name || exp.company_name === "No data found"
                                            ? "\u00A0"
                                            : exp.company_name}
                                          <p
                                            className="cursor-pointer hidden group-hover:block "
                                            onClick={(e) => handleCopyText(exp.company_name, e)}
                                          >
                                            <FaRegCopy className="text-gray-600" />
                                          </p>
                                        </div>
                                      </div>
                                      <div className="flex flex-col w-full group relative">
                                        <span className="w-full text-[#575757]">Company Linkedin Id</span>{" "}
                                        <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                                          {!exp.company_linkedin_id || exp.company_linkedin_id === "No data found"
                                            ? "\u00A0"
                                            : exp.company_linkedin_id}
                                          <p
                                            className="cursor-pointer hidden group-hover:block "
                                            onClick={(e) => handleCopyText(exp.company_linkedin_id, e)}
                                          >
                                            <FaRegCopy className="text-gray-600" />
                                          </p>
                                        </div>
                                      </div>
                                      <div className="flex flex-col w-full group relative">
                                        <span className="w-full text-[#575757]">Start Date</span>{" "}
                                        <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                                          {!exp.start_date || exp.start_date === "No data found"
                                            ? "\u00A0"
                                            : exp.start_date}
                                          <p
                                            className="cursor-pointer hidden group-hover:block "
                                            onClick={(e) => handleCopyText(exp.start_date, e)}
                                          >
                                            <FaRegCopy className="text-gray-600" />
                                          </p>
                                        </div>
                                      </div>
                                      <div className="flex flex-col w-full group relative">
                                        <span className="w-full text-[#575757]">End Date</span>{" "}
                                        <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                                          {!exp.end_date || exp.end_date === "No data found" ? "\u00A0" : exp.end_date}
                                          <p
                                            className="cursor-pointer hidden group-hover:block "
                                            onClick={(e) => handleCopyText(exp.end_date, e)}
                                          >
                                            <FaRegCopy className="text-gray-600" />
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        <div
                          className={`${
                            activeIndices.includes(3001) ? "" : "border border-gray-200 rounded-sm bg-[#FAFAFA] p-2"
                          } cursor-pointer space-y-2 mt-2`}
                        >
                          <div className="flex items-center justify-between" onClick={() => toggleAccordion(3001)}>
                            <p className="!text-[16px] font-semibold flex items-center gap-x-2">
                              Education
                              {activeIndices.includes(3001) ? (
                                <UpArrowNew color="black" />
                              ) : (
                                <DownArrowNew color="black" />
                              )}
                            </p>
                            <p
                              className="cursor-pointer flex items-center gap-x-2"
                              onClick={(e) => handleCopyJSON(employee[15]?.value, e)}
                            >
                              Copy All <FaRegCopy className="text-gray-600" />
                            </p>
                          </div>
                          {activeIndices.includes(3001) && (
                            <div className="space-y-2">
                              {employee[15]?.value.map((exp: any, idx: number) => (
                                <div
                                  key={idx}
                                  className={`${
                                    activeIndices.includes(3001 + idx + 1) ? "" : " bg-[#FAFAFA]"
                                  } border border-gray-200 p-2 rounded-sm cursor-pointer `}
                                >
                                  <div
                                    className="flex items-center justify-between"
                                    onClick={() => toggleAccordion(3001 + idx + 1)}
                                  >
                                    <p className="text-[16px] font-semibold flex items-center gap-x-2">
                                      {idx + 1 < 10 ? "0" + (idx + 1) : idx + 1}
                                      {activeIndices.includes(3001 + idx + 1) ? (
                                        <UpArrowNew color="black" />
                                      ) : (
                                        <DownArrowNew color="black" />
                                      )}
                                    </p>
                                    <p
                                      className="cursor-pointer flex items-center gap-x-2"
                                      onClick={(e) => handleCopyJSON(exp, e)}
                                    >
                                      Copy All <FaRegCopy className="text-gray-600" />
                                    </p>
                                  </div>
                                  {activeIndices.includes(3001 + idx + 1) && (
                                    <div className="space-y-2">
                                      <div className="flex flex-col w-full group relative">
                                        <span className="w-full text-[#575757]">Degree Name</span>{" "}
                                        <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                                          {!exp.degree_name || exp.degree_name === "No data found"
                                            ? "\u00A0"
                                            : exp.degree_name}
                                          <p
                                            className="cursor-pointer hidden group-hover:block "
                                            onClick={(e) => handleCopyText(exp.degree_name, e)}
                                          >
                                            <FaRegCopy className="text-gray-600" />
                                          </p>
                                        </div>
                                      </div>
                                      <div className="flex flex-col w-full group relative">
                                        <span className="w-full text-[#575757]">Institute Name</span>{" "}
                                        <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                                          {!exp.institute_name || exp.institute_name === "No data found"
                                            ? "\u00A0"
                                            : exp.institute_name}
                                          <p
                                            className="cursor-pointer hidden group-hover:block "
                                            onClick={(e) => handleCopyText(exp.institute_name, e)}
                                          >
                                            <FaRegCopy className="text-gray-600" />
                                          </p>
                                        </div>
                                      </div>
                                      <div className="flex flex-col w-full group relative">
                                        <span className="w-full text-[#575757]">Field Of Study</span>{" "}
                                        <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                                          {!exp.field_of_study || exp.field_of_study === "No data found"
                                            ? "\u00A0"
                                            : exp.field_of_study}
                                          <p
                                            className="cursor-pointer hidden group-hover:block "
                                            onClick={(e) => handleCopyText(exp.field_of_study, e)}
                                          >
                                            <FaRegCopy className="text-gray-600" />
                                          </p>
                                        </div>
                                      </div>
                                      <div className="flex flex-col w-full group relative">
                                        <span className="w-full text-[#575757]">Start Date</span>{" "}
                                        <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                                          {!exp.start_date || exp.start_date === "No data found"
                                            ? "\u00A0"
                                            : exp.start_date}
                                          <p
                                            className="cursor-pointer hidden group-hover:block "
                                            onClick={(e) => handleCopyText(exp.start_date, e)}
                                          >
                                            <FaRegCopy className="text-gray-600" />
                                          </p>
                                        </div>
                                      </div>
                                      <div className="flex flex-col w-full group relative">
                                        <span className="w-full text-[#575757]">End Date</span>{" "}
                                        <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center">
                                          {!exp.end_date || exp.end_date === "No data found" ? "\u00A0" : exp.end_date}
                                          <p
                                            className="cursor-pointer hidden group-hover:block "
                                            onClick={(e) => handleCopyText(exp.end_date, e)}
                                          >
                                            <FaRegCopy className="text-gray-600" />
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        <div
                          className={`${
                            activeIndices.includes(4001) ? "" : "border border-gray-200 rounded-sm bg-[#FAFAFA] p-2"
                          } cursor-pointer space-y-2 mt-2`}
                        >
                          <div className="flex items-center justify-between" onClick={() => toggleAccordion(4001)}>
                            <p className="!text-[16px] font-semibold flex items-center gap-x-2">
                              Skills
                              {activeIndices.includes(4001) ? (
                                <UpArrowNew color="black" />
                              ) : (
                                <DownArrowNew color="black" />
                              )}
                            </p>
                            <p
                              className="cursor-pointer flex items-center gap-x-2"
                              onClick={(e) => handleCopyJSON(employee[13]?.value, e)}
                            >
                              Copy All <FaRegCopy className="text-gray-600" />
                            </p>
                          </div>
                          {activeIndices.includes(4001) && (
                            <div className="gap-y-2 gap-x-2 grid grid-cols-2">
                              {employee[13]?.value.map((exp: any, idx: number) => (
                                <div
                                  key={idx}
                                  className=" bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center col-span-1 group"
                                >
                                  {!exp || exp === "No data found" ? "\u00A0" : exp}
                                  <p
                                    className="cursor-pointer hidden group-hover:block "
                                    onClick={(e) => handleCopyText(exp, e)}
                                  >
                                    <FaRegCopy className="text-gray-600" />
                                  </p>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        <div
                          className={`${
                            activeIndices.includes(5001) ? "" : "border border-gray-200 rounded-sm bg-[#FAFAFA] p-2"
                          } cursor-pointer space-y-2 mt-2`}
                        >
                          <div className="flex items-center justify-between" onClick={() => toggleAccordion(5001)}>
                            <p className="!text-[16px] font-semibold flex items-center gap-x-2">
                              Languages
                              {activeIndices.includes(5001) ? (
                                <UpArrowNew color="black" />
                              ) : (
                                <DownArrowNew color="black" />
                              )}
                            </p>
                            <p
                              className="cursor-pointer flex items-center gap-x-2"
                              onClick={(e) => handleCopyJSON(employee[13]?.value, e)}
                            >
                              Copy All <FaRegCopy className="text-gray-600" />
                            </p>
                          </div>
                          {activeIndices.includes(5001) && (
                            <div className="gap-y-2 gap-x-2 grid grid-cols-2">
                              {employee[19]?.value.map((exp: any, idx: number) => (
                                <div
                                  key={idx}
                                  className=" bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center col-span-1 group"
                                >
                                  {!exp || exp === "No data found" ? "\u00A0" : exp}
                                  <p
                                    className="cursor-pointer hidden group-hover:block "
                                    onClick={(e) => handleCopyText(exp, e)}
                                  >
                                    <FaRegCopy className="text-gray-600" />
                                  </p>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <hr className="" />
                </div>
              ))}
          </div>
        </div>
      </App>
      <div className="mt-4 flex items-center">
        <h1 className="font-semibold text-md">Total cost:</h1>
        <div className="flex items-center ml-2 bg-purple-100 rounded p-1">
          <span className="font-bold text-md">
            {typeof credits === "number" ? credits * EmployeeLength : Object.values(credits)[0] * EmployeeLength}
          </span>
          <FaCoins className="ml-1 text-purple-500 w-5" />
        </div>
      </div>
    </>
  );
};

export const DownArrowNew = ({ color }: { color?: string }) => (
  <svg width="14" height="9" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0.296875V5.16511L9 12.999L18 5.16511V0.296875L9 8.11216L0 0.296875Z" fill={color} />
  </svg>
);
export const UpArrowNew = ({ color }: { color?: string }) => (
  <svg width="14" height="9" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.999 12.6953V7.82708L8.99902 -0.00685804L-0.000980854 7.82708V12.6953L8.99902 4.88003L17.999 12.6953Z"
      fill={color}
    />
  </svg>
);

export default SalesNavEmployeeViewer;

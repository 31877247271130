import { useEffect, useMemo, useState } from "react";
import { GroupFilter, nonCollapsibleFilterTypes, notPrimaryFilters, PayloadStructure } from "../models";
import {
  Collapse,
  ConfigProvider,
  DatePicker,
  Input,
  InputNumber,
  Switch,
  message,
  Select,
  Skeleton,
  Slider,
  Tooltip,
} from "antd";
import { RiCloseLine, RiExpandUpDownLine } from "react-icons/ri";
import { DownOutlined, InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { crustDataIndustries } from "@/utils/industries";
import LoaderButton from "@/Components/LoaderButton";
import { createSignal } from "../apis";
import { SIGNAL_ID_TO_URL } from "@/utils/urls";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { regions } from "@/utils/crustdataRegions";
import TextArea from "antd/es/input/TextArea";
import { getCampaigns } from "@/utils/apis";
import { CRM_FREQUENCY, ENGAGEBAY_SYSTEM_FIELDS } from "@/utils/constants";
import CRMfilterComponent from "./CRMfilterComponent";
import { useUser } from "@/contexts/UserContext";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi2";
import { IoIosClose } from "react-icons/io";
import playArrow from "/assets/PlayArrow.svg";
import MultiLineInput from "./MultiLineInput";
import { isNumber } from "lodash";

type Props = {
  payloadStructure: PayloadStructure[];
  signalId: string;
  name: string;
  preview?: boolean;
  generatePreview: (payload: any) => Promise<void>;
  payloads: any;
  setPayloads: React.Dispatch<React.SetStateAction<any>>;
  createSignalParentHandler?: () => Promise<void>;
  groupFilters?: GroupFilter[];
  pageCount?: number;
  handlePageCount?: (count: number) => void;
  creditCost?: number;
  showFilterSearch?: boolean;
  isList?: boolean;
  itemsCount?: number;
  hideCreateSignalButton?: boolean;
  pageCountToolTipText?: string;
};

const Filters = ({
  payloadStructure,
  signalId,
  name,
  preview,
  generatePreview,
  payloads,
  setPayloads,
  createSignalParentHandler,
  groupFilters,
  pageCount,
  handlePageCount,
  creditCost,
  showFilterSearch,
  isList,
  itemsCount,
  hideCreateSignalButton,
  pageCountToolTipText,
}: Props) => {
  const [requiredPayloads, setRequiredPayloads] = useState<any>([]);
  const [crustdataRegions, setCrustdataRegions] = useState<string[]>(regions);
  const [campaignsLoading, setCampaignsLoading] = useState<Record<string, boolean>>({});
  const [campaigns, setCampaigns] = useState<Record<string, any[]>>({});
  const [propertiesFilter, setPropertiesFilter] = useState<any[]>([]);
  const [runCondition, setRunCondition] = useState({ conditions: [] });
  const [filtersApplied, setFiltersApplied] = useState<number>(0);
  const [showCustomFrequency, setShowCustomFrequency] = useState<boolean>(false);
  const [customRangeValue, setCustomRangeValue] = useState(1);
  const [selectedFrequency, setSelectedFrequency] = useState(1);
  const [activeKey, setActiveKey] = useState<string[]>([]);

  const [searchQuery, setSearchQuery] = useState<string>("");

  const handlePanelChange = (keys: string | string[]) => {
    // Normal behavior when not searching
    const keysArray = Array.isArray(keys) ? keys : [keys];
    if (keysArray.includes("TITLE") || keysArray.includes("DESCRIPTION")) {
      setActiveKey(["TITLE", "DESCRIPTION"]);
    } else {
      setActiveKey(keysArray);
    }
  };

  dayjs.extend(advancedFormat);
  const navigate = useNavigate();
  const { collapsed: isSidebarCollapsed } = useUser();

  // Update filtersApplied count whenever payloads change
  useEffect(() => {
    const count = Object.keys(payloads).filter((key) => {
      const value = payloads[key];
      const isNotPrimaryFilter = notPrimaryFilters.filter((str) => key.includes(str))?.length > 0;
      return value !== undefined && value !== "" && !isNotPrimaryFilter;
    }).length;
    setFiltersApplied(count);
  }, [payloads]);

  // Add useEffect to handle search expansion
  useEffect(() => {
    if (searchQuery) {
      // Get all matching filter IDs
      const matchingIds = payloadStructure.filter(matchesSearch).map((payload) => {
        if (payload.payloadStructureId === "TITLE" || payload.payloadStructureId === "DESCRIPTION") {
          return "TITLE_DESCRIPTION";
        }
        return payload.payloadStructureId;
      });

      // Get all matching group IDs
      const matchingGroupIds =
        groupFilters
          ?.filter((group) => {
            const groupPayloads = payloadStructure.filter((p) => p.group === group.id);
            return groupPayloads.some(matchesSearch);
          })
          ?.map((group) => group.id) || [];

      // Combine all IDs and remove duplicates
      const uniqueIds = Array.from(new Set([...matchingIds, ...matchingGroupIds]));

      setActiveKey(uniqueIds);
    } else {
      // Reset to empty when search is cleared
      setActiveKey([]);
    }
  }, [searchQuery]);

  // Reset all filters
  const resetFilters = () => {
    setPayloads({});
    setFiltersApplied(0);
  };

  const validateMinMaxPayloads = (requiredPayloads: any[], payloads: any): boolean => {
    // Filter for `minMax` payloads
    const minMaxPayloads = requiredPayloads.filter((payload: any) => payload.type === "minMax");

    // Validate each `minMax` payload
    for (const payload of minMaxPayloads) {
      const { payloadStructureId, name } = payload;
      const min = payloads[payloadStructureId]?.min;
      const max = payloads[payloadStructureId]?.max;

      if (min === undefined || max === undefined || min === null || max === null) {
        message.error(`Both 'min' and 'max' values are required for "${name}"`);
        return false;
      }
      if (min > max) {
        message.error(`The 'min' value cannot be greater than the 'max' value for "${name}"`);
        return false;
      }
    }

    return true;
  };

  const validateFrequencyPayload = (payloads: any): boolean => {
    const frequencyValue = payloads["FREQUENCY"];
    if (!frequencyValue) return true;

    // Convert frequencyValue to a number
    const numericFrequency = Number(frequencyValue);

    // Check if the frequency is a positive integer and is at least 1
    if (!Number.isInteger(numericFrequency) || numericFrequency < 1) {
      message.error(
        "The specified 'Frequency' value is invalid. Please enter a positive integer value of 1 or greater."
      );
      return false;
    }
    return true;
  };

  const validateTitleDescription = (payloads: any): boolean => {
    const hasTitle = payloadStructure.some((p) => p.payloadStructureId === "TITLE");
    const hasDescription = payloadStructure.some((p) => p.payloadStructureId === "DESCRIPTION");

    if (hasTitle && hasDescription) {
      const title = payloads["TITLE"];
      const description = payloads["DESCRIPTION"];

      if (!title && !description) {
        message.error("Please fill either 'Title' or 'Description'");
        return false;
      }
    }

    return true;
  };

  const types = useMemo<string[]>(
    () =>
      payloadStructure.filter((field) => field.type.includes("dynamicDropdown")).map((field) => field.valuesId ?? ""),
    [payloadStructure]
  );

  useEffect(() => {
    const requiredPayloads = payloadStructure.filter((payload) => payload.required);
    setRequiredPayloads(requiredPayloads);
  }, [payloadStructure]);

  useEffect(() => {
    if (types.length === 0) return;
    types.forEach((type) => {
      setCampaignsLoading((prev) => ({ ...prev, [type]: true }));
      getCampaigns(type)
        .then((data: any[]) => {
          setCampaigns((prev) => ({ ...prev, [type]: data }));
          if (type.includes("properties")) {
            setPropertiesFilter(data);
          }
        })
        .catch((_error) => {
          setCampaigns((prev) => ({ ...prev, [type]: [] }));
        })
        .finally(() => {
          setCampaignsLoading((prev) => ({ ...prev, [type]: false }));
        });
    });
  }, [types]);

  const FREQUENCY_OPTIONS = [
    { label: "Daily", value: 1 },
    { label: "Every 2 Days", value: 2 },
    { label: "Every Week", value: 7 },
    { label: "Every Month", value: 30 },
  ];

  const CUSTOM_FREQUENCY_OPTIONS = [
    { label: "Days", value: 1 },
    { label: "Weeks", value: 7 },
    { label: "Months", value: 30 },
  ];

  const renderFrequencyLabel = (value: number) => {
    // Check if the value matches any predefined option
    const predefinedOption = FREQUENCY_OPTIONS.find((option) => option.value === value);
    if (predefinedOption) return predefinedOption.label;
    // Handle custom range
    const frequencyUnit = CUSTOM_FREQUENCY_OPTIONS.find((option) => option.value === selectedFrequency);
    if (frequencyUnit) {
      const adjustedValue = value / frequencyUnit.value;
      if (adjustedValue === 1) return `Every ${frequencyUnit.label.toLowerCase().replace("s", "")}`; // Singular form
      return `Every ${adjustedValue} ${frequencyUnit.label.toLowerCase()}`; // Plural form
    }
    return `Every ${value} days`;
  };

  const formatDate = (date: string) => {
    return dayjs(date).format("Do [of] MMMM, YYYY"); // eg: "25th of December, 2025"
  };

  const payloadSwitch = (payload: PayloadStructure, checkSubfilterType?: boolean) => {
    const type = checkSubfilterType ? payload?.sub_filter_type : payload.type;
    switch (type) {
      case "logoMultiDropdown":
        return (
          <Select
            suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
            value={[]}
            className="w-full"
            showSearch
            optionFilterProp="name"
            mode="multiple"
            onChange={(value) => {
              setPayloads((prev: any) => {
                const currentValues = prev[payload.payloadStructureId] || [];
                return {
                  ...prev,
                  [payload.payloadStructureId]: [...currentValues, ...value],
                };
              });
            }}
            filterOption={(input, option) =>
              (option?.name?.toLowerCase() ?? "").includes(input.toLowerCase()) ||
              (option?.label?.toString().toLowerCase() ?? "").includes(input.toLowerCase())
            }
            options={(payload.values ?? []).map((value: any) => {
              if (typeof value === "string")
                return {
                  label: value,
                  value,
                };
              return {
                label: (
                  <div className="flex justify-between items-center gap-2 p-1 w-full">
                    <div className="flex items-center gap-2 justify-end text-ellipsis">
                      {value?.logo && <img src={value?.logo} alt={value?.name} className="w-6 h-6" />}
                      <p>{value?.name}</p>
                    </div>
                  </div>
                ),
                value: value?.id || value.name,
                name: value?.name,
              };
            })}
          />
        );
      case "customRender":
        return payload?.renderer?.();
      case "minMax":
        return (
          <div className="flex w-full justify-between gap-4 items-center customSlider">
            <InputNumber
              min={0}
              placeholder="Min %"
              className="min-w-[100px] max-w-[100px] text-sm text-center border border-[#8E8E8E] rounded"
              value={payloads[payload.payloadStructureId]?.min ?? ""}
              onChange={(value) => {
                setPayloads((prev: any) => ({
                  ...prev,
                  [payload.payloadStructureId]: {
                    min: value || 0,
                    max: prev[payload.payloadStructureId]?.max || 0,
                  },
                }));
              }}
            />
            <Slider
              range
              min={0}
              max={payload?.maxSliderValue ? payload?.maxSliderValue : 500}
              className="w-full customSlider"
              value={[payloads[payload.payloadStructureId]?.min || 0, payloads[payload.payloadStructureId]?.max || 0]}
              onChange={(values) => {
                setPayloads((prev: any) => ({
                  ...prev,
                  [payload.payloadStructureId]: {
                    min: values[0],
                    max: values[1],
                  },
                }));
              }}
              styles={{
                track: { backgroundColor: "#333333", height: 2 },
                rail: { backgroundColor: "#D0D0D0", height: 2 },
              }}
            />
            <InputNumber
              min={0}
              placeholder="Max %"
              className="min-w-[100px] max-w-[100px] text-sm text-center border border-[#8E8E8E] rounded"
              value={payloads[payload.payloadStructureId]?.max ?? ""}
              onChange={(value) => {
                setPayloads((prev: any) => ({
                  ...prev,
                  [payload.payloadStructureId]: {
                    min: prev[payload.payloadStructureId]?.min || 0,
                    max: value || 0,
                  },
                }));
              }}
            />
          </div>
        );
      case "dropdown":
        return (
          <Select
            suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
            value={
              checkSubfilterType
                ? payloads[`${payload.payloadStructureId}_subfilter`] || ""
                : payloads[payload.payloadStructureId] || ""
            }
            showSearch
            className="w-full"
            optionFilterProp="name"
            onChange={(value) => {
              setPayloads((prev: any) => {
                const temp = { ...prev };
                checkSubfilterType
                  ? (temp[`${payload.payloadStructureId}_subfilter`] = value)
                  : (temp[payload.payloadStructureId] = value);
                return temp;
              });
            }}
            options={(checkSubfilterType ? (payload?.sub_filter_values ?? []) : (payload.values ?? [])).map(
              (value: any) => {
                if (typeof value === "string")
                  return {
                    label: value,
                    value,
                  };
                return {
                  label: (
                    <div className="flex justify-between items-center gap-2 p-1 w-full">
                      <div className="flex items-center gap-2 justify-end text-ellipsis">
                        {value?.logo && <img src={value?.logo} alt={value?.name} className="w-6 h-6" />}
                        <p>{value?.name}</p>
                      </div>
                    </div>
                  ),
                  value: value?.id || value.name,
                  name: value?.name,
                };
              }
            )}
          />
        );
      case "industry":
        return (
          <Select
            className="w-full"
            suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
            value={payloads[payload.payloadStructureId] || ""}
            showSearch
            onChange={(value) => {
              setPayloads((prev: any) => ({
                ...prev,
                [payload.payloadStructureId]: value,
              }));
            }}
            options={crustDataIndustries.map((value: any) => ({
              label: value.name,
              value: value.id,
            }))}
          />
        );
      case "dynamicDropdownInp":
        return (
          <Skeleton loading={campaignsLoading[payload.valuesId || ""] ?? true}>
            <Select
              key={payloads[payload.payloadStructureId] || [].join("")}
              className="w-full"
              mode="multiple"
              defaultValue={payloads[payload.payloadStructureId] || []}
              onChange={(value) => {
                setPayloads((prev: any) => ({
                  ...prev,
                  [payload.payloadStructureId]: value,
                }));
              }}
              options={
                payload.valuesId === "engagebay_properties"
                  ? (campaigns[payload.valuesId || ""] ?? []).concat(ENGAGEBAY_SYSTEM_FIELDS).map((property: any) => ({
                      label: property.field_label,
                      value: property.field_name,
                    }))
                  : (campaigns[payload.valuesId || ""] ?? []).map((property: any) => ({
                      label: property.label,
                      value: property.name,
                    }))
              }
              optionFilterProp="label"
            />
          </Skeleton>
        );
      case "industries":
        return (
          <Select
            className="w-full"
            suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
            value={payloads[payload.payloadStructureId] || []}
            showSearch
            mode="multiple"
            onChange={(value) => {
              if (value.length === 0) {
                setPayloads((prev: any) => {
                  const newPayloads = { ...prev };
                  delete newPayloads[payload.payloadStructureId];
                  return newPayloads;
                });
              } else {
                setPayloads((prev: any) => ({
                  ...prev,
                  [payload.payloadStructureId]: value,
                }));
              }
            }}
            options={crustDataIndustries.map((value: any) => ({
              label: value.name,
              value: value.id,
            }))}
          />
        );
      case "crustdataRegion":
        return (
          <Select
            className="w-full"
            virtual={true}
            onSearch={(value) => {
              if (!value) return setCrustdataRegions(regions);
              // display perfect match first
              const perfectMatch = regions.filter((region) => region.toLowerCase() === value.toLowerCase());
              // display partial match next by sorting with matching length
              const partialMatch = regions
                .filter((region) => region.toLowerCase().includes(value.toLowerCase()))
                .sort((a, b) => a.length - b.length);

              const newSet = new Set([...perfectMatch, ...partialMatch]);
              setCrustdataRegions(newSet.size ? Array.from(newSet) : []);
            }}
            suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
            value={payloads[payload.payloadStructureId] || []}
            showSearch
            onChange={(value) => {
              setPayloads((prev: any) => ({
                ...prev,
                [payload.payloadStructureId]: value,
              }));
            }}
            options={crustdataRegions.map((value: string) => ({
              label: value,
              value: value,
            }))}
          />
        );
      case "multiDropdown":
        if (payload.payloadStructureId === "COMPANY_HEADCOUNT") {
          const selectedValues = payloads[payload.payloadStructureId] || [];
          return (
            <div className="flex items-center gap-2 flex-wrap">
              {payload?.values?.map((value: string) => {
                const label = value.replace("-", " to ");
                return (
                  <button
                    key={value}
                    className={`bg-[#F3F4F6] hover:bg-[#e6e7e9] text-[11px] text-primaryBlack rounded-sm min-h-[24px] px-1.5 ${
                      selectedValues.includes(value) ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    onClick={() => {
                      // Check if the value is already selected
                      if (!selectedValues.includes(value)) {
                        setPayloads((prev: any) => ({
                          ...prev,
                          [payload.payloadStructureId]: [...(prev[payload.payloadStructureId] || []), value],
                        }));
                      }
                    }}
                    disabled={selectedValues.includes(value)}
                  >
                    {label}
                  </button>
                );
              })}
            </div>
          );
        } else {
          return (
            <Select
              suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
              value={payloads[payload.payloadStructureId] || []}
              className="w-full"
              showSearch
              optionFilterProp="name"
              mode="multiple"
              onChange={(value) => {
                setPayloads((prev: any) => ({
                  ...prev,
                  [payload.payloadStructureId]: value,
                }));
              }}
              filterOption={(input, option) =>
                (option?.name?.toLowerCase() ?? "").includes(input.toLowerCase()) ||
                (option?.label?.toString().toLowerCase() ?? "").includes(input.toLowerCase())
              }
              options={(payload.values ?? []).map((value: any) => {
                if (typeof value === "string")
                  return {
                    label: value,
                    value,
                  };
                return {
                  label: (
                    <div className="flex justify-between items-center gap-2 p-1 w-full">
                      <div className="flex items-center gap-2 justify-end text-ellipsis">
                        {value?.logo && <img src={value?.logo} alt={value?.name} className="w-6 h-6" />}
                        <p>{value?.name}</p>
                      </div>
                    </div>
                  ),
                  value: value?.id || value.name,
                  name: value?.name,
                };
              })}
            />
          );
        }
      case "singleSelect":
        return (
          <div className="flex flex-col gap-3">
            <div className="flex items-center gap-2">
              {payload?.values?.map((value: string) => {
                const selectedValues = payloads[payload.payloadStructureId] || [];
                const label = value.replace("-", " to ");
                return (
                  <button
                    key={value}
                    className={`bg-[#F3F4F6] hover:bg-[#e6e7e9] text-[11px] text-primaryBlack rounded-sm min-h-[24px] px-1.5 ${
                      selectedValues.includes(value) ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    onClick={() => {
                      // Check if the value is already selected
                      if (!selectedValues.includes(value)) {
                        setPayloads((prev: any) => ({
                          ...prev,
                          [payload.payloadStructureId]: [value],
                        }));
                      }
                    }}
                    disabled={selectedValues.includes(value)}
                  >
                    {label}
                  </button>
                );
              })}
            </div>
          </div>
        );
      case "dynamicDropdownMulti":
        return (
          <Skeleton loading={campaignsLoading[payload.valuesId || ""] ?? true}>
            <Select
              key={payloads[payload.payloadStructureId] || [].join("")}
              className="w-full"
              showSearch
              mode="multiple"
              defaultValue={payloads[payload.payloadStructureId] || []}
              onChange={(value) => {
                setPayloads((prev: any) => ({
                  ...prev,
                  [payload.payloadStructureId]: value,
                }));
              }}
              options={(campaigns[payload.valuesId || ""] ?? []).map((value: any) => ({
                label: value.name,
                value: value.id,
              }))}
            />
          </Skeleton>
        );
      case "frequency":
        return (
          <div className="flex flex-col gap-3">
            <div className="flex items-center gap-2">
              {FREQUENCY_OPTIONS.map((option) => (
                <button
                  className="bg-[#F3F4F6] hover:bg-[#e6e7e9] text-[11px] text-primaryBlack rounded-sm min-h-[24px] px-2"
                  onClick={() => setPayloads((prev: any) => ({ ...prev, [payload.payloadStructureId]: option.value }))}
                >
                  {option.label}
                </button>
              ))}
            </div>
            <button
              onClick={() => setShowCustomFrequency(!showCustomFrequency)}
              className="text-[12px] font-bold text-left w-fit text-primaryBlack hover:text-black decoration-[0.5px] underline underline-offset-4 leading-relaxed cursor-pointer"
            >
              Specify custom range
            </button>
            {showCustomFrequency && (
              <div className="flex items-center gap-2 customAntdInput">
                <span className="font-light">Repeat every: </span>
                <Input
                  type="number"
                  min={1}
                  placeholder="eg: 25"
                  className="max-w-[66px] rounded-sm h-[24px]"
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    setCustomRangeValue(value);
                    if (isNaN(value) || value === null) {
                      setPayloads((prev: any) => {
                        const updatedPayloads = { ...prev };
                        delete updatedPayloads[payload.payloadStructureId];
                        return updatedPayloads;
                      });
                    } else {
                      setPayloads((prev: any) => ({
                        ...prev,
                        [payload.payloadStructureId]: value * selectedFrequency,
                      }));
                    }
                  }}
                />
                <Select
                  className="min-w-[120px] rounded-sm h-[24px]"
                  value={selectedFrequency}
                  onChange={(value) => {
                    setSelectedFrequency(value);
                    setPayloads((prev: any) => ({
                      ...prev,
                      [payload.payloadStructureId]: customRangeValue * value,
                    }));
                  }}
                  options={CUSTOM_FREQUENCY_OPTIONS.map((option) => ({
                    label: option.label,
                    value: option.value,
                  }))}
                />
              </div>
            )}
            {payloads["FREQUENCY"] && (
              <div>
                <span className="px-1 mt-1 text-[11px] rounded-sm min-h-[18px] max-h-[18px] flex items-center text-[#514200] bg-[#FFF6CC]">
                  {`New leads will be fetched ${renderFrequencyLabel(payloads["FREQUENCY"]).toLowerCase()}.`}
                </span>
              </div>
            )}
          </div>
        );
      case "number":
        return (
          <Input
            type="number"
            min={1}
            className="w-full p-1 rounded"
            value={payloads[payload.payloadStructureId] || ""}
            onChange={(e) => setPayloads((prev: any) => ({ ...prev, [payload.payloadStructureId]: e.target.value }))}
          />
        );
      case "date":
        return (
          <div className="flex flex-col flex-1 gap-4">
            <div className="flex items-center gap-2 p-2 bg-[#F3F4F6] rounded-sm">
              <span className="font-light px-1">On</span>
              <DatePicker
                format="Do [of] MMMM, YYYY"
                className="w-full p-1 rounded-sm"
                allowClear={false}
                value={
                  payloads[payload.payloadStructureId]
                    ? dayjs(payloads[payload.payloadStructureId], "YYYY-MM-DD")
                    : null
                }
                onChange={(value) =>
                  setPayloads((prev: any) => ({ ...prev, [payload.payloadStructureId]: value?.format("YYYY-MM-DD") }))
                }
                disabledDate={(current) => {
                  // Disable today and all past dates
                  return current && current <= dayjs().endOf("day");
                }}
              />
            </div>
            {payloads["EXPIRATION_DATE"] && (
              <div>
                <span className="px-1 mt-1 text-[11px] rounded-sm min-h-[18px] max-h-[18px] flex items-center text-[#514200] bg-[#FFF6CC]">
                  {`New leads will stop being fetched on ${formatDate(payloads["EXPIRATION_DATE"])}.`}
                </span>
              </div>
            )}
          </div>
        );
      case "textarea":
        return (
          <TextArea
            className="w-full p-1 rounded"
            value={payloads[payload.payloadStructureId] || ""}
            onChange={(e) => setPayloads((prev: any) => ({ ...prev, [payload.payloadStructureId]: e.target.value }))}
          />
        );
      case "textareaMultiLine":
        return (
          <MultiLineInput
            className="w-full p-1 rounded"
            value={payloads[payload.payloadStructureId] || []}
            onChange={(values) =>
              setPayloads((prev: any) => ({
                ...prev,
                [payload.payloadStructureId]: values,
              }))
            }
            placeholder="Type and press Enter to add items"
          />
        );
      case "boolean":
        return (
          <Switch
            checked={payloads[payload.payloadStructureId] || false}
            onChange={(checked) => {
              setPayloads((prev: any) => ({
                ...prev,
                [payload.payloadStructureId]: checked,
              }));
            }}
            className="max-w-fit"
          />
        );
      case "multiInput":
        return (
          <Select
            mode="tags"
            className="w-full"
            value={payloads[payload.payloadStructureId] || []}
            onChange={(value) => {
              setPayloads((prev: any) => {
                const newPayloads = { ...prev, [payload.payloadStructureId]: value };
                if (value.length === 0) {
                  delete newPayloads[payload.payloadStructureId];
                }
                return newPayloads;
              });
            }}
            tokenSeparators={[","]}
            open={false}
            suffixIcon={null}
            placeholder="Type and press Enter to add values"
          />
        );
      case "crmFrequency":
        return (
          <Select
            suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
            value={payloads[payload.payloadStructureId] || ""}
            showSearch
            className="w-full"
            optionFilterProp="name"
            onChange={(value) => {
              setPayloads((prev: any) => ({
                ...prev,
                [payload.payloadStructureId]: value,
              }));
            }}
            options={CRM_FREQUENCY.map((value: any) => {
              if (typeof value === "string")
                return {
                  label: value,
                  value,
                };
              return {
                label: (
                  <div className="flex justify-between items-center gap-2 p-1 w-full">
                    <div className="flex items-center gap-2 justify-end text-ellipsis">
                      {value?.logo && <img src={value?.logo} alt={value?.name} className="w-6 h-6" />}
                      <p>{value?.name || value?.label}</p>
                    </div>
                  </div>
                ),
                value: value?.id || value?.value || value?.name,
                name: value?.name || value?.label,
              };
            })}
          />
        );
      case "crmFilters":
        return (
          <CRMfilterComponent
            variables={propertiesFilter}
            conditionsArr={runCondition || { conditions: [] }}
            setConditionsArr={setRunCondition}
            setPayloads={setPayloads}
          />
        );
      case "string":
      default:
        return (
          <Input
            type="text"
            className="w-full p-1 rounded"
            value={payloads[payload.payloadStructureId] || ""}
            onChange={(e) => {
              const value = e.target.value;
              setPayloads((prev: any) => {
                const updatedPayloads = { ...prev };
                if (value) {
                  updatedPayloads[payload.payloadStructureId] = value;
                } else {
                  delete updatedPayloads[payload.payloadStructureId];
                }
                return updatedPayloads;
              });
            }}
          />
        );
    }
  };

  const getFilters = () => {
    const nonCollapsibleFilters = payloadStructure.filter((field) => nonCollapsibleFilterTypes?.includes(field.type));
    const collapsibleFilters = payloadStructure.filter(
      (field) => !nonCollapsibleFilterTypes?.includes(field.type) && !field?.group
    );
    const filters = nonCollapsibleFilters?.map((payload) => {
      switch (payload.type) {
        case "primaryInput":
          return (
            <div className="flex flex-col gap-2">
              <span className="text-sm font-light">{payload.description}</span>
              <Input
                placeholder={payload.name}
                className="w-full px-4 py-2 rounded"
                value={payloads[payload.payloadStructureId] || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  setPayloads((prev: any) => ({
                    ...prev,
                    [payload.payloadStructureId]: value,
                  }));
                }}
              />
            </div>
          );
      }
    });
    return (
      <>
        {filters}
        {collapsibleFilters?.length ? (
          <ConfigProvider
            theme={{
              components: {
                Collapse: {
                  colorBorder: "rgb(209 213 219)",
                  headerBg: "white",
                },
                Slider: {
                  handleColor: "#333333",
                  handleActiveColor: "#333333",
                  // colorPrimary: '#333333',
                },
              },
            }}
          >
            <Collapse
              rootClassName="font-favorit rounded-[4px] customCollapse"
              destroyInactivePanel
              items={getFilterItems(collapsibleFilters)}
              activeKey={activeKey}
              onChange={handlePanelChange}
              expandIcon={({ isActive }) =>
                isActive ? <HiOutlineChevronUp className="h-4 w-4" /> : <HiOutlineChevronDown className="h-4 w-4" />
              }
              expandIconPosition="end"
            ></Collapse>
          </ConfigProvider>
        ) : (
          ""
        )}
      </>
    );
  };

  // Add this function inside the Filters component
  const matchesSearch = (payload: PayloadStructure) => {
    if (!searchQuery) return true;
    const query = searchQuery.toLowerCase();
    return payload.name.toLowerCase().includes(query) || payload.description.toLowerCase().includes(query);
  };

  // Add this function to highlight matching text
  const highlightText = (text: string) => {
    if (!searchQuery) return text;
    const parts = text.split(new RegExp(`(${searchQuery})`, "gi"));
    return (
      <span>
        {parts.map((part, i) =>
          part.toLowerCase() === searchQuery.toLowerCase() ? (
            <span key={i} className="bg-yellow-200">
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  const handleClearFrequency = (e: any) => {
    e.stopPropagation();
    setPayloads((prev: any) => {
      const updatedPayloads = { ...prev };
      delete updatedPayloads["FREQUENCY"];
      return updatedPayloads;
    });
    setSelectedFrequency(1);
    setCustomRangeValue(1);
    setShowCustomFrequency(false);
  };

  const getOperatorDropdown = (payload: PayloadStructure) => {
    if (!payload?.availableOperators?.length) {
      return null;
    }

    if (payload?.availableOperators?.length <= 1) {
      return null;
    }

    const lablelMap = (id: String) => {
      switch (id) {
        case "in":
          return "is any of";
        case "not_in":
          return "not any of";
        case "between":
          return "is between";
        default:
          return id?.replace(/_/g, " ");
      }
    };

    return (
      <Select
        className="w-full mb-2"
        placeholder="Select operator"
        suffixIcon={<DownOutlined className=" text-[#333]" />}
        value={payloads[`${payload.payloadStructureId}_operator`] || payload.availableOperators[0]}
        onChange={(value) => {
          setPayloads((prev: any) => ({
            ...prev,
            [`${payload.payloadStructureId}_operator`]: value,
          }));
        }}
        options={payload.availableOperators.map((op: string) => ({
          label: lablelMap(op),
          value: op,
        }))}
      />
    );
  };

  const handleCreateSignal = async () => {
    if (!validateFrequencyPayload(payloads)) {
      return;
    }
    if (!validateMinMaxPayloads(requiredPayloads, payloads)) {
      return;
    }
    if (!validateTitleDescription(payloads)) {
      return;
    }

    // Check if both "TITLE" and "DESCRIPTION" are present
    const hasTitle = payloadStructure.some((p) => p.payloadStructureId === "TITLE");
    const hasDescription = payloadStructure.some((p) => p.payloadStructureId === "DESCRIPTION");

    // Filter out "TITLE" and "DESCRIPTION" from requiredPayloads if both are present
    const filteredRequiredPayloads = requiredPayloads.filter((payload: any) => {
      if (hasTitle && hasDescription) {
        return payload.payloadStructureId !== "TITLE" && payload.payloadStructureId !== "DESCRIPTION";
      }
      return true;
    });

    // Check if all required payloads (excluding "TITLE" and "DESCRIPTION") are present
    if (filteredRequiredPayloads.some((payload: any) => !payloads[payload.payloadStructureId])) {
      message.error("Please fill all required fields");
      return;
    }

    const newId = await createSignal(SIGNAL_ID_TO_URL[signalId as keyof typeof SIGNAL_ID_TO_URL], payloads, name);

    if (newId) {
      navigate(`/list/${newId}`);
    }
  };

  const renderPayload = (payloadStructureId: string, payload: any) => {
    switch (payloadStructureId) {
      case "COMPANY_HEADCOUNT_GROWTH":
        return (
          <div className="flex items-center gap-1">
            <span>
              Min: {payload.min === 0 ? "0" : String(payload.min).padStart(2, "0")}
              %, Max: {payload.max === 0 ? "0" : String(payload.max).padStart(2, "0")}%
            </span>
            <IoIosClose
              className="text-lg cursor-pointer text-primaryBlack hover:text-black"
              onClick={(e) => {
                e.stopPropagation();
                setPayloads((prev: any) => {
                  const updatedPayloads = { ...prev };
                  delete updatedPayloads["COMPANY_HEADCOUNT_GROWTH"];
                  return updatedPayloads;
                });
              }}
            />
          </div>
        );
      case "FREQUENCY":
        const frequencyValue = payload as number;
        return (
          <div className="flex items-center gap-1">
            <span>{renderFrequencyLabel(frequencyValue)}</span>
            <IoIosClose
              className="text-lg cursor-pointer text-primaryBlack hover:text-black"
              onClick={handleClearFrequency}
            />
          </div>
        );
      case "EXPIRATION_DATE":
        return (
          <div className="flex items-center gap-1">
            <span>{formatDate(payload)}</span>
            <IoIosClose
              className="text-lg cursor-pointer text-primaryBlack hover:text-black"
              onClick={(e) => {
                e.stopPropagation();
                setPayloads((prev: any) => {
                  const updatedPayloads = { ...prev };
                  delete updatedPayloads["EXPIRATION_DATE"];
                  return updatedPayloads;
                });
              }}
            />
          </div>
        );
      default:
        if (typeof payload === "object") {
          return JSON.stringify(payload);
        }
        return (
          <div className="flex items-center gap-1">
            <span className="text-nowrap max-w-64 overflow-hidden text-ellipsis">{payload.toString()}</span>
            <IoIosClose
              className="text-lg cursor-pointer text-primaryBlack hover:text-black"
              onClick={(e) => {
                e.stopPropagation();
                setPayloads((prev: any) => {
                  const updatedPayloads = { ...prev };
                  delete updatedPayloads[payloadStructureId];
                  return updatedPayloads;
                });
              }}
            />
          </div>
        );
    }
  };

  const getFilterItems = (payloadStructure: PayloadStructure[]) => {
    return payloadStructure?.filter(matchesSearch)?.map((payload) => {
      const isActive = activeKey.includes(payload.payloadStructureId);
      const isTitleDescriptionActive = activeKey.includes("TITLE_DESCRIPTION");

      // If both "TITLE" and "DESCRIPTION" are present
      const hasTitle = payloadStructure.some((p) => p.payloadStructureId === "TITLE");
      const hasDescription = payloadStructure.some((p) => p.payloadStructureId === "DESCRIPTION");
      const isTitle = payload.payloadStructureId === "TITLE";
      const shouldAddHr = hasTitle && hasDescription && isTitle;
      const shouldRemoveBorder = hasTitle && hasDescription && isTitle;

      if (
        hasTitle &&
        hasDescription &&
        (payload.payloadStructureId === "TITLE" || payload.payloadStructureId === "DESCRIPTION")
      ) {
        return {
          key: "TITLE_DESCRIPTION",
          className: shouldRemoveBorder ? "!border-b-0" : "",
          label: (
            <div className="block items-start gap-2 w-[calc(100%-1px)] font-favorit">
              <p className="flex justify-between">
                <span
                  className={`text-base font-normal ${
                    isSidebarCollapsed ? "md:max-w-[280px] max-w-[100px]" : "md:max-w-[220px] max-w-[100px]"
                  } ${isActive ? "whitespace-normal" : "truncate"} text-black`}
                >
                  {payload.name}
                </span>
                {payload.required && (
                  <span className="px-1 mt-1 text-[10px] uppercase rounded-sm min-h-[18px] max-h-[18px] flex items-center text-[#514200] bg-[#FFF6CC]">
                    Required
                  </span>
                )}
                {!payload.required && (
                  <span className="px-1 mt-1 text-[10px] uppercase rounded-sm min-h-[18px] max-h-[18px] flex items-center text-[#5D5D5D] bg-[#EFEFEF]">
                    Optional
                  </span>
                )}
              </p>
              {(payloads[payload.payloadStructureId] || payloads[payload.payloadStructureId] === false) && (
                <p className="w-fit bg-[#EFEFEF] mt-2 ring-[0.5px] ring-[#565656] py-0.5 px-1 text-xs text-primaryBlack break-words">
                  {renderPayload(payload.payloadStructureId, payloads[payload.payloadStructureId])}
                </p>
              )}
              {!isTitleDescriptionActive && shouldAddHr && (
                <div className="absolute left-0 right-0 bottom-0 w-full flex items-center justify-center transition-all duration-300 ease-in-out">
                  <hr className="w-full border-t border-[#D3D3D3]" />
                  <span className="absolute px-2 bg-white text-xs text-[#5D5D5D]">AND/OR</span>
                </div>
              )}
            </div>
          ),
          children: (
            <div className="flex flex-wrap gap-3 w-full font-favorit">
              <p className="text-xs font-light">{payload.description}</p>
              {(payload.payloadStructureId === "TITLE" || payload.payloadStructureId === "DESCRIPTION") && (
                <span className="uppercase px-1 text-[10px] rounded-sm min-h-[16px] flex items-center text-[#5D5D5D] bg-[#EFEFEF]">
                  single option
                </span>
              )}
              {payloadSwitch(payload)}
              {payload.payloadStructureId === "TITLE" &&
                payloadStructure.find((p) => p.payloadStructureId === "DESCRIPTION") && (
                  <div className="relative flex items-center justify-center w-[calc(100%+32px)] -mx-4 mt-3 transition-all duration-300 ease-in-out">
                    <hr className="w-full border-t border-[#D3D3D3]" />
                    <span className="absolute px-2 bg-[#FBFBFB] text-xs text-[#5D5D5D]">AND/OR</span>
                  </div>
                )}
            </div>
          ),
        };
      }

      return {
        key: payload.payloadStructureId,
        label: (
          <div className="block items-start gap-2 w-[calc(100%-1px)] font-favorit">
            <p className="flex justify-between">
              <span
                className={`text-base font-normal ${
                  isSidebarCollapsed ? "md:max-w-[200px] max-w-[100px]" : "md:max-w-[360px] max-w-[100px]"
                } ${isActive ? "whitespace-normal" : "truncate"} text-black`}
              >
                {highlightText(payload.name)}
              </span>
              {payload.required && (
                <span className="px-1 mt-1 text-[10px] uppercase rounded-sm min-h-[18px] max-h-[18px] flex items-center text-[#514200] bg-[#FFF6CC]">
                  Required
                </span>
              )}
              {!payload.required && (
                <span className="px-1 mt-1 text-[10px] uppercase rounded-sm min-h-[18px] max-h-[18px] flex items-center text-[#5D5D5D] bg-[#EFEFEF]">
                  Optional
                </span>
              )}
            </p>
            {(payloads[payload.payloadStructureId] || payloads[payload.payloadStructureId] === false) &&
              (Array.isArray(payloads[payload.payloadStructureId]) ? (
                <div className="flex flex-wrap gap-2 mt-2">
                  {payloads[payload.payloadStructureId].map((item: any, index: number) => (
                    <div
                      key={index}
                      className="bg-[#EFEFEF] ring-[0.5px] ring-[#565656] py-0.5 px-1 text-xs text-primaryBlack flex items-center gap-1"
                    >
                      <span>{typeof item === "object" ? JSON.stringify(item) : item}</span>
                      <IoIosClose
                        className="text-sm cursor-pointer hover:text-black"
                        onClick={(e) => {
                          e.stopPropagation();
                          setPayloads((prev: any) => {
                            const updatedPayloads = { ...prev };
                            const updatedArray = updatedPayloads[payload.payloadStructureId].filter(
                              (val: any) => val !== item
                            );

                            if (updatedArray.length === 0) {
                              delete updatedPayloads[payload.payloadStructureId];
                            } else {
                              updatedPayloads[payload.payloadStructureId] = updatedArray;
                            }

                            return updatedPayloads;
                          });
                        }}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <p className="w-fit bg-[#EFEFEF] mt-2 ring-[0.5px] ring-[#565656] py-0.5 px-1 text-xs text-primaryBlack break-words">
                  {renderPayload(payload.payloadStructureId, payloads[payload.payloadStructureId])}
                </p>
              ))}
          </div>
        ),
        children: (
          <div className="flex flex-col flex-wrap gap-3 w-full font-favorit">
            <p className="text-xs font-light">{highlightText(payload.description)}</p>
            <div>
              <div>{getOperatorDropdown(payload)}</div>
              <div>{payload?.has_sub_filter && payloadSwitch(payload, true)}</div>
              <div className="max-w-fit">
                {(payload.type === "industry" ||
                  payload.type === "crustdataRegion" ||
                  payload.type === "frequency" ||
                  payload.type === "singleSelect") && (
                  <span className="uppercase px-1 text-[10px] rounded-sm min-h-[16px] flex items-center text-[#5D5D5D] bg-[#EFEFEF]">
                    single select
                  </span>
                )}

                {(payload.type === "multiDropdown" ||
                  payload.type === "industries" ||
                  payload.type === "customRender" ||
                  payload.type === "logoMultiDropdown") && (
                  <span className="uppercase px-1 text-[10px] rounded-sm min-h-[16px] flex items-center text-[#5D5D5D] bg-[#EAEEFF]">
                    multi-select
                  </span>
                )}

                {(payload.payloadStructureId === "TITLE" || payload.payloadStructureId === "DESCRIPTION") && (
                  <span className="uppercase px-1 text-[10px] rounded-sm min-h-[16px] flex items-center text-[#5D5D5D] bg-[#EFEFEF]">
                    single option
                  </span>
                )}
              </div>
            </div>

            {payloadSwitch(payload)}
          </div>
        ),
      };
    });
  };

  const groupItems = () => {
    if (!groupFilters) {
      return [];
    }

    const items = groupFilters
      ?.filter((group) => {
        // Check if any payload in this group matches the search
        const groupPayloads = payloadStructure.filter((p) => {
          return p.group === group.id;
        });
        return groupPayloads.some(matchesSearch);
      })
      ?.map((filterGroup) => {
        const isActive = activeKey.includes(filterGroup.id);
        return {
          key: filterGroup.id,
          className: "!border-b-0",
          label: (
            <div className="block items-start gap-2 w-[calc(100%-1px)] font-favorit">
              <p className="flex justify-between">
                <span
                  className={`text-base font-normal flex gap-4 ${
                    isSidebarCollapsed ? "md:max-w-[280px] max-w-[100px]" : "md:max-w-[220px] max-w-[100px]"
                  } ${isActive ? "whitespace-normal" : "truncate"} text-black`}
                >
                  <span>{filterGroup?.logo && <img src={filterGroup?.logo} />}</span>
                  {filterGroup.name}
                </span>
                <span className="px-1 mt-1 text-[10px] uppercase rounded-sm min-h-[18px] max-h-[18px] flex items-center text-[#5D5D5D] bg-[#EFEFEF]">
                  Optional
                </span>
              </p>
            </div>
          ),
          children: (
            <ConfigProvider
              theme={{
                components: {
                  Collapse: {
                    colorBorder: "rgb(209 213 219)",
                    headerBg: "white",
                    contentPadding: "16px",
                  },
                  Slider: {
                    handleColor: "#333333",
                    handleActiveColor: "#333333",
                    // colorPrimary: '#333333',
                  },
                },
              }}
            >
              <Collapse
                rootClassName="font-favorit rounded-[4px] customCollapse"
                destroyInactivePanel
                items={getFilterItems(payloadStructure?.filter((payload) => payload?.group === filterGroup.id) || [])}
                activeKey={activeKey}
                onChange={handlePanelChange}
                expandIcon={({ isActive }) =>
                  isActive ? <HiOutlineChevronUp className="h-4 w-4" /> : <HiOutlineChevronDown className="h-4 w-4" />
                }
                expandIconPosition="end"
              />
            </ConfigProvider>
          ),
        };
      });

    return items;
  };

  return (
    <div className="w-full h-full flex flex-col justify-between gap-4 pt-8 bg-[#FBFBFB] font-favorit border-r-[1px] border-[#D3D3D3] relative">
      <div className="flex flex-col gap-4 flex-1">
        <div className="flex gap-2 items-center px-8">
          <p className="text-xl font-medium text-primaryBlack">Filters</p>
          {filtersApplied > 0 && (
            <button
              title="Remove Applied Filters"
              className="flex px-1 items-center bg-[#F3F4F6] gap-2 text-sm text-primaryBlack hover:bg-[#e6e6e6] hover:text-black rounded-[1px] ring-[0.5px] ring-[#565656]"
              onClick={resetFilters}
            >
              <span className="font-medium">{filtersApplied}</span>
              <RiCloseLine />
            </button>
          )}
        </div>

        {showFilterSearch && (
          <div className="px-8">
            <Input
              placeholder="Search filters..."
              prefix={<SearchOutlined className="text-gray-400" />}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="mb-4"
              allowClear
            />
          </div>
        )}

        <div className="px-8 h-fit max-h-[calc(100vh-200px)] overflow-auto w-full">
          {getFilters()}
          <div className="mb-4" />
          <ConfigProvider
            theme={{
              components: {
                Collapse: {
                  colorBorder: "rgb(209 213 219)",
                  headerBg: "white",
                  contentPadding: "0",
                },
                Slider: {
                  handleColor: "#333333",
                  handleActiveColor: "#333333",
                  // colorPrimary: '#333333',
                },
              },
            }}
          >
            <Collapse
              rootClassName="font-favorit rounded-[4px] customCollapse"
              destroyInactivePanel
              items={groupItems()}
              activeKey={activeKey}
              onChange={handlePanelChange}
              expandIcon={({ isActive }) =>
                isActive ? <HiOutlineChevronUp className="h-4 w-4" /> : <HiOutlineChevronDown className="h-4 w-4" />
              }
              expandIconPosition="end"
            ></Collapse>
          </ConfigProvider>
        </div>
      </div>
      <div className="sticky bottom-0 bg-[#FBFBFB] border-t-[1px] border-[#D3D3D3] py-[0.7rem] px-8 flex flex-col gap-2">
        {isNumber(pageCount) && (
          <div className="flex justify-between items-center gap-2">
            <div className="bg-[#eeecf7] px-3 py-1 rounded-4px">
              Total Cost: <span className="font-bold">{creditCost}</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-500" title="Higher values will process more data but may take longer">
                <div className="flex items-center gap-1">
                  <Tooltip title={pageCountToolTipText}>
                    <InfoCircleOutlined className="text-gray-500 text-sm" />
                  </Tooltip>
                </div>
              </span>
              <InputNumber
                min={1}
                value={pageCount}
                onChange={(value) => handlePageCount?.(value || 1)}
                className="w-26 min-w-fit"
                title="Number of pages to process"
              />
            </div>
          </div>
        )}
        {preview && (
          <LoaderButton
            text={
              <div className="flex gap-2">
                <img src={playArrow} width={12} />
                <span>Generate Preview</span>
              </div>
            }
            loaderText="Generating Preview..."
            btnClasses="mt-auto !bg-[#EAEAEA] !text-[#000] !text-nowrap !text-center"
            onClickCallback={async () => {
              // check if all required payloads are present
              if (requiredPayloads.some((payload: any) => !payloads[payload.payloadStructureId])) {
                message.error("Please fill all required fields");
                return;
              }
              await generatePreview(payloads);
              return;
            }}
            error=""
            disabled={false}
          />
        )}
        {!hideCreateSignalButton && (
          <LoaderButton
            text={isList ? `Create List ${itemsCount ? `of ${itemsCount} items` : ""}` : "Create Signal"}
            loaderText="Creating Signal..."
            btnClasses="!bg-primaryBlack"
            onClickCallback={createSignalParentHandler ? createSignalParentHandler : handleCreateSignal}
            error=""
            disabled={false}
          />
        )}
      </div>
    </div>
  );
};

export default Filters;

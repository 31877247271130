import { useEffect, useState } from "react";
import Loader from "../Loader";
import { LOADER_TYPES } from "../../utils/constants";
import { Collapse, ConfigProvider, Divider } from "antd";
import { useConnections } from "../../contexts/ConnectionContext";
import { CollapsibleIcon } from "@/Components/NavbarNew/CollapsibleIcon";

// const comingSoon = [
//   {
//     name: "Linkedin",
//     logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/connection_logos/linkedin.svg",
//   },
//   {
//     name: "Outreach",
//     logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/connection_logos/outreach.svg",
//   },
//   {
//     name: "Twitter",
//     logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/connection_logos/twitter.svg",
//   },
//   {
//     name: "Crunchbase",
//     logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/connection_logos/crunchbase.svg",
//   },
//   {
//     name: "Zoominfo",
//     logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/connection_logos/zoominfo.svg",
//   },
//   {
//     name: "Owler",
//     logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/connection_logos/owler.svg",
//   },
// ];

type Connection = {
  name: string;
  logo: string;
  description: string;
  config: {
    name: string;
    description: string;
    id: string;
    type: string;
  }[];
  value?: any;
  id: string;
  connectionId?: string;
};

const Connections = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { getAllConnections, saveConnection, deleteConnectionFromType, saveCredentialConnection } = useConnections();
  const [connections, setConnections] = useState<Connection[]>([]);

  useEffect(() => {
    setLoading(true);
    getAllConnections().then((connections) => {
      const conn: Connection[] = [];
      connections.existingConnections.forEach((connection: any) => {
        const userConn = connections.userConnections.find((c: any) => c.connectionId === connection.id);
        connection.connectionId = connection.id;
        connection.id = "";
        if (userConn) {
          connection.value = userConn.value;
          connection.id = userConn.id;
        }
        // connection.accounts = userConn || [];
        conn.push(connection);
      });
      setConnections(conn);
      setLoading(false);
    });
  }, []);

  /**
   * @description Save the connection with the provided API key.
   * @param {Object} connection - The connection object to be validated and saved.
   *   @param {string} id - Unique identifier for the connection.
   *   @param {string} connectionId - Identifier for the specific connection type.
   *   @param {Object} value - The value object containing API key and name.
   *     @param {string} value.apiKey - The API key provided by the user.
   *     @param {string} value.name - The name of the connection.
   *
   * @todo
   * 1. Add a new button next to the save button to validate the API key.
   *    - If the API key is valid, display "Connected ✅".
   *    - If the API key is invalid or missing, display "Not Connected ❌".
   *
   * Cases being handled currently:
   * 1. If the user forgets to provide the API key:
   *    - Do not save the connection.
   *    - Display "Not Connected ❌".
   * 2. If the user has already saved the connection with an API key and then updates it with a non-empty string:
   *    - Display "Connected ✅".
   * 3. If the user has already saved the connection and then removes the API key/updates it with an empty string:
   *    - Remove the connection.
   *    - Display "Not Connected ❌".
   */
  const saveConnectionHandler = (connection: any) => {
    setLoading(true);

    const isCredentialType = connection.type === "credential";
    const saveFn = isCredentialType ? saveCredentialConnection : saveConnection;

    const hasEmptyFields = !connection.value || Object.values(connection.value).some((v) => v === "");


    if (hasEmptyFields) {
      deleteConnectionFromType(connection.connectionId)
        .then(() => {
          const conn = [...connections];
          const index = conn.findIndex((c) => c.connectionId === connection.connectionId);
          conn[index].value = {};
          conn[index].id = "";
          setConnections(conn);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else {
      saveFn(connection.id, connection.connectionId, connection.value)
        .then((connections) => {
          const conn: Connection[] = [];
          connections.existingConnections.forEach((connection: any) => {
            const userConn = connections.userConnections.find((c: any) => c.connectionId === connection.id);
            connection.connectionId = connection.id;
            connection.id = "";
            if (userConn) {
              connection.value = userConn.value;
              connection.id = userConn.id;
            }
            conn.push(connection);
          });
          setConnections(conn);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          if (error.message?.includes("Invalid credentials")) {
            alert("❌ Connection failed: Please check your credentials.");
          } else {
            alert("Something went wrong while saving the connection.");
          }
          setLoading(false);
        });
    }
  };

  const items = (connection: any, index: number): any => ({
    key: index.toString(),
    label: (
      <div className="w-full flex items-center gap-4">
        <img src={connection.logo} alt={connection.name} className="h-6" />
        <p className="font-medium">{connection.name}</p>
        <Divider type="vertical" className="h-6" />
        {connection.id !== "" ? (
          <div className="flex items-center gap-1 font-medium">
            <span className="px-1 text-lg">✅</span>
            Connected
          </div>
        ) : (
          <div className="flex items-center gap-1 font-medium">
            <span className="px-1 text-lg">❌</span>
            Not Connected
          </div>
        )}
      </div>
    ),
    children:
      ["api", "credential"].includes(connection.type) ? (
        <div className="flex flex-col items-start gap-4">
          {/* <p className="font-light">{connection.description}</p> */}
          {connection.config.map((config: any, index: number) => (
            <div key={index} className="w-full flex flex-col gap-1">
              <label className="font-medium">{config.name}</label>
              <input
                type="text"
                className="w-1/2 p-2 border-1 border-gray-300 rounded-lg shadow-sm"
                placeholder={config.description}
                value={connection.value ? connection.value[config.id] : ""}
                onChange={(e) => {
                  const conn = [...connections];
                  const index = conn.findIndex((c) => c.connectionId === connection.connectionId);
                  if (!conn[index].value) conn[index].value = {};
                  conn[index].value[config.id] = e.target.value;
                  setConnections(conn);
                }}
              />
            </div>
          ))}
          <button
            className="bg-primary text-white font-medium py-2 px-8 rounded-md hover:bg-dark"
            onClick={() => saveConnectionHandler(connection)}
          >
            Save
          </button>
        </div>
      ) : connection.type === "oauth" ? (
        <div className="flex flex-col items-start gap-4">
          {connection.value && (
            <div className="flex items-center gap-1 font-medium">
              <span className="px-1 text-lg">✅</span>
              Connected
            </div>
          )}
          <a
            href={connection?.authURL || ""}
            className="bg-primary text-white font-medium py-2 px-8 rounded-md hover:bg-dark hover:text-white"
          >
            Connect
          </a>
        </div>
      ) : null,
  });

  if (loading)
    return (
      <div className="h-full w-full">
        <Loader loaderType={LOADER_TYPES.fetching} payload="Workflow Libraries" />
      </div>
    );

  return (
    <div className="h-full w-full flex flex-col !cursor-default gap-5 p-5">
      <div className="w-full flex flex-row gap-2 items-center relative">
        <CollapsibleIcon isHeader />
        <div className="text-2xl font-bold bg-gradient-to-r from-[#5e35b1] via-[#8e24aa] to-[#ab47bc] bg-clip-text text-black cursor-default">
          Connections
        </div>
      </div>
      <ConfigProvider
        theme={{
          components: {
            Collapse: {
              contentBg: "#ffffff",
              headerBg: "#ffffff",
              headerPadding: "10px !important",
            },
          },
          token: {
            colorText: "#000000 !important",
          },
        }}
      >
        <div className="w-3/4 flex flex-col gap-4 pb-10">
          {connections.map((connection, index) => (
            <Collapse
              key={index}
              className="shadow-md"
              accordion
              destroyInactivePanel
              items={[items(connection, index)]}
              expandIconPosition="end"
            />
          ))}
          {/* {comingSoon.map((item, index) => (
            <div
              key={index}
              className="w-full flex items-center gap-4 p-[10px] bg-white border border-[#D7D7D7] rounded-lg shadow-md"
            >
              <img src={item.logo} alt={item.name} className="h-6" />
              <p className="font-medium">{item.name}</p>
              <Divider type="vertical" className="h-6" />
              <div className="font-medium text-sm tracking-primary text-gray-600 rounded uppercase px-3 py-1 bg-gradient-to-r from-[#CFCBFF] from-0% via-[#FAF2FF] via-55% to-[#FFE7EB] to-100%">
                Coming Soon...
              </div>
            </div>
          ))} */}
        </div>
      </ConfigProvider>

      {/* <Wrapper /> */}
    </div>
  );
};

export default Connections;

export type PayloadStructure = {
  name: string;
  description: string;
  type: string;
  payloadStructureId: string;
  required: boolean;
  fileUrl?: string;
  values?: any[];
  valuesId?: string;
  group?: string;
  availableOperators?: string[];
  sub_filter_values?: string[];
  has_sub_filter?: boolean;
  sub_filter_type?: string;
  maxSliderValue?: number;
  renderer?: () => React.ReactNode;
};

export type ResponseStructure = {
  name: string;
  description: string;
  type: string;
  responseStructureId: string;
};

export type GroupFilter = {
  id: string;
  name: string;
  logo: string;
  required: boolean;
};

export const notPrimaryFilters = ["_operator", "_subfilter", "_valueId"];
export const nonCollapsibleFilterTypes = ["primaryInput"];

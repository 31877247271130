import { useNavigate } from "react-router-dom";
import { CollapsibleIcon } from "../NavbarNew/CollapsibleIcon";
import { cn } from "@/utils/cn";

interface NavTabsProps {
  currentURL: string;
}

const NavTabs = ({ currentURL }: NavTabsProps) => {
  const navigate = useNavigate();

  const tabStyle = (isActive: boolean) =>
    cn(
      "text-lg font-medium px-4 py-3 transition-colors duration-200",
      "cursor-pointer select-none",
      isActive ? "text-black" : "text-gray-400 hover:text-gray-600"
    );

  return (
    <div className="w-full flex flex-row gap-4 items-center relative px-5 pt-5">
      <CollapsibleIcon isHeader />
      <div className="flex items-center">
        <div
          className={tabStyle(currentURL === "/workflows")}
          onClick={() => navigate("/workflows", { replace: true })}
        >
          Floqs
        </div>
        <div className="h-4 w-[1px] bg-gray-400 mx-1" />
        <div className={tabStyle(currentURL === "/subfloqs")} onClick={() => navigate("/subfloqs", { replace: true })}>
          SubFloqs
        </div>
      </div>
    </div>
  );
};

export default NavTabs;

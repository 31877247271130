import { fetchTransactionsForWorkflowActionDataPart } from "@/utils/apis";
import { App, message } from "antd";
import { useEffect, useState } from "react";
import { FaRegCopy } from "react-icons/fa";
import CreditHistoryViewer from "./CreditHistorViewer";

type Props = {
  open: boolean;
  data: any;
  credits: any;
};

const HackerTargetASN = ({ open, data }: Props) => {
  const { response, workflowDataActionPartId, action, workflowId } = data;

  if (!response || !workflowDataActionPartId || !action) return;
  const [newData, setNewData] = useState<any>(data);
  const [activeIndices, setActiveIndices] = useState<number[]>([]);
  const [transactionHistory, setTransactionHistory] = useState<any[]>([]);

  useEffect(() => {
    if (!open) setNewData({});
    else setNewData(response);
    getTransactionHistory(workflowDataActionPartId, action);
  }, [open, data]);

  const getTransactionHistory = async (workflowDataActionPartId: string, action: any) => {
    const dataId = workflowDataActionPartId;
    const wfId = action?.workflowId || workflowId;
    if (!wfId || !dataId) return;

    const response = await fetchTransactionsForWorkflowActionDataPart(wfId, dataId);
    setTransactionHistory(response.transactions || []);
  };

  const toggleAccordion = (index: number) => {
    setActiveIndices((prevIndices) =>
      prevIndices.includes(index) ? prevIndices.filter((i) => i !== index) : [...prevIndices, index]
    );
  };

  const handleCopy = async (content: any, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      const textToCopy = typeof content === "object" ? JSON.stringify(content, null, 2) : content;
      await navigator.clipboard.writeText(textToCopy);
      message.success("Content copied to clipboard!");
    } catch (err) {
      message.error("Failed to copy content.");
    }
  };

  const fieldsMapping: Record<string, string> = {
    asn_number: "ASN Number",
    company_name: "Company Name",
    company_domain: "Company Domain",
    organization_name: "Organization Name",
    company_description: "Company Description",
  };

  return (
    <>
      <App>
        <div className="flex flex-col gap-2 text-[14px]">
          <div className="text-[20px] font-bold">Total ASNs</div>
          <hr />
          <div className="border border-gray-200 p-2 rounded-sm">
            <div className="flex justify-between">
              <p className="text-[16px]">{newData["Total ASNs"] || 0}</p>
              <FaRegCopy
                className="text-gray-600 cursor-pointer"
                onClick={(e) => handleCopy(newData["Total ASNs"], e)}
              />
            </div>
          </div>
          <div className="text-[20px] font-bold">ASNs Found</div>
          <hr />
          <div className="space-y-2">
            {newData && newData["ASNs"] && Object.entries(newData["ASNs"] || {})?.length ? (
              Object.entries(newData["ASNs"] || {}).map(([_, well]: [string, any], idx: number) => (
                <div key={idx} className="border border-gray-200 p-2 rounded-sm">
                  <div
                    className="flex justify-between items-center cursor-pointer"
                    onClick={() => toggleAccordion(idx)}
                  >
                    <p className="text-[16px] font-bold">ASN {idx + 1}</p>
                    <FaRegCopy className="text-gray-600" onClick={(e) => handleCopy(well, e)} />
                  </div>
                  {activeIndices.includes(idx) && (
                    <div className="space-y-2 mt-2 border p-2 rounded-sm">
                      {well.map((item: any, i: number) => (
                        <div key={i} className="flex flex-col group relative">
                          <span className="text-[#575757] font-semibold">
                            {fieldsMapping[item.responseStructureId]}
                          </span>
                          <div className="bg-[#FFF7DF] px-2 py-1 flex justify-between items-center">
                            {Array.isArray(item.value) ? (
                              <pre className="text-xs w-[90%] whitespace-pre-wrap">
                                {JSON.stringify(item.value, null, 2)}
                              </pre>
                            ) : typeof item.value === "object" ? (
                              <pre className="text-xs w-[90%] whitespace-pre-wrap">
                                {JSON.stringify(item.value, null, 2)}
                              </pre>
                            ) : (
                              <p className="w-[95%]">{item.value || "No data found"}</p>
                            )}
                            <FaRegCopy
                              className="text-gray-600 cursor-pointer"
                              onClick={(e) => handleCopy(item.value, e)}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="border border-gray-200 p-2 rounded-sm">
                <p className="text-[16px]">No ASNs found</p>
              </div>
            )}
          </div>
        </div>
      </App>
      <div className="mt-4 flex items-center">
        <CreditHistoryViewer transactionHistory={transactionHistory} />
      </div>
    </>
  );
};

export default HackerTargetASN;

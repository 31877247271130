import { Button, Modal, Select, Tabs } from "antd";
import { useEffect, useState } from "react";
import { PayloadConfiguration, PayloadStructure, ResponseConfiguration } from "../../../utils/interfaces";
import { getEndpointFromNameAndId, getTypeFromValue } from "../../../utils/constants";
import { useWorkflow } from "../../../contexts/WorkflowContext";
import { useParams } from "react-router-dom";
import { FaCheck, FaRegCopy } from "react-icons/fa";
import PingViewer from "../Modals/PingViewer/PingViewer";
import { MdDeleteOutline } from "react-icons/md";
import { IoIosRefresh, IoMdAdd } from "react-icons/io";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";

type Props = {
  modal: boolean;
  action: any;
  close: () => void;
  responseConfiguration: ResponseConfiguration[];
  payloadConfiguration: PayloadConfiguration[];
};

const WebhookModal = ({ action, modal, close, responseConfiguration, payloadConfiguration }: Props) => {
  const [loading, setLoading] = useState(false);

  const [responses, setResponses] = useState<ResponseConfiguration[]>(responseConfiguration);
  const [payloads, setPayloads] = useState<PayloadConfiguration[]>(payloadConfiguration);
  const [isCopied, setIsCopied] = useState(false);
  const [activeTab, setActiveTab] = useState("url");

  const [ping, setPing] = useState<any>({
    loading: false,
    data: action.ping,
  });

  const {
    updateResponseConfig,
    updatePayloadConfig,
    saveWorkflowActions,
    setPublishWarning,
    getAllWorkflowActionsDetails,
  } = useWorkflow();
  const { id } = useParams();

  useEffect(() => {
    setResponses(responseConfiguration);
    setPayloads(payloadConfiguration);
  }, [responseConfiguration, payloadConfiguration]);

  const saveOutputStructure = async () => {
    const filteredResponses = responses?.filter((res) => {
      return !!res?.name;
    });
    setLoading(true);
    const updated = updateResponseConfig(filteredResponses, action.id);
    updatePayloadConfig(payloads, action.id, false);
    setResponses(updated);
    await saveWorkflowActions(id || "");
    setPublishWarning(true);
    setLoading(false);
    close();
  };

  const refreshPing = async () => {
    setPing({ ...ping, loading: true });
    const data = await getAllWorkflowActionsDetails(id || "");
    setPing({
      loading: false,
      data: data?.actions?.find((act: any) => act.id === action.id)?.ping || undefined,
    });
  };

  const addFields = (keys: string[]) => {
    setResponses((prev) =>
      prev.concat(
        keys.map((key) => {
          const name = key.split(".");
          return {
            name: name[name.length - 1],
            description: "",
            responseStructureId: key,
            type: getTypeFromValue(ping.data[key]),
          };
        })
      )
    );
  };

  const addField = (key: string) => {
    const temp = [...responses];
    const name = key.split(".");
    temp.push({
      name: name[name.length - 1],
      description: "",
      responseStructureId: key,
      type: getTypeFromValue(ping.data[key]),
    });
    setResponses(temp);
  };

  const handleAddFields = () => {
    // Accumulate all keys in a single array
    const keysToAdd: string[] = [];
    // Recursive function to traverse and collect keys
    const collectKeys = (data: any, prefix = "") => {
      Object.entries(data).forEach(([key, value]) => {
        const currentKey = prefix ? `${prefix}.${key}` : key;
        // Add the current key
        keysToAdd.push(currentKey);
        if (Array.isArray(value)) {
          // For arrays, include the index as part of the key
          value.forEach((item, index) => {
            const arrayKey = `${currentKey}.${index}`;
            keysToAdd.push(arrayKey); // Add the array index key
            if (item && typeof item === "object") {
              // Recurse for objects within arrays
              collectKeys(item, arrayKey);
            }
          });
        } else if (value && typeof value === "object") {
          // Recurse for nested objects
          collectKeys(value, currentKey);
        }
      });
    };
    // Collect all keys from ping.data
    collectKeys(ping.data);
    // Call addFields once with the accumulated keys
    addFields(keysToAdd);
  };

  const handlePaste = (e: any, index: number, field: string) => {
    e.preventDefault(); // Prevent the default paste behavior
    const pastedData = e.clipboardData.getData("text"); // Get clipboard data

    // Split the pasted data by new lines (assuming each line is a row)
    const lines = pastedData.split("\t").filter((line: any) => line.trim() !== ""); // Avoid empty lines

    const updatedRows: any[] = [...responses];

    // Update the rows starting from the pasted input's index
    lines.forEach((line: any, lineIndex: number) => {
      if (index + lineIndex < responses.length) {
        // Update existing rows
        updatedRows[index + lineIndex][field] = line;
      } else {
        // Add new rows if needed
        const newRow: any = { name: "", description: "" };
        newRow[field] = line;
        updatedRows.push(newRow);
      }
    });

    setResponses(updatedRows);
  };

  // function to return code for "fixed" type fields
  const getFixedCode = (field: PayloadStructure) => {
    const includeNames = ["search/create", "navigator", "pipedrive"];
    if (includeNames.some((name) => action.actionDetails.name.toLowerCase().includes(name))) {
      return payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString
        ? typeof payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString ===
          "string"
          ? payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString
          : JSON.stringify(payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString)
        : "";
    } else {
      return getEndpointFromNameAndId(
        action.actionDetails.name,
        payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""
      );
    }
  };

  const generateCurl = (fieldData: any) => {
    const payload = responses.reduce((acc: any, field: any) => {
      acc[field.name] = `placeholder_${field.name}`; // Use placeholders for values
      return acc;
    }, {});

    const webhookURL = getFixedCode(fieldData);

    // Convert the dynamically created object to a JSON string
    const jsonData = JSON.stringify(payload, null, 2);

    const curlCommand = `
        curl --location '${webhookURL}' \\
        --header 'Content-Type: application/json' \\
        --data '${jsonData}'`;
    return curlCommand;
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  function flattenObjectToOptions(obj: any, parentPath: string = ""): Array<{ label: string; value: string }> {
    let result: Array<{ label: string; value: string }> = [];

    for (const [key, value] of Object.entries(obj)) {
      const currentPath = parentPath ? `${parentPath}.${key}` : key;

      if (value && typeof value === "object") {
        // Recursively process nested objects
        const nestedOptions = flattenObjectToOptions(value, currentPath);
        result = result.concat(nestedOptions);
      } else {
        // Add root level or leaf properties
        result.push({
          label: key,
          value: currentPath,
        });
      }
    }

    return result;
  }

  const fieldSwitch = (field: PayloadStructure) => {
    switch (field.type) {
      case "webhookArray":
        return (
          <div className="flex flex-col w-full gap-6">
            {responses.map((response, index) => (
              <div className="w-full" key={index}>
                <p className="mb-2 font-medium">Input {index + 1}</p>
                <div className="flex gap-3 items-center">
                  <input
                    className="flex-1 px-4 py-2 rounded-lg border border-gray-300 focus:border-primary focus:ring-1 focus:ring-primary"
                    type="text"
                    value={response.name}
                    onChange={(e) => {
                      const temp = [...responses];
                      temp[index].name = e.target.value;
                      setResponses(temp);
                    }}
                    placeholder="Name of the column"
                    onPaste={(e) => handlePaste(e, index, "name")}
                  />
                  <input
                    className="flex-1 px-4 py-2 rounded-lg border border-gray-300 focus:border-primary focus:ring-1 focus:ring-primary"
                    type="text"
                    placeholder="Webhook field"
                    value={response.responseStructureId || ""}
                    onChange={(e) => {
                      const temp = [...responses];
                      temp[index].responseStructureId = e.target.value;
                      setResponses(temp);
                    }}
                    onPaste={(e) => handlePaste(e, index, "responseStructureId")}
                  />
                  <button
                    className="p-2 hover:bg-primary/10 rounded-full text-primary transition-colors duration-200"
                    onClick={() => {
                      const temp = [...responses];
                      temp.splice(index, 1);
                      setResponses(temp);
                    }}
                  >
                    <MdDeleteOutline size={24} />
                  </button>
                </div>
              </div>
            ))}
            <button
              className="self-start px-4 py-2 border border-black rounded-lg hover:bg-black hover:text-white transition-colors duration-200"
              onClick={() => {
                const temp = [...responses];
                temp.push({
                  name: "",
                  description: "",
                });
                setResponses(temp);
              }}
            >
              + Add input
            </button>
          </div>
        );
      case "webhookArrayDropdown":
        return (
          <div className="flex flex-col w-full justify-center items-start gap-4">
            {responses.map((response, index) => {
              let dropdownOptions: any[] = [];

              if (ping.data) {
                dropdownOptions = flattenObjectToOptions(ping.data);
              }

              return (
                <div className="w-full" key={index}>
                  <div className="flex gap-3 justify-between items-center">
                    <p className="text-sm font-semibold min-w-fit">Input {index + 1}</p>
                    <input
                      className="w-[47%] h-4 px-2 py-4 rounded border-black"
                      type="text"
                      value={response.name}
                      onChange={(e) => {
                        const temp = [...responses];
                        temp[index].name = e.target.value;
                        setResponses(temp);
                      }}
                      placeholder="Table Column"
                      onPaste={(e) => handlePaste(e, index, "name")}
                    />
                    <Select
                      showSearch
                      style={{ width: "47%" }}
                      placeholder="Webhook field"
                      value={response.responseStructureId || undefined} // `undefined` clears the field if value is empty
                      onChange={(value) => {
                        const temp = [...responses];
                        temp[index].responseStructureId = value;
                        setResponses(temp);
                      }}
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                      }
                      options={dropdownOptions}
                      className="text-start"
                    />
                    <button
                      className="hover:bg-primary/10 h-fit w-fit p-2 rounded-full text-primary"
                      onClick={() => {
                        const temp = [...responses];
                        temp.splice(index, 1);
                        setResponses(temp);
                      }}
                    >
                      <MdDeleteOutline size={25} />
                    </button>
                  </div>
                </div>
              );
            })}
            <button
              className="border border-black rounded-lg p-2"
              onClick={() => {
                const temp = [...responses];
                temp.push({
                  name: "",
                  description: "",
                });
                setResponses(temp);
              }}
            >
              + Add input
            </button>
          </div>
        );
      case "fixed":
        return (
          <div className="w-full rounded-xl p-4 bg-gray-100">
            <Tabs
              activeKey={activeTab}
              onChange={setActiveTab}
              type="card"
              className="min-h-[120px]"
              items={[
                {
                  label: "Plain Webhook URL",
                  key: "url",
                  children: (
                    <div className="relative break-words ">
                      <code className="block">{getFixedCode(field)}</code>
                      <Button
                        type="text"
                        icon={isCopied && activeTab === "url" ? <FaCheck /> : <FaRegCopy />}
                        className="absolute bottom-0 right-1 hover:bg-gray-200 bg-white rounded-md"
                        onClick={() => handleCopy(getFixedCode(field))}
                      />
                    </div>
                  ),
                },
                {
                  label: "cURL Command",
                  key: "curl",
                  children: (
                    <div className="relative break-words">
                      <code className="block whitespace-pre-wrap">{generateCurl(field)}</code>
                      <Button
                        type="text"
                        icon={isCopied && activeTab === "curl" ? <FaCheck /> : <FaRegCopy />}
                        className="absolute bottom-0 right-1 hover:bg-gray-200 bg-white rounded-md"
                        onClick={() => handleCopy(generateCurl(field))}
                      />
                    </div>
                  ),
                },
              ]}
            />
          </div>
        );

      default:
        return;
    }
  };

  return (
    <Modal
      open={modal}
      mask={false}
      onOk={saveOutputStructure}
      onCancel={close}
      width="50rem"
      className="text-center overflow-y-auto p-0"
      footer={[
        <button
          key="ok"
          className="px-6 py-2 rounded-lg text-base font-semibold border border-black hover:bg-black hover:text-white transition-colors duration-200"
          onClick={saveOutputStructure}
        >
          {loading ? "Saving..." : "Save"}
        </button>,
      ]}
    >
      <div className="flex flex-col gap-8 p-6">
        {action?.actionDetails?.payloadStructure &&
          action.actionDetails.payloadStructure.map((field: any, idx: number) => {
            return (
              field.payloadStructureId.includes("Modal") && (
                <div key={idx} className="flex flex-col w-full gap-6 p-6 border border-gray-300 rounded-xl shadow-sm">
                  <div className="space-y-2">
                    <p className="text-lg tracking-primary font-semibold">
                      {field.name.toUpperCase()}
                      {!field.required && (
                        <span className="text-sm font-normal tracking-normal text-gray-400 ml-2">(Optional)</span>
                      )}
                    </p>
                    <p className="text-sm text-gray-600">{field.description}</p>
                  </div>
                  {fieldSwitch(field)}
                </div>
              )
            );
          })}

        <div className="flex flex-col w-full gap-6">
          <div className="flex items-center justify-between">
            <div className="space-y-1">
              <p className="text-lg tracking-primary font-semibold">Mapping</p>
              <p className="text-sm text-gray-600">Click the field to add as an acceptable parameter</p>
            </div>
            <button
              className="flex gap-2 items-center px-3 py-2 hover:bg-gray-200 rounded-lg transition-colors duration-200"
              disabled={ping.loading}
              onClick={refreshPing}
            >
              {ping.loading ? <SpinnerStatus /> : <IoIosRefresh size={20} />}
              Refresh
            </button>
          </div>

          <div className="bg-gray-100 rounded-xl p-6">
            {ping.data ? (
              <>
                <div className="flex flex-wrap items-center gap-4 mb-6">
                  {Object.entries(ping.data).map(([key, value], idx) => (
                    <PingViewer keyName={key} value={value} prev={""} key={idx} addField={addField} />
                  ))}
                </div>
                <div className="w-full flex justify-center gap-4 items-center">
                  <button
                    className="w-full font-medium py-3 px-4 rounded-lg bg-gray-200 hover:bg-gray-300 transition-colors duration-200 text-sm flex items-center justify-center gap-2"
                    onClick={handleAddFields}
                  >
                    <IoMdAdd size={18} />
                    Add All Fields
                  </button>
                </div>
              </>
            ) : (
              <div className="py-8 flex flex-col items-center space-y-2 text-gray-600">
                <p className="font-medium">No mapping found</p>
                <p>The webhook hasn't received any data yet.</p>
                <p>Make a request and refresh to see the mapping.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WebhookModal;

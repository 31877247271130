import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { ActionResponses } from "@/utils/interfaces";
import Filters from "../../Actions/Filters";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import { FiDownload, FiSave } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { ViewerProps } from "./TableHandlers";
import ReviewModal from "../../Modals/ReviewModal";
import { AlertDialog, Button, Flex, TextField } from "@radix-ui/themes";
import XLSXInput from "../../XLSXInput";
import { deleteAllRecords, updateWorkflowSettings, wipeWorkflowCache } from "@/utils/apis";
import { GrRefresh } from "react-icons/gr";
import { interfaceinputAction } from "@/hooks/useFilter";
import PlaySimple from "/assets/play-simple.svg";
import FunnelIcon from "/assets/funnel.svg";
import DownloadIcon from "/assets/download.svg";
import ExportIcon from "/assets/export.svg";
import DeleteIcon from "/assets/delete.svg";
import SearchIcon from "/assets/search (1) 1.svg";
import TooltipComp from "../../../Generics/Tooltip";
import { useWorkflow } from "@/contexts/WorkflowContext";
import { captureEvent, isFeatureEnabled } from "@/utils/featureFlags";
import { TbRefreshDot } from "react-icons/tb";
import CookieIcon from "/assets/cookie.svg";
import WhiteCookieIcon from "/assets/cookie_white.svg";
import SaveIcon from "/assets/save 1.svg";
import InfoIcon from "/assets/info1.svg";
import { generatePastTimestamp, timestampToDate } from "../../utils";
import { Drawer } from "antd";
import { viewerComponent } from "./ViewerUtil";
import { DatePicker } from "antd";
import { MdKeyboardArrowDown } from "react-icons/md";
import dayjs from "dayjs";

interface Props {
  handleAdd: (rowsCount?: number) => Promise<void>;
  currentSection: number;
  setCreateRows: React.Dispatch<React.SetStateAction<number>>;
  createRows: number;
  loading: boolean;
  id: string;
  responses: ActionResponses[];
  filters: {};
  inputFilters: {};
  actions: ActionResponses[];
  setFilters: React.Dispatch<React.SetStateAction<{} | null>>;
  setInputFilters: React.Dispatch<React.SetStateAction<{} | null>>;
  setLoadTrigger: React.Dispatch<
    React.SetStateAction<{
      counter: boolean;
      ignoreCache?: boolean;
    }>
  >;
  setSearchedText: React.Dispatch<React.SetStateAction<string>>;
  sections: number;
  setCurrentSection: React.Dispatch<React.SetStateAction<number>>;
  selectedRows: any[];
  runMultipleRows: () => Promise<void>;
  handleDelete: () => Promise<void>;
  setChatNameModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleExport: (type: "csv" | "excel") => Promise<void>;
  handleRefresh: () => Promise<void>;
  exporting: boolean;
  setDownloadLink: React.Dispatch<React.SetStateAction<string>>;
  setExporting: React.Dispatch<React.SetStateAction<boolean>>;
  downloadLink: string;
  customViewer: ViewerProps;
  setCustomViewer: React.Dispatch<React.SetStateAction<ViewerProps>>;
  modalStyle: {};
  currentSectionRef: any;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  stopAllRowsRunning: () => void;
  handleHubspotPullContacts: () => void;
  loadingHubspotContacts: boolean;
  handleSfDataPull: () => void;
  loadingSfData: boolean;
  handleEngagebayContactsPull: () => void;
  loadingEngagebayContacts: boolean;
  crmSyncAction: any;
}

const TableActions: React.FC<Props> = ({
  handleAdd,
  currentSection,
  id,
  responses,
  filters,
  inputFilters,
  actions,
  setFilters,
  setInputFilters,
  setLoadTrigger,
  setSearchedText,
  sections,
  setCurrentSection,
  selectedRows,
  runMultipleRows,
  handleDelete,
  // setChatNameModal,
  handleExport,
  handleRefresh,
  exporting,
  setDownloadLink,
  setExporting,
  downloadLink,
  customViewer,
  setCustomViewer,
  modalStyle,
  currentSectionRef,
  loading,
  setLoading,
  // stopAllRowsRunning
  handleHubspotPullContacts,
  loadingHubspotContacts,
  handleSfDataPull,
  loadingSfData,
  handleEngagebayContactsPull,
  loadingEngagebayContacts,
  crmSyncAction,
}) => {
  const [openExportAlert, setOpenExportAlert] = useState(false);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [openDeleteAllAlert, setOpenDeleteAllAlert] = useState(false);
  const { workflowBuildDetails, getWorkflowConfig } = useWorkflow();
  const [cacheEnabled, setCacheEnabled] = useState<boolean | null>(null);
  const [updatingCache, setUpdatingCache] = useState(false);
  const [cacheDays, setCacheDays] = useState(1);
  const [selectedPredefinedCacheDuration, setSelectedPredefinedCacheDuration] = useState<number | null>(null);
  const [selectedCacheDurationUnit, setSelectedCacheDurationUnit] = useState<string>("day");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [cacheExpiryTTL, setCacheExpiryTTL] = useState<number | null>(null);
  const [triggerSoftRefresh, setTriggerSoftRefresh] = useState(false);
  const [preventTooltipClose, setPreventTooltipClose] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs("2025-01-01"));

  useEffect(() => {
    if (id) {
      getWorkflowConfig(id)
        .then((config) => {
          if (config?.workflowDetails?.cache_enabled !== undefined)
            setCacheEnabled(config.workflowDetails.cache_enabled);
          if (config?.workflowDetails?.cache_config)
            setCacheExpiryTTL(config.workflowDetails.cache_config?.expiryDate ?? null);
        })
        .catch((error) => {
          console.error("Error fetching workflow config:", error);
        });
    }
  }, [id, triggerSoftRefresh]);

  useEffect(() => {
    if (workflowBuildDetails?.cache_enabled !== undefined && cacheEnabled === null) {
      setCacheEnabled(workflowBuildDetails.cache_enabled);
    }
  }, [workflowBuildDetails]);

  const getFilterButtonText = () => {
    const appliedFiltersNum = Object?.entries(filters || {})?.length + Object?.entries(inputFilters || {})?.length;

    return (
      <>
        <span className="font-thin flex gap-2">
          {appliedFiltersNum > 0 ? <div className="font-normal text-md">{appliedFiltersNum}</div> : ""}
          <img src={FunnelIcon} className={`h-[0.95rem] w-[0.95rem] ${appliedFiltersNum && "translate-y-[3px]"}`} />
        </span>{" "}
      </>
    );
  };

  const getActions = (actions: ActionResponses[]) => {
    return actions?.filter((action: any) => action?.type !== "input" && action?.type !== "add_section");
  };

  const getinputActions = (actions: ActionResponses[]) => {
    const tempActions = actions?.filter((action: any) => action?.type === "input" || action?.type === "add_section");
    const inputActions: interfaceinputAction[] = [];
    tempActions?.forEach((action: any) => {
      action?.response?.forEach((response: any) => {
        inputActions.push({
          name: response?.name,
          id: response?.responseId,
        });
      });
    });
    return inputActions;
  };

  const handleCacheToggle = async (checked: boolean, ttl?: number) => {
    setUpdatingCache(true);
    try {
      if (ttl === undefined) {
        const now = dayjs();
        const targetDate = dayjs("2025-01-01");
        const defaultCacheDays = targetDate.diff(now, "day");
        await updateWorkflowSettings(id, {
          cacheEnabled: checked,
          cacheConfig: { expiryDate: checked ? generatePastTimestamp(Math.abs(defaultCacheDays)) : null },
        });
      } else {
        await updateWorkflowSettings(id, {
          cacheEnabled: checked,
          cacheConfig: { expiryDate: ttl },
        });
      }
      setCacheEnabled(checked);
      captureEvent("cache_toggle", {
        workflow_id: id,
        cache_enabled: checked,
      });
    } catch (error) {
      console.error("Failed to update cache settings:", error);
      setCacheEnabled(!checked);
    } finally {
      setTriggerSoftRefresh(!triggerSoftRefresh);
      setUpdatingCache(false);
    }
  };

  const handleRefreshIgnoreCache = async () => {
    setLoading(true);
    setLoadTrigger((prev) => ({
      counter: !prev.counter,
      ignoreCache: true,
    }));
    captureEvent("refresh_ignore_cache", {
      workflow_id: id,
    });
  };

  const handleClearCache = async () => {
    setLoading(true);
    try {
      await wipeWorkflowCache();
      // Refresh the table after cache is wiped
      setLoadTrigger((prev) => ({
        counter: !prev.counter,
        ignoreCache: true,
      }));
      captureEvent("clear_cache", {
        workflow_id: id,
      });
    } catch (error) {
      console.error("Error wiping workflow cache:", error);
    } finally {
      setLoading(false);
    }
  };

  const PredefinedCacheOptions = [
    {
      days: 7,
      label: "1 Week",
    },
    {
      days: 30,
      label: "1 Month",
    },
  ];

  return (
    <>
      {customViewer.type === "review" ? (
        <ReviewModal
          close={() => setCustomViewer({ ...customViewer, open: false })}
          data={customViewer.data}
          open={customViewer.open}
          title={customViewer.title}
          section={currentSection}
          style={modalStyle}
          currentSectionRef={currentSectionRef}
        />
      ) : (
        <Drawer
          title={customViewer.title()}
          placement="right"
          onClose={() => setCustomViewer({ ...customViewer, open: false })}
          open={customViewer.open}
          width={customViewer.type === "email" ? "50%" : "30%"}
        >
          {viewerComponent(customViewer)}
        </Drawer>
      )}
      {exporting && (
        <dialog className="fixed inset-0 z-[200] bg-black bg-opacity-50 flex items-center justify-center backdrop-filter w-full h-full">
          <div className="flex flex-col min-w-[440px] min-h-[100px] bg-white px-4 py-2 rounded-md">
            <div className="flex items-center justify-between w-full mb-2 pb-2 border-b gap-2">
              <div className="flex items-center gap-2">
                <FiSave className="w-6 h-6" />
                <h3 className="text-lg font-semibold">Exporting Table Data</h3>
              </div>
              <button
                type="button"
                className="p-1 rounded-full hover:bg-gray-100"
                onClick={() => {
                  setDownloadLink("");
                  setExporting(false);
                }}
              >
                <IoClose size="1.5rem" />
              </button>
            </div>
            {downloadLink.length === 0 ? (
              <div className="flex flex-col items-center justify-center w-full my-2 gap-2">
                <div className="h-1.5 w-full bg-blue-100 overflow-hidden rounded-lg">
                  <div className="animate-progress w-full h-full bg-blue-500 origin-left-right"></div>
                </div>
                <span className="text-sm text-[#69717d]">Please wait while we export the table data</span>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center w-full my-2 gap-2">
                <span className="text-sm text-[#69717d]">Your file is ready for download</span>
                <button
                  type="button"
                  className="flex items-center justify-center px-4 py-2 font-semibold text-white bg-primary rounded-md w-full gap-2"
                  onClick={() => {
                    window.open(downloadLink, "_blank");
                    setExporting(false);
                    setDownloadLink("");
                  }}
                >
                  <FiDownload className="w-4 h-4" />
                  Download
                </button>
              </div>
            )}
          </div>
        </dialog>
      )}

      <section className="w-full flex justify-between items-center overflow-auto gap-2 p-4 bg-white border-b border-gray-200 relative">
        <AlertDialog.Root open={openDeleteAlert}>
          <AlertDialog.Content maxWidth="450px">
            <AlertDialog.Title>Delete the selected row(s)?</AlertDialog.Title>
            <AlertDialog.Description size="2">
              Are you sure you want to delete the selected rows from the table?
            </AlertDialog.Description>

            <Flex gap="3" mt="4" justify="end">
              <AlertDialog.Cancel>
                <Button variant="soft" color="gray" onClick={() => setOpenDeleteAlert(false)}>
                  Cancel
                </Button>
              </AlertDialog.Cancel>
              <AlertDialog.Action>
                <Button
                  variant="solid"
                  color="red"
                  onClick={() => {
                    handleDelete();
                    setOpenDeleteAlert(false);
                  }}
                >
                  Yes
                </Button>
              </AlertDialog.Action>
            </Flex>
          </AlertDialog.Content>
        </AlertDialog.Root>

        <AlertDialog.Root open={openExportAlert} onOpenChange={setOpenDeleteAlert}>
          <AlertDialog.Content maxWidth="450px">
            <AlertDialog.Title>
              <Flex justify="between" className="mb-4">
                <span>Select export format</span>
                <button onClick={() => setOpenExportAlert(false)} className="text-gray-500 hover:text-gray-700">
                  <IoClose size={20} />
                </button>
              </Flex>
            </AlertDialog.Title>

            <AlertDialog.Description size="2" className="font-normal">
              Excel has <span className="font-bold text-md">limit of 32700</span> characters{" "}
              <span className="font-bold text-md">per cell</span>, if your data to too large CSV export is recommended
              to avoid data truncation.
            </AlertDialog.Description>

            <Flex gap="3" mt="6">
              <Button
                variant="soft"
                color="green"
                onClick={() => {
                  handleExport("csv");
                  setOpenExportAlert(false);
                }}
                className="w-[50%]"
              >
                Export to CSV
              </Button>
              <Button
                variant="soft"
                color="gray"
                onClick={() => {
                  handleExport("excel");
                  setOpenExportAlert(false);
                }}
                className="w-[50%]"
              >
                Export to Excel
              </Button>
            </Flex>
          </AlertDialog.Content>
        </AlertDialog.Root>

        <AlertDialog.Root open={openDeleteAllAlert}>
          <AlertDialog.Content maxWidth="450px">
            <AlertDialog.Title>Delete all workflow data?</AlertDialog.Title>
            <AlertDialog.Description size="2">
              Are you sure you want to delete all data from this workflow? This action cannot be undone.
            </AlertDialog.Description>

            <Flex gap="3" mt="4" justify="end">
              <AlertDialog.Cancel>
                <Button variant="soft" color="gray" onClick={() => setOpenDeleteAllAlert(false)}>
                  Cancel
                </Button>
              </AlertDialog.Cancel>
              <AlertDialog.Action>
                <Button
                  variant="solid"
                  color="red"
                  onClick={async () => {
                    setOpenDeleteAllAlert(false);
                    setLoading(true);
                    await deleteAllRecords(id);
                    setLoadTrigger((prev) => ({
                      counter: !prev.counter,
                      ignoreCache: true,
                    }));
                  }}
                >
                  Delete All
                </Button>
              </AlertDialog.Action>
            </Flex>
          </AlertDialog.Content>
        </AlertDialog.Root>

        <section className="flex flex-row gap-2">
          <div>
            <Filters
              filters={filters}
              inputFilters={inputFilters}
              actions={getActions(actions)}
              inputActions={getinputActions(actions)}
              onChange={(filters: any, inputFilters: any) => {
                setFilters({ ...filters });
                setInputFilters({ ...inputFilters });
                setLoadTrigger((prev) => ({
                  counter: !prev.counter,
                  ignoreCache: true,
                }));
                setLoading(true);
              }}
              triggerButton={
                <button
                  className={`!border border-[#000000] bg-[#FFF9ED] rounded-[5px] text-sm flex items-center gap-2 px-3 py-1 min-h-8 font-semibold ${loading ? "cursor-not-allowed pointer-events-none" : "cursor-pointer"}`}
                >
                  {getFilterButtonText()}
                </button>
              }
            />
          </div>

          <div>
            <TextField.Root
              placeholder="Search for row data"
              onChange={async (e) => {
                setSearchedText(e.target.value);
              }}
              className="min-h-8 border border-[#000000] rounded-[5px] w-56 bg-[#FBFBFB]"
            >
              <TextField.Slot>
                <img src={SearchIcon} className="h-[0.95rem] w-[0.95rem]" />
              </TextField.Slot>
            </TextField.Root>
          </div>
          <div className="w-px h-6 bg-[#CCC9C9] self-center" />
          <button
            disabled={currentSection !== 0}
            onClick={() => handleAdd()}
            className={`!border border-[#000000] bg-[#FBFBFB] rounded-[5px] text-sm flex items-center gap-2 px-3 py-1 min-h-8 font-semibold disabled:cursor-not-allowed cursor-pointer`}
          >
            <FaPlus className="text-[#1c1c1c]" />
            Add a row
          </button>
          {/* Run Button */}
          {selectedRows?.length ? (
            <button
              disabled={loading || selectedRows.length === 0}
              onClick={runMultipleRows}
              className={`!border border-[#31059F] bg-[#EFEEFF] rounded-[5px] text-sm flex items-center gap-2 px-3 py-1 min-h-8 font-semibold disabled:cursor-not-allowed cursor-pointer`}
            >
              <span>Run {selectedRows?.length} </span>
              <img src={PlaySimple} className="h-[0.95rem] w-[0.95rem]" />
            </button>
          ) : (
            ""
          )}

          {/* Vertical Divider */}
        </section>

        <section className="flex items-center justify-center gap-2">
          {sections > 0 && (
            <div className="flex gap-2 w-fit mx-auto">
              {Array.from({ length: sections + 1 }, (_, i) => i).map((section, index) => (
                <div key={index}>
                  <Button
                    variant={section === currentSection ? "solid" : "surface"}
                    color="indigo"
                    onClick={() => {
                      if (!id) {
                        return;
                      }
                      setFilters(null);
                      setInputFilters(null);
                      setCurrentSection(section);
                      setLoading(true);
                    }}
                  >
                    Section {section + 1}
                  </Button>
                </div>
              ))}
            </div>
          )}
        </section>

        <section className="flex flex-row w-fit gap-2">
          {/* Refresh button */}
          {currentSection > 0 && (
            <Button
              variant="solid"
              color="indigo"
              disabled={loading}
              onClick={() => handleRefresh()}
              className={loading ? "cursor-not-allowed pointer-events-none" : "cursor-pointer"}
            >
              {loading ? <SpinnerStatus /> : <GrRefresh />}
            </Button>
          )}

          <>
            <TooltipComp
              trigger={<img src={InfoIcon} className="-ml-1 text-gray-500 cursor-help h-4 self-center" />}
              content={
                <div className="max-w-[40ch]">
                  Caching saves your table's past data, so you can access it within your workflow without spending
                  credits on every new run.
                </div>
              }
            />
            <TooltipComp
              open={tooltipOpen}
              onOpenChange={setTooltipOpen}
              preventTooltipClose={preventTooltipClose}
              trigger={
                <div
                  className="flex align-center items-center cursor-pointer underline underline-offset-2 text-[#333333]"
                  onClick={() => {
                    setSelectedPredefinedCacheDuration(null);
                    setTooltipOpen(!tooltipOpen);
                  }}
                >
                  Edit
                </div>
              }
              content={
                <div className="p-2 w-[17rem]">
                  <div className="text-lg leading-[1.25rem]">How far back do you want to get cache data from?</div>
                  <div className="flex gap-3 mt-4 justify-between">
                    {PredefinedCacheOptions.map((option) => (
                      <div
                        key={option.days}
                        className={`border rounded-[2px] bg-[#F3F4F6] py-1 px-3 text-md cursor-pointer min-w-fit w-full text-center text-sm ${selectedPredefinedCacheDuration === option.days ? "border-[#333333]" : ""}`}
                        onClick={() => {
                          setCacheDays(option.days);
                          setSelectedPredefinedCacheDuration(option.days);
                          // setSelectedCacheDurationUnit("day");
                          // Update the datepicker to show the date from X days ago
                          setSelectedDate(dayjs().subtract(option.days, "day"));
                        }}
                      >
                        <div>{option.label}</div>
                        {/* <div>{generatePastTimestamp(option.days)}</div> */}
                      </div>
                    ))}
                  </div>
                  <div className="w-full h-px bg-[#CCC9C9] my-4 scale-x-110" />
                  <div>
                    <div className="mb-4 underline underline-offset-4">Specify custom range</div>
                    <DatePicker
                      onChange={(selectedDate) => {
                        const now = dayjs();
                        const selected = dayjs(selectedDate);
                        const daysDiff = now.diff(selected, "day");

                        setCacheDays(daysDiff);
                        setSelectedPredefinedCacheDuration(null);
                        setSelectedCacheDurationUnit("day");
                        setPreventTooltipClose(false);
                        setSelectedDate(selected);
                      }}
                      onOpenChange={(e) => {
                        setPreventTooltipClose(e);
                      }}
                      suffixIcon={<MdKeyboardArrowDown className="text-[1.5rem]" />}
                      className="w-full"
                      format="MMM DD, YYYY"
                      value={selectedDate}
                      defaultValue={dayjs("2025-01-01")}
                      disabledDate={(current) => {
                        return current && current > dayjs().endOf("day");
                      }}
                    />
                  </div>
                  <div className="w-full h-px bg-[#CCC9C9] my-4 scale-x-110" />
                  <div className="h-7 flex align-center items-center">
                    {cacheExpiryTTL ? (
                      <div>
                        <span className="font-semibold">Getting cached data from:</span>{" "}
                        <span className="text-neutral-600 font-light">{timestampToDate(cacheExpiryTTL)}</span>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div className="w-full h-px bg-[#CCC9C9] my-4 scale-x-110" />
                  <div>
                    <div
                      className="flex gap-3 bg-[#333333] text-md rounded-[5px] text-[#FFFFFF] items-center justify-center px-3 py-2 min-h-8 font-semibold cursor-pointer"
                      onClick={async () => {
                        if (updatingCache) return;

                        const totalDays = selectedCacheDurationUnit === "day" ? cacheDays : cacheDays * 7;
                        const ttl = generatePastTimestamp(totalDays);
                        setTooltipOpen(false);
                        setSelectedPredefinedCacheDuration(null);
                        await handleCacheToggle(true, ttl);
                      }}
                    >
                      Save <img src={SaveIcon} />
                    </div>
                  </div>
                </div>
              }
            />
            <button
              className={`!border border-[#333333] rounded-[5px] text-sm flex items-center px-3 py-1 min-h-8 cursor-pointer font-semibold tracking-tight ${cacheEnabled ? "text-[#047831] bg-[#EDFFF4]" : "text-[#333333] bg-[#FBFBFB]"}`}
              onClick={() => handleCacheToggle(!cacheEnabled)}
              disabled={updatingCache}
            >
              <span className="text-nowrap mr-2">{cacheEnabled ? "Using Cache" : "Not Using Cache"}</span>
              <img src={cacheEnabled ? CookieIcon : WhiteCookieIcon} className="h-[1.35rem] w-[1.35rem]" />
            </button>
            <div className="w-px h-6 bg-[#CCC9C9] self-center" />
            {/* Import Button */}
            <div>
              {(() => {
                switch (crmSyncAction) {
                  case "SALESFORCE_PULL_DATA":
                    return (
                      <Button
                        color="gray"
                        variant="soft"
                        highContrast
                        onClick={handleSfDataPull}
                        disabled={loadingSfData}
                      >
                        {loadingSfData ? (
                          <SpinnerStatus />
                        ) : (
                          <img
                            src={
                              "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/salesforce.svg"
                            }
                            alt="Salesforce"
                            className="w-4 h-4"
                          />
                        )}
                        {loadingSfData ? "Importing..." : "Import Records"}
                      </Button>
                    );
                  case "ENGAGEBAY_CONTACT_ADDED":
                    return (
                      <Button
                        color="gray"
                        variant="soft"
                        highContrast
                        onClick={handleEngagebayContactsPull}
                        disabled={loadingEngagebayContacts}
                      >
                        {loadingEngagebayContacts ? (
                          <SpinnerStatus />
                        ) : (
                          <img
                            src={
                              "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/engagebay.png"
                            }
                            alt="Engagebay"
                            className="w-4 h-4"
                          />
                        )}
                        {loadingEngagebayContacts ? "Importing..." : "Import Records"}
                      </Button>
                    );
                  case "HUBSPOT_CONTACT_ADDED":
                    return (
                      <Button
                        color="gray"
                        variant="soft"
                        highContrast
                        onClick={handleHubspotPullContacts}
                        disabled={loadingHubspotContacts}
                      >
                        {loadingHubspotContacts ? (
                          <SpinnerStatus />
                        ) : (
                          <img
                            src={
                              "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/HubSpot.svg"
                            }
                            alt="Hubspot"
                            className="w-4 h-4"
                          />
                        )}
                        {loadingHubspotContacts ? "Importing..." : "Import Records"}
                      </Button>
                    );
                  default:
                    return (
                      <XLSXInput
                        template={responses.length > 0 ? responses[0].responseConfiguration : []}
                        refresh={(): void => {
                          setLoadTrigger((prev) => ({
                            counter: !prev.counter,
                            ignoreCache: true,
                          }));
                          setLoading(true);
                        }}
                        workflowId={id || ""}
                      >
                        <button
                          className={`!border border-[#000000] bg-[#FBFBFB] rounded-[5px] text-sm flex items-center px-3 py-1 min-h-8 font-semibold disabled:cursor-not-allowed cursor-pointer`}
                        >
                          <span className="text-nowrap mr-2">Import</span>
                          <img src={DownloadIcon} className="h-[0.95rem] w-[0.95rem]" />
                        </button>
                      </XLSXInput>
                    );
                }
              })()}
            </div>
            {/* Export Button */}
            <TooltipComp
              trigger={
                <button
                  disabled={loading}
                  // onClick={() => handleExport()}
                  onClick={() => setOpenExportAlert(true)}
                  className={`!border border-[#000000] bg-[#FBFBFB] rounded-[5px] text-sm flex items-center px-3 py-1 min-h-8 font-semibold disabled:cursor-not-allowed cursor-pointer`}
                >
                  <img src={ExportIcon} className="h-[0.95rem] w-[0.95rem]" />
                </button>
              }
              content="Export to CSV or Excel"
            />

            {/* Divider */}
            <div className="w-px h-6 bg-[#CCC9C9] self-center" />
            {/* Delete Button */}
            <button
              disabled={loading}
              onClick={() => {
                if (selectedRows.length > 0) {
                  setOpenDeleteAlert(true);
                } else {
                  setOpenDeleteAllAlert(true);
                }
              }}
              className={`!border border-[#A60003] bg-[#FFF9F9] rounded-[5px] text-sm flex items-center px-3 py-1 min-h-8 font-semibold disabled:cursor-not-allowed cursor-pointer`}
            >
              {selectedRows?.length ? (
                <span className=" overflow-hidden text-nowrap max-w-xl mr-2">
                  Delete {selectedRows?.length ? selectedRows?.length : "all"}
                </span>
              ) : (
                ""
              )}
              <img src={DeleteIcon} className="h-[0.95rem] w-[0.95rem]" />
            </button>

            <TooltipComp
              trigger={
                <button
                  disabled={loading}
                  onClick={handleRefreshIgnoreCache}
                  className={`!border border-[#000000] bg-[#FBFBFB] rounded-[5px] text-sm flex items-center px-3 py-1 min-h-8 font-semibold disabled:cursor-not-allowed cursor-pointer`}
                >
                  <TbRefreshDot className="h-[0.95rem] w-[0.95rem]" />
                </button>
              }
              content="Refresh Table"
            />

            {isFeatureEnabled("clear_internal_cache_button") && (
              <TooltipComp
                trigger={
                  <button
                    disabled={loading}
                    onClick={handleClearCache}
                    className={`!border border-[#000000] bg-[#FBFBFB] rounded-[5px] text-sm flex items-center px-3 py-1 min-h-8 font-semibold disabled:cursor-not-allowed cursor-pointer`}
                  >
                    <TbRefreshDot className="h-[0.95rem] w-[0.95rem] text-red-500" />
                  </button>
                }
                content="Clear Cache"
              />
            )}
          </>
        </section>
      </section>
    </>
  );
};

export default TableActions;

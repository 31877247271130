import { Input as AntdInput, InputRef, message } from "antd";
import { useEffect, useRef, useState } from "react";
import Search from "/assets/search (1) 1.svg";
import { RiDragMove2Fill, RiExpandDiagonalLine } from "react-icons/ri";
import { ActionCategoryGroups, CategorizedAction } from "@/utils/interfaces";
import CreditsIcon from "@/assets/SVGs/GoldDollarCoin.svg";
import { AiOutlineCompress } from "react-icons/ai";
import ResponseStructureViewer from "./ModalComponents/ResponseStructureLite";
import SidebarToggleIcon from "/assets/SidebarToggleIcon.svg";
import { AnimatedTooltip } from "./ModalComponents/AnimatedTooltip";
import { AnimatedTooltipItem } from "./ModalComponents/AnimatedTooltipItem";

type Props = {
  availableActions: any[];
  source: string;
  target: string | null;
  handleActionSelection?: (actionId: string) => void;
  dragableActions?: boolean;
  onActionDropAction?: (actionId: string, hoveredSource?: string, targetX?: string | null) => void;
  doubleClickAction?: (actionId: string) => void;
  actionsCategory: CategorizedAction[];
  isOpen?: boolean;
  actionCategoryGroups?: ActionCategoryGroups;
};

const AddActionView: React.FC<Props> = ({
  handleActionSelection,
  dragableActions,
  onActionDropAction,
  doubleClickAction,
  actionsCategory,
  isOpen,
  actionCategoryGroups,
}) => {
  const [selectedAction, setSelectedAction] = useState<any>("");
  const [searchTerm, setSearchTerm] = useState("");
  const searchInputRef = useRef<InputRef | null>(null);
  const [filteredActions, setFilteredActions] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState("All");
  const [hoveredActionSource, setHoveredActionSource] = useState("");
  const [hoveredActionTarget, setHoveredActionTarget] = useState<string | null>(null);
  const [expandedSubActionId, setExpandedSubActionId] = useState<string | null>(null);

  // Helper to highlight matching text by wrapping with <mark>
  const highlightText = (text: string, query: string) => {
    if (!query) return text;
    const regex = new RegExp(`(${query})`, "gi");
    return text?.replace(regex, '<mark class="bg-[#ECFF44]">$1</mark>');
  };

  // When availableActions (or source/target) change, reset selected action and filtered list

  // Re-run search whenever search term or activeTab changes
  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm, activeTab]);

  // Focus on search input when modal opens
  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current?.focus();
    }
  }, [isOpen]);

  const handleActionSelect = (actionId: string) => {
    handleActionSelection && handleActionSelection(actionId);
    if (selectedAction === actionId) {
      setSelectedAction("");
    } else {
      setSelectedAction(actionId);
    }
  };

  const checkRecursiveResponseStructure = (section: any, lowerValue: string, matches: Set<string>) => {
    if (section.name?.toLowerCase().includes(lowerValue)) {
      matches.add(`response_${section.name}`);
      return;
    }

    if (section?.responseStructure && (section.type === "json" || section.type === "jsonArray")) {
      section?.responseStructure?.forEach((sectionVar: any) => {
        checkRecursiveResponseStructure(sectionVar, lowerValue, matches);
      });
    }
  };

  const checkSectionVariables = (section: any, lowerValue: string, matches: Set<string>) => {
    if (section.name?.toLowerCase().includes(lowerValue)) {
      matches.add(`response_${section.name}`);
      return;
    }

    if (section?.sectionVariablesStructure) {
      section?.sectionVariablesStructure?.forEach((sectionVar: any) => {
        checkSectionVariables(sectionVar, lowerValue, matches);
      });
    }
  };

  const findMatches = (action: any, searchValue: string) => {
    const matches = new Set<string>();

    if (action.name?.toLowerCase().includes(searchValue)) {
      matches.add("name");
    }
    if (action.description?.toLowerCase().includes(searchValue)) {
      matches.add("description");
    }
    if (action.responseStructure) {
      action.responseStructure.forEach((resp: any) => {
        if (resp.name?.toLowerCase().includes(searchValue)) {
          matches.add(`response_${resp.name}`);
        }

        if (resp?.sectionVariablesStructure) {
          resp.sectionVariablesStructure.forEach((sectionVar: any) => {
            checkSectionVariables(sectionVar, searchValue, matches);
          });
        }

        if (resp?.responseStructure && (resp.type === "json" || resp.type === "jsonArray")) {
          resp?.responseStructure?.forEach((sectionVar: any) => {
            checkRecursiveResponseStructure(sectionVar, searchValue, matches);
          });
        }
      });
    }

    if (action.stepDownAPIs) {
      action.stepDownAPIs.forEach((api: any) => {
        if (api.apiName?.toLowerCase().includes(searchValue)) {
          matches.add(`provider_${api.apiName}`);
        }
      });
    }

    return matches;
  };

  // Filter categories and their sub-actions based on the search term.
  // Check match in subAction.name, subAction.description (if exists), and in any response name.
  const handleSearch = (value: string) => {
    if (!value) {
      const filtered = actionsCategory.filter((ac) => {
        return activeTab === "All" || ac.parent_name === activeTab;
      });
      setFilteredActions(filtered);
      setSearchTerm(value);
      return;
    }

    setSearchTerm(value);

    const lowerValue = value.toLowerCase();

    const filtered = actionsCategory
      .map((cat) => {
        // Filter each category's actions based on search match
        const filteredSubActions = cat.actions.filter((action) => {
          if (!action) return false;
          const foundMaches = findMatches(action, lowerValue);
          return foundMaches.size > 0;
        });

        if (cat?.name.toLowerCase().includes(lowerValue) || cat?.description.toLowerCase().includes(lowerValue)) {
          return filteredSubActions?.length
            ? { ...cat, actions: filteredSubActions }
            : { ...cat, actions: cat.actions };
        }

        return { ...cat, actions: filteredSubActions };
      })
      .filter((cat) => cat.actions.length > 0);

    setFilteredActions(filtered);
  };

  const handleTabChange = (key: string) => {
    setActiveTab(key);
    setSearchTerm("");
  };

  const onDragHandler = (e: React.DragEvent) => {
    const connections = document.querySelectorAll(`.connection-svg`);
    connections?.forEach((connection) => {
      connection.classList.forEach((cls) => {
        if (cls.includes("path-highlight") || cls.includes("path-highlight-error")) {
          connection.classList.remove("path-highlight");
          connection.classList.remove("path-highlight-error");
          if (hoveredActionSource) setHoveredActionSource("");
          if (hoveredActionTarget) setHoveredActionTarget(null);
        }
      });
    });

    const addActionButtons = document.querySelectorAll(".nodrag.nopan.add-action-button");
    let hovering = false;
    let hoveredButton: Element | null = null;
    // offset to factor in the drag button distance
    let offSetX = 0;
    addActionButtons.forEach((button) => {
      const buttonRect = button.getBoundingClientRect();
      const buttonCenterX = buttonRect.left + buttonRect.width / 2 + offSetX;
      const buttonCenterY = buttonRect.top + buttonRect.height / 2;

      const mouseX = e.pageX || e.clientX + (document.documentElement.scrollLeft || document.body.scrollLeft);
      const mouseY = e.pageY || e.clientY + (document.documentElement.scrollTop || document.body.scrollTop);
      const distance = Math.sqrt(Math.pow(mouseX - buttonCenterX, 2) + Math.pow(mouseY - buttonCenterY, 2));
      if (distance <= 100) {
        hovering = true;
        hoveredButton = button;
      }
    });

    const containerElement = document.querySelector(".add-action-container");
    if (containerElement && hovering) {
      if (!hoveredButton) return;

      let source = "";
      let target = "";
      (hoveredButton as Element).classList?.forEach((cls) => {
        if (cls.includes("source-")) source = cls.replace("source-", "");
        if (cls.includes("target-")) target = cls.replace("target-", "");
      });

      if (source) {
        const connections = document.querySelectorAll(`.connection-svg.source-${source}.target-${target}`);
        connections.forEach((connection) => {
          if (target.includes("-cond") && !target.includes("-end")) connection.classList.add("path-highlight-error");
          else connection.classList.add("path-highlight");
          setHoveredActionSource(source);
          setHoveredActionTarget(target.includes("-end") ? null : target);
        });
      }
    }
  };

  const onDragEnd = (e: React.DragEvent, action: any) => {
    e.preventDefault();
    const containerElement = document.querySelector(".add-action-container");
    if (containerElement) {
      const containerRect = containerElement.getBoundingClientRect();
      const isOverFlow =
        e.clientX >= containerRect.left &&
        e.clientX <= containerRect.right &&
        e.clientY >= containerRect.top &&
        e.clientY <= containerRect.bottom;

      if (isOverFlow || hoveredActionTarget?.includes("-cond")) return message.error("Cannot add an action here");
      onActionDropAction && onActionDropAction(action.id, hoveredActionSource, hoveredActionTarget);
    }
  };

  // Toggle expanded panel for a sub-action
  const toggleExpand = (subActionId: string) => {
    setExpandedSubActionId((prev) => (prev === subActionId ? null : subActionId));
  };
  const [isExpanded, setIsExpanded] = useState(false);

  // custom bg color for each action group
  const actionGroupColors: {
    [key: string]: string;
  } = {
    All: "#E5E4E2",
    AI: "#F7D0F1",
    "Company Data": "#F5D0FF",
    "People Data": "#DDF0B4",
    Integrations: "#FFF1E9",
    CRM: "#C5FBFF",
    Outreach: "#D5C6FF",
    Scrapping: "#FFB8B3",
    "Floq Tools": "#FFF2B7",
  };

  const [hoveredGroup, setHoveredGroup] = useState<String | null>(null);

  const getActionGroups = () => {
    const order = [
      "All",
      "AI",
      "Company Data",
      "People Data",
      "Integrations",
      "CRM",
      "Outreach",
      "Scrapping",
      "Floq Tools",
    ];

    const allKeys = actionCategoryGroups ? Object.keys(actionCategoryGroups) : [];
    const sortedKeys = [
      ...order.filter((key) => allKeys.includes(key)),
      ...allKeys.filter((key) => !order.includes(key)),
    ];

    return sortedKeys.map((key, idx) => {
      const isActive = activeTab === key;
      const isHovered = hoveredGroup === key;
      // If active OR hovered (when not active), show the group-specific bg; otherwise, white.
      const bgColor = isActive || isHovered ? actionGroupColors[key] : "white";

      const buttonContent = (
        <button
          key={idx}
          onClick={() => handleTabChange(key)}
          onMouseEnter={() => setHoveredGroup(key)}
          onMouseLeave={() => setHoveredGroup(null)}
          style={{ backgroundColor: bgColor }}
          className="flex gap-3 items-center justify-start py-2 pr-4 w-full border-none rounded transition-colors duration-200 text-black"
        >
          <img
            src={
              actionCategoryGroups
                ? actionCategoryGroups[key]?.logo
                : "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/placeholder_image.png"
            }
            alt={key}
            className="h-6 w-6 ml-2"
          />
          {/* When sidebar is expanded, show the text; otherwise, hide it */}
          <span
            className={`ml-1 rounded-full bg-nightshade-950 text-md font-light truncate text-ellipsis ${
              isExpanded ? "inline-block" : "hidden"
            }`}
          >
            {key}
          </span>
        </button>
      );

      // When collapsed, wrap the button in a tooltip; when expanded, show button without tooltip.
      return !isExpanded ? (
        <AnimatedTooltipItem key={idx} text={key} position="right">
          {buttonContent}
        </AnimatedTooltipItem>
      ) : (
        buttonContent
      );
    });
  };

  const calculateDistanceFromCursor = (element: Element, cursorX: number, cursorY: number) => {
    const rect = element.getBoundingClientRect();

    // Get element center coordinates
    const elementCenterX = rect.left + rect.width / 2;
    const elementCenterY = rect.top + rect.height / 2;

    // Calculate distance using the Pythagorean theorem
    const distance = Math.sqrt(Math.pow(cursorX - elementCenterX, 2) + Math.pow(cursorY - elementCenterY, 2));

    return distance;
  };

  const findDragButton = (element: any): Element | null => {
    // Base case: if element has className "drag-button"
    if (element?.className === "drag-button") {
      return element;
    }

    // Recursively check children
    if (element?.childNodes) {
      for (const child of element.childNodes) {
        const result = findDragButton(child);
        if (result) {
          return result;
        }
      }
    }

    return null;
  };

  return (
    <div className="flex flex-col add-action-container !font-favorit h-[80vh]" style={{ maxHeight: "inherit" }}>
      {/* Search input */}
      <div className="px-4 py-5 border-b border-[#D4D4D4]">
        <header className="relative">
          <AntdInput
            size="small"
            placeholder="Search for an action or the data you are looking for"
            onChange={(e) => handleSearch(e.target.value)}
            ref={searchInputRef}
            value={searchTerm}
            className="w-full gap-1 text-[#333333] font-normal border rounded-[5px] !border-[#979797] focus:right-0"
            prefix={<img src={Search} alt="Search" className="h-8 w-4 py-2" />}
          />
        </header>
      </div>

      {/* Two-column layout */}
      <main className="flex mx-auto max-h-full w-full flex-1 overflow-auto bg-white">
        {/* Left Sidebar */}
        <nav
          className={`space-y-10 relative flex flex-col gap-1 ${isExpanded ? "w-50" : "w-16"} h-full px-3 py-4 transition-opacity duration-300 font-favoritBook`}
        >
          <div className="flex flex-col justify-between h-full pb-2">
            <div className="space-y-2">{getActionGroups()}</div>

            {/* Sidebar toggle icon at bottom right, if expanded, else bottom centered */}
            <div
              className={`cursor-pointer border-t-2 border-gray-200 pt-4 flex ${isExpanded ? "justify-end" : "justify-center"}`}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <img
                src={SidebarToggleIcon}
                alt="Toggle Sidebar"
                style={{
                  width: "20px",
                }}
              />
            </div>
          </div>
        </nav>

        {/* Right column: actions (vertical), scrollable */}
        <div
          className="flex flex-col gap-2 flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 
                   scrollbar-track-gray-100 border-l border-[#D4D4D4] p-5 py-6"
        >
          {searchTerm ? (
            <div className="flex items-center gap-2 p-2 font-favoritBook">
              {/* <img src={Search} alt="Search" className="h-4 w-4" /> */}
              <span className="text-xl">
                Results for <span className="font-semibold">'{searchTerm}'</span>
              </span>
            </div>
          ) : (
            <div className="flex items-center gap-2 pb-2">
              <span className="text-lg font-normal">{activeTab}</span>
            </div>
          )}
          {filteredActions
            .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
            .map((category, idx) => (
              <div key={idx} className="border border-[#939191] rounded-[6px] gap-3 bg-white shadow-sm relative">
                {/* Category header */}
                <div className="flex items-center gap-3 p-2 mx-2 mt-2 ">
                  {category.logo && (
                    <img
                      src={
                        category.logo ||
                        "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/placeholder_image.png"
                      }
                      className="h-8 w-8 rounded-full"
                    />
                  )}
                  <div className="flex flex-col">
                    <div className="flex items-center gap-1">
                      <h3
                        className="font-medium text-sm text-[#333333] tracking-[-0.02em]"
                        dangerouslySetInnerHTML={{
                          __html: highlightText(category.name, searchTerm),
                        }}
                      />
                      {/* Number of sub-actions (small text) */}
                      {!searchTerm && (
                        <span className="text-[10px] text-gray-800 font-light bg-gray-100 rounded-sm w-[16px] h-[16px] items-center flex justify-center">
                          <div className="p-[2px]">{category.actions.length}</div>
                        </span>
                      )}
                    </div>
                    <p
                      className="text-[10px] font-light text-[#858585] tracking-[-0.02em]"
                      dangerouslySetInnerHTML={{
                        __html: highlightText(category.description, searchTerm),
                      }}
                    />
                  </div>
                </div>

                {/* Sub-actions list */}
                <div className="rounded pb-1 px-1 mx-2 mb-3  space-y-[6px]">
                  {/* waterfall tag pnly for waterfall actions*/}
                  {category?.type === "waterfall" && (
                    <div className="flex items-center gap-2 py-2">
                      <span className="text-xs text-gray-700 bg-[#D0FFF1] rounded-[2px] px-2 py-1">WATERFALL</span>
                    </div>
                  )}

                  {category?.actions?.map((subAction: any, subIdx: any) => {
                    if (!subAction) return null;
                    const isExpanded = expandedSubActionId === subAction.id;

                    // Get matching responses (for tag view) if searchTerm is non-empty
                    // const matchingResponses =
                    //   searchTerm && subAction.responseStructure
                    //     ? subAction.responseStructure.filter((resp: any) =>
                    //         resp.name?.toLowerCase().includes(searchTerm.toLowerCase())
                    //       )
                    //     : [];

                    const matchingResponses = searchTerm
                      ? [...findMatches(subAction, searchTerm.toLowerCase())]
                          .filter((match) => {
                            return match?.includes("response_");
                          })
                          .map((match) => match?.replace("response_", ""))
                      : [];

                    const providers =
                      subAction.stepDownAPIs ||
                      subAction.payloadStructure.find((inp: any) => inp?.stepDownAPIs)?.stepDownAPIs ||
                      [];

                    return (
                      <ActionCard
                        key={subIdx}
                        subAction={subAction}
                        category={category}
                        providers={providers}
                        isExpanded={isExpanded}
                        searchTerm={searchTerm}
                        matchingResponses={matchingResponses}
                        handleActionSelect={handleActionSelect}
                        dragableActions={dragableActions}
                        findDragButton={findDragButton}
                        calculateDistanceFromCursor={calculateDistanceFromCursor}
                        onDragEnd={onDragEnd}
                        onDragHandler={onDragHandler}
                        toggleExpand={toggleExpand}
                        highlightText={highlightText}
                        doubleClickAction={doubleClickAction}
                      />
                    );
                  })}
                </div>
              </div>
            ))}
        </div>
      </main>
    </div>
  );
};

// Component to display matching responses as tags in a horizontal list with show more/less
type ResponseTagProps = {
  responses: any[];
  searchTerm: string;
  highlightText: (text: string, query: string) => string;
};

const ResponseTags: React.FC<ResponseTagProps> = ({ responses, searchTerm, highlightText }) => {
  const [showAll, setShowAll] = useState(false);
  const threshold = 3;
  const displayed = showAll ? responses : responses.slice(0, threshold);

  return (
    <div className="flex items-center gap-2 overflow-x-auto p-1">
      {displayed.map((resp, idx) => (
        <span
          key={idx}
          title={resp}
          className="bg-gray-200 text-[12px] px-2 rounded-sm whitespace-nowrap"
          dangerouslySetInnerHTML={{ __html: highlightText(resp, searchTerm) }}
        />
      ))}
      {responses.length > threshold && (
        <button
          onClick={() => setShowAll((prev) => !prev)}
          className="text-gray-900 text-[12px] underline whitespace-nowrap"
        >
          {showAll ? "Less" : "More"}
        </button>
      )}
    </div>
  );
};

function ActionCard({
  subAction,
  category,
  providers,
  isExpanded,
  searchTerm,
  matchingResponses,
  handleActionSelect,
  dragableActions,
  onDragEnd,
  onDragHandler,
  toggleExpand,
  highlightText,
  doubleClickAction,
}: any) {
  // 1) Determine card type
  const isWaterfall = category?.type === "waterfall";
  const isWaterfallDatapoint = category?.type === "waterfall-datapoint";
  const hasResponseTags = !isExpanded && searchTerm && matchingResponses?.length > 0;
  let cardType: "provider" | "waterfall" | "datapoint" | "waterfall-datapoint" = "provider";
  if (isWaterfall) {
    cardType = "waterfall";
  } else if (hasResponseTags) {
    cardType = "datapoint";
  } else if (isWaterfallDatapoint) {
    cardType = "waterfall-datapoint";
  }

  // 2) Shared drag props
  const dragProps = {
    draggable: dragableActions,
    // onDragStart: (e: any) => {
    //   let cursorDistance = 1000;
    //   const dragButton = findDragButton(e.target);
    //   if (dragButton) {
    //     cursorDistance = calculateDistanceFromCursor(dragButton, e.clientX, e.clientY);
    //   }
    //   if (!dragableActions || cursorDistance > 10) {
    //     e.preventDefault();
    //   }
    // },
    onDragEnd: (e: any) => onDragEnd(e, subAction),
    onDrag: onDragHandler,
  };

  // 3) Subaction header row (always one horizontal line)
  const headerRow = (
    <div
      className="flex items-center justify-between w-full"
      // onClick={(e) => {
      //   e.stopPropagation();
      //   toggleExpand(subAction.id);
      // }}
      onDoubleClickCapture={() => {
        doubleClickAction(subAction.id);
      }}
    >
      <div className={`flex items-center ${isExpanded ? "pl-[4px]" : "pl-[8px]"} w-[80%]`}>
        {category?.type === "datapoint" && (
          <img
            src={
              subAction.logo ||
              "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/placeholder_image.png"
            }
            className="h-4 w-4 rounded-full m-1"
          />
        )}
        <span
          className={`text-[14px] whitespace-nowrap overflow-hidden text-ellipsis ${category?.type === "datapoint" ? "px-2" : "m-1"} font-normal ${isExpanded ? "font-semibold" : ""} `}
          dangerouslySetInnerHTML={{
            __html: highlightText(subAction?.label || subAction?.name, searchTerm),
          }}
          title={subAction?.label || subAction?.name}
        />
        {cardType === "waterfall-datapoint" && isExpanded && (
          <div className="">
            <span className="text-xs text-gray-700 bg-[#D0FFF1] rounded-[2px] px-2 py-1">WATERFALL</span>
          </div>
        )}
      </div>
      <div className="flex items-center gap-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200 ">
        {isExpanded ? (
          <AiOutlineCompress
            className="cursor-pointer w-4 h-4"
            onClick={(e) => {
              e.stopPropagation();
              toggleExpand(subAction.id);
            }}
          />
        ) : (
          <RiExpandDiagonalLine
            className="cursor-pointer w-4 h-4"
            onClick={(e) => {
              e.stopPropagation();
              toggleExpand(subAction.id);
            }}
          />
        )}
        <div className="drag-button">
          <RiDragMove2Fill className="cursor-move w-4 h-4" />
        </div>
      </div>
    </div>
  );

  // 4) Subaction row container
  const subActionRow = (
    <div
      className={`action-item ${
        isExpanded
          ? "bg-white border border-b-0 border-[#D0D0D0] rounded-b-none flex flex-col"
          : "bg-[#F2F2F2] border-[#D0D0D0] flex items-center hover:bg-white hover:ring-gray-800 hover:ring-[0.5px]"
      } p-1 w-full z-10 text-xs cursor-default`}
      style={{ cursor: "default" }}
      onClick={() => handleActionSelect(subAction.id)}
      {...dragProps}
    >
      {headerRow}
      {isExpanded && (
        <p
          className="text-[10px] text-gray-500 font-light mt-1 ml-[8px]"
          dangerouslySetInnerHTML={{
            __html: highlightText(subAction?.description || category?.description, searchTerm),
          }}
        />
      )}
    </div>
  );

  // 5) Bottom section: providers (for waterfall) or response tags (for datapoint)
  let bottomSection = null;
  if ((cardType === "waterfall" || cardType === "waterfall-datapoint") && !isExpanded && providers?.length > 0) {
    bottomSection = (
      <div className="flex gap-[2px] items-center bg-gray-100 border-t px-4 py-1">
        {/* {providers.map((api: any) => (
          <img
            key={api.apiName}
            src={
              api.logo ||
              "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/placeholder_image.png"
            }
            title={api.apiName}
            className="h-4 w-4 rounded-full"
          />
        ))} */}
        <AnimatedTooltip items={providers} />
      </div>
    );
  } else if (cardType === "datapoint") {
    bottomSection = (
      <div className="flex gap-1 items-center bg-gray-100 border-t px-2 py-1">
        <ResponseTags responses={matchingResponses} searchTerm={searchTerm} highlightText={highlightText} />
      </div>
    );
  }

  // 6) Expanded panel (shared by all card types)
  const expandedPanel = isExpanded && (
    <SubActionDetails subAction={subAction} searchTerm={searchTerm} highlightText={highlightText} />
  );

  // 7) Render final layout with divider if needed
  // const shouldShowDivider = cardType === "waterfall" || cardType === "datapoint";
  return (
    <div key={subAction.id} className="relative my-1 ">
      <div className="group flex flex-col bg-[#F2F2F2] rounded-sm ">
        {subActionRow}
        {/* {shouldShowDivider && <div className="border-t border-[#D0D0D0]"></div>} */}
        {bottomSection}
        {expandedPanel}
      </div>
    </div>
  );
}

// Component to display the expanded details (inputs and responses, cost, etc.)
function SubActionDetails({
  subAction,
}: {
  subAction: any;
  searchTerm: string;
  highlightText: (text: string, query: string) => string;
}) {
  const [showAllInputs, setShowAllInputs] = useState(false);
  const [showAllProviders, setShowAllProviders] = useState(false);

  const inputs = subAction.payloadStructure || [];
  const responses = subAction.responseStructure || [];

  // stepDownAPIs can be defined at the action level or at the payloads level
  const providers = subAction.stepDownAPIs || inputs.find((inp: any) => inp?.stepDownAPIs)?.stepDownAPIs || [];

  const cost = typeof subAction.credits === "number" ? subAction.credits : 1;

  const displayedInputs = showAllInputs ? inputs : inputs.slice(0, 3);
  const displayedResponses = responses;
  const displayedProviders = showAllProviders ? providers : providers.slice(0, 3);

  return (
    <div className="p-2 border border-t-0 border-[#D0D0D0] rounded rounded-t-none text-xs bg-white">
      {displayedProviders && displayedProviders.length > 1 && (
        <>
          <hr className="border-[#D0D0D0] py-[8px]" />
          <h4 className="font-light text-[10px] mb-2 bg-gray-100 w-fit px-1">PROVIDERS:</h4>
          <div className="space-y-1 mb-4">
            {displayedProviders.map((api: any) => (
              <div key={api.apiId} className="flex flex-col gap-1 pl-[10px] pr-[6px] py-2 bg-gray-100 rounded">
                <div className="flex justify-between">
                  <div className="flex items-center gap-1">
                    <img
                      src={
                        api.logo ||
                        "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/placeholder_image.png"
                      }
                      alt={api.apiName}
                      className="h-4 w-4"
                    />
                    <span
                      className="text-[10px] font-semibold truncate max-w-[100px] overflow-hidden text-ellipsis"
                      title={api.apiName}
                    >
                      {api.apiName}
                    </span>
                  </div>
                  <div className="flex items-center gap-1 mr-2">
                    <img src={CreditsIcon} alt="Credits" className="h-4 w-4" />
                    <span className="text-gray-600 text-[10px]">{subAction.credits[api.apiId]}</span>
                  </div>
                </div>
              </div>
            ))}
            {displayedProviders.length > 2 && (
              <button
                onClick={() => setShowAllProviders((prev) => !prev)}
                className="text-black text-[10px] font-light mt-1 underline"
              >
                {showAllProviders ? "Show less" : "Show all"}
              </button>
            )}
          </div>
          <hr className="border-[#D0D0D0] py-[8px]" />
        </>
      )}

      {/* Inputs */}
      <h4 className="font-light text-[10px] mb-2 bg-gray-100 w-fit px-1">INPUTS WITH EXAMPLES:</h4>
      <div className="space-y-1 mb-4">
        {displayedInputs.length > 0 ? (
          displayedInputs.map((inp: any, i: number) => (
            <div key={i} className="flex flex-col gap-1 p-2 bg-gray-50 rounded">
              <div className="flex justify-between">
                <span
                  className="text-[10px] font-semibold truncate max-w-[200px] overflow-hidden text-ellipsis"
                  title={inp.name}
                >
                  {inp.name}:
                </span>
                {inp.required ? (
                  <span className="text-gray-500 ml-2 text-[10px] bg-yellow-100">REQUIRED</span>
                ) : (
                  <span className="text-gray-500 ml-2 text-[10px]">OPTIONAL</span>
                )}
              </div>
              {inp.placeholder && (
                <div className="text-gray-600 text-[10px] truncate" title={inp.placeholder}>
                  placeholder: {inp.placeholder}
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="text-gray-500 italic">No inputs required.</div>
        )}

        {inputs.length > 3 && (
          <button
            onClick={() => setShowAllInputs((prev) => !prev)}
            className="text-black text-[10px] font-light mt-1 underline"
          >
            {showAllInputs ? "Show less" : "Show all"}
          </button>
        )}
      </div>

      <hr className="border-[#D0D0D0] py-[8px]" />

      {/* Responses */}
      <h3 className="font-light text-[10px] mb-2 bg-gray-100 w-fit px-1">RESPONSE STRUCTURE:</h3>
      <h4 className="font-light text-[10px] mb-2 text-gray-500 pb-[6px]">
        This is the structure of the response returned by the action
      </h4>
      <div className="space-y-1 mb-4 text-xs font-light">
        {subAction.name === "HTTP API Call" ? (
          <div className="text-gray-500 italic bg-gray-100 p-1 flex items-center justify-center flex-col">
            <p>Response structure will vary based on the API.</p>
            <p>You can make a test call to verify the response structure.</p>
          </div>
        ) : displayedResponses.length > 0 ? (
          <ResponseStructureViewer responses={displayedResponses} action={subAction} />
        ) : (
          <div className="text-gray-500 italic bg-gray-100 p-1 flex items-center justify-center flex-col">
            <p>No response structure available.</p>
          </div>
        )}
      </div>
      <hr className="border-[#D0D0D0] pb-1" />

      {/* Cost */}
      <div className="py-2 space-x-[6px] flex items-center ">
        <span className="text-[10px] font-light text-gray-600 bg-gray-100 w-fit px-1">CREDIT USAGE PER RUN:</span>
        <img src={CreditsIcon} alt="Credits" className="h-4 w-4" />
        <span className="text-black">{cost}</span>
      </div>
    </div>
  );
}

export default AddActionView;

import { App, message } from "antd";
import { isArray } from "lodash";
import { useEffect, useState } from "react";
import { FaRegCopy } from "react-icons/fa";
import { fetchTransactionsForWorkflowActionDataPart } from "@/utils/apis";
import CreditHistoryViewer from "./CreditHistorViewer";

type Props = {
  open: boolean;
  data: any;
  credits: number | { [key: string]: number };
};

interface EmployeeField {
  description: string;
  name: string;
  value: string;
}

const initValues = [
  "First Name",
  "Last Name",
  "Person Title",
  "Person LinkedIn Profile Id",
  "Person LinkedIn Profile Type",
  "Open To Work",
];

const AibeesEmployeeViewer = ({ open, data: dataProps }: Props) => {
  const { data, action, workflowDataActionPartId } = dataProps;

  const [_, setNewData] = useState<any>(data);
  const [activeIndices, setActiveIndices] = useState<number[]>([]);
  const [employeeList, setEmployeeList] = useState<EmployeeField[][]>([]);
  const [transactionHistory, setTransactionHistory] = useState<any[]>([]);

  useEffect(() => {
    if (!open) setNewData({});
    else setNewData(data);

    getTransactionHistory(workflowDataActionPartId, action);

    const listStructure = action?.responseStructure?.[1]?.sectionVariablesStructure || [];
    const dataKey =
      data && Object.keys(data).length ? Object.keys(data).find((key) => typeof data[key] === "object") : null;
    let employeesData = data?.[dataKey || "List of Employees"] || {};
    if(typeof employeesData === "string" && employeesData === "No data found") employeesData = {};

    const employeeKeys = Object.keys(employeesData);

    const employeeList = employeeKeys.map((key) => {
      const employee = employeesData[key];
      return listStructure.map((struct: any) => {
        const employeeValue = employee.find((obj: any) => obj.responseStructureId === struct.responseStructureId);
        return {
          name: struct?.name,
          description: struct?.description,
          value: employeeValue?.value || "No data found",
        };
      });
    });

    // Sort employeeList by initValues compared to name
    const sortedEmployeeList = employeeList.map((employee) => {
      return employee.sort((a: any, b: any) => {
        const indexA = initValues.indexOf(a.name);
        const indexB = initValues.indexOf(b.name);
        if (indexA === -1 && indexB === -1) return 0;
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;
        return indexA - indexB;
      });
    });

    setEmployeeList(sortedEmployeeList);

    setEmployeeList(employeeList);
  }, [open, data]);

  const getTransactionHistory = async (workflowDataActionPartId: string, action: any) => {
    const dataId = workflowDataActionPartId;
    const workflowId = action?.workflowId;
    if (!workflowId || !dataId) return;

    const response = await fetchTransactionsForWorkflowActionDataPart(workflowId, dataId);
    setTransactionHistory(response.transactions || []);
  };

  const toggleAccordion = (index: number) => {
    setActiveIndices((prevIndices) =>
      prevIndices.includes(index) ? prevIndices.filter((i) => i !== index) : [...prevIndices, index]
    );
  };
  const handleCopyJSON = async (content: any, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      const jsonString = JSON.stringify(content, null, 2); // Convert JSON to string with indentation
      await navigator.clipboard.writeText(jsonString);
      message.success("Content copied to clipboard!");
      // Check the data that got copied
      await navigator.clipboard.readText();
    } catch (err) {
      message.error("Failed to copy content.");
      console.error("Copy error:", err);
    }
  };
  const handleCopyText = async (content: any, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(content);
      message.success("Content copied to clipboard!");
      // Check the data that got copied
      await navigator.clipboard.readText();
    } catch (err) {
      message.error("Failed to copy content.");
      console.error("Copy error:", err);
    }
  };

  const capitalizeFirstLetter = (val: string) => {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  };

  const valueBox = (value: string, fieldName?: string) => {
    const specialRendereds = Object.keys(specialRenders);
    if (fieldName && specialRendereds.includes(fieldName)) {
      // @ts-ignore
      return specialRenders[fieldName](value, valueBox);
    }
    const name = fieldName?.replace(/_/g, " ");
    return (
      <div className="space-y-2 mt-2 border p-2 rounded-sm">
        <div className="flex flex-col w-full group relative">
          <span className="w-full text-[#575757]">{capitalizeFirstLetter(name ?? "")}</span>
          <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center overflow-x-hidden">
            {typeof value === "string" &&
            (value.startsWith("http://") || value.startsWith("https://") || value.startsWith("www.")) ? (
              <a
                href={value.startsWith("http") ? value : `https://${value}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline underline-offset-1 overflow-x-hidden"
              >
                {value}
              </a>
            ) : (
              value
            )}
            <p className="cursor-pointer hidden group-hover:block " onClick={(e) => handleCopyText(value, e)}>
              <FaRegCopy className="text-gray-600" />
            </p>
          </div>
        </div>
      </div>
    );
  };

  const valueBoxBoderless = (value: string, fieldName?: string) => {
    const specialRendereds = Object.keys(specialRenders);
    if (fieldName && specialRendereds.includes(fieldName)) {
      // @ts-ignore
      return specialRenders[fieldName](value, valueBoxBoderless);
    }
    const name = fieldName?.replace(/_/g, " ");
    return (
      <div className="space-y-2 mt-2 rounded-sm">
        <div className="flex flex-col w-full group relative">
          <span className="w-full text-[#575757]">{capitalizeFirstLetter(name ?? "")}</span>
          <div className="w-full bg-[#FFF7DF] px-2 py-1 break-words flex justify-between items-center overflow-x-hidden text-nowrap">
            {typeof value === "string" &&
            (value.startsWith("http://") || value.startsWith("https://") || value.startsWith("www.")) ? (
              <a
                href={value.startsWith("http") ? value : `https://${value}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline underline-offset-1 overflow-x-hidden"
              >
                {value}
              </a>
            ) : (
              value
            )}
            <p className="cursor-pointer hidden group-hover:block " onClick={(e) => handleCopyText(value, e)}>
              <FaRegCopy className="text-gray-600" />
            </p>
          </div>
        </div>
      </div>
    );
  };

  const structuredViews = {
    Educations: {
      primaryKey: "school",
      header: "school.name",
      exclude: ["id"],
    },

    "Person Position Groups": {
      primaryKey: "company",
      header: "company.name",
      exclude: ["id"],
    },
    Certifications: {
      primaryKey: "company",
      header: "company.name",
      exclude: ["id"],
    },
  };

  const specialRenders = {
    date: (jsonStr: any, rederFunction: any) => {
      const json = JSON.parse(jsonStr);
      const startDate = json?.start ? `${json.start.year}-${json.start.month}-${json.start.day}` : "N/A";
      const endDate = json?.end ? `${json.end.year}-${json.end.month}-${json.end.day}` : "N/A";
      const formattedDates = [
        { key: "start", value: startDate },
        { key: "end", value: endDate },
      ];

      return (
        <div className="flex flex-col gap-2">
          {formattedDates.map((date, idx) => (
            <div key={idx}>{rederFunction(date.value, date.key)}</div>
          ))}
        </div>
      );
    },
    profile_positions: (jsonStr: any, rederFunction: any) => {
      const json = JSON.parse(jsonStr);
      if (isArray(json)) {
        return json.map((position, idx) => {
          return <div key={idx}>{rederFunction(position?.title, "position")}</div>;
        });
      }
    },
  };

  const nestedFields = (json: any) => {
    if (isArray(json)) {
      const fields = json.map((child, idx) => {
        return <div key={idx}>{nestedFields(child)}</div>;
      });

      return fields?.length ? fields : valueBox("No data found");
    }

    if (typeof json === "object") {
      const keys = Object.keys(json);
      return keys.map((key, idx) => {
        const value = json[key];
        return <div key={idx}>{valueBox(JSON.stringify(value), key)}</div>;
      });
    }

    return valueBox(json);
  };

  const getNestedValue = (obj: any, path: string) => {
    if (!path || !obj) return null;
    const keys = path.split(".");
    return keys.reduce((o, key) => (o && o[key] !== undefined ? o[key] : null), obj);
  };

  const nesterBoderlessObjectFields = (json: any, metaData: any) => {
    const keys = Object.keys(json ?? []);
    return (
      <>
        {keys.map((key, idx) => {
          if (metaData?.exclude?.includes(key)) return null;

          return (
            <div key={idx}>
              {valueBoxBoderless(typeof json?.[key] === "object" ? JSON.stringify(json?.[key]) : json?.[key], key)}
            </div>
          );
        })}
        <div className="mt-2 mx-auto w-full h-[2px] bg-gray-200 scale-110" />
      </>
    );
  };

  const nestedBoderlessFields = (json: any, metaData: any) => {
    if (isArray(json)) {
      const fields = json.map((child, idx) => {
        return <div key={idx}>{nestedFields(child)}</div>;
      });

      return fields?.length ? fields : valueBoxBoderless("No data found");
    }

    if (typeof json === "object") {
      const keys = Object.keys(json);
      return keys.map((key, idx) => {
        if (metaData?.primaryKey === key) {
          return nesterBoderlessObjectFields(json[key], metaData);
        }
        const value = json[key];
        return <div key={idx}>{valueBoxBoderless(typeof value === "object" ? JSON.stringify(value) : value, key)}</div>;
      });
    }

    return valueBoxBoderless(json);
  };

  const renderStructuredView = (json: any, key: string, idx: number) => {
    if (isArray(json)) {
      return json.map((child, idx) => {
        return <div key={idx}>{renderStructuredView(child, key, idx)}</div>;
      });
    }
    const primaryIndex = 3001;
    return (
      <div className="p-2">
        <div
          key={idx}
          className={`${
            activeIndices.includes(primaryIndex + idx + 1) ? "" : " bg-[#FAFAFA] border border-gray-200 p-2 rounded-sm"
          }  cursor-pointer`}
        >
          <div className="flex items-center justify-between" onClick={() => toggleAccordion(primaryIndex + idx + 1)}>
            <p className="text-sm font-bold flex items-center gap-x-2">
              {/* @ts-ignore */}
              {getNestedValue(json, structuredViews[key].header) ?? "N/A"}
            </p>
            <p
              className="cursor-pointer flex items-center gap-x-2"
              // onClick={(e) => handleCopyJSON(field?.value, e)}
            >
              {activeIndices.includes(primaryIndex + idx + 1) ? (
                <UpArrowNew color="black" />
              ) : (
                <DownArrowNew color="black" />
              )}
            </p>
          </div>
        </div>
        {activeIndices.includes(primaryIndex + idx + 1) && (
          <div>
            {/* @ts-ignore */}
            {nestedBoderlessFields(json, structuredViews[key])}
          </div>
        )}
      </div>
    );
  };

  const renderEmployeeList = (employeeList: EmployeeField[][]) => {
    return employeeList.map((employeeFields, idx) => {
      const primaryIndex = 1001;
      return (
        <div key={idx}>
          <div
            key={idx}
            className={`${
              activeIndices.includes(primaryIndex + idx + 1)
                ? ""
                : " bg-[#FAFAFA] border border-gray-200 p-2 rounded-sm"
            }  cursor-pointer mt-2 mb-2`}
          >
            <div className="flex items-center justify-between" onClick={() => toggleAccordion(primaryIndex + idx + 1)}>
              <p className="text-[16px] font-bold flex items-center gap-x-2">
                Employee {idx + 1 < 10 ? "0" + (idx + 1) : idx + 1}
                {activeIndices.includes(primaryIndex + idx + 1) ? (
                  <UpArrowNew color="black" />
                ) : (
                  <DownArrowNew color="black" />
                )}
              </p>
              <p
                className="cursor-pointer flex items-center gap-x-2"
                onClick={(e) => handleCopyJSON(employeeFields, e)}
              >
                Copy All <FaRegCopy className="text-gray-600" />
              </p>
            </div>
          </div>
          <div>
            {activeIndices.includes(primaryIndex + idx + 1) &&
              employeeFields.map((field, idx) => {
                const primaryIndex = 2001;
                if (typeof field?.value === "object") {
                  const structuredViewKeys = Object.keys(structuredViews);
                  const showStructuredView = structuredViewKeys.includes(field?.name);
                  return (
                    <div>
                      <div
                        key={idx}
                        className={`${
                          activeIndices.includes(primaryIndex + idx + 1)
                            ? ""
                            : " bg-[#FAFAFA] border border-gray-200 p-2 rounded-sm"
                        }  cursor-pointer mt-2 mb-2`}
                      >
                        <div
                          className="flex items-center justify-between"
                          onClick={() => toggleAccordion(primaryIndex + idx + 1)}
                        >
                          <p className="text-sm font-bold flex items-center gap-x-2">
                            {field?.name}
                            {activeIndices.includes(primaryIndex + idx + 1) ? (
                              <UpArrowNew color="black" />
                            ) : (
                              <DownArrowNew color="black" />
                            )}
                          </p>
                          <p
                            className="cursor-pointer flex items-center gap-x-2"
                            onClick={(e) => handleCopyJSON(field?.value, e)}
                          >
                            Copy All <FaRegCopy className="text-gray-600" />
                          </p>
                        </div>
                      </div>
                      {activeIndices.includes(primaryIndex + idx + 1) && (
                        <div>
                          {showStructuredView
                            ? renderStructuredView(field.value, field.name, idx)
                            : nestedFields(field?.value)}
                          <div className="mt-2 mx-auto w-full h-[2px] bg-gray-200 scale-110" />
                        </div>
                      )}
                    </div>
                  );
                }
                //{field?.name} //field?.value
                return <div key={idx}>{valueBox(field?.value, field?.name)}</div>;
              })}
          </div>

          <hr className="" />
        </div>
      );
    });
  };

  const employeeCountKey =
    data && Object.keys(data).length ? Object.keys(data).find((key) => typeof data[key] === "number") : 0;
  const employeeCount = employeeCountKey ? data[employeeCountKey] : 0;

  return (
    <>
      <App>
        <div className="flex flex-col gap-2 !text-[14px]">
          <div className="flex w-full justify-between">
            <div className="text-[20px] font-bold">Retrieved list of employees</div>
            <div className="text-[20px] font-bold">Count: {employeeCount}</div>
          </div>
          <hr />

          <div className="space-y-2">{renderEmployeeList(employeeList)}</div>
        </div>
      </App>
      <div className="mt-4 flex items-center">
        <span className="font-bold flex items-center gap-x-2">
          <CreditHistoryViewer transactionHistory={transactionHistory} />
        </span>
      </div>
    </>
  );
};

export const DownArrowNew = ({ color }: { color?: string }) => (
  <svg width="14" height="9" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0.296875V5.16511L9 12.999L18 5.16511V0.296875L9 8.11216L0 0.296875Z" fill={color} />
  </svg>
);
export const UpArrowNew = ({ color }: { color?: string }) => (
  <svg width="14" height="9" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.999 12.6953V7.82708L8.99902 -0.00685804L-0.000980854 7.82708V12.6953L8.99902 4.88003L17.999 12.6953Z"
      fill={color}
    />
  </svg>
);

export default AibeesEmployeeViewer;

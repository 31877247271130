import {
  EMAIL_VALIDATORS_STRUCTURE_ID,
  NON_AI_ACTIONS_WITH_API_PRICIING_IDS,
  setStateType,
} from "../../../utils/constants";
import { useCallback, useEffect, useState } from "react";
import { useWorkflow } from "../../../contexts/WorkflowContext";
import TiptapField from "../Tiptap/TiptapField";
import { Action, PayloadConfiguration } from "../../../utils/interfaces";
import { Alert, ConfigProvider, Drawer, InputNumber, Select, Skeleton, Slider, Switch } from "antd";
import StepDownSearch from "./StepDownSearch";
import { FaCoins, FaPlus, FaRegEdit } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import ResponseStructureViewer from "./ModalComponents/ResponseStructure";
import RunCondition from "./ModalComponents/RunCondition";
import Coins from "@/assets/SVGs/buildTab/Coins";
import { RiExpandUpDownLine } from "react-icons/ri";
import { useConnections } from "@/contexts/ConnectionContext";
import { useRunOnFloq } from "@/contexts/RunOnFloqContext";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

type Props = {
  action: Action;
  modal: boolean;
  setModal: setStateType<boolean>;
  payloadConfiguration: PayloadConfiguration[];
  responseConfiguration: any[];
  variables: any[];
};

const WaterfallModal = ({ action, modal, setModal, payloadConfiguration, responseConfiguration, variables }: Props) => {
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  /**
   * @todo: also show the connection name in the error message
   */
  const [isConnectionExists, setIsConnectionExists] = useState(true);
  const [updateField, setUpdateField] = useState(0);
  const [review, setReview] = useState(action.reviewNeeded || false);
  const { collapseConfig, setCollapseConfig } = useRunOnFloq();
  const [drawerWidth, setDrawerWidth] = useState(collapseConfig ? "3rem" : "30%");
  const [payloads, setPayloads] = useState<PayloadConfiguration[]>(payloadConfiguration);
  const [responses, setResponses] = useState(responseConfiguration);
  const [runCondition, setRunCondition] = useState(action.runCondition);
  const {
    updatePayloadConfig,
    updateResponseConfig,
    saveWorkflowActions,
    updateActionName,
    setPublishWarning,
    fetchAllAPIPricing,
  } = useWorkflow();
  const { getConnectionFromType } = useConnections();
  const [providersWithCredits, setProvidersWithCredits] = useState<{ loading: boolean; providers: any[] }>({
    loading: true,
    providers: [],
  });
  const [changeName, setChangeName] = useState(false);
  const stepDownAPIs = action.stepDownAPIs || [];
  const { id } = useParams();

  useEffect(() => {
    setWidthPercent();
    window.addEventListener("resize", setWidthPercent);
    return () => window.removeEventListener("resize", setWidthPercent);
  }, [collapseConfig]);

  const setWidthPercent = useCallback(() => {
    try {
      if (collapseConfig) setDrawerWidth("3rem");
      // Adjust width for different screen sizes
      else if (window.matchMedia("(min-width: 1024px) and (min-height: 1366px)").matches)
        // iPad Pro and similar devices
        setDrawerWidth("50%");
      else if (window.matchMedia("(min-width: 1024px)").matches) setDrawerWidth("30%");
      else if (window.matchMedia("(min-width: 640px)").matches) setDrawerWidth("50%");
      else setDrawerWidth("100%");
    } catch (err) {
      console.error("Error calculating width percent based on media size");
    }
  }, [collapseConfig]);

  useEffect(() => {
    if (!action?.connectionId) return;
    getConnectionFromType(action?.connectionId).then((connection: any) => {
      setIsConnectionExists(connection?.userConnection ? true : false);
    });
  }, []);

  /**
   * @todo: try to show user's openai api key, if the user has selected the "use personal api key" option
   */
  useEffect(() => {
    if (NON_AI_ACTIONS_WITH_API_PRICIING_IDS.includes(action?.actionName)) {
      const providersPayload = action.payloadStructure.find(
        (input) => input.payloadStructureId === EMAIL_VALIDATORS_STRUCTURE_ID
      )?.values;
      setProvidersWithCredits({ loading: true, providers: [] });
      fetchAllAPIPricing().then((apiPricing: any) => {
        if (!providersPayload) return;
        const providers = providersPayload.map((provider: any) => {
          const pricing = apiPricing.api_pricing_pans.find((api: any) => api.id === provider.apiPricingId);
          return {
            id: provider.id || provider.name,
            name: provider.name,
            logo: provider.logo,
            credits: pricing?.credits,
          };
        });
        setProvidersWithCredits({ loading: false, providers });
      });
    }
  }, []);

  useEffect(() => {
    setPayloads(payloadConfiguration);
    setUpdateField((prev) => prev + 1);
  }, [payloadConfiguration]);

  useEffect(() => {
    setRunCondition(action.runCondition);
  }, [action]);

  useEffect(() => {
    setResponses(responseConfiguration);
  }, [responseConfiguration]);

  useEffect(() => {
    if (payloads !== payloadConfiguration || review !== action.reviewNeeded || runCondition !== action.runCondition) {
      setChanged(true);
    } else setChanged(false);
  }, [payloads, review, runCondition]);

  const saveOutputStructure = async () => {
    setLoading(true);
    const updated = updatePayloadConfig(payloads, action.id, review || false, action.continueOnFail, runCondition);
    setPayloads(updated);
    const updatedResponses = updateResponseConfig(responses, action.id);
    setResponses(updatedResponses);
    await saveWorkflowActions(id || "");
    setChanged(false);
    setLoading(false);
    setPublishWarning(true);
  };

  const updateConnectionToStepDown = (structureId: string, apiId: string, value: boolean) => {
    const payl = payloads;
    payl.forEach((item) => {
      if (item.payloadStructureId === structureId) {
        let temp = item.stepDownSearchConfig;
        if (!temp) return;
        temp = temp.map((item) => {
          if (item.apiId === apiId) {
            item.connection = value;
          }
          return item;
        });
      }
    });
    setChanged(true);
    setPayloads(payl);
  };

  const updateStepDownOrder = (structureId: string, apiId: string, dropApiId: string, below: boolean) => {
    const payl = payloads;

    payl.forEach((item) => {
      if (item.payloadStructureId === structureId) {
        let temp = item.stepDownSearchConfig;
        if (!temp) return;
        const dropOrder = temp.find((item) => item.apiId === dropApiId)?.order;

        if (!dropOrder) return;
        const newOrder = below ? dropOrder + 1 : dropOrder;
        temp = temp.map((item) => {
          if (item.apiId === apiId) {
            item.order = newOrder;
          } else if (item.order >= newOrder) {
            item.order += 1;
          }
          return item;
        });
      }
    });
    setChanged(true);
    setPayloads(payl);
  };

  const updateStepDownConfig = (structureId: string, removedApiId: string | null, addedApiId: string | null) => {
    const payl = payloads;
    const payloadIndex = payloads.findIndex((input) => input.payloadStructureId === structureId) || 0;
    let temp = payl[payloadIndex].stepDownSearchConfig;
    if (!temp) return;
    if (removedApiId) {
      const removedOrder = temp.find((item) => item.apiId === removedApiId)?.order;
      if (!removedOrder) return;
      temp = temp.filter((item) => item.apiId !== removedApiId);
    }
    // If a node was added, place it at the end of the order
    if (addedApiId) {
      if (temp.length === 0) {
        temp.push({ apiId: addedApiId, order: 1 });
      } else {
        const maxOrder = Math.max(...temp.map((item) => item.order));
        temp.push({ apiId: addedApiId, order: maxOrder + 1 });
      }
    }

    payl[payloadIndex].stepDownSearchConfig = temp;
    setChanged(true);
    const updated = updatePayloadConfig(payl, action.id, review || false, action.continueOnFail);
    setPayloads(updated);
  };

  const resetStepDownConfig = (structureId: string, config: any[]) => {
    const payl = payloads;
    const payloadIndex = payloads.findIndex((input) => input.payloadStructureId === structureId) || 0;
    payl[payloadIndex].stepDownSearchConfig = config;
    setChanged(true);
    const updated = updatePayloadConfig(payl, action.id, review || false, action.continueOnFail);
    setPayloads(updated);
  };

  const fieldSwitchCase = (field: any) => {
    switch (field.type) {
      // pass the credits here
      case "stepDownSearch":
        return (
          <StepDownSearch
            structureId={field.payloadStructureId}
            updateStepDownOrder={updateStepDownOrder}
            updateConnectionToStepDown={updateConnectionToStepDown}
            updateStepDownConfig={updateStepDownConfig}
            resetStepDownConfig={resetStepDownConfig}
            stepDownAPIs={stepDownAPIs}
            payloadConfig={
              payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.stepDownSearchConfig ||
              []
            }
            action={action}
            credits={typeof action.credits === "object" ? action.credits : undefined}
            setIsConnectionExists={setIsConnectionExists}
          />
        );
      case "dropdownWithCredits":
        return (
          <Skeleton active loading={providersWithCredits.loading} paragraph={{ rows: 1 }} title={{ width: "100%" }}>
            <Select
              suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
              value={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString ||
                field.values.find((value: any) => value?.default)?.id ||
                field.values.find((value: any) => value?.default)?.name ||
                ""
              }
              showSearch
              // allowClear
              optionFilterProp="name"
              onChange={(value) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field?.payloadStructureId);
                if (index === -1) {
                  temp.push({
                    payloadStructureId: field.payloadStructureId,
                    inputString: value,
                  });
                } else temp[index].inputString = value;
                setPayloads(temp);
              }}
              // display providersWithCredits.providers here
              options={(providersWithCredits.providers ?? []).map((value: any) => ({
                label: (
                  <div className="flex justify-between items-center gap-2 p-1 w-full">
                    <div className="flex items-center gap-2 justify-end text-ellipsis">
                      {value?.logo && <img src={value?.logo} alt={value?.name} className="w-6 h-6" />}
                      <p>{value?.name}</p>
                    </div>
                    {value?.credits >= 0 ? (
                      <div className="flex items-center gap-1 justify-end">
                        <span className="text-xs">
                          <div className="flex items-center justify-center gap-1">
                            <Coins width="18" height="22" />
                            <span className="font-bold text-md text-black">{value?.credits}</span>
                          </div>
                        </span>
                      </div>
                    ) : null}
                  </div>
                ),
                value: value?.id || value.name,
                name: value?.name,
              }))}
            />
          </Skeleton>
        );
      case "dropdown":
        return (
          <Select
            suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || []}
            showSearch
            optionFilterProp="name"
            mode="multiple"
            onChange={(value) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field?.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: value,
                });
              } else temp[index].inputString = value;
              setPayloads(temp);
            }}
            options={field.values.map((value: any) => ({
              label: (
                <div className="flex justify-between items-center gap-2 p-1 w-full">
                  <div className="flex items-center gap-2 justify-end text-ellipsis">
                    {value?.logo && <img src={value?.logo} alt={value?.name} className="w-6 h-6" />}
                    <p>{value?.name}</p>
                  </div>
                </div>
              ),
              value: value?.id || value.name,
              name: value?.name,
            }))}
          />
        );
      case "jsonArray":
        return (
          <div className="flex flex-col gap-4">
            {payloads
              .find((input) => input.payloadStructureId === field.payloadStructureId)
              ?.inputString.map((input: any, idx: number) => {
                return (
                  <div className="" key={idx}>
                    <input
                      type="text"
                      className="w-full rounded-lg"
                      placeholder="Enter the name"
                      value={input.name}
                      onChange={(e) => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        if (index === -1) return;
                        temp[index].inputString[idx].name = e.target.value;
                        setPayloads(temp);
                      }}
                    />
                    <TiptapField
                      content={
                        payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString[
                          idx
                        ].tiptapJson || ""
                      }
                      setContent={(content: any, text: string) => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        if (index === -1) return;
                        temp[index].inputString[idx].value = text;
                        temp[index].inputString[idx].tiptapJson = content;
                        setPayloads(temp);
                      }}
                      refresh={updateField}
                      placeholder="Enter the value"
                      variables={variables}
                    />
                  </div>
                );
              })}
            <button
              className="text-primary flex gap-2 items-center"
              onClick={() => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                if (index === -1) return;
                temp[index].inputString.push({
                  name: "Variable Name",
                });
                setPayloads(temp);
              }}
            >
              <FaPlus />
              Add a new Variable Name and Variable Value pair
            </button>
          </div>
        );
      case "slider":
        return (
          <div className="flex gap-4 items-center">
            <Slider
              min={0}
              max={1}
              step={0.01}
              style={{ width: "100%" }}
              value={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || 0.5
              }
              onChange={(e) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                if (index === -1) {
                  temp.push({
                    payloadStructureId: field.payloadStructureId,
                    inputString: e,
                  });
                } else {
                  temp[index].inputString = e;
                }
                setPayloads(temp);
              }}
            />
            <InputNumber
              min={0}
              max={1}
              step={0.01}
              style={{ width: "20%" }}
              placeholder="0.50"
              value={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || 0.5
              }
              onChange={(e) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                if (index === -1) {
                  temp.push({
                    payloadStructureId: field.payloadStructureId,
                    inputString: e,
                    type: field.type,
                  });
                } else {
                  temp[index].inputString = e;
                }
                setPayloads(temp);
              }}
            />
          </div>
        );

      default:
        return (
          <textarea
            className="w-full h-32 rounded-lg"
            placeholder="Enter the value"
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""}
            onChange={(e) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: e.target.value,
                  type: field.type,
                });
              } else {
                temp[index].inputString = e.target.value;
              }
              setPayloads(temp);
            }}
          />
        );
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={modal}
        mask={false}
        onClose={() => setModal(false)}
        width={drawerWidth}
        styles={{
          content: {
            transition: "width 0.3s",
          },
          body: {
            padding: collapseConfig ? "0" : "24px",
          },
        }}
        classNames={{ header: !collapseConfig ? "border-b !border-gray-300" : "!border-none" }}
        className="ml-auto !font-favorit !p-0 border-l border-gray-500"
        getContainer={false}
        footer={
          !collapseConfig && (
            <div className="w-full flex flex-col gap-4 bg-white">
              {action.reviewAllowed && (
                <div className="flex gap-2 items-center">
                  <input type="checkbox" checked={review} onChange={() => setReview(!review)} />
                  <span className="font-medium">
                    Enable this if you’d like to review before moving on to the next step
                  </span>
                </div>
              )}
              {!isConnectionExists && (
                <Alert
                  message={
                    <>
                      Please add the required connection to proceed.{" "}
                      <Link to="/connections" target="_blank" rel="noopener noreferrer">
                        <i>Click here</i>
                      </Link>
                    </>
                  }
                  type="warning"
                  showIcon
                />
              )}
              <button
                className={` text-white w-full font-semibold rounded-lg p-2 mt-auto ${
                  loading || !changed || !isConnectionExists
                    ? "cursor-not-allowed bg-gray-500"
                    : "bg-primary cursor-pointer"
                }`}
                onClick={saveOutputStructure}
                disabled={loading || !changed || !isConnectionExists}
              >
                {loading ? "Saving..." : !changed ? "Saved" : "Save"}
              </button>
            </div>
          )
        }
        // check if the credits is a number , if (number) display here
        title={
          !collapseConfig && (
            <div className="flex items-center gap-2">
              <button className="p-0 border border-gray-300 rounded-sm" onClick={() => setCollapseConfig(true)}>
                <MdChevronRight size={25} />
              </button>
              <img src={action.logo} alt={action.name} className="w-6 h-6" />
              <span
                id="actionName"
                contentEditable={changeName}
                className={changeName ? "border border-black px-2" : ""}
                suppressContentEditableWarning
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.target.blur();
                  }
                }}
                onBlur={async (e) => {
                  if (!e.target.textContent) return;
                  setLoading(true);
                  updateActionName(action.id, e.target.textContent || action.name);
                  setChangeName(false);
                  await saveWorkflowActions(id || "");
                  setChanged(false);
                  setLoading(false);
                  setPublishWarning(true);
                }}
              >
                {action.name}
              </span>
              <button
                onClick={() => {
                  setChangeName(!changeName);
                  const p = document.getElementById("actionName");
                  if (!p) return;
                  setTimeout(function () {
                    const range = document.createRange();
                    const selection = window.getSelection();
                    range.selectNodeContents(p);
                    range.collapse(false); // <-- Set the cursor at the end of the selection
                    selection?.removeAllRanges();
                    selection?.addRange(range);
                    p.focus();
                  }, 0);
                }}
                className={`p-2 rounded-md transition-opacity duration-600 ease-in-out hover:bg-gray-200 ${
                  changeName ? "opacity-50" : "opacity-100"
                }`}
              >
                <FaRegEdit />
              </button>
              <span className="text-sm mt-2 font-light text-gray-400">
                {typeof action.credits === "number" && (
                  <div className="flex items-center ml-2 bg-purple-100 rounded p-1">
                    <span className="font-bold text-md">{action.credits}</span>
                    <FaCoins className="ml-1 text-purple-500 w-5" />
                  </div>
                )}
              </span>
            </div>
          )
        }
      >
        {collapseConfig ? (
          <button
            className="w-full h-full flex flex-col items-center justify-center p-3 gap-3"
            onClick={() => setCollapseConfig(false)}
          >
            <span className="p-0 border border-gray-300 rounded-sm">
              <MdChevronLeft size={25} />
            </span>
            <p className="[writing-mode:sideways-lr] text-center">CONFIG</p>
          </button>
        ) : (
          <div className="bg-white gap-10 flex flex-col">
            {action.payloadStructure.map((field, idx) => {
              return (
                <div key={idx} className="flex flex-col w-full gap-4 p-4 border border-gray-300 rounded-lg">
                  <div>
                    <div className="tracking-primary font-semibold flex">
                      <span>{field.name.toUpperCase()}</span>
                      {!field.required && (
                        <span className="text-sm font-normal tracking-normal text-gray-400"> (Optional)</span>
                      )}
                      <div className="flex gap-0.5 ml-auto">
                        {stepDownAPIs.length > 0 &&
                          stepDownAPIs
                            .filter((api) => api.payloads.includes(field.payloadStructureId))
                            .map((api) => (
                              <img key={api.id} src={api.logo} alt={api.name} title={api.name} className="w-5 h-5" />
                            ))}
                      </div>
                    </div>
                    <p className="text-sm font-light">{field.description}</p>
                  </div>
                  {field.needVars ? (
                    <TiptapField
                      content={
                        payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.tiptapJson ||
                        ""
                      }
                      setContent={(content: any, text: string) => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        if (index === -1) {
                          temp.push({
                            payloadStructureId: field.payloadStructureId,
                            tiptapJson: content,
                            inputString: text,
                            type: field.type,
                          });
                        } else {
                          temp[index].tiptapJson = content;
                          temp[index].inputString = text;
                        }
                        setPayloads(temp);
                      }}
                      refresh={updateField}
                      placeholder={field.name}
                      variables={variables}
                    />
                  ) : (
                    fieldSwitchCase(field)
                  )}
                </div>
              );
            })}
            <div>
              Continue workflow even if this fails:{" "}
              <Switch
                defaultChecked={action.continueOnFail}
                onChange={(value) => {
                  setChanged(true);
                  action.continueOnFail = value;
                }}
              />
            </div>
            <RunCondition
              variables={variables}
              responses={action.responseStructure}
              conditionsArr={
                runCondition || {
                  conditions: [],
                  otherwise: [],
                }
              }
              setConditionsArr={setRunCondition}
            />
            <ResponseStructureViewer
              setResponses={setResponses}
              responses={responses}
              action={action}
              setChanged={setChanged}
            />
          </div>
        )}
      </Drawer>
    </ConfigProvider>
  );
};

export default WaterfallModal;

import React, { useEffect, useRef, useState } from "react";
import { Drawer, Input, message, Select } from "antd";
import { BiCheck } from "react-icons/bi";
import { useWorkflow } from "@/contexts/WorkflowContext";
import { getExpectedTypeFromName } from "@/utils/constants";
import { addWorkflowToSubfloq, createAndSaveSubfloq, getAllSubfloqs } from "@/utils/apis";
import LoaderButton from "@/Components/LoaderButtonBlack";
import { MdDeleteOutline } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import TiptapField from "../../Tiptap/TiptapField";

interface SubfloqModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedNodes: any[];
  onAddSubfloq: (action: any, actions: any[], hoveredSource?: string, targetX?: string | null) => void;
}

// This modal is the Subfloq configuration modal that opens up once you select the actions.
const SubfloqCreateModal: React.FC<SubfloqModalProps> = ({ isOpen, onClose, selectedNodes, onAddSubfloq }) => {
  const subfloqVariablesRef = useRef<any[]>([]);
  const [requiredInputs, setRequiredInputs] = useState<any[]>([]);
  const [payloads, setPayloads] = useState<any[]>([{ name: "", description: "" }]);
  const [subfloqName, setSubfloqName] = useState<{ name: string; description: string }>({
    name: "New-one",
    description: "This is your new subfloq!",
  });
  const [selectedActions, setSelectedActions] = useState<any[]>([]);
  const [updateField, setUpdateField] = useState(0);
  const [shareLevel, setShareLevel] = useState<"individual" | "organization" | "community">("individual");
  const { actions, setAvailableSubfloqs, workflowConfig, availableActions } = useWorkflow();

  useEffect(() => {
    if (!isOpen) return;
    const newActions: any[] = structuredClone(actions);
    const filteredNodeActions = selectedNodes
      .map((node) => newActions.find((act) => act.id === node.id))
      .filter(Boolean);
    if (filteredNodeActions.length === 0) return;
    filteredNodeActions[0].prevAction[0] = { name: "Conditional Path", actionId: null, conditions: [] };
    filteredNodeActions[filteredNodeActions.length - 1].nextAction[0] = {
      name: "Conditional Path",
      actionId: null,
      conditions: [],
    };
    getVariableForActions(filteredNodeActions);
    setSelectedActions(filteredNodeActions);

    const payloadConfig = filteredNodeActions
      .map((act: any) => {
        return act.payloadConfiguration.filter((config: any) => config.tiptapJson);
      })
      .flat();

    const missingVariables = payloadConfig.flatMap((config: any) => {
      if (config.tiptapJson !== "" && config.tiptapJson.type === "doc" && subfloqVariablesRef.current.length > 0)
        return config.tiptapJson.content.flatMap(checkValidNode).filter(Boolean); // Remove empty values
      return [];
    });
    // Remove duplicates based on `id`
    const uniqueMissingVariables = Array.from(new Map(missingVariables.map((item) => [item.id, item])).values());

    setRequiredInputs(transformNames(uniqueMissingVariables));
    setUpdateField(updateField + 1);
  }, [selectedNodes, isOpen]);
  // Example Usage:
  // Assuming 'actions' is your array of action objects and 'subfloqId' is your subfloq ID
  // const updatedActions = transformActions(actions, subfloqId);

  const transformNames = (missingVariables: any[]) => {
    //---------------------------------\\\\\\\\\\\\\\\\\\\\\\\\\\\\\----------
    // TODO: NEED TO GET THE TIPTAP AND INPUTSTRING HERE AS WELL SOMEHOW
    //---------------------------------\\\\\\\\\\\\\\\\\\\\\\\\\\\\\----------
    return missingVariables.map((variable: any) => ({
      description: variable.label,
      name: variable.label,
      needVars: true,
      payloadStructureId: variable.id, // Generate a UUID
      required: true,
      type: "string",
    }));
  };

  const checkValidNode = (node: any): any => {
    if (node.type === "customTag") {
      const parentAction = getParentAction(node.attrs.id);
      if (!parentAction) return { label: node.attrs.label, id: node.attrs.id };
      else return "";
    } else if (node.type === "errorTag") {
      const parentAction = getParentAction(node.attrs.id);
      if (parentAction) {
        node.type = "customTag";
        node.attrs.parent = parentAction;
      }
      return { label: node.attrs.label, id: node.attrs.id }; // Explicit return for this case
    }
    // Recursively check child nodes, return false if any child is invalid
    if (Array.isArray(node.content)) return node.content.flatMap(checkValidNode);
    return null; // Default to true if no children exist
  };

  const getParentAction = (variableId: any) => {
    for (const action of subfloqVariablesRef.current) {
      for (const variable of action.variables) if (variable.responseId === variableId) return action.name;
    }
    return "";
  };

  const getVariableForActions = (filteredNodeActions: any[]) => {
    const responses: any[] = [];
    filteredNodeActions.forEach((act: any) => {
      const a: any = {
        name: act.displayName || act.actionDetails.name,
        type: act.actionDetails.type,
        logo: act.actionDetails.logo,
        id: act.id,
      };
      const alreadyExists = responses.some((res) => res.id === a.id);
      if (alreadyExists) return;
      if (act.actionDetails.outputStructureType === "userDefined") {
        responses.push({
          ...a,
          variables: act.responseConfiguration || [],
        });
      } else {
        const arr: any[] = [];
        const configs: {
          responseId: string;
          responseStructureId: string;
        }[] = act.responseConfiguration || [];
        const structures: {
          name: string;
          description: string;
          responseStructureId: string;
          type: string;
        }[] = act.actionDetails.responseStructure;

        configs.forEach((config: any) => {
          const response: any = config;
          const structure = structures.find((str) => str.responseStructureId === config.responseStructureId);
          arr.push({
            ...response,
            ...structure,
          });
        });

        responses.push({
          ...a,
          variables: arr,
        });
      }
    });
    subfloqVariablesRef.current = responses;
    setUpdateField(updateField + 1);
  };

  const createSaveSubfloq = async () => {
    if (payloads.length === 0) {
      message.error("Please add at least one output field");
      return;
    }
    for (const payload of payloads) {
      if (!payload.name) {
        message.error("Please fill in the name of the output field");
        return;
      }
      if (!payload.tiptapJson) {
        message.error("Please fill in the output field mapping");
        return;
      }
    }

    const subfloq_id = await createAndSaveSubfloq(
      subfloqName.name,
      subfloqName.description,
      { requiredInputs, payloads, selectedActions, shareLevel },
      true
    );
    if (!subfloq_id) {
      message.error("Error creating SubFloq! Try refreshing the page, or try again later.", 6);
      onClose();
      setSelectedActions([]);
      subfloqVariablesRef.current = [];
      setPayloads([]);
      setRequiredInputs([]);
      return;
    }

    const newSubs = await getAllSubfloqs(true);
    setAvailableSubfloqs(newSubs);

    const hoveredActionSourceId = selectedNodes[0].id;
    const hoveredActionTargetId = selectedNodes[selectedNodes.length - 1].id;
    const hoveredActionSource = actions.find((act) => act.id === hoveredActionSourceId).prevAction[0]?.actionId;
    const hoveredActionTarget = actions.find((act) => act.id === hoveredActionTargetId).nextAction[0]?.actionId;
    // console.log(hoveredActionSourceId, hoveredActionTargetId, hoveredActionSource, hoveredActionTarget, "NODESSSSss");

    const subfloq = newSubs.find((sub) => sub.id === subfloq_id);
    const actIdSub = uuidv4().toString();

    const updatedActions = subfloq.actions.map((action: any) => {
      const actPKId = uuidv4().toString();
      const actDetails = availableActions.find((act) => act.id === action.actionName) || {};
      return {
        ...action,
        actionDetails: actDetails,
        id: `${action.id}_${actIdSub}`,
        pk_id: actPKId,
        prevAction: action.prevAction.map((prev: any) => ({
          ...prev,
          actionId:
            prev.actionId !== null
              ? `${prev.actionId}_${actIdSub}`
              : action.id.includes("id2")
                ? hoveredActionSource
                : null,
        })),
        nextAction: action.nextAction.map((next: any) => ({
          ...next,
          actionId:
            next.actionId !== null
              ? `${next.actionId}_${actIdSub}`
              : action.id.includes("idout")
                ? hoveredActionTarget
                : null,
        })),
      };
    });
    const firstAction = updatedActions.find((act: any) => act.id.includes("id2"));

    onAddSubfloq(firstAction, updatedActions, hoveredActionSource, hoveredActionTarget);
    await addWorkflowToSubfloq(subfloq.id, workflowConfig.id, workflowConfig.name, "add");

    onClose();
    setSelectedActions([]);
    subfloqVariablesRef.current = [];
    setPayloads([]);
    setRequiredInputs([]);
    message.info("SubFloq created successfully! Reloading the workflow...");
  };

  return (
    <Drawer
      title={
        <div className="flex items-center gap-2">
          <img
            src="https://storage.googleapis.com/public_image_assets/internal-web-app-logos/subfloq-icon.svg"
            alt={"Create Subfloq"}
            className="w-6 h-6"
          />
          Create Subfloq
        </div>
      }
      placement="right"
      closable
      onClose={onClose}
      open={isOpen}
      width={500}
    >
      {/* Name Input */}
      <Input
        value={subfloqName.name}
        onChange={(e) => setSubfloqName((prev: any) => ({ ...prev, name: e.target.value }))}
        placeholder="Enter a name for your SubFloq"
        className="mb-4"
      />

      {/* Description Input */}
      <Input.TextArea
        value={subfloqName.description}
        onChange={(e) => setSubfloqName((prev: any) => ({ ...prev, description: e.target.value }))}
        placeholder="Describe what your SubFloq will do..."
        className="mb-4"
      />

      {/* Info Banner */}
      <div className="p-3 bg-yellow-100 text-sm text-gray-800 border-l-4 border-yellow-400 rounded-md mb-4">
        Your SubFloq named <strong>"{subfloqName.name}"</strong> will be saved in <strong>Floqs/SubFloqs</strong>.
      </div>

      {/* Selected Nodes Display */}
      <div className="mb-4">
        <h3 className="text-sm font-semibold text-gray-700 mb-2">INPUTS OF SUBFLOQ:</h3>
        <div className="flex flex-wrap gap-2">
          {requiredInputs.length > 0 ? (
            requiredInputs.map((node: any, idx: number) => (
              <div key={idx} className="bg-blue-100 text-blue-700 px-2 py-1 rounded-md">
                {node.name}
              </div>
            ))
          ) : (
            <p className="text-sm text-gray-500">No inputs selected</p>
          )}
        </div>
      </div>

      {/* Output Section */}
      <div className="mb-4">
        <h3 className="text-sm font-semibold text-gray-700 mb-2">What is your SubFloq going to output?</h3>
        <p className="text-sm text-gray-500">Define the outputs you want coming out from your SubFloq.</p>
      </div>
      <div className="flex flex-col w-full justify-center items-start border-t border-gray-300 overflow-y-scroll">
        {payloads.map((payload, index) => {
          return (
            <div className="w-full flex-col justify-center gap-1 items-end border border-gray-300 p-1 mt-2" key={index}>
              <div className="w-full pr-8 pb-1">
                <input
                  className="header-input w-full h-4 px-2 py-4 rounded border border-gray-300 focus:outline-none focus:ring-0 focus:border-gray-500"
                  type="text"
                  name="name"
                  value={payload.name}
                  onChange={(e) => {
                    const temp = [...payloads];
                    temp[index].name = e.target.value;
                    if (!temp[index].type || temp[index].type === "string")
                      temp[index].type = getExpectedTypeFromName(e.target.value);
                    temp[index].payloadStructureId = payload?.payloadStructureId || uuidv4();
                    setPayloads(temp);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      const temp = [...payloads];
                      temp.push({
                        name: "",
                        description: "",
                        inputString: "", // Ensure this exists
                        payloadStructureId: payload?.payloadStructureId || uuidv4(),
                      });
                      setPayloads(temp);
                      setTimeout(() => {
                        const inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll(".header-input");
                        const lastInput = inputs[inputs.length - 1];
                        lastInput.focus();
                      }, 100);
                    }
                  }}
                  placeholder="Name of the Field"
                />
              </div>

              <div className="w-full flex items-center gap-2">
                <TiptapField
                  content={payload.tiptapJson || ""}
                  setContent={(content: any, text: string) => {
                    const temp = [...payloads];
                    temp[index] = {
                      ...temp[index], // Preserve other fields
                      tiptapJson: content,
                      inputString: text,
                    };
                    setPayloads(temp);
                  }}
                  refresh={updateField}
                  placeholder={"Output variable mapping"}
                  variables={subfloqVariablesRef.current || []}
                />
                <button
                  className="hover:bg-red-600/10 h-fit w-fit p-1 text-primary rounded-full"
                  onClick={() => {
                    const temp = [...payloads];
                    temp.splice(index, 1);
                    setPayloads(temp);
                  }}
                >
                  <MdDeleteOutline size={16} color="red" />
                </button>
              </div>
            </div>
          );
        })}

        <button
          className="px-2 py-4 border-t-2 border-b-2 border-gray-400 w-full font-bold text-left"
          onClick={() => {
            const temp = [...payloads];
            temp.push({
              name: "",
              description: "",
            });
            setPayloads(temp);
          }}
        >
          + Add output
        </button>
      </div>
      <div className="flex items-center gap-x-2 py-4">
        <p className="w-fit text-nowrap">Sharing level: </p>
        <Select
          defaultValue={shareLevel}
          className="w-full"
          onChange={(value) => setShareLevel(value)}
          options={[
            { label: "Individual (Only you can access)", value: "individual" },
            { label: "Organization (Everyone from your org.)", value: "organization" },
            { label: "Community (Everyone using Floqer)", value: "community" },
          ]}
        />
      </div>

      {/* Save Button */}
      <LoaderButton
        text="Create Subfloq"
        loaderText="Creating Subfloq..."
        btnClasses="bg-black text-white px-14 w-full justify-center mt-2"
        onClickCallback={createSaveSubfloq}
        error=""
        disabled={false}
        IconComponent={BiCheck}
      />
    </Drawer>
  );
};

export default SubfloqCreateModal;

import { Button, ConfigProvider, Divider, Drawer, Select, Skeleton } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Action, PayloadConfiguration, PayloadStructure, ResponseConfiguration } from "../../../utils/interfaces";
import {
  ENGAGEBAY_SYSTEM_FIELDS,
  getEndpointFromNameAndId,
  getExpectedTypeFromName,
  getTypeFromValue,
  INPUT_TYPES,
  setStateType,
} from "../../../utils/constants";
import { useWorkflow } from "../../../contexts/WorkflowContext";
import { useParams } from "react-router-dom";
import { FaCheck, FaPlus, FaRegCopy } from "react-icons/fa";
import PingViewer from "../Modals/PingViewer/PingViewer";
import { MdChevronLeft, MdChevronRight, MdContentPaste, MdDeleteOutline } from "react-icons/md";
import { IoIosRefresh, IoMdAdd } from "react-icons/io";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import { getCampaigns } from "@/utils/apis";
import styles from "./Components.module.css";
import { PiCopySimpleThin } from "react-icons/pi";
import { useRunOnFloq } from "@/contexts/RunOnFloqContext";
import Upload from "@/assets/SVGs/buildTab/Upload.svg";
import XLSXInput from "../Tabs/Import/XLSXInput";

type Props = {
  action: Action;
  modal: boolean;
  setModal: setStateType<boolean>;
  responseConfiguration: ResponseConfiguration[];
  payloadConfiguration: PayloadConfiguration[];
};

const InputModal = ({ action, modal, setModal, responseConfiguration, payloadConfiguration }: Props) => {
  const [loading, setLoading] = useState(false);
  const [campaignsLoading, setCampaignsLoading] = useState<Record<string, boolean>>({});
  const [campaigns, setCampaigns] = useState<Record<string, any[]>>({});
  const [responses, setResponses] = useState<ResponseConfiguration[]>(responseConfiguration);
  const [payloads, setPayloads] = useState<PayloadConfiguration[]>(payloadConfiguration);
  const [isCopied, setIsCopied] = useState(false);
  const [isPasted, setIsPasted] = useState(false);
  const { collapseConfig, setCollapseConfig } = useRunOnFloq();
  const [drawerWidth, setDrawerWidth] = useState(collapseConfig ? "3rem" : "30%");
  const types = useMemo<string[]>(
    () =>
      action.payloadStructure
        .filter((field) => field.type.includes("dynamicDropdown"))
        .map((field) => field.valuesId ?? ""),
    [action.payloadStructure]
  );
  const [ping, setPing] = useState<any>({
    loading: false,
    data: action.ping,
  });
  const {
    actions,
    updateResponseConfig,
    updatePayloadConfig,
    saveWorkflowActions,
    setPublishWarning,
    getAllWorkflowActionsDetails,
  } = useWorkflow();
  const { id } = useParams();

  useEffect(() => {
    if (types.length === 0) return;
    types.forEach((type) => {
      setCampaignsLoading((prev) => ({ ...prev, [type]: true }));
      getCampaigns(type)
        .then((data: any[]) => {
          setCampaigns((prev) => ({ ...prev, [type]: data }));
        })
        .catch((error) => {
          console.log(error);
          setCampaigns((prev) => ({ ...prev, [type]: [] }));
        })
        .finally(() => {
          setCampaignsLoading((prev) => ({ ...prev, [type]: false }));
        });
    });
  }, [types]);

  useEffect(() => {
    setWidthPercent();
    window.addEventListener("resize", setWidthPercent);
    return () => window.removeEventListener("resize", setWidthPercent);
  }, [collapseConfig]);

  const setWidthPercent = useCallback(() => {
    try {
      if (collapseConfig) setDrawerWidth("3rem");
      // Adjust width for different screen sizes
      else if (window.matchMedia("(min-width: 1024px) and (min-height: 1366px)").matches)
        // iPad Pro and similar devices
        setDrawerWidth("50%");
      else if (window.matchMedia("(min-width: 1024px)").matches) setDrawerWidth("30%");
      else if (window.matchMedia("(min-width: 640px)").matches) setDrawerWidth("50%");
      else setDrawerWidth("100%");
    } catch (err) {
      console.error("Error calculating width percent based on media size");
    }
  }, [collapseConfig]);

  const saveOutputStructure = async () => {
    const filteredResponses = responses?.filter((res) => {
      return !!res?.name;
    });
    setLoading(true);
    const updated = updateResponseConfig(filteredResponses, action.id);
    updatePayloadConfig(payloads, action.id, false);
    setResponses(updated);
    await saveWorkflowActions(id || "");
    setPublishWarning(true);
    setLoading(false);
  };

  const refreshAction = async () => {
    const act = actions.find((act) => act.id === action.id);
    if (!act) return;

    setResponses(act.responseConfiguration || []);
    setPayloads(act.payloadConfiguration || []);
  };

  const refreshPing = async () => {
    setPing({ ...ping, loading: true });
    const data = await getAllWorkflowActionsDetails(id || "");
    setPing({
      loading: false,
      data: data?.actions?.find((act: any) => act.id === action.id)?.ping || undefined,
    });
  };

  const addFields = (keys: string[]) => {
    setResponses((prev) =>
      prev.concat(
        keys.map((key) => {
          const name = key.split(".");
          return {
            name: name[name.length - 1],
            description: "",
            responseStructureId: key,
            type: getTypeFromValue(ping.data[key]),
          };
        })
      )
    );
  };

  const addField = (key: string) => {
    const temp = [...responses];
    const name = key.split(".");
    temp.push({
      name: name[name.length - 1],
      description: "",
      responseStructureId: key,
      type: getTypeFromValue(ping.data[key]),
    });
    setResponses(temp);
  };

  const handleAddFields = () => {
    // Accumulate all keys in a single array
    const keysToAdd: string[] = [];
    // Recursive function to traverse and collect keys
    const collectKeys = (data: any, prefix = "") => {
      Object.entries(data).forEach(([key, value]) => {
        const currentKey = prefix ? `${prefix}.${key}` : key;
        // Add the current key
        keysToAdd.push(currentKey);
        if (Array.isArray(value)) {
          // For arrays, include the index as part of the key
          value.forEach((item, index) => {
            const arrayKey = `${currentKey}.${index}`;
            keysToAdd.push(arrayKey); // Add the array index key
            if (item && typeof item === "object") {
              // Recurse for objects within arrays
              collectKeys(item, arrayKey);
            }
          });
        } else if (value && typeof value === "object") {
          // Recurse for nested objects
          collectKeys(value, currentKey);
        }
      });
    };
    // Collect all keys from ping.data
    collectKeys(ping.data);
    // Call addFields once with the accumulated keys
    addFields(keysToAdd);
  };

  const handleCopy = async (responses: ResponseConfiguration[]) => {
    setIsCopied(true);
    try {
      const names = responses.map((item) => item.name);
      const clipboardText = names.join("\t");
      await navigator.clipboard.writeText(clipboardText);
    } catch (err) {
      console.error("Failed to copy to clipboard:", err);
    }
    setTimeout(() => setIsCopied(false), 1500);
  };

  const handlePaste = async (e: any, index: number, field: string) => {
    setIsPasted(true);
    e.preventDefault();

    let pastedData;
    if (e.clipboardData) pastedData = e.clipboardData.getData("text");
    else if (navigator.clipboard) pastedData = await navigator.clipboard.readText();
    else console.error("unable to paste");

    const lines = pastedData.split("\t").filter((line: any) => line.trim() !== "");
    const updatedRows: any[] = [...responses];

    // Update the rows starting from the pasted input's index
    lines.forEach((line: any, lineIndex: number) => {
      // Update existing rows
      if (index + lineIndex < responses.length) {
        updatedRows[index + lineIndex][field] = line;
        updatedRows[index + lineIndex].type = getExpectedTypeFromName(line);
      } else {
        // Add new rows if needed
        const newRow: any = { name: "", description: "" };
        newRow[field] = line;
        newRow.type = getExpectedTypeFromName(line);
        updatedRows.push(newRow);
      }
    });

    setResponses(updatedRows);
    setTimeout(() => setIsPasted(false), 1500);
  };

  // function to return code for "fixed" type fields
  const getFixedCode = (field: PayloadStructure) => {
    const includeNames = ["search/create", "navigator", "pipedrive"];
    if (includeNames.some((name) => action.name.toLowerCase().includes(name))) {
      return payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString
        ? typeof payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString ===
          "string"
          ? payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString
          : JSON.stringify(payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString)
        : "";
    } else {
      return getEndpointFromNameAndId(
        action.name,
        payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""
      );
    }
  };

  const getInputType = (type: string) => {
    switch (type) {
      case "text":
      case "string":
      case "textarea":
      case "longtext":
        return "string";
      case "number":
      case "integer":
      case "float":
      case "int":
        return "number";
      case "revenue":
      case "currency":
        return "currency";
      case "email":
        return "email";
      case "url":
      case "link":
      case "website":
      case "image":
        return "url";
      case "imageurl":
      case "image_url":
      case "imageURL":
        return "imageUrl";
      case "date":
      case "datetime":
      case "time":
        return "date";
      default:
        return "string";
    }
  };

  const fieldSwitch = (field: PayloadStructure) => {
    switch (field.type) {
      case "inputArray":
        return (
          <div className="flex flex-col w-full justify-center items-start border border-gray-300 rounded-xl">
            <div className="flex justify-between w-full items-center px-3 py-2 font-bold">
              <div className="w-[45%] flex gap-x-2 items-center">
                <p>Inputs</p>
                <div
                  className="flex gap-x-1 items-center bg-[#ECEBFF] border border-white hover:border-[#C6C4FF] rounded-md text-primary p-1 group cursor-pointer h-[26px]"
                  onClick={(e) => handlePaste(e, 0, "name")}
                >
                  {isPasted ? <FaCheck className="text-primary" /> : <MdContentPaste color="primary" size={16} />}
                  <p className="text-xs opacity-0 max-w-0 -ml-1 group-hover:ml-0 overflow-hidden group-hover:opacity-100 group-hover:max-w-xs transition-all duration-500 ease-in-out">
                    Paste
                  </p>
                </div>
                <div
                  className="flex gap-x-1 items-center bg-[#ECEBFF] border border-white hover:border-[#C6C4FF] rounded-md text-primary p-1 group cursor-pointer h-[26px]"
                  onClick={() => handleCopy(responses)}
                >
                  {isCopied ? <FaCheck className="text-primary" /> : <PiCopySimpleThin color="prirmary" size={16} />}
                  <p className="text-xs opacity-0 max-w-0 -ml-1 group-hover:ml-0 overflow-hidden group-hover:opacity-100 group-hover:max-w-xs transition-all duration-500 ease-in-out">
                    Copy
                  </p>
                </div>
              </div>
              <div className="w-[45%] text-left">Type of field</div>
            </div>
            {responses.map((response, index) => {
              return (
                <div className="w-full flex justify-between gap-1 items-end border border-gray-300 p-1" key={index}>
                  <div className="w-[45%]">
                    {/* <p>Input {index + 1}</p> */}
                    <input
                      className="header-input w-full h-4 px-2 py-4 rounded border-none focus:outline-none focus:ring-0 focus:border-transparent"
                      type="text"
                      name="name"
                      value={response.name}
                      onChange={(e) => {
                        const temp = [...responses];
                        temp[index].name = e.target.value;
                        if (!temp[index].type || temp[index].type === "string")
                          temp[index].type = getExpectedTypeFromName(e.target.value);
                        setResponses(temp);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          const temp = [...responses];
                          temp.push({
                            name: "",
                            description: "",
                          });
                          setResponses(temp);
                          setTimeout(() => {
                            const inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll(".header-input");
                            const lastInput = inputs[inputs.length - 1];
                            lastInput.focus();
                          }, 100);
                        }
                      }}
                      onPaste={(e) => handlePaste(e, index, "name")}
                      placeholder="Name of the Field"
                    />
                  </div>
                  <div className="w-[45%] flex items-center">
                    {/* <p>Type</p> */}
                    <Select
                      showSearch
                      className={`w-full rounded ${styles.selectInputDropdown}`}
                      value={response.type || "string"}
                      onChange={(value) => {
                        const temp = [...responses];
                        temp[index].type = value;
                        setResponses(temp);
                      }}
                      options={INPUT_TYPES}
                      style={{ border: "none" }}
                    />
                    <button
                      className="hover:bg-red-600/10 h-fit w-fit p-1 rounded-sm text-primary"
                      onClick={() => {
                        const temp = [...responses];
                        temp.splice(index, 1);
                        setResponses(temp);
                      }}
                    >
                      <MdDeleteOutline size={16} color="red" />
                    </button>
                  </div>
                </div>
              );
            })}
            <button
              className="p-2 font-bold"
              onClick={() => {
                const temp = [...responses];
                temp.push({
                  name: "",
                  description: "",
                });
                setResponses(temp);
              }}
            >
              + Add input
            </button>
          </div>
        );
      case "dynamicDropdownInp":
        return (
          <Skeleton loading={campaignsLoading[field.valuesId || ""] ?? true}>
            <Select
              key={responses.map((response) => response.responseStructureId).join("")}
              mode="multiple"
              defaultValue={responses.map((response) => response.responseStructureId)}
              onChange={(value) => {
                if (field.valuesId === "engagebay_properties") {
                  setResponses(
                    value.map((res) => {
                      const p: any = (campaigns[field.valuesId || ""] ?? [])
                        .concat(ENGAGEBAY_SYSTEM_FIELDS)
                        .find((property: any) => property.field_name === res);
                      return {
                        name: p.field_label,
                        description: p.field_description,
                        responseStructureId: p.field_name,
                      };
                    })
                  );
                } else {
                  setResponses(
                    value.map((res) => {
                      const p: any = (campaigns[field.valuesId || ""] ?? []).find(
                        (property: any) => property.name === res
                      );
                      return {
                        name: p.label,
                        description: p.description,
                        responseStructureId: p.name,
                        type: getInputType(p.type),
                      };
                    })
                  );
                }
              }}
              options={
                field.valuesId === "engagebay_properties"
                  ? (campaigns[field.valuesId || ""] ?? []).concat(ENGAGEBAY_SYSTEM_FIELDS).map((property: any) => ({
                      label: property.field_label,
                      value: property.field_name,
                    }))
                  : (campaigns[field.valuesId || ""] ?? []).map((property: any) => ({
                      label: property.label,
                      value: property.name,
                    }))
              }
              optionFilterProp="label"
            />
          </Skeleton>
        );
      case "dynamicDropdown":
        return (
          <Skeleton loading={campaignsLoading[field.valuesId || ""] ?? true}>
            <Select
              showSearch
              defaultValue={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""
              }
              onChange={(value) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                temp[index].inputString = value;
                setPayloads(temp);
              }}
              options={(campaigns[field.valuesId || ""] ?? []).map((value: any) => ({
                label: value.name,
                value: value.id,
              }))}
            />
          </Skeleton>
        );
      case "dynamicDropdownMulti":
        return (
          <Skeleton loading={campaignsLoading[field.valuesId || ""] ?? true}>
            <Select
              showSearch
              mode="multiple"
              defaultValue={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || []
              }
              onChange={(value) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                if (index === -1)
                  temp.push({
                    payloadStructureId: field.payloadStructureId,
                    inputString: value,
                    type: field.type,
                  });
                else temp[index].inputString = value;
                setPayloads(temp);
              }}
              options={(campaigns[field.valuesId || ""] ?? []).map((value: any) => ({
                label: value.name,
                value: value.id,
              }))}
            />
          </Skeleton>
        );
      case "array":
        return (
          <div className="flex flex-col gap-6">
            {responses.map((item: ResponseConfiguration, idx2: number) => (
              <div className="flex gap-2">
                <input
                  type="text"
                  key={idx2}
                  className="w-full rounded-lg"
                  placeholder={field.description}
                  value={item.name}
                  onChange={(e) => {
                    const temp = [...responses];
                    temp[idx2].name = e.target.value;
                    temp[idx2].description = e.target.value;
                    temp[idx2].responseStructureId = e.target.value;
                    setResponses(temp);
                  }}
                />
                <button
                  className="hover:bg-primary/10 h-fit w-fit p-2 rounded-full text-primary"
                  onClick={() => {
                    const temp = [...responses];
                    temp.splice(idx2, 1);
                    setResponses(temp);
                  }}
                >
                  <MdDeleteOutline size={25} />
                </button>
              </div>
            ))}
            <button
              className="border border-black w-fit flex font-semibold items-center text-black gap-2 rounded-lg p-2"
              onClick={() => {
                const temp = [...responses];
                temp.push({
                  name: "",
                  description: "",
                });
                setResponses(temp);
              }}
              disabled={responses.length > 0 && responses[responses.length - 1].name === ""}
            >
              <FaPlus />
              Add {field.name}
            </button>
          </div>
        );

      case "webhookArray":
        return (
          <div className="flex flex-col w-full justify-center items-start gap-4">
            {responses.map((response, index) => {
              return (
                <div className="w-full" key={index}>
                  <p>Input {index + 1}</p>
                  <div className="flex gap-1 justify-between">
                    <input
                      className="w-[47%] rounded-lg"
                      type="text"
                      value={response.name}
                      onChange={(e) => {
                        const temp = [...responses];
                        temp[index].name = e.target.value;
                        setResponses(temp);
                      }}
                      placeholder="Name of the column"
                      onPaste={(e) => handlePaste(e, index, "name")}
                    />
                    <input
                      className="w-[47%] rounded-lg"
                      type="text"
                      placeholder="Webhook field"
                      value={response.responseStructureId || ""}
                      onChange={(e) => {
                        const temp = [...responses];
                        temp[index].responseStructureId = e.target.value;
                        setResponses(temp);
                      }}
                      onPaste={(e) => handlePaste(e, index, "responseStructureId")}
                    />
                    <button
                      className="hover:bg-primary/10 h-fit w-fit p-2 rounded-full text-primary"
                      onClick={() => {
                        const temp = [...responses];
                        temp.splice(index, 1);
                        setResponses(temp);
                      }}
                    >
                      <MdDeleteOutline size={25} />
                    </button>
                  </div>
                </div>
              );
            })}
            <button
              className="border border-black rounded-lg p-2"
              onClick={() => {
                const temp = [...responses];
                temp.push({
                  name: "",
                  description: "",
                });
                setResponses(temp);
              }}
            >
              + Add input
            </button>
          </div>
        );
      case "fixed":
        return (
          <div className="w-full rounded-lg p-2 bg-gray-100 break-words relative">
            <code>{getFixedCode(field)}</code>
            <Button
              type="text"
              icon={
                isCopied ? (
                  <FaCheck className="text-white bg-primary rounded-md p-1" />
                ) : (
                  <FaRegCopy className="text-primary" />
                )
              }
              className="absolute top-2 right-2 hover:bg-gray-200 rounded-md"
              onClick={() => {
                navigator.clipboard.writeText(getFixedCode(field)).then(() => {
                  setIsCopied(true);
                  setTimeout(() => setIsCopied(false), 2000);
                });
              }}
            />
          </div>
        );
      default:
        return (
          <textarea
            className="w-full h-32 rounded-lg"
            placeholder="Enter the value"
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""}
            onChange={(e) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: e.target.value,
                  type: field.type,
                });
              } else {
                temp[index].inputString = e.target.value;
              }
              setPayloads(temp);
            }}
          />
        );
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={modal}
        mask={false}
        onClose={() => {
          saveOutputStructure();
          setModal(false);
        }}
        width={drawerWidth}
        styles={{
          content: {
            transition: "width 0.3s",
          },
          body: {
            padding: "0",
          },
        }}
        classNames={{ header: !collapseConfig ? "border-b !border-gray-300" : "!border-none" }}
        className="ml-auto !font-favorit !p-0 border-l border-gray-500"
        getContainer={false}
        title={
          !collapseConfig && (
            <div className="flex items-center gap-2">
              <button className="p-0 border border-gray-300 rounded-sm" onClick={() => setCollapseConfig(true)}>
                <MdChevronRight size={25} />
              </button>
              <img src={action.logo} alt={action.name} className="w-6 h-6" />
              {action.name}
            </div>
          )
        }
        footer={
          !collapseConfig && (
            <button
              className={`w-full text-white rounded-lg p-2 mt-auto ${
                loading ? "cursor-not-allowed bg-gray-500" : "bg-primary cursor-pointer"
              }`}
              onClick={saveOutputStructure}
              disabled={loading}
            >
              {loading ? "Saving..." : "Save"}
            </button>
          )
        }
      >
        {collapseConfig ? (
          <button
            className="w-full h-full flex flex-col items-center justify-center p-3 gap-3"
            onClick={() => setCollapseConfig(false)}
          >
            <span className="p-0 border border-gray-300 rounded-sm">
              <MdChevronLeft size={25} />
            </span>
            <p className="[writing-mode:sideways-lr] text-center">CONFIG</p>
          </button>
        ) : (
          // <div className="flex">
          // <ResizeableButton onSizeChange={(percentage) => setDrawerWidth(percentage)} />
          <div className="h-full bg-white gap-5 flex flex-col">
            {action.actionName.includes("CSV") && (
              <>
                <div className="p-5 w-full cursor-pointer">
                  <XLSXInput refresh={refreshAction} template={responses}>
                    <div
                      className="flex gap-2 items-center justify-center outline-2 outline-dashed outline-gray-300 rounded-sm hover:outline-primary p-4 w-full"
                      onClick={saveOutputStructure}
                    >
                      <div className="h-10 w-10 relative flex justify-center items-center rounded-full flex-shrink-0 ">
                        <img src={Upload} alt="icon" />
                      </div>

                      <label className="text-gray-500 font-light text-lg select-none pointer-events-none whitespace-no-wrap break-words line-clamp-2">
                        Upload CSV
                      </label>
                    </div>
                  </XLSXInput>
                </div>
                <Divider className="!m-0 border-gray-300" />
              </>
            )}
            {action.payloadStructure.map((field, idx) => {
              return (
                !field.payloadStructureId.includes("Modal") && (
                  <div key={idx} className="flex flex-col gap-4 p-4 m-5 border border-gray-300 rounded-lg">
                    <div>
                      <p className="tracking-primary font-semibold">
                        {field.name.toUpperCase()}
                        {!field.required && (
                          <span className="text-sm font-normal tracking-normal text-gray-400"> (Optional)</span>
                        )}
                      </p>
                      <p className="text-sm font-light">{field.description}</p>
                    </div>
                    {fieldSwitch(field)}
                  </div>
                )
              );
            })}
            {action.name.includes("Webhook") && !action.actionName.includes("CSV") && (
              <div className="flex flex-col gap-4 mx-5">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="tracking-primary font-semibold">Mapping</p>
                    <p className="text-sm font-light">Click the field to add as an acceptable parameter</p>
                  </div>
                  <button
                    className="flex gap-1 items-center hover:bg-gray-200 p-1 rounded"
                    disabled={ping.loading}
                    onClick={refreshPing}
                  >
                    {ping.loading ? <SpinnerStatus /> : <IoIosRefresh color="black" />}
                    Refresh
                  </button>
                </div>
                <div className="bg-gray-100 rounded p-4">
                  {ping.data ? (
                    <>
                      <div className="flex flex-wrap items-center justify-between mb-4">
                        {Object.entries(ping.data).map(([key, value], idx) => (
                          <PingViewer keyName={key} value={value} prev={""} key={idx} addField={addField} />
                        ))}
                      </div>
                      <div className="w-full flex justify-center gap-4 items-center">
                        {/* <button
                        className="w-full font-medium py-2 px-4 whitespace-nowrap h-fit bg-gray-200 hover:bg-gray-300 rounded text-xs"
                        onClick={handleAddFields}
                      >
                        Copy All Fields
                      </button> */}
                        <button
                          className="w-full font-medium py-2 px-4 whitespace-nowrap h-fit bg-gray-200 hover:bg-gray-300 rounded text-xs flex items-center justify-center"
                          onClick={handleAddFields}
                        >
                          <IoMdAdd />
                          Add All Fields
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="p-2 flex flex-col items-center">
                      <p>No mapping found</p>
                      <p>The webhook hasn't received any data yet.</p>
                      <p>Make a request and refresh to see the mapping.</p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </Drawer>
    </ConfigProvider>
  );
};

export default InputModal;

import { useState } from "react";
import { CloseOutlined, PlusOutlined, DownOutlined, DeleteOutlined } from "@ant-design/icons";
import { Select, TextField } from "@radix-ui/themes";
import { TABLE_COLUMN_STATUS_FILTERS } from "@/utils/constants";
import * as Popover from "@radix-ui/react-popover";
import * as Checkbox from "@radix-ui/react-checkbox";
import { ImCheckboxChecked } from "react-icons/im";
import {
  interfaceinputAction,
  InputFilterDataStructure,
  useFilter,
  filter,
  inputActionOperations,
} from "@/hooks/useFilter";
import { Tooltip } from "antd";

interface Props {
  filters: any;
  actions: any[];
  inputActions: interfaceinputAction[];
  inputFilters: InputFilterDataStructure;
  onChange: (filters: any, inputFilters: any) => void;
  triggerButton: React.ReactNode;
}

const Filters: React.FC<Props> = ({
  filters: defaultFilters,
  inputFilters: defaultInputFilters,
  actions,
  inputActions,
  onChange,
  triggerButton,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const {
    filters,
    appliedFilters,
    availableFilters,
    addFilter,
    setFilter,
    removeFilter,
    getAppliedFilterKeys,
    showInputForFilter,
    clearFilters,
  } = useFilter(actions, inputActions, defaultFilters, defaultInputFilters, isPopoverOpen);

  const getFiltersFromAppliedFilters = () => {
    const filter_conditions: { [key: string]: string[] } = {};
    const response_filter: {
      [key: string]: {
        type: string;
        value: string | string[];
      };
    } = {};

    appliedFilters.forEach((filter) => {
      if (filter.type === "action" && Array.isArray(filter.value)) {
        if (!filter.value.length) return;
        filter_conditions[filter.id] = filter.value;
      } else if (filter.type === "input") {
        if (!filter.operator || typeof filter.value !== "string") return;
        const value = filter.value.replace(/,\s+/g, ",");
        response_filter[filter.id] = {
          type: filter.operator,
          value,
        };
      }
    });

    return {
      filter_conditions,
      response_filter,
    };
  };

  const handleActionInputChange = (val: string, filter: filter) => {
    const newFilter = filters.find((f) => f.id === val);
    if (!newFilter) return;
    setFilter(newFilter, filter);
  };

  const handleInputActionInputChange = (val: string, filter: filter) => {
    const newFilter = filters.find((f) => f.id === val);
    if (!newFilter) return;
    setFilter(newFilter, filter);
  };

  const getSubUI = (filter: any) => {
    switch (filter?.type) {
      case "action":
        const appliedOptionsCount = filter?.value?.length || 0;
        return (
          <>
            <Popover.Root>
              <Popover.Trigger asChild>
                <button className="flex items-center justify-between w-44 px-3 py-1 text-sm bg-white border border-gray-300 rounded hover:bg-gray-50">
                  <span>
                    {appliedOptionsCount} filter
                    {appliedOptionsCount !== 1 ? "s" : ""} applied
                  </span>
                  <DownOutlined className="text-gray-400" />
                </button>
              </Popover.Trigger>
              <Popover.Content className="bg-white py-1 px-1 rounded shadow-sm w-48">
                {TABLE_COLUMN_STATUS_FILTERS?.map((status) => {
                  // const checked = tempFilters[filter?.id]?.includes(status?.value);
                  const checkboxID = `${filter?.id}_${status?.value}`;
                  return (
                    <label
                      key={checkboxID}
                      htmlFor={checkboxID}
                      className="flex items-center gap-2 py-1 px-2 cursor-pointer hover:bg-gray-100"
                    >
                      <Checkbox.Root
                        className="w-4 h-4 border border-gray-300 rounded"
                        checked={filter.value?.includes(status?.value)}
                        id={checkboxID}
                        onCheckedChange={(checked) => {
                          if (checked) {
                            if (!filter.value?.includes(status?.value)) {
                              filter.value.push(status?.value);
                            }
                          }

                          if (!checked) {
                            filter.value = filter.value.filter((item: string) => item !== status?.value);
                          }
                          setFilter(filter, filter);
                        }}
                      >
                        <Checkbox.Indicator>
                          {true && <ImCheckboxChecked className="text-blue-500" />}
                        </Checkbox.Indicator>
                      </Checkbox.Root>
                      <span className="text-sm">{status?.text}</span>
                    </label>
                  );
                })}
              </Popover.Content>
            </Popover.Root>
            <div className="ml-auto">
              <button
                onClick={() => removeFilter(filter)}
                className="p-2 rounded hover:bg-gray-100 text-red-600"
                aria-label="Remove filter"
              >
                <DeleteOutlined />
              </button>
            </div>
          </>
        );

      case "input":
        const showInput = showInputForFilter(filter);
        const selectedOporation = inputActionOperations?.find((operator) => operator?.value === filter?.operator);

        return (
          <div className="flex gap-2 justify-center items-center w-full" key={filter.id}>
            {/* <div>
              {index === 0 ? (
                <div className="text-gray-500 text-sm w-10">Where</div>
              ) : (
                <div className="text-sm w-10">And</div>
              )}
            </div> */}
            <Select.Root
              defaultValue={filter?.operator}
              onValueChange={(val) => {
                filter.operator = val;
                setFilter(filter, filter);
              }}
            >
              <Select.Trigger
                className={`px-3 py-1 gap-2 border border-gray-300 rounded ${showInput ? "w-30" : "w-44"}`}
              />
              <Select.Content>
                {inputActionOperations.map((operator) => {
                  return (
                    <Select.Item value={operator?.value} key={operator?.value}>
                      {operator?.title}
                    </Select.Item>
                  );
                })}
              </Select.Content>
            </Select.Root>
            {showInput && (
              <Tooltip title={selectedOporation?.tooltip ? selectedOporation?.tooltip : ""}>
                <TextField.Root
                  size="2"
                  placeholder={selectedOporation?.Placeholder ? selectedOporation?.Placeholder : "Type here..."}
                  className="px-3 py-1 rounded w-40"
                  value={filter.value}
                  onChange={(val) => {
                    filter.value = val?.target?.value;
                    setFilter(filter, filter);
                  }}
                />
              </Tooltip>
            )}
            <div className="ml-auto">
              <button
                onClick={() => removeFilter(filter)}
                className="p-2 rounded hover:bg-gray-100 text-red-600"
                aria-label="Remove filter"
              >
                <DeleteOutlined />
              </button>
            </div>
          </div>
        );
    }
  };

  const appliedFiltersUI = () => {
    return (
      <div className="flex flex-col justify-start items-start gap-2 text-sm">
        {appliedFilters.map((filter, index) => {
          return (
            <div className="flex gap-2 justify-between items-center w-full" key={filter.id}>
              <div>
                {index === 0 ? (
                  <div className="text-gray-500 text-sm w-10">Where</div>
                ) : (
                  <div className="text-sm w-10">And</div>
                )}
              </div>
              <Select.Root
                defaultValue={filter?.id}
                key={index}
                onValueChange={(val) => {
                  switch (filter?.type) {
                    case "action":
                      handleActionInputChange(val, filter);
                      break;
                    case "input":
                      handleInputActionInputChange(val, filter);
                      break;
                  }
                }}
              >
                <Select.Trigger className="px-3 py-1 gap-2 w-52 border border-gray-300 rounded" />
                <Select.Content>
                  {[filter, ...availableFilters]?.map((action) => {
                    return (
                      <Select.Item value={action?.id} key={action?.id}>
                        {action?.name}
                      </Select.Item>
                    );
                  })}
                </Select.Content>
              </Select.Root>

              {getSubUI(filter)}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <Popover.Root
        onOpenChange={(state) => {
          setIsPopoverOpen(state);
        }}
      >
        <Popover.Trigger>{triggerButton}</Popover.Trigger>
        <Popover.Portal>
          <Popover.Content className="PopoverContent">
            <div className="bg-white shadow-lg py-5 px-5">
              <div>
                {appliedFiltersUI()}
                <div className="flex gap-2 text-sm mt-5">
                  {true && (
                    <button
                      onClick={() => {
                        addFilter();
                      }}
                      className="flex items-center gap-1 px-2 py-1 text-sm bg-transparent rounded transition duration-300 hover:bg-gray-100"
                    >
                      <PlusOutlined />
                      Add filter
                    </button>
                  )}
                  {getAppliedFilterKeys().length > 0 && (
                    <button
                      onClick={() => {
                        clearFilters();
                        onChange({}, {});
                      }}
                      className="flex items-center gap-1 px-2 py-1 text-sm rounded transition duration-300 text-red-600 hover:bg-gray-100 cursor-pointer"
                    >
                      <CloseOutlined />
                      Clear filters
                    </button>
                  )}
                  <button
                    onClick={() => {
                      const { filter_conditions, response_filter } = getFiltersFromAppliedFilters();
                      onChange(filter_conditions, response_filter);
                    }}
                    className="border border-gray-350 bg-white text-black py-2 px-4 text-sm rounded-md disabled:bg-gray-300 hover:bg-gray-100"
                  >
                    Apply Filters
                  </button>
                </div>
              </div>
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

export default Filters;

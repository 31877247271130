import LoaderButton from "@/Components/LoaderButtonBlack";
import { message } from "antd";
import PreviewData from "../PreviewData";
import CRMfilterComponent from "../CRMfilterComponent";
import { useEffect, useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import ColumnSelector from "./ColumnSelectPopup";
import { createSignal } from "../../apis";
import { SIGNAL_ID_TO_URL } from "@/utils/urls";
import { useNavigate } from "react-router-dom";
import FilePlusCreate from "@/assets/SVGs/FilePlusCreate";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";

type FinalStepProps = {
  prev: () => void;
  next?: () => void;
  signal: any;
  generatePreview: (payload: any) => Promise<void>;
  previewData: any[];
  loading: boolean;
  payloads: any;
  propertiesFilter: any;
  setPayloads: any;
  runCondition: any;
  setRunCondition: any;
  name?: string;
};

const HBfilterPreview = ({
  prev,
  next,
  signal,
  generatePreview,
  previewData,
  loading,
  payloads,
  propertiesFilter,
  setPayloads,
  runCondition,
  setRunCondition,
  name = "New Signal",
}: FinalStepProps) => {
  const [isValidCondition, setIsValidCondition] = useState(true);
  const navigate = useNavigate();

  const getDefaultSelectedValues = (signalId: string) => {
    switch (signalId) {
      case "HUBSPOT_CONTACT_ADDED":
        return ["createdate", "firstname", "lastname", "email", "lastmodifieddate"];
      case "SALESFORCE_PULL_DATA":
        return ["Id"];
      case "PIPEDRIVE_IMPORT_DATA":
        return ["id", "add_time", "update_time"];
      default:
        return [];
    }
  };
  const [defaultSelectedValues, setDefaultSelectedValues] = useState<string[]>([]);

  useEffect(() => {
    setDefaultSelectedValues(getDefaultSelectedValues(signal.id));
  }, []);

  useEffect(() => {
    // const defaultSelectedValues = getDefaultSelectedValues(signal.id);
    if (!payloads.properties || !defaultSelectedValues.every((val) => payloads.properties?.includes(val))) {
      setPayloads((prev: any) => ({
        ...prev,
        properties: Array.from(new Set([...(prev.properties ?? []), ...defaultSelectedValues])),
      }));
    }
  }, [payloads, setPayloads]);

  function validateConditions(conditions: any) {
    // Check if the top-level conditions array exists and is not empty
    if (!conditions || !conditions.conditions || !Array.isArray(conditions.conditions)) return false;
    // Iterate through the top-level conditions
    for (const conditionGroup of conditions.conditions) {
      // Check if the nested conditions array exists and is not empty
      if (
        !conditionGroup.conditions ||
        !Array.isArray(conditionGroup.conditions) ||
        conditionGroup.conditions.length === 0
      )
        return false;
      // Iterate through the nested conditions
      for (const condition of conditionGroup.conditions) {
        // Check if required fields are empty
        if (
          !condition.variable || // variable is empty
          !condition.condition || // condition is empty
          !condition.variableType || // variableType is empty
          (condition.values && !Array.isArray(condition.values)) // values is not an array (if present)
        )
          return false;
        // If the condition requires values (e.g., "contains"), ensure values array is not empty
        if (
          [
            "contains",
            "includes",
            "is",
            "is not",
            "greater than",
            "less than",
            "greater than or equal to",
            "less than or equal to",
            "is after",
            "is before",
          ].includes(condition.condition)
        ) {
          if (!condition.values || condition.values.length === 0) return false;
        }
        if (["is between"].includes(condition.condition)) {
          if (!condition.values || condition.values.length <= 1) return false;
        }
      }
    }
    // If all checks pass, return true
    return true;
  }

  useEffect(() => {
    const conditionValid = validateConditions(runCondition);
    if (!conditionValid) {
      setIsValidCondition(false);
      return;
    }
    setIsValidCondition(true);

    setPayloads((prev: any) => ({
      ...prev,
      crmFilters: { ...(prev.crmFilters ?? []), ...runCondition },
    }));
  }, [runCondition]);

  const validatePayload = (payloadData: Record<string, any>, payloadStructure: any[]) => {
    return payloadStructure.every((item) => {
      if (!item.required) return true; // Skip non-required fields
      const fieldValue = payloadData[item.payloadStructureId];
      // Check if the required field exists and is not empty
      if (fieldValue === undefined || fieldValue === null) return false;
      // Check if it's an array and is empty
      if (Array.isArray(fieldValue) && fieldValue.length === 0) return false;
      // Check if it's an object and is empty
      if (typeof fieldValue === "object" && Object.keys(fieldValue).length === 0) return false;
      return true;
    });
  };

  return (
    <div className="p-4 h-full">
      <div className="flex flex-col col-span-4 items-start justify-start w-full gap-3 py-6 px-6 border">
        <div className="flex justify-between w-full">
          <div className="flex gap-2 items-center">
            <img src={signal.logo} alt="logo" className="h-6 w-6" />
            <h2 className="text-xl">Now, let's import your data</h2>
          </div>
          <div className="flex gap-2 items-center">
            <button
              className="text-gray-800 px-1 py-1 rounded disabled:opacity-50 border border-gray-800 hover:bg-gray-100"
              onClick={prev}
            >
              <AiOutlineArrowLeft />
            </button>
            {isValidCondition &&
              (loading ? (
                <SpinnerStatus />
              ) : (
                <button
                  className="text-gray-800 px-1 py-1 rounded disabled:opacity-50 border border-gray-800 hover:bg-gray-100"
                  onClick={next}
                >
                  <AiOutlineArrowRight />
                </button>
              ))}
          </div>
        </div>
        <p className="text-sm text-gray-500">
          Use our filters to sift through which data you want to bring in from your CRM. Additionally, use Edit columns
          to fine-tune which properties to import from your CRM.
        </p>
      </div>
      <div className="flex min-h-[40vh] border">
        <div className="w-[25%] flex flex-col gap-4 justify-between overflow-y-scroll">
          <div className=" w-full mx-auto flex flex-col items-center justify-center gap-8 rounded-lg">
            <div className="flex flex-col rounded w-full items-center justify-center">
              <h1 className="w-full text-left px-4 font-bold text-xl pt-4">Filters</h1>
              <CRMfilterComponent
                variables={propertiesFilter}
                conditionsArr={runCondition || { conditions: [] }}
                setConditionsArr={setRunCondition}
                setPayloads={setPayloads}
              />
            </div>
          </div>
          <div className="flex flex-col w-full px-2 gap-3">
            {!isValidCondition && (
              <p className="text-red-500 text-sm">*Please fill the filters or remove the empty ones</p>
            )}
            {signal.isPreviewAvailable && (
              <LoaderButton
                text="Generate Preview"
                loaderText="Generating Preview..."
                btnClasses=" mt-auto !bg-gray-300"
                txtClasses=" !text-gray-800 !justify-center w-full"
                onClickCallback={async () => {
                  // check if all required payloads are present
                  try {
                    await generatePreview(payloads);
                  } catch (error) {
                    message.error("some error occured, please refresh the page and try again");
                  }
                  return;
                }}
                error=""
                disabled={false}
              />
            )}
            {payloads.listType === "active" ? (
              <button
                onClick={next}
                className={`flex  items-center gap-2 justify-center w-full border border-gray-300 rounded py-2 ${!isValidCondition ? "bg-gray-500" : "bg-black"}  text-white`}
                disabled={!isValidCondition}
              >
                Next <AiOutlineArrowRight />
              </button>
            ) : (
              <LoaderButton
                text="Create List"
                loaderText="Creating List..."
                btnClasses="mt-auto !bg-black"
                txtClasses="text-white w-full justify-center"
                IconComponent={FilePlusCreate}
                onClickCallback={async () => {
                  const isValidPayload = validatePayload(payloads, signal.payloadStructure);
                  // check if all required payloads are present
                  if (!isValidPayload) {
                    message.error("Please fill all required fields");
                    return;
                  }
                  const newId = await createSignal(
                    SIGNAL_ID_TO_URL[signal.id as keyof typeof SIGNAL_ID_TO_URL],
                    payloads,
                    name
                  );
                  navigate(`/list/${newId}`);
                }}
                error=""
                disabled={!isValidCondition}
              />
            )}
          </div>
        </div>
        <div className="w-[75%] h-full">
          <div className="flex gap-4 border w-full p-3 items-center justify-start">
            <div className="flex gap-2 items-center">
              <h1>Data Preview</h1>
              <ColumnSelector
                payloads={payloads}
                setPayloads={setPayloads}
                propertiesFilter={propertiesFilter}
                defaultSelectedValues={defaultSelectedValues}
              />
              <p className="text-xs text-red-700">*Note: Please select the properties that you are filtering on.</p>
            </div>
          </div>
          <PreviewData
            responseStructure={signal.responseStructure}
            dynamic={signal.outputStructureType === "dataDefined"}
            data={previewData}
            generating={loading}
            signalId={signal.id}
          />
        </div>
      </div>
    </div>
  );
};

export default HBfilterPreview;

import "./instrument.js";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import "./Components/UserWorkflows/inputStyle.css";
import { BrowserRouter } from "react-router-dom";
import UserContextProvider from "./contexts/UserContext.tsx";
import WorkflowContextProvider from "./contexts/WorkflowContext.tsx";
import { ConfigProvider } from "antd";
import ConnectionContextProvider from "./contexts/ConnectionContext.tsx";
import BuilderContextProvider from "./contexts/BuilderContext.tsx";
import { ChatProvider } from "./contexts/ChatContext.tsx";
import { Theme } from "@radix-ui/themes";
import { PostHogProvider } from "posthog-js/react";
import { isPostHogEnabled, posthogKey } from "./utils/featureFlags";
import RunOnFloqContextProvider from "./contexts/RunOnFloqContext.tsx";

const PostHogWrapper = ({ children }: { children: React.ReactNode }) => {
  if (!isPostHogEnabled()) {
    return <>{children}</>;
  }
  return <PostHogProvider apiKey={posthogKey}>{children}</PostHogProvider>;
};

ReactDOM.createRoot(document.getElementById("root")!).render(
  <BrowserRouter>
    <PostHogWrapper>
      <UserContextProvider>
        <WorkflowContextProvider>
          <RunOnFloqContextProvider>
            <BuilderContextProvider>
              <ConnectionContextProvider>
                <ChatProvider>
                  <ConfigProvider
                    theme={{
                      token: {
                        fontFamily: "Lausanne",
                        colorBorder: "#000",
                        borderRadius: 3,
                        colorPrimary: "#5750FF",
                      },
                      components: {
                        Segmented: {
                          itemSelectedBg: "#5750FF",
                          itemSelectedColor: "#fff",
                        },
                        Input: {
                          activeBorderColor: "#5750FF",
                        },
                        Select: {
                          // optionActiveBg: "#5750FF",
                        },
                      },
                    }}
                  >
                    <Theme className="overflow-hidden" accentColor="indigo">
                      <App />
                    </Theme>
                  </ConfigProvider>
                </ChatProvider>
              </ConnectionContextProvider>
            </BuilderContextProvider>
          </RunOnFloqContextProvider>
        </WorkflowContextProvider>
      </UserContextProvider>
    </PostHogWrapper>
  </BrowserRouter>
);

import axios from "axios";
import { getV2, isV2 } from "./constants";
import { getBrowserTokens, handleUpdateTokens } from "./functions";
import {
  COMPANY_SEARCH_IMPORT_URL,
  COMPANY_SEARCH_IMPORT_URL_V2,
  COMPANY_SEARCH_LIST_FETCH_URL,
  COMPANY_SEARCH_LIST_FETCH_URL_V2,
  COMPANY_SEARCH_PREVIEW_URL,
  COMPANY_SEARCH_PREVIEW_URL_V2,
  deleteTableRecordsURL,
  deleteTableRecordsURLV2,
  deleteWorkflowFolderURL,
  deleteWorkflowFolderURLV2,
  deleteWorkflowURL,
  deleteWorkflowURLV2,
  duplicateWorkflowForUserURL,
  duplicateWorkflowForUserURLV2,
  getAllWorkflowActionsDetailsURL,
  getAllWorkflowActionsDetailsURLV2,
  getDataInfoForWorkflowURL,
  getDataInfoForWorkflowURLV2,
  getTableInfoForWorkflowURL,
  getTableInfoForWorkflowURLV2,
  getUserWorkflowsURL,
  getUserWorkflowsURLV2,
  moveWorkflowURL,
  moveWorkflowFolderURL,
  moveWorkflowURLV2,
  publishDraftURL,
  publishDraftURLV2,
  renameWorkflowFolderURL,
  renameWorkflowFolderURLV2,
  renameWorkflowURL,
  renameWorkflowURLV2,
  revertDraftURL,
  revertDraftURLV2,
  savePaginationURL,
  savePaginationURLV2,
  shareWorkflowAccessURL,
  shareWorkflowAccessURLV2,
  triggerEngagebayPullContactsURL,
  triggerEngagebayPullContactsURLV2,
  triggerSalesforcePullDataURL,
  triggerSalesforcePullDataURLV2,
  changeWorkflowStructureURLV2,
  getWorkflowStructureURLV2,
  stopWorkflowActions,
  WELL_DB_OPERATORS_IMPORT_URL_V2,
  WELL_DB_OPERATORS_IMPORT_URL,
  getCampaignsURLV2,
  getCampaignsURL,
  PIPEDRIVE_IMPORT_URL_V2,
  REGION_SEARCH_LIST_FETCH_CRUSTDATA_URL_V2,
  triggerHubspotPullContactsURLV2,
  triggerHubspotPullContactsURL,
  deleteAllWorkflowData,
  UPDATE_WORKFLOW_SETTINGS_URL,
  fetchTransactionsForWorkflowActionDataPartUrl,
  getWorkflowConfigURLV2,
  WIPE_WORKFLOW_CACHE_URL,
  getActionDataOnBuildURLV2,
  retryActionURLV2,
  retryActionURL,
  dataFormatterPromptURLV2,
  getSnowflakeColumnsURLV2,
  createAndSaveSubfloqURLV2,
  getUserSubfloqsURLV2,
  getAllSubfloqActionsDetailsURLV2,
  addWorkflowToSubfloqURLV2,
  publishDraftToWorkflowsURLV2,
} from "./urls";
import { IcacheConfig } from "./interfaces";
import { message } from "antd";

export const getDataInfoForWorkflow = async (userWorkflowId: string): Promise<any> => {
  try {
    const v2 = getV2();
    const url = v2 ? getDataInfoForWorkflowURLV2 : getDataInfoForWorkflowURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const res = await fetch(`${url}/${userWorkflowId}`, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.response;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch workflow");
  }
};

export const getTableInfoForWorkflow = async (
  userWorkflowId: string,
  sectionNo: number,
  pageSize: number,
  next: boolean,
  dataId: string
): Promise<any> => {
  try {
    const url = getTableInfoForWorkflowURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        section: sectionNo,
        pageSize,
        next,
        dataId,
      }),
    };
    const res = await fetch(`${url}/${userWorkflowId}`, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.response;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch workflow");
  }
};

export const getTableInfoForWorkflowV2 = async (
  userWorkflowId: string,
  sectionNo: number,
  pageSize: number | null,
  pageNo: number,
  filters?: any,
  inputFilters?: any,
  sorters: any = [],
  searchedText?: string | null,
  ignoreCache: boolean = false
): Promise<any> => {
  try {
    // We are ignoring the cache by default for now
    // TODO: Remove this once we have a proper edge case handling
    // - When the user fetches data as soon as they update it
    console.log(`Overriding ${ignoreCache} with true for ${userWorkflowId}`);
    const url = getTableInfoForWorkflowURLV2;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        section: sectionNo,
        pageSize,
        pageNo,
        filters,
        inputFilters,
        sorters: sorters,
        search_text: searchedText || null,
        ignoreCache: true,
      }),
    };
    const res = await fetch(`${url}/${userWorkflowId}`, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.response;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch workflow");
  }
};

export const deleteRecords = async (workflowId: string, dataIds: string[]) => {
  try {
    const v2 = getV2();
    const url = v2 ? deleteTableRecordsURLV2 : deleteTableRecordsURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        dataIds,
      }),
    };

    const res = await fetch(`${url}/${workflowId}`, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(`Failed to mark action as reviewed --> ${JSON.stringify(responseData.error)}`);
    handleUpdateTokens(responseData, accessToken, refreshToken);
  } catch (err) {
    console.error(err);
  }
};

export const deleteAllRecords = async (workflowId: string) => {
  try {
    const url = deleteAllWorkflowData;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({}),
    };

    const res = await fetch(`${url}/${workflowId}`, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(`Failed to mark action as reviewed --> ${JSON.stringify(responseData.error)}`);
    handleUpdateTokens(responseData, accessToken, refreshToken);
  } catch (err) {
    console.error(err);
  }
};

export const triggerSalesforcePullData = async (userWorkflowId: string) => {
  try {
    const v2 = getV2();
    const url = v2 ? triggerSalesforcePullDataURLV2 : triggerSalesforcePullDataURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const res = await fetch(`${url}/${userWorkflowId}`, options);
    const data = await res.json();
    if (!res.ok) throw new Error(data.error || "Error updating workflow data");
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const triggerEngagebayPullContacts = async (userWorkflowId: string) => {
  try {
    const v2 = getV2();
    const url = v2 ? triggerEngagebayPullContactsURLV2 : triggerEngagebayPullContactsURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const res = await fetch(`${url}/${userWorkflowId}`, options);
    const data = await res.json();
    if (!res.ok) throw new Error(data.error || "Error updating workflow data");
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const triggerHubspotPullContacts = async (userWorkflowId: string) => {
  try {
    const v2 = getV2();
    const url = v2 ? triggerHubspotPullContactsURLV2 : triggerHubspotPullContactsURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const res = await fetch(`${url}/${userWorkflowId}`, options);
    const data = await res.json();
    if (!res.ok) throw new Error(data.error || "Error updating workflow data");
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const revertDraft = async (userWorkflowId: string): Promise<any> => {
  try {
    const v2 = getV2();
    const url = v2 ? revertDraftURLV2 : revertDraftURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "POST",
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const res = await fetch(`${url}/${userWorkflowId}`, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
  } catch (error) {
    console.log(error);
    throw new Error("Failed to revert workflow");
  }
};

export const publishDraft = async (userWorkflowId: string): Promise<any> => {
  try {
    const v2 = getV2();
    const url = v2 ? publishDraftURLV2 : publishDraftURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "PATCH",
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const res = await fetch(`${url}/${userWorkflowId}`, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch workflow");
  }
};

export const publishDraft2 = async (userWorkflowId: string, isV2: boolean): Promise<any> => {
  try {
    // const v2 = getV2();
    const url = isV2 ? publishDraftURLV2 : publishDraftURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "PATCH",
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const res = await fetch(`${url}/${userWorkflowId}`, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch workflow");
  }
};

export const getUserWorkflows = async (includeActions = false, orgWorkflow?: boolean): Promise<any> => {
  try {
    const url = new URL(getUserWorkflowsURL);
    url.searchParams.append("includeActions", includeActions.toString());

    const urlV2 = new URL(getUserWorkflowsURLV2);
    urlV2.searchParams.append("includeActions", includeActions.toString());

    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      headers: {
        accessToken,
        refreshToken,
        orgWorkflows: orgWorkflow ? "true" : "false",
      },
    };
    // const response = await fetch(url, options);
    const [response, responseV2] = await Promise.all([fetch(url, options), fetch(urlV2, options)]);
    // const responseData = await response.json();
    const [responseData, responseDataV2] = await Promise.all([response.json(), responseV2.json()]);
    if (!response.ok) throw new Error(responseData.error);
    if (!responseV2.ok) throw new Error(responseDataV2.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);

    return {
      workflows: responseData,
      workflowsV2: isV2
        ? responseDataV2
        : {
            workflows: [],
            workflowFolders: [],
          },
    };
  } catch (error) {
    console.log(error);
    return { workflows: [], workflowsV2: [], error };
  }
};

export const getWorkflowDetailsWithActions = async (userWorkflowId: string, v2: boolean = false): Promise<any> => {
  try {
    const url = v2 ? getAllWorkflowActionsDetailsURLV2 : getAllWorkflowActionsDetailsURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const res = await fetch(`${url}/${userWorkflowId}`, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch workflow actions");
  }
};
export const getSubfloqDetailsWithActions = async (userWorkflowId: string): Promise<any> => {
  try {
    const url = getAllSubfloqActionsDetailsURLV2;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const res = await fetch(`${url}/${userWorkflowId}`, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch workflow actions");
  }
};

export const deleteWorkflow = async (workflowId: string, shared_with: string[] = [], v2: boolean): Promise<string> => {
  try {
    const url = v2 ? deleteWorkflowURLV2 : deleteWorkflowURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        shared_with,
      }),
    };
    const response = await fetch(`${url}/${workflowId}`, options);
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to delete workflow");
  }
};

export const getWorkflowConfigAPI = async (userWorkflowId: string): Promise<any> => {
  try {
    const url = getWorkflowConfigURLV2;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const res = await fetch(`${url}/${userWorkflowId}`, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch workflow config");
  }
};

export const deleteWorkflowFolder = async (folderId: string): Promise<string> => {
  try {
    const v2 = getV2();
    const url = v2 ? deleteWorkflowFolderURLV2 : deleteWorkflowFolderURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "DELETE",
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const response = await fetch(`${url}/${folderId}`, options);
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to delete workflow");
  }
};

export const moveWorkflow = async (
  workflowId: string,
  oldWorkflowFolderId: string,
  newWorkflowFolderId: string
): Promise<string> => {
  try {
    const v2 = getV2();
    const url = v2 ? moveWorkflowURLV2 : moveWorkflowURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        oldWorkflowFolderId,
        newWorkflowFolderId,
      }),
    };
    const response = await fetch(`${url}/${workflowId}`, options);
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to move workflow");
  }
};

export const moveWorkflowFolder = async (
  workflowFolderId: string,
  oldWorkflowFolderId: string,
  newWorkflowFolderId: string
): Promise<string> => {
  try {
    const url = moveWorkflowFolderURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        oldWorkflowFolderId,
        newWorkflowFolderId,
      }),
    };
    const response = await fetch(`${url}/${workflowFolderId}`, options);
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to move workflow");
  }
};

export const getWorkflowStructure = async (): Promise<{
  success: boolean;
  data: any[] | null;
}> => {
  try {
    const url = getWorkflowStructureURLV2;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const res = await fetch(url, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return {
      success: responseData.success,
      data: responseData.data,
    };
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch workflow structure");
  }
};

export const reorderWorkflowStructure = async (folderStructure: any[]): Promise<void> => {
  try {
    const url = changeWorkflowStructureURLV2;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        data: folderStructure,
      }),
    };
    const response = await fetch(url, options);
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
  } catch (error) {
    console.log(error);
    throw new Error("Failed to change workflow structure");
  }
};

export const duplicateWorkflow = async (
  workflowId: string,
  newName: string,
  folder: string,
  v2: boolean
): Promise<string> => {
  try {
    const url = v2 ? duplicateWorkflowForUserURLV2 : duplicateWorkflowForUserURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        newWfName: newName,
        folder,
      }),
    };
    const response = await fetch(`${url}/${workflowId}`, options);
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.newWorkflowId;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to copy workflow");
  }
};

export const renameWorkflow = async (workflowId: string, newName: string, v2: boolean): Promise<void> => {
  try {
    const url = v2 ? renameWorkflowURLV2 : renameWorkflowURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        newWfName: newName,
      }),
    };
    const response = await fetch(`${url}/${workflowId}`, options);
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
  } catch (error) {
    console.log(error);
    throw new Error("Failed to rename workflow");
  }
};
export const renameWorkflowFolder = async (folderId: string, newName: string): Promise<void> => {
  try {
    const v2 = getV2();
    const url = v2 ? renameWorkflowFolderURLV2 : renameWorkflowFolderURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        newWfName: newName,
      }),
    };
    const response = await fetch(`${url}/${folderId}`, options);
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.newWorkflowId;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to Rename Folder");
  }
};

export const savePagination = async (workflowId: string, pagination: number) => {
  try {
    const v2 = getV2();
    const url = v2 ? savePaginationURLV2 : savePaginationURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        pagination,
      }),
    };

    const res = await fetch(`${url}/${workflowId}`, options);
    const data = await res.json();
    if (!res.ok) {
      throw new Error(data.error);
    }
    return data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to save pagination");
  }
};

export const stopAllWorkflowActions = async (workflowId: string, section: number) => {
  try {
    const url = stopWorkflowActions;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        section,
      }),
    };

    const res = await fetch(`${url}/${workflowId}`, options);
    const data = await res.json();
    if (!res.ok) {
      throw new Error(data.error);
    }
    return data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to save pagination");
  }
};

export const fetchTSLists = async (type: string, payload: any, v2: boolean = false): Promise<any[]> => {
  try {
    let url = v2 ? COMPANY_SEARCH_LIST_FETCH_URL_V2 : COMPANY_SEARCH_LIST_FETCH_URL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
    };
    const finalURL = new URL(`${url}/${type}`);
    // Loop through the parameters object and append each one to the URL
    for (const [key, value] of Object.entries(payload)) {
      if (value != undefined && value != null) {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            finalURL.searchParams.append(key, String(item));
          });
        } else {
          finalURL.searchParams.append(key, String(value));
        }
      }
    }
    const res = await fetch(finalURL, options);
    const data = await res.json();
    if (!res.ok) throw new Error(data.error);

    return data.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch data");
  }
};

export const fetchRegionLists = async (payload: any): Promise<any[]> => {
  try {
    let url = REGION_SEARCH_LIST_FETCH_CRUSTDATA_URL_V2;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
    };
    const finalURL = new URL(url);
    // Loop through the parameters object and append each one to the URL
    for (const [key, value] of Object.entries(payload)) {
      if (value != undefined && value != null) {
        if (Array.isArray(value)) {
          value.forEach((item: any) => {
            finalURL.searchParams.append(key, String(item));
          });
        } else {
          finalURL.searchParams.append(key, String(value));
        }
      }
    }
    const res = await fetch(finalURL, options);
    const data = await res.json();
    if (!res.ok) throw new Error(data.error);

    return data.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch data");
  }
};

export const generateTSPreview = async (filters: any, v2: boolean = false): Promise<any> => {
  try {
    const url = v2 ? COMPANY_SEARCH_PREVIEW_URL_V2 : COMPANY_SEARCH_PREVIEW_URL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        filters,
      }),
    };
    const res = await fetch(url, options);
    const data = await res.json();
    if (!res.ok) throw new Error(data.error);

    return data.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch data");
  }
};

export const importTSCompanies = async (
  worfklowId: string,
  filters: any,
  count: number,
  total: number,
  v2: boolean = false
): Promise<any> => {
  try {
    const url = v2 ? COMPANY_SEARCH_IMPORT_URL_V2 : COMPANY_SEARCH_IMPORT_URL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        filters,
        userWorkflowId: worfklowId,
        count,
        total,
      }),
    };
    const res = await fetch(url, options);
    const data = await res.json();
    if (!res.ok) throw new Error(data.error);

    return data.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch data");
  }
};

export const importWelldbOperators = async (worfklowId: string, filters: any, v2: boolean = false): Promise<any> => {
  try {
    const url = v2 ? WELL_DB_OPERATORS_IMPORT_URL_V2 : WELL_DB_OPERATORS_IMPORT_URL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        filters,
        userWorkflowId: worfklowId,
      }),
    };
    const res = await fetch(url, options);
    const data = await res.json();
    if (!res.ok) throw new Error(data.error);

    return data.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch data");
  }
};

export const importPipedrive = async (worfklowId: string, type: any, fields: any): Promise<any> => {
  try {
    const url = PIPEDRIVE_IMPORT_URL_V2;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        type,
        fields,
        userWorkflowId: worfklowId,
      }),
    };
    const res = await fetch(url, options);
    const data = await res.json();
    if (!res.ok) throw new Error(data.error);

    return data.data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch data");
  }
};

export const shareWorkflowAccess = async (
  workflowId: string,
  users: string[],
  type: string,
  wfData: any,
  v2: boolean = true
) => {
  try {
    const isV2 = v2;
    const url = isV2 ? shareWorkflowAccessURLV2 : shareWorkflowAccessURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        users,
        type,
        wfData,
      }),
    };

    const res = await fetch(`${url}/${workflowId}`, options);
    const data = await res.json();
    if (!res.ok) {
      throw new Error(data.error);
    }
    return data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to save pagination");
  }
};

// NEEDS TO BE UPDATED
export const getCampaigns = async (type: string, isV2: boolean = false) => {
  try {
    const v2 = getV2();
    const url = isV2 || v2 ? getCampaignsURLV2 : getCampaignsURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const response = await fetch(`${url}/${type}`, options);
    const responseData = await response.json();

    if (!response.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.campaigns;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch campaigns");
  }
};
export const getCampaignsFields = async (type: string, field: string, isV2: boolean = false) => {
  try {
    const v2 = getV2();
    const url = isV2 || v2 ? getCampaignsURLV2 : getCampaignsURL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const response = await fetch(`${url}/${type}/${field}`, options);
    const responseData = await response.json();

    if (!response.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.campaigns;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch campaigns");
  }
};

export const fetchTechnologies = async () => {
  try {
    const data = await axios.get("https://storage.googleapis.com/json_document_bucket/hg_insights_technologies.json", {
      headers: { "Access-Control-Allow-Origin": "*" },
    });
    const res = await data.data;
    return res;
  } catch (error) {
    console.error("Error fetching technologies:", error);
    return [];
  }
};

export const fetchLanguages = async () => {
  try {
    const data = await axios.get("https://storage.googleapis.com/json_document_bucket/youtube_languages.json", {
      headers: { "Access-Control-Allow-Origin": "*" },
    });
    const res = await data.data;
    return res;
  } catch (error) {
    console.error("Error fetching languages:", error);
    return [];
  }
};

export const fetchYoutubeCountries = async () => {
  try {
    const data = await axios.get("https://storage.googleapis.com/json_document_bucket/youtube_countries.json", {
      headers: { "Access-Control-Allow-Origin": "*" },
    });
    const res = await data.data;
    return res;
  } catch (error) {
    console.error("Error fetching youtube countries:", error);
    return [];
  }
};

export const updateWorkflowSettings = async (
  workflowId: string,
  settings: {
    cacheEnabled?: boolean;
    auto_publish?: boolean;
    cacheConfig?: IcacheConfig;
    continue_run?: boolean;
  }
) => {
  try {
    const url = UPDATE_WORKFLOW_SETTINGS_URL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const body: any = {};
    settings.cacheConfig && (body["cache_config"] = settings.cacheConfig);
    settings.cacheEnabled != undefined && (body["cache_enabled"] = settings.cacheEnabled);
    settings.auto_publish != undefined && (body["auto_publish"] = settings.auto_publish);
    settings.continue_run != undefined && (body["continue_run"] = settings.continue_run);

    const options = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify(body),
    };
    const res = await fetch(`${url}/${workflowId}`, options);
    const data = await res.json();
    if (!res.ok) throw new Error(data.error);
    return data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to update workflow settings");
  }
};

export const wipeWorkflowCache = async () => {
  try {
    const url = WIPE_WORKFLOW_CACHE_URL;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({}),
    };
    const res = await fetch(url, options);
    const data = await res.json();
    if (!res.ok) throw new Error(data.error);
    return data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to wipe workflow cache");
  }
};

export const fetchTransactionsForWorkflowActionDataPart = async (
  workflowId: string,
  workflowDataActionPartId: string
) => {
  const url = fetchTransactionsForWorkflowActionDataPartUrl;
  const [accessToken, refreshToken] = getBrowserTokens();
  const options = {
    headers: {
      accessToken,
      refreshToken,
    },
  };
  const res = await fetch(`${url}/${workflowId}/${workflowDataActionPartId}`, options);
  const data = await res.json();
  if (!res.ok) throw new Error(data.error);
  return data;
};

export const getActionDataOnBuild = async (
  workflowId: string,
  actionId: string,
  section: number,
  pageNo?: number,
  pageSize?: number
) => {
  try {
    const url = getActionDataOnBuildURLV2 + `/${workflowId}`;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "POST",
      headers: {
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        section,
        actionNameId: actionId,
        pageNo: pageNo || 1,
        pageSize: pageSize || 10,
      }),
    };
    const res = await fetch(url, options);
    const data = await res.json();
    if (!res.ok) throw new Error(data.error);
    return data.response;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch action data");
  }
};

export const runOnFloq = async (
  actionNameId: string,
  userWorkflowId: string,
  dataIds: string[],
  section: number,
  type: string
) => {
  try {
    const v2 = getV2();
    const url = v2 ? retryActionURLV2 : retryActionURL;
    const [accessToken, refreshToken] = getBrowserTokens();

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        actionNameId,
        dataIds,
        type,
        section,
      }),
    };

    const res = await fetch(`${url}/${userWorkflowId}`, options);
    const data = await res.json();

    if (!res.ok) throw new Error(data.error || "Error updating workflow data");
    handleUpdateTokens(data, accessToken, refreshToken);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const updateDataForRunOnFloq = async (
  actionNameId: string,
  userWorkflowId: string,
  dataIds: string[],
  section: number
) => {
  try {
    const url = getActionDataOnBuildURLV2 + `/${userWorkflowId}`;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "PATCH",
      headers: {
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        section,
        actionNameId: actionNameId,
        dataIds: dataIds,
      }),
    };
    const res = await fetch(url, options);
    const data = await res.json();
    if (!res.ok) throw new Error(data.error);
    return data.updatedWorkflowData;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch action data");
  }
};
export const fetchClayIndustries = async () => {
  try {
    const data = await axios.get("https://storage.googleapis.com/json_document_bucket/clay_industries.json", {
      headers: { "Access-Control-Allow-Origin": "*" },
    });
    const res = await data.data;
    return res;
  } catch (error) {
    console.error("Error fetching industries:", error);
    return [];
  }
};

export const fetchClayCountries = async () => {
  try {
    const data = await axios.get("https://storage.googleapis.com/json_document_bucket/clay_countries.json", {
      headers: { "Access-Control-Allow-Origin": "*" },
    });
    const res = await data.data;
    return res;
  } catch (error) {
    console.error("Error fetching countries:", error);
    return [];
  }
};

export const fetchClayLocations = async () => {
  try {
    const data = await axios.get("https://storage.googleapis.com/json_document_bucket/clay_locations.json", {
      headers: { "Access-Control-Allow-Origin": "*" },
    });
    const res = await data.data;
    return res;
  } catch (error) {
    console.error("Error fetching locations:", error);
    return [];
  }
};

export const fetchClayCities = async () => {
  try {
    const data = await axios.get("https://storage.googleapis.com/json_document_bucket/clay_cities.json", {
      headers: { "Access-Control-Allow-Origin": "*" },
    });
    const res = await data.data;
    return res;
  } catch (error) {
    console.error("Error fetching cities:", error);
    return [];
  }
};

export const fetchClayStates = async () => {
  try {
    const data = await axios.get("https://storage.googleapis.com/json_document_bucket/clay_states.json", {
      headers: { "Access-Control-Allow-Origin": "*" },
    });
    const res = await data.data;
    return res;
  } catch (error) {
    console.error("Error fetching states:", error);
    return [];
  }
};

export const createAndSaveSubfloq = async (
  name: string,
  description: string,
  extra: any = {},
  v2?: boolean
): Promise<string> => {
  try {
    const url = v2 ? createAndSaveSubfloqURLV2 : "";
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        name,
        description,
        extraData: extra,
      }),
    };
    const response = await fetch(url, options);
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
    return responseData.subfloq_id;
  } catch (error: any) {
    console.log(error);
    if (error.message.includes("connection not found")) return error.message;
    return "";
  }
};

export const getUserSubfloqs = async (
  accessibleSubfloqs: "individual" | "organization" | "community",
  includeActions: boolean
): Promise<any> => {
  try {
    const urlV2 = new URL(getUserSubfloqsURLV2);
    urlV2.searchParams.append("includeActions", includeActions.toString());

    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      headers: {
        accessToken,
        refreshToken,
        accessibleSubfloqs,
      },
    };
    // const response = await fetch(url, options);
    const responseV2 = await fetch(urlV2, options);
    // const responseData = await response.json();
    const responseDataV2: any = await responseV2.json();
    if (!responseV2.ok) throw new Error(responseDataV2.error);
    handleUpdateTokens(responseDataV2, accessToken, refreshToken);

    return responseDataV2.subfloqs;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getAllSubfloqs = async (includeActions: boolean = false) => {
  const [indvSubfloqs, orgSubfloqs, communitySubfloqs] = await Promise.all([
    getUserSubfloqs("individual", includeActions),
    getUserSubfloqs("organization", includeActions),
    getUserSubfloqs("community", includeActions),
  ]);

  // Use a Set to track IDs and filter duplicates
  const seenIds = new Set<string>();
  const allSubfloqs = [...indvSubfloqs, ...orgSubfloqs, ...communitySubfloqs].filter((subfloq) => {
    if (seenIds.has(subfloq.id)) {
      return false; // Skip duplicate
    }
    seenIds.add(subfloq.id);
    return true; // Keep unique subfloq
  });

  return allSubfloqs;
};

export const addWorkflowToSubfloq = async (
  subfloqId: string,
  workflowId: string,
  workflowName: string,
  operation: "add" | "remove"
): Promise<void> => {
  try {
    const url = addWorkflowToSubfloqURLV2;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "POST",
      headers: {
        accessToken,
        refreshToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        workflowId,
        workflowName,
        operation,
      }),
    };
    // const response = await fetch(url, options);
    const res = await fetch(`${url}/${subfloqId}`, options);
    // const responseData = await response.json();
    const responseDataV2: any = await res.json();
    if (!res.ok) throw new Error(responseDataV2.error);
  } catch (error) {
    console.log(error);
  }
};

export const publishDraftSubfloqToWorkflows = async (subfloq_id: string): Promise<any> => {
  try {
    const v2 = getV2();
    const url = v2 ? publishDraftToWorkflowsURLV2 : publishDraftToWorkflowsURLV2;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "PATCH",
      headers: {
        accessToken,
        refreshToken,
      },
    };
    const res = await fetch(`${url}/${subfloq_id}`, options);
    const responseData = await res.json();
    if (!res.ok) throw new Error(responseData.error);
    handleUpdateTokens(responseData, accessToken, refreshToken);
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch workflow");
  }
};

export const dataFormatterPrompt = async (workflowId: string, prompt: any) => {
  try {
    // if there is sampleOutputs in prompt, pop it out
    const { sampleOutputs, ...rest } = prompt;
    const url = dataFormatterPromptURLV2;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "POST",
      headers: {
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        workflowId,
        prompt: rest,
        sampleOutputs,
      }),
    };
    const res = await fetch(url, options);
    const data = await res.json();
    if (!res.ok) throw new Error(data.error);
    return data;
  } catch (error) {
    console.log(error);
    throw new Error("Failed to fetch data formatter prompt");
  }
};

export const getSnowflakeColumns = async (
  tableName: string,
  database: string,
  schema: string,
  role: string,
  warehouse: string
) => {
  try {
    // if there is sampleOutputs in prompt, pop it out
    const url = getSnowflakeColumnsURLV2;
    const [accessToken, refreshToken] = getBrowserTokens();
    const options = {
      method: "POST",
      headers: {
        accessToken,
        refreshToken,
      },
      body: JSON.stringify({
        tableName,
        database,
        schema,
        role,
        warehouse,
      }),
    };
    const res = await fetch(url, options);
    const data = await res.json();
    if (!res.ok) throw new Error(data.error);
    return data.columns;
  } catch (error: any) {
    message.error(error.message);
    return [];
  }
};

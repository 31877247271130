import { useEffect, useRef, useState } from "react";
import { getSignals } from "./apis";
import Signals from "./Pages/Signals";
import { message } from "antd";
import Loader from "../Loader";
import { LOADER_TYPES } from "@/utils/constants";
import { useLocation, useSearchParams } from "react-router-dom";
import Create from "./Pages/Create";
import { CollapsibleIcon } from "../NavbarNew/CollapsibleIcon";
// import { TbChevronUp } from "react-icons/tb";
import CRM from "./Pages/CRM";
import Lists from "./Pages/Lists";
import { useUser } from "@/contexts/UserContext";
import "./styles.css";
import OptionSelector from "./Components/CrmComponents/ListTypeOptionSelect";

const index = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [availableSignals, setAvailableSignals] = useState<any[]>([]);
  const [staticSignals, setStaticSignals] = useState<any[]>([]);
  const [activeSignals, setActiveSignals] = useState<any[]>([]);
  const [crmSignals, setCrmSignals] = useState<any[]>([]);

  const [signals, setSignals] = useState<any[]>([]);
  const [crms, setCrms] = useState<any[]>([]);
  const [lists, setLists] = useState<any[]>([]);

  // const [isTopVisible, setIsTopVisible] = useState(true);
  const topElementRef = useRef<HTMLDivElement | null>(null);

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       setIsTopVisible(entry.isIntersecting);
  //     },
  //     {
  //       root: null,
  //       rootMargin: "0px",
  //       threshold: 0.9,
  //     }
  //   );

  //   if (topElementRef.current) {
  //     observer.observe(topElementRef.current);
  //   }

  //   return () => {
  //     if (topElementRef.current) {
  //       observer.unobserve(topElementRef.current);
  //     }
  //   };
  // }, []);

  // const scrollToTop = () => {
  //   const container = topElementRef.current;
  //   container?.scrollIntoView({ behavior: "smooth", block: "start" });
  // };

  const { collapsed: isSidebarCollapsed } = useUser();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const createId = searchParams.get("create");

  useEffect(() => {
    setLoading(true);
    getSignals()
      .then((data) => {
        // Filter out archived signals
        const filteredUserWatchers = data.userWatchers.filter((signal: any) => signal.status !== "archived");
        // setUserSignals(data.userWatchers);
        setAvailableSignals(data.availableWatchers);
        // Set signals, lists, and crms based on the type key
        setSignals(data.availableWatchers.filter((watcher: any) => watcher.type === "watcher"));
        setLists(data.availableWatchers.filter((watcher: any) => watcher.type === "list"));
        setCrms(
          data.availableWatchers.filter((watcher: any) => watcher.type === "crmList" || watcher.type === "crmWatcher")
        );

        setStaticSignals(filteredUserWatchers.filter((signal: any) => signal.status === "static"));
        setActiveSignals(filteredUserWatchers.filter((signal: any) => ["active", "paused"].includes(signal.status)));
        setCrmSignals(
          filteredUserWatchers.filter((signal: any) => ["crm-static", "crm-active"].includes(signal.status))
        );
      })
      .catch((error) => message.error(error.message))
      .finally(() => setLoading(false));
  }, []);

  let content;

  switch (location.pathname) {
    case "/intent-signals":
      content = (
        <Signals
          isSidebarCollapsed={isSidebarCollapsed}
          availableSignals={signals}
          userSignals={activeSignals}
          setActiveSignals={setActiveSignals}
        />
      );
      break;
    case "/crm":
      content = (
        <CRM
          isSidebarCollapsed={isSidebarCollapsed}
          availableSignals={crms}
          userSignals={crmSignals}
          setCrmSignals={setCrmSignals}
        />
      );
      break;
    case "/list-building":
    default:
      content = (
        <Lists
          isSidebarCollapsed={isSidebarCollapsed}
          availableSignals={lists}
          userSignals={staticSignals}
          setStaticSignals={setStaticSignals}
        />
      );
      break;
  }

  const getPageTitle = () => {
    switch (location.pathname) {
      case "/intent-signals":
        return "Intent Signals";
      case "/crm":
        return "CRM";
      case "/list-building":
      default:
        return "List Builders";
    }
  };

  if (loading)
    return (
      <div className="w-full h-full">
        <Loader loaderType={LOADER_TYPES.fetching} payload="Signals" />
      </div>
    );

  if (createId) {
    const signal = availableSignals.find((signal) => signal.id === createId);
    if (signal) {
      switch (signal.id) {
        case "HUBSPOT_CONTACT_ADDED":
        case "SALESFORCE_PULL_DATA":
        case "PIPEDRIVE_IMPORT_DATA":
          return (
            <div className="w-full h-full">
              {/* <HubspotMultiStepForm signal={signal} /> */}
              <OptionSelector signal={signal} />
            </div>
          );
        default:
          return (
            <div className="w-full h-full">
              <Create signal={signal} />
            </div>
          );
      }
    } else {
      return (
        <div className="w-full h-full">
          <Loader loaderType={LOADER_TYPES.fetching} payload="Signal Data" />
        </div>
      );
    }
  }

  return (
    <div ref={topElementRef} className="newScroll h-full w-full flex flex-col !cursor-default !font-favorit">
      <div className="w-full flex items-center justify-between px-8 py-3 z-40 mb-8 sticky top-0 bg-white border-b border-gray-300">
        <span className="flex items-center gap-4">
          <CollapsibleIcon isHeader />
          <span className=" font-medium text-center text-[#3F3F3F] flex items-center gap-[5px]">{getPageTitle()}</span>
        </span>

        {/* 
        <CollapsibleIcon isHeader />

        <span className=" font-medium text-center text-[#3F3F3F] flex items-center gap-[5px]">{getPageTitle()}</span>

        <Tooltip title="Click for help or more info">
          <span className="flex items-center gap-1 text-[#5b5b5b] cursor-pointer">
            <TbHelp className="text-lg" />
            <span className="text-sm font-semibold">Help</span>
          </span>
        </Tooltip> */}
      </div>
      {content}
      {/* Go Back to Top Button */}
      {/* {!isTopVisible && (
        <div className="absolute bottom-4 z-50 right-0 flex justify-center ml-auto px-4 mr-3">
          <button
            onClick={scrollToTop}
            className="bg-[#e6e6e8] text-[#333333] px-2 py-1 rounded-md hover:bg-[#dad9d9] transition-colors flex items-center gap-2 mb-2"
          >
            Back to top <TbChevronUp className="text-2xl" />
          </button>
        </div>
      )} */}
      {/* <Sidebar selected={selected} setSelected={setSelected} /> */}
      {/* <div className="col-span-4">{switchComponent()}</div> */}
    </div>
  );
};

export default index;

import { useState } from "react";
import FloqerIcon from "/assets/floq-icon.svg";
import SignalsIcon from "/assets/signals.svg";
import SignalsPurpleIcon from "/assets/signals_purple.svg";
import ListIcon from "/assets/list.svg";
import ListPurpleIcon from "/assets/list_purple.svg";
import WifiIcon from "/assets/wifi-icon.svg";
import PlayIcon from "/assets/play-icon.svg";
import PeopleIcon from "/assets/people-icon.svg";
import WifiIconPurple from "/assets/wifi-icon-purple.svg";
import PlayIconPurple from "/assets/play-icon-purplr.svg";
import PeopleIconPurple from "/assets/people-icon-purple.svg";
import DollarIconBlack from "@Assets/SVGs/DollarIconBlack.svg";
import SettingsIconBlack from "@Assets/SVGs/SettingsIconBlack.svg";
import { motion } from "framer-motion";
import { cn } from "@/utils/cn";
import { Sidebar, SidebarBody, SidebarLink } from "./Sidebar";
import { Link, Outlet } from "react-router-dom";
import { useUser } from "@/contexts/UserContext";
import { CollapsibleIcon } from "./CollapsibleIcon";
import { LuLogOut } from "react-icons/lu";
import DatabaseIcon from "@Assets/SVGs/DatabaseIcon.svg";

export function MainSidebar() {
  const [open, setOpen] = useState(false);
  const { collapsed } = useUser();
  const topLinks = [
    {
      label: "Get Leads",
      href: "/lists",
      selected: "/list",
      icon: <img src={PeopleIcon} className="h-4 w-4 flex-shrink-0 translate-y-[-1px]" />,
      selectedIcon: <img src={PeopleIconPurple} className="h-4 w-4 flex-shrink-0 translate-y-[-1px]" />,
      subLinks: [
        {
          label: "Intent Signals",
          href: "/intent-signals",
          selected: "/intent-signals",
          icon: <img src={SignalsIcon} className="h-4 w-4 flex-shrink-0" />,
          selectedIcon: <img src={SignalsPurpleIcon} className="h-4 w-4 flex-shrink-0" />,
        },
        {
          label: "List Building",
          href: "/list-building",
          selected: "/list-building",
          icon: <img src={ListIcon} className="h-4 w-4 flex-shrink-0" />,
          selectedIcon: <img src={ListPurpleIcon} className="h-4 w-4 flex-shrink-0" />,
        },
        {
          label: "CRM",
          href: "/crm",
          selected: "/crm",
          icon: <img src={DatabaseIcon} className="h-4 w-4 flex-shrink-0" />,
          selectedIcon: <img src={DatabaseIcon} className="h-4 w-4 flex-shrink-0" />,
        },
      ],
    },
    {
      label: "Workflows",
      href: "/workflows",
      selected: "/workflow",
      icon: <img src={PlayIcon} className="h-4 w-4 flex-shrink-0" />,
      selectedIcon: <img src={PlayIconPurple} className="h-4 w-4 flex-shrink-0" />,
    },
    {
      label: "Connections",
      href: "/connections",
      selected: "/connections",
      icon: <img src={WifiIcon} className="h-4 w-4 flex-shrink-0" />,
      selectedIcon: <img src={WifiIconPurple} className="h-4 w-4 flex-shrink-0" />,
    },
  ];

  const bottomLinks = [
    {
      label: "Usage",
      href: "/credits",
      selected: "/credits",
      icon: <img src={DollarIconBlack} className="h-4 w-4 flex-shrink-0" />,
      selectedIcon: <img src={DollarIconBlack} className="h-4 w-4 flex-shrink-0" />,
    },
    {
      label: "Settings",
      href: "/settings",
      selected: "/settings",
      icon: <img src={SettingsIconBlack} className="h-4 w-4 flex-shrink-0" />,
      selectedIcon: <img src={SettingsIconBlack} className="h-4 w-4 flex-shrink-0" />,
    },
    // {
    //   label: "Logout",
    //   href: "#",
    //   selected: "/logout",
    //   icon: <img src={LogoutIcon} className="h-5 w-5 flex-shrink-0" />,
    // },
  ];

  return (
    <div
      className={cn(
        "flex flex-col md:flex-row bg-white w-full border border-black overflow-hidden",
        "h-screen" // for your use case, use `h-screen` instead of `h-[60vh]`
      )}
    >
      <Sidebar open={open} setOpen={setOpen} animate={collapsed}>
        <SidebarBody className="justify-between gap-10">
          {!collapsed ? (
            <>
              <div className="flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <div className="flex items-center justify-between gap-2 group/sidebar py-3 border-b border-gray-200 px-3">
                  <FloqLogo />
                  <CollapsibleIcon />
                </div>
                <div className="flex flex-col gap-1.5 mt-8 px-3">
                  {topLinks.map((link, idx) => (
                    <SidebarLink key={idx} link={link} />
                  ))}
                </div>
              </div>
              <div className="flex flex-col gap-1.5 mb-3 px-3">
                {bottomLinks.map((link, idx) => (
                  <SidebarLink key={idx} link={link} />
                ))}
              </div>
            </>
          ) : (
            ""
          )}
          <div className="flex items-center gap-2 group/sidebar px-3 py-3 border-t border-gray-200">
            <Logout isOpen={open} animate={collapsed} />
          </div>
        </SidebarBody>
      </Sidebar>
      <div className="w-full bg-[#ffffff] overflow-auto">
        <Outlet />
      </div>
    </div>
  );
}

export const FloqLogo = () => {
  return (
    <Link to="#" className="flex items-center justify-start gap-2 group/sidebar">
      <img src={FloqerIcon} className="h-6 w-6" />
      {/* <motion.span
        animate={{
          display: animate ? (isOpen ? "inline-block" : "none") : "inline-block",
          opacity: animate ? (isOpen ? 1 : 0) : 1,
        }}
        transition={{
          duration: 0.15,
          ease: "easeInOut",
        }}
        className="text-neutral-700 font-bold text-lg whitespace-pre inline-block !p-0 !m-0"
      >
        Floqer
      </motion.span> */}
    </Link>
  );
};

export const Logout = ({ isOpen, animate }: any) => {
  const { logout } = useUser();
  return (
    <motion.button
      animate={{
        display: animate ? (isOpen ? "flex" : "none") : "flex",
        opacity: animate ? (isOpen ? 1 : 0) : 1,
      }}
      transition={{
        duration: 0.15,
        ease: "easeInOut",
      }}
      onClick={logout}
      className="flex items-center justify-start gap-2 group/sidebar p-2 hover:bg-red-200 w-full rounded-md"
    >
      <LuLogOut className="text-lg text-[#C50000]" />
      <span className="text-[#C50000] text-sm font-medium whitespace-pre inline-block !p-0 !m-0">Sign Out</span>
    </motion.button>
  );
};

// Dummy dashboard component with content
// const Dashboard = () => {
//   return (
//     <div className="flex flex-1">
//       <div className="p-2 md:p-10 rounded-tl-2xl border border-neutral-200 bg-white flex flex-col gap-2 flex-1 w-full h-full">
//         <div className="flex gap-2">
//           {[...new Array(4)].map((_, i) => (
//             <div key={"first-array" + i} className="h-20 w-full rounded-lg  bg-gray-100 animate-pulse"></div>
//           ))}
//         </div>
//         <div className="flex gap-2 flex-1">
//           {[...new Array(2)].map((_, i) => (
//             <div key={"second-array" + i} className="h-full w-full rounded-lg  bg-gray-100 animate-pulse"></div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

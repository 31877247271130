import React, { useState } from "react";
import { Popconfirm } from "antd";
import DeleteIcon from "../../../assets/SVGs/DeleteIcon.svg";

type ConfirmDeleteButtonProps = {
  onDelete: () => Promise<void>;
  hoverText?: string;
  disabled?: boolean;
};

const ConfirmDeleteButton: React.FC<ConfirmDeleteButtonProps> = ({ onDelete, hoverText, disabled }) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    await onDelete();
    setConfirmLoading(false);
    setOpen(false);
  };

  return (
    <Popconfirm
      title="Delete inputs"
      description="Are you sure to delete these inputs?"
      open={open}
      onConfirm={handleOk}
      okButtonProps={{ loading: confirmLoading }}
      onCancel={() => setOpen(false)}
      okText="Yes"
      cancelText="No"
      destroyTooltipOnHide
      trigger="click"
      fresh
      disabled={disabled}
    >
      <button
        className="rounded-full w-fit h-8 px-3 flex gap-1 items-center text-red-500 border border-red-300 transition-all ease-in-out duration-300 hover:bg-red-50 hover:border-red-500 disabled:cursor-not-allowed"
        onClick={showPopconfirm}
        title={disabled ? "Please select an input to delete" : ""}
        disabled={disabled}
      >
        <img src={DeleteIcon} alt="Delete" className="w-4 h-4" />
        <p className={`text-sm font-normal ${disabled ? "hidden" : "inline-block"}`}>{hoverText || ""}</p>
      </button>
    </Popconfirm>
  );
};

export default ConfirmDeleteButton;
